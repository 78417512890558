import PropTypes from '+prop-types';
import { useCallback } from 'react';

import styled, { css } from 'styled-components';

import { useMenuActions, useMenuState } from '../context';

const Container = styled.div.attrs({ role: 'button' })`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  min-width: 150px;
  transition: background-color 200ms;
  cursor: pointer;
  padding: 8px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${({ theme }) => theme.contextMenuItemHoverBorder};
    opacity: 0;
    transition: all 0.3s;
  }

  ${(props) =>
    (props.$hoverFocus ?? true) &&
    css`
      &:hover,
      &:focus {
        background-color: ${props.$disabled
          ? 'transparent'
          : props.theme.contextMenuItemHoverBackground};

        &:before {
          opacity: ${props.$disabled ? 0 : 1};
        }
      }
    `}

  ${(props) =>
    props.$focused &&
    css`
      background-color: ${props.$disabled
        ? 'transparent'
        : props.theme.contextMenuItemHoverBackground};

      &:before {
        opacity: ${props.$disabled ? 0 : 1};
      }
    `}

  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
  }

  > span,
  .menu-item__content {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;

    &:not(#fake_id_for_span_in_item) {
      color: inherit;
    }

    > .mdi-icon {
      margin-right: 4px;
    }

    .menu-item__icon {
      display: flex;
      align-items: center;
      margin-right: 4px;

      &:only-child {
        margin-right: unset;
      }
    }
  }

  ${(props) =>
    props.$disabled &&
    css`
      * {
        color: ${props.theme.contextMenuItemDisabledColor} !important;
      }
      color: ${props.theme.contextMenuItemDisabledColor} !important;
      pointer-events: none;
    `}

  &.menu-trigger {
    min-width: unset;

    .menu-item__icon {
      margin-right: unset;
    }

    .menu-item__text,
    &:before {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.menu-trigger.first-item-as-trigger .menu-item__content {
    justify-content: center;
    align-items: center;
    padding: unset;
    height: 24px;
  }
`;

export const Item = (props) => {
  const {
    dismissOnExecute = true,
    onClick,
    onKeyUp,
    disabled,
    focused,
    hoverFocus,
    ...tail
  } = props;

  const state = useMenuState();
  const { hideMenu } = useMenuActions();

  const emitKeyUp = useCallback(
    (evt) => {
      if (dismissOnExecute && evt.key === 'Enter') {
        hideMenu();
      }

      if (onKeyUp) {
        onKeyUp(evt, state);
      }
    },
    [dismissOnExecute, onKeyUp, hideMenu, state],
  );

  const emitClick = useCallback(
    (evt) => {
      if (dismissOnExecute) {
        hideMenu();
      }
      if (onClick) {
        onClick(evt, state);
      }
    },
    [onClick, dismissOnExecute, hideMenu, state],
  );

  return (
    <Container
      tabIndex={0 - disabled}
      onClick={emitClick}
      onKeyUp={emitKeyUp}
      $disabled={disabled}
      $focused={focused}
      $hoverFocus={hoverFocus}
      {...tail}
    />
  );
};

Item.propTypes = {
  dismissOnExecute: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  hoverFocus: PropTypes.bool,
};

Item.defaultProps = {
  dismissOnExecute: true,
  onClick: null,
  onKeyUp: null,
  disabled: false,
  focused: false,
  hoverFocus: null,
};

export default Item;
