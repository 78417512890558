import { useMemo } from 'react';

import { isIpField } from '@/models/FieldTypes';

import { UniversalCell } from '+components/Table/Cells';
import {
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
  NumberColumnFactory,
} from '+components/Table/Columns';

export const getColumns = ({ field, labelContext }) => [
  {
    accessor: 'agg',
    Header: 'Aggregation',
    Cell: (cell) => UniversalCell(field)(cell),
    sortType: isIpField(field) ? 'ip' : 'alphanumeric',
  },
  NumberColumnFactory({
    accessor: 'agg_count',
    Header: 'Count (of 1000)',
    textAlign: 'center',
  }),
  NumberColumnFactory({
    accessor: 'packets',
    Header: 'Packets',
    textAlign: 'center',
  }),
  LabelOrIpColumnFactory({
    Header: 'Source',
    dataFieldName: 'srcip',
    labelFieldName: `label.ip.${labelContext.ip}.src`,
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    id: 'srcip_%',
    accessor: (original) => Math.round(original['srcip_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'SRC IP %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  LabelOrPortColumnFactory({
    Header: 'SRC Port',
    dataFieldName: 'srcport',
    labelFieldName: `label.port.${labelContext.port}.src`,
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    id: 'srcport_%',
    accessor: (original) => Math.round(original['srcport_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'SRC Port %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  LabelOrIpColumnFactory({
    Header: 'Destination',
    dataFieldName: 'dstip',
    labelFieldName: `label.ip.${labelContext.ip}.dst`,
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    id: 'dstip_%',
    accessor: (original) => Math.round(original['dstip_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'DST IP %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  LabelOrPortColumnFactory({
    Header: 'DST Port',
    dataFieldName: 'dstport',
    labelFieldName: `label.port.${labelContext.port}.dst`,
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    id: 'dstport_%',
    accessor: (original) => Math.round(original['dstport_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'DST Port %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'protocol',
    Header: 'Protocol',
    Cell: UniversalCell('protocol'),
  },
  NumberColumnFactory({
    id: 'protocol_%',
    accessor: (original) => Math.round(original['protocol_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Protocol %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
];
