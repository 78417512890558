import PropTypes from 'prop-types';

import { ContextTypes } from '@/models/ContextTypes';

// Custom props
const margin = PropTypes.shape({
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
});

const component = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.elementType,
]);

const children = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.arrayOf(component),
  component,
]);

/**
 * Returns a prop type that is one of the ContextTypes excluding the given types.
 * @param {Set} [excludes] - Set of ContextTypes to exclude
 * @returns {Requireable<ContextTypes>}
 */
const oneOfContext = (excludes) =>
  PropTypes.oneOf(
    Object.values(ContextTypes).filter(
      (type) => !(excludes || new Set()).has(type),
    ),
  );

export const nullFn = () => null;

// Default props
export const getDefaultMargin = () => ({
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
});

export default {
  ...PropTypes,
  margin,
  component,
  children,
  oneOfContext,
};
