import mitt from 'mitt';

import { config } from '@/config';
import authClient from '@/middleware/authClient';

export const CLOSE_CODES = {
  NORMAL: 1000, // invalid jwt
  ClOSE_TRY_AGAIN: 1013, // Try again later
  CLOSED_NO_STATUS: 1005,
  CLOSE_ABNORMAL: 1006, // nodemon reloades node.js
  DISCONNECT: 4000, // disconnect from server
};

const hookedProps = new Set([
  'close',
  'send',
  'addEventListener',
  'removeEventListener',
]);

const customEventProperties = new Map([
  ['onreconnect', 'reconnect'],
  ['onreconnecting', 'reconnecting'],
]);

/**
 * Custom socket implementation that handles reconnection.
 * It is a superset over Websocket, and includes all event handlers like
 * onmessage, onopen, etc.
 *
 * It also includes two new events: onreconnecting with info on the reconnection
 * attempt that is being made, as well as onreconnect when a reconnection is
 * successfully completed. If you're using `addListener` then these events can
 * be used through the names 'reconnecting' and 'reconnect' respectively.
 *
 * Example:
 * const ws = new NetSocket(url);
 * ws.onreconnecting = ({ delay }) => console.log(delay);
 * ws.addEventlistener = ({ attempts }) => console.log(attempts);
 * ws.onreconnect = () => console.log('Reconnect success');
 */
class NetSocket {
  constructor() {
    this.socketProps = {};
    this.initialDelay = 1000;
    this.delay = 0;
    this.attempts = 0;
    this.maxExponent = 8;

    this.open();

    this.emitter = mitt();
    this.customEvents = {};

    return new Proxy(this, {
      /**
       * Save user's socket properties being set so we can restore socket
       * later when creating a new socket for reconnect.
       */
      set(target, name, value) {
        if (customEventProperties.has(name)) {
          const eventName = customEventProperties.get(name);
          // Turn off old event (only one handler per set at a time)
          if (eventName in target.customEvents) {
            target.emitter.off(eventName, target.customEvents[eventName]);
          }
          target.emitter.on(eventName, value);
          target.customEvents[eventName] = value;
        }
        target.socketProps[name] = value;
        // Set property on underlying socket
        target.ws[name] = value;
        return true;
      },
      get(target, name) {
        // Hook all props in the Set to return this object's property
        return hookedProps.has(name) ? target[name] : target.ws[name];
      },
    });
  }

  close = (code = CLOSE_CODES.NORMAL, reason = '') => {
    this.ws.close(code, reason);
  };

  send = (msg) => this.ws.send(msg);

  addEventListener = (name, handler) => this.emitter.on(name, handler);

  removeEventListener = (name, handler) => this.emitter.off(name, handler);

  attachListeners() {
    this.ws.addEventListener('open', this.onOpen);
    this.ws.addEventListener('close', this.onClose);
    this.ws.addEventListener('message', this.onMessage);
    this.ws.addEventListener('error', this.onError);
  }

  detachListeners() {
    this.ws.removeEventListener('open', this.onOpen);
    this.ws.removeEventListener('close', this.onClose);
    this.ws.removeEventListener('message', this.onMessage);
    this.ws.removeEventListener('error', this.onError);
  }

  /**
   * Attempt to open a new connection, this will be called on init and reconnect
   */
  open = () => {
    if (this.ws) {
      this.detachListeners();
      this.ws.close();
    }
    const jwtToken = authClient.getToken();
    const url = config.appWebsocketUrlRoot;
    this.ws = new WebSocket(`${url}?access_token=${jwtToken}`);
    this.attachListeners();
    // Add all user props from expired socket back
    Object.keys(this.socketProps).forEach((k) => {
      this.ws[k] = this.socketProps[k];
    });
    this.reconnectTimeout = null;
  };

  ping = () => {
    this.lastPingToken = Math.trunc(Math.random() * 100000);
    if (this.ws) {
      this.ws.send(`{"ping":${this.lastPingToken}}`);
      // Set a handler in the case no pong is received in 5 seconds
      this.noPongTimeout = setTimeout(this.noPongReceived, 5000); //
    }
  };

  noPongReceived = () => {
    // eslint-disable-next-line no-console
    console.warn('No pong received in 5 seconds, connection is likely broken.');
    this.ws.close(CLOSE_CODES.RECONNECT);
  };

  tryReconnect() {
    if (this.reconnectTimeout) {
      return;
    }

    const randomization = Math.random() / 2 + 0.75; // Factor 0.75 - 1.25
    this.delay = Math.floor(
      randomization *
        2 ** Math.min(this.maxExponent, this.attempts) *
        this.initialDelay,
    );
    this.attempts += 1;
    this.reconnectTimeout = setTimeout(this.open, this.delay);
    this.emitter.emit('reconnecting', {
      attempts: this.attempts,
      delay: this.delay,
    });
  }

  // SOCKET EVENT HANDLERS
  // ---------------------------------------------------------------------------

  #checkStates(event) {
    if (!event?.data) {
      return;
    }

    let data = event.data.toString();

    try {
      data = JSON.parse(data);
      // eslint-disable-next-line no-empty
    } catch (error) {
      return;
    }

    if (data.event === 'server-connect') {
      this.attempts = 0;
      this.delay = 0;
      return;
    }

    if (data.pong != null) {
      if (data.pong === this.lastPingToken) {
        clearTimeout(this.noPongTimeout);
      }
    }
  }

  onMessage = (evt) => {
    this.#checkStates(evt);
    this.emitter.emit('message', evt);
  };

  /**
   * Event to set socket state when socket is connected
   */
  onOpen = (evt) => {
    if (this.attempts > 0) {
      this.emitter.emit('reconnect');
    }
    this.reconnectTimeout = null;
    this.pingInterval = setInterval(this.ping, 8000);
    this.emitter.emit('open', evt);
  };

  /**
   * Event to set socket state when socket is closed by server.
   * Try to recreate socket connection (reconnect).
   */
  onClose = (evt) => {
    clearInterval(this.pingInterval);
    clearTimeout(this.noPongTimeout);

    switch (evt.code) {
      case CLOSE_CODES.NORMAL:
        // eslint-disable-next-line no-console
        console.log(`websocket status ${evt.code}: ${evt.reason}`);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn(
          `websocket status ${evt.code}: ${
            evt.reason || 'socket closed'
          }. retry: ${this.delay}ms`,
        );
        this.tryReconnect();
        break;
    }

    this.emitter.emit('close', evt);
  };

  onError = (evt) => this.emitter.emit('error', evt);
}

export default NetSocket;
