import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 7px;
  margin-right: 10px;
  z-index: 11;
  cursor: unset;
  visibility: hidden;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.chartBackground};
  height: 21px;

  &.widget__controls-hovered {
    visibility: visible;
  }

  &.widget__chart_with-menu {
    transform: translateX(-23px);
  }

  .widget__control-icon .mdi-icon {
    width: 21px;
    height: 100%;
    padding: 3px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colorText};
    cursor: pointer;
  }
`;
