import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

export default (provider, source) => {
  const dispatch = useDispatch();
  const classification = useSelector(
    vpcSelectors.getVpcClassification(provider, source),
  );
  useEffect(() => {
    if (!classification) {
      dispatch(vpcActions.fetchVpcClassification());
    }
  }, [classification]);
  return classification;
};
