import styled from 'styled-components';

const TCPFlagBackgrounds = {
  ack: 'DeepSkyBlue',
  cwr: 'Violet',
  ece: 'Moccasin',
  fin: 'LightGreen',
  ns: 'GoldenRod',
  psh: 'OrangeRed',
  rst: 'Orange',
  syn: 'Fuchsia',
  urg: 'Tomato',
};

const TCPFlagLabel = styled.div`
  padding: 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  border-radius: 2px;
  border: 1px solid
    ${({ $flag, theme }) =>
      TCPFlagBackgrounds[$flag] || theme.tcpFlagLabelDefaultBorder};
  color: ${({ $flag, theme }) =>
    TCPFlagBackgrounds[$flag] || theme.tcpFlagLabelDefaultColor} !important;
  min-height: 19px;
  line-height: 19px;
`;

export default TCPFlagLabel;
