import PropTypes from '+prop-types';
import { useEffect, useState } from 'react';

import styled from 'styled-components';

import PlusIcon from 'mdi-react/PlusIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import Button from '+components/Button';
import { Col, LayoutSizes, Row } from '+components/Layout';
import { makeId } from '+utils/general';

import TransformTextField from '../TransformTextField';
import FieldLabel from './FieldLabel';
import MethodSelector from './MethodSelector';

// convert JSON into array for display to preserve order between updates
const dataJsonToArray = (data) =>
  Object.keys(data).map((key) => ({ key, value: data[key], id: makeId() }));
const dataArrayToJson = (dataArray) =>
  dataArray.reduce((acc, data) => {
    if (data.key.length > 0) {
      acc[data.key] = data.value;
    }
    return acc;
  }, {});

const RowButton = styled(Button)`
  width: 32px;
  min-height: 0;
  margin: 0;
  padding: 0;
  border: none;
`;
const LowerRowReplace = (props) => {
  const { disabled, data, onChange } = props;
  const [dataArray, setDataArray] = useState(dataJsonToArray(data));
  useEffect(() => {
    onChange('replace', dataArrayToJson(dataArray));
  }, [dataArray]);
  return (
    <Col gap="6px">
      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <FieldLabel $disabled={disabled}>Method</FieldLabel>
        </Col>
        <Col item xs={3.75}>
          <FieldLabel $disabled={disabled}>Src Value</FieldLabel>
        </Col>
        <Col item xs={3.75}>
          <FieldLabel $disabled={disabled}>Output</FieldLabel>
        </Col>
      </Row>

      <Row gap={LayoutSizes.groupGap}>
        {dataArray?.map((row, index) => (
          <Row key={`${row.id}`} gap={LayoutSizes.groupGap}>
            <Col item xs={2.5}>
              {index === 0 && <MethodSelector {...props} />}
            </Col>
            <Col item xs={3.65}>
              <TransformTextField
                disabled={disabled}
                fieldKey={row.key}
                defaultValue={row.key || ''}
                onChange={(_, newKey) => {
                  const copy = [...dataArray];
                  copy[index] = {
                    ...dataArray[index],
                    key: newKey,
                  };
                  setDataArray(copy);
                }}
              />
            </Col>
            <Col item xs={3.65}>
              <TransformTextField
                disabled={disabled}
                fieldKey={row.key}
                defaultValue={row.value || ''}
                onChange={(key, value) => {
                  const copy = [...dataArray];
                  copy[index] = {
                    ...dataArray[index],
                    key,
                    value,
                  };
                  setDataArray(copy);
                }}
              />
            </Col>
            <Col item xs={0.65}>
              <RowButton
                disabled={disabled || dataArray.length === 1}
                onClick={() => {
                  const copy = [...dataArray];
                  copy.splice(index, 1);
                  setDataArray(copy);
                }}
              >
                <TrashCanOutlineIcon size={16} />
              </RowButton>
            </Col>
            <Col item xs={0.65}>
              {index === dataArray.length - 1 && (
                <RowButton
                  disabled={disabled}
                  onClick={() => {
                    const copy = [...dataArray];
                    copy.push({ key: '', val: '', id: makeId() });
                    setDataArray(copy);
                  }}
                >
                  <PlusIcon size={18} />
                </RowButton>
              )}
            </Col>
          </Row>
        ))}
      </Row>
    </Col>
  );
};

LowerRowReplace.propTypes = {
  data: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LowerRowReplace;
