import PropTypes from '+prop-types';
import { forwardRef, memo, useCallback } from 'react';

import ScrollBar from '+components/ScrollBar/smooth';

const ScrollbarList = memo(
  forwardRef((props, ref) => {
    const { style, onScroll, children } = props;

    const refSetter = useCallback(
      (scrollbar) => {
        if (ref) {
          ref(scrollbar?.containerEl);
        }
      },
      [ref],
    );

    const doScroll = useCallback(
      (_, scrollbar) =>
        onScroll?.({
          currentTarget: {
            clientHeight: scrollbar.size.container.height,
            scrollHeight: scrollbar.size.content.height,
            scrollTop: scrollbar.scrollTop,
          },
        }),
      [onScroll],
    );

    return (
      <ScrollBar
        ref={refSetter}
        style={{
          ...style,
          overflow: 'hidden',
        }}
        onScroll={doScroll}
      >
        {children}
      </ScrollBar>
    );
  }),
);

ScrollbarList.displayName = 'ScrollbarList';

ScrollbarList.propTypes = {
  style: PropTypes.shape(),
  children: PropTypes.children,
  onScroll: PropTypes.func,
};

ScrollbarList.defaultProps = {
  style: null,
  children: null,
  onScroll: null,
};

export default ScrollbarList;
