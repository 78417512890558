import PropTypes from '+prop-types';
import { forwardRef, useEffect, useRef } from 'react';
import { Field as FinalField, useField, useForm } from 'react-final-form';
import { useDebounce } from 'react-use';

import useEvent from '+hooks/useEvent';

const empty = [];

const Field = forwardRef((props, ref) => {
  const {
    validate,
    name,
    onChange,
    validationDebounceTimeout,
    disabled,
    ...tail
  } = props;

  const form = useForm();
  const { input, meta } = useField(name, {
    subscription: { active: true, value: true },
  });
  const lastValue = useRef(input.value);

  const normalizedValidate = useEvent((...args) => {
    if (!Array.isArray(validate)) {
      return typeof validate === 'function' && validate(...args);
    }

    let error = null;

    validate.some((fn) => {
      if (typeof fn !== 'function') {
        return false;
      }

      error = fn(...args);

      return error != null;
    });

    return error;
  });

  const clearTimeoutRef = useRef();
  const debouncedValidate = useEvent((...args) => {
    if (typeof clearTimeoutRef.current === 'function') {
      clearTimeoutRef.current();
      clearTimeoutRef.current = null;
    }

    if (!validationDebounceTimeout) {
      return normalizedValidate(...args);
    }

    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        clearTimeoutRef.current = null;
        resolve(normalizedValidate(...args));
      }, validationDebounceTimeout);

      clearTimeoutRef.current = (needResolve = true) => {
        clearTimeout(timer);
        clearTimeoutRef.current = null;
        if (needResolve) {
          resolve();
        }
      };
    });
  });

  useDebounce(
    () => {
      if (name) {
        form.resetFieldState(name);
      }
    },
    100,
    [disabled, normalizedValidate, name],
  );

  useEffect(() => {
    if (
      meta.active &&
      lastValue.current !== input.value &&
      typeof onChange === 'function'
    ) {
      onChange(input.value, lastValue.current, input.name);
    }

    lastValue.current = input.value;
  }, [input.value, meta.active]);

  useEffect(
    () => () => {
      clearTimeoutRef.current?.(false);
    },
    [],
  );

  return (
    <FinalField
      ref={ref}
      validateFields={empty}
      disabled={disabled}
      {...tail}
      name={name}
      validate={disabled ? undefined : debouncedValidate}
    />
  );
});

Field.propTypes = {
  name: PropTypes.string.isRequired,
  /**
   * Validators for field, can be array or function and should be memorized.
   */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.bool])),
  ]),
  /**
   * validation debounce timeout
   */
  validationDebounceTimeout: PropTypes.number,

  disabled: PropTypes.bool,

  onChange: PropTypes.func,
};

Field.defaultProps = {
  validate: null,
  onChange: null,
  validationDebounceTimeout: null,
  disabled: false,
};

export default Field;
