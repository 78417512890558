import styled from 'styled-components';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const MainRowNqlGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  height: 36px;
  max-height: 36px;
  width: 100%;
  border-radius: 18px;
  padding: 2px 4px 2px 2px;
  overflow: hidden;

  &.active:not(.disabled),
  &:focus:not(.disabled),
  &:hover:not(.disabled),
  &:active:not(.disabled) {
    background: ${globalFiltersTheme.mainRowNqlGroupHoverBackground};
    height: fit-content;
    max-height: fit-content;
    overflow: visible;
    z-index: 999;

    .nql-main-container {
      max-height: unset;
    }

    // &:hover,
    // &:has(textarea:focus) {
    //   box-shadow: 0 4px 4px 0 ${globalFiltersTheme.mainRowNqlGroupHoverShadow};
    //
    //   &.invalid {
    //     box-shadow:
    //       inset 0 0 0 1px ${({ theme }) => theme.Palette.danger},
    //       0 4px 4px 0 ${globalFiltersTheme.mainRowNqlGroupHoverShadow};
    //   }
    // }
  }

  &.invalid {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.Palette.danger};
  }

  &.disabled {
    background: transparent;
    box-shadow: none;
    opacity: 0.5;
    * {
      user-select: none !important;
      pointer-events: none !important;
    }
  }
`;

export default MainRowNqlGroup;
