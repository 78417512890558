import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import { CustomType } from '@/models/CustomType';
import { TimePeriods } from '@/models/TimePeriods';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';
import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import { Dropdown as DropdownOrigin } from '+components/Dropdown';
import { usePageTabs } from '+components/PageTabs';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';
import dayjs, { DateFormat } from '+utils/dayjs';
import { timeBounds } from '+utils/timeBounds';

const Dropdown = styled(DropdownOrigin).attrs({
  showIcon: false,
  menuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    PaperProps: {
      style: {
        transform: 'translateY(5px)',
      },
    },
  },
})`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 36px;
  max-height: 36px;
  width: fit-content;
  border-radius: 18px;
  padding: 0 12px;
  overflow: hidden;
  z-index: 99;

  border: unset;

  --background: ${({ theme, $dirty }) =>
    $dirty ? theme.colorTool.alpha(theme.primary, 0.2) : 'transparent'};

  background-color: var(--background) !important;

  text-transform: none !important;

  &.active,
  &:hover {
    background-color: ${globalFiltersTheme.mainRowNqlGroupHoverBackground} !important;
    //box-shadow: 0 4px 4px 0 ${globalFiltersTheme.mainRowNqlGroupHoverShadow} !important;
  }

  &[disabled] {
    color: ${({ theme }) => theme.iconButtonInsideComponentsText} !important;
    background-color: transparent !important;
    opacity: 0.3 !important;
  }

  &.inactive {
    pointer-events: none;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  max-height: 32px;
`;

const ItemLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  height: 12px;
  max-height: 12px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const ItemValue = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 13px;
  white-space: nowrap;
  user-select: none;
  text-transform: none;
  font-weight: normal !important;
  flex: 1 1 0;

  div {
    display: flex;
    align-items: center;
    height: 20px;
  }
`;

const DateTimeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  gap: 15px;
  min-width: 380px;
`;

const DateTimeMenuLabel = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
`;

const DateTimeMenuRow = styled.div`
  display: flex;
  flex-direction: row;

  &.datetime-absolute-fields {
    margin-top: 5px;
    gap: 15px;
  }

  &.datetime-relative {
    gap: 10px;
    > .form__form-group {
      max-width: 25%;
    }
    > .form__form-group ~ .form__form-group {
      max-width: unset;
    }
  }

  &.datetime-relative + .datetime-quick {
    margin-top: 10px;
  }

  & + .toggle {
    margin-top: 10px;
  }

  &.datetime-quick {
    justify-content: space-between;

    .MuiButton-link {
      height: 24px;
      width: 88px;
      min-width: 88px;
      justify-content: flex-start;
    }
  }

  .form__form-group {
    margin-bottom: 0 !important;
  }
`;

const DateTimeMenuCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const DateTimeDropdown = (props) => {
  const {
    values,
    onlyRealtime,
    children,
    disabled = false,
    dirty = false,
    ...tail
  } = props;

  const [, activePageTab] = usePageTabs();
  const retention = useSelector(customerSelectors.getRetention);
  const isSocketPaused = useSelector(
    socketControlSelectors.isPaused(activePageTab?.id),
  );
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );

  const caption = useMemo(() => {
    if (!isSocketPaused) {
      return (
        <ItemContainer>
          <ItemValue>REAL-TIME</ItemValue>
        </ItemContainer>
      );
    }

    if (onlyRealtime) {
      return (
        <ItemContainer>
          <ItemValue>REAL-TIME</ItemValue>
        </ItemContainer>
      );
    }

    if (values.period?.type === CustomType) {
      const { start, end } = timeBounds(values);
      return (
        <ItemContainer>
          {autoRefresh && <ItemLabel>Auto-refresh is on</ItemLabel>}
          <ItemValue>
            <div>
              {values.startIsMin
                ? `-${retention} DAYS`
                : dayjs(start).format(DateFormat.second)}
            </div>
            <div>
              <ChevronRightIcon size={18} />
            </div>
            <div>
              {values.endIsNow ? 'NOW' : dayjs(end).format(DateFormat.second)}
            </div>
          </ItemValue>
        </ItemContainer>
      );
    }

    const timePeriod = TimePeriods[values.period?.type];
    if (timePeriod) {
      return (
        <ItemContainer>
          {autoRefresh && <ItemLabel>Auto-refresh is on</ItemLabel>}
          <ItemValue>
            <div>Last</div>
            <div>{values.period.value}</div>
            <div>
              {values.period.value > 1
                ? timePeriod.unit
                : timePeriod.unit.slice(0, -1)}
            </div>
          </ItemValue>
        </ItemContainer>
      );
    }

    return null;
  }, [isSocketPaused, values, retention, onlyRealtime, autoRefresh]);

  return (
    <Dropdown
      {...tail}
      caption={caption}
      $dirty={!disabled && dirty}
      disabled={disabled}
    >
      {children}
    </Dropdown>
  );
};

DateTimeDropdown.propTypes = {
  values: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
    period: PropTypes.shape(),
    startIsMin: PropTypes.bool,
    endIsNow: PropTypes.bool,
  }).isRequired,
  onlyRealtime: PropTypes.bool.isRequired,
  children: PropTypes.children.isRequired,
  disabled: PropTypes.bool,
  dirty: PropTypes.bool,
};
DateTimeDropdown.defaultProps = {
  disabled: false,
  dirty: false,
};

export {
  DateTimeMenuContainer,
  DateTimeMenuRow,
  DateTimeMenuCol,
  DateTimeMenuLabel,
};

export default DateTimeDropdown;
