import PropTypes from '+prop-types';
import { Fragment } from 'react';

import FieldsSection from '+components/form/FieldsSection';
import Field from '+components/form/FinalForm/Field';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import NumberField from '+components/form/NumberField';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

import { MAX_JS_DIGITS } from './constants';

// factors for this block integration are limited to srcip and dstip
const factors = ['srcip', 'dstip'];

const PluginCrowdstrike = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />
    <Field
      name="config.url"
      label="API URL"
      component={TextField}
      type="text"
      disabled={disabled}
      required
      validate={validateRequired}
    />
    <Field
      name="config.factors"
      label="Factors"
      component={SelectField}
      options={factors}
      parse={normalizeMultiSelectValue}
      validate={validateRequired}
      disabled={disabled}
      required
    />
    <Field
      name="config.expiration"
      label="Expiration"
      component={NumberField}
      autoComplete="new-password"
      min={0}
      max={MAX_JS_DIGITS}
      style={{ width: '50%' }}
      helperText="Number of seconds the blocklist will remain active"
      disabled={disabled}
    />
    <Field
      name="config.max"
      label="Max"
      component={NumberField}
      autoComplete="new-password"
      min={0}
      max={MAX_JS_DIGITS}
      style={{ width: '50%' }}
      helperText="Limit on number of blocks"
      disabled={disabled}
    />
    <FieldsSection label="Authentication">
      <Field
        name="config.client_id"
        label="Client ID"
        component={TextField}
        type="text"
        disabled={disabled}
        required
        validate={validateRequired}
      />
      <Field
        name="config.client_secret"
        label="Client Secret"
        component={TextField}
        type="password"
        disabled={disabled}
        required
        validate={validateRequired}
      />
    </FieldsSection>
  </Fragment>
);

PluginCrowdstrike.propTypes = {
  disabled: PropTypes.bool,
};

PluginCrowdstrike.defaultProps = {
  disabled: false,
};

export default PluginCrowdstrike;
