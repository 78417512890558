// original from node_modules/react-table/src/plugin-hooks/useRowSelect.js

import { useCallback, useMemo } from 'react';
import {
  actions,
  ensurePluginOrder,
  makePropGetter,
  useGetLatest,
  useMountedLayoutEffect,
} from 'react-table';

const pluginName = 'useRowSelect';

// Actions
actions.resetSelectedRows = 'resetSelectedRows';
actions.validateSelectedRows = 'validateSelectedRows';
actions.toggleAllRowsSelected = 'toggleAllRowsSelected';
actions.toggleRowSelected = 'toggleRowSelected';
actions.toggleAllPageRowsSelected = 'toggleAllPageRowsSelected';

const getRowId = (row) => row.virtualRowId || row.id;

const defaultGetToggleRowSelectedProps = (props, { instance, row }) => {
  const { manualRowSelectedKey = 'isSelected' } = instance;
  let checked;

  if (row.original && row.original[manualRowSelectedKey]) {
    checked = true;
  } else {
    checked = row.isSelected;
  }

  return [
    props,
    {
      onChange: (e) => {
        row.toggleRowSelected(e.target.checked);
      },
      style: {
        cursor: 'pointer',
      },
      checked,
      title: 'Toggle Row Selected',
      indeterminate: row.isSomeSelected,
      disabled: row.isSelectorDisabled,
    },
  ];
};

const defaultGetToggleAllRowsSelectedProps = (props, { instance }) => [
  props,
  {
    onChange: (e) => {
      instance.toggleAllRowsSelected(e.target.checked);
    },
    style: {
      cursor: 'pointer',
    },
    checked: instance.isAllRowsSelected,
    title: 'Toggle All Rows Selected',
    indeterminate: Boolean(
      !instance.isAllRowsSelected &&
        Object.keys(instance.state.selectedRowIds).length,
    ),
    disabled: instance.isAllRowsSelectorDisabled,
  },
];

const defaultGetToggleAllPageRowsSelectedProps = (props, { instance }) => [
  props,
  {
    onChange(e) {
      instance.toggleAllPageRowsSelected(e.target.checked);
    },
    style: {
      cursor: 'pointer',
    },
    checked: instance.isAllPageRowsSelected,
    title: 'Toggle All Current Page Rows Selected',
    indeterminate: Boolean(
      !instance.isAllPageRowsSelected &&
        instance.page.some(({ id }) => instance.state.selectedRowIds[id]),
    ),
    disabled: instance.isAllPageRowsSelectorDisabled,
  },
];

const isRowDisabled = (row, isDisabled) => {
  if (!row) {
    return false;
  }

  return isDisabled?.(row) ?? false;
};

const reducer = (state, action, previousState, instance) => {
  if (action.type === actions.init) {
    return {
      selectedRowIds: {},
      ...state,
    };
  }

  if (action.type === actions.resetSelectedRows) {
    return {
      ...state,
      selectedRowIds: instance.initialState.selectedRowIds || {},
    };
  }

  if (action.type === actions.toggleAllRowsSelected) {
    const { value: setSelected } = action;
    const {
      isAllRowsSelected,
      rowsById,
      nonGroupedRowsById = rowsById,
      getIsRowSelectorDisabled,
    } = instance;

    const selectAll =
      typeof setSelected !== 'undefined' ? setSelected : !isAllRowsSelected;

    // Only remove/add the rows that are visible on the screen
    //  Leave all the other rows that are selected alone.
    const selectedRowIds = { ...state.selectedRowIds };

    if (selectAll) {
      Object.keys(nonGroupedRowsById).forEach((rowId) => {
        if (
          isRowDisabled(nonGroupedRowsById[rowId], getIsRowSelectorDisabled)
        ) {
          return;
        }

        selectedRowIds[rowId] = true;
      });
    } else {
      Object.keys(nonGroupedRowsById).forEach((rowId) => {
        delete selectedRowIds[rowId];
      });
    }

    return {
      ...state,
      selectedRowIds,
    };
  }

  if (action.type === actions.toggleRowSelected) {
    const { id, value: setSelected } = action;
    const {
      rowsById,
      selectSubRows = true,
      getSubRows,
      getIsRowSelectorDisabled,
    } = instance;
    const isSelected = state.selectedRowIds[id];
    const shouldExist =
      typeof setSelected !== 'undefined' ? setSelected : !isSelected;

    if (
      isSelected === shouldExist ||
      isRowDisabled(rowsById[id], getIsRowSelectorDisabled)
    ) {
      return state;
    }

    const newSelectedRowIds = { ...state.selectedRowIds };

    const handleRowById = (rowId) => {
      const row = rowsById[rowId];

      if (!row) {
        return;
      }

      if (!row.isGrouped) {
        if (shouldExist) {
          if (isRowDisabled(row, getIsRowSelectorDisabled)) {
            return;
          }

          newSelectedRowIds[rowId] = true;
        } else {
          delete newSelectedRowIds[rowId];
        }
      }

      if (selectSubRows) {
        (getSubRows(row) || []).forEach((subRow) =>
          handleRowById(getRowId(subRow)),
        );
      }
    };

    handleRowById(id);

    return {
      ...state,
      selectedRowIds: newSelectedRowIds,
    };
  }

  if (action.type === actions.toggleAllPageRowsSelected) {
    const { value: setSelected } = action;
    const {
      page,
      rowsById,
      selectSubRows = true,
      isAllPageRowsSelected,
      getIsRowSelectorDisabled,
      getSubRows,
    } = instance;

    const selectAll =
      typeof setSelected !== 'undefined' ? setSelected : !isAllPageRowsSelected;

    const newSelectedRowIds = { ...state.selectedRowIds };

    const handleRowById = (id) => {
      const row = rowsById[id];

      if (!row) {
        return;
      }

      if (!row.isGrouped) {
        if (selectAll) {
          if (isRowDisabled(row, getIsRowSelectorDisabled)) {
            return;
          }

          newSelectedRowIds[id] = true;
        } else {
          delete newSelectedRowIds[id];
        }
      }

      if (selectSubRows && getSubRows(row)) {
        getSubRows(row).forEach((subRow) => handleRowById(getRowId(subRow)));
      }
    };

    page.forEach((row) => handleRowById(getRowId(row)));

    return {
      ...state,
      selectedRowIds: newSelectedRowIds,
    };
  }

  if (action.type === actions.validateSelectedRows) {
    const {
      rowsById,
      nonGroupedRowsById = rowsById,
      getIsRowSelectorDisabled,
    } = instance;

    const newSelectedRowIds = { ...state.selectedRowIds };
    let hasChanges = false;

    Object.keys(newSelectedRowIds).forEach((rowId) => {
      if (
        !nonGroupedRowsById[rowId] ||
        isRowDisabled(nonGroupedRowsById[rowId], getIsRowSelectorDisabled)
      ) {
        hasChanges = true;
        delete newSelectedRowIds[rowId];
      }
    });

    return {
      ...state,
      ...(hasChanges ? { selectedRowIds: newSelectedRowIds } : {}),
    };
  }

  return state;
};

const getRowIsSelected = (row, selectedRowIds, getSubRows) => {
  if (selectedRowIds[getRowId(row)]) {
    return true;
  }

  const subRows = getSubRows(row);

  if (subRows && subRows.length) {
    let allChildrenSelected = true;
    let someSelected = false;

    subRows.forEach((subRow) => {
      // Bail out early if we know both of these
      if (someSelected && !allChildrenSelected) {
        return;
      }

      if (getRowIsSelected(subRow, selectedRowIds, getSubRows)) {
        someSelected = true;
      } else {
        allChildrenSelected = false;
      }
    });

    if (allChildrenSelected) {
      return true;
    }

    return someSelected ? null : false;
  }

  return false;
};

const getSelectedFlatRows = (props) => {
  const { rows, selectSubRows, selectedRowIds, getSubRows } = props;
  return rows.reduce((result, row) => {
    const isSelected = selectSubRows
      ? getRowIsSelected(row, selectedRowIds, getSubRows)
      : !!selectedRowIds[getRowId(row)];
    row.isSelected = !!isSelected;
    row.isSomeSelected = isSelected === null;

    if (isSelected) {
      result.push(row);
    }

    // If the row has subRows, call the function recursively
    const subRows = getSubRows(row);
    if (subRows && subRows.length > 0) {
      result = result.concat(
        getSelectedFlatRows({
          rows: subRows,
          selectSubRows,
          selectedRowIds,
          getSubRows,
        }),
      );
    }

    return result;
  }, []);
};

function useInstance(instance) {
  const {
    data,
    rows,
    getHooks,
    plugins,
    rowsById,
    nonGroupedRowsById = rowsById,
    autoResetSelectedRows = true,
    state: { selectedRowIds },
    selectSubRows = true,
    dispatch,
    page,
    getSubRows,
    getIsRowSelectorDisabled,
  } = instance;

  ensurePluginOrder(
    plugins,
    ['useFilters', 'useGroupBy', 'useSortBy', 'useExpanded', 'usePagination'],
    pluginName,
  );

  const selectedFlatRows = useMemo(
    () =>
      getSelectedFlatRows({
        rows,
        selectSubRows,
        selectedRowIds,
        getSubRows,
      }),
    [rows, selectSubRows, selectedRowIds, getSubRows],
  );

  const disabledSelectors = useMemo(() => {
    const result = {};

    rows.forEach((row) => {
      row.isSelectorDisabled = isRowDisabled(row, getIsRowSelectorDisabled);

      if (row.isSelectorDisabled) {
        result[getRowId(row)] = true;
      }
    });

    return result;
  }, [rows, getIsRowSelectorDisabled]);

  const keysNonGroupedRowsById = Object.keys(nonGroupedRowsById);

  let isAllRowsSelected = !!(
    keysNonGroupedRowsById.length && Object.keys(selectedRowIds).length
  );

  let isAllPageRowsSelected = isAllRowsSelected;

  if (isAllRowsSelected) {
    if (
      keysNonGroupedRowsById.some(
        (id) => !selectedRowIds[id] && !disabledSelectors[id],
      )
    ) {
      isAllRowsSelected = false;
    }
  }

  if (!isAllRowsSelected) {
    if (
      page?.length &&
      page.some(({ id }) => !selectedRowIds[id] && !disabledSelectors[id])
    ) {
      isAllPageRowsSelected = false;
    }
  }

  let isAllRowsSelectorDisabled = !!(
    !keysNonGroupedRowsById.length || Object.keys(disabledSelectors).length
  );

  let isAllPageRowsSelectorDisabled = isAllRowsSelectorDisabled;

  if (isAllRowsSelectorDisabled) {
    if (keysNonGroupedRowsById.some((id) => !disabledSelectors[id])) {
      isAllRowsSelectorDisabled = false;
    }
  }

  if (!isAllRowsSelectorDisabled) {
    if (page?.length && page.some(({ id }) => !disabledSelectors[id])) {
      isAllPageRowsSelectorDisabled = false;
    }
  }

  const getAutoResetSelectedRows = useGetLatest(autoResetSelectedRows);

  useMountedLayoutEffect(() => {
    if (getAutoResetSelectedRows()) {
      dispatch({ type: actions.resetSelectedRows });
    }
  }, [dispatch, data]);

  useMountedLayoutEffect(() => {
    if (!getAutoResetSelectedRows()) {
      dispatch({ type: actions.validateSelectedRows });
    }
  }, [dispatch, data]);

  const toggleAllRowsSelected = useCallback(
    (value) => dispatch({ type: actions.toggleAllRowsSelected, value }),
    [dispatch],
  );

  const toggleAllPageRowsSelected = useCallback(
    (value) => dispatch({ type: actions.toggleAllPageRowsSelected, value }),
    [dispatch],
  );

  const toggleRowSelected = useCallback(
    (id, value) => dispatch({ type: actions.toggleRowSelected, id, value }),
    [dispatch],
  );

  const getInstance = useGetLatest(instance);

  const getToggleAllRowsSelectedProps = makePropGetter(
    getHooks().getToggleAllRowsSelectedProps,
    { instance: getInstance() },
  );

  const getToggleAllPageRowsSelectedProps = makePropGetter(
    getHooks().getToggleAllPageRowsSelectedProps,
    { instance: getInstance() },
  );

  Object.assign(instance, {
    selectedFlatRows,
    isAllRowsSelected,
    isAllPageRowsSelected,
    isAllRowsSelectorDisabled,
    isAllPageRowsSelectorDisabled,
    toggleRowSelected,
    toggleAllRowsSelected,
    toggleAllPageRowsSelected,
    getToggleAllRowsSelectedProps,
    getToggleAllPageRowsSelectedProps,
  });
}

const prepareRow = (row, { instance }) => {
  row.toggleRowSelected = (set) =>
    instance.toggleRowSelected(getRowId(row), set);

  row.getToggleRowSelectedProps = makePropGetter(
    instance.getHooks().getToggleRowSelectedProps,
    { instance, row },
  );
};

export const useRowSelect = (hooks) => {
  hooks.getToggleRowSelectedProps = [defaultGetToggleRowSelectedProps];
  hooks.getToggleAllRowsSelectedProps = [defaultGetToggleAllRowsSelectedProps];
  hooks.getToggleAllPageRowsSelectedProps = [
    defaultGetToggleAllPageRowsSelectedProps,
  ];
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

useRowSelect.pluginName = pluginName;
