import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  whitelists: null, // {}
};

const apiPath = '/allowlists';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'whitelists',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchAllowlists: startFetching,
    fetchAllowlistsSuccess(state, { payload: whitelists }) {
      stopFetching(state);
      state.whitelists = (whitelists || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    // fetchWhitelist: startFetching,
    // fetchWhitelistSuccess(state, { payload: whitelist }) {
    //   stopFetching(state);
    //   state.whitelists[whitelist.id] = whitelist;
    // },

    createAllowlist: startFetching,
    createAllowlistSuccess(state, { payload: whitelist }) {
      stopFetching(state);
      if (!state.whitelists) {
        state.whitelists = {};
      }
      state.whitelists[whitelist.id] = whitelist;
    },

    updateAllowlist: startFetching,
    updateAllowlistSuccess(state, { payload: whitelist }) {
      stopFetching(state);
      if (!state.whitelists) {
        state.whitelists = {};
      }
      state.whitelists[whitelist.id] = whitelist;
    },

    addIpToAllowlist: startFetching,
    addIpToAllowlistSuccess(state, { payload: whitelist }) {
      stopFetching(state);
      if (!state.whitelists) {
        state.whitelists = {};
      }
      state.whitelists[whitelist.id] = whitelist;
    },

    deleteAllowlist: startFetching,
    deleteAllowlistSuccess(state, { payload: id }) {
      stopFetching(state);
      delete state.whitelists?.[id];
    },
    bulkDeleteAllowlists: startFetching,
    bulkDeleteAllowlistsSuccess(state, { payload: ids }) {
      stopFetching(state);
      ids.forEach((id) => delete state.whitelists?.[id]);
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchAllowlists]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const {
            data: { data },
          } = yield call(api.get, apiPath);
          yield put(actions.fetchAllowlistsSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching IP trust lists',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createAllowlist]: {
      *saga({ payload: whitelist }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, whitelist);
          yield put(actions.createAllowlistSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP trust list has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating IP trust list',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateAllowlist]: {
      *saga({ payload: whitelist }) {
        initApi();

        try {
          const { id, ...rest } = whitelist;
          const response = yield call(api.put, `${apiPath}/${id}`, rest);
          yield put(actions.updateAllowlistSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP trust list has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating IP trust list',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteAllowlist]: {
      *saga({ payload: whitelist }) {
        initApi();

        try {
          const { id } = whitelist;
          const response = yield call(api.delete, `${apiPath}/${id}`);
          yield put(actions.deleteAllowlistSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP trust list has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting IP trust list',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteAllowlists]: {
      *saga({ payload: ids }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/bulkdelete`, {
            ids,
          });
          yield put(actions.bulkDeleteAllowlistsSuccess(ids));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP trust lists have been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting IP trust lists',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addIpToAllowlist]: {
      *saga({ payload: { id, name, ip } }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${id}/${ip}`);
          if (!response.data.data) {
            yield put(actions.skip());
            yield put(
              toastActions.info(
                `IP ${ip} already exists in ${name} IP trust list`,
              ),
            );
            return;
          }
          yield put(actions.addIpToAllowlistSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `IP ${ip} has been added to ${name} IP trust list`,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating IP trust list',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getAllowlists: createSelector([getState], (state) => state.whitelists),

    getAllowlist: (id) =>
      createSelector([getState], (state) => state.whitelists?.[id]),
  }),
});

export const { actions, selectors } = slice;

export default slice;
