import scrollIntoView from 'scroll-into-view-if-needed';

/**
 * @callback CriteriaCallback
 * @param {HTMLElement} node
 * @return {boolean}
 */

/**
 * Recursively crawls up the DOM tree until an element that meets a particular criteria is found.
 * Returns the desired element or false. Stops at the body element.
 * @param {HTMLElement} node Element to crawl from
 * @param {CriteriaCallback} criteria Function to check if element has been found
 *
 * @return {boolean|HTMLElement}
 */
export const crawlDomTree = (node, criteria) => {
  if (typeof criteria !== 'function') {
    throw TypeError('criteria must be a function');
  }

  if (!node) {
    return false;
  }

  if (node.parentElement === document.body) {
    return false;
  }

  if (!criteria(node) && node.parentElement) {
    return crawlDomTree(node.parentElement, criteria);
  }

  return node;
};

/**
 * Checks to see if a current element is inside another element.
 * Useful for when, for example, a click target is inside a particular parent element.
 * @param {HTMLElement} current
 * @param {HTMLElement} element
 *
 * @return {boolean}
 */
export const isInElement = (current, element) => {
  return !!crawlDomTree(current, (el) => el === element);
};

/**
 * Check if element is scrolled into view of scrolling element vertically
 * @param {HTMLElement} scroller element with scrollbar
 * @param {HTMLElement} element element to check view status
 *
 * @return {boolean}
 */
export const isScrolledIntoView = (scroller, element) => {
  const { scrollTop } = scroller;
  const scrollBottom = scrollTop + scroller.offsetHeight;
  const elementTop = element.offsetTop;
  const elementBottom = elementTop + element.offsetHeight;

  return elementBottom <= scrollBottom && elementTop >= scrollTop;
};

/**
 * @enum {string}
 */
export const ElementPosition = {
  start: 'start',
  center: 'center',
  end: 'end',
  nearest: 'nearest',
};

/**
 * Scroll an element to it's child index
 * @param {HTMLElement} element
 * @param {Number} childIndex
 * @param {ElementPosition} position The position of the element's scroll view the child should be placed
 */
export const scrollToChildIndex = (
  element,
  childIndex,
  position = ElementPosition.nearest,
) => {
  if (!element) {
    return;
  }

  const child = element.children[childIndex];
  if (!child) {
    return;
  }

  // [16.02.2021] using library because safari and firefox don't support the new spec completely.
  scrollIntoView(child, {
    scrollMode: 'if-needed',
    block: position,
    inline: 'nearest',
  });
};

/**
 * Promisified setTimeout
 * @param {Number} time time in milliseconds
 *
 * @return {Promise}
 */
export const wait = (time) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

/**
 * Get canvas context or throw error if not supported
 * @param {HTMLCanvasElement} canvas
 * @param {string} contextId can be 2d, bitmaprenderer, webgl, webgl2
 * @param {
 *   CanvasRenderingContext2DSettings
 *    | ImageBitmapRenderingContextSettings
 *    | WebGLContextAttributes
 * } [options]
 *
 * @return {
 *   CanvasRenderingContext2D
 *    | ImageBitmapRenderingContextSettings
 *    | WebGLRenderingContext
 *    | WebGL2RenderingContext
 * }
 */
export function getContext(canvas, contextId, options) {
  const context = canvas.getContext(contextId, options);

  if (!context) {
    throw Error(`Canvas type "${contextId}" not supported`);
  }

  return context;
}
