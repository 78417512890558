import nqlLang from '+utils/nqlLang';

export const getStreamNql = (data, fullStreamOnly = true) => {
  const { srcip, srcport, dstip, dstport, protocol } = data || {};

  const isFullStream = srcip && dstip && protocol;
  if (!isFullStream && fullStreamOnly) {
    return undefined;
  }

  // if srcport or dstport == 0 or null or empty, then omit from the NQL
  const stream = {
    in: {},
    out: {},
  };

  if (srcip) {
    stream.in.srcip = srcip;
    stream.out.dstip = srcip;
  }

  if (srcport) {
    stream.in.srcport = srcport;
    stream.out.dstport = srcport;
  }

  if (dstip) {
    stream.in.dstip = dstip;
    stream.out.srcip = dstip;
  }

  if (dstport) {
    stream.in.dstport = dstport;
    stream.out.srcport = dstport;
  }

  const nql = nqlLang.or(
    nqlLang.and(
      ...Object.entries(stream.in).map(([key, value]) =>
        nqlLang.equal(key, value),
      ),
    ),
    fullStreamOnly
      ? nqlLang.and(
          ...Object.entries(stream.out).map(([key, value]) =>
            nqlLang.equal(key, value),
          ),
        )
      : '',
  );

  if (!protocol) {
    return nql;
  }

  return nqlLang.and(nql, nqlLang.equal('protocol', protocol));
};
