import { TimeDuration } from '@/models/TimePeriods';

export const intervalToMilliseconds = (interval) => {
  const reg = /\d+([a-z]+)/;

  let [, symbol] = (interval || '').match(reg) || [];

  symbol = symbol || 'm';
  const duration = Math.max(1, parseInt(interval, 10) || 0);

  switch (symbol) {
    case 's':
      return duration * TimeDuration.second;
    case 'h':
      return duration * TimeDuration.hour;
    case 'd':
      return duration * TimeDuration.day;
    case 'M':
      return duration * TimeDuration.month;
    default:
      return duration * TimeDuration.minute;
  }
};
