import dayjs from '+utils/dayjs';

const parseChartMetricFromOperation = (operation) => {
  const countFields = [
    'cnt',
    'count',
    'avg',
    'average',
    'div',
    'divide',
    'min',
    'max',
    'product',
    'multiply',
  ];
  if (operation) {
    if (countFields.some((field) => operation.includes(field))) {
      return 'counts';
    }
    if (operation.includes('bitsrate') || operation.includes('bitsxrate')) {
      return 'bitrate';
    }
    if (operation.includes('bits')) {
      return 'bits';
    }
    if (
      operation.includes('packetsrate') ||
      operation.includes('packetsxrate')
    ) {
      return 'packetrate';
    }
    if (operation.includes('packet')) {
      return 'packets';
    }
  }
  return 'counts';
};

const compare = ([a], [b]) => a - b;

const order = {
  low: 3,
  medium: 4,
  high: 5,
};

const sortLevels = ([a], [b]) => order[a] - order[b];

export const convertThresholdsValuesToSeries = (data, level) => {
  const thresholdValues = {};

  // we expect all threshold values to be of the same metric:
  const metric = parseChartMetricFromOperation(data?.[0]?.operation);

  data.forEach((obj) => {
    obj.threshold.forEach((threshold) => {
      if (!thresholdValues[threshold.level]) {
        thresholdValues[threshold.level] = [];
      }

      thresholdValues[threshold.level].push([
        +dayjs(obj.timestamp),
        threshold.value,
      ]);
    });
  });

  const result = Object.entries(thresholdValues)
    .sort(sortLevels)
    .map(([name, values]) => ({
      name,
      metric,
      colorIndex: order[name],
      data: values.sort(compare),
    }));

  if (level) {
    return result.find((item) => item.name === level);
  }

  return result;
};
