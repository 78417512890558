import cloneDeep from 'lodash.clonedeep';
import isFunction from 'lodash.isfunction';
import omit from 'lodash.omit';
import { all, call, delay, put, select } from 'redux-saga/effects';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as portalSettingsActions,
  selectors as portalSettingsSelectors,
} from '@/redux/api/portalSettings';
import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  isCancelled,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const MAX_PINNED_DASHBOARDS = 10;
const MAX_DASHBOARD_RECENT_LIST = 10;
const _addDashboardToRecent = (prevRecent, id) => {
  const nextRecent = cloneDeep(prevRecent);
  const index = nextRecent.findIndex((item) => item.id === id);
  const timestamp = +new Date();
  if (index === -1) {
    nextRecent.push({ id, timestamp });
  } else {
    nextRecent[index].timestamp = timestamp;
  }
  return nextRecent
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, MAX_DASHBOARD_RECENT_LIST);
};

const _removeDashboardFromRecent = (prevRecent, id) => {
  const nextRecent = cloneDeep(prevRecent);
  const index = nextRecent.findIndex((item) => item.id === id);
  if (index !== -1) {
    nextRecent.splice(index, 1);
  }
  return nextRecent
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, MAX_DASHBOARD_RECENT_LIST);
};

const initialState = {
  isFetching: false,
  isAllMetaFetched: false,
  dashboardsMeta: {},
  dashboards: {},
  isLayoutUpdating: false,
  layoutError: '',
  createdDashboardId: null,
  schedules: {},
};

const apiPath = '/dashboards';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const startLayoutFetching = (state) => {
  state.isLayoutUpdating = true;
  state.layoutError = '';
};

const stopLayoutFetching = (state) => {
  state.isLayoutUpdating = false;
};

const getMeta = (item) => omit(item, ['layout', 'widgets']);

const slice = createSlice({
  name: 'dashboards',
  initialState,

  reducers: {
    ...defaultReducers,

    fetchDashboardsMeta: startFetching,
    fetchDashboardsMetaSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.isAllMetaFetched = true;
      state.dashboardsMeta = {};

      (data || []).forEach((item) => {
        state.dashboardsMeta[item.id] = item;
      });
    },

    fetchDashboards: startFetching,
    fetchDashboardsSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.isAllMetaFetched = true;
      state.dashboardsMeta = {};
      state.dashboards = {};

      (data || []).forEach((item) => {
        state.dashboards[item.id] = item;
        state.dashboardsMeta[item.id] = getMeta(item);
      });
    },

    fetchDashboard: startFetching,
    fetchDashboardSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    createDashboard: startFetching,
    createDashboardSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
      state.createdDashboardId = data.id;
    },
    clearCreatedDashboardId(state) {
      state.createdDashboardId = null;
    },

    updateDashboard: startFetching,
    updateDashboardSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    bulkUpdateDashboards: startFetching,
    bulkUpdateDashboardsSuccess(state, { payload: data }) {
      stopFetching(state);
      data.forEach((item) => {
        state.dashboards[item.id] = item;
        state.dashboardsMeta[item.id] = getMeta(item);
      });
    },

    removeDashboard: startFetching,
    removeDashboardSuccess(state, { payload: id }) {
      stopFetching(state);
      delete state.dashboards[id];
      delete state.dashboardsMeta[id];
    },

    cloneDashboard: startFetching,
    cloneDashboardSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    updateLayout(state) {
      startFetching(state);
      startLayoutFetching(state);
    },
    updateLayoutSuccess(state, { payload: { data } }) {
      stopFetching(state);
      stopLayoutFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },
    updateLayoutError(state, { payload: { message } }) {
      stopFetching(state);
      stopLayoutFetching(state);
      state.layoutError = message;
    },
    cancelLayoutUpdate(state) {
      stopLayoutFetching(state);
    },

    createWidget: startFetching,
    createWidgetSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    updateWidget: startFetching,
    updateWidgetSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    removeWidget: startFetching,
    removeWidgetSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    cloneWidget: startFetching,
    cloneWidgetSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.dashboards[data.id] = data;
      state.dashboardsMeta[data.id] = getMeta(data);
    },

    /** * SCHEDULE ** */
    fetchSchedules: startFetching,
    fetchSchedulesSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.schedules = {};
      (data || []).forEach((item) => {
        state.schedules[item.data.dashboard] = item;
      });
    },

    fetchSchedule: startFetching,
    fetchScheduleSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.schedules[data.data.dashboard] = data;
    },

    testSchedule: startFetching,
    testScheduleSuccess: stopFetching,

    createSchedule: startFetching,
    createScheduleSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.schedules[data.data.dashboard] = data;
    },

    updateSchedule: startFetching,
    updateScheduleSuccess(state, { payload: { data } }) {
      stopFetching(state);
      state.schedules[data.data.dashboard] = data;
    },

    removeSchedule: startFetching,
    removeScheduleSuccess(state, { payload: id }) {
      stopFetching(state);
      delete state.schedules[id];
    },

    /** * RECENT ** */
    addDashboardToRecent: () => {},
    removeDashboardFromRecent: () => {},

    /** * COMMON ** */
    skip: stopFetching,
    cancelled: defaultReducers.cancel,
  },

  sagas: (actions) => ({
    [actions.fetchDashboardsMeta]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/meta`);
          yield put(actions.fetchDashboardsMetaSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching dashboards',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchDashboards]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, apiPath);
          yield put(actions.fetchDashboardsSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching dashboards',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchDashboard]: {
      *saga({ payload: { silent = true, id } }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${id}`);
          yield put(actions.fetchDashboardSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error fetching dashboard',
                details: error.message,
              }),
            );
          }
        }
      },
    },

    [actions.createDashboard]: {
      *saga({ payload: dashboard }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, dashboard);
          yield put(actions.createDashboardSuccess(response.data));
          yield put(actions.addDashboardToRecent(response.data?.data?.id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Dashboard has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating dashboard',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateDashboard]: {
      *saga({ payload: { silent = false, ...dashboard } }) {
        initApi();

        const { id, version, errorDetails, layout, widgets, ...updateData } =
          dashboard;

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${id}?version=${version}`,
            updateData,
          );
          if (response.data.data) {
            yield put(actions.updateDashboardSuccess(response.data));
            yield put(actions.addDashboardToRecent(response.data?.data?.id));
          } else {
            // public dashboard became private
            yield put(actions.removeDashboardSuccess(id));
            yield put(actions.removeDashboardFromRecent(id));
          }
          if (!silent) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message: 'Dashboard has been updated',
                response,
              }),
            );
          }
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating dashboard',
              details: isFunction(errorDetails)
                ? errorDetails(error)
                : error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUpdateDashboards]: {
      *saga({ payload: { silent = false, successMessage, dashboards } }) {
        initApi();

        try {
          const responses = yield all(
            dashboards.map((dashboard) => {
              return call(
                api.put,
                `${apiPath}/${dashboard.id}?version=${dashboard.version}`,
                omit(dashboard, [
                  'id',
                  'version',
                  'errorDetails',
                  'layout',
                  'widgets',
                ]),
              );
            }),
          );

          // TODO: Check if public dashboard became private
          yield put(
            actions.bulkUpdateDashboardsSuccess(
              responses.map((response) => response.data.data),
            ),
          );

          if (!silent) {
            yield put(
              toastActions.successWithAuditLogVerification({
                message: successMessage || 'Dashboards have been updated',
                response: responses[0],
              }),
            );
          }
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating dashboards',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeDashboard]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${id}`);
          yield put(actions.removeDashboardSuccess(id));
          yield put(actions.removeDashboardFromRecent(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Dashboard has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting dashboard',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.cloneDashboard]: {
      *saga({ payload: { id, shortname } }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${id}/clone/${shortname}`,
          );
          const { customer = {} } = yield select(customerSelectors.getState);
          let message;
          if (customer.shortname === response.data.data.shortname) {
            message = 'Dashboard has been copied';
            yield put(actions.cloneDashboardSuccess(response.data));
            yield put(actions.addDashboardToRecent(response.data?.data?.id));
          } else {
            message = `Dashboard has been copied to ${response.data.data.shortname}`;
            yield put(actions.cancelled());
          }
          yield put(
            toastActions.successWithAuditLogVerification({
              message,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error copying dashboard',
              details: error.message,
            }),
          );
        }
      },
    },

    // ***************************** LAYOUTS ***************************** //

    [actions.updateLayout]: {
      *saga({ payload: { id, version, errorDetails, ...layout } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${id}/layouts?version=${version}`,
            layout,
          );
          yield put(actions.updateLayoutSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Widget has been updated',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.updateLayoutError(error));
          // yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating widget',
              details: isFunction(errorDetails)
                ? errorDetails(error)
                : error.message,
            }),
          );
        } finally {
          if (yield isCancelled()) {
            yield put(actions.cancelLayoutUpdate());
          }
        }
      },
    },

    // ***************************** WIDGETS ***************************** //

    [actions.createWidget]: {
      *saga({ payload: { id, version, errorDetails, ...widget } }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${id}/widgets?version=${version}`,
            widget,
          );
          yield put(actions.createWidgetSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Widget has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating widget',
              details: isFunction(errorDetails)
                ? errorDetails(error)
                : error.message,
            }),
          );
        }
      },
    },

    [actions.updateWidget]: {
      *saga({
        payload: { dashboardId, widgetId, version, errorDetails, ...widget },
      }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${dashboardId}/widgets/${widgetId}?version=${version}`,
            widget,
          );
          yield put(actions.updateWidgetSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Widget has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating widget',
              details: isFunction(errorDetails)
                ? errorDetails(error)
                : error.message,
            }),
          );
        }
      },
    },

    [actions.removeWidget]: {
      *saga({ payload: { dashboardId, widgetId, version, errorDetails } }) {
        initApi();

        try {
          const response = yield call(
            api.delete,
            `${apiPath}/${dashboardId}/widgets/${widgetId}?version=${version}`,
          );
          yield put(actions.removeWidgetSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Widget has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting widget',
              details: isFunction(errorDetails)
                ? errorDetails(error)
                : error.message,
            }),
          );
        }
      },
    },

    [actions.cloneWidget]: {
      *saga({
        payload: {
          dashboardFromId,
          dashboardToId,
          widgetId,
          version,
          errorDetails,
        },
      }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${dashboardFromId}/widgets/${widgetId}/clone/${dashboardToId}?version=${version}`,
          );
          yield put(actions.cloneWidgetSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Widget has been copied',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error copying widget',
              details: isFunction(errorDetails)
                ? errorDetails(error)
                : error.message,
            }),
          );
        }
      },
    },

    /** * RECENT ** */
    [actions.addDashboardToRecent]: {
      *saga({ payload: id }) {
        try {
          const category = 'dashboard';
          const property = 'recents';
          yield put(
            portalSettingsActions.fetchPortalSetting({
              category,
              property,
            }),
          );
          let isPortalSettingFetching = true;
          while (isPortalSettingFetching) {
            yield delay(300);
            isPortalSettingFetching = yield select(
              portalSettingsSelectors.isFetching,
            );
          }
          const portalSetting = yield select(
            portalSettingsSelectors.getCategoryPropertySelector(
              category,
              property,
            ),
          ) || {};
          yield put(
            portalSettingsActions.savePortalSetting({
              ...portalSetting,
              value: _addDashboardToRecent(portalSetting.value, id),
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.removeDashboardFromRecent]: {
      *saga({ payload: id }) {
        try {
          const category = 'dashboard';
          const property = 'recents';
          yield put(
            portalSettingsActions.fetchPortalSetting({
              category,
              property,
            }),
          );
          let isPortalSettingFetching = true;
          while (isPortalSettingFetching) {
            yield delay(300);
            isPortalSettingFetching = yield select(
              portalSettingsSelectors.isFetching,
            );
          }
          const portalSetting = yield select(
            portalSettingsSelectors.getCategoryPropertySelector(
              category,
              property,
            ),
          ) || {};
          yield put(
            portalSettingsActions.savePortalSetting({
              ...portalSetting,
              value: _removeDashboardFromRecent(portalSetting.value, id),
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    /** * SCHEDULE ** */
    [actions.fetchSchedules]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/schedules`);
          yield put(actions.fetchSchedulesSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching dashboards schedules',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchSchedule]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${id}/schedule`);
          yield put(actions.fetchScheduleSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          // yield put(
          //   toastActions.error({
          //     message: 'Error fetching dashboard schedule',
          //     details: error.message,
          //   }),
          // );
        }
      },
    },

    [actions.testSchedule]: {
      *saga({ payload: { id, data } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${id}/schedule/test`,
            data,
          );
          yield put(actions.testScheduleSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Dashboard has been sent',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error sending dashboard',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createSchedule]: {
      *saga({ payload: { id, data } }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${id}/schedule`,
            data,
          );
          yield put(actions.createScheduleSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Dashboard schedule has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating dashboard schedule',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateSchedule]: {
      *saga({ payload: { id, data } }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${id}/schedule`,
            data,
          );
          yield put(actions.updateScheduleSuccess(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Dashboard schedule has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating dashboard schedule',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeSchedule]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${id}/schedule`);
          yield put(actions.removeScheduleSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Dashboard schedule has been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error removing dashboard schedule',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    isAllMetaFetched: createSelector(
      [getState],
      (state) => state.isAllMetaFetched,
    ),

    getError: createSelector([getState], (state) => state.error),

    getLayoutError: createSelector([getState], (state) => state.layoutError),

    getDashboardsMeta: createSelector(
      [getState],
      (state) => state.dashboardsMeta,
    ),

    getDashboards: createSelector([getState], (state) => state.dashboards),

    getDashboard: (id) =>
      createSelector([getState], (state) => state.dashboards?.[id]),

    getCreatedDashboardId: createSelector(
      [getState],
      (state) => state.createdDashboardId,
    ),

    getSchedules: createSelector([getState], (state) => state.schedules),

    getSchedule: (id) =>
      createSelector([getState], (state) => state.schedules?.[id]),
  }),
});

export const { actions, selectors } = slice;
export default slice;
