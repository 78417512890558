import dayjs, { DateFormat } from '+utils/dayjs';

const xAxisCategoryCollection = {
  hour: Array(24)
    .fill(0)
    .map((_, index) =>
      dayjs(`2022-08-22 ${index}:00`).format(DateFormat.minuteWithoutDate),
    ),
  day: Array(31)
    .fill(0)
    .map((_, index) => index + 1),
  minute: Array(61)
    .fill(0)
    .map((_, index) => index),
  second: Array(61)
    .fill(0)
    .map((_, index) => index),
};

export default (timeSeries, interval) => {
  const result = {
    interval,
    xAxisCategories: [],
    yAxisCategories: [],
    data: [],
    field: {
      x: '',
      y: '',
    },
    title: '',
    filterTitle: '',
    height: 0,
  };

  if (!(Array.isArray(timeSeries) && timeSeries.length)) {
    return result;
  }

  const sortedData = [...timeSeries]
    .map((x) => (x[1] > 0 ? x : [x[0], null]))
    .sort((a, b) => a[0] - b[0]);
  const firstDate = dayjs(+sortedData[0][0]);
  let yIndex = 0;
  let flag;
  let yAxisFormat;

  switch (interval) {
    case '1d':
      flag = firstDate.month();

      result.filterTitle = firstDate.format(DateFormat.year);
      yAxisFormat = DateFormat.yearAndMonth;

      result.yAxisCategories.push({
        title: firstDate.format(yAxisFormat),
        date: firstDate,
      });

      sortedData.forEach((item) => {
        const d = dayjs(+item[0]);
        if (d.month() !== flag) {
          result.yAxisCategories.push({
            title: d.format(yAxisFormat),
            date: d,
          });
          flag = d.month();
          yIndex += 1;
        }
        result.data.push([d.date() - 1, yIndex, item[1]]);
      });

      result.field = {
        x: 'Day',
        y: 'Month',
      };

      result.xAxisCategories = xAxisCategoryCollection.day;
      break;
    case '1h':
      flag = firstDate.date();

      result.filterTitle = firstDate.format(DateFormat.day);
      yAxisFormat = DateFormat.day;

      result.yAxisCategories.push({
        title: firstDate.format(yAxisFormat),
        date: firstDate,
      });

      sortedData.forEach((item) => {
        const d = dayjs(+item[0]);
        if (d.date() !== flag) {
          result.yAxisCategories.push({
            title: d.format(yAxisFormat),
            date: d,
          });
          flag = d.date();
          yIndex += 1;
        }
        result.data.push([d.hour(), yIndex, item[1]]);
      });

      result.field = {
        x: 'Hour',
        y: 'Date',
      };

      result.xAxisCategories = xAxisCategoryCollection.hour;
      break;
    case '1m':
      flag = firstDate.hour();

      result.filterTitle = firstDate.format(DateFormat.hour);
      yAxisFormat = 'H';

      result.yAxisCategories.push({
        title: firstDate.format(yAxisFormat),
        date: firstDate,
      });

      sortedData.forEach((item) => {
        const d = dayjs(+item[0]);
        if (d.hour() !== flag) {
          result.yAxisCategories.push({
            title: d.format(yAxisFormat),
            date: d,
          });
          flag = d.hour();
          yIndex += 1;
        }
        result.data.push([d.minute(), yIndex, item[1]]);
      });

      result.field = {
        x: 'Minute',
        y: `${firstDate.format(DateFormat.day)}<br>Hour`,
      };

      result.xAxisCategories = xAxisCategoryCollection.minute;
      break;
    case '1s':
      flag = firstDate.minute();

      result.filterTitle = firstDate.format(DateFormat.minute);
      yAxisFormat = 'm';

      result.yAxisCategories.push({
        title: firstDate.format(yAxisFormat),
        date: firstDate,
      });

      sortedData.forEach((item) => {
        const d = dayjs(+item[0]);
        if (d.minute() !== flag) {
          result.yAxisCategories.push({
            title: d.format(yAxisFormat),
            date: d,
          });
          flag = d.minute();
          yIndex += 1;
        }
        result.data.push([d.second(), yIndex, item[1]]);
      });

      result.field = {
        x: 'Second',
        y: `${firstDate.format(DateFormat.hour)}<br>Minute`,
      };

      result.xAxisCategories = xAxisCategoryCollection.second;
      break;
    default:
      break;
  }

  result.title = result.field.x ? `Event Count by ${result.field.x}` : '';
  result.height = result.yAxisCategories?.length <= 7 ? 300 : 600;

  return result;
};
