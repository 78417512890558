import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled from 'styled-components';

import { findMetricSettings } from '@/models/MetricSettings';

import useExportingFilename from '+hooks/useExportingFilename';
import useGlobalFilters from '+hooks/useGlobalFilters';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import {
  defaultLegendLabelFormatter,
  defaultNumberFormatter,
  defaultSeriesColorIndexFormatter,
  defaultSeriesValueFormatter,
} from './common/formatters';
import { Highcharts } from './common/highcharts';
import {
  getLegendPositionOptions,
  LegendMixin,
  LegendPositions,
} from './common/legend';
import { defaultColorVariety, lang, legendItemClick } from './common/utils';

const defaultTooltipFormatter = function () {
  const { y, point, total } = this;
  const {
    name,
    userOptions: { metric, context },
  } = this.series;
  const { tooltipFormatter } = findMetricSettings({ context, metric }) || {
    tooltipFormatter: defaultNumberFormatter,
  };
  const percent = total ? ((y * 100) / total).toFixed(1) : 0;
  return `
    <div style="display: flex; flex-wrap: nowrap; align-items: center">
      <span style="display: flex; flex-wrap: nowrap; align-items: center">
        <b class="highcharts-color-${
          point.colorIndex
        }" style="margin-right: 4px">▬</b>
        ${name}
      </span>
      <span style="margin: auto 0 auto 12px; font-weight: bold">
        <b>${tooltipFormatter(y)} (${percent}%)</b>
      </span>
    </div>
  `;
};

const plotOptions = {
  series: {
    borderWidth: 0,
    dataGrouping: {
      approximation: 'sum',
      enabled: false,
      groupPixelWidth: 0,
    },
    events: {
      legendItemClick,
    },
  },
  column: {
    stacking: 'normal',
    animation: false,
    shadow: false,
    stickyTracking: false, // hide the tooltip when the mouse leaves
    dataLabels: { enabled: false },
    pointPadding: 0,
    groupPadding: 0,
    borderWidth: 0,
  },
};

/**
 * StackedBarChart is a variation of BarChart that plots bars indicating data values for a category.
 */
const AggStackedBarChart = styled((props) => {
  const {
    className,
    type,
    context,
    title,
    subtitle,
    series,
    categories,
    legend,
    legendReversed,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    colorIndex,
    seriesNameFormatter,
    seriesColorIndexFormatter,
    legendLabelFormatter,
    tooltipFormatter,
    legendPosition,
    hideTitle,
    hideSubtitle,
    waitingOnNql,
  } = props;

  const chartRef = useRef(null);
  const [filters] = useGlobalFilters();
  const exportingFilename = useExportingFilename(title);
  const defaultProps = useDefaultPropsHSX({ exporting });

  const legendPositionOptions = useMemo(
    () => getLegendPositionOptions({ legendPosition, title, subtitle }),
    [legendPosition, title, subtitle],
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(
          className,
          'stacked-bar-char',
          `p-${colorVariety ?? defaultColorVariety}`,
          `legend-${legendPosition}`,
          {
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
          },
        )}
        plotOptions={plotOptions}
        callback={onChartCallback}
        __colorVariety={colorVariety}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>
          {waitingOnNql ? lang.waitingOnNql : lang.loading}
        </HSX.Loading>

        <HSX.Legend
          {...legendPositionOptions}
          enabled={legend}
          labelFormat={legendLabelFormatter}
          shadow={false}
          squareSymbol={false}
          symbolWidth={0}
          symbolHeight={0}
          symbolPadding={0}
          reversed={legendReversed}
          reverseLegendClickAction
          alignColumns
          useHTML
        />

        <HSX.Tooltip
          formatter={tooltipFormatter}
          useHTML
          borderRadius={8}
          shadow={false}
          animation={false}
        />

        <HSX.Chart
          type={type}
          width={width}
          height={height}
          animation={false}
          reflow={false}
        />

        <HSX.XAxis categories={categories} labels={{ rotation: 0 }} />

        <HSX.YAxis floor={0} min={0}>
          {series.map((item, i) => {
            const seriesName = seriesNameFormatter({
              ...item,
              labelContext: filters.labelContext,
            });
            const seriesId = `${seriesName}_${i}`;
            const seriesColorIndex =
              colorIndex ?? item.colorIndex ?? seriesColorIndexFormatter(item);
            return (
              <HSX.ColumnSeries
                key={seriesId}
                id={seriesId}
                name={seriesName}
                context={context}
                metric={series.metric}
                data={item.data}
                colorIndex={seriesColorIndex}
              />
            );
          })}
        </HSX.YAxis>
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})`
  ${LegendMixin};
`;

AggStackedBarChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart type.
   */
  type: PropTypes.oneOf(['line', 'bar']),
  /**
   * Chart context.
   */
  context: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart series.
   */
  series: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Data categories.
   */
  categories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  /**
   * If true, chart legend will be displayed.
   */
  legend: PropTypes.bool,
  /**
   * If true, legend will be reversed.
   */
  legendReversed: PropTypes.bool,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index.
   */
  colorIndex: PropTypes.number,
  /**
   * Series name formatter.
   */
  seriesNameFormatter: PropTypes.func,
  /**
   * Series color index formatter.
   */
  seriesColorIndexFormatter: PropTypes.func,
  /**
   * Legend label formatter.
   */
  legendLabelFormatter: PropTypes.string,
  /**
   * Tooltip formatter.
   */
  tooltipFormatter: PropTypes.func,
  /**
   * Legend position.
   */
  legendPosition: PropTypes.oneOf(Object.values(LegendPositions)),
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * if true, chart data will not be loaded until user inputs NQL (handled in StatsWrapper)
   * Will show the corresponding message on the chart
   */
  waitingOnNql: PropTypes.bool,
};

AggStackedBarChart.defaultProps = {
  className: '',
  type: 'line',
  context: undefined,
  title: null,
  subtitle: null,
  series: [],
  categories: [],
  legend: true,
  legendReversed: false,
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndex: undefined,
  seriesNameFormatter: defaultSeriesValueFormatter,
  seriesColorIndexFormatter: defaultSeriesColorIndexFormatter,
  legendLabelFormatter: defaultLegendLabelFormatter,
  tooltipFormatter: defaultTooltipFormatter,
  legendPosition: LegendPositions.bottom,
  hideTitle: false,
  hideSubtitle: false,
};

export default memo(AggStackedBarChart);
