import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/api/rules';

import Form from './Form';

const ModalEditCategory = (props) => {
  const {
    isOpen,
    initialValues,
    permissions,
    successCallback,
    toggleModal,
    ...tail
  } = props;

  const dispatch = useDispatch();

  const { isFetching, error } = useSelector(selectors.getState);

  const [isProcessing, setIsProcessing] = useState(false);

  const onSubmit = useCallback((values) => {
    dispatch(actions.addOrUpdateCategory(values));
    setIsProcessing(true);
  }, []);

  useEffect(() => {
    if (isFetching || !isProcessing) {
      return;
    }

    setIsProcessing(false);

    if (!error) {
      successCallback();
    }
  }, [isFetching, isProcessing, error, successCallback]);

  return (
    <Form
      {...tail}
      mode="edit"
      isOpen={isOpen}
      initialValues={initialValues}
      successCallback={onSubmit}
      toggleModal={toggleModal}
      disabled={!permissions?.update}
    />
  );
};

ModalEditCategory.propTypes = {
  isOpen: PropTypes.bool,
  initialValues: PropTypes.shape(),
  successCallback: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  permissions: PropTypes.shape(),
};

ModalEditCategory.defaultProps = {
  isOpen: false,
  initialValues: {
    name: '',
    description: null,
  },
  permissions: null,
};

export default ModalEditCategory;
