import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, FetchStates, selectors } from '@/redux/api/nql-complete';

import ConfirmModal from '+components/ConfirmModal';
import useUIProperty from '+hooks/useUIProperty';

export const RemoveNqlPreset = () => {
  const dispatch = useDispatch();

  const [globalNqlPresetRemove, setGlobalNqlPresetRemove] = useUIProperty(
    'globalNqlPresetRemove',
    null,
  );

  const status = useSelector(selectors.getPresetsStatus);

  const isFetching = status === FetchStates.fetching;
  const isError = status === FetchStates.error;

  const [isProcessing, setIsProcessing] = useState(false);

  const onCancel = useCallback(() => {
    setGlobalNqlPresetRemove(null);
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(actions.deletePreset(globalNqlPresetRemove));
    setIsProcessing(true);
  }, [globalNqlPresetRemove]);

  useEffect(() => {
    if (isFetching || !isProcessing) {
      return;
    }

    setIsProcessing(false);

    if (!isError) {
      setGlobalNqlPresetRemove(null);
    }
  }, [isFetching, isProcessing, isError, globalNqlPresetRemove]);

  const isDisabled = isFetching || isProcessing;

  return (
    <ConfirmModal
      item={`${globalNqlPresetRemove?.title} NQL preset`}
      onToggle={onCancel}
      onConfirm={onConfirm}
      isDisabled={isDisabled}
      isOpen={!!globalNqlPresetRemove}
      testId="nql-presets-remove-modal"
    />
  );
};
