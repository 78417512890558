import { useCallback, useEffect, useMemo, useRef } from 'react';
import { actions } from 'react-table';

import { makeId } from '+utils';

actions.resetDuplicates = 'resetDuplicates';
actions.setAllDuplicates = 'setAllDuplicates';
actions.duplicateColumn = 'duplicateColumn';
actions.removeDuplicate = 'removeDuplicate';

const emptyFn = () => {};

const reducer = (state, action, _, instance) => {
  if (action.type === actions.init) {
    return {
      duplicates: [],
      ...state,
    };
  }

  if (action.type === actions.resetDuplicates) {
    return {
      ...state,
      duplicates: instance.initialState.duplicates || [],
    };
  }

  if (action.type === actions.setAllDuplicates) {
    const { duplicates } = action;
    return {
      ...state,
      duplicates,
    };
  }

  if (action.type === actions.removeDuplicate) {
    const { id } = action;
    const { duplicates } = state;
    return {
      ...state,
      duplicates: duplicates.filter((item) => item.id !== id),
    };
  }

  if (action.type === actions.duplicateColumn) {
    const { id, duplicateOf } = action;
    const { allColumns } = instance;

    const column = allColumns.find((d) => d.id === duplicateOf);

    if (!column) {
      throw new Error(
        `React-Table: Could not find a column with id: ${duplicateOf}`,
      );
    }

    return {
      ...state,
      duplicates: [...state.duplicates, { id, duplicateOf }],
    };
  }

  return state;
};

const useInstance = (instance) => {
  const {
    allColumns: allColumnsProp,
    disableDuplicateBy,
    state: { duplicates },
    setColumnOrder,
    dispatch,
  } = instance;

  const defOrder = useRef([]);

  const resetDuplicates = useCallback(() => {
    dispatch({ type: actions.resetDuplicates });
  }, [dispatch]);

  const setAllDuplicates = useCallback(
    (value) => {
      dispatch({ type: actions.setAllDuplicates, duplicates: value });
    },
    [dispatch],
  );

  const duplicateColumn = useCallback(
    (id) => {
      const newId = `${id}-${makeId()}`;

      setColumnOrder((prevOrder) => {
        const nextOrder = [
          ...(prevOrder?.length ? prevOrder : defOrder.current),
        ];
        const index = nextOrder.indexOf(id);
        if (index === -1) {
          return prevOrder;
        }
        nextOrder.splice(index + 1, 0, newId);
        return nextOrder;
      });

      dispatch({ type: actions.duplicateColumn, id: newId, duplicateOf: id });
    },
    [dispatch],
  );

  const removeDuplicate = useCallback(
    (id) => {
      setColumnOrder((prevOrder) => {
        const nextOrder = [
          ...(prevOrder?.length ? prevOrder : defOrder.current),
        ];
        const index = nextOrder.indexOf(id);
        if (index === -1) {
          return prevOrder;
        }
        nextOrder.splice(index, 1);
        return nextOrder;
      });

      dispatch({ type: actions.removeDuplicate, id });
    },
    [dispatch],
  );

  const allColumns = useMemo(
    () =>
      allColumnsProp?.map((column) => {
        const found = duplicates.find((item) => item.id === column.id);
        column.isDuplicate = !!found;
        column.duplicateOf = found?.duplicateOf;
        column.canBeDuplicated =
          !column.isGrouped &&
          !column.disableDuplicateBy &&
          !disableDuplicateBy;
        column.duplicateColumn = column.canBeDuplicated
          ? () => duplicateColumn(column.duplicateOf || column.id)
          : emptyFn;
        column.removeDuplicate = column.isDuplicate
          ? () => removeDuplicate(column.id)
          : emptyFn;
        return column;
      }),
    [
      allColumnsProp,
      duplicates,
      duplicateColumn,
      removeDuplicate,
      disableDuplicateBy,
    ],
  );

  useEffect(() => {
    defOrder.current = (allColumnsProp || []).map(({ id }) => id);
  }, [allColumnsProp]);

  Object.assign(instance, {
    allColumns,
    resetDuplicates,
    setAllDuplicates,
    duplicateColumn,
  });
};

/**
 * This plugin adds duplicate functionality to react-table.
 * @param hooks
 */
export const useDuplicates = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useDuplicates.pluginName = 'useDuplicates';
