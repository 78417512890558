import PropTypes from '+prop-types';
import { forwardRef, useCallback } from 'react';

import InputOrigin from 'rc-input-number';
import styled from 'styled-components';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import { ClearButton, ClearIcon } from '+components/form/InputText';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  > .MuiIconButton-root {
    position: absolute;
    right: 24px;
  }
`;

const Input = styled(InputOrigin)`
  font-size: 12px;
  position: relative;
  width: 100%;

  &:only-child .rc-input-number-input {
    padding-right: ${({ $padInputField }) => ($padInputField ? '27px' : '0')};
  }

  &:not(:only-child) .rc-input-number-input {
    padding-right: 52px;
  }

  .rc-input-number-handler-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 3px;
    width: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  .rc-input-number-handler {
    display: flex;
    overflow: hidden;
    line-height: 12px;
    text-align: center;
    touch-action: none;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    width: 14px;
    max-height: 14px;
    border: unset;
    border-radius: 50%;
    transition:
      border-color 0.3s ease-in-out,
      background 0.3s ease-in-out;

    &-up,
    &-down {
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background: rgba(255, 255, 255, 0.35);
      }
    }
  }

  &:not(.rc-input-number-disabled).rc-input-number-focused {
    input,
    .rc-input-number-handler {
      border-color: ${({ theme }) => theme.input.borderFocused} !important;
    }
  }

  &:not(.rc-input-number-disabled):hover {
    input,
    .rc-input-number-handler {
      border-color: ${({ theme }) => theme.input.borderHover} !important;
    }
  }

  .rc-input-number-input-wrap {
    z-index: 2;
  }

  input {
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colorFieldsBorder};
    height: 32px;

    outline: 0;
    padding: 0 7px;
    width: 100%;

    transition: border-color 0.3s ease-in-out;

    &:not(:disabled):active,
    &:not(:disabled):focus {
      border-color: ${({ theme }) => theme.input.borderFocused} !important;
    }

    &:not(:disabled):hover {
      border-color: ${({ theme }) => theme.input.borderHover} !important;
    }
  }

  &.rc-input-number-disabled {
    .rc-input-number-input {
      border: 0 solid transparent;
      padding-left: unset;
      padding-right: unset;
    }
    .rc-input-number-handler-wrap {
      display: none;
    }
  }
`;

const NumberInput = forwardRef((props, ref) => {
  const {
    className,
    value,
    disabled,
    readOnly,
    showClearButton,
    onChange,
    padInputField,
    ...rest
  } = props;

  const onClear = useCallback(
    (e) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  return (
    <Container ref={ref} className={className}>
      <Input
        {...rest}
        value={value}
        upHandler={<ChevronUpIcon size={16} />}
        downHandler={<ChevronDownIcon size={16} />}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        $padInputField={padInputField}
      />

      {!disabled &&
        !readOnly &&
        showClearButton &&
        value != null &&
        value !== '' && (
          <ClearButton tabIndex={-1} size="medium" onClick={onClear}>
            <ClearIcon size={14} />
          </ClearButton>
        )}
    </Container>
  );
});

NumberInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showClearButton: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  padInputField: PropTypes.bool,
};

NumberInput.defaultProps = {
  className: '',
  value: undefined,
  disabled: false,
  readOnly: false,
  showClearButton: false,
  padInputField: true,
};

export default NumberInput;
