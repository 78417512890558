import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 5px 23px;
`;

const Input = styled.input`
  width: 0;
  flex: 1;
  border-radius: 3px;
`;

const filterMixin = css`
  ${({ $filter }) =>
    !$filter
      ? ''
      : `
  [data-mlc-search]:not([data-mlc-search*="${$filter}"]) {
    max-height: 0;
    padding: 0 15px;
    opacity: 0;
  }
`}
`;

const DirtyCss = (props) =>
  !props.$filter ? null : (
    <style dangerouslySetInnerHTML={{ __html: filterMixin[0](props) }} />
  );

DirtyCss.propTypes = {
  $filter: PropTypes.string,
};

DirtyCss.defaultProps = {
  $filter: null,
};

const Filter = ({ onChange }) => {
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const onChangeFilter = useCallback((event) => {
    setFilter(event?.target?.value);
  }, []);

  useDebounce(() => setSearch(filter), 300, [filter]);

  useEffect(() => onChange(search), [search]);

  return (
    <Container>
      <DirtyCss $filter={search} />
      <Input value={filter} onChange={onChangeFilter} />
    </Container>
  );
};

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Filter;
