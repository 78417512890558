import styled, { css } from 'styled-components';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const AdditionalFiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  //align-items: center;
  height: ${({ $expanded }) => ($expanded ? 32 : 0)}px;
  width: 100%;
  flex-shrink: 0;
  background: ${globalFiltersTheme.additionalRowBackground};
  gap: ${({ $separatorWidth }) => $separatorWidth}px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  border-bottom: ${({ $expanded, $separatorWidth }) =>
      $expanded ? $separatorWidth : 0}px
    solid ${({ theme }) => theme.colorBackgroundSeparator};

  &:focus,
  &:hover,
  &:active,
  &:focus:active {
    overflow: visible;
  }

  ${({ $maxItems }) => css`
    .additional-filters-row-item:not(#menu-more):nth-child(n + ${$maxItems}) {
      display: none;
    }

    .additional-filters-separator:not(#menu-more-s):nth-child(
        n + ${$maxItems + 1}
      ) {
      display: none;
    }

    #menu-more:nth-child(-n + ${$maxItems + 1}) {
      display: none;
    }

    #menu-more-s:nth-child(-n + ${$maxItems + 2}) {
      display: none;
    }
  `}
`;

export default AdditionalFiltersRow;
