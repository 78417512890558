import { BooleanColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @return {Object} - column with filter=booleanFilter and Filter=BooleanColumnFilter
 */
export const EnabledDisabledColumnFactory = (props) => ({
  ...BaseColumnFactory(props),
  width: 120,
  getCellProps: () => ({ style: { justifyContent: 'center' } }),
  sortType: 'boolean',
  Filter: BooleanColumnFilter(
    {
      false: 'Disabled',
      true: 'Enabled',
    },
    null,
    ['all', 'false', 'true'],
  ),
  filter: 'booleanFilter',
  // eslint-disable-next-line react/prop-types
  Cell: ({ value }) => (value ? 'Enabled' : 'Disabled'),
});
