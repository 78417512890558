import axios from 'axios';

import { config } from '@/config';

import { removeAllSavedFilters } from '+utils/removeAllSaveFilters';

/** @type {import('react-oidc-context').AuthContext} */
let client;

const setClient = (value) => {
  client = value;
};

const guest = {
  code: null,
  token: null,
  data: null,
};

const setGuest = ({ code, token, data }) => {
  guest.code = code;
  guest.token = token;
  guest.data = data;
};

const getGuest = () => guest;

const isGuest = () => !!guest.code && !!guest.token && !!guest.data;

const guestLogin = (code) => {
  return axios.post(`${config.appBackendUrlRoot}/api/v1/guests/token`, {
    code,
  });
};

const beforeLogin = () => {
  localStorage.removeItem('logoutRedirectUri');
  localStorage.removeItem('impersonate');
  setGuest({ url: null, code: null, token: null });
};

const silentLogin = () => client.signinSilent();

const logout = () => {
  const redirectUri =
    localStorage.getItem('logoutRedirectUri') || window.location.href;
  localStorage.removeItem('logoutRedirectUri');
  localStorage.removeItem('impersonate');
  removeAllSavedFilters();
  setGuest({ url: null, code: null, token: null });
  client.signoutRedirect({ post_logout_redirect_uri: redirectUri });
};

const getToken = () => {
  const impersonateToken = localStorage.getItem('impersonate');
  return guest.token || impersonateToken || client?.user?.access_token;
};

const getUser = () => guest.data || client?.user?.profile;

const authClient = {
  setClient,
  getClient: () => client,
  setGuest,
  getGuest,
  isGuest,
  guestLogin,
  beforeLogin,
  silentLogin,
  logout,
  getToken,
  getUser,
};

export default authClient;
