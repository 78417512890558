import PropTypes from '+prop-types';
import { useCallback, useContext, useEffect, useRef } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import ButtonOrigin, { ButtonVariants } from '+components/Button';

import { Context } from './Context';

const Button = styled(ButtonOrigin)`
  &.open {
    pointer-events: none;
  }
`;

const Toggle = (props) => {
  const { className, children, showIcon, onOpenOrClose, ...rest } = props;
  const { show, hide, isOpen } = useContext(Context);
  const isOpenPrevRef = useRef(isOpen);

  const onClick = useCallback(
    (event) => {
      if (isOpen) {
        hide();
      } else {
        show(event);
      }
    },
    [isOpen, show, hide],
  );

  const icon = isOpen ? (
    <ChevronUpIcon size={18} />
  ) : (
    <ChevronDownIcon size={18} />
  );
  const endIcon = children && showIcon ? icon : null;
  const iconInBody = !children && showIcon ? icon : null;

  useEffect(() => {
    if (isOpen !== isOpenPrevRef.current) {
      isOpenPrevRef.current = isOpen;
      onOpenOrClose?.(isOpen);
    }
  }, [isOpen]);

  return (
    <Button
      className={classNames(className, 'dropdown-toggle', {
        open: isOpen,
      })}
      variant={ButtonVariants.contained}
      disableElevation
      {...rest}
      onClick={onClick}
      endIcon={endIcon}
    >
      {children}
      {iconInBody}
    </Button>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.children,
  showIcon: PropTypes.bool,
  onOpenOrClose: PropTypes.func,
};

Toggle.defaultProps = {
  className: null,
  children: null,
  showIcon: true,
  onOpenOrClose: undefined,
};

export default Toggle;
