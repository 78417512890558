import PropTypes from '+prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';

const Context = createContext({
  additionalFilters: null,
});

export const { Provider } = Context;

const updateStore = (store, id, value) => {
  const { [id]: _, ...rest } = store || {};
  return value != null ? { ...rest, [id]: value } : rest;
};

export const GlobalFilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isFiltered, setIsFiltered] = useState({});

  const setAdditionalFilters = useCallback((id, content) => {
    setFilters((prev) =>
      updateStore(prev, id, content != null ? { id, content } : null),
    );
  }, []);

  const setPortalInitialValues = useCallback((id, values) => {
    setInitialValues((prev) => updateStore(prev, id, values));
  }, []);

  const setPortalIsFiltered = useCallback((id, value) => {
    setIsFiltered((prev) => updateStore(prev, id, value));
  }, []);

  const remove = useCallback((id) => {
    setAdditionalFilters(id, null);
    setPortalInitialValues(id, null);
    setPortalIsFiltered(id, null);
  }, []);

  const portalChildren = useMemo(() => Object.values(filters), [filters]);

  const portalInitialValues = useMemo(
    () =>
      Object.values(initialValues || {}).reduce(
        (acc, values) => ({ ...acc, ...values }),
        {},
      ),
    [initialValues],
  );

  const portalIsFiltered = Object.values(isFiltered).some(Boolean);

  const value = useMemo(
    () => ({
      portalChildren,
      portalInitialValues,
      portalIsFiltered,

      hasFilters: !!portalChildren?.length,

      setAdditionalFilters,
      setPortalInitialValues,
      setPortalIsFiltered,
      remove,
    }),
    [portalChildren, portalInitialValues, portalIsFiltered],
  );

  return <Provider value={value}>{children}</Provider>;
};

GlobalFilterProvider.propTypes = {
  children: PropTypes.children.isRequired,
};

export default Context;
