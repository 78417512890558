import { css } from 'styled-components';

export default ({ theme }) => css`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.4rem;
    line-height: 1.6;
    color: ${theme.colorText};
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  #app {
    position: relative;
    width: 100%;
    height: 100%;
  }

  pre,
  code {
    font-family: 'Source Code Pro', monospace;
  }
`;
