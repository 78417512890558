import { useCallback } from 'react';
import {
  actions,
  defaultColumn,
  ensurePluginOrder,
  functionalUpdate,
  makePropGetter,
  useGetLatest,
  useMountedLayoutEffect,
} from 'react-table';

// Default Column
defaultColumn.canResize = true;

// Actions
actions.columnStartResizing = 'columnStartResizing';
actions.columnResizing = 'columnResizing';
actions.columnDoneResizing = 'columnDoneResizing';
actions.resetResize = 'resetResize';
actions.resetResizeColumn = 'resetResizeColumn';
actions.setColumnWidths = 'setColumnWidths';

const minmax = (value, min, max) =>
  Math.round(Math.min(Math.max(value, +min), +max));

let passiveSupported = null;
export function passiveEventSupported() {
  // memoize support to avoid adding multiple test events
  if (typeof passiveSupported === 'boolean') {
    return passiveSupported;
  }

  let supported = false;
  try {
    const options = {
      get passive() {
        supported = true;
        return false;
      },
    };

    window.addEventListener('test', null, options);
    window.removeEventListener('test', null, options);
  } catch (err) {
    supported = false;
  }

  passiveSupported = supported;
  return passiveSupported;
}

const defaultGetResizerProps = (props, { instance, header }) => {
  const { dispatch, headerGroups, state } = instance;

  if (state.columnResizing?.isResizingColumn) {
    return [props];
  }

  const [{ headers }] = headerGroups.slice(-1);

  const onResizeStart = (event) => {
    let isTouchEvent = false;
    if (event.type === 'touchstart') {
      // lets not respond to multiple touches (e.g. 2 or 3 fingers)
      if (event.touches && event.touches.length > 1) {
        return;
      }
      isTouchEvent = true;
    }

    const index = headers.indexOf(header);

    const prevHeaders = headers
      .slice(0, index)
      .map(
        (prevHeader) =>
          prevHeader.canResize && [
            prevHeader.id,
            prevHeader.totalWidth,
            prevHeader.minWidth,
            prevHeader.maxWidth,
          ],
      )
      .filter(Boolean);

    const nextHeaders = headers
      .slice(index + 1)
      .map(
        (nextHeader) =>
          nextHeader.canResize && [
            nextHeader.id,
            nextHeader.totalWidth,
            nextHeader.minWidth,
            nextHeader.maxWidth,
          ],
      )
      .filter(Boolean);

    const clientX = isTouchEvent
      ? Math.round(event.touches[0].clientX)
      : event.clientX;

    const dispatchMove = (clientXPos) => {
      dispatch({ type: actions.columnResizing, clientX: clientXPos });
    };
    const dispatchEnd = () => dispatch({ type: actions.columnDoneResizing });

    const handlersAndEvents = {
      mouse: {
        moveEvent: 'mousemove',
        moveHandler: (e) => dispatchMove(e.clientX),
        upEvent: 'mouseup',
        upHandler: () => {
          document.removeEventListener(
            'mousemove',
            handlersAndEvents.mouse.moveHandler,
          );
          document.removeEventListener(
            'mouseup',
            handlersAndEvents.mouse.upHandler,
          );
          dispatchEnd();
        },
      },
      touch: {
        moveEvent: 'touchmove',
        moveHandler: (e) => {
          if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
          }
          dispatchMove(e.touches[0].clientX);
          return false;
        },
        upEvent: 'touchend',
        upHandler: () => {
          document.removeEventListener(
            handlersAndEvents.touch.moveEvent,
            handlersAndEvents.touch.moveHandler,
          );
          document.removeEventListener(
            handlersAndEvents.touch.upEvent,
            handlersAndEvents.touch.moveHandler,
          );
          dispatchEnd();
        },
      },
    };

    const events = isTouchEvent
      ? handlersAndEvents.touch
      : handlersAndEvents.mouse;
    const passiveIfSupported = passiveEventSupported()
      ? { passive: false }
      : false;
    document.addEventListener(
      events.moveEvent,
      events.moveHandler,
      passiveIfSupported,
    );
    document.addEventListener(
      events.upEvent,
      events.upHandler,
      passiveIfSupported,
    );

    dispatch({
      type: actions.columnStartResizing,
      columnId: header.id,
      columnWidth: header.totalWidth,
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      clientX,
      prevHeaders,
      nextHeaders,
    });
  };

  return [
    props,
    {
      onMouseDown: (e) => e.persist() || onResizeStart(e),
      onTouchStart: (e) => e.persist() || onResizeStart(e),
      style: {
        cursor: 'col-resize',
      },
      draggable: false,
      role: 'separator',
    },
  ];
};

const reducer = (state, action) => {
  if (action.type === actions.init) {
    return {
      columnWidths: {},
      columnResizing: {},
      ...state,
    };
  }

  if (action.type === actions.resetResize) {
    return {
      ...state,
      columnWidths: {},
      columnResizing: {},
    };
  }

  if (action.type === actions.resetResizeColumn) {
    const { [action.columnId]: _, ...tail } = state.columnWidths || {};
    return {
      ...state,
      columnWidths: tail,
    };
  }

  if (action.type === actions.setColumnWidths) {
    return {
      ...state,
      columnWidths: functionalUpdate(action.columnWidths, state.columnWidths),
    };
  }

  if (action.type === actions.columnStartResizing) {
    const { type, clientX, columnId, ...tail } = action;

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        ...tail,
        startX: clientX,
        isResizingColumn: columnId,
      },
    };
  }

  if (action.type === actions.columnResizing) {
    const { clientX } = action;
    const {
      startX,
      isResizingColumn,
      minWidth,
      maxWidth,
      columnWidth,
      prevHeaders,
      nextHeaders,
    } = state.columnResizing;

    const deltaX = clientX - startX;
    const newColumnWidths = {
      ...(prevHeaders || []).reduce(
        (acc, [id, width, min, max]) => ({
          ...acc,
          [id]: minmax(width, min, max),
        }),
        {},
      ),
      [isResizingColumn]: minmax(columnWidth + deltaX, minWidth, maxWidth),
      ...(nextHeaders || []).reduce(
        (acc, [id, width, min, max]) => ({
          ...acc,
          [id]: minmax(width, min, max),
        }),
        {},
      ),
    };

    return {
      ...state,
      columnWidths: {
        ...state.columnWidths,
        ...newColumnWidths,
      },
    };
  }

  if (action.type === actions.columnDoneResizing) {
    return {
      ...state,
      columnResizing: {},
    };
  }

  return state;
};

const useInstanceBeforeDimensions = (instance) => {
  const {
    flatHeaders,
    disableResizing,
    getHooks,
    state: { columnResizing, columnWidths },
  } = instance;

  const getInstance = useGetLatest(instance);

  let { columnsOriginalWidths } = instance;
  if (!columnsOriginalWidths) {
    columnsOriginalWidths = {};
    instance.columnsOriginalWidths = columnsOriginalWidths;
  }

  flatHeaders.forEach((header) => {
    const canResize =
      (header.disableResizing === true ? false : undefined) ??
      (disableResizing === true ? false : undefined) ??
      true;

    if (columnsOriginalWidths[header.id] == null) {
      columnsOriginalWidths[header.id] = header.width;
    }

    const originalWidth = columnsOriginalWidths[header.id];

    header.canResize = canResize;
    header.width = columnWidths?.[header.id] ?? originalWidth;
    header.isResizing = columnResizing.isResizingColumn === header.id;

    if (canResize) {
      header.getResizerProps = makePropGetter(getHooks().getResizerProps, {
        instance: getInstance(),
        header,
      });
    }
  });
};

function useInstance(instance) {
  const {
    plugins,
    dispatch,
    autoResetResize = true,
    columns,
    flatHeaders,
    state,
  } = instance;

  ensurePluginOrder(plugins, ['useAbsoluteLayout'], 'useResizeColumns');

  const { columnWidths: widths } = state;

  const getAutoResetResize = useGetLatest(autoResetResize);
  useMountedLayoutEffect(() => {
    if (getAutoResetResize()) {
      dispatch({ type: actions.resetResize });
    }
  }, [columns]);

  const resetResizing = useCallback(
    () => dispatch({ type: actions.resetResize }),
    [dispatch],
  );

  const resetSizeColumn = useCallback(
    (columnId) => () => dispatch({ type: actions.resetResizeColumn, columnId }),
    [dispatch],
  );

  const setColumnWidths = useCallback(
    (columnWidths) => {
      dispatch({ type: actions.setColumnWidths, columnWidths });
    },
    [dispatch],
  );

  const tableHasResized =
    Object.values(widths || {}).filter((value) => value != null).length > 0;

  flatHeaders.forEach((header) => {
    header.resetSize = resetSizeColumn(header.id);
    header.resetAllSizes = resetResizing;
    header.isResized = widths?.[header.id] != null;
    header.tableHasResized = tableHasResized;
  });

  Object.assign(instance, {
    resetResizing,
    setColumnWidths,
  });
}

export const useResizeColumns = (hooks) => {
  hooks.getResizerProps = [defaultGetResizerProps];
  hooks.getHeaderProps.push({
    style: {
      position: 'relative',
    },
  });
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.useInstanceBeforeDimensions.push(useInstanceBeforeDimensions);
};

useResizeColumns.pluginName = 'useResizeColumns';
