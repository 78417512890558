import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/nql-complete';
import {
  actions as usersActions,
  selectors as usersSelectors,
} from '@/redux/api/user';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button from '+components/Button';
import { ActionsContainer } from '+components/Layout';
import { Tab, TabContent, Tabs, TabsContainer } from '+components/Tabs';
import { useLoadingIndicator } from '+hooks';
import useUIProperty from '+hooks/useUIProperty';

import { NqlPresetsTable } from './components/NqlPresetsTable';

const tabs = {
  all: { value: 'all', label: 'All' },
  // recent: { value: 'recent', label: 'Recent' },
  my: { value: 'my', label: 'My' },
  company: { value: 'company', label: 'Company' },
  system: { value: 'system', label: 'System' },
};

const defaultTab = tabs.all;

const useTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = useMemo(() => {
    const search = new URLSearchParams(location.search);

    const tabId = search.get('tab');

    return tabs[tabId] || defaultTab;
  }, [location.search]);

  const onTabChange = useCallback(
    (_, value) => {
      const search = new URLSearchParams();

      if (value !== defaultTab.value) {
        search.set('tab', value);
      }

      navigate({
        pathname: `${RoutePaths.nqlPresets}`,
        search: search.toString(),
      });
    },
    [defaultTab],
  );

  return { currentTab, onTabChange };
};

export const NqlPresets = () => {
  const dispatch = useDispatch();

  const { currentTab, onTabChange } = useTabs();

  const { email } = useSelector(profileSelectors.getProfile);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const users = useSelector(usersSelectors.getUsers);
  const presetsData = useSelector(selectors.getPresets());
  const isFetching = useSelector(selectors.isPresetsFetching);

  const [, setGlobalNqlPresetChange] = useUIProperty(
    'globalNqlPresetChange',
    null,
  );
  const [, setGlobalNqlPresetRemove] = useUIProperty(
    'globalNqlPresetRemove',
    null,
  );

  useLoadingIndicator(isFetching);

  const usersHash = useMemo(
    () =>
      Object.values(users || {}).reduce((acc, user) => {
        acc[user.email] = user;
        return acc;
      }, {}),
    [users],
  );

  const data = useMemo(() => {
    const result = {
      all: [],
      my: [],
      company: [],
      system: [],
    };

    Object.values(presetsData || {}).forEach((item) => {
      const record = {
        ...item,
        user: usersHash[item.email],
      };

      result.all.push(record);

      if (item.system && !isDefaultCustomer) {
        record.user = null;
        record.email = 'Netography';
        result.system.push(record);
        return;
      }

      if (record.email === email) {
        result.my.push(record);
        return;
      }

      result.company.push(record);
    });

    return result;
  }, [users, presetsData, email, isDefaultCustomer]);

  useEffect(() => {
    dispatch(actions.fetchPresets(null, 'nql_presets_tables'));

    return () => {
      dispatch(actions.cancel('nql_presets_tables'));
    };
  }, [dispatch]);

  const usersLength = Object.keys(users || {}).length;
  useEffect(() => {
    if (!usersLength) {
      dispatch(usersActions.requestUsers());
    }
  }, [usersLength]);

  const onAddClick = useCallback(() => {
    setGlobalNqlPresetChange({});
  }, []);

  const onCopy = useCallback((item) => {
    setGlobalNqlPresetChange({
      ...item,
      title: `${item.title} (copy)`,
    });
  }, []);

  const onEdit = useCallback((item) => {
    setGlobalNqlPresetChange(item);
  }, []);

  const onDelete = useCallback((item) => {
    setGlobalNqlPresetRemove(item);
  }, []);

  return (
    <TabsContainer>
      <Tabs
        value={currentTab.value}
        onChange={onTabChange}
        data-tracking="nql-presets-tabs"
      >
        {Object.values(tabs).map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>

      <TabContent>
        <ActionsContainer>
          <Button
            disabled={isFetching}
            onClick={onAddClick}
            testId="nql-presets-add-button"
          >
            Add NQL Preset
          </Button>
        </ActionsContainer>

        <NqlPresetsTable
          data={data[currentTab.value]}
          key={`nql-presets-table-${currentTab.value}`}
          id={`nql-presets-table-${currentTab.value}`}
          onCopy={onCopy}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </TabContent>
    </TabsContainer>
  );
};
