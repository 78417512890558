import PropTypes from '+prop-types';

import { Item as ItemOrigin } from '+components/Menu';

const span = (value) =>
  ['string', 'number'].includes(typeof value) ? (
    <span className="menu-item__content">{value}</span>
  ) : (
    value
  );

const Item = ({ children, ...props }) => (
  <ItemOrigin {...props}>{span(children)}</ItemOrigin>
);

Item.propTypes = {
  children: PropTypes.children.isRequired,
};

export default Item;
