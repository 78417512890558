import { createSelector, createSlice } from '@/redux/util';

export const initialState = {
  indicators: {},
};

const slice = createSlice({
  name: 'globalLoading',
  initialState,

  reducers: {
    change(state, { payload: { id, active } }) {
      if (active) {
        state.indicators[id] = active;
      } else {
        delete state.indicators[id];
      }
    },

    remove(state, { payload: { id } }) {
      delete state.indicators[id];
    },
  },

  sagas: () => ({}),

  selectors: (getState) => ({
    getFullState: createSelector([getState], (state) => state),

    getIsLoading: (id) =>
      createSelector([getState], (state) => state.indicators[id] ?? false),

    isActive: createSelector(
      [getState],
      (state) => Object.values(state?.indicators || {}).find(Boolean) ?? false,
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
