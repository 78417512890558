import { useMemo } from 'react';

import ContextLabel from '+components/Labels/ContextLabel';
import { CellIpLabel, UniversalCell } from '+components/Table/Cells';
import { BaseColumnFactory } from '+components/Table/Columns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  ip: 'ip',
  cvss_rating: 'cvss_rating',
  cvss_score: 'cvss_score',
  vuln_count: 'vuln_count',
};

const columnsCollection = () => ({
  [Columns.ip]: BaseColumnFactory({
    accessor: Columns.ip,
    Header: 'IP Address',
    Cell: UniversalCell(Columns.ip),
    sortType: 'ip',
  }),
  [Columns.cvss_rating]: BaseColumnFactory({
    accessor: Columns.cvss_rating,
    Header: 'CVSS Rating',
    width: 100,
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ContextLabel clickable>
            <CellIpLabel
              field={`label.ip.${Columns.cvss_rating}`}
              value={value}
            />
          </ContextLabel>
        ),
        [value],
      ),
  }),
  [Columns.cvss_score]: BaseColumnFactory({
    accessor: Columns.cvss_score,
    Header: 'CVSS Score',
    width: 100,
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ContextLabel clickable>
            <CellIpLabel
              field={`label.ip.${Columns.cvss_score}`}
              value={value}
            />
          </ContextLabel>
        ),
        [value],
      ),
  }),
  [Columns.vuln_count]: BaseColumnFactory({
    accessor: Columns.vuln_count,
    Header: 'Total Vulnerabilities',
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ContextLabel clickable>
            <CellIpLabel
              field={`label.ip.${Columns.vuln_count}`}
              value={value}
            />
          </ContextLabel>
        ),
        [value],
      ),
  }),
});

export const getColumns = getColumnsHelper(columnsCollection);
