import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as socketControlActions,
  selectors as socketControlSelectors,
} from '@/redux/ui/socketControl';

import Button, { ButtonVariants } from '+components/Button';
import { usePageTabs } from '+components/PageTabs';

/**
 * Array holding pages that must be realtime by default.
 * these paths override realTimePaused settings until user don't press pause button.
 *
 * @type {string[]}
 */
const realtimePaths = [`${RoutePaths.realTimeFlowMap}`];

const SocketControlButton = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [, activePageTab] = usePageTabs();
  const isPaused = useSelector(
    socketControlSelectors.isPaused(activePageTab?.id),
  );
  const hadUserAction = useRef();

  const onPauseToggle = useCallback(
    (event) => {
      hadUserAction.current = true;

      event.stopPropagation();
      if (isPaused) {
        dispatch(socketControlActions.resume(activePageTab?.id));
      } else {
        dispatch(socketControlActions.pause(activePageTab?.id));
      }
    },
    [isPaused, activePageTab?.id],
  );

  useEffect(() => {
    if (!activePageTab?.id) {
      return;
    }

    if (hadUserAction.current) {
      return;
    }

    if (realtimePaths.includes(location.pathname) && isPaused) {
      dispatch(socketControlActions.resume(activePageTab?.id));
    }
  }, [isPaused, location.pathname, activePageTab?.id]);

  useEffect(
    () => () => {
      dispatch(socketControlActions.pause(activePageTab?.id));
    },
    [activePageTab?.id],
  );

  return (
    <Button
      {...props}
      variant={isPaused ? ButtonVariants.outlined : ButtonVariants.contained}
      onClick={onPauseToggle}
      data-tracking="pause-resume-button"
    >
      {isPaused ? 'Stream Live' : 'Stop streaming'}
    </Button>
  );
};

export default SocketControlButton;
