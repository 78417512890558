import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';

import authClient from '@/middleware/authClient';

import useUIProperty from '+hooks/useUIProperty';

import Button from './components/Button';
import Container from './components/Container';
import Description from './components/Description';
import Menu from './components/Menu';
import Shortname from './components/Shortname';
import UserName from './components/UserName';

export const Profile = (props) => {
  const { $separatorWidth, collapse, disabled } = props;

  const dispatch = useDispatch();

  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const isProfileFetching = useSelector(profileSelectors.isFetching);
  const profile = useSelector(profileSelectors.getProfile);

  const [guest] = useUIProperty('guest');
  const [isUnderCovered] = useUIProperty('underCover');
  const [isImpersonating] = useUIProperty('impersonating');

  const [isProfileMenuOpen, setProfileMenuOpen] =
    useUIProperty('profileMenuOpen');

  const username = useMemo(
    () =>
      profile?.name ||
      `${profile.given_name || ''} ${profile.family_name || ''}`.trim(),
    [profile?.name, profile?.given_name, profile?.family_name],
  );

  const onMenuClose = useCallback(() => {
    setProfileMenuOpen(false);
  }, []);

  const onMenuOpen = useCallback(() => {
    setProfileMenuOpen(true);
  }, []);

  const onMenuToggle = useCallback(() => {
    setProfileMenuOpen((prevValue) => !prevValue);
  }, []);

  const onUnimpersonateClick = useCallback(() => {
    onMenuClose();
    dispatch(profileActions.unimpersonate());
  }, [onMenuClose]);

  const onLogoutClick = useCallback(() => {
    onMenuClose();
    authClient.logout();
  }, [onMenuClose]);

  useEffect(() => {
    onMenuClose();
  }, [collapse, onMenuClose]);

  const shortname = profile?.app_metadata?.shortname;
  const picture = profile?.user_metadata?.picture;
  const isMasquerading = isUnderCovered || isImpersonating;

  const description = useMemo(
    () => (
      <Description>
        <UserName>{username}</UserName>
        <Shortname $covered={isMasquerading} data-testid="profile-shortname">
          {shortname}
        </Shortname>
      </Description>
    ),
    [username, shortname, isMasquerading],
  );

  return (
    <Container
      $collapse={collapse}
      onMouseLeave={() => collapse && onMenuClose()}
      onMouseEnter={() => collapse && onMenuOpen()}
      data-testid="profile"
    >
      <Button
        $separatorWidth={$separatorWidth}
        description={collapse ? null : description}
        username={username}
        picture={picture}
        isOpen={isProfileMenuOpen}
        collapse={collapse}
        disabled={guest || disabled}
        onClick={collapse ? null : onMenuToggle}
      />

      <Menu
        $separatorWidth={$separatorWidth}
        description={collapse ? description : null}
        isFetching={isProfileFetching || isCustomerFetching}
        isOpen={isProfileMenuOpen}
        collapse={collapse}
        disabled={disabled}
        onUnimpersonateClick={onUnimpersonateClick}
        onLogoutClick={onLogoutClick}
        onItemClick={onMenuClose}
      />
    </Container>
  );
};

Profile.propTypes = {
  collapse: PropTypes.bool,
  disabled: PropTypes.bool,
  $separatorWidth: PropTypes.number.isRequired,
};

Profile.defaultProps = {
  collapse: false,
  disabled: false,
};

export default Profile;
