import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import * as HSX from 'react-jsx-highcharts';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styled from 'styled-components';

import { CustomType } from '@/models/CustomType';
import { findMetricSettings } from '@/models/MetricSettings';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import { actions as globalFiltersActions } from '@/redux/globalFilters';

import ScaleTypes from '+components/charts/common/ScaleTypes';
import useShowDataLabelsOnPrint from '+components/charts/common/useShowDataLabelsOnPrint';
import useExportingFilename from '+hooks/useExportingFilename';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';
import { intervalToMilliseconds } from '+utils';
import dayjs, { DateFormat } from '+utils/dayjs';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import FlagBackgroundMixin from './common/FlagBackgroundMixin';
import {
  defaultLegendLabelFormatter,
  defaultSeriesColorIndexFormatter,
  defaultSeriesValueFormatter,
} from './common/formatters';
import getPropertiesTrayData from './common/getPropertiesTrayData';
import { Highcharts } from './common/highcharts';
import {
  getLegendPositionOptions,
  LegendMixin,
  LegendPositions,
} from './common/legend';
import SeriesTypes from './common/SeriesTypes';
import {
  defaultColorVariety,
  getDefs,
  lang,
  legendItemClick,
  positioner,
} from './common/utils';

const gridLineColor = 'rgba(200,200,200,0.3)';

const defs = getDefs();

// if scale is logarithmic, 0 (and negative values) are disallowed by Highcharts.
// the 'hack' is to set those values to 0.001, then have formatters only show 2 decimal points.
// @see https://www.highcharts.com/forum/viewtopic.php?t=39841
const logarithmicScaleMin = 0.001;
const logarithmicDataMap = (data) =>
  data.reduce(
    (acc, point) => [
      ...acc,
      [point[0], point[1] <= 0 ? logarithmicScaleMin : point[1]],
    ],
    [],
  );

const getSeries = (type) => {
  switch (type) {
    case SeriesTypes.area:
      return HSX.AreaSeries;
    case SeriesTypes.column:
      return HSX.ColumnSeries;
    default:
      return HSX.LineSeries;
  }
};

const DefaultSeries = getSeries();

const TimeseriesChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    type,
    context,
    fields,
    periodType,
    series,
    legend,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    colorIndex,
    seriesNameFormatter,
    seriesColorIndexFormatter,
    legendLabelFormatter,
    legendPosition,
    xPlotBands,
    scale,
    overrides = {},
    hideTitle,
    hideSubtitle,
    stacked,
    synchronizeTooltip,
    waitingOnNql,
  } = props;

  const dispatch = useDispatch();

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);
  const [, setGlobalContextMenu] = useUIProperty('globalContextMenu', null);

  const chartRef = useRef(null);

  const min = scale === ScaleTypes.logarithmic ? logarithmicScaleMin : 0;
  const [filters] = useGlobalFilters();
  const metric = series?.[0]?.metric;

  const exportingFilename = useExportingFilename(title);

  useShowDataLabelsOnPrint(chartRef.current, 'series');

  const plotOptions = useMemo(
    () => ({
      ...(overrides.plotOptions || {}),
      series: {
        dataLabels: {
          enabled: false,
        },
        stacking: stacked ?? undefined,
        animation: false,
        marker: {
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              radius: 3,
            },
          },
        },
        ...(overrides.plotOptions?.series || {}),
        events: {
          ...(overrides.plotOptions?.series?.events || {}),
        },
      },
      area: {
        lineWidth: 1,
        stacking: stacked ?? undefined,
        animation: false,
        shadow: false,
        dataLabels: { style: { textShadow: false } },
        ...(overrides.plotOptions?.area || {}),
      },
      line: {
        animation: false,
        shadow: false,
        dataLabels: { style: { textShadow: false } },
        ...(overrides.plotOptions?.line || {}),
      },
      column: {
        stacking: 'normal',
        animation: false,
        stickyTracking: false, // hide the tooltip when the mouse leaves
        dataLabels: {
          enabled: false,
        },
        maxPointWidth: 50,
        pointPadding: 0.2,
        groupPadding: 0,
        shadow: false,
        borderRadius: 0,
        ...(overrides.plotOptions?.column || {}),
      },
    }),
    [overrides, stacked],
  );

  const legendPositionOptions = useMemo(
    () => getLegendPositionOptions({ legendPosition, title, subtitle }),
    [legendPosition, title, subtitle],
  );

  let xAxisValueHash = {};
  const formatXAxisCb = useCallback(
    (item) => {
      if (item.isFirst) {
        xAxisValueHash = {};
      }

      const fixedPeriodType = periodType || filters.period.type;
      const { format } = TimePeriods[fixedPeriodType] || {
        format: DateFormat.minuteWithoutYear,
      };
      let xAxisValue = dayjs(item.value).format(format);

      if (fixedPeriodType >= TimeDuration.week) {
        if (xAxisValueHash[xAxisValue]) {
          xAxisValue = dayjs(item.value).format(
            `${format} ${DateFormat.minuteWithoutDate}`,
          );
        }
        xAxisValueHash[xAxisValue] = true;
      }

      return xAxisValue;
    },
    [periodType, filters.period.type],
  );

  const formatYAxisCb = useCallback(
    (item) => {
      const decimals = scale === ScaleTypes.logarithmic ? 2 : undefined;
      const metricSettings = findMetricSettings({ context, metric });
      return metricSettings?.yAxisFormatter(item.value || 0, decimals);
    },
    [context, metric, scale],
  );

  const tooltipFormatter = useCallback(
    function () {
      const that = this;
      const points = that?.points ?? [that.point];
      const { tooltipFormatter: valueFormatter } = findMetricSettings({
        context,
        metric,
      });
      return renderToStaticMarkup(
        <Fragment>
          <span className="tooltip-timestamp">
            {dayjs(that.x).format(DateFormat.minute)}
          </span>
          <span className="tooltip-data">
            {points
              .sort((a, b) => b.y - a.y)
              .map((point) => (
                <span key={point.series.colorIndex} className="tooltip-row">
                  <b
                    className={`tooltip-row-marker highcharts-color-${point.series.colorIndex}`}
                  >
                    ▬
                  </b>
                  <span
                    className="tooltip-row-name"
                    dangerouslySetInnerHTML={{ __html: point.series.name }}
                  />
                  <span className="tooltip-row-value">
                    {valueFormatter(point.y ?? 0)}
                  </span>
                </span>
              ))}
          </span>
        </Fragment>,
      );
    },
    [context, metric],
  );

  const dataLabelFormatter = useCallback(
    function () {
      const that = this;
      const { tooltipFormatter: valueFormatter } = findMetricSettings({
        context,
        metric,
      });
      return valueFormatter(that.y ?? 0);
    },
    [context, metric],
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  const onPushToGF = useCallback((axes) => {
    const extremes = axes[0].getExtremes();
    const extremesMin = Math.round(extremes.min);
    const extremesMax = Math.round(extremes.max);
    if (!extremesMin || !extremesMax) {
      return;
    }
    const unix = `${extremesMin}`.length <= 10;
    dispatch(
      globalFiltersActions.changeFilter({
        period: { type: CustomType },
        from: extremesMin * (unix ? 1000 : 1),
        to: extremesMax * (unix ? 1000 : 1),
        startIsMin: false,
        endIsNow: false,
      }),
    );
  }, []);

  const onLegendItemClick = useCallback(
    (item) => (event) => {
      legendItemClick(event);

      // do not open properties tray if chart is in fullscreen mode
      const chart = chartRef.current;
      if (chart?.fullscreen?.isOpen) {
        return;
      }

      const propertiesTrayData = getPropertiesTrayData({
        context,
        fields,
        item,
        labelContext: filters.labelContext,
      });

      setPropertiesTray({
        data: propertiesTrayData,
        isOpen: true,
      });
    },
    [context, fields, filters.labelContext],
  );

  const onLegendItemContextMenu = useCallback(
    (item) => (event) => {
      const legendSubItemsWrapper = event.target.classList.contains(
        'label-series-group',
      )
        ? event.target
        : event.target.closest('.label-series-group');
      if (!legendSubItemsWrapper) {
        return;
      }

      const legendSubItem = event.target.classList.contains('label-series')
        ? event.target
        : event.target.closest('.label-series');
      if (!legendSubItem) {
        return;
      }

      const legendSubItems =
        legendSubItemsWrapper.getElementsByClassName('label-series');
      const legendSubItemIndex = [...legendSubItems].indexOf(legendSubItem);
      if (legendSubItemIndex === -1) {
        return;
      }

      let globalContextMenuData = getPropertiesTrayData({
        context,
        fields,
        item,
        labelContext: filters.labelContext,
      });
      globalContextMenuData = [globalContextMenuData[legendSubItemIndex]];

      setGlobalContextMenu({
        data: globalContextMenuData,
        event,
      });
    },
    [context, fields, filters.labelContext],
  );

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  const defaultProps = useDefaultPropsHSX({ exporting, onPushToGF });

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(
          className,
          'timeseries-chart',
          `p-${colorVariety ?? defaultColorVariety}`,
          `legend-${legendPosition}`,
          {
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
          },
        )}
        plotOptions={plotOptions}
        boost={{
          // Workaround for uncolored series bug https://gitlab.com/netography/portal/-/issues/665
          // @see https://github.com/highcharts/highcharts/issues/9407
          seriesThreshold: Infinity,
          allowForce: false,
        }}
        scrollbar={{ enabled: false }}
        responsive={{}}
        defs={type === SeriesTypes.area ? defs : undefined}
        callback={onChartCallback}
        __colorVariety={colorVariety}
        {...(overrides.general || {})}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>
          {waitingOnNql ? lang.waitingOnNql : lang.loading}
        </HSX.Loading>

        <HSX.Legend
          {...legendPositionOptions}
          enabled={legend}
          labelFormat={legendLabelFormatter}
          shadow={false}
          squareSymbol={false}
          symbolWidth={0}
          symbolHeight={0}
          symbolPadding={0}
          reverseLegendClickAction
          alignColumns
          useHTML
        />

        <HSX.Tooltip
          useHTML
          shared
          stickyTracking={false}
          followPointer={false} // to avoid tooltip jumping @see: https://gitlab.com/netography/portal/-/issues/1155
          stickOnContact // to avoid tooltip jumping @see https://gitlab.com/netography/portal/-/issues/1155
          crosshairs
          borderRadius={8}
          formatter={tooltipFormatter}
          positioner={positioner}
          shadow={false}
          animation={false}
          {...(overrides.tooltip || {})}
        />

        <HSX.Chart
          type={type}
          zoomType="x"
          resetZoomButton={{
            position: {
              verticalAlign: 'top',
              x: 7,
              y:
                (!!title && !hideTitle) || (!!subtitle && !hideSubtitle)
                  ? 4
                  : 28,
            },
          }}
          width={width}
          height={height}
          marginTop={
            title ||
            subtitle ||
            (legend && legendPosition === LegendPositions.top)
              ? undefined
              : 18
          }
          scrollablePlotArea={{}}
          panning
          panKey="shift"
          animation={false}
          reflow={false}
          __synchronizeTooltip={synchronizeTooltip}
        />

        <HSX.XAxis
          type="datetime"
          labels={{
            format: '{value}',
            formatter: formatXAxisCb,
          }}
          floor={5}
          title={{ text: '' }}
          {...(overrides.xAxis || {})}
          plotBands={xPlotBands}
          crosshair={type !== SeriesTypes.column}
        />

        <HSX.YAxis
          type={scale}
          labels={{
            format: '{value}',
            formatter: formatYAxisCb,
          }}
          min={min}
          floor={0}
          startOnTick={false} // so yAxis.min is honored
          tickPixelInterval={75}
          endOnTick
          maxPadding={0.2}
          gridLineColor={gridLineColor}
          title={{ text: '' }}
          {...(overrides.yAxis || {})}
        >
          {series.length ? (
            series.map((item, i) => {
              const seriesName = seriesNameFormatter({
                ...item,
                labelContext: filters.labelContext,
              });
              const seriesId = `${seriesName}_${i}`;
              const seriesColorIndex =
                colorIndex ??
                item.colorIndex ??
                seriesColorIndexFormatter(item);
              const seriesData =
                scale === ScaleTypes.logarithmic
                  ? logarithmicDataMap(item.data)
                  : item.data;
              const Series = getSeries(item.seriesType || type);

              return (
                <Series
                  {...item.seriesProps}
                  key={seriesId}
                  id={seriesId}
                  name={seriesName}
                  data={seriesData}
                  colorIndex={seriesColorIndex}
                  // We need this to prevent Highcharts update error #15 https://www.highcharts.com/errors/15/
                  jsxOptions={{ updatePoints: false }}
                  dataLabels={{
                    formatter: dataLabelFormatter,
                  }}
                  pointInterval={intervalToMilliseconds(item.interval)}
                  onLegendItemClick={onLegendItemClick(item)}
                  __onLegendItemContextMenu={onLegendItemContextMenu(item)}
                />
              );
            })
          ) : (
            // We need this fake series to display axis when there is no data
            // @see: https://netography.atlassian.net/browse/PORTAL-1336
            <DefaultSeries enableMouseTracking={false} showInLegend={false} />
          )}
        </HSX.YAxis>
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})`
  ${(props) =>
    props.flagValue && props.showFlag && FlagBackgroundMixin(props.flagValue)}

  .highcharts-tooltip > span {
    .tooltip-timestamp {
      font-weight: bold;
    }

    .tooltip-data {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .tooltip-row {
      display: flex;
      align-items: center;
    }

    .tooltip-row-marker {
      font-weight: bold;
      margin-right: 4px;
    }

    .tooltip-row-name {
      display: flex;
      align-items: center;
      margin-right: 6px;
    }

    .tooltip-row-value {
      font-weight: bold;
      margin-left: auto;
      white-space: nowrap;
    }

    span + span {
      margin-top: 2px;
    }
  }

  g.highcharts-tooltip[class*='highcharts-color-'] {
    fill: transparent;
    stroke: transparent;
    color: transparent;
  }

  .highcharts-data-label {
    visibility: hidden;
  }

  ${LegendMixin};
`;

TimeseriesChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart type.
   */
  type: PropTypes.oneOf(Object.values(SeriesTypes)),
  /**
   * Chart context.
   */
  context: PropTypes.string,
  /**
   * Chart request fields.
   */
  fields: PropTypes.arrayOf(PropTypes.string),
  /**
   * Series period type (if null then will be using global filters period type).
   */
  periodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Chart series.
   */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string,
    }),
  ),
  /**
   * If true, legend will be shown.
   */
  legend: PropTypes.bool,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index.
   */
  colorIndex: PropTypes.number,
  /**
   * Series name formatter.
   */
  seriesNameFormatter: PropTypes.func,
  /**
   * Series color index formatter.
   */
  seriesColorIndexFormatter: PropTypes.func,
  /**
   * Legend label formatter.
   */
  legendLabelFormatter: PropTypes.string,
  /**
   * Legend position.
   */
  legendPosition: PropTypes.oneOf(Object.values(LegendPositions)),
  /**
   * Legend position.
   */
  xPlotBands: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * YAxis scale type
   */
  scale: PropTypes.oneOf(Object.values(ScaleTypes)),
  /**
   * Override or extend chart options.
   */
  overrides: PropTypes.shape({}),
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * data series stacking option
   */
  stacked: PropTypes.string,
  /**
   * If true, tooltip will be synchronized between charts.
   */
  synchronizeTooltip: PropTypes.bool,
  /**
   * if true, chart data will not be loaded until user inputs NQL (handled in StatsWrapper)
   * Will show the corresponding message on the chart
   */
  waitingOnNql: PropTypes.bool,
};

TimeseriesChart.defaultProps = {
  className: '',
  title: undefined,
  subtitle: undefined,
  type: SeriesTypes.area,
  context: undefined,
  fields: [],
  periodType: undefined,
  series: [],
  legend: true,
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndex: undefined,
  overrides: {},
  scale: ScaleTypes.linear,
  seriesNameFormatter: defaultSeriesValueFormatter,
  seriesColorIndexFormatter: defaultSeriesColorIndexFormatter,
  legendLabelFormatter: defaultLegendLabelFormatter,
  legendPosition: LegendPositions.bottom,
  xPlotBands: null,
  hideTitle: false,
  hideSubtitle: false,
  stacked: null,
  synchronizeTooltip: true,
  waitingOnNql: false,
};

export default memo(TimeseriesChart);
