import { TimePeriods } from '@/models/TimePeriods';

// TODO: Handle "Now" and "Min Retention" modes
/**
 * Calculate time bounds for a given period.
 * @param period - time period
 * @param from - start time
 * @param to - end time
 * @returns {{start: number, timePeriod: undefined, end: number, interval: undefined}|{start: number, timePeriod: *, end: number, interval}}
 */
export const timeBounds = ({ period, from, to }) => {
  const relativeTimePeriod = TimePeriods[period?.type];

  if (!relativeTimePeriod) {
    return {
      start: Math.trunc(from),
      end: Math.trunc(to),
      interval: undefined,
      timePeriod: undefined,
    };
  }

  return {
    start: relativeTimePeriod.query * Math.max(1, period?.value || 1),
    end: 0,
    interval: relativeTimePeriod.interval,
    timePeriod: relativeTimePeriod,
  };
};
