/* eslint-disable */
export default [
  { offset: '-11:00', label: '(GMT-11:00) Niue', value: 'Pacific/Niue' },
  {
    offset: '-11:00',
    label: '(GMT-11:00) Pago Pago',
    value: 'Pacific/Pago_Pago',
  },
  {
    offset: '-10:00',
    label: '(GMT-10:00) Hawaii Time',
    value: 'Pacific/Honolulu',
  },
  {
    offset: '-10:00',
    label: '(GMT-10:00) Rarotonga',
    value: 'Pacific/Rarotonga',
  },
  { offset: '-10:00', label: '(GMT-10:00) Tahiti', value: 'Pacific/Tahiti' },
  {
    offset: '-09:30',
    label: '(GMT-09:30) Marquesas',
    value: 'Pacific/Marquesas',
  },
  {
    offset: '-09:00',
    label: '(GMT-09:00) Alaska Time',
    value: 'America/Anchorage',
  },
  { offset: '-09:00', label: '(GMT-09:00) Gambier', value: 'Pacific/Gambier' },
  {
    offset: '-08:00',
    label: '(GMT-08:00) Pacific Time',
    value: 'America/Los_Angeles',
  },
  {
    offset: '-08:00',
    label: '(GMT-08:00) Pacific Time - Tijuana',
    value: 'America/Tijuana',
  },
  {
    offset: '-08:00',
    label: '(GMT-08:00) Pacific Time - Vancouver',
    value: 'America/Vancouver',
  },
  {
    offset: '-08:00',
    label: '(GMT-08:00) Pacific Time - Whitehorse',
    value: 'America/Whitehorse',
  },
  {
    offset: '-08:00',
    label: '(GMT-08:00) Pitcairn',
    value: 'Pacific/Pitcairn',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time',
    value: 'America/Denver',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time - Arizona',
    value: 'America/Phoenix',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan',
    value: 'America/Mazatlan',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time - Dawson Creek',
    value: 'America/Dawson_Creek',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time - Edmonton',
    value: 'America/Edmonton',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time - Hermosillo',
    value: 'America/Hermosillo',
  },
  {
    offset: '-07:00',
    label: '(GMT-07:00) Mountain Time - Yellowknife',
    value: 'America/Yellowknife',
  },
  { offset: '-06:00', label: '(GMT-06:00) Belize', value: 'America/Belize' },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Central Time',
    value: 'America/Chicago',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Central Time - Mexico City',
    value: 'America/Mexico_City',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Central Time - Regina',
    value: 'America/Regina',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Central Time - Tegucigalpa',
    value: 'America/Tegucigalpa',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Central Time - Winnipeg',
    value: 'America/Winnipeg',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Costa Rica',
    value: 'America/Costa_Rica',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) El Salvador',
    value: 'America/El_Salvador',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Galapagos',
    value: 'Pacific/Galapagos',
  },
  {
    offset: '-06:00',
    label: '(GMT-06:00) Guatemala',
    value: 'America/Guatemala',
  },
  { offset: '-06:00', label: '(GMT-06:00) Managua', value: 'America/Managua' },
  {
    offset: '-05:00',
    label: '(GMT-05:00) America Cancun',
    value: 'America/Cancun',
  },
  { offset: '-05:00', label: '(GMT-05:00) Bogota', value: 'America/Bogota' },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Easter Island',
    value: 'Pacific/Easter',
  },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Eastern Time',
    value: 'America/New_York',
  },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Eastern Time - Iqaluit',
    value: 'America/Iqaluit',
  },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Eastern Time - Toronto',
    value: 'America/Toronto',
  },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Guayaquil',
    value: 'America/Guayaquil',
  },
  { offset: '-05:00', label: '(GMT-05:00) Havana', value: 'America/Havana' },
  { offset: '-05:00', label: '(GMT-05:00) Jamaica', value: 'America/Jamaica' },
  { offset: '-05:00', label: '(GMT-05:00) Lima', value: 'America/Lima' },
  { offset: '-05:00', label: '(GMT-05:00) Nassau', value: 'America/Nassau' },
  { offset: '-05:00', label: '(GMT-05:00) Panama', value: 'America/Panama' },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Port-au-Prince',
    value: 'America/Port-au-Prince',
  },
  {
    offset: '-05:00',
    label: '(GMT-05:00) Rio Branco',
    value: 'America/Rio_Branco',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Atlantic Time - Halifax',
    value: 'America/Halifax',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Barbados',
    value: 'America/Barbados',
  },
  { offset: '-04:00', label: '(GMT-04:00) Bermuda', value: 'Atlantic/Bermuda' },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Boa Vista',
    value: 'America/Boa_Vista',
  },
  { offset: '-04:00', label: '(GMT-04:00) Caracas', value: 'America/Caracas' },
  { offset: '-04:00', label: '(GMT-04:00) Curacao', value: 'America/Curacao' },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Grand Turk',
    value: 'America/Grand_Turk',
  },
  { offset: '-04:00', label: '(GMT-04:00) Guyana', value: 'America/Guyana' },
  { offset: '-04:00', label: '(GMT-04:00) La Paz', value: 'America/La_Paz' },
  { offset: '-04:00', label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Martinique',
    value: 'America/Martinique',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Port of Spain',
    value: 'America/Port_of_Spain',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Porto Velho',
    value: 'America/Porto_Velho',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Puerto Rico',
    value: 'America/Puerto_Rico',
  },
  {
    offset: '-04:00',
    label: '(GMT-04:00) Santo Domingo',
    value: 'America/Santo_Domingo',
  },
  { offset: '-04:00', label: '(GMT-04:00) Thule', value: 'America/Thule' },
  {
    offset: '-03:30',
    label: '(GMT-03:30) Newfoundland Time - St. Johns',
    value: 'America/St_Johns',
  },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Araguaina',
    value: 'America/Araguaina',
  },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Asuncion',
    value: 'America/Asuncion',
  },
  { offset: '-03:00', label: '(GMT-03:00) Belem', value: 'America/Belem' },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Campo Grande',
    value: 'America/Campo_Grande',
  },
  { offset: '-03:00', label: '(GMT-03:00) Cayenne', value: 'America/Cayenne' },
  { offset: '-03:00', label: '(GMT-03:00) Cuiaba', value: 'America/Cuiaba' },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Fortaleza',
    value: 'America/Fortaleza',
  },
  { offset: '-03:00', label: '(GMT-03:00) Godthab', value: 'America/Godthab' },
  { offset: '-03:00', label: '(GMT-03:00) Maceio', value: 'America/Maceio' },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Miquelon',
    value: 'America/Miquelon',
  },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Montevideo',
    value: 'America/Montevideo',
  },
  { offset: '-03:00', label: '(GMT-03:00) Palmer', value: 'Antarctica/Palmer' },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Paramaribo',
    value: 'America/Paramaribo',
  },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Punta Arenas',
    value: 'America/Punta_Arenas',
  },
  { offset: '-03:00', label: '(GMT-03:00) Recife', value: 'America/Recife' },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Rothera',
    value: 'Antarctica/Rothera',
  },
  { offset: '-03:00', label: '(GMT-03:00) Salvador', value: 'America/Bahia' },
  {
    offset: '-03:00',
    label: '(GMT-03:00) Santiago',
    value: 'America/Santiago',
  },
  { offset: '-03:00', label: '(GMT-03:00) Stanley', value: 'Atlantic/Stanley' },
  { offset: '-02:00', label: '(GMT-02:00) Noronha', value: 'America/Noronha' },
  {
    offset: '-02:00',
    label: '(GMT-02:00) Sao Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    offset: '-02:00',
    label: '(GMT-02:00) South Georgia',
    value: 'Atlantic/South_Georgia',
  },
  { offset: '-01:00', label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  {
    offset: '-01:00',
    label: '(GMT-01:00) Cape Verde',
    value: 'Atlantic/Cape_Verde',
  },
  {
    offset: '-01:00',
    label: '(GMT-01:00) Scoresbysund',
    value: 'America/Scoresbysund',
  },
  { offset: '+00:00', label: '(GMT+00:00) Abidjan', value: 'Africa/Abidjan' },
  { offset: '+00:00', label: '(GMT+00:00) Accra', value: 'Africa/Accra' },
  { offset: '+00:00', label: '(GMT+00:00) Bissau', value: 'Africa/Bissau' },
  {
    offset: '+00:00',
    label: '(GMT+00:00) Canary Islands',
    value: 'Atlantic/Canary',
  },
  {
    offset: '+00:00',
    label: '(GMT+00:00) Casablanca',
    value: 'Africa/Casablanca',
  },
  {
    offset: '+00:00',
    label: '(GMT+00:00) Danmarkshavn',
    value: 'America/Danmarkshavn',
  },
  { offset: '+00:00', label: '(GMT+00:00) Dublin', value: 'Europe/Dublin' },
  { offset: '+00:00', label: '(GMT+00:00) El Aaiun', value: 'Africa/El_Aaiun' },
  { offset: '+00:00', label: '(GMT+00:00) Faeroe', value: 'Atlantic/Faroe' },
  { offset: '+00:00', label: '(GMT+00:00) UTC', value: 'Etc/UTC' },
  { offset: '+00:00', label: '(GMT+00:00) Lisbon', value: 'Europe/Lisbon' },
  { offset: '+00:00', label: '(GMT+00:00) London', value: 'Europe/London' },
  { offset: '+00:00', label: '(GMT+00:00) Monrovia', value: 'Africa/Monrovia' },
  {
    offset: '+00:00',
    label: '(GMT+00:00) Reykjavik',
    value: 'Atlantic/Reykjavik',
  },
  { offset: '+01:00', label: '(GMT+01:00) Algiers', value: 'Africa/Algiers' },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Amsterdam',
    value: 'Europe/Amsterdam',
  },
  { offset: '+01:00', label: '(GMT+01:00) Andorra', value: 'Europe/Andorra' },
  { offset: '+01:00', label: '(GMT+01:00) Berlin', value: 'Europe/Berlin' },
  { offset: '+01:00', label: '(GMT+01:00) Brussels', value: 'Europe/Brussels' },
  { offset: '+01:00', label: '(GMT+01:00) Budapest', value: 'Europe/Budapest' },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Central European Time - Belgrade',
    value: 'Europe/Belgrade',
  },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Central European Time - Prague',
    value: 'Europe/Prague',
  },
  { offset: '+01:00', label: '(GMT+01:00) Ceuta', value: 'Africa/Ceuta' },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Copenhagen',
    value: 'Europe/Copenhagen',
  },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Gibraltar',
    value: 'Europe/Gibraltar',
  },
  { offset: '+01:00', label: '(GMT+01:00) Lagos', value: 'Africa/Lagos' },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Luxembourg',
    value: 'Europe/Luxembourg',
  },
  { offset: '+01:00', label: '(GMT+01:00) Madrid', value: 'Europe/Madrid' },
  { offset: '+01:00', label: '(GMT+01:00) Malta', value: 'Europe/Malta' },
  { offset: '+01:00', label: '(GMT+01:00) Monaco', value: 'Europe/Monaco' },
  { offset: '+01:00', label: '(GMT+01:00) Ndjamena', value: 'Africa/Ndjamena' },
  { offset: '+01:00', label: '(GMT+01:00) Oslo', value: 'Europe/Oslo' },
  { offset: '+01:00', label: '(GMT+01:00) Paris', value: 'Europe/Paris' },
  { offset: '+01:00', label: '(GMT+01:00) Rome', value: 'Europe/Rome' },
  {
    offset: '+01:00',
    label: '(GMT+01:00) Stockholm',
    value: 'Europe/Stockholm',
  },
  { offset: '+01:00', label: '(GMT+01:00) Tirane', value: 'Europe/Tirane' },
  { offset: '+01:00', label: '(GMT+01:00) Tunis', value: 'Africa/Tunis' },
  { offset: '+01:00', label: '(GMT+01:00) Vienna', value: 'Europe/Vienna' },
  { offset: '+01:00', label: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw' },
  { offset: '+01:00', label: '(GMT+01:00) Zurich', value: 'Europe/Zurich' },
  { offset: '+02:00', label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
  { offset: '+02:00', label: '(GMT+02:00) Athens', value: 'Europe/Athens' },
  { offset: '+02:00', label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
  {
    offset: '+02:00',
    label: '(GMT+02:00) Bucharest',
    value: 'Europe/Bucharest',
  },
  { offset: '+02:00', label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { offset: '+02:00', label: '(GMT+02:00) Chisinau', value: 'Europe/Chisinau' },
  { offset: '+02:00', label: '(GMT+02:00) Damascus', value: 'Asia/Damascus' },
  { offset: '+02:00', label: '(GMT+02:00) Gaza', value: 'Asia/Gaza' },
  { offset: '+02:00', label: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki' },
  { offset: '+02:00', label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  {
    offset: '+02:00',
    label: '(GMT+02:00) Johannesburg',
    value: 'Africa/Johannesburg',
  },
  { offset: '+02:00', label: '(GMT+02:00) Khartoum', value: 'Africa/Khartoum' },
  { offset: '+02:00', label: '(GMT+02:00) Kiev', value: 'Europe/Kiev' },
  { offset: '+02:00', label: '(GMT+02:00) Maputo', value: 'Africa/Maputo' },
  {
    offset: '+02:00',
    label: '(GMT+02:00) Moscow-01 - Kaliningrad',
    value: 'Europe/Kaliningrad',
  },
  { offset: '+02:00', label: '(GMT+02:00) Nicosia', value: 'Asia/Nicosia' },
  { offset: '+02:00', label: '(GMT+02:00) Riga', value: 'Europe/Riga' },
  { offset: '+02:00', label: '(GMT+02:00) Sofia', value: 'Europe/Sofia' },
  { offset: '+02:00', label: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn' },
  { offset: '+02:00', label: '(GMT+02:00) Tripoli', value: 'Africa/Tripoli' },
  { offset: '+02:00', label: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius' },
  { offset: '+02:00', label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
  { offset: '+03:00', label: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad' },
  { offset: '+03:00', label: '(GMT+03:00) Istanbul', value: 'Europe/Istanbul' },
  { offset: '+03:00', label: '(GMT+03:00) Minsk', value: 'Europe/Minsk' },
  {
    offset: '+03:00',
    label: '(GMT+03:00) Moscow+00 - Moscow',
    value: 'Europe/Moscow',
  },
  { offset: '+03:00', label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { offset: '+03:00', label: '(GMT+03:00) Qatar', value: 'Asia/Qatar' },
  { offset: '+03:00', label: '(GMT+03:00) Riyadh', value: 'Asia/Riyadh' },
  { offset: '+03:00', label: '(GMT+03:00) Syowa', value: 'Antarctica/Syowa' },
  { offset: '+03:30', label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { offset: '+04:00', label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { offset: '+04:00', label: '(GMT+04:00) Dubai', value: 'Asia/Dubai' },
  { offset: '+04:00', label: '(GMT+04:00) Mahe', value: 'Indian/Mahe' },
  {
    offset: '+04:00',
    label: '(GMT+04:00) Mauritius',
    value: 'Indian/Mauritius',
  },
  {
    offset: '+04:00',
    label: '(GMT+04:00) Moscow+01 - Samara',
    value: 'Europe/Samara',
  },
  { offset: '+04:00', label: '(GMT+04:00) Reunion', value: 'Indian/Reunion' },
  { offset: '+04:00', label: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi' },
  { offset: '+04:00', label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { offset: '+04:30', label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { offset: '+05:00', label: '(GMT+05:00) Aqtau', value: 'Asia/Aqtau' },
  { offset: '+05:00', label: '(GMT+05:00) Aqtobe', value: 'Asia/Aqtobe' },
  { offset: '+05:00', label: '(GMT+05:00) Ashgabat', value: 'Asia/Ashgabat' },
  { offset: '+05:00', label: '(GMT+05:00) Dushanbe', value: 'Asia/Dushanbe' },
  { offset: '+05:00', label: '(GMT+05:00) Karachi', value: 'Asia/Karachi' },
  {
    offset: '+05:00',
    label: '(GMT+05:00) Kerguelen',
    value: 'Indian/Kerguelen',
  },
  { offset: '+05:00', label: '(GMT+05:00) Maldives', value: 'Indian/Maldives' },
  { offset: '+05:00', label: '(GMT+05:00) Mawson', value: 'Antarctica/Mawson' },
  {
    offset: '+05:00',
    label: '(GMT+05:00) Moscow+02 - Yekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  { offset: '+05:00', label: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent' },
  { offset: '+05:30', label: '(GMT+05:30) Colombo', value: 'Asia/Colombo' },
  {
    offset: '+05:30',
    label: '(GMT+05:30) India Standard Time',
    value: 'Asia/Kolkata',
  },
  { offset: '+05:45', label: '(GMT+05:45) Kathmandu', value: 'Asia/Kathmandu' },
  { offset: '+06:00', label: '(GMT+06:00) Almaty', value: 'Asia/Almaty' },
  { offset: '+06:00', label: '(GMT+06:00) Bishkek', value: 'Asia/Bishkek' },
  { offset: '+06:00', label: '(GMT+06:00) Chagos', value: 'Indian/Chagos' },
  { offset: '+06:00', label: '(GMT+06:00) Dhaka', value: 'Asia/Dhaka' },
  {
    offset: '+06:00',
    label: '(GMT+06:00) Moscow+03 - Omsk',
    value: 'Asia/Omsk',
  },
  { offset: '+06:00', label: '(GMT+06:00) Thimphu', value: 'Asia/Thimphu' },
  { offset: '+06:00', label: '(GMT+06:00) Vostok', value: 'Antarctica/Vostok' },
  { offset: '+06:30', label: '(GMT+06:30) Cocos', value: 'Indian/Cocos' },
  { offset: '+06:30', label: '(GMT+06:30) Rangoon', value: 'Asia/Yangon' },
  { offset: '+07:00', label: '(GMT+07:00) Bangkok', value: 'Asia/Bangkok' },
  {
    offset: '+07:00',
    label: '(GMT+07:00) Christmas',
    value: 'Indian/Christmas',
  },
  { offset: '+07:00', label: '(GMT+07:00) Davis', value: 'Antarctica/Davis' },
  { offset: '+07:00', label: '(GMT+07:00) Hanoi', value: 'Asia/Saigon' },
  { offset: '+07:00', label: '(GMT+07:00) Hovd', value: 'Asia/Hovd' },
  { offset: '+07:00', label: '(GMT+07:00) Jakarta', value: 'Asia/Jakarta' },
  {
    offset: '+07:00',
    label: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
  },
  { offset: '+08:00', label: '(GMT+08:00) Brunei', value: 'Asia/Brunei' },
  {
    offset: '+08:00',
    label: '(GMT+08:00) China Time - Beijing',
    value: 'Asia/Shanghai',
  },
  {
    offset: '+08:00',
    label: '(GMT+08:00) Choibalsan',
    value: 'Asia/Choibalsan',
  },
  { offset: '+08:00', label: '(GMT+08:00) Hong Kong', value: 'Asia/Hong_Kong' },
  {
    offset: '+08:00',
    label: '(GMT+08:00) Kuala Lumpur',
    value: 'Asia/Kuala_Lumpur',
  },
  { offset: '+08:00', label: '(GMT+08:00) Macau', value: 'Asia/Macau' },
  { offset: '+08:00', label: '(GMT+08:00) Makassar', value: 'Asia/Makassar' },
  { offset: '+08:00', label: '(GMT+08:00) Manila', value: 'Asia/Manila' },
  {
    offset: '+08:00',
    label: '(GMT+08:00) Moscow+05 - Irkutsk',
    value: 'Asia/Irkutsk',
  },
  { offset: '+08:00', label: '(GMT+08:00) Singapore', value: 'Asia/Singapore' },
  { offset: '+08:00', label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  {
    offset: '+08:00',
    label: '(GMT+08:00) Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
  },
  {
    offset: '+08:00',
    label: '(GMT+08:00) Western Time - Perth',
    value: 'Australia/Perth',
  },
  { offset: '+08:30', label: '(GMT+08:30) Pyongyang', value: 'Asia/Pyongyang' },
  { offset: '+09:00', label: '(GMT+09:00) Dili', value: 'Asia/Dili' },
  { offset: '+09:00', label: '(GMT+09:00) Jayapura', value: 'Asia/Jayapura' },
  {
    offset: '+09:00',
    label: '(GMT+09:00) Moscow+06 - Yakutsk',
    value: 'Asia/Yakutsk',
  },
  { offset: '+09:00', label: '(GMT+09:00) Palau', value: 'Pacific/Palau' },
  { offset: '+09:00', label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { offset: '+09:00', label: '(GMT+09:00) Tokyo', value: 'Asia/Tokyo' },
  {
    offset: '+09:30',
    label: '(GMT+09:30) Central Time - Darwin',
    value: 'Australia/Darwin',
  },
  {
    offset: '+10:00',
    label: "(GMT+10:00) Dumont D'Urville",
    value: 'Antarctica/DumontDUrville',
  },
  {
    offset: '+10:00',
    label: '(GMT+10:00) Eastern Time - Brisbane',
    value: 'Australia/Brisbane',
  },
  { offset: '+10:00', label: '(GMT+10:00) Guam', value: 'Pacific/Guam' },
  {
    offset: '+10:00',
    label: '(GMT+10:00) Moscow+07 - Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    offset: '+10:00',
    label: '(GMT+10:00) Port Moresby',
    value: 'Pacific/Port_Moresby',
  },
  { offset: '+10:00', label: '(GMT+10:00) Truk', value: 'Pacific/Chuuk' },
  {
    offset: '+10:30',
    label: '(GMT+10:30) Central Time - Adelaide',
    value: 'Australia/Adelaide',
  },
  { offset: '+11:00', label: '(GMT+11:00) Casey', value: 'Antarctica/Casey' },
  {
    offset: '+11:00',
    label: '(GMT+11:00) Eastern Time - Hobart',
    value: 'Australia/Hobart',
  },
  {
    offset: '+11:00',
    label: '(GMT+11:00) Eastern Time - Melbourne, Sydney',
    value: 'Australia/Sydney',
  },
  { offset: '+11:00', label: '(GMT+11:00) Efate', value: 'Pacific/Efate' },
  {
    offset: '+11:00',
    label: '(GMT+11:00) Guadalcanal',
    value: 'Pacific/Guadalcanal',
  },
  { offset: '+11:00', label: '(GMT+11:00) Kosrae', value: 'Pacific/Kosrae' },
  {
    offset: '+11:00',
    label: '(GMT+11:00) Moscow+08 - Magadan',
    value: 'Asia/Magadan',
  },
  { offset: '+11:00', label: '(GMT+11:00) Norfolk', value: 'Pacific/Norfolk' },
  { offset: '+11:00', label: '(GMT+11:00) Noumea', value: 'Pacific/Noumea' },
  { offset: '+11:00', label: '(GMT+11:00) Ponape', value: 'Pacific/Pohnpei' },
  {
    offset: '+12:00',
    label: '(GMT+12:00) Funafuti',
    value: 'Pacific/Funafuti',
  },
  {
    offset: '+12:00',
    label: '(GMT+12:00) Kwajalein',
    value: 'Pacific/Kwajalein',
  },
  { offset: '+12:00', label: '(GMT+12:00) Majuro', value: 'Pacific/Majuro' },
  {
    offset: '+12:00',
    label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
    value: 'Asia/Kamchatka',
  },
  { offset: '+12:00', label: '(GMT+12:00) Nauru', value: 'Pacific/Nauru' },
  { offset: '+12:00', label: '(GMT+12:00) Tarawa', value: 'Pacific/Tarawa' },
  { offset: '+12:00', label: '(GMT+12:00) Wake', value: 'Pacific/Wake' },
  { offset: '+12:00', label: '(GMT+12:00) Wallis', value: 'Pacific/Wallis' },
  {
    offset: '+13:00',
    label: '(GMT+13:00) Auckland',
    value: 'Pacific/Auckland',
  },
  {
    offset: '+13:00',
    label: '(GMT+13:00) Enderbury',
    value: 'Pacific/Enderbury',
  },
  { offset: '+13:00', label: '(GMT+13:00) Fakaofo', value: 'Pacific/Fakaofo' },
  { offset: '+13:00', label: '(GMT+13:00) Fiji', value: 'Pacific/Fiji' },
  {
    offset: '+13:00',
    label: '(GMT+13:00) Tongatapu',
    value: 'Pacific/Tongatapu',
  },
  { offset: '+14:00', label: '(GMT+14:00) Apia', value: 'Pacific/Apia' },
  {
    offset: '+14:00',
    label: '(GMT+14:00) Kiritimati',
    value: 'Pacific/Kiritimati',
  },
];
