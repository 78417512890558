/* eslint-disable react/prop-types */
import { Fragment } from 'react';

import capitalize from 'lodash.capitalize';

import { ContextTypes } from '@/models/ContextTypes';

import { VpcIcon } from '@/shared/img/icon';

import Toggle from '+components/form/Toggle';
import {
  CellFlowKey,
  CellIpLabels,
  CellTrafficSourceName,
  UniversalCell,
} from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  RowSelectorColumnFactory,
  ToggleColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories/someOfFieldsFilter';
import Tag from '+components/Tag';
import dayjs from '+utils/dayjs';
import { getColumnsHelper } from '+utils/getColumnsHelper';
import sortByHelper from '+utils/sortByHelper';

import { SourceTypeKeys, SOURCETYPES } from '../utils/sourceTypes';
import { TableContextMenu } from './TableContextMenu';

const SampleRate = ({ row }) => {
  const original = getRowOriginal(row);
  return (
    <Fragment>
      {(!original?.samplerate || original?.samplerate === 1) && (
        <Tag color="info" outlined={false}>
          unsampled
        </Tag>
      )}
      {original?.samplerate > 1 && original?.samplerate}
    </Fragment>
  );
};

const BgpCell = ({ value }) => {
  if (value) {
    return (
      <Tag color="info" outlined={false}>
        Enabled
      </Tag>
    );
  }

  return null;
};

export const Columns = {
  rowSelector: 'rowSelector',
  bgp: 'bgp', // combo
  created: 'created',
  name: 'name',
  state: 'state',
  ips: 'ips',
  lastupdated: 'lastupdated',
  learnedat: 'learnedat',
  learnedsamplerate: 'learnedsamplerate',
  learneduptime: 'learneduptime',
  postnatdst: 'postnatdst',
  postnatsrc: 'postnatsrc',
  samplerate: 'samplerate',
  site: 'site',
  source: 'source',
  trafficType: 'traffictype',
  tags: 'tags',
  menu: 'menu',
  enabled: 'enabled',
};

const tagsExtractor = (value) => (value || []).slice().sort().join('');

const columnsCollection = ({ labelContext, ...props }) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.bgp]: BaseColumnFactory({
    accessor: 'bgpenabled',
    Header: 'bgp',
    width: 120,
    Cell: BgpCell,
    Filter: BooleanColumnFilter({ true: 'Enabled', false: 'Disabled' }),
    filter: 'booleanFilter',
    sortType: 'boolean',
  }),
  [Columns.created]: BaseColumnFactory({
    accessor: 'created',
    Header: 'created',
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: UniversalCell(Columns.created),
  }),
  [Columns.name]: BaseColumnFactory({
    accessor: 'name',
    Header: 'name',
    width: 180,
    getCellProps: () => ({ className: 'name' }),
    Cell: ({ row }) => {
      const original = getRowOriginal(row);
      return (
        <CellTrafficSourceName
          field="flowsrcname"
          id={original.id}
          value={original.name}
          type={original.flowtype ? 'cloud' : 'device'}
        />
      );
    },
  }),
  [Columns.state]: BaseColumnFactory({
    accessor: 'state',
    Header: 'last seen',
    Cell: ({ value, row }) => {
      if (value == null) {
        return null;
      }

      // const original = getRowOriginal(row); // do not use it to show the state in grouped rows
      const { original } = row;
      const isActive = value === 'active';
      const time = original?.stateLastUpdate
        ? dayjs(original?.stateLastUpdate).fromNow(true)
        : null;
      const message = isActive && time ? `${time} ago` : value;
      return (
        <Tag color={isActive ? 'info' : 'secondary'} outlined={false}>
          {message}
        </Tag>
      );
    },
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'active', 'inactive', 'not polling'],
    }),
    filter: 'selectFilter',
    sortType: (rowA, rowB) => {
      const originalA = getRowOriginal(rowA);
      const originalB = getRowOriginal(rowB);
      const timeA = originalA.stateLastUpdate
        ? dayjs(originalA.stateLastUpdate).fromNow(true)
        : null;
      const messageA =
        originalA.state === 'active' && timeA
          ? `${timeA} ago`
          : originalA.state;

      const timeB = originalB.stateLastUpdate
        ? dayjs(originalB.stateLastUpdate).fromNow(true)
        : null;
      const messageB =
        originalB.state === 'active' && timeB
          ? `${timeB} ago`
          : originalB.state;

      return messageA?.localeCompare(messageB);
    },
  }),
  [Columns.ips]: BaseColumnFactory({
    accessor: 'ips',
    Header: 'ips / labels',
    Cell: ({ value: ips, row }) => {
      const original = getRowOriginal(row);
      return (
        <CellIpLabels
          ips={ips}
          labels={original?.labels}
          context={labelContext.ip}
          fetchLabels={labelContext.show}
        />
      );
    },
    realAccessor: ['ips', 'ipsnames'],
    filter: someOfFieldsFilter(
      labelContext.show ? ['ips', 'ipsnames'] : ['ips'],
    ),
  }),
  [Columns.lastupdated]: BaseColumnFactory({
    accessor: 'lastupdated',
    Header: 'lastupdated',
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: UniversalCell(Columns.lastupdated),
  }),
  [Columns.learneduptime]: NumberColumnFactory({
    accessor: Columns.learneduptime,
    maxWidth: 120,
  }),
  [Columns.postnatdst]: BaseColumnFactory({
    accessor: 'postnatdst',
    Header: 'postnatdst',
    maxWidth: 120,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.postnatdst),
  }),
  [Columns.postnatsrc]: BaseColumnFactory({
    accessor: 'postnatsrc',
    Header: 'postnatsrc',
    maxWidth: 120,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    Cell: UniversalCell(Columns.postnatsrc),
  }),
  [Columns.samplerate]: NumberColumnFactory({
    accessor: Columns.samplerate,
    maxWidth: 120,
    textAlign: 'center',
    Cell: SampleRate,
  }),
  [Columns.site]: BaseColumnFactory({
    accessor: (data) => data.site || data.region,
    Header: 'region/site',
    Cell: ({ value }) => <CellFlowKey field="site" value={value} />,
  }),
  [Columns.source]: BaseColumnFactory({
    accessor: (original) => {
      if (original?.logtype === 'aws-tgw-flow-log') {
        return 'aws-tgw/s3';
      }
      if (original?.logtype === 'azure-vnet-flow-log') {
        return 'azure-vnet/blobstorage';
      }
      return original.flowresource
        ? `${original.flowtype}/${original.flowresource}`
        : 'device';
    },
    Header: 'source',
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Filter: SelectColumnFilter({
      optionLabel: (key) =>
        key === 'all' ? 'All' : SOURCETYPES[key]?.label || capitalize(key),
      sort: (a, b) => {
        if (a === 'device') {
          return -1;
        }
        if (b === 'device') {
          return 1;
        }
        const labelA = SOURCETYPES[a]?.label || a;
        const labelB = SOURCETYPES[b]?.label || b;
        return labelA.localeCompare(labelB);
      },
    }),
    filter: 'selectFilter',
    minWidth: 60,
    maxWidth: 60,
    disableResizing: true,
    Cell: ({ row }) => {
      const original = getRowOriginal(row);
      return (
        <VpcIcon
          sourceType={original.sourceType ?? SourceTypeKeys.device}
          size={20}
        />
      );
    },
    sortType: sortByHelper((_, row) => {
      const original = getRowOriginal(row);
      return original?.flowresource
        ? `${original?.flowtype}/${original?.flowresource}`
        : '__device';
    }),
  }),
  [Columns.trafficType]: TrafficColumnFactory({
    accessor: ({ traffictype }) => traffictype || ContextTypes.flow,
  }),
  [Columns.tags]: BaseColumnFactory({
    accessor: 'tags',
    Header: 'tags',
    Cell: UniversalCell(Columns.tags),
    sortType: sortByHelper(tagsExtractor),
  }),
  // second empty argument for toggleColumnFactory is necessary to avoid issues with toggle styling and function
  [Columns.enabled]: ToggleColumnFactory({
    Header: 'polling',
    getDisabled: () => !props.permissions?.cloudPermissions?.update,
    onToggle: props.onEnableToggle,
    Cell: ({ row, value }) =>
      row.values.source === 'device' ? null : (
        <Toggle
          checked={value}
          disabled={!props.permissions?.cloudPermissions?.update}
          onChange={() => props.onEnableToggle(row?.original)}
        />
      ),
  }),
  [Columns.menu]: MenuColumnFactory({
    cxActionMenu: (id, item) => <TableContextMenu {...props} item={item} />,
  }),
});

export const getColumns = getColumnsHelper(columnsCollection);
