import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import * as PropertiesTray from '@/models/PropertiesTray';

import { selectors as customerSelectors } from '@/redux/api/customer';

import Table from '+components/Table';
import { MenuColumnPropertiesTrayTrigger } from '+components/Table/Columns';
import { useAvailableExtractor } from '+hooks/useAvailableExtractor';
import useGlobalFilters from '+hooks/useGlobalFilters';
import { usePreparedColumns } from '+hooks/usePreparedColumns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import { Columns, columnsCollection } from './components/Columns';
import SubDetails, { getRowHeight } from './components/SubDetails';

const defaultColumns = [
  Columns.timestamp,
  Columns._source,
  Columns._srcPort,
  Columns._srcGeo,
  Columns._srcOwnerAs,
  Columns._destination,
  Columns._dstPort,
  Columns.protocol,
  Columns.bits,
  Columns.menu,
];

const FlowTable = (props) => {
  const { columns: columnsProp, tableData, hideSubComponent, ...tail } = props;

  const [filters] = useGlobalFilters();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const columns = useMemo(() => {
    if (customer?.multi_account) {
      const indexOfCustomerColumn = columnsProp.indexOf(Columns.customer);
      if (indexOfCustomerColumn === -1) {
        return [...columnsProp, Columns.customer];
      }
    }
    return columnsProp;
  }, [columnsProp, customer]);

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnPropertiesTrayTrigger
        title={`Flow Details — ${original.id}`}
        dataType={PropertiesTray.DataTypes.record}
        recordType={ContextTypes.flow}
        value={original}
      />
    ),
    [],
  );

  const overrideColumns = useMemo(
    () =>
      columnsCollection({
        labelContext: filters.labelContext,
        cxActionMenu,
      }),
    [cxActionMenu, filters.labelContext],
  );

  const collection = usePreparedColumns(ContextTypes.flow, { overrideColumns });

  const preparedColumns = useMemo(() => Object.keys(collection), [collection]);

  const getColumns = useMemo(() => getColumnsHelper(collection), [collection]);

  const [tableColumns, available] = useAvailableExtractor({
    row: tableData?.[0],
    selectedColumns: columns,
    getColumns,
    preparedColumns,
  });

  return (
    <Table
      data={tableData}
      SubComponent={SubDetails}
      rowHeight={getRowHeight}
      {...tail}
      columns={tableColumns}
      availableColumns={available}
    />
  );
};

FlowTable.propTypes = {
  ...Table.propTypes,
  tableData: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.string),
  noDataText: PropTypes.string,
  hideSubComponent: PropTypes.bool,
};

FlowTable.defaultProps = {
  columns: defaultColumns,
  noDataText: 'No flow received',
  tableData: [],
  hideSubComponent: false,
};

export { Columns, defaultColumns };

export default memo(FlowTable);
