import styled from 'styled-components';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const MainRowSeparator = styled.div`
  flex-shrink: 0;
  align-self: center;
  width: ${({ $width }) => $width}px;
  height: 28px;
  background: ${({ $visible }) =>
    $visible ? globalFiltersTheme.mainRowSeparatorBackground : 'transparent'};
`;

export default MainRowSeparator;
