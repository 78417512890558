import PropTypes from '+prop-types';
import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import { useAuthProfile } from '+hooks/useAuthProfile';
import { chargebee } from '+utils/chargebee';
import { logRocket } from '+utils/logRocket';

const PreloadSources = ({ children }) => {
  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isCustomerFetching = useSelector(customerSelectors.isFetching);
  const isVpcFetching = useSelector(vpcSelectors.isFetching);
  const isDeviceFetching = useSelector(deviceSelectors.isFetching);

  const isStartedFetching = useRef(false);
  const isFetching = useRef(true);

  useEffect(() => {
    isStartedFetching.current = true;
    dispatch(customerActions.fetchCurrentCustomer());
    dispatch(vpcActions.fetchVpcs());
    dispatch(deviceActions.devicesFetch());
  }, []);

  useEffect(() => {
    if (customer?.type && customer?.status) {
      logRocket.init(customer);
    }
  }, [customer?.type, customer?.status]);

  useEffect(() => {
    if (customer?.type === 'plg') {
      chargebee.init();
    }
  }, [customer?.type]);

  if (isFetching.current) {
    isFetching.current =
      !isStartedFetching.current ||
      isCustomerFetching ||
      isVpcFetching ||
      isDeviceFetching;
  }

  return isFetching.current ? null : children;
};
PreloadSources.propTypes = {
  children: PropTypes.children.isRequired,
};

const HasFlowState = ({ children }) => {
  const { isDefault } = useAuthProfile();

  const Wrapper = isDefault ? Fragment : PreloadSources;

  return <Wrapper>{children}</Wrapper>;
};
HasFlowState.propTypes = {
  children: PropTypes.children.isRequired,
};

export const PreloadState = (props) => <HasFlowState {...props} />;
