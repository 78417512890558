import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

export const useAuthProfile = () => {
  const { user } = useAuth();

  const { profile } = user || {};

  return useMemo(
    () => ({
      ...profile,
      isDefault: profile?.app_metadata?.shortname === 'default',
    }),
    [profile],
  );
};
