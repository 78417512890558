import { createSelector, createSlice } from '@/redux/util';

export const initialState = {
  backTo: null,
};

const slice = createSlice({
  name: 'backTo',
  initialState,

  reducers: {
    setBackTo(state, { payload: backTo }) {
      state.backTo = backTo;
    },

    clearBackTo(state) {
      state.backTo = null;
    },
  },

  sagas: () => ({}),

  selectors: (getState) => ({
    getBackTo: createSelector([getState], (state) => state.backTo),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
