import PropTypes from '+prop-types';

import classNames from 'classnames';
import styled from 'styled-components';

import OpenInNewIcon from 'mdi-react/OpenInNewIcon';

import { ColorTypes } from '@/models/ColorTypes';
import RoutePaths from '@/models/RoutePaths';

import Button, { ButtonVariants } from '+components/Button';
import GlobalLink from '+components/GlobalLink';
import Modal, {
  ModalBody as ModalBodyOrigin,
  ModalFooter as ModalFooterOrigin,
  ModalHeader,
} from '+components/Modal';

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

const MessageItem = styled.span`
  display: inline-block;
  color: ${({ theme, $color }) =>
    theme[$color || ColorTypes.primary]} !important;
  margin: 0 0.25rem;
`;

const ModalBody = styled(ModalBodyOrigin)`
  max-height: 500px;
`;

const ModalFooter = styled(ModalFooterOrigin)`
  .gray:not(:hover) {
    color: #c0bfc5 !important;
    border-color: #c0bfc5 !important;
  }
`;

const ModalLink = styled(GlobalLink)`
  display: flex;
`;

const UnderLineLink = styled.span`
  align-items: center;
  height: 20px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  border-color: #c0bfc5 !important;
  border-bottom: 1px dashed;
`;

const LinkContainer = styled.div`
  column-count: 2;
  max-height: 400px;
`;

const LinkIcon = styled(OpenInNewIcon)`
  margin-left: 0.25rem;
  margin-top: 4px;
  color: #4d4d52;
`;

/**
 * Custom confirm delete modal for Detection Models
 */
const CustomConfirmDeleteModal = (props) => {
  const {
    className,
    confirmButtonColor,
    customError,
    item,
    isOpen,
    onClose,
    onRetry,
  } = props;

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} color={confirmButtonColor}>
        Delete Failed
      </ModalHeader>

      <ModalBody>
        <div>
          <MessageContainer>
            <span>Could not delete because the Detection Model is in use.</span>
            <span>
              To proceed, remove{' '}
              <MessageItem $color="danger">{item}</MessageItem> from the
              following Response Policies:
            </span>
          </MessageContainer>
          <LinkContainer>
            {customError.error?.usage?.map?.((usage) => {
              return (
                <ModalLink
                  key={usage.name}
                  href={`${RoutePaths.responsePolicies}/${usage.id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <UnderLineLink>{usage.name}</UnderLineLink>{' '}
                  <LinkIcon size={14} />
                </ModalLink>
              );
            })}
          </LinkContainer>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          onClick={() => onRetry(customError.id)}
          color={confirmButtonColor}
        >
          Retry
        </Button>
        <Button
          $gray={confirmButtonColor === ColorTypes.danger}
          className={classNames({
            gray: confirmButtonColor === ColorTypes.danger,
          })}
          variant={ButtonVariants.outlined}
          onClick={onClose}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CustomConfirmDeleteModal.propTypes = {
  className: PropTypes.string,
  confirmButtonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  customError: PropTypes.shape(),
  isOpen: PropTypes.bool,
  item: PropTypes.string,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
};

CustomConfirmDeleteModal.defaultProps = {
  className: '',
  customError: {},
  confirmButtonColor: '',
  isOpen: false,
  item: '',
  onClose: () => {},
  onRetry: () => {},
};

export default CustomConfirmDeleteModal;
