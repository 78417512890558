import styled from 'styled-components';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: padding-right 0.3s ease-in-out 0.1s;
  padding-right: ${({ $paddingRight }) => `${$paddingRight || 0}px`};

  .form__form-group {
    margin-bottom: unset;
  }
`;

export default FormContainer;
