import PropTypes from '+prop-types';
import { useContext, useEffect, useRef } from 'react';

import styled from 'styled-components';

import MenuOrigin from '@mui/material/Menu';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

import { Context } from './Context';

const StyledMenu = styled(MenuOrigin)`
  .MuiPaper-root {
    max-height: 60vh;
    min-width: 140px;
    background: ${({ theme }) => theme.contextMenuBackground} !important;
    border: 1px solid ${({ theme }) => theme.contextMenuBorderColor};
    ${ScrollBarMixin};
  }

  .MuiMenuItem-root + :not(.MuiMenuItem-root) {
    margin-top: 12px;
  }
`;

const Menu = ({ PaperProps, hideOnClickOutside, ...tail }) => {
  const { id, hide, anchor, isOpen } = useContext(Context);

  const ref = useRef();

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    const handleClick = (e) => {
      // we need this nextElementSibling thing for cases when dropdown contains another dropdown/select/date-time picker,
      // so we don't close the dropdown when clicking on the inner dropdown
      // for example, when clicking on the date-time picker in the global filters dropdown
      const menuEl = document.getElementById(id);
      if (menuEl?.nextElementSibling?.contains(e.target)) {
        return;
      }
      if (!ref.current?.contains(e.target)) {
        e.stopPropagation();
        hide();
      }
    };

    if (hideOnClickOutside) {
      document.addEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [hideOnClickOutside, hide, id, isOpen]);

  return (
    <StyledMenu
      ref={ref}
      id={id}
      elevation={0}
      {...tail}
      slotProps={{
        root: {
          style: {
            width: '0',
            height: '0',
            overflow: 'visible',
          },
        },
        paper: {
          style: {
            transform: 'translateY(4px)',
            minWidth: 'fit-content',
            width: '140px',
            maxWidth: '25vw',
            // eslint-disable-next-line react/prop-types
            ...PaperProps?.style,
          },
        },
      }}
      anchorEl={anchor}
      open={isOpen}
      onClose={hide}
      hideBackdrop
    />
  );
};

Menu.propTypes = {
  PaperProps: PropTypes.object,
  hideOnClickOutside: PropTypes.bool,
};

Menu.defaultProps = {
  PaperProps: {},
  hideOnClickOutside: true,
};

export default Menu;
