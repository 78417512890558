import styled from 'styled-components';

import Item from '@/pages/Layout/SidebarLeft/components/Item';

const ItemStyledLikeGroup = styled(Item)`
  cursor: pointer;
  height: 30px;
  padding: 11px 4px 11px 13px;
  margin: 13px 6px 0 5px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.sideBarGroupItemBackground};

  .sidebar__link-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    .mdi-icon {
      margin: unset;
      width: 15px;
      color: ${({ theme }) => theme.colorSideBarLeftGroupIcon};
    }
  }

  .sidebar__link-title {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    color: ${({ theme }) => theme.colorSideBarLeftGroupTitle};
    line-height: 100%;
  }

  &.sidebar__link--active {
    font-weight: bold;
    .sidebar__link-icon .mdi-icon,
    .sidebar__link-title {
      color: ${({ theme }) => theme.sideBarItemActiveColor};
    }
  }
`;

export default ItemStyledLikeGroup;
