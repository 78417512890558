import styled, { css } from 'styled-components';

import { Dropdown } from '+components/Dropdown';

import { DropdownMixin } from './DropdownMixin';

export const AdditionalFiltersMoreDropdown = styled(Dropdown).attrs(() => ({
  menuProps: {
    PaperProps: {
      style: {
        transform: 'translate(10px, 4px)',
      },
    },
    style: {
      zIndex: 9999999,
    },
  },
}))`
  ${DropdownMixin};
`;

export const AdditionalFiltersMore = styled.li`
  ${({ $maxItems }) => css`
    .additional-filters-row-item:nth-child(-n + ${$maxItems}) {
      display: none;
    }

    .additional-filters-separator:nth-child(-n + ${$maxItems}) {
      display: none;
    }
  `}
`;
