import PropTypes from '+prop-types';
import { Fragment, useCallback, useRef } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import ArrowUpBoldIcon from 'mdi-react/ArrowUpBoldIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import PinIcon from 'mdi-react/PinIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';

import RoutePaths from '@/models/RoutePaths';

import IconButton from '+components/IconButton';
import {
  Menu,
  Item as MenuItem,
  useMenuActions,
  useMenuState,
  withMenu,
} from '+components/Menu';
import { BetaTag } from '+components/Tag';
import Tooltip from '+components/Tooltip';
import useEvent from '+hooks/useEvent';

const dragItemType = 'main-nav-item';

const checkMatching = (patterns, pathname) =>
  (Array.isArray(patterns) ? patterns : [patterns])
    .filter(Boolean)
    .some((path) => matchPath(path, pathname));

const StyledTooltip = styled(Tooltip)`
  max-width: 250px !important;
  padding: 15px;
`;

const Item = styled((props) => {
  const {
    className,
    icon,
    title,
    beta,
    collapse,
    route,
    exact,
    includeRoutes,
    excludeRoutes,
    disableActive,
    id,
    index,
    onMove,
    onMoveUp,
    onMoveDown,
    onUnpin,
    onOpenInNewTab,
    disabled,
    ...tail
  } = props;

  const { pathname } = useLocation();

  const isContextMenuAvailable =
    !!onOpenInNewTab || !!onMoveUp || !!onMoveDown || !!onUnpin;
  const menuState = useMenuState();
  const menuActions = useMenuActions();

  const hasIncludedRoutes = checkMatching(includeRoutes, pathname);
  const hasExcludedRoutes = checkMatching(excludeRoutes, pathname);
  const canBeActive =
    (includeRoutes && hasIncludedRoutes) || !hasExcludedRoutes;

  const ref = useRef(null);

  const isNavDragging = useDragLayer(
    (monitor) => monitor.isDragging() && monitor.getItemType() === dragItemType,
  );

  const [{ isDragging: isItemDragging }, drag] = useDrag({
    type: dragItemType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: dragItemType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      // const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // const clientOffset = monitor.getClientOffset();
      // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }
      onMove?.(item.id, id);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const setClassName = useEvent(({ isActive }) =>
    classNames(className, 'sidebar__link', {
      'sidebar__link--collapse': collapse,
      'sidebar__link--active':
        !disableActive && canBeActive && (isActive || hasIncludedRoutes),
      isItemDragging,
      isNavDragging,
      isContextMenuOpen: menuState?.show,
      'sidebar__link--disabled': disabled,
    }),
  );

  const doMoveUp = useCallback(() => {
    onMoveUp?.(id);
  }, [onMoveUp, id]);

  const doMoveDown = useCallback(() => {
    onMoveDown?.(id);
  }, [onMoveDown, id]);

  const doUnpin = useCallback(
    (e) => {
      // prevent navigation to the route
      e.preventDefault();
      onUnpin?.(id);
    },
    [onUnpin, id],
  );

  const doOpenInNewTab = useCallback(() => {
    onOpenInNewTab?.(route);
  }, [onOpenInNewTab, route]);

  const onContextMenu = useCallback(
    (e) => {
      if (!isContextMenuAvailable) {
        return;
      }
      menuActions?.showMenu?.(e);
    },
    [isContextMenuAvailable, menuActions?.showMenu],
  );

  const handleNavLinkClick = useCallback(
    (e) => {
      if (disabled) {
        e.preventDefault();
      }
    },
    [disabled],
  );

  return (
    <Fragment>
      {isContextMenuAvailable && (
        <Menu>
          {onOpenInNewTab && (
            <MenuItem onClick={doOpenInNewTab}>
              <OpenInNewIcon size={16} />
              <span>Open in new tab</span>
            </MenuItem>
          )}
          {onMoveUp && (
            <MenuItem onClick={doMoveUp}>
              <ArrowUpBoldIcon size={16} />
              <span>Move up</span>
            </MenuItem>
          )}
          {onMoveDown && (
            <MenuItem onClick={doMoveDown}>
              <ArrowDownBoldIcon size={16} />
              <span>Move down</span>
            </MenuItem>
          )}
          {onUnpin && (
            <MenuItem onClick={doUnpin}>
              <PinOffIcon size={16} />
              <span>Unpin</span>
            </MenuItem>
          )}
        </Menu>
      )}
      <StyledTooltip
        title="Your account role does not have the proper read permissions to view this page."
        disabled={!disabled}
        arrow={false}
      >
        <div>
          <NavLink
            {...tail}
            ref={onMove ? ref : undefined}
            data-handler-id={handlerId}
            className={setClassName}
            to={route}
            end={exact}
            onContextMenu={onContextMenu}
            onClick={handleNavLinkClick}
          >
            {icon && <span className="sidebar__link-icon">{icon}</span>}
            {title && !collapse && (
              <span className="sidebar__link-title">{title}</span>
            )}
            {beta && (
              <span className="sidebar__link-beta">
                <BetaTag>Beta</BetaTag>
              </span>
            )}
            {!!onUnpin && !isNavDragging && (
              <IconButton
                className="sidebar__link-unpin"
                size="small"
                title="Unpin"
                onClick={doUnpin}
              >
                <PinIcon className="pin-icon" size={16} />
                <PinOffIcon className="unpin-icon" size={16} />
              </IconButton>
            )}
          </NavLink>
        </div>
      </StyledTooltip>
    </Fragment>
  );
}).attrs(({ theme }) => ({
  key: theme.name,
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  height: 24px;
  padding: 0 0 0 32px;
  overflow: hidden;
  border-radius: 12px;
  gap: 4px;
  transition: background-color 0.3s;
  background-color: transparent;

  &.isContextMenuOpen,
  &:hover {
    background-color: ${({ theme }) => theme.sideBarItemHoverBackground};
    .sidebar__link-unpin {
      display: flex !important;
    }
  }
  .sidebar__link-unpin {
    display: none;
    color: ${({ theme }) => theme.colorText};
    background-color: transparent;
    margin-left: auto;
    .unpin-icon {
      display: none;
    }

    &:hover {
      .unpin-icon {
        display: block;
      }
      .pin-icon {
        display: none;
      }
    }
  }

  .sidebar__link-icon .mdi-icon {
    width: 16px;
  }

  .sidebar__link-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colorText};
  }

  &.sidebar__link--active {
    font-weight: bold;
    .sidebar__link-icon .mdi-icon,
    .sidebar__link-title {
      color: ${({ theme }) => theme.sideBarItemActiveColor};
    }
  }

  &.sidebar__link--collapse {
    justify-content: center;
    padding: unset;
    width: 40px;
    height: 40px;
    border-radius: 20px;

    .sidebar__link-icon .mdi-icon {
      width: 26px;
      height: 26px;
      color: ${({ theme }) => theme.colorText};
    }
  }

  &.sidebar__link--disabled {
    background-color: transparent;
    cursor: default;
    .sidebar__link-title {
      color: ${({ theme }) => theme.colorMenuDisabledText};
    }
  }

  &.isItemDragging:not(.sidebar__link--active) {
    background-color: ${({ theme }) => theme.sideBarItemHoverBackground};
  }

  &.isNavDragging:not(.isItemDragging):not(.sidebar__link--active):hover {
    background-color: unset;
  }
`;

Item.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.children]).isRequired,
  beta: PropTypes.bool,
  collapse: PropTypes.bool,
  route: PropTypes.string,
  exact: PropTypes.bool,
  includeRoutes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  excludeRoutes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disableActive: PropTypes.bool,
  id: PropTypes.string,
  index: PropTypes.number,
  onMove: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onUnpin: PropTypes.func,
  onOpenInNewTab: PropTypes.func,
  disabled: PropTypes.bool,
};

Item.defaultProps = {
  className: '',
  icon: null,
  beta: false,
  collapse: false,
  route: `${RoutePaths.home}`,
  exact: false,
  includeRoutes: null,
  excludeRoutes: null,
  disableActive: false,
  id: null,
  index: null,
  onMove: null,
  onMoveUp: null,
  onMoveDown: null,
  onUnpin: null,
  onOpenInNewTab: null,
  disabled: false,
};

export default withMenu(Item);
