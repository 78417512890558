import { useMemo } from 'react';
import {
  Form,
  FormSpy,
  useField,
  useForm,
  useFormState,
} from 'react-final-form';

import { ARRAY_ERROR, FORM_ERROR } from 'final-form';
import createFocusOnFirstFieldDecorator from 'final-form-focus-on-first-field';

import { usePageTabs } from '+components/PageTabs';

import mutators from './mutators';

export * from 'react-final-form-arrays';
export { default as Field } from './Field';

// eslint-disable-next-line react/prop-types
export const FinalForm = ({ validateOnBlur, focusOnFields, ...rest }) => {
  const [, activePageTab] = usePageTabs();

  const decorators = useMemo(
    () => [createFocusOnFirstFieldDecorator(focusOnFields)],
    [focusOnFields],
  );

  return (
    <Form
      key={activePageTab?.id}
      mutators={mutators}
      decorators={decorators}
      {...rest}
      validateOnBlur={validateOnBlur}
      isValidationOnBlur={validateOnBlur}
    />
  );
};

export { useForm, useFormState, useField, FormSpy, FORM_ERROR, ARRAY_ERROR };

export default FinalForm;
