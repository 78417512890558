import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { Col, Row } from '+components/Layout';

import { BodySeparator } from '../../BodyComponents';
import IpIntelligence from '../../IpIntelligence';
import Title from '../../Title';
import TitleContainer from '../../TitleContainer';

const getTitle = (item) => `${item.type} — ${item.ip}`;

const IpIntelligenceWrapper = ({ padding, recordType, record }) => {
  const [activeDataIndex, setActiveDataIndex] = useState(null);

  const ipIntellData = useMemo(() => {
    const data = [];

    if (
      recordType === ContextTypes.flow ||
      recordType === ContextTypes.blocks ||
      recordType === ContextTypes.dns
    ) {
      if (record?.srcip) {
        data.push({
          type: 'srcip',
          ip: record.srcip,
        });
      }
      if (record?.dstip) {
        data.push({
          type: 'dstip',
          ip: record.dstip,
        });
      }
    }

    if (recordType === ContextTypes.alerts) {
      record.ipinfo?.forEach((item) => {
        data.push({
          type: item.dstip ? 'dstip' : 'srcip',
          ip: item.ip,
        });
      });
    }

    return data;
  }, [recordType, record]);

  const dataLength = ipIntellData.length;

  const onTitleClick = useCallback(
    (index) => () =>
      setActiveDataIndex((prevValue) => (prevValue === index ? null : index)),
    [],
  );

  useEffect(() => {
    setActiveDataIndex(dataLength > 1 ? null : 0);
  }, [ipIntellData, dataLength]);

  return (
    <Col>
      {ipIntellData.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`ipIntell-${index}`}>
          <TitleContainer
            $padding={padding}
            $active={activeDataIndex === index}
            $disabled={dataLength === 1}
            onClick={onTitleClick(index)}
          >
            <Title>{getTitle(item)}</Title>
            <ChevronRightIcon />
          </TitleContainer>

          <BodySeparator />

          {activeDataIndex === index && (
            <Row padding="20px 0" minHeight="200px" alignItems="center">
              <IpIntelligence padding={padding} ip={item.ip} showLabels />
            </Row>
          )}

          {activeDataIndex === index && index !== dataLength - 1 && (
            <BodySeparator />
          )}
        </Fragment>
      ))}
    </Col>
  );
};

IpIntelligenceWrapper.propTypes = {
  padding: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default IpIntelligenceWrapper;
