import { useSelector } from 'react-redux';

import { selectors as deviceSelectors } from '@/redux/api/device';
import { selectors as vpcSelectors } from '@/redux/api/vpc';

import { useAuthProfile } from '+hooks/useAuthProfile';

export const useHasSources = () => {
  const { isDefault } = useAuthProfile();

  const devices = useSelector(deviceSelectors.getDevices);
  const vpcs = useSelector(vpcSelectors.getVpcs);

  const hasDevices = !!Object.keys(devices || {}).length;
  const hasVpcs = !!Object.keys(vpcs || {}).length;

  return isDefault || hasDevices || hasVpcs;
};
