import PropTypes from '+prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';

import capitalize from 'lodash.capitalize';
import isEqual from 'lodash.isequal';
import styled from 'styled-components';

import PermissionModel from '@/models/Permission';

import CheckBoxField from '+components/form/CheckBox';
import {
  Field,
  FieldArray,
  useForm,
  useFormState,
} from '+components/form/FinalForm';
import { Group, Label } from '+components/form/FormField';
import { Col, Row } from '+components/Layout';

import RenderPermissions from './RenderPermissions';

const initialControlValues = {
  fetch: false,
  create: false,
  update: false,
  delete: false,
};

const ControlGroup = styled(Group)`
  margin-bottom: -15px;
  margin-top: 30px;
`;
const { ResourceGroups, Actions } = PermissionModel;
const manageActions = Object.values(Actions);

const PermissionGroupOrder = {
  [ResourceGroups.action]: 1,
  [ResourceGroups.users]: 2,
  [ResourceGroups.data]: 3,
  [ResourceGroups.ndr]: 4,
  [ResourceGroups.portal]: 5,
};

const permissionComparator = (a, b) => {
  if (PermissionGroupOrder[a] === PermissionGroupOrder[b]) {
    return 0;
  }
  return PermissionGroupOrder[a] < PermissionGroupOrder[b] ? -1 : 1;
};

const PermissionsSection = (props) => {
  const { resourceGroups, canManage, canManageResellerParams, disabled } =
    props;
  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });
  const [controlIndeterminate, setControlIndeterminate] =
    useState(initialControlValues);

  const prevControlsRef = useRef(initialControlValues);
  const prevPermissionsRef = useRef(formValues.permissions || {});
  const userChangedControls = useRef(false);

  useEffect(() => {
    const newValues = { ...initialControlValues, ...formValues.controls };
    let prevValues = prevControlsRef.current;

    prevControlsRef.current = newValues;

    // if permissions controls change, update permissions values to match
    if (
      formValues.controls &&
      !isEqual(newValues, prevValues) &&
      userChangedControls.current
    ) {
      const diff = Object.fromEntries(
        Object.entries(newValues).filter(
          ([key, val]) => prevValues[key] !== val,
        ),
      );

      const anyTrue = Object.values(diff).some(Boolean);

      const newPermissions = formValues?.permissions.map((original) => ({
        ...original,
        fetch: original.fetch || anyTrue,
        ...diff,
      }));
      form.change('permissions', newPermissions);
      userChangedControls.current = false;
      return;
    }

    prevValues = prevPermissionsRef.current;

    prevPermissionsRef.current = formValues.permissions;

    // if permissions changed, change control indeterminate values to match
    if (
      formValues.permissions &&
      !isEqual(formValues.permissions, prevValues)
    ) {
      const newIndeterminate = { ...initialControlValues };

      Object.values(Actions).forEach((item) => {
        let allTrue = true;
        let allFalse = true;

        formValues.permissions.every((permission) => {
          const value = Boolean(permission[item]);

          allTrue = allTrue && value;
          allFalse = allFalse && !value;

          return allTrue || allFalse;
        });

        if (allTrue || allFalse) {
          const itemState = allTrue || !allFalse;
          form.change(`controls.${item}`, itemState);
        }

        newIndeterminate[item] = !allTrue && !allFalse;
      });

      setControlIndeterminate(newIndeterminate);
    }
  }, [formValues.controls, formValues.permissions]);

  return (
    <Fragment>
      <ControlGroup>
        <Row wrap="nowrap" gap="20px" alignItems="center">
          <Label>{/* empty label for proper formatting */} </Label>
          <Col container={false} xs item>
            <Row wrap="nowrap" gap="42px" alignItems="center">
              {manageActions.map((item) => (
                <Field
                  key={item}
                  name={`controls.${item}`}
                  type="checkbox"
                  component={CheckBoxField}
                  label={capitalize(item === Actions.fetch ? 'read' : item)}
                  indeterminate={controlIndeterminate[item]}
                  disabled={disabled || !canManage}
                  onChange={() => {
                    userChangedControls.current = true;
                  }}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </ControlGroup>

      {Object.keys(resourceGroups)
        .sort(permissionComparator)
        .map((resourceGroup) => (
          <FieldArray
            key={resourceGroup}
            name="permissions"
            component={RenderPermissions}
            resourceGroup={resourceGroup}
            canManageResellerParams={canManageResellerParams}
            disabled={disabled || !canManage}
          />
        ))}
    </Fragment>
  );
};

PermissionsSection.propTypes = {
  resourceGroups: PropTypes.shape().isRequired,
  canManage: PropTypes.bool.isRequired,
  canManageResellerParams: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PermissionsSection;
