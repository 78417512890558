import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import {
  actions as responseIntegrationsActions,
  selectors as responseIntegrationsSelectors,
} from '@/redux/api/integrations/response';

import { usePageTabs } from '+components/PageTabs';

import NoData from '../shared/NoData';
import { ResponseIntegrationModels } from '../shared/ResponseIntegrationModels';
import { paramsToUi, uiToParams } from './components/utils';
import Form from './Form';

const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();

  const { adapter } = useParams();
  const { isFetching, error } = useSelector(
    responseIntegrationsSelectors.getState,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const model = useMemo(() => ResponseIntegrationModels[adapter], [adapter]);

  const initialValues = useMemo(() => paramsToUi(adapter), [adapter]);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      const data = uiToParams(adapter, values);

      dispatch(
        responseIntegrationsActions.addPlugin(
          data,
          'ndr_integrations_add_plugin',
        ),
      );
    },
    [adapter, activePageTab?.id],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.integrationsResponse}`);
  }, []);

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      onCancel();
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id, onCancel]);

  return !model ? (
    <NoData>No Provider Found</NoData>
  ) : (
    <Form
      mode="add"
      model={model}
      initialValues={initialValues}
      isFetching={isFetching}
      onSubmit={onSubmit}
      onCancel={onCancel}
      confirmButtonText="Create"
      deleteButtonHidden
    />
  );
};

export default Add;
