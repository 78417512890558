import { useCallback, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import IpLabels from '@/pages/Labels/IpLabels';
import PortLabels from '@/pages/Labels/PortLabels';

import { CrumbRoute } from '+components/Breadcrumb';
import { Tab, TabContent, Tabs, TabsContainer } from '+components/Tabs';

const tabs = {
  ip: { value: RoutePaths.labelsIp.pageName, label: 'IP Labels' },
  port: { value: RoutePaths.labelsPort.pageName, label: 'Port Labels' },
};

const defaultTab = tabs.ip;

const LabelsRoutes = () => {
  const navigate = useNavigate();
  const { params: { id: tabId } = {} } =
    useMatch(`${RoutePaths.labels}/:id/*`) || {};

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabId, tabs, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      // if (value === defaultTab.value) {
      //   navigate(rootPath);
      //   return;
      // }
      navigate(`${RoutePaths.labels}/${value}`);
    },
    [defaultTab],
  );

  const addIntegrationMatch = useMatch(
    `${RoutePaths.labels}/integrations/add/*`,
  );
  const editIntegrationMatch = useMatch(
    `${RoutePaths.labels}/integrations/edit/*`,
  );
  const hideTabs = addIntegrationMatch || editIntegrationMatch;

  return (
    <TabsContainer>
      {!hideTabs && (
        <Tabs value={currentTab.value} onChange={onTabChange}>
          {Object.values(tabs).map(({ value, label }) => (
            <Tab key={value} label={label} value={value} />
          ))}
        </Tabs>
      )}

      <TabContent>
        <Routes>
          <Route
            path={`${RoutePaths.labelsIp.pageName}/*`}
            element={<CrumbRoute title="IP Labels" component={IpLabels} />}
          />

          <Route
            path={`${RoutePaths.labelsPort.pageName}/*`}
            element={<CrumbRoute title="Port Labels" component={PortLabels} />}
          />

          <Route
            path="*"
            element={<Navigate to={RoutePaths.labelsIp.pageName} replace />}
          />
        </Routes>
      </TabContent>
    </TabsContainer>
  );
};

export default LabelsRoutes;
