import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import PermissionModel from '@/models/Permission';

import {
  actions as bgpActions,
  selectors as bgpSelectors,
} from '@/redux/api/bgp';

import Button from '+components/Button';
import AggStackedBarChart from '+components/charts/AggStackedBarChart';
import PieChart from '+components/charts/PieChart';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer, Col, Row } from '+components/Layout';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePermissions from '+hooks/usePermissions';

import BgpFormModal from './components/Form';
import BgpTable from './components/Table';
import BgpTablePrefixes from './components/TablePrefixes';

const countStats = (neighbors) => {
  const stats = {
    accepted: 0,
    advertised: 0,
    prefixrate: 0,
    prefixtotalcount: 0,
    prefixsessioncount: 0,
    received: 0,
  };

  const totalData = [];
  const sessionData = [];
  const advertisedData = [];
  const blockrateData = [];
  const stackedData = [];
  const pieData = [];

  neighbors.forEach((neighbor) => {
    const { routes = {} } = neighbor;
    const { total } = routes;

    if (total) {
      const name = `${neighbor.addresslocal}-${neighbor.addressremote}`;

      Object.entries(total).forEach(([key, val]) => {
        stats[key] += val;
      });

      totalData.push({ name, value: total.prefixtotalcount });
      sessionData.push({ name, value: total.prefixsessioncount });
      advertisedData.push({ name, value: total.advertised });
      blockrateData.push({ name, value: total.prefixrate });

      stackedData.push({
        name,
        y: total.prefixtotalcount,
        data: [total.prefixsessioncount, total.advertised, total.prefixrate],
      });

      pieData.push({
        name,
        y: total.prefixtotalcount,
      });
    }
  });

  return {
    stats: [stats],
    totalData,
    sessionData,
    advertisedData,
    blockrateData,
    stackedData: stackedData.sort((a, b) => b.y - a.y),
    pieData: pieData.sort((a, b) => b.y - a.y),
  };
};

const BgpTab = (props) => {
  const { device } = props;

  const dispatch = useDispatch();

  const [filters] = useGlobalFilters();
  const neighbors = useSelector(
    bgpSelectors.getNeighborsByDeviceId(device?.id),
  );
  const permissions = usePermissions(PermissionModel.Resources.device.value);

  const [currentItem, setCurrentItem] = useState(false);
  const [showForm, toggleForm] = useToggle(false);
  const [showDelete, toggleDelete] = useToggle(false);

  const canManage = currentItem?.id ? permissions?.update : permissions?.create;
  const canRemove = currentItem?.id && permissions?.delete;

  const { stats, stackedData, pieData } = useMemo(
    () => countStats(neighbors),
    [neighbors],
  );

  const toggleAdd = useCallback(() => {
    setCurrentItem({});
    toggleForm(true);
  }, [toggleForm]);

  const onDeleteModalConfirm = useCallback(() => {
    if (!currentItem?.id) {
      return;
    }
    dispatch(bgpActions.deleteNeighbor(currentItem.id));
    toggleForm(false);
  }, [toggleForm, currentItem]);

  useEffect(() => {
    if (device?.id) {
      dispatch(bgpActions.fetchNeighborsByDeviceId(device?.id));
    }
  }, [device?.id, filters.refresher]);

  return (
    <Fragment>
      <ActionsContainer>
        <Button onClick={toggleAdd} disabled={!permissions?.create}>
          Add BGP Neighbor
        </Button>
      </ActionsContainer>

      <Col gap="15px">
        <Row>
          <Col xs={12} item container={false}>
            <BgpTablePrefixes data={stats} />
          </Col>
        </Row>

        <Row columnSpacing={1} style={{ height: 300, overflow: 'hidden' }}>
          <Col md={4} item container={false}>
            <PieChart
              title="Total Prefixes"
              series={{ data: pieData }}
              legend={false}
            />
          </Col>

          <Col md={8} item container={false}>
            <AggStackedBarChart
              title="NGP Neighbor Breakdown"
              series={stackedData}
              categories={['Session', 'Advertised', 'Block Rate']}
              legend={false}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} item container={false}>
            <BgpTable
              neighbors={neighbors}
              toggleForm={toggleForm}
              toggleDelete={toggleDelete}
              setCurrentItem={setCurrentItem}
              permissions={permissions}
            />
          </Col>
        </Row>
      </Col>

      {showForm && (
        <BgpFormModal
          item={currentItem}
          device={device}
          toggleModal={toggleForm}
          deleteButtonText="Delete BGP Neighbor"
          onDelete={toggleDelete}
          deleteButtonHidden={!currentItem?.id}
          deleteButtonDisabled={!canRemove}
          disabled={!canManage}
          isOpen
        />
      )}

      {showDelete && (
        <ConfirmModal
          item={currentItem.addressremote}
          onToggle={toggleDelete}
          onConfirm={onDeleteModalConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

BgpTab.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default BgpTab;
