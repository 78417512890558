import { ContextTypes } from '@/models/ContextTypes';

import makeArr from '+utils/makeArr';
import nqlLang from '+utils/nqlLang';

/**
 * Stats request types.
 * @enum {string}
 */
export const Types = {
  ts: 'ts',
  agg: 'agg',
  heatmap: 'heatmap',
  sankey: 'sankey',
};

// Upto 5 NQL search queries and 5 intersected fields for flows only
// @see: https://netography.atlassian.net/browse/BE-382
export const SearchConfig = {
  [ContextTypes.alerts]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.audit]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.blocks]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.flow]: {
    maxNqlQueries: 5,
    maxIntersects: 5,
  },
  [ContextTypes.dns]: {
    maxNqlQueries: 5,
    maxIntersects: 5,
  },
  [ContextTypes.traffic]: {
    maxNqlQueries: 5,
    maxIntersects: 5,
  },
  [ContextTypes.thresholdFlow]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.thresholdDns]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
};

export const StatsConfig = {
  [ContextTypes.alerts]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.audit]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.blocks]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.flow]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.dns]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.traffic]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.thresholdFlow]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
  [ContextTypes.thresholdDns]: {
    maxNqlQueries: 1,
    maxIntersects: 0,
  },
};

export const makeSearch = (params) => {
  let search = makeArr(params?.search).filter((item) => !!item?.trim());

  if (params?.andSearch || params?.orSearch) {
    if (!search.length) {
      search.push('');
    }
    search = search.map((item) =>
      nqlLang.or(nqlLang.and(item, params?.andSearch), params?.orSearch),
    );
  }

  search = search?.length ? search : null;

  let intersect =
    search?.length > 1 ? makeArr(params?.intersect).filter(Boolean) : null;
  intersect = intersect?.length ? intersect : null;

  return search
    ? {
        search,
        ...(!!intersect && { intersect }),
      }
    : null;
};

export default {
  Types,
  SearchConfig,
  StatsConfig,
  makeSearch,
};
