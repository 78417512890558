import PropTypes from '+prop-types';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import { useTheme } from 'styled-components';

import { Col } from '+components/Layout';
import { darkTheme, lightTheme } from '+theme';

const diffViewerStyleOverrides = {
  variables: {
    dark: {
      diffViewerBackground: darkTheme.cardBackgroundColor,
      gutterBackground: darkTheme.cardBackgroundColor,
      codeFoldGutterBackground: darkTheme.colorBackground,
      codeFoldBackground: darkTheme.colorBackground,
      emptyLineBackground: darkTheme.cardBackgroundColor,
      diffViewerTitleBackground: darkTheme.cardBackgroundColor,
      diffViewerTitleBorderColor: darkTheme.cardBackgroundColor,
      diffViewerTitleColor: darkTheme.colorText,
    },
    light: {
      diffViewerBackground: lightTheme.cardBackgroundColor,
      gutterBackground: lightTheme.cardBackgroundColor,
      codeFoldGutterBackground: lightTheme.cardBackgroundColor,
      codeFoldBackground: lightTheme.cardBackgroundColor,
      emptyLineBackground: lightTheme.cardBackgroundColor,
      diffViewerTitleBackground: lightTheme.cardBackgroundColor,
      diffViewerTitleBorderColor: lightTheme.cardBackgroundColor,
      diffViewerTitleColor: lightTheme.colorText,
    },
  },
};

const stringifyOrdered = (obj) => {
  if (!obj) {
    return '';
  }

  const allKeys = new Set();
  JSON.stringify(obj, (key, value) => {
    allKeys.add(key);
    return value;
  });
  return JSON.stringify(obj, Array.from(allKeys).sort(), 2);
};

const JsonDiffViewer = (props) => {
  const { name } = useTheme();
  const { auditlog } = props;

  return (
    <Col>
      <ReactDiffViewer
        oldValue={stringifyOrdered(auditlog?.original_object)}
        newValue={stringifyOrdered(auditlog?.new_object)}
        compareMethod={DiffMethod.WORDS}
        styles={diffViewerStyleOverrides}
        useDarkTheme={name === 'dark'}
        leftTitle="Old Object"
        rightTitle="New Object"
      />
    </Col>
  );
};

JsonDiffViewer.propTypes = {
  auditlog: PropTypes.shape().isRequired,
};

export default JsonDiffViewer;
