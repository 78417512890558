import { useCallback, useState } from 'react';

import { Tab, TabContent, Tabs, TabsContainer } from '+components/Tabs';

import JsonView from './components/JsonView';
import TableView from './components/TableView';

const tabs = {
  table: { value: 'table', label: 'Table' },
  json: { value: 'json', label: 'JSON' },
};

const defaultTab = tabs.table;

const TableTabsSub = (props) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);

  const onTabChange = useCallback(
    (_, value) => {
      setCurrentTab(tabs[value]);
    },
    [tabs],
  );

  return (
    <TabsContainer>
      <Tabs value={currentTab.value} onChange={onTabChange}>
        <Tab label={tabs.table.label} value={tabs.table.value} />
        <Tab label={tabs.json.label} value={tabs.json.value} />
      </Tabs>

      {currentTab.value === tabs.table.value && (
        <TabContent>
          <TableView {...props} />
        </TabContent>
      )}
      {currentTab.value === tabs.json.value && (
        <TabContent>
          <JsonView {...props} />
        </TabContent>
      )}
    </TabsContainer>
  );
};

export { tabs, TableView, JsonView };
export default TableTabsSub;
