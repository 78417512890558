import PropTypes from '+prop-types';
import { Fragment, memo, useMemo } from 'react';

import ButtonGroupField from '+components/form/ButtonGroupField';
import FieldsSection from '+components/form/FieldsSection';
import { Field, useFormState } from '+components/form/FinalForm';
import MultiSelectField from '+components/form/MultiSelectField';
import NetofuseTransformField from '+components/form/NetofuseTransformField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import NumberField from '+components/form/NumberField';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateNumber,
  validateRequired,
  validateTextJsonArray,
  validateTransformJson,
} from '+components/form/Validators';

import NoData from '../../NoData';

const beautifyStringIfJson = (value) => {
  try {
    return JSON.stringify(JSON.parse(value), null, 4);
  } catch (e) {
    return value;
  }
};

const parseTextList = (value) => {
  let copy = `${value}`;
  if (!copy.startsWith('[')) {
    copy = `[${copy}`;
  }
  if (!copy.endsWith(']')) {
    copy = `${copy}]`;
  }
  return copy;
};

const sortByOrder = ([, a], [, b]) =>
  (a.orderIndex ?? Infinity) - (b.orderIndex ?? Infinity);

const getSorted = (inFields, order) => {
  const fields = { ...(inFields || {}) };

  (order || []).forEach((key, index) => {
    if (!fields[key]) {
      return;
    }

    fields[key] = {
      ...fields[key],
      orderIndex: index,
    };
  });

  return Object.entries(fields).sort(sortByOrder);
};

// these are pre-populated by the form component
const universalFieldNames = ['name', 'updateinterval', 'enabled'];

const getCommonFieldAttributes = (key, field, disabled) => ({
  key,
  name: key,
  label: field.label,
  helperText: field.description,
  required: field.required,
  autoComplete: field.autoComplete,
  disabled: disabled === true,
  parseDescriptionUrls: true,
});

const Fields = {
  password: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={TextField}
      type="password"
      autoComplete="off"
      validate={field.required ? validateRequired : null}
    />
  ),
  text: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={TextField}
      type="text"
      maxLength={field.maxLength}
      validate={field.required ? validateRequired : null}
    />
  ),
  textArea: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={TextField}
      type="textarea"
      maxLength={field.maxLength}
      validate={field.required ? validateRequired : null}
      parse={beautifyStringIfJson}
      format={beautifyStringIfJson}
    />
  ),
  transform: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={NetofuseTransformField}
      validate={[
        field.required ? validateRequired : null,
        validateTransformJson,
      ]}
    />
  ),
  integer: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={NumberField}
      step={1}
      min={field.min}
      max={field.max}
      precision={0}
      validate={[validateNumber, field.required ? validateRequired : null]}
    />
  ),
  toggle: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={ToggleField}
      type="checkbox"
      checkedLabel={field.checkedLabel}
    />
  ),
  select: (key, field, disabled, props) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={SelectField}
      validate={field.required ? validateRequired : null}
      options={
        field?.portalPropOptions
          ? props?.[field?.portalPropOptions]
          : field?.options
      }
      parse={normalizeSelectValue}
    />
  ),
  multiSelect: (key, field, disabled, props) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={MultiSelectField}
      options={
        field?.portalPropOptions
          ? props?.[field?.portalPropOptions]
          : field?.options
      }
      parse={normalizeMultiSelectValue}
      allowCreate={field?.allowCreate}
    />
  ),
  buttonGroup: (key, field, disabled, props) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={ButtonGroupField}
      options={
        field?.portalPropOptions
          ? props?.[field?.portalPropOptions]
          : field?.options
      }
    />
  ),
  list: (key, field, disabled) => (
    <Field
      {...getCommonFieldAttributes(key, field, disabled)}
      component={TextField}
      type="text"
      maxLength={field.maxLength}
      validate={[
        field.required ? validateRequired : undefined,
        validateTextJsonArray,
      ]}
      parse={parseTextList}
    />
  ),
};

const FieldElements = memo((props) => {
  const { fields, disabled, required } = props;

  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  if (!fields?.length) {
    return <NoData>No Integration Fields Found</NoData>;
  }

  return (
    <Fragment>
      {fields.map(([key, field]) => {
        if (!field || universalFieldNames.includes(key.toLocaleLowerCase())) {
          return null;
        }

        if (
          field.conditionalDisplay &&
          formValues?.[field.conditionalDisplay.key] !==
            field.conditionalDisplay?.showIfValueEquals
        ) {
          return null;
        }

        if (field.type === 'section') {
          return (
            <FieldsSection
              key={key}
              label={field.label}
              collapsible={field.collapsible}
            >
              <FieldElements
                {...props}
                fields={getSorted(field.fields, field?.order)}
              />
            </FieldsSection>
          );
        }

        const isFieldRequired = required?.includes(key);

        return Fields[field.type]?.(
          key,
          { required: isFieldRequired, ...field },
          disabled,
          props,
        );
      })}
    </Fragment>
  );
});

FieldElements.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    ),
  ).isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};
FieldElements.defaultProps = {
  disabled: false,
  order: null,
  required: null,
};

const FieldsFromManifest = memo((props) => {
  const { manifest } = props;
  const order = manifest?.order;

  const sortedFields = useMemo(() => {
    return getSorted(manifest?.fields, order);
  }, [manifest?.fields, order]);

  return (
    <FieldElements
      {...props}
      fields={sortedFields}
      required={manifest?.required}
      order={order}
    />
  );
});

FieldsFromManifest.propTypes = {
  manifest: PropTypes.shape({
    fields: PropTypes.objectOf(PropTypes.object),
    order: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.arrayOf(PropTypes.string),
  }),
};
FieldsFromManifest.defaultProps = {
  manifest: {},
};

export default FieldsFromManifest;
