import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import { Col } from '+components/Layout';
import usePermissions from '+hooks/usePermissions';

import SamlConfigurationForm from './components/SamlConfigurationForm';

const SsoFormPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const samlProvider = useSelector(customerSelectors.getSamlProvider);
  const samlProviderSettings = useSelector(
    customerSelectors.getSamlProviderSettings,
  );
  const samlProviderMetadataXml = useSelector(
    customerSelectors.getSamlProviderMetadataXml,
  );
  const isFetching = useSelector(customerSelectors.isFetching);
  const permissions = usePermissions(PermissionModel.Resources.account.value);

  const initialValues = useMemo(
    () => (!samlProvider?.alias ? {} : samlProvider),
    [samlProvider],
  );

  const onSamlSubmit = useCallback((values) => {
    const data = {
      file: values.file,
      resellerSso: values.resellerSso,
      config: {
        wantAssertionsEncrypted: values.config?.wantAssertionsEncrypted,
      },
      userAttributeMappers: values.userAttributeMappers,
      userRoleMappers: values.userRoleMappers,
      defaultUserRole: values.defaultUserRole,
    };
    if (values.alias) {
      dispatch(customerActions.updateSamlProvider(data));
    } else {
      dispatch(customerActions.createSamlProvider(data));
    }
  }, []);

  useEffect(() => {
    dispatch(customerActions.requestSamlProviderSettings());
  }, []);

  return (
    <Col xs={8} item>
      <SamlConfigurationForm
        samlProvider={initialValues}
        samlProviderSettings={samlProviderSettings}
        samlProviderMetadataXml={samlProviderMetadataXml}
        disabled={isFetching || !permissions?.update}
        onCancel={() => navigate(`${RoutePaths.security}`)}
        onSubmit={onSamlSubmit}
      />
    </Col>
  );
};

export default SsoFormPage;
