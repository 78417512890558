import { all, call, delay, put, select } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  awaiters,
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  isAllFetched: false,
  error: '',
  contexts: [],
  subAccountsContexts: {},
  totalIpCount: {},
  lastIpCount: 0,
  ipLabels: [],
  ipLabelsPagination: {},
  ipLabelsHash: {},
  ipLabelsIndexes: {},
  ipLabelsFetched: {},
  newIpLabel: null, // {}
};

export const defaultPaginationParams = {
  page: 0,
  size: 10,
  sort: [{ id: 'ip', desc: false }],
};

const apiPath = '/labels/ips';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

export const makeId = (item) =>
  `${item.ip}/${item.context}${item.customer ? `/${item.customer}` : ''}`;
export const splitId = (id) => {
  const [ip, context, customer] = id.split('/');
  return { ip, context, customer };
};

const removeFromHash = (hash, item) => {
  const record = hash[item.ip];
  if (!record) {
    return;
  }

  delete record[`${item.context}${item.customer ? `/${item.customer}` : ''}`];

  if (Object.keys(record).length === 0) {
    delete hash[item.ip];
  }
};

const updateHash = (hash, item) => {
  let record = hash[item.ip];

  if (record && item.hide) {
    removeFromHash(hash, item);
    return;
  }

  if (!record) {
    record = {};
    hash[item.ip] = record;
  }

  record[`${item.context}${item.customer ? `/${item.customer}` : ''}`] =
    item.labels;
};

export const getURLIndex = ({
  customer,
  page,
  size,
  context,
  labels,
  ip,
  sort,
}) => {
  const params = new URLSearchParams({
    ...(customer && { customer }),
    ...((page || page === 0) && { page }),
    ...(size && { size }),
  });

  if (sort) {
    sort.forEach((item) => {
      params.append('sort', `${item.id}:${item.desc ? 'desc' : 'asc'}`);
    });
  }

  if (context) {
    params.append(
      'context',
      `${context.operator === '==' && 'like'}:${context.value}`,
    );
  }

  if (labels) {
    params.append('labels', `${labels.operator}:${labels.value}`);
  }

  if (ip) {
    params.append('ip', `${ip.operator}:${ip.value}`);
  }

  return params.toString();
};

const slice = createSlice({
  name: 'ipLabels',
  initialState,

  reducers: {
    ...defaultReducers,

    fetchContexts: startFetching,
    fetchContextsSuccess(state, { payload: contexts }) {
      stopFetching(state);
      state.contexts = contexts;
    },

    fetchSubAccountsContexts: startFetching,
    fetchSubAccountsContextsSuccess(state, { payload: subAccountsContexts }) {
      stopFetching(state);
      subAccountsContexts.forEach((item) => {
        if (!state.subAccountsContexts[item.customer]) {
          state.subAccountsContexts[item.customer] = [];
        }
        state.subAccountsContexts[item.customer].push(item);
      });
    },

    fetchIpLabelsWithPagination: startFetching,
    fetchIpLabelsWithPaginationSuccess(
      state,
      { payload: { ipLabels, totalcount, index } },
    ) {
      stopFetching(state);

      ipLabels.forEach((ipLabel) => {
        ipLabel.id = makeId(ipLabel);
      });

      state.ipLabelsPagination[`${index}`] = ipLabels;
      state.totalIpCount[`${index}`] = totalcount;
      state.lastIpCount = totalcount;
    },

    fetchIpLabelsByContext: startFetching,
    fetchIpLabelsByContextSuccess(state, { payload: { data: ipLabels } }) {
      stopFetching(state);
      ipLabels.forEach((ipLabel) => {
        ipLabel.id = makeId(ipLabel);
        const index = state.ipLabelsIndexes[ipLabel.id];
        if (index != null) {
          state.ipLabels[index] = ipLabel;
        } else {
          state.ipLabelsIndexes[ipLabel.id] = state.ipLabels.length;
          state.ipLabels.push(ipLabel);
        }
        updateHash(state.ipLabelsHash, ipLabel);
      });
    },

    fetchIpLabelsByIp: startFetching,
    fetchIpLabelsByIpSuccess(
      state,
      { payload: { ip, customer, data: ipLabels } },
    ) {
      stopFetching(state);
      ipLabels.forEach((ipLabel) => {
        ipLabel.id = makeId(ipLabel);
        const index = state.ipLabelsIndexes[ipLabel.id];
        if (index != null) {
          state.ipLabels[index] = ipLabel;
        } else {
          state.ipLabelsIndexes[ipLabel.id] = state.ipLabels.length;
          state.ipLabels.push(ipLabel);
        }
        updateHash(state.ipLabelsHash, ipLabel);
      });
      state.ipLabelsFetched[`${ip}${customer ? `/${customer}` : ''}`] = true;
    },

    createIpLabel: startFetching,
    createIpLabelSuccess(state, { payload: ipLabel }) {
      stopFetching(state);
      ipLabel.id = makeId(ipLabel);
      const index = state.ipLabelsIndexes[ipLabel.id];
      if (index != null) {
        state.ipLabels[index] = ipLabel;
      } else {
        state.ipLabelsIndexes[ipLabel.id] = state.ipLabels.length;
        state.ipLabels.push(ipLabel);
      }
      state.ipLabelsFetched[ipLabel.ip] = true;
      state.newIpLabel = ipLabel;
      updateHash(state.ipLabelsHash, ipLabel);
    },

    updateIpLabel: startFetching,
    updateIpLabelSuccess(state, { payload: ipLabel }) {
      stopFetching(state);
      ipLabel.id = makeId(ipLabel);
      const index = state.ipLabelsIndexes[ipLabel.id];
      if (index != null) {
        state.ipLabels[index] = ipLabel;
      } else {
        state.ipLabelsIndexes[ipLabel.id] = state.ipLabels.length;
        state.ipLabels.push(ipLabel);
      }
      state.ipLabelsFetched[ipLabel.ip] = true;
      updateHash(state.ipLabelsHash, ipLabel);
    },

    removeIpLabel: startFetching,
    removeIpLabelSuccess(state, { payload: ipLabel }) {
      stopFetching(state);
      const id = makeId(ipLabel);
      const index = state.ipLabelsIndexes[id];
      if (index != null) {
        const len = state.ipLabels.length - 1;
        // Unfortunately, splice is slow, so we just move the last item to the removed item's place.
        // this operation is without generating garbage
        // and can't use batches for this operation, since we need to keep correct indexes, and avoid allocation new memory
        for (let i = index; i < len; i += 1) {
          const item = state.ipLabels[i + 1];
          state.ipLabels[i] = item;
          state.ipLabelsIndexes[item.id] = i;
        }
        state.ipLabels.length = len;
      }
      delete state.ipLabelsIndexes[id];
      delete state.ipLabelsFetched[ipLabel.ip];
      removeFromHash(state.ipLabelsHash, ipLabel);
    },

    bulkUploadIpLabel: startFetching,
    bulkUploadIpLabelSuccess: stopFetching,

    bulkUploadFile: startFetching,
    bulkUploadFileSuccess: stopFetching,

    bulkRemoveIpLabel: startFetching,
    bulkRemoveIpLabelSuccess: stopFetching,

    removeNewIpLabel(state) {
      state.newIpLabel = null;
    },

    skip: stopFetching,
  },

  sagas: (actions, selectors) => ({
    [actions.fetchContexts]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/contexts`);
          yield put(actions.fetchContextsSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching IP label contexts',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchSubAccountsContexts]: {
      taker: takeLeading(actions.skip),
      *saga({ payload: customers }) {
        initApi();

        try {
          const responses = yield all(
            customers.map((customer) =>
              call(api.get, `${apiPath}/contexts?customer=${customer}`),
            ),
          );
          const data = responses.reduce((acc, response) => {
            return [...acc, ...response.data.data];
          }, []);
          yield put(actions.fetchSubAccountsContextsSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching IP label contexts',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchIpLabelsWithPagination]: {
      *saga({ payload }) {
        initApi();

        const index = getURLIndex(payload);

        const completeApiPath = `${apiPath}/?${index}`;

        try {
          const response = yield call(api.get, completeApiPath);
          yield put(
            actions.fetchIpLabelsWithPaginationSuccess({
              ipLabels: response.data.data,
              totalcount: response.data.meta.totalcount,
              index,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching IP labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchIpLabels]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const isAllFetched = yield select(selectors.isAllFetched);
          if (isAllFetched) {
            yield put(actions.skip());
            return;
          }
          const response = yield call(api.get, apiPath);
          const labels = response.data.data;

          const indexes = {};
          const batchSize = 1e5;
          const amount = labels.length;

          // work with data in batches to avoid blocking the event loop
          yield Promise.all(
            Array.from(
              { length: Math.ceil(amount / batchSize) },
              (_, offsetIndex) => {
                return new Promise((resolve) => {
                  setTimeout(() => {
                    const start = offsetIndex * batchSize;
                    let end = start + batchSize;
                    if (end > amount) {
                      end = amount;
                    }

                    for (let i = start; i < end; i += 1) {
                      const label = labels[i];
                      label.id = makeId(label);
                      indexes[label.id] = i;
                    }
                    resolve();
                  }, 0);
                });
              },
            ),
          );

          yield put(
            actions.fetchIpLabelsSuccess({
              ipLabels: labels,
              indexes,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching IP labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchIpLabelsByContext]: {
      *saga({ payload: { silent = true, context } }) {
        initApi();

        // const fullPath = apiPath;
        const path = `${apiPath}/all/${context}`;
        const ipLabelsFetched = yield select(
          selectors.getIpLabelsFetchedByContext(context),
        );
        // const skip = ipLabelsFetched || awaiters.has(path) || awaiters.has(fullPath);
        const skip = ipLabelsFetched || awaiters.has(path);
        if (skip) {
          yield put(actions.skip());
          return;
        }

        awaiters.add(path);

        try {
          const response = yield call(api.get, path);
          yield put(
            actions.fetchIpLabelsByContextSuccess({
              context,
              data: response.data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error fetching IP labels',
                details: error.message,
              }),
            );
          }
        }

        awaiters.delete(path);
      },
    },

    [actions.fetchIpLabelsByIp]: {
      *saga({ payload: { silent = true, ip, customer } }) {
        initApi();

        // const fullPath = apiPath;
        let path = `${apiPath}/${ip}`;
        if (customer) {
          path += `?customer=${customer}`;
        }
        const ipLabelsFetched = yield select(
          selectors.getIpLabelsFetchedByIp(ip, customer),
        );
        // const skip = ipLabelsFetched || awaiters.has(path) || awaiters.has(fullPath);
        const skip = ipLabelsFetched || awaiters.has(path);
        if (skip) {
          yield put(actions.skip());
          return;
        }

        awaiters.add(path);

        try {
          const response = yield call(api.get, path);
          yield put(
            actions.fetchIpLabelsByIpSuccess({
              ip,
              customer,
              data: response.data.data,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error fetching IP label',
                details: error.message,
              }),
            );
          }
        }

        awaiters.delete(path);
      },
    },

    [actions.createIpLabel]: {
      *saga({ payload: { silent = false, ...ipLabel } }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, ipLabel);
          yield put(actions.createIpLabelSuccess(response.data.data));
          yield put(actions.fetchContexts()); // update label contexts
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP label has been created',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating IP label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateIpLabel]: {
      *saga({ payload: { silent = false, ...ipLabel } }) {
        initApi();

        try {
          const response = yield call(api.put, apiPath, ipLabel);
          yield put(actions.updateIpLabelSuccess(response.data.data));
          yield put(actions.fetchContexts()); // update label contexts
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP label has been updated',
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating IP label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeIpLabel]: {
      *saga({ payload: ipLabel }) {
        initApi();

        try {
          const response = yield call(
            api.delete,
            `${apiPath}/${ipLabel.ip}/${ipLabel.context}`,
          );
          yield put(actions.removeIpLabelSuccess(ipLabel));
          yield put(actions.fetchContexts()); // update label contexts
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP label has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting IP label',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUploadIpLabel]: {
      *saga({ payload: { silent = false, data: ipLabels } }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/bulk`, ipLabels);
          const updatedLabels = response.data.data;
          yield put(actions.bulkUploadIpLabelSuccess(updatedLabels));
          yield put(
            actions.fetchIpLabelsWithPagination({
              ...defaultPaginationParams,
              skipAwait: true,
            }),
          );
          yield put(actions.fetchContexts()); // update label contexts
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Created/Updated ${updatedLabels.length} IP Labels`,
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error importing IP labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkUploadFile]: {
      *saga({ payload: { silent = false, file } }) {
        initApi();

        try {
          const formData = new FormData();
          if (file) {
            formData.append('file', file);
          }
          const response = yield call(api.put, `${apiPath}/upload`, formData);
          const updatedLabels = response.data.data;
          yield put(actions.bulkUploadFileSuccess(updatedLabels));
          yield put(
            actions.fetchIpLabelsWithPagination({
              ...defaultPaginationParams,
              skipAwait: true,
            }),
          );
          yield put(actions.fetchContexts()); // update label contexts
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Created/Updated ${updatedLabels.length} IP Labels`,
              response,
              showWarningOnly: silent,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error importing IP labels',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkRemoveIpLabel]: {
      *saga({ payload: data }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/bulk`, { data });
          yield put(actions.bulkRemoveIpLabelSuccess());
          yield put(
            actions.fetchIpLabelsWithPagination({
              ...defaultPaginationParams,
              skipAwait: true,
            }),
          );
          yield put(actions.fetchContexts());
          yield delay(2000); // 1s
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'IP labels removed/reset',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error removing/resetting IP labels',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    isAllFetched: createSelector([getState], (state) => state.isAllFetched),

    getContexts: createSelector([getState], (state) => state.contexts),

    getSubAccountsContexts: (customers) =>
      createSelector([getState], (state) => {
        const result = {};
        customers.forEach((customer) => {
          result[customer] = state.subAccountsContexts[customer] || [];
        });
        return result;
      }),

    getNewIpLabel: createSelector([getState], (state) => state.newIpLabel),

    getIpLabels: createSelector([getState], (state) => state.ipLabels),

    getIpLabelsHash: createSelector([getState], (state) => state.ipLabelsHash),

    getIpLabelsIndexes: createSelector(
      [getState],
      (state) => state.ipLabelsIndexes,
    ),

    getLastIpCount: createSelector([getState], (state) => state.lastIpCount),

    getTotalIpCount: (key) =>
      createSelector([getState], (state) => state.totalIpCount?.[key]),

    getIpLabelsPagination: (key) =>
      createSelector([getState], (state) => state.ipLabelsPagination?.[key]),

    getIpLabelsHashByIp: (ip) =>
      createSelector([getState], (state) => state.ipLabelsHash?.[ip]),

    getIpLabelsFetchedByContext: (context) =>
      createSelector([getState], (state) => state.ipLabelsFetched?.[context]),

    getIpLabelsFetchedByIp: (ip, customer) =>
      createSelector(
        [getState],
        (state) =>
          state.ipLabelsFetched?.[`${ip}${customer ? `/${customer}` : ''}`],
      ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
