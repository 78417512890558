import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextIntegrations } from '@/models/integrations/ContextIntegrations';

import {
  actions as contextActions,
  selectors as contextSelectors,
} from '@/redux/api/integrations/context';

import { ContextIntegrationModels } from './ContextIntegrationModels';

export const useContextIntegrationModels = () => {
  const dispatch = useDispatch();

  const rawCIModels = useSelector(contextSelectors.getContextIntegrationModels);

  const contextIntegrationModels = useMemo(
    () => ({
      ...(rawCIModels || {}),
      ...ContextIntegrationModels,
    }),
    [rawCIModels],
  );

  const isIntegrationTypeHardcoded = useCallback(
    (type) => !!ContextIntegrations[type],
    [],
  );

  useEffect(() => {
    dispatch(contextActions.fetchIntegrationManifests());
  }, [rawCIModels]);

  return [contextIntegrationModels, isIntegrationTypeHardcoded];
};

export default useContextIntegrationModels;
