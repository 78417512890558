import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled, { css } from 'styled-components';

import useExportingFilename from '+hooks/useExportingFilename';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import FlagBackgroundMixin from './common/FlagBackgroundMixin';
import {
  barChartDataLabelFormatter,
  barChartTooltipFormatter,
  defaultLegendLabelFormatter,
  defaultSeriesColorIndexFormatter,
  defaultSeriesValueFormatter,
} from './common/formatters';
import getPropertiesTrayData from './common/getPropertiesTrayData';
import { Highcharts } from './common/highcharts';
import {
  getLegendPositionOptions,
  LegendMixin,
  LegendPositions,
} from './common/legend';
import useShowDataLabelsOnPrint from './common/useShowDataLabelsOnPrint';
import {
  defaultColorVariety,
  lang,
  legendItemClick,
  legendItemHover,
} from './common/utils';

const minPointValue = 0.007;

const EmptySeries = styled.div`
  display: ${(props) => (props.$plotBox ? null : 'none')};
  position: absolute;
  border: 1px solid #767676;

  // horizontal orientation
  ${(props) =>
    props.$plotBox &&
    props.$type === 'bar' &&
    css`
      top: 50%;
      left: ${props.$plotBox.x}px;
      transform: translateY(calc(-50% + ${props.$withTitle ? 15 : 0}px));
      width: ${props.$plotBox.width}px;
      height: 50px;
    `}

  // vertical orientation
  ${(props) =>
    props.$plotBox &&
    props.$type !== 'bar' &&
    css`
      top: ${props.$plotBox.y}px;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: ${props.$plotBox.height}px;
    `}
`;

const sortByValue = (a, b) => b[1] - a[1];

/**
 * Bar charts display data as horizontal or vertical bars.
 */
const BarChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    type,
    context,
    fields,
    series,
    categories,
    legend,
    reversed,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    colorIndex,
    seriesNameFormatter,
    seriesColorIndexFormatter,
    legendLabelFormatter,
    tooltipFormatter,
    legendPosition,
    overrides = {},
    hideTitle,
    hideSubtitle,
    waitingOnNql,
  } = props;

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);
  const [, setGlobalContextMenu] = useUIProperty('globalContextMenu', null);

  const chartRef = useRef(null);
  const [filters] = useGlobalFilters();
  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });
  useShowDataLabelsOnPrint(chartRef.current, 'series');

  const seriesData = useMemo(
    () => (series?.data ? [...series.data].sort(sortByValue) : []),
    [series?.data],
  );

  const seriesSum = useMemo(
    () => seriesData.reduce((acc, item) => acc + item[1], 0),
    [seriesData],
  );

  const plotOptions = useMemo(
    () => ({
      ...(overrides.plotOptions || {}),
      series: {
        animation: false,
        shadow: false,
        dataLabels: { enabled: false },
        borderWidth: 0,
        groupPadding: 0,
        // minPointLength: 3,
        maxPointWidth: 50,
        ...(overrides.plotOptions?.series || {}),
        events: {
          afterAnimate(event) {
            legendItemHover(event);
          },
          ...(overrides.plotOptions?.series?.events || {}),
        },
      },
      column: {
        animation: false,
        shadow: false,
        dataLabels: { style: { textShadow: false } },
        borderRadius: 0,
        ...(overrides.plotOptions?.column || {}),
      },
      bar: {
        animation: false,
        shadow: false,
        dataLabels: { style: { textShadow: false } },
        borderRadius: 0,
        ...(overrides.plotOptions?.bar || {}),
      },
    }),
    [overrides],
  );

  const legendPositionOptions = useMemo(
    () => getLegendPositionOptions({ legendPosition, title, subtitle }),
    [legendPosition, title, subtitle],
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  const onLegendItemClick = useCallback(
    (rawItem) => (event) => {
      legendItemClick(event);

      // do not open properties tray if chart is in fullscreen mode
      const chart = chartRef.current;
      if (chart?.fullscreen?.isOpen) {
        return;
      }

      const item = {
        name: rawItem[0],
        series: series.series,
        customer: series.customer,
      };

      const propertiesTrayData = getPropertiesTrayData({
        context,
        fields,
        item,
        labelContext: filters.labelContext,
      });

      setPropertiesTray({
        data: propertiesTrayData,
        isOpen: true,
      });
    },
    [context, fields, series?.series, series?.customer, filters.labelContext],
  );

  const onLegendItemContextMenu = useCallback(
    (rawItem) => (event) => {
      const legendSubItemsWrapper = event.target.classList.contains(
        'label-series-group',
      )
        ? event.target
        : event.target.closest('.label-series-group');
      if (!legendSubItemsWrapper) {
        return;
      }

      const legendSubItem = event.target.classList.contains('label-series')
        ? event.target
        : event.target.closest('.label-series');
      if (!legendSubItem) {
        return;
      }

      const legendSubItems =
        legendSubItemsWrapper.getElementsByClassName('label-series');
      const legendSubItemIndex = [...legendSubItems].indexOf(legendSubItem);
      if (legendSubItemIndex === -1) {
        return;
      }

      const item = {
        name: rawItem[0],
        series: series.series,
        customer: series.customer,
      };

      let globalContextMenuData = getPropertiesTrayData({
        context,
        fields,
        item,
        labelContext: filters.labelContext,
      });
      globalContextMenuData = [globalContextMenuData[legendSubItemIndex]];

      setGlobalContextMenu({
        data: globalContextMenuData,
        event,
      });
    },
    [context, fields, filters.labelContext],
  );

  // Workaround for no data message - without this message will not be displayed
  //  until the chart is resized
  //  @see: https://netography.atlassian.net/browse/ENG-1017
  useEffect(() => {
    const chart = chartRef.current;
    if (chart && !seriesData.length) {
      chart.showNoData?.(lang.noData);
    }
  }, [chartRef.current, seriesData]);

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(
          className,
          'bar-chart',
          `p-${colorVariety ?? defaultColorVariety}`,
          `legend-${legendPosition}`,
          {
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
            thin: width <= 100,
          },
        )}
        boost={{
          // Workaround for uncolored series bug https://gitlab.com/netography/portal/-/issues/665
          // @see https://github.com/highcharts/highcharts/issues/9407
          seriesThreshold: Infinity,
          allowForce: false,
        }}
        plotOptions={plotOptions}
        callback={onChartCallback}
        __colorVariety={colorVariety}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>
          {waitingOnNql ? lang.waitingOnNql : lang.loading}
        </HSX.Loading>

        <HSX.Legend
          {...legendPositionOptions}
          enabled={legend}
          labelFormat={legendLabelFormatter}
          shadow={false}
          squareSymbol={false}
          symbolWidth={0}
          symbolHeight={0}
          symbolPadding={0}
          reversed={reversed}
          reverseLegendClickAction
          alignColumns
          useHTML
          {...overrides.legend}
        />

        <HSX.Tooltip
          formatter={tooltipFormatter}
          useHTML
          borderRadius={8}
          shadow={false}
          animation={false}
        />

        <HSX.Chart
          type={type}
          width={width}
          height={height}
          colorCounter={0}
          reflow={false}
          {...overrides.chart}
        />

        <HSX.XAxis
          categories={categories}
          visible={false}
          minPadding={0}
          maxPadding={0}
          reversedStacks={reversed}
        />

        {seriesData.length ? (
          <HSX.YAxis visible reversedStacks={reversed}>
            {seriesData.map((item, i) => {
              const seriesName = seriesNameFormatter({
                name: item[0],
                series: series?.series,
                customer: series?.customer,
                labelContext: filters.labelContext,
              });
              const seriesId = item[0];
              const seriesColorIndex =
                colorIndex ??
                item[2] ??
                seriesColorIndexFormatter({
                  name: item[0],
                  series: series?.series,
                  customer: series?.customer,
                });
              const columnData =
                item[1] / seriesSum > minPointValue
                  ? item[1]
                  : seriesSum * minPointValue;
              return (
                <HSX.ColumnSeries
                  key={seriesId}
                  id={seriesId}
                  index={i}
                  name={seriesName}
                  context={context}
                  metric={series.metric}
                  data={[columnData]}
                  realData={item[1]}
                  colorIndex={seriesColorIndex}
                  sum={seriesSum}
                  dataLabels={{
                    enabled: true,
                    allowOverlap: true,
                    formatter: barChartDataLabelFormatter,
                  }}
                  onLegendItemClick={onLegendItemClick(item)}
                  __onLegendItemContextMenu={onLegendItemContextMenu(item)}
                />
              );
            })}
          </HSX.YAxis>
        ) : (
          // We need this fake series to display axis when there is no data
          // @see: https://netography.atlassian.net/browse/PORTAL-1336
          <EmptySeries
            $type={type}
            $plotBox={chartRef.current?.plotBox}
            $withTitle={
              (!!title && !hideTitle) || (!!subtitle && !hideSubtitle)
            }
          />
        )}
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})`
  ${(props) =>
    props.flagValue && props.showFlag && FlagBackgroundMixin(props.flagValue)}

  position: relative;
  .highcharts-data-label {
    visibility: ${(props) => (props.displayDataLabel ? 'visible' : 'hidden')};
  }

  ${LegendMixin};
`;

const propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart type:
   * - bar - chart will be in horizontal orientation;
   * - column - in vertical orientation.
   */
  type: PropTypes.oneOf(['bar', 'column']),
  /**
   * Chart context.
   */
  context: PropTypes.string,
  /**
   * Chart request fields.
   */
  fields: PropTypes.arrayOf(PropTypes.string),
  /**
   * Chart series.
   */
  series: PropTypes.shape({
    name: PropTypes.string,
    metric: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ),
  }),
  /**
   * Data categories.
   */
  categories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  /**
   * If true, chart legend will be displayed.
   */
  legend: PropTypes.bool,
  /**
   * If true, data will be reversed.
   */
  reversed: PropTypes.bool,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index.
   */
  colorIndex: PropTypes.number,
  /**
   * Series name formatter.
   */
  seriesNameFormatter: PropTypes.func,
  /**
   * Series color index formatter.
   */
  seriesColorIndexFormatter: PropTypes.func,
  /**
   * Legend label formatter.
   */
  legendLabelFormatter: PropTypes.string,
  /**
   * Tooltip formatter.
   */
  tooltipFormatter: PropTypes.func,
  /**
   * Legend position.
   */
  legendPosition: PropTypes.oneOf(Object.values(LegendPositions)),
  /**
   * Override or extend chart options.
   */
  overrides: PropTypes.shape({}),
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * If included, shows a description on the widget
   */
  description: PropTypes.string,
  /**
   * display bar/column data labels
   */
  displayDataLabel: PropTypes.bool,
  /**
   * if true, chart data will not be loaded until user inputs NQL (handled in StatsWrapper)
   * Will show the corresponding message on the chart
   */
  waitingOnNql: PropTypes.bool,
};

const defaultProps = {
  className: '',
  description: '',
  title: null,
  subtitle: null,
  type: 'bar',
  context: undefined,
  fields: [],
  series: {},
  categories: [],
  legend: true,
  reversed: false,
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndex: undefined,
  seriesNameFormatter: defaultSeriesValueFormatter,
  seriesColorIndexFormatter: defaultSeriesColorIndexFormatter,
  legendLabelFormatter: defaultLegendLabelFormatter,
  tooltipFormatter: barChartTooltipFormatter,
  legendPosition: LegendPositions.bottom,
  overrides: {},
  hideTitle: false,
  hideSubtitle: false,
  displayDataLabel: false,
};

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;

export { propTypes, defaultProps };

export default memo(BarChart);
