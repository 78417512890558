import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import AddIntegration from '@/pages/Integrations/AddIntegration';
import ResponseIntegrationsPage from '@/pages/Integrations/ResponseIntegrations';
import ResponseIntegrationsAdd from '@/pages/Integrations/ResponseIntegrations/Add';
import ResponseIntegrationsEdit from '@/pages/Integrations/ResponseIntegrations/Edit';

import { CrumbRoute } from '+components/Breadcrumb';

const ReplaceAllowlists = () => {
  const { pathname } = useLocation();

  const parts = pathname.split(RoutePaths.allowlists.toString());

  const to = [RoutePaths.ipTrustLists.toString(), parts[1] || '']
    .filter(Boolean)
    .join('');

  return <Navigate to={to} replace />;
};

const ResponseIntegrationsRoutes = () => (
  <Routes>
    <Route
      path="edit/:id/*"
      element={
        <CrumbRoute
          title="Edit Response Integration"
          component={ResponseIntegrationsEdit}
        />
      }
    />

    <Route
      path="add/:adapter"
      element={
        <CrumbRoute
          title="Add Response Integration"
          component={ResponseIntegrationsAdd}
        />
      }
    />

    <Route
      path="add"
      element={
        <CrumbRoute title="Add Integration" component={AddIntegration} />
      }
    />

    <Route path="allowlists/*" element={<ReplaceAllowlists />} />

    <Route
      path="*"
      element={
        <CrumbRoute
          title="Response Integrations"
          component={ResponseIntegrationsPage}
        />
      }
    />
  </Routes>
);

export default ResponseIntegrationsRoutes;
