import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useFullscreen,
  useLocalStorage,
  useMeasure,
  useToggle,
} from 'react-use';

import styled, { css, useTheme } from 'styled-components';

import FitToPageOutlineIcon from 'mdi-react/FitToPageOutlineIcon';
import FitToScreenIcon from 'mdi-react/FitToScreenIcon';
import FitToScreenOutlineIcon from 'mdi-react/FitToScreenOutlineIcon';
import TuneIcon from 'mdi-react/TuneIcon';

import OriginalButton, { ButtonVariants } from '+components/Button';
import MenuOriginal from '+components/charts/common/Menu';
import {
  downloadDataUrl,
  exportingButtons,
  lang,
  print,
} from '+components/charts/common/utils';
import ToggleOriginal from '+components/form/Toggle';
import useExportingFilename from '+hooks/useExportingFilename';
import { lightTheme } from '+theme/theme';

import Application, {
  ChartModes,
  Events,
  GraphicsQuality,
  VisibleElements,
} from './ForceDirected';
import Legend, { propTypes as legendPropTypes } from './Legend';

export { ChartModes, GraphicsQuality };

export const Button = styled(OriginalButton)`
  font-size: 12px;
  padding: 6px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.colorBackgroundBody};
`;

const BindElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Panel = styled.div`
  position: absolute;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  > * {
    pointer-events: auto;
  }
  gap: 0.2em;
`;

const LeftBottomPanel = styled(Panel)`
  //left: 5px;
  //bottom: 0.5em;
  left: 0;
  bottom: 0;
`;

const removeTransform = css`
  &:not(#fake_id) {
    transform: none;
  }
`;

const Menu = styled(MenuOriginal)`
  ${({ isFullscreen }) => isFullscreen && removeTransform}
`;

const SettingsMenu = styled(Menu)`
  top: 31px;
`;

const noop = () => {};

export const SettingToggle = styled(ToggleOriginal).attrs(({ onChange }) => ({
  onChange: onChange ?? noop,
}))`
  margin-left: 20px;
  transform: scale(0.8, 0.8);
  height: 20px;
  padding: 0;
  pointer-events: none;
`;

export const SettingItem = styled.div`
  display: flex;
  min-width: 20ch;
  width: 100%;
  justify-content: ${({ $align }) => $align || 'space-between'};
  align-items: center;
`;

const getModel = (data) => ({
  nodes: Object.values(data?.nodes || {}),
  links: Object.values(data?.links || {}),
});

const {
  contextButton: { menuItems = [] },
} = exportingButtons();

const Chart = (props) => {
  const {
    className,
    data,
    particles,
    selectedIds,
    onNodeOver,
    onNodeMove,
    onNodeOut,
    onNodeClick,
    onNodeDragStart,
    onNodeDrag,
    onNodeDragEnd,
    onNodeRightClick,
    onLabelCountClick,

    groupBy,

    linkWidth,
    labelContext,
    label,
    labelCount,
    showLabel,

    nodeColor,
    selectedNodeColor,
    particleColor,
    linkColor,
    titleColor,

    nodeRadius,
    particleRadius,

    quality,
    mode,
    legendProps,
    additionalSettingsActions,
    buttons,
    suffixOfExportFilename,
  } = props;

  const theme = useTheme();

  const [savedQuality, setSavedQuality, unsetSavedQuality] = useLocalStorage(
    'fdc_quality',
    quality === GraphicsQuality.High,
  );

  const [measureRef, { width, height }] = useMeasure();
  const [element, setElement] = useState();
  const [, refresh] = useToggle();
  const [showTraffic, toggleShowTraffic] = useToggle(false);
  const [showLegend, toggleShowLegend] = useToggle(true);
  const [highQuality, toggleHighQuality] = useToggle(savedQuality);

  const [isGlowEffectVisible, setIsGlowEffectVisible] = useToggle(true);
  const [isNodesVisible, setIsNodesVisible] = useToggle(true);
  const [isTitlesVisible, setIsTitlesVisible] = useToggle(true);
  const [isLinksVisible, setIsLinksVisible] = useToggle(true);
  const [isParticlesVisible, setIsParticlesVisible] = useToggle(true);
  const [isArrowsVisible, setIsArrowsVisible] = useToggle(true);

  const setRef = useCallback((node) => {
    measureRef(node);
    setElement(node);
  }, []);

  const onAutoFitToggled = useCallback(() => refresh(), []);

  const onVisibilityToggled = useCallback((el, value) => {
    switch (el) {
      case VisibleElements.nodes:
        setIsNodesVisible(value);
        break;
      case VisibleElements.titles:
        setIsTitlesVisible(value);
        break;
      case VisibleElements.links:
        setIsLinksVisible(value);
        break;
      case VisibleElements.particles:
        setIsParticlesVisible(value);
        break;
      case VisibleElements.arrows:
        setIsArrowsVisible(value);
        break;
      case VisibleElements.glowEffect:
        setIsGlowEffectVisible(value);
        break;
      default:
        break;
    }
  }, []);

  /**
   * @type {React.MutableRefObject<Application>}
   */
  const app = useRef(null);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const options = {
      quality: highQuality ? GraphicsQuality.High : GraphicsQuality.Low,
      mode,

      groupBy,
      linkWidth,
      labelContext,
      label,
      labelCount,
      showLabel,
      nodeColor,
      titleColor: titleColor || theme.ctText,
      titleBackground: theme.ctLabelBackground,
      labelBorderColor: theme.genericLabelBorderColor,
      labelContextColor: theme.colorText,
      labelContextBackground: theme.genericLabelContextBackground,
      labelColor: theme.colorText,
      labelBackground: theme.genericLabelBodyBackground,
      labelCountColor: theme.showMoreButtonText,
      labelCountBackground: theme.showMoreButtonBackground,
      labelCountClickable: !!onLabelCountClick,
      linkColor: linkColor || theme.ctStroke,
      selectedNodeColor: selectedNodeColor || theme.primary,
      particleColor,
      nodeRadius,
      particleRadius,

      visibleElements: {
        [VisibleElements.nodes]: isNodesVisible,
        [VisibleElements.titles]: isTitlesVisible,
        [VisibleElements.links]: isLinksVisible,
        [VisibleElements.particles]: isParticlesVisible,
        [VisibleElements.arrows]: isArrowsVisible,
        [VisibleElements.glowEffect]: isGlowEffectVisible,
      },

      autoFit: true,

      selected: selectedIds,
    };

    app.current = new Application(element, options)
      .on(Events.nodeOver, onNodeOver)
      .on(Events.nodeOut, onNodeOut)
      .on(Events.nodeMove, onNodeMove)
      .on(Events.nodeDragStart, onNodeDragStart)
      .on(Events.nodeDrag, onNodeDrag)
      .on(Events.nodeDragEnd, onNodeDragEnd)
      .on(Events.nodeClick, onNodeClick)
      .on(Events.nodeRightClick, onNodeRightClick)
      .on(Events.labelCountClick, onLabelCountClick)
      .on(Events.autoFitToggled, onAutoFitToggled)
      .on(Events.titlesAutoHideToggled, onAutoFitToggled)
      .on(Events.visibilityToggled, onVisibilityToggled)
      .data(getModel(data));

    return () => {
      if (app.current) {
        app.current.destroy();
        app.current = null;
      }
    };
  }, [element]);

  useEffect(() => {
    app.current?.data(getModel(data));
  }, [data]);

  useEffect(() => {
    if (showTraffic) {
      app.current?.particles(particles);
    }
  }, [particles]);

  useEffect(() => {
    app.current?.selected(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    app.current?.groupBy(groupBy);
  }, [groupBy]);

  useEffect(() => {
    if (!app.current) {
      return;
    }

    app.current
      .on(Events.nodeOver, onNodeOver)
      .on(Events.nodeOut, onNodeOut)
      .on(Events.nodeMove, onNodeMove)
      .on(Events.nodeDragStart, onNodeDragStart)
      .on(Events.nodeDrag, onNodeDrag)
      .on(Events.nodeDragEnd, onNodeDragEnd)
      .on(Events.nodeClick, onNodeClick)
      .on(Events.nodeRightClick, onNodeRightClick)
      .on(Events.labelCountClick, onLabelCountClick);
  }, [
    onNodeOver,
    onNodeOut,
    onNodeMove,
    onNodeClick,
    onNodeDragStart,
    onNodeDrag,
    onNodeDragEnd,
    onNodeRightClick,
    onLabelCountClick,
  ]);

  useEffect(() => {
    if (app.current) {
      app.current
        .groupBy(groupBy)
        .linkWidth(linkWidth)
        .nodeColor(nodeColor)
        .selectedNodeColor(selectedNodeColor || theme.primary)
        .labelContext(labelContext)
        .label(label)
        .labelCount(labelCount)
        .showLabel(showLabel)
        .titleColor(titleColor || theme.ctText)
        .titleBackground(theme.ctLabelBackground)
        .labelBorderColor(theme.genericLabelBorderColor)
        .labelContextColor(theme.colorText)
        .labelContextBackground(theme.genericLabelContextBackground)
        .labelColor(theme.colorText)
        .labelBackground(theme.genericLabelBodyBackground)
        .labelCountColor(theme.showMoreButtonText)
        .labelCountBackground(theme.showMoreButtonBackground)
        .linkColor(linkColor || theme.ctStroke)
        .particleColor(particleColor)
        .nodeRadius(nodeRadius)
        .particleRadius(particleRadius)
        .updateLayout();
    }
  }, [
    theme.name,
    groupBy,
    linkWidth,
    nodeColor,
    selectedNodeColor,
    titleColor,
    linkColor,
    particleColor,
    nodeRadius,
    particleRadius,
    labelContext,
    label,
    labelCount,
    showLabel,
  ]);

  useEffect(() => {
    app.current?.resize();
  }, [width, height]);

  useEffect(() => {
    app.current?.mode(mode);
  }, [mode]);

  const anchor = useMemo(
    () => (
      <BindElement key={`_${highQuality}`} className={className} ref={setRef} />
    ),
    [className, highQuality],
  );

  const onClickAutoFit = useCallback(() => {
    app.current?.toggleAutoFit();
  }, []);

  const onClickResetViewport = useCallback(() => {
    app.current?.fitWorld().updateLayout();
  }, []);

  const containerRef = useRef(null);
  const fullscreenRef = useRef(null);
  fullscreenRef.current = containerRef.current?.parentNode;
  const [show, toggleShow] = useToggle(false);
  const isFullscreen = useFullscreen(fullscreenRef, show, {
    onClose: () => toggleShow(false),
  });

  const exportingFilename = useExportingFilename(suffixOfExportFilename);

  const prepareForStore = useCallback(
    (fn) => {
      if (theme.name === 'light') {
        fn();
        return;
      }

      app.current
        .pause()
        .titleColor(lightTheme.ctText)
        .titleBackground(lightTheme.ctLabelBackground)
        .updateLayout(false, () => {
          fn();

          app.current
            .titleColor(titleColor || theme.ctText)
            .titleBackground(theme.ctLabelBackground)
            .updateLayout(false)
            .resume();
        });
    },
    [theme, titleColor],
  );

  const downloadPNG = useCallback(() => {
    if (!app.current) {
      return;
    }

    prepareForStore(() => {
      downloadDataUrl(
        app.current.toDataURL('image/png', 1),
        `${exportingFilename}.png`,
      );
    });
  }, [prepareForStore, exportingFilename]);

  const printChart = useCallback(() => {
    if (!app.current) {
      return;
    }

    prepareForStore(() => {
      const scale = app.current.getScale();
      const canvas = app.current.toCanvas();

      const { width: ctxWidth, height: ctxHeight } = canvas;

      const img = app.current.toDataURL('image/png', 1);

      const {
        outerHTML: body,
        clientWidth,
        clientHeight,
      } = containerRef.current;

      const refItem = {
        current: {
          outerHTML: `
              <style>
                ${Container} {
                  background: transparent;
                }

                .___imgBg {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: ${ctxWidth * scale.x * 0.8}px;
                  height: ${ctxHeight * scale.y * 0.8}px;
                  object-fit: contain;
                }
                .___imgBgContainer {
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  overflow: hidden;
                }
                .MuiButton-root {
                  display: none;
                }
                [class^="Legend__LegendContainer"] {
                  background: ${lightTheme.fdcLegendBackground};
                }
              </style>
              <div class="___imgBgContainer">
                <img src="${img}" class="___imgBg" />
              </div>
              ${body}
            `,
          clientWidth,
          clientHeight,
        },
      };

      print(refItem);
    });
  }, [prepareForStore]);

  const actions = useMemo(
    () =>
      menuItems
        .map((item) => {
          const key = String(item.textKey || item);
          let action = noop;

          switch (key) {
            case 'viewFullscreen':
              action = () => toggleShow();
              break;
            case 'printChart':
              action = printChart;
              break;
            case 'downloadPNG':
              action = downloadPNG;
              break;
            default:
              break;
          }

          return {
            key,
            action,
            content:
              key === 'viewFullscreen' && isFullscreen
                ? lang.exitFullscreen
                : lang[key],
          };
        })
        .filter(({ action }) => action !== noop),
    [isFullscreen, downloadPNG, printChart],
  );

  const settingsActions = useMemo(
    () => [
      ...(additionalSettingsActions || []),
      {
        key: 'High quality',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          toggleHighQuality();
        },
        content: (
          <SettingItem>
            <span>High quality</span>
            <SettingToggle checked={highQuality} />
          </SettingItem>
        ),
      },
      {
        key: 'Glow effect',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleGlowEffect();
        },
        content: (
          <SettingItem>
            <span>Glow effect</span>
            <SettingToggle checked={app.current?.isGlowEffectVisible} />
          </SettingItem>
        ),
      },
      {
        key: 'Animate new traffic',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          toggleShowTraffic();
        },
        content: (
          <SettingItem>
            <span>Animate new traffic</span>
            <SettingToggle checked={showTraffic} />
          </SettingItem>
        ),
      },
      {
        key: 'Auto-hide titles',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleTitlesAutoHide();
        },
        content: (
          <SettingItem>
            <span>Auto-hide titles</span>
            <SettingToggle checked={app.current?.isTitlesAutoHide} />
          </SettingItem>
        ),
      },
      {
        separator: true,
      },
      {
        key: 'Show nodes',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleNodes();
        },
        content: (
          <SettingItem>
            <span>Show nodes</span>
            <SettingToggle checked={app.current?.isNodesVisible} />
          </SettingItem>
        ),
      },
      {
        key: 'Show titles',
        closeMenu: false,
        disabled: app.current?.isTitlesAutoHide,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleTitles();
        },
        content: (
          <SettingItem>
            <span>Show titles</span>
            <SettingToggle checked={app.current?.isTitlesVisible} />
          </SettingItem>
        ),
      },
      {
        key: 'Show links',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleLinks();
        },
        content: (
          <SettingItem>
            <span>Show links</span>
            <SettingToggle checked={app.current?.isLinksVisible} />
          </SettingItem>
        ),
      },
      {
        key: 'Show arrows',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleArrows();
        },
        content: (
          <SettingItem>
            <span>Show arrows</span>
            <SettingToggle checked={app.current?.isArrowsVisible} />
          </SettingItem>
        ),
      },
      {
        key: 'Show particles',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          app.current?.toggleParticles();
        },
        content: (
          <SettingItem>
            <span>Show particles</span>
            <SettingToggle checked={app.current?.isParticlesVisible} />
          </SettingItem>
        ),
      },
      {
        key: 'Show legend',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          toggleShowLegend();
        },
        content: (
          <SettingItem>
            <span>Show legend</span>
            <SettingToggle checked={showLegend} />
          </SettingItem>
        ),
      },
      {
        separator: true,
      },
      {
        key: 'Unpin',
        closeMenu: app.current?.hasPinnedNodes,
        disabled: !app.current?.hasPinnedNodes,
        action: () => {
          app.current?.unpinAllNodes();
          refresh();
        },
        content: (
          <SettingItem $align="center">
            <Button disabled={!app.current?.hasPinnedNodes}>
              Unpin all nodes
            </Button>
          </SettingItem>
        ),
      },
    ],
    [
      showTraffic,
      showLegend,
      highQuality,
      additionalSettingsActions,
      app.current?.isGlowEffectVisible,
      app.current?.isNodesVisible,
      app.current?.isTitlesVisible,
      app.current?.isLinksVisible,
      app.current?.isArrowsVisible,
      app.current?.isParticlesVisible,
      app.current?.hasPinnedNodes,
      app.current?.isTitlesAutoHide,
    ],
  );

  useEffect(() => {
    if (highQuality) {
      setSavedQuality(true);
    } else {
      unsetSavedQuality();
    }
  }, [highQuality]);

  const buttonsRender = useMemo(
    () => (
      <LeftBottomPanel>
        {buttons}
        <Button
          variant={
            app.current?.isAutoFitted
              ? ButtonVariants.contained
              : ButtonVariants.outlined
          }
          onClick={onClickAutoFit}
          title={`Autofit: ${app.current?.isAutoFitted ? 'on' : 'off'}`}
          data-tracking="autofit-button"
        >
          {app.current?.isAutoFitted ? (
            <FitToScreenIcon size={16} />
          ) : (
            <FitToScreenOutlineIcon size={16} />
          )}
        </Button>
        <Button
          variant={ButtonVariants.outlined}
          onClick={onClickResetViewport}
          title="Reset"
          disabled={!!app.current?.isAutoFitted}
          data-tracking="autofit-reset-button"
        >
          <FitToPageOutlineIcon size={16} />
        </Button>
      </LeftBottomPanel>
    ),
    [buttons, app.current?.isAutoFitted],
  );

  return useMemo(
    () => (
      <Container ref={containerRef}>
        {anchor}
        <Menu
          actions={actions}
          title="Menu"
          isFullscreen={isFullscreen}
          hideOnClickOutside={false}
        />
        <SettingsMenu
          actions={settingsActions}
          icon={<TuneIcon size={17} />}
          title="Settings"
          isFullscreen={isFullscreen}
          hideOnClickOutside={false}
        />
        {buttonsRender}
        {showLegend && legendProps && <Legend {...(legendProps || {})} />}
      </Container>
    ),
    [anchor, legendProps, actions, settingsActions, buttonsRender],
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape(),
  particles: PropTypes.arrayOf(PropTypes.shape()),
  selectedIds: PropTypes.arrayOf(PropTypes.string),

  onNodeOver: PropTypes.func,
  onNodeMove: PropTypes.func,
  onNodeOut: PropTypes.func,
  onNodeDragStart: PropTypes.func,
  onNodeDrag: PropTypes.func,
  onNodeDragEnd: PropTypes.func,
  onNodeClick: PropTypes.func,
  onNodeRightClick: PropTypes.func,
  onLabelCountClick: PropTypes.func,

  groupBy: PropTypes.func,

  linkWidth: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  labelContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  labelCount: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  showLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),

  nodeColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  selectedNodeColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  titleColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  linkColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  particleColor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

  nodeRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  particleRadius: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),

  mode: PropTypes.oneOf(Object.values(ChartModes)),
  quality: PropTypes.oneOf(Object.values(GraphicsQuality)),

  legendProps: PropTypes.shape(legendPropTypes),
  additionalSettingsActions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      content: PropTypes.children,
      onClick: PropTypes.func,
    }),
  ),
  buttons: PropTypes.children,
  suffixOfExportFilename: PropTypes.string,
};

Chart.defaultProps = {
  className: null,
  data: null,
  particles: null,
  selectedIds: null,

  onNodeOver: null,
  onNodeMove: null,
  onNodeOut: null,
  onNodeDragStart: null,
  onNodeDrag: null,
  onNodeDragEnd: null,
  onNodeClick: null,
  onNodeRightClick: null,
  onLabelCountClick: null,

  groupBy: null,

  linkWidth: null,
  labelContext: null,
  label: null,
  labelCount: null,
  showLabel: null,

  nodeColor: null,
  selectedNodeColor: null,
  titleColor: null,
  linkColor: null,
  particleColor: null,

  nodeRadius: null,
  particleRadius: null,
  mode: ChartModes.Dynamic,
  quality: GraphicsQuality.Low,
  legendProps: null,
  additionalSettingsActions: null,
  buttons: null,
  suffixOfExportFilename: 'fcd',
};

export default Chart;
