/* eslint-disable react/prop-types */
import { Flip, Slide, toast } from 'react-toastify';

import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import Body from './components/Body';
import Container from './components/Container';
import Icon from './components/Icon';
import Separator from './components/Separator';
import Title from './components/Title';
import ToastContainer from './components/ToastContainer';

const Types = {
  error: 'error',
  warn: 'warn',
  info: 'info',
  success: 'success',
  log: 'log',
};

const Positions = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  TOP_CENTER: 'top-center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_CENTER: 'bottom-center',
};

const Transition = {
  Flip,
  Slide,
};

const Component = (props) => {
  const { message, details, showIcon = true, testId } = props;

  return (
    <Container data-testid={testId}>
      <Title className="toast__title">
        {showIcon && (
          <Icon className="toast__icon">
            <InformationOutlineIcon />
          </Icon>
        )}
        {message}
      </Title>
      {details && <Separator />}
      {details && (
        <Body className="toast__message">{details.message || details}</Body>
      )}
    </Container>
  );
};

const notify = (data, type = Types.log, options = {}) => {
  const message = data.message ? data : { message: data };

  const { showIcon, ...toastOptions } = {
    draggable: false,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    ...options,
  };

  if (type in toast) {
    toast[type](
      <Component {...message} showIcon={showIcon} testId={`toast-${type}`} />,
      toastOptions,
    );
    return;
  }

  toast(
    <Component type={type} {...message} showIcon={showIcon} />,
    toastOptions,
  );
};

export const error = (message, options) =>
  notify(message, Types.error, options);
export const warn = (message, options) => notify(message, Types.warn, options);
export const info = (message, options) => notify(message, Types.info, options);
export const success = (message, options) =>
  notify(message, Types.success, options);
export const log = (message, options) => notify(message, Types.log, options);
export const clearAll = () => toast.dismiss();

export { ToastContainer, Positions, Transition };
