import { ContextTypes } from '@/models/ContextTypes';
import { isIpField } from '@/models/FieldTypes';

import { getEventTableFilterValues } from '+components/ContextTables/EventTable/getEventTableFilterValues';
import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
} from '+components/Table/Columns';

const makeBaseColumn = ({ context, field, categories }) =>
  BaseColumnFactory({
    accessor: ({ [field]: value }) => value,
    Header: field === 'customer' ? 'Account' : field,
    Cell: UniversalCell(field),
    sortType: isIpField(field) ? 'ip' : 'alphanumeric',
    ...(context !== ContextTypes.alerts
      ? {}
      : getEventTableFilterValues(field, { categories })),
  });

/**
 * Get chart table columns.
 * @param {string[]} fields - fields
 * @param {string?} context - context
 * @param {boolean?} showLabel - show label
 * @param {Object} categories - categories
 * @param {Object} labelContext - label context from GlobalFilters
 * @return {{columns: *[], availableColumns: *[]}}
 */
const getChartTableColumns = ({
  fields,
  context,
  showLabel,
  categories,
  labelContext,
}) => {
  if (!fields?.length) {
    return { columns: [], availableColumns: [] };
  }

  // TODO: Add support for AS and GEO fields
  const skipFieldNames = fields.reduce((acc, field) => {
    switch (field) {
      case 'srcip': {
        acc[`label.ip.${labelContext.ip}.src`] = labelContext.show;
        acc.srcipname = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'dstip': {
        acc[`label.ip.${labelContext.ip}.dst`] = labelContext.show;
        acc.dstipname = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'ipinfo.ip': {
        acc['ipinfo.ipname'] = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'srcport': {
        acc[`label.port.${labelContext.port}.src`] = labelContext.show;
        acc.srcportname = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'dstport': {
        acc[`label.port.${labelContext.port}.dst`] = labelContext.show;
        acc.dstportname = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'srcports': {
        acc.srcportnames = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'dstports': {
        acc.dstportnames = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      default:
        return acc;
    }
  }, {});

  return fields.reduce(
    (acc, field) => {
      let addToColumns = true;
      let addToAvailableColumns = false;

      if (skipFieldNames[field]) {
        addToColumns = false;
        addToAvailableColumns = true;
      } else {
        switch (field) {
          case 'srcip': {
            if (!labelContext.show) {
              break;
            }
            const labelFieldNames = [
              `label.ip.${labelContext.ip}.src`,
              labelContext.ip === 'name' && 'srcipname',
            ].filter(Boolean);
            const labelIndex = fields.findIndex((f) =>
              labelFieldNames.includes(f),
            );
            if (labelIndex === -1) {
              break;
            }
            const labelFieldName = fields[labelIndex];
            acc.columns.push(
              LabelOrIpColumnFactory({
                Header: 'Source',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          case 'dstip': {
            if (!labelContext.show) {
              break;
            }
            const labelFieldNames = [
              `label.ip.${labelContext.ip}.dst`,
              labelContext.ip === 'name' && 'dstipname',
            ].filter(Boolean);
            const labelIndex = fields.findIndex((f) =>
              labelFieldNames.includes(f),
            );
            if (labelIndex === -1) {
              break;
            }
            const labelFieldName = fields[labelIndex];
            acc.columns.push(
              LabelOrIpColumnFactory({
                Header: 'Destination',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          case 'ipinfo.ip': {
            if (!labelContext.show || labelContext.ip !== 'name') {
              break;
            }
            const labelFieldName = 'ipinfo.ipname';
            const labelIndex = fields.findIndex((f) => f === labelFieldName);
            if (labelIndex === -1) {
              break;
            }
            acc.columns.push(
              LabelOrIpColumnFactory({
                Header: 'IP',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          case 'srcport': {
            if (!labelContext.show) {
              break;
            }
            const labelFieldNames = [
              `label.port.${labelContext.port}.src`,
              labelContext.port === 'name' && 'srcportname',
            ].filter(Boolean);
            const labelIndex = fields.findIndex((f) =>
              labelFieldNames.includes(f),
            );
            if (labelIndex === -1) {
              break;
            }
            const labelFieldName = fields[labelIndex];
            acc.columns.push(
              LabelOrPortColumnFactory({
                Header: 'SRC Port',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          case 'dstport': {
            if (!labelContext.show) {
              break;
            }
            const labelFieldNames = [
              `label.port.${labelContext.port}.dst`,
              labelContext.port === 'name' && 'dstportname',
            ].filter(Boolean);
            const labelIndex = fields.findIndex((f) =>
              labelFieldNames.includes(f),
            );
            if (labelIndex === -1) {
              break;
            }
            const labelFieldName = fields[labelIndex];
            acc.columns.push(
              LabelOrPortColumnFactory({
                Header: 'DST Port',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          case 'srcports': {
            if (!labelContext.show || labelContext.port !== 'name') {
              break;
            }
            const labelFieldName = 'srcportnames';
            const labelIndex = fields.findIndex((f) => f === labelFieldName);
            if (labelIndex === -1) {
              break;
            }
            acc.columns.push(
              LabelOrPortColumnFactory({
                Header: 'SRC Port',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          case 'dstports': {
            if (!labelContext.show || labelContext.port !== 'name') {
              break;
            }
            const labelFieldName = 'dstportnames';
            const labelIndex = fields.findIndex((f) => f === labelFieldName);
            if (labelIndex === -1) {
              break;
            }
            acc.columns.push(
              LabelOrPortColumnFactory({
                Header: 'DST Port',
                dataFieldName: field,
                labelFieldName,
                showLabel,
              }),
            );
            addToColumns = false;
            addToAvailableColumns = true;
            break;
          }

          default:
            break;
        }
      }

      if (addToColumns) {
        acc.columns.push(makeBaseColumn({ context, field, categories }));
      }

      if (addToAvailableColumns) {
        acc.availableColumns.push(
          makeBaseColumn({ context, field, categories }),
        );
      }

      return acc;
    },
    {
      columns: [],
      availableColumns: [],
    },
  );
};

export default getChartTableColumns;
