import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import { FORM_ERROR } from 'final-form';
import uniqBy from 'lodash.uniqby';

import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import { actions as rolesActions } from '@/redux/api/roles';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import {
  formatter,
  groups,
  makeOptions,
  parser,
} from '@/pages/Dashboards/Dashboard/utils/dashboardOptions';

import Alert from '+components/Alert';
import ConfirmModal from '+components/ConfirmModal';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { Field, FormSpy } from '+components/form/FinalForm';
import { Group, Label } from '+components/form/FormField';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateRequired,
  validateRoleName,
} from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import { NumberField } from '+components/GlobalFilters/Components';
import { Col, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useRoles from '+hooks/useRoles';
import useUIProperty from '+hooks/useUIProperty';

import FieldsSection from './components/FieldsSection';
import PermissionsSection from './components/PermissionsSection';

const hideNavParse = (value) => !value;
const hideNavFormat = (value) => !value;
const dashboardGroupBy = (option) => option.group;
const dashboardParser = (value) => parser(value);

const resourceGroups = Object.values(PermissionModel.Resources).reduce(
  (acc, item) => {
    if (!acc[item.group]) {
      acc[item.group] = [];
    }
    return {
      ...acc,
      [item.group]: [...acc[item.group], item.label],
    };
  },
  {},
);

const defaultRole = {
  isNew: true,
  name: '',
  description: '',
  canMasquerade: false,
  canSendFlow: false,
  canFetchAuditLog: false,
  canManageSubscription: false,
  permissions: Object.values(PermissionModel.Resources).map((resource) => ({
    resource: resource.value,
    fetch: true,
    create: false,
    update: false,
    delete: false,
  })),
  uiSettings: {},
};

const RolePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, activePageTab] = usePageTabs();
  const { params: routeParams } = useMatch(`${RoutePaths.roles}/:id/*`);

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);

  const {
    roles,
    isRolesFetching,
    error: rolesError,
    createdId: createdRoleId,
  } = useRoles();

  const permissions = usePermissions(PermissionModel.Resources.role.value);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);
  const retention = useSelector(customerSelectors.getRetention);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useToggle(false);
  const [formValues, setFormValues] = useState({});

  const isAdding = routeParams?.id === 'add';
  const id = isAdding ? undefined : routeParams?.id;
  const [currentUserRole] = profile?.roles || [];
  const isCurrentUserRole = currentUserRole === id;

  const [userRoleUiSettings, setUserRoleUiSettings, isPortalSettingsFetching] =
    usePortalSettingsValue(
      SettingCategories.ui,
      `${routeParams?.id}:settings`,
      {},
    );

  useLoadingIndicator(isRolesFetching || isPortalSettingsFetching);

  const role = useMemo(() => {
    const _role = roles?.[id];
    if (_role) {
      _role.permissions = uniqBy(
        [..._role.permissions, ...defaultRole.permissions],
        'resource',
      );
      if (isRolesUiSettingsEnabled) {
        _role.uiSettings = userRoleUiSettings;
      }
    }
    return _role;
  }, [roles, id, userRoleUiSettings, isRolesUiSettingsEnabled]);

  const notFound = !isRolesFetching && id && role?.id !== id;
  const hasPermissions = role?.id ? permissions?.update : permissions?.create;
  const canManageCurrent =
    !isCurrentUserRole || customer?.shortname === 'default';
  const canManage = canManageCurrent && !role?.system && hasPermissions;
  const canRemove =
    !isCurrentUserRole && !role?.system && role?.id && permissions?.delete;
  const canManageResellerParams =
    customer?.multi_account || customer?.shortname === 'default';

  const [favIds] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'favorites',
    [],
  );
  const [recent] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'recents',
    [],
  );

  const {
    recentDashboards,
    favoriteDashboards,
    customDashboards,
    systemDashboards,
  } = useMemo(() => {
    const favoriteSet = new Set(favIds);
    const recentSet = new Set(recent.map((item) => item.id));
    return Object.values(dashboardsMeta || {}).reduce(
      (acc, el) => {
        if (!isDefaultCustomer && el.hidden) {
          return acc;
        }
        if (recentSet.has(el.id)) {
          const lastseen = recent.find((item) => item.id === el.id)?.timestamp;
          acc.recentDashboards.push({ ...el, lastseen });
        }

        if (favoriteSet.has(el.id)) {
          acc.favoriteDashboards.push(el);
        }

        if (el.system) {
          acc.systemDashboards.push(el);
        } else {
          acc.customDashboards.push(el);
        }

        return acc;
      },
      {
        recentDashboards: [],
        favoriteDashboards: [],
        systemDashboards: [],
        customDashboards: [],
      },
    );
  }, [dashboardsMeta, favIds, recent, isDefaultCustomer]);

  const dashboardOptions = useMemo(
    () => [
      ...makeOptions(recentDashboards, groups.recent),
      ...makeOptions(favoriteDashboards, groups.favorites),
      ...makeOptions(systemDashboards, groups.system),
      ...makeOptions(customDashboards, groups.custom),
    ],
    [recentDashboards, favoriteDashboards, systemDashboards, customDashboards],
  );

  const dashboardFormatter = useCallback(
    (value) => formatter(value, dashboardOptions),
    [dashboardOptions],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.roles}`);
  }, []);

  const onSubmit = useCallback(
    ({ isNew, uiSettings, ...values }) => {
      const { controls: _, ...submitValues } = values;
      if (isNew) {
        dispatch(rolesActions.createRole(submitValues));
      } else {
        dispatch(rolesActions.updateRole(submitValues));
      }
      setIsSubmitting(activePageTab?.id);
      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [activePageTab?.id],
  );

  const onDeleteModalToggle = useCallback(() => {
    setShowDeleteModal((prevValue) => !prevValue);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(rolesActions.removeRole(role.id));
    onCancel();
  }, [role, onCancel]);

  const timer = useRef();
  const onChange = useCallback(({ values }) => {
    timer.current = setTimeout(() => {
      setFormValues(values);
    }, 10);
  }, []);
  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    if (role?.id && !role.system) {
      setMasqueradeUrl(`${RoutePaths.roles}`);
    }
    return () => {
      setMasqueradeUrl(null);
    };
  }, [role]);

  useEffect(() => {
    if (!isAllMetaFetched) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [isAllMetaFetched]);

  useEffect(() => {
    if (isRolesFetching || !isProcessing) {
      return undefined;
    }

    if (isSubmitting !== activePageTab?.id) {
      return undefined;
    }

    const { resolve } = isProcessing;

    setIsProcessing(null);
    setIsSubmitting(false);

    if (rolesError) {
      resolve({ [FORM_ERROR]: rolesError });
      return undefined;
    }

    resolve();

    const roleId = createdRoleId || role?.id;
    if (isRolesUiSettingsEnabled && roleId) {
      let uiSettings = formValues?.uiSettings;
      if (uiSettings?.dateTimeLimit != null) {
        uiSettings = {
          ...uiSettings,
          dateTimeLimit: Math.max(
            Math.min(uiSettings.dateTimeLimit, retention),
            0,
          ),
        };
      }
      setUserRoleUiSettings(
        uiSettings,
        true,
        SettingCategories.ui,
        `${roleId}:settings`,
      );
    }
    onCancel();

    return () => {
      dispatch(rolesActions.clearCreatedId());
    };
  }, [
    isRolesFetching,
    isProcessing,
    rolesError,
    createdRoleId,
    role?.id,
    formValues?.uiSettings,
    isRolesUiSettingsEnabled,
    retention,
    activePageTab?.id,
    onCancel,
  ]);

  const showSubscription =
    customer?.type === 'plg' || customer?.shortname === 'default';

  const isFormDisabled =
    isRolesFetching || isPortalSettingsFetching || !canManage;

  return (
    <Fragment>
      {notFound && (
        <Row height="80vh" alignItems="center">
          <Col alignItems="center">
            <h3>Role not found</h3>
            <Link to={`${RoutePaths.roles}`}>Go to Manage Roles page</Link>
          </Col>
        </Row>
      )}

      {!notFound && (
        <EditPageAuditLogTabs
          auditNqlQuery={`class == role && original_id == ${id}`}
          showTabs={!isAdding}
        >
          <FormWizard
            initialValues={role || defaultRole}
            onSubmit={onSubmit}
            onCancel={onCancel}
            loading={isRolesFetching || isPortalSettingsFetching}
            disabled={isFormDisabled}
            deleteButtonText="Delete Role"
            onDelete={onDeleteModalToggle}
            deleteButtonHidden={!role?.id}
            deleteButtonDisabled={
              isRolesFetching || isPortalSettingsFetching || !canRemove
            }
          >
            <Step>
              <FormSpy subscription={{ values: true }} onChange={onChange} />

              {hasPermissions && !role?.system && !canManageCurrent && (
                <Row paddingLeft="140px">
                  <Col>
                    <Alert>You cannot edit your own role.</Alert>
                  </Col>
                </Row>
              )}

              {hasPermissions && role?.system && (
                <Row paddingLeft="140px">
                  <Col>
                    <Alert>You cannot edit system role.</Alert>
                  </Col>
                </Row>
              )}

              {role?.id ? (
                <Group>
                  <Label>Name</Label>
                  <Plaintext>{role.name}</Plaintext>
                </Group>
              ) : (
                <Field
                  name="name"
                  label="Name"
                  helperText={
                    <Fragment>
                      Unique name of the role. Valid characters are{' '}
                      <code>0-9a-zA-Z._-</code>.<br />
                      Cannot be edited once created.
                      <br />
                      Maxlength of 32.
                    </Fragment>
                  }
                  component={TextField}
                  validate={[validateRequired, validateRoleName]}
                  disabled={isFormDisabled}
                  required
                />
              )}

              <Field
                name="description"
                label="Description"
                helperText="Longer user-friendly description of the role"
                component={TextField}
                disabled={isFormDisabled}
              />

              {canManageResellerParams && (
                <Field
                  name="canMasquerade"
                  component={ToggleField}
                  type="checkbox"
                  label="Masquerading"
                  uncheckedLabel="Disabled"
                  checkedLabel="Enabled"
                  disabled={isFormDisabled}
                />
              )}

              <Field
                name="canSendFlow"
                component={ToggleField}
                type="checkbox"
                label="Send NetoFlow"
                uncheckedLabel="Disabled"
                checkedLabel="Enabled"
                disabled={isFormDisabled}
              />

              <Field
                name="canFetchAuditLog"
                component={ToggleField}
                type="checkbox"
                label="View Audit Logs"
                uncheckedLabel="Disabled"
                checkedLabel="Enabled"
                disabled={isFormDisabled}
              />

              {showSubscription && (
                <Field
                  name="canManageSubscription"
                  component={ToggleField}
                  type="checkbox"
                  label="Manage Subscription"
                  uncheckedLabel="Disabled"
                  checkedLabel="Enabled"
                  disabled={isFormDisabled}
                />
              )}

              {isRolesUiSettingsEnabled && (
                <FieldsSection label="UI Settings">
                  <Field
                    name="uiSettings.homepageDashboardId"
                    component={SelectField}
                    options={dashboardOptions}
                    groupBy={dashboardGroupBy}
                    parse={dashboardParser}
                    format={dashboardFormatter}
                    disabled={isFormDisabled}
                    label="Default Homepage"
                    placeholder="Select dashboard..."
                    showClearButton
                  />

                  <Field
                    name="uiSettings.hideNav"
                    component={ToggleField}
                    type="checkbox"
                    label="Main Navigation"
                    uncheckedLabel="Disabled"
                    checkedLabel="Enabled"
                    disabled={isFormDisabled}
                    parse={hideNavParse}
                    format={hideNavFormat}
                  />

                  <Field
                    component={NumberField}
                    name="uiSettings.dateTimeLimit"
                    label="Date & Time Limit"
                    step={1}
                    min={1}
                    max={retention}
                    precision={0}
                    helperText={`Number of days to limit the date & time range in Searches, Dashboards, Global filters. The maximum value is ${retention} days.`}
                    showClearButton
                    disabled={isFormDisabled}
                  />
                </FieldsSection>
              )}

              <PermissionsSection
                resourceGroups={resourceGroups}
                canManage={canManage}
                canManageResellerParams={canManageResellerParams}
                disabled={isFormDisabled}
              />
            </Step>
          </FormWizard>
        </EditPageAuditLogTabs>
      )}

      {showDeleteModal && (
        <ConfirmModal
          item={role?.name}
          onToggle={onDeleteModalToggle}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default RolePage;
