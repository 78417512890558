import styled from 'styled-components';

const AdditionalFiltersSeparator = styled.div.attrs({
  className: 'additional-filters-separator',
})`
  flex-shrink: 0;
  align-self: center;
  width: ${({ $width }) => $width || 1}px;
  height: 100%;
  background: ${({ theme }) => theme.colorBackgroundSeparator};

  .MuiMenu-list & {
    height: ${({ $width }) => $width || 1}px;
    width: 100%;
  }
`;

export default AdditionalFiltersSeparator;
