import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import { FieldArray } from '+components/form/FinalForm';
import FormWizard, { Step } from '+components/FormWizard';

import RenderPasswordPolicy from './RenderPasswordPolicy';

const PasswordPolicyForm = styled((props) => {
  const { passwordPolicy, passwordPolicyTypes, disabled, onSubmit, ...tail } =
    props;

  const initialValues = useMemo(() => {
    const passwordPolicyHash = passwordPolicy.reduce(
      (acc, el) => ({ ...acc, [el.name]: { ...el, enabled: true } }),
      {},
    );
    const data = passwordPolicyTypes.map((el) => ({
      ...el,
      ...passwordPolicyHash[el.name],
    }));
    return { data };
  }, [passwordPolicyTypes, passwordPolicy]);

  const doSubmit = useCallback(
    (values) => {
      const data = values.data.reduce((acc, el) => {
        if (!el.enabled) {
          return acc;
        }
        return [...acc, { name: el.name, value: el.value }];
      }, []);
      onSubmit(data);
    },
    [onSubmit],
  );

  return (
    <FormWizard
      initialValues={initialValues}
      focusOnFields={false}
      disabled={disabled}
      onSubmit={doSubmit}
      {...tail}
    >
      <Step title="">
        <FieldArray
          name="data"
          component={RenderPasswordPolicy}
          disabled={disabled}
          required
        />
      </Step>
    </FormWizard>
  );
})`
  &.wizard,
  .fields-section,
  .form__form-group {
    margin-bottom: unset;
  }
  .wizard__actions {
    padding-top: unset;
  }
  .wizard__left-panel {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

PasswordPolicyForm.propTypes = {
  passwordPolicy: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  passwordPolicyTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PasswordPolicyForm;
