import { joinKeys } from './general';

/**
 * @param {Object} item
 * @param {string} [prefix='']
 * @param {boolean} [withPrefixInArray=true]
 * @return {Object}
 */
const flatten = (item, prefix = '', withPrefixInArray = true) => {
  if (item == null) {
    return null;
  }

  if (Array.isArray(item)) {
    return item.map((value) => flatten(value, prefix));
  }

  if (typeof item !== 'object') {
    return item;
  }

  return Object.entries(item || {}).reduce((acc, [key, entry]) => {
    const newKey = joinKeys([prefix, key]);

    if (entry == null) {
      acc[newKey] = null;
      return acc;
    }

    // Do NOT flatten Arrays, but Do flatten objects in the arrays
    if (Array.isArray(entry)) {
      acc[newKey] = flatten(entry, withPrefixInArray ? newKey : '');
      return acc;
    }

    if (typeof entry !== 'object') {
      acc[newKey] = entry;
      return acc;
    }

    return {
      ...acc,
      ...flatten(entry, newKey),
    };
  }, {});
};

/**
 * @param {Object} item
 * @param {string} [prefix='']
 * @return {Object}
 */
export const flattenObject = (item, prefix = '') =>
  flatten(item, prefix, false);

/**
 * @param {Object} item
 * @param {string} [prefix='']
 * @return {Object}
 */
export const flattenObjectPrefixInArray = (item, prefix = '') =>
  flatten(item, prefix);

export default flattenObject;
