import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  .copy-to-clipboard__button {
    margin-left: auto;
  }

  &.reverse {
    flex-direction: row-reverse;
    .copy-to-clipboard__button {
      margin-left: unset;
      margin-right: 4px;
    }
  }
`;
