import { ContextTypes } from '@/models/ContextTypes';
import { DNSMetricSettings, FlowMetricSettings } from '@/models/MetricSettings';

import { NumberColumnFactory } from '+components/Table/Columns/NumberColumnFactory';
import { formatNumber } from '+utils/format';

const MetricSettings = {
  [ContextTypes.flow]: FlowMetricSettings,
  [ContextTypes.dns]: DNSMetricSettings,
};

export const columnsCollection = (context) => [
  NumberColumnFactory({
    accessor: 'min',
    Header: 'Min',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    accessor: 'avg',
    Header: 'Avg',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    accessor: 'max',
    Header: 'Max',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    accessor: 'sum',
    Header: 'Sum',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    accessor: 'count',
    Header: 'Count',
    Cell: ({ value }) => formatNumber(value),
  }),
  NumberColumnFactory({
    id: 'parent_25',
    accessor: (row) => row.percentiles['25.0'],
    Header: '25%',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    id: 'parent_50',
    accessor: (row) => row.percentiles['50.0'],
    Header: '50%',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    id: 'parent_95',
    accessor: (row) => row.percentiles['95.0'],
    Header: '95%',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    id: 'parent_98',
    accessor: (row) => row.percentiles['98.0'],
    Header: '98%',
    Cell: ({ value, row: { original } }) =>
      MetricSettings[context][original.metric].yAxisFormatter(value),
  }),
  NumberColumnFactory({
    accessor: 'std_deviation',
    Header: <span>&sigma;</span>,
    Description: 'Standard Deviation',
    Cell: (row) => formatNumber(row.value, 2),
  }),
  NumberColumnFactory({
    accessor: 'variance',
    Header: (
      <span>
        &sigma;<sup>2</sup>
      </span>
    ),
    Description: 'Variance',
    Cell: (row) => formatNumber(row.value, 2),
  }),
];
