import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import dayjs from '+utils/dayjs';

import { Banner } from './Banner';
import { Severities } from './Severities';

const StyledBanner = styled(Banner)`
  color: black !important;
`;

const BannerLink = styled.a`
  color: black !important;
  text-decoration: underline;
  &:hover {
    color: #404040 !important;
    text-decoration: underline;
  }
`;

export const TrialBanner = () => {
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const trialEnd = useSelector(customerSelectors.getTrialEnd);
  const dispatch = useDispatch();
  useEffect(() => dayjs.extend(relativeTime), []);

  useEffect(() => {
    if (
      !trialEnd &&
      customer &&
      customer?.type === 'plg' &&
      customer?.status === 'trial_active'
    ) {
      dispatch(customerActions.fetchTrialEnd(customer.shortname));
    }
  }, [customer, trialEnd]);

  const [bannerText, bannerSeverity] = useMemo(() => {
    if (customer?.type !== 'plg') {
      return [null, null];
    }

    if (customer.status === 'trial_pending') {
      return [
        'To activate your trial account add a traffic source.',
        Severities.warn,
      ];
    }

    if (customer.status === 'trial_active') {
      return [
        trialEnd
          ? `Your trial account is currently active, it expires in ${dayjs(trialEnd).diff(dayjs(), 'day')} days.`
          : 'Your trial account is currently active.',
        Severities.info,
      ];
    }

    if (customer.status === 'trial_expired') {
      return ['Your trial account has expired', Severities.error];
    }

    return [null, null];
  }, [customer?.status, customer?.type, trialEnd]);

  return (
    <StyledBanner
      $isOpen={bannerText && bannerSeverity}
      $severity={bannerSeverity}
    >
      {bannerText} &nbsp;
      <BannerLink href="https://netography.com/pricing/">
        View all plans.
      </BannerLink>
    </StyledBanner>
  );
};
