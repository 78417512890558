import PropTypes from '+prop-types';
import { Fragment, useMemo } from 'react';

import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import NumberField from '+components/form/NumberField';
import TextField from '+components/form/TextField';
import {
  validateCombinedWhitelist,
  validateRequired,
} from '+components/form/Validators';
import useAllowlists from '+hooks/useAllowlists';

import { FACTORS } from '../../factors';
import RenderIpAggregate from '../components/RenderIpAggregate';
import { MAX_JS_DIGITS } from './constants';

const factors = FACTORS;

const BlocklistFields = (props) => {
  const { disabled } = props;

  const { allowlists } = useAllowlists();

  const allowlistsOptions = useMemo(
    () =>
      Object.values(allowlists || {}).map(({ id, name, description }) => ({
        value: `id:${id}`,
        label: name,
        description,
      })),
    [allowlists],
  );

  return (
    <Fragment>
      <Field
        name="name"
        label="Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        style={{ width: '50%' }}
        disabled={disabled}
        required
      />

      <Field
        name="description"
        label="Description"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        disabled={disabled}
      />

      <Field
        name="config.factors"
        label="Factors"
        component={MultiSelectField}
        options={factors}
        parse={normalizeMultiSelectValue}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="config.expiration"
        label="Expiration"
        component={NumberField}
        autoComplete="new-password"
        min={0}
        max={MAX_JS_DIGITS}
        style={{ width: '50%' }}
        helperText="Number of seconds the blocklist will remain active"
        disabled={disabled}
      />

      <Field
        name="config.max"
        label="Max"
        component={NumberField}
        autoComplete="new-password"
        style={{ width: '50%' }}
        min={0}
        max={MAX_JS_DIGITS}
        helperText="Limit on number of blocks"
        disabled={disabled}
      />

      <Field
        name="config.combinedWhitelist"
        label="Allow List"
        helperText="List of IP or IP/CIDR addresses."
        component={MultiSelectField}
        options={allowlistsOptions}
        parse={normalizeMultiSelectValue}
        validate={validateCombinedWhitelist}
        disabled={disabled}
        allowCreate
      />

      <Group>
        <Label>Aggregate</Label>
        <FieldContainer>
          <RenderIpAggregate disabled={disabled} />
        </FieldContainer>
        <Description>Aggregate IP addresses by mask length</Description>
      </Group>
    </Fragment>
  );
};

BlocklistFields.propTypes = {
  disabled: PropTypes.bool,
};

BlocklistFields.defaultProps = {
  disabled: false,
};

export default BlocklistFields;
