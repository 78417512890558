import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';

import { Columns } from './components/Columns';
import { getColumns } from './components/getColumns';

const defaultColumns = [
  Columns.menu,
  Columns.context,
  Columns.title,
  Columns.author,
  Columns.nql,
];

export const NqlPresetsTable = (props) => {
  const { onEdit, onCopy, onDelete, ...rest } = props;

  const profile = useSelector(profileSelectors.getProfile);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);
  const isAdmin = useSelector(profileSelectors.isAdminUser);

  const cxActionMenu = useCallback(
    (_, original = {}) => {
      const isSystem = original.system && !isDefaultCustomer;
      const isMine = original.email === profile.email;
      const isEditable = (isMine || isAdmin) && !isSystem;

      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          disabled: !isEditable,
          onClick: () => onEdit?.(original),
        },
        {
          icon: <ContentCopyIcon />,
          text: 'Copy',
          onClick: () => onCopy?.(original),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !isEditable,
          onClick: () => onDelete?.(original),
        },
      ];

      return (
        <MenuColumnContextMenu
          title={original.title}
          items={items}
          dataTracking="nql-preset"
        />
      );
    },
    [isDefaultCustomer, profile.email, onEdit, onCopy, onDelete, isAdmin],
  );

  const columns = useMemo(
    () =>
      getColumns(defaultColumns, { profileEmail: profile.email, cxActionMenu }),
    [profile.email, cxActionMenu],
  );

  return <Table {...rest} columns={columns} />;
};

NqlPresetsTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
