import get from 'lodash.get';

import { UniversalCell } from '+components/Table/Cells';
import { BaseColumnFactory } from '+components/Table/Columns/BaseColumnFactory';
import { SelectColumnFilter } from '+components/Table/Filters';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories';
import { compareIpAndLabel } from '+components/Table/ReactTable/sortTypes';
import { autoRemoveByOperator } from '+components/Table/ReactTable/utils';
import sortByHelper from '+utils/sortByHelper';

import { getRowOriginal } from './utils';

const Cell = (props) => {
  const { cell, dataFieldName, labelFieldName, showLabel, fetchLabels } = props;

  const original = getRowOriginal(cell?.row) ?? {};

  const dataFieldValue = get(original, dataFieldName);
  const labelFieldValue = get(original, labelFieldName);

  return showLabel && labelFieldValue?.length
    ? UniversalCell(labelFieldName)({
        ...cell,
        value: labelFieldValue,
        options: { useDataValueInPropertiesTray: true },
      })
    : UniversalCell(dataFieldName)({
        ...cell,
        value: dataFieldValue,
        options: {
          showAsLabel: true,
          fetchLabels: showLabel && fetchLabels && !labelFieldValue,
        },
      });
};

export const LabelOrIpColumnFactory = ({
  dataFieldName,
  labelFieldName,
  showLabel,
  fetchLabels,
  ...tail
}) => ({
  ...BaseColumnFactory(tail),
  accessor: dataFieldName,
  realAccessor: [dataFieldName, labelFieldName],
  Cell: (cell) => (
    <Cell
      cell={cell}
      dataFieldName={dataFieldName}
      labelFieldName={labelFieldName}
      showLabel={showLabel}
      fetchLabels={fetchLabels}
    />
  ),
  Filter: SelectColumnFilter({
    optionLabel: (key) => (key === 'all' ? 'All' : key),
    optionValueExtractor: (row) => {
      const original = getRowOriginal(row) ?? {};
      return get(original, labelFieldName);
    },
    enableLikeFilter: true,
  }),
  filter: someOfFieldsFilter(
    showLabel ? [dataFieldName, labelFieldName] : [dataFieldName],
    autoRemoveByOperator,
  ),
  sortType: sortByHelper((_, row) => {
    const original = getRowOriginal(row) ?? {};
    if (showLabel) {
      const labelFieldValue = get(original, labelFieldName);
      if (labelFieldValue?.length) {
        return [...labelFieldValue].sort().join('');
      }
    }
    return get(original, dataFieldName);
  }, compareIpAndLabel),
});
