import styled, { css } from 'styled-components';

export default styled.div`
  flex-shrink: 0;
  width: ${({ $width }) => $width}px;
  height: 80%;
  background-image: url(${({ theme }) => theme.logoSvgUrl});
  background-repeat: no-repeat;
  background-size: 160px 32px;
  background-position-x: 22px;
  transition:
    width 0.3s ease-in-out 0.1s,
    background-position-x 0.3s linear,
    background-size 0.3s linear;
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      background-size: 110px 32px;
      background-position-x: 5px;
      transition:
        width 0.3s ease-in-out 0.1s,
        background-position-x 0.3s linear,
        background-size 0.3s linear;
    `}
`;
