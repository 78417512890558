import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ColorTypes } from '@/models/ColorTypes';
import PermissionModel from '@/models/Permission';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal, {
  MessageAction,
  MessageContainer,
  MessageItem,
} from '+components/ConfirmModal';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import { ActionsContainer } from '+components/Layout';
import { Tab, TabContent, Tabs, TabsContainer } from '+components/Tabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';
import useRoles from '+hooks/useRoles';
import useUIProperty from '+hooks/useUIProperty';

import AuditLogsSection from './components/AuditLogsSection';
import Context from './components/Context';
import ProfileSection from './components/ProfileSection';
import SecuritySection from './components/SecuritySection';
import SessionsSection from './components/SessionsSection';
import SettingsSection from './components/SettingsSection';

const deleteAndDisableTitleTemplate = (action, item) =>
  `Delete ${item} And Disable 2FA`;

const deleteAndDisableTextTemplate = (action, item, whyAsking) => {
  return (
    <Fragment>
      <MessageContainer>
        <span>Do you really want to</span>
        <MessageAction>delete</MessageAction>
        <MessageItem>{item}</MessageItem>
        <span>&nbsp;</span>
        <span>and disable Two-Factor Authentication?</span>
      </MessageContainer>
      <div>{whyAsking}</div>
    </Fragment>
  );
};

const UserProfile = (props) => {
  const {
    user,
    otpDevices,
    sessions,
    isFetching,
    profileMode,
    onSessions,
    onSendVerificationEmail,
    onSendChangePasswordEmail,
    onOtpDevices,
    onOtpEnable,
    onOtpDisable,
    onOtpDeviceAdd,
    onOtpDeviceDelete,
    onOtpDevicesReset,
    onOtpEmailEnable,
    onOtpEmailDisable,
    onImpersonate,
    onUpdate,
    onLogout,
    onLogoutFromCustomer,
    onBlock,
    onUnblock,
    onDelete,
    breadcrumbData,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);

  const profile = useSelector(profileSelectors.getProfile);
  const permissions = usePermissions(PermissionModel.Resources.user.value);
  const { roles } = useRoles();

  const [isUnderCovered] = useUIProperty('underCover');
  const [isImpersonating] = useUIProperty('impersonating');

  const isSelf = !!(user?.id && profile.id === user?.id);
  const canMasquerade = !!roles?.[user?.roles?.[0]]?.canMasquerade;
  // disallow customers from editing netography users that are masquerading in their account
  const canManage = !!(
    user &&
    !user?.idp &&
    !user?.app_metadata?.useResellerSso &&
    (!user?.app_metadata?.original ||
      user?.app_metadata?.original === customer.shortname) &&
    (isSelf || permissions?.update)
  );
  const canManageRole = !!(
    user &&
    (!user?.idp || user?.app_metadata?.useResellerSso) &&
    (!user?.app_metadata?.original ||
      user?.app_metadata?.original === customer.shortname) &&
    (isSelf || permissions?.update)
  );
  const canRemove = !!(
    user &&
    (!user?.app_metadata?.original ||
      user?.app_metadata?.original === customer.shortname) &&
    !isSelf &&
    permissions?.delete
  );

  const [emailVerificationModalOpen, setEmailVerificationModalOpen] =
    useState(false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const [userLogoutModalOpen, setUserLogoutModalOpen] = useState(false);
  const [userLogoutFromCustomerModalOpen, setUserLogoutFromCustomerModalOpen] =
    useState(false);
  const [userBlockModalOpen, setUserBlockModalOpen] = useState(false);
  const [userDeleteModalOpen, setUserDeleteModalOpen] = useState(false);
  const [otpChangeModalOpen, setOtpChangeModalOpen] = useState(false);
  const [otpEmailChangeModalOpen, setOtpEmailChangeModalOpen] = useState(false);
  const [otpDeviceAddModalOpen, setOtpDeviceAddModalOpen] = useState(false);
  const [otpDevicesResetModalOpen, setOtpDevicesResetModalOpen] =
    useState(false);
  const [otpDeviceToDelete, setOtpDeviceToDelete] = useState(null);

  useLoadingIndicator(isFetching);

  const otpDeviceConfigurationRequested = useMemo(
    () => (user.requiredActions || []).indexOf('CONFIGURE_TOTP') !== -1,
    [user.requiredActions],
  );

  const tabs = useMemo(
    () => ({
      profile: { value: 'profile', label: 'Details' },
      ...(!profileMode
        ? {}
        : {
            personalization: {
              value: 'personalization',
              label: 'Personalization',
            },
            // if it's profile mode but user can't manage profile then user is masquerading,
            // and we need to hide security tab
            ...(canManage
              ? { security: { value: 'security', label: 'Security' } }
              : {}),
          }),
      ...(!onSessions
        ? {}
        : {
            activity: { value: 'activity', label: 'Activity' },
          }),
      auditLogs: { value: 'auditLogs', label: 'Audit Logs' },
    }),
    [profileMode, onSessions, canManage],
  );

  const defaultTab = tabs.profile;

  const tabId = useMemo(() => {
    const search = new URLSearchParams(location.search);
    const tab = search.get('tab');
    if (tab) {
      return tab;
    }
    const last = location.pathname.split('/').pop();
    return last.split('-').pop();
  }, [location.search, location.pathname]);

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabId, tabs, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      if (value === defaultTab) {
        navigate(location.pathname);
      } else {
        const search = new URLSearchParams();
        search.set('tab', value);
        navigate(`${location.pathname}?${search.toString()}`);
      }
    },
    [location.pathname, defaultTab, navigate],
  );

  const onEmailVerificationModalToggle = useCallback(() => {
    setEmailVerificationModalOpen((prevValue) => !prevValue);
  }, []);

  const onChangePasswordModalToggle = useCallback(() => {
    setPasswordChangeModalOpen((prevValue) => !prevValue);
  }, []);

  const onOtpChangeModalToggle = useCallback(() => {
    setOtpChangeModalOpen((prevValue) => !prevValue);
  }, []);

  const onOtpEmailChangeModalToggle = useCallback(() => {
    setOtpEmailChangeModalOpen((prevValue) => !prevValue);
  }, []);

  const onOtpDeviceAddModalToggle = useCallback(() => {
    setOtpDeviceAddModalOpen((prevValue) => !prevValue);
  }, []);

  const onOtpDeviceDeleteToggle = useCallback((value) => {
    setOtpDeviceToDelete(value);
  }, []);

  const onOtpDevicesResetModalToggle = useCallback(() => {
    setOtpDevicesResetModalOpen((prevValue) => !prevValue);
  }, []);

  const onOtpDeviceDeleteConfirm = useCallback(() => {
    setOtpDeviceToDelete(null);
    if (otpDevices?.length > 1 || otpDeviceConfigurationRequested) {
      onOtpDeviceDelete(otpDeviceToDelete.id);
    } else {
      onOtpDisable();
    }
  }, [
    otpDevices?.length,
    otpDeviceToDelete,
    otpDeviceConfigurationRequested,
    onOtpDeviceDelete,
    onOtpDisable,
  ]);

  const onUserLogoutToggle = useCallback(() => {
    setUserLogoutModalOpen((prevValue) => !prevValue);
  }, []);

  const onUserLogoutFromCustomerToggle = useCallback(() => {
    setUserLogoutFromCustomerModalOpen((prevValue) => !prevValue);
  }, []);

  const onUserBlockToggle = useCallback(() => {
    setUserBlockModalOpen((prevValue) => !prevValue);
  }, []);

  const onUserDeleteToggle = useCallback(() => {
    setUserDeleteModalOpen((prevValue) => !prevValue);
  }, []);

  const actions = useMemo(
    () =>
      [
        !user?.blocked &&
          [
            !user?.email_verified && (
              <DropdownItem
                key="email_verified"
                onClick={onEmailVerificationModalToggle}
              >
                Send Verification Email
              </DropdownItem>
            ),
            <DropdownItem
              key="change_password"
              onClick={onChangePasswordModalToggle}
            >
              Send Password Change Email
            </DropdownItem>,
            <DropdownItem key="header1" header>
              Two Factor Authentication
            </DropdownItem>,
            user?.otpEmail ? (
              <DropdownItem
                key="otp_email_disable"
                onClick={onOtpEmailChangeModalToggle}
              >
                Disable via Email
              </DropdownItem>
            ) : (
              <DropdownItem
                key="otp_email_enable"
                onClick={onOtpEmailChangeModalToggle}
              >
                Enable via Email
              </DropdownItem>
            ),
            user?.otp ? (
              [
                <DropdownItem
                  key="otp_disable"
                  disabled={customer.isOtpRequired}
                  onClick={onOtpChangeModalToggle}
                >
                  Disable via OTP
                </DropdownItem>,
                <DropdownItem
                  key="otp_reset_devices"
                  onClick={onOtpDevicesResetModalToggle}
                >
                  Reset All OTP Devices
                </DropdownItem>,
              ]
            ) : (
              <DropdownItem key="otp_enable" onClick={onOtpChangeModalToggle}>
                Enable via OTP
              </DropdownItem>
            ),
            <DropdownItem key="header3" header>
              Manage
            </DropdownItem>,
            // Remove this `&& false` if we want to have ability to impersonate users
            // @see: https://netography.atlassian.net/browse/PORTAL-1289
            canMasquerade && false && (
              <DropdownItem key="login_as" onClick={onImpersonate}>
                Login As
              </DropdownItem>
            ),
            user?.app_metadata?.original &&
              user?.app_metadata?.original === customer.shortname && (
                <DropdownItem
                  key="revoke_masquerade"
                  onClick={onUserLogoutFromCustomerToggle}
                >
                  Revoke Masquerade
                </DropdownItem>
              ),
            <DropdownItem key="logout" onClick={onUserLogoutToggle}>
              Logout User
            </DropdownItem>,
            <DropdownItem key="block" onClick={onUserBlockToggle}>
              Block User
            </DropdownItem>,
          ].filter(Boolean),
        user?.blocked && (
          <DropdownItem key="unblock" onClick={onUserBlockToggle}>
            Unblock User
          </DropdownItem>
        ),
      ]
        .filter(Boolean)
        .flat(),
    [
      user,
      onEmailVerificationModalToggle,
      onChangePasswordModalToggle,
      onOtpChangeModalToggle,
      onOtpEmailChangeModalToggle,
      onImpersonate,
      onUserLogoutToggle,
      onUserBlockToggle,
      onOtpDevicesResetModalToggle,
      canMasquerade,
    ],
  );

  const value = {
    customer,
    profile,
    user,
    roles,
    isUnderCovered,
    isImpersonating,
    isSelf,
    canManage,
    canManageRole,
    canRemove,
    isFetching,
    otpDevices,
    sessions,
    onDelete: onUserDeleteToggle,
  };

  const valueMemo = useMemo(() => value, Object.values(value));

  useEffect(() => {
    if (!samlProvider?.alias) {
      dispatch(customerActions.requestSamlProvider());
    }
  }, [samlProvider?.alias]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    if (!profileMode) {
      const rootPath = location.pathname.slice(
        0,
        location.pathname.lastIndexOf('/'),
      );
      setMasqueradeUrl(rootPath);
    }
    return () => {
      setMasqueradeUrl(null);
    };
  }, [profileMode, location.pathname]);

  const emailVerificationModalWhyAsking = `This will send an email ${
    isSelf ? 'to you' : 'to the user'
  } to verify ${isSelf ? 'your' : 'their'} email address.`;

  const passwordChangeModalWhyAsking = `This will send an email ${
    isSelf ? 'to you' : 'to the user'
  } with the link to the change password page, where ${
    isSelf ? 'you' : 'they'
  } can configure a new password.`;

  const otpChangeModalWhyAsking = user.otp
    ? `It will remove all ${
        isSelf ? 'your' : "user's"
      } two-factor authenticator devices.`
    : `It will affect after ${isSelf ? 'you' : 'user'} will logout and ${
        isSelf ? 'you' : 'user'
      } will be required to setup two-factor authentication the next time login.`;

  return (
    <Context.Provider value={valueMemo}>
      <TabsContainer>
        {breadcrumbData && <Breadcrumb {...breadcrumbData} />}

        {!profileMode && (
          <Tabs value={currentTab.value} onChange={onTabChange}>
            <Tab label={tabs.profile.label} value={tabs.profile.value} />
            {tabs.personalization && (
              <Tab
                label={tabs.personalization.label}
                value={tabs.personalization.value}
              />
            )}
            {tabs.security && (
              <Tab label={tabs.security.label} value={tabs.security.value} />
            )}
            {tabs.activity && (
              <Tab label={tabs.activity.label} value={tabs.activity.value} />
            )}
            <Tab label={tabs.auditLogs.label} value={tabs.auditLogs.value} />
          </Tabs>
        )}

        {currentTab === tabs.profile && (
          <TabContent>
            {!profileMode && !user?.idp && (
              <ActionsContainer>
                <Dropdown
                  caption="Actions"
                  disabled={isFetching || !canManage || isSelf}
                >
                  {actions}
                </Dropdown>
              </ActionsContainer>
            )}

            <ProfileSection
              onSendVerificationEmail={onEmailVerificationModalToggle}
              onUpdate={onUpdate}
            />
          </TabContent>
        )}

        {currentTab === tabs.personalization && (
          <TabContent>
            <SettingsSection />
          </TabContent>
        )}

        {currentTab === tabs.security && (
          <TabContent>
            <SecuritySection
              onSendChangePasswordEmail={onChangePasswordModalToggle}
              onOtpChange={onOtpChangeModalToggle}
              onOtpDeviceAdd={onOtpDeviceAddModalToggle}
              onOtpDeviceDelete={onOtpDeviceDeleteToggle}
              onOtpDevices={onOtpDevices}
              onOtpEmailChange={onOtpEmailChangeModalToggle}
            />
          </TabContent>
        )}

        {currentTab === tabs.activity && (
          <TabContent>
            <SessionsSection onSessions={onSessions} />
          </TabContent>
        )}

        {currentTab === tabs.auditLogs && (
          <TabContent>
            <AuditLogsSection />
          </TabContent>
        )}

        {emailVerificationModalOpen && (
          <ConfirmModal
            item="verification email"
            confirmButtonText="Send"
            confirmButtonColor={ColorTypes.primary}
            whyAsking={emailVerificationModalWhyAsking}
            onToggle={onEmailVerificationModalToggle}
            onConfirm={onSendVerificationEmail}
            isOpen
          />
        )}

        {passwordChangeModalOpen && (
          <ConfirmModal
            item="password change email"
            confirmButtonText="Send"
            confirmButtonColor={ColorTypes.primary}
            whyAsking={passwordChangeModalWhyAsking}
            onToggle={onChangePasswordModalToggle}
            onConfirm={onSendChangePasswordEmail}
            isOpen
          />
        )}

        {otpChangeModalOpen && (
          <ConfirmModal
            item="two-factor authentication"
            confirmButtonText={user.otp ? 'Disable' : 'Enable'}
            confirmButtonColor={ColorTypes.primary}
            whyAsking={otpChangeModalWhyAsking}
            onToggle={onOtpChangeModalToggle}
            onConfirm={user.otp ? onOtpDisable : onOtpEnable}
            isOpen
          />
        )}

        {otpEmailChangeModalOpen && (
          <ConfirmModal
            item="two-factor authentication by email"
            confirmButtonText={user.otpEmail ? 'Disable' : 'Enable'}
            confirmButtonColor={ColorTypes.primary}
            whyAsking=""
            onToggle={onOtpEmailChangeModalToggle}
            onConfirm={user.otpEmail ? onOtpEmailDisable : onOtpEmailEnable}
            isOpen
          />
        )}

        {otpDeviceAddModalOpen && (
          <ConfirmModal
            item="two-factor authenticator device"
            confirmButtonText="Add"
            confirmButtonColor={ColorTypes.primary}
            whyAsking=""
            onToggle={onOtpDeviceAddModalToggle}
            onConfirm={onOtpDeviceAdd}
            isOpen
          />
        )}

        {otpDevicesResetModalOpen && (
          <ConfirmModal
            item="two-factor authenticator device"
            confirmButtonText="Reset"
            onToggle={onOtpDevicesResetModalToggle}
            onConfirm={onOtpDevicesReset}
            isOpen
          />
        )}

        {otpDeviceToDelete && (
          <ConfirmModal
            item={`${otpDeviceToDelete.userLabel || 'unnamed'} OTP device`}
            titleTemplate={
              otpDevices?.length === 1 && !otpDeviceConfigurationRequested
                ? deleteAndDisableTitleTemplate
                : undefined
            }
            textTemplate={
              otpDevices?.length === 1 && !otpDeviceConfigurationRequested
                ? deleteAndDisableTextTemplate
                : undefined
            }
            confirmButtonText={
              otpDevices?.length === 1 && !otpDeviceConfigurationRequested
                ? 'Delete And Disable 2FA'
                : 'Delete'
            }
            whyAsking={
              otpDevices?.length === 1 && !otpDeviceConfigurationRequested
                ? 'Removing the last device will disable Two-Factor Authentication.'
                : undefined
            }
            onToggle={() => onOtpDeviceDeleteToggle(null)}
            onConfirm={onOtpDeviceDeleteConfirm}
            isOpen
          />
        )}

        {userLogoutModalOpen && (
          <ConfirmModal
            item={user.email}
            confirmButtonText="Logout"
            confirmButtonColor={ColorTypes.primary}
            whyAsking=""
            onToggle={onUserLogoutToggle}
            onConfirm={onLogout}
            isOpen
          />
        )}

        {userLogoutFromCustomerModalOpen && (
          <ConfirmModal
            item={user.email}
            confirmButtonText="Revoke Masquerade"
            confirmButtonColor={ColorTypes.primary}
            whyAsking=""
            onToggle={onUserLogoutFromCustomerToggle}
            onConfirm={onLogoutFromCustomer}
            isOpen
          />
        )}

        {userBlockModalOpen && (
          <ConfirmModal
            item={user.email}
            confirmButtonText={user.blocked ? 'Unblock' : 'Block'}
            confirmButtonColor={ColorTypes.primary}
            whyAsking=""
            onToggle={onUserBlockToggle}
            onConfirm={user.blocked ? onUnblock : onBlock}
            isOpen
          />
        )}

        {userDeleteModalOpen && (
          <ConfirmModal
            item={user.email}
            onToggle={onUserDeleteToggle}
            onConfirm={onDelete}
            isOpen
          />
        )}
      </TabsContainer>
    </Context.Provider>
  );
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    email_verified: PropTypes.bool,
    otp: PropTypes.bool,
    idp: PropTypes.string,
    otpEmail: PropTypes.bool,
    blocked: PropTypes.bool,
    requiredActions: PropTypes.arrayOf(PropTypes.string),
    app_metadata: PropTypes.shape({
      original: PropTypes.string,
      useResellerSso: PropTypes.bool,
    }),
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  otpDevices: PropTypes.arrayOf(PropTypes.shape({})),
  sessions: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
  profileMode: PropTypes.bool,
  onSendVerificationEmail: PropTypes.func,
  onSendChangePasswordEmail: PropTypes.func,
  onSessions: PropTypes.func,
  onOtpDevices: PropTypes.func,
  onOtpEnable: PropTypes.func,
  onOtpDisable: PropTypes.func,
  onOtpDeviceAdd: PropTypes.func,
  onOtpDeviceDelete: PropTypes.func,
  onOtpDevicesReset: PropTypes.func,
  onOtpEmailEnable: PropTypes.func,
  onOtpEmailDisable: PropTypes.func,
  onImpersonate: PropTypes.func,
  onUpdate: PropTypes.func,
  onLogout: PropTypes.func,
  onLogoutFromCustomer: PropTypes.func,
  onBlock: PropTypes.func,
  onUnblock: PropTypes.func,
  onDelete: PropTypes.func,
  breadcrumbData: PropTypes.shape({}),
};

UserProfile.defaultProps = {
  user: null,
  otpDevices: null,
  sessions: null,
  isFetching: false,
  profileMode: false,
  onSendVerificationEmail: null,
  onSendChangePasswordEmail: null,
  onSessions: null,
  onOtpDevices: null,
  onOtpEnable: null,
  onOtpDisable: null,
  onOtpEmailEnable: null,
  onOtpEmailDisable: null,
  onOtpDeviceAdd: null,
  onOtpDeviceDelete: null,
  onOtpDevicesReset: null,
  onImpersonate: null,
  onUpdate: null,
  onLogout: null,
  onLogoutFromCustomer: null,
  onBlock: null,
  onUnblock: null,
  onDelete: null,
  breadcrumbData: null,
};

export default UserProfile;
