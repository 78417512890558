import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import capitalize from 'lodash.capitalize';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import {
  actions as filtersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';

import TimeseriesChart from '+components/charts/TimeseriesChart';
import { usePageTabs } from '+components/PageTabs';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import getMetricFieldName from '+utils/getMetricFieldName';
import { timeBounds } from '+utils/timeBounds';

const chartType = 'vpc';
const fields = {
  [ContextTypes.flow]: ['flowsrcname'],
  [ContextTypes.dns]: ['datasrc'],
};

const SourcesLineChart = (props) => {
  const {
    sources,
    width,
    height,
    metric: searchMetric,
    excludeMetrics,
    nqlQuery,
    size,
    context,
  } = props;

  const dispatch = useDispatch();

  const [, activePageTab] = usePageTabs();
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );
  const filtersManualRefresher = useSelector(
    globalFiltersSelectors.getRefresherManualOnly(activePageTab?.id),
  );

  const namespace = useMemo(() => `sources-line-chart-${context}`, [context]);

  const [filters] = useGlobalFilters(context);
  const { start, end } = timeBounds(filters);
  const request = useMemo(
    () => ({
      seriesId: namespace,
      params: {
        start,
        end,
        series: [
          {
            metric: filters.metric,
            size,
            name: chartType,
            field: fields[context],
            ...StatsRequest.makeSearch({
              search: filters.nql,
              andSearch: nqlQuery,
              intersect: filters.intersect,
            }),
          },
        ],
      },
    }),
    [
      namespace,
      start,
      end,
      filters.metric,
      filters.nql,
      filters.intersect,
      nqlQuery,
      size,
      context,
    ],
  );

  const { series, isFetching: isStatsFetching } = useStatsRequest({
    context,
    requestType: StatsRequest.Types.ts,
    request,
    stopRequest: excludeMetrics.includes(filters.metric) || !sources?.length,
    refresher: filtersManualRefresher,
    stopPollingHeartbeat: !autoRefresh,
  });

  const title = useMemo(
    () =>
      series === undefined
        ? ''
        : `Top ${size} by ${capitalize(filters.metric)}`,
    [series, size, filters.metric],
  );

  useLoadingIndicator(isStatsFetching);

  useEffect(() => {
    if (searchMetric) {
      dispatch(
        filtersActions.changeFilter({
          [getMetricFieldName(ContextTypes.flow)]: searchMetric,
        }),
      );
    }
  }, [searchMetric]);

  return (
    <TimeseriesChart
      title={title}
      fields={fields[context]}
      series={series}
      width={width}
      height={height}
      loading={!series}
      stacked="normal"
    />
  );
};

SourcesLineChart.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
  height: PropTypes.number,
  filter: PropTypes.shape({}),
  metric: PropTypes.string,
  excludeMetrics: PropTypes.arrayOf(PropTypes.string),
  nqlQuery: PropTypes.string,
  size: PropTypes.number,
  context: PropTypes.string.isRequired,
};

SourcesLineChart.defaultProps = {
  sources: [],
  width: undefined,
  height: 300,
  filter: {},
  metric: '',
  excludeMetrics: [],
  nqlQuery: '',
  size: 10,
};

export default SourcesLineChart;
