import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

const path = '/portal-settings';

const initialState = {};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const apply = (state, data) => {
  if (!data) {
    return;
  }

  data = Array.isArray(data) ? data : [data];

  data.forEach((item) => {
    if (!state[item.category]) {
      state[item.category] = {};
    }
    state[item.category][item.property] = item;
  });
};

const slice = createSlice({
  name: 'portalSettings',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchPortalSettings: startFetching,
    fetchPortalSetting: startFetching,

    fetchSuccess(state, { payload: { data } }) {
      stopFetching(state);
      apply(state, data);
    },

    savePortalSetting: startFetching,
    // this action is needed to users don't have lag between changing a prop and backend operation.
    preSavePortalSetting(state, { payload: { data } }) {
      apply(state, data);
    },
    savePortalSettingSuccess(state, { payload: { data } }) {
      stopFetching(state);
      apply(state, data);
    },

    removePortalSetting: startFetching,
    // this action is needed to users don't have lag between changing a prop and backend operation.
    preRemovePortalSetting(state, { payload: { data } }) {
      if (state[data.category]?.[data.property]) {
        delete state[data.category]?.[data.property];
      }
    },
    removePortalSettingSuccess(state, { payload: { data } }) {
      stopFetching(state);
      apply(state, data);
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchPortalSettings]: {
      taker: takeLeading(actions.skip),
      *saga() {
        try {
          initApi();

          const { data } = yield call(api.get, path);

          yield put(actions.fetchSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error retrieving portal settings',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchPortalSetting]: {
      taker: takeLeading(actions.skip),
      *saga({ payload: { category, property } }) {
        try {
          initApi();

          const { data } = yield call(
            api.get,
            `${path}/${category}/${property}`,
          );
          yield put(actions.fetchSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: `Error retrieving portal setting for ${category} and ${property}`,
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.savePortalSetting]: {
      *saga({ payload: { isDefault, ...settings } }) {
        try {
          initApi();
          // this action is needed to users don't have lag between changing a prop and backend operation.
          yield put(actions.preSavePortalSetting({ data: settings }));
          let { data } = yield call(api.put, path, settings);
          if (data.data && data.data.default !== !!isDefault) {
            const responce = yield call(
              api.post,
              `${path}/${isDefault ? '' : 'un'}set-default/${data.data.id}`,
            );
            data = responce.data;
          }
          yield put(actions.savePortalSettingSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error saving portal settings',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removePortalSetting]: {
      *saga({ payload }) {
        try {
          initApi();

          let data = { data: payload };

          // this action is needed to users don't have lag between changing a prop and backend operation.
          yield put(actions.preRemovePortalSetting({ data: payload }));

          if (payload?.id) {
            const response = yield call(api.delete, `${path}/${payload.id}`);

            data = response.data;
          }

          yield put(actions.removePortalSettingSuccess(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error saving portal settings',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getCategorySelector: (category, defaultValue) => {
      return createSelector([getState], (state) => {
        if (!state?.[category]) {
          return defaultValue;
        }
        return state?.[category];
      });
    },
    getCategoryPropertySelector: (category, property) => {
      return createSelector(
        [getState],
        (state) => state?.[category]?.[property],
      );
    },
  }),
});

export const { actions, selectors } = slice;
export default slice;
