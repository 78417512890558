import PropTypes from '+prop-types';

import styled, { css } from 'styled-components';

import PlusCircleIcon from 'mdi-react/PlusCircleIcon';

import { Col, Row } from '+components/Layout';

const PlusIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  visibility: hidden;
`;

const GroupName = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const Container = styled(Col)`
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: ${({ $width }) => $width};
  max-width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  border-radius: 4px;
  padding: 8px;
  background-color: ${({ theme, $secondaryColor }) =>
    $secondaryColor
      ? theme.secondaryPluginCardBackground
      : theme.pluginCardBackground};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, $secondaryColor }) =>
      $secondaryColor
        ? theme.secondaryPluginCardHoverBackground
        : theme.pluginCardHoverBackground};
    ${PlusIconContainer} {
      color: ${({ theme }) => theme.pluginCardIconColor} !important;
      visibility: visible;
    }
  }

  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
      * {
        color: ${props.theme.colorTextSecondary} !important;
        fill: ${props.theme.colorTextSecondary} !important;
      }
      ${PlusIconContainer} {
        display: none;
      }
    `};
`;

const PluginCard = ({
  group,
  name,
  icon,
  caption,
  disabled,
  onClick,
  width,
  height,
  fontSize,
  secondaryColor,
  testId,
}) => (
  <Container
    type="button"
    title={caption}
    $disabled={disabled}
    onClick={onClick}
    $width={width}
    $height={height}
    $secondaryColor={secondaryColor}
    data-testid={testId}
  >
    {group && <GroupName>{group}</GroupName>}

    <Col gap="4px">
      <Row justifyContent="center">{icon}</Row>
      <Row justifyContent="center" textAlign="center" fontSize={fontSize}>
        {name}
      </Row>
    </Col>

    <PlusIconContainer>
      <PlusCircleIcon size={28} />
    </PlusIconContainer>
  </Container>
);

PluginCard.propTypes = {
  group: PropTypes.string,
  name: PropTypes.string,
  caption: PropTypes.string,
  icon: PropTypes.children,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  secondaryColor: PropTypes.bool,
  testId: PropTypes.string,
};

PluginCard.defaultProps = {
  group: '',
  name: '',
  caption: '',
  icon: null,
  disabled: false,
  onClick: () => {},
  width: '262px',
  height: '94px',
  fontSize: '16px',
  secondaryColor: false,
  testId: undefined,
};

export default PluginCard;
