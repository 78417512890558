import PropTypes from '+prop-types';
import { useCallback, useMemo, useRef } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';

import Error from '+components/form/FormField/components/Error';

import NQLTextArea from './NQLTextArea';

const defaultPlaceholders = {
  [ContextTypes.flow]:
    'Example: srcport >= 80 AND srcport <= 1024 && dstport != 443 && dstip == 10.0.0.0/24',
  [ContextTypes.dns]: 'Example: query.type == AAAA',
  [ContextTypes.alerts]: 'Example: categories == iprep',
  [ContextTypes.blocks]: 'Example: dstport != 443 && dstip == 10.0.0.0/24',
  [ContextTypes.thresholdFlow]:
    'Example: avg(bitsxrate) >= 1000 && count(srcip) >= 10',
  [ContextTypes.thresholdDns]:
    'Example: avg(avgdepth) > 100 && cnt(instanceid) > 5',
  [ContextTypes.traffic]: 'Example: srcip == 10.0.0.12 and srcport == 53',
  [ContextTypes.audit]: 'action == create',
};

const Container = styled.div`
  position: relative;
`;

const NqlTextField = (props) => {
  const {
    input = {},
    meta = {},
    className,
    style,
    placeholder: placeholderProp,
    defaultValue,
    context,
    helperText,
    error,
    onChange,
    onBlur,
    onFocus,
    errorPositionOver,
    disabled,
    ...tail
  } = props;

  const fixedDefaultValue = useRef(input.defaultValue || defaultValue);

  const placeholder = disabled
    ? ''
    : input?.placeholder || placeholderProp || defaultPlaceholders[context];
  const errorMessage = error || meta.error || meta.submitError;

  const touched = meta.touched && (meta.dirty || meta.submitFailed);

  const invalid = errorMessage && (errorMessage !== 'Required' || touched);

  const hasHelp = useMemo(
    () => input.helperText || helperText,
    [input.helperText, helperText],
  );

  const onChangeField = input?.onChange || onChange;

  const onSubmit = useCallback(
    (text, event) => {
      if (fixedDefaultValue.current) {
        fixedDefaultValue.current = '';
      }
      if (onChangeField) {
        onChangeField(text, event);
      }
    },
    [onChangeField],
  );

  return (
    <Container
      className={classNames(input?.className || className || '', { invalid })}
      style={input?.style || style}
    >
      <NQLTextArea
        {...tail}
        rows={6}
        cols={60}
        wrap="off"
        name={input?.name}
        placeholder={placeholder}
        onSubmit={onSubmit}
        value={input?.value || fixedDefaultValue.current}
        context={input?.context || context}
        invalid={invalid}
        disabled={disabled}
        onBlur={onBlur || input?.onBlur}
        onFocus={onFocus || input?.onFocus}
      />
      {invalid && (
        <Error $errorOver={!hasHelp && errorPositionOver}>{errorMessage}</Error>
      )}
      {hasHelp && (
        <span className="rta__description">
          {input.helperText || helperText}
        </span>
      )}
    </Container>
  );
};

NqlTextField.propTypes = {
  ...NQLTextArea.propTypes,
  onChange: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  helperText: PropTypes.string,
  error: PropTypes.string,
  input: PropTypes.shape({}),
  errorPositionOver: PropTypes.bool,
};

NqlTextField.defaultProps = {
  onChange: null,
  className: '',
  defaultValue: '',
  placeholder: '',
  style: { width: '100%' },
  helperText: null,
  error: null, // form validation,
  input: {},
  errorPositionOver: false,
};

export default NqlTextField;
