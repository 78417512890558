import PropTypes from '+prop-types';
import { Children, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import useEvent from '+hooks/useEvent';
import { makeId } from '+utils/general';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: ${(props) => props.$gap}px;
  width: 100%;
  overflow: hidden;
  min-width: 12ch;

  .menu-trigger {
    overflow: unset !important;
  }

  [class^='HeaderSubheader'] {
    width: unset;
  }

  .__chip {
    &-hidden {
      display: none !important;
    }

    &-ellipsis {
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const CounterButton = styled.button`
  display: ${(props) => (props.$visible ? 'flex' : 'none')};

  justify-content: center;
  align-items: center;
  align-self: center;

  height: 21px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 8px;
  border-radius: 10px;
  outline: unset;
  white-space: nowrap;

  color: ${({ theme }) => theme.showMoreButtonText};
  background-color: ${({ theme }) => theme.showMoreButtonBackground};
  border: 0 solid ${({ theme }) => theme.showMoreButtonBackground};

  &:disabled {
    color: ${({ theme }) => theme.showMoreButtonTextDisabled};
    background-color: ${({ theme }) => theme.showMoreButtonBackgroundDisabled};
    border: 0 solid ${({ theme }) => theme.showMoreButtonBackgroundDisabled};
  }

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.showMoreButtonBackgroundHover};
  }
`;

const counterButtonId = makeId();

const ShowMoreWrapper = (props) => {
  const {
    gap,
    originalLength,
    slicedLength,
    minRenderLength,
    maxRenderLength,
    onShowMore,
  } = props;

  const firstLoad = useRef(true);
  const [containerNode, setContainerNode] = useState(null);
  const [hiddenLength, setHiddenLength] = useState(0);

  const children = useMemo(
    () => Children.toArray(props.children),
    [props.children],
  );

  const doShowMore = useEvent((event) => onShowMore?.(event, containerNode));

  const onResize = useEvent((width) => {
    if (!children?.length || !containerNode) {
      return;
    }

    let _hiddenCount = 0;

    const childrenLength = containerNode.children.length;

    const containerNodeRect = containerNode.getBoundingClientRect();
    const containerNodeRightBound = containerNodeRect.x + width;

    const childrenNodes = Array.from(containerNode.children);

    const counterNodeRect = childrenNodes.at(-1).getBoundingClientRect();
    const counterNodeWidth = counterNodeRect.width + gap;

    childrenNodes.forEach((childNode, index) => {
      // hide all child to the right if one before was already hidden (_hiddenCount > 0)
      // or hide if child width more than container's
      // but do not hide last element (last is [+N] counter, and it's hidden by its own logic)
      if (childNode.id === counterButtonId) {
        return;
      }

      childNode.classList.add('__chip');
      childNode.classList.remove('__chip-ellipsis');

      if (minRenderLength && index < minRenderLength) {
        childNode.classList.remove('__chip-hidden');
        return;
      }

      let hide = !!_hiddenCount;

      if (!hide) {
        childNode.classList.remove('__chip-hidden');
        const childNodeRect = childNode.getBoundingClientRect();
        const childNodeRightBound = childNodeRect.x + childNodeRect.width;
        // do not include [+N] counter width if it's last label
        const childNodeRightOffset =
          index === childrenLength - 2 ? 0 : counterNodeWidth;
        hide =
          childNodeRightBound + childNodeRightOffset > containerNodeRightBound;
      }

      if (hide) {
        childNode.classList.add('__chip-hidden');
        _hiddenCount += 1;
      }
    });

    const lastVisibleChild = Array.from(
      containerNode.querySelectorAll('.__chip:not(.__chip-hidden)'),
    ).at(-1);
    lastVisibleChild?.classList?.add('__chip-ellipsis');

    const _slicedLength =
      originalLength > slicedLength ? originalLength - slicedLength : 0;

    setHiddenLength(
      (children.length > maxRenderLength
        ? _hiddenCount + children.length - maxRenderLength
        : _hiddenCount) + _slicedLength,
    );
  });

  const observer = useMemo(
    () =>
      new ResizeObserver((elements) => {
        const [{ contentRect }] = elements || [];

        if (!contentRect) {
          return;
        }

        const { width } = contentRect;
        if (firstLoad.current) {
          firstLoad.current = false;
          onResize(width);
        } else {
          // put func inside requestAnimationFrame for performance reasons
          requestAnimationFrame(() => onResize(width));
        }
      }),
    [],
  );

  useEffect(
    () => {
      if (!containerNode) {
        return undefined;
      }

      observer.observe(containerNode);

      return () => {
        observer.disconnect();
      };
    },
    // we need children in deps to re-observe on children change
    [containerNode, children],
  );

  return !children?.length ? null : (
    <Container ref={setContainerNode} $gap={gap}>
      {children.slice(0, maxRenderLength)}

      <CounterButton
        id={counterButtonId}
        type="button"
        $visible={!!hiddenLength}
        title={onShowMore ? 'Show more' : undefined}
        $disabled={!onShowMore}
        disabled={!onShowMore}
        onClick={doShowMore}
      >
        +{hiddenLength}
      </CounterButton>
    </Container>
  );
};

ShowMoreWrapper.propTypes = {
  children: PropTypes.children.isRequired,
  gap: PropTypes.number,
  originalLength: PropTypes.number,
  slicedLength: PropTypes.number,
  minRenderLength: PropTypes.number,
  maxRenderLength: PropTypes.number,
  onShowMore: PropTypes.func,
};

ShowMoreWrapper.defaultProps = {
  gap: 10,
  originalLength: 0,
  slicedLength: 0,
  minRenderLength: 1,
  maxRenderLength: 20,
  onShowMore: null,
};

export default ShowMoreWrapper;
