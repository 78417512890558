import PropTypes from '+prop-types';

export const CardTitle = (props) => {
  const { Component = 'div', head, subhead, className, ...rest } = props;

  return (
    <Component className={`card__title ${className || ''}`} {...rest}>
      <h5 className="head">{head}</h5>
      {subhead && <div className="subhead">{subhead}</div>}
    </Component>
  );
};

CardTitle.propTypes = {
  Component: PropTypes.component,
  head: PropTypes.node.isRequired,
  subhead: PropTypes.node,
  className: PropTypes.string,
};

CardTitle.defaultProps = {
  Component: 'div',
  subhead: undefined,
  className: undefined,
};
