/* eslint-disable no-param-reassign */
import { UniversalCell } from '+components/Table/Cells';
import {
  AnswersColumnFactory,
  BaseColumnFactory,
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  StringsArrayColumnsFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';

// commented out columns can fallback on the default presentation (text)
export const Columns = {
  // TODO uncomment when labels for dns are ready (https://netography.atlassian.net/browse/SQB-179)
  _source: '_source',
  _srcPort: '_srcPort',
  action: 'action',
  dstinternal: 'internal',
  protocol: 'protocol',
  srcip: 'srcip',
  srcport: 'srcport',
  timestamp: 'timestamp',
  customer: 'customer',
  menu: 'menu',
  queryName: 'query.name',
  queryType: 'query.type',
  queryDepth: 'query.depth',
  rcode: 'rcode',
  answercount: 'answercount',
  datasrc: 'datasrc',
  answers: 'answers',
  answersClass: 'answers.class',
  answersType: 'answers.type',
  answersRdata: 'answers.rdata',
  type: 'type',
};

export const columnsCollection = ({ labelContext, cxActionMenu }) => {
  const collection = {
    [Columns._source]: LabelOrIpColumnFactory({
      Header: 'Source',
      dataFieldName: 'srcip',
      labelFieldName: `label.ip.${labelContext.ip}.src`,
      showLabel: labelContext.show,
    }),
    [Columns._srcPort]: LabelOrPortColumnFactory({
      Header: 'SRC Port',
      dataFieldName: 'srcport',
      labelFieldName: `label.port.${labelContext.port}.src`,
      showLabel: labelContext.show,
    }),
    [Columns.srcip]: BaseColumnFactory({
      // width: 110,
      getCellProps: () => ({ style: { textAlign: 'right' } }),
      sortType: 'ip',
    }),
    [Columns.srcport]: NumberColumnFactory({
      // width: 90,
    }),
    [Columns.queryName]: BaseColumnFactory({
      // width: 120,
    }),
    [Columns.queryType]: StringsArrayColumnsFactory({
      // width: 100,
    }),
    [Columns.queryDepth]: BaseColumnFactory({
      width: 90,
    }),
    [Columns.action]: BaseColumnFactory({
      getCellProps: () => ({ style: { textAlign: 'center' } }),
      width: 100,
    }),
    [Columns.answercount]: BaseColumnFactory({
      width: 90,
    }),
    [Columns.protocol]: BaseColumnFactory({
      getCellProps: () => ({ style: { textAlign: 'center' } }),
      width: 80,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
        enableLikeFilter: true,
      }),
      filter: 'selectFilter',
    }),
    [Columns.rcode]: StringsArrayColumnsFactory({
      // width: 80,
    }),
    [Columns.type]: StringsArrayColumnsFactory({
      // width: 80,
    }),
    [Columns.timestamp]: TimestampColumnFactory({ width: 160 }),
    [Columns.customer]: {
      accessor: 'customer',
      Header: 'Account',
      width: 160,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    },
    [Columns.answers]: AnswersColumnFactory({
      width: 250,
      disableFilters: true,
      disableSortBy: true,
      disableGroupBy: true,
    }),
    [Columns.answersClass]: BaseColumnFactory({
      accessor: ({ answers }) => [
        ...new Set(answers.map((answer) => answer.class)),
      ],
      Cell: UniversalCell(),
    }),
    [Columns.answersType]: BaseColumnFactory({
      accessor: ({ answers }) => [
        ...new Set(answers.map((answer) => answer.type)),
      ],
      Cell: UniversalCell(),
    }),
    [Columns.answersRdata]: BaseColumnFactory({
      accessor: ({ answers }) => [
        ...new Set(answers.map((answer) => answer.rdata)),
      ],
      Cell: UniversalCell(),
    }),
    [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
