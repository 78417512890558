import PropTypes from '+prop-types';
import { forwardRef, Fragment, useCallback, useMemo } from 'react';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import FolderPlusIcon from 'mdi-react/FolderPlusIcon';
import FolderRemoveIcon from 'mdi-react/FolderRemoveIcon';

import Menu, { useMenuActions } from '+components/Menu';

const HeaderContextMenu = (Component) => {
  const Render = forwardRef((props, ref) => {
    const { column, activeActionsColumnIndex, ...tail } = props;
    const { showMenu } = useMenuActions();

    const menuItems = useMemo(() => {
      const canBeHide = !(column.disableHide && column.isGrouped);

      const canResetSize = !column.disableResizing && column.isResized;
      const canResetFilter =
        !tail.disableFilters && column.canFilter && !!column.filterValue;
      const canResetAllSizes = column.tableHasResized;
      const canResetAllFilters = !tail.disableFilters && column.tableHasFilters;

      const showSeparator =
        (canBeHide || column.canGroupBy || column.canBeDuplicated) &&
        (canResetSize ||
          canResetFilter ||
          canResetAllSizes ||
          canResetAllFilters);

      return [
        column.canGroupBy && {
          id: 'groupBy',
          icon: column.isGrouped ? (
            <FolderRemoveIcon size={16} />
          ) : (
            <FolderPlusIcon size={16} />
          ),
          label: column.isGrouped ? 'Ungroup' : 'Group by',
          onClick: column.toggleGroupBy,
        },
        column.canBeDuplicated && {
          id: 'duplicate',
          icon: <ContentCopyIcon size={16} />,
          label: 'Duplicate',
          onClick: column.duplicateColumn,
        },
        canBeHide && {
          id: 'hide',
          icon: <EyeOffIcon size={16} />,
          label: 'Hide',
          onClick: () => {
            column.toggleHidden();
            if (column.isDuplicate) {
              column.removeDuplicate();
            }
          },
        },
        showSeparator && {
          id: 'separator',
          separator: true,
        },
        canResetSize && {
          id: 'resetSize',
          label: 'Reset size',
          onClick: column.resetSize,
        },
        canResetFilter && {
          id: 'resetFilter',
          label: 'Reset filter',
          onClick: () => column.setFilter(),
        },
        canResetAllSizes && {
          id: 'resetAllSizes',
          label: 'Reset all sizes',
          onClick: column.resetAllSizes,
        },
        canResetAllFilters && {
          id: 'resetAllFilters',
          label: 'Reset all filters',
          onClick: column.resetAllFilters,
        },
      ].filter(Boolean);
    }, [
      column.disableHide,
      column.toggleHidden,
      column.removeDuplicate,
      column.canGroupBy,
      column.isGrouped,
      column.isDuplicate,
      column.canBeDuplicated,
      column.toggleGroupBy,
      column.duplicate,
      column.disableResizing,
      column.canFilter,
      !!column.filterValue,
      column.tableHasFilters,
      column.tableHasResized,
      column.isResized,
      tail.disableFilters,
    ]);

    const menu = useMemo(
      () => (
        <Fragment>
          {menuItems.map((item) =>
            item.separator ? (
              <Menu.Separator key={item.id} />
            ) : (
              <Menu.Item key={item.id} onClick={item.onClick}>
                {item.icon}
                {item.label}
              </Menu.Item>
            ),
          )}
        </Fragment>
      ),
      [menuItems],
    );

    const onContextMenu = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        showMenu(event, { children: menu });
      },
      [menu],
    );

    const isDisabled =
      column.disableContextMenu ||
      activeActionsColumnIndex !== null ||
      !menuItems.length;

    return (
      <Component
        {...tail}
        ref={ref}
        onContextMenu={isDisabled ? null : onContextMenu}
      />
    );
  });

  Render.propTypes = {
    column: PropTypes.shape().isRequired,
    activeActionsColumnIndex: PropTypes.number,
  };

  Render.defaultProps = {
    activeActionsColumnIndex: null,
  };

  return Render;
};

export default HeaderContextMenu;
