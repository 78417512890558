import { useCallback, useEffect, useState } from 'react';

import isFunction from 'lodash.isfunction';
import Papa from 'papaparse';

import { UnixTimestampFields } from '@/models/UnixTimestampFields';

import { downloadFile } from '+components/charts/common/utils';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import * as toast from '+components/toast';
import useExportingFilename from '+hooks/useExportingFilename';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { flattenObject, flattenObjectPrefixInArray } from '+utils';
import dayjs from '+utils/dayjs';
import makeArr from '+utils/makeArr';

const isObject = (item) =>
  item != null && typeof item === 'object' && !Array.isArray(item);

const unwind = (item) => {
  let arrays = Object.entries(item).filter(
    ([, value]) => Array.isArray(value) && value.find(isObject),
  );

  if (arrays.length < 1) {
    return item;
  }

  arrays.forEach(([key]) => {
    delete item[key];
  });

  arrays = arrays
    .map(([, value]) => value.flatMap((node) => unwind(node)))
    .sort((a, b) => b.length - a.length);

  const [first, ...rest] = arrays;

  return first.map((node, index) => ({
    ...item,
    ...node,
    ...rest.reduce(
      (acc, array) => ({
        ...acc,
        ...(array[index] ? array[index] : {}),
      }),
      {},
    ),
  }));
};

const flatten = (row) => unwind(flattenObjectPrefixInArray(row));

const formatExportKey = (key, { tzName }) =>
  (UnixTimestampFields.includes(key)
    ? `${key} (${tzName})`
    : key
  ).toLowerCase();

const formatExportValue = (key, value) => {
  if (UnixTimestampFields.includes(key)) {
    return timestampFormatter(value);
  }

  const reg = /(src|dst)(portnames|ipnames)/i;
  if (reg.test(key)) {
    return Array.isArray(value) ? value.map((item) => item.join(';')) : value;
  }

  if (isObject(value) || Array.isArray(value)) {
    return JSON.stringify(value);
  }

  if (typeof value === 'string' && value.includes(',')) {
    return `"${value}"`;
  }

  return value;
};

export const ExportTypes = {
  csv: 'csv',
  csvCurrentView: 'csvCurrentView',
  csvFlattened: 'csvFlattened',
  csvFlattenedCurrentView: 'csvFlattenedCurrentView',
};

const exportTypes = Object.values(ExportTypes);

const noDataMessage = 'There are no records to export.';

/**
 * @param {object} props
 * @param {string} props.id
 * @param {string} [props.title]
 * @param {Array} props.allColumns
 * @param {Array} props.hiddenColumns
 * @param {Array} props.filteredRows
 * @param {function} [props.onGetExportData]
 * @returns {{exportData: (function(*): void), isExporting: boolean}}
 */
export const useExport = (props) => {
  const {
    title,
    id,
    allColumns,
    hiddenColumns,
    filteredRows,
    onGetExportData,
  } = props;

  const [exportType, setExportType] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  useLoadingIndicator(isExporting);

  const exportingFilename = useExportingFilename(title || id);

  const exporter = useCallback(
    async (type) => {
      if (!exportTypes.includes(type)) {
        return;
      }

      const tzName = dayjs().format('z');
      let exportingColumns;
      let exportingHeaders;
      let exportingData;

      if (
        [
          ExportTypes.csvCurrentView,
          ExportTypes.csvFlattenedCurrentView,
        ].includes(type)
      ) {
        // User exporting current view
        exportingColumns = allColumns.reduce((acc, col) => {
          if (col.disableExport || hiddenColumns?.includes(col.id)) {
            return acc;
          }
          const accessor =
            col.realAccessor ||
            (typeof col.accessor === 'string' ? col.accessor : col.id);
          const arr = makeArr(accessor).flat();
          arr.forEach((key) => {
            if (!key) {
              return;
            }
            const formattedKey = formatExportKey(key, { tzName });
            acc[formattedKey] = formattedKey;
          });
          return acc;
        }, {});

        if (!Object.keys(exportingColumns).length) {
          // User hided all columns and trying to export current view
          // Then export empty csv
          toast.info(noDataMessage);
          return;
        }
      }

      const data = filteredRows.map((row) => row.original);

      exportingData = isFunction(onGetExportData)
        ? await onGetExportData(
            type,
            data,
            Object.values(exportingColumns || {}).map(
              (key) => key.split(' ')[0],
            ),
          )
        : data;

      if (!exportingData.length) {
        toast.info(noDataMessage);
        return;
      }

      if (
        [
          ExportTypes.csvFlattened,
          ExportTypes.csvFlattenedCurrentView,
        ].includes(type)
      ) {
        exportingData = exportingData.map((row) => flatten(row)).flat();
      } else {
        exportingData = exportingData.map((row) => flattenObject(row));
      }

      exportingData = exportingData.map((row) => {
        return Object.entries(row).reduce((acc, [key, value]) => {
          const formattedKey = formatExportKey(key, { tzName });
          acc[formattedKey] = formatExportValue(key, value);
          return acc;
        }, {});
      });

      const exportableContent = Papa.unparse(
        exportingHeaders
          ? {
              fields: exportingHeaders,
              data: exportingData,
            }
          : exportingData,
        exportingColumns
          ? { columns: Object.keys(exportingColumns) }
          : undefined,
      );
      downloadFile(exportableContent, `${exportingFilename}.csv`);
    },
    [
      exportingFilename,
      filteredRows,
      allColumns,
      hiddenColumns,
      onGetExportData,
    ],
  );

  const exportData = useCallback((type) => {
    setExportType(type);
    setIsExporting(true);
  }, []);

  useEffect(() => {
    if (!isExporting) {
      return undefined;
    }

    const timer = setTimeout(() => {
      exporter(exportType)
        .finally(() => {
          setIsExporting(false);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('Exporter', err);
        });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [isExporting, exporter, exportType]);

  return {
    exportData,
    isExporting,
  };
};
