import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useBreadcrumbEffect } from './BreadProvider';

/**
 * Add this component to a page to automatically add a link to Breadcrumbs,
 * while this Breadcrumb is mounted.
 */
const Breadcrumb = (props) => {
  const { pathname, title } = props;

  const { key, pathname: meta } = useLocation();

  const prepared = {
    key,
    pathname,
    title,
    meta,
  };

  const data = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(prepared).filter(([, value]) => value !== undefined),
      ),
    Object.values(prepared),
  );

  useBreadcrumbEffect(data);

  return null;
};

Breadcrumb.propTypes = {
  /* router path */
  pathname: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Breadcrumb.defaultProps = {
  pathname: undefined,
  title: undefined,
};

export default Breadcrumb;
