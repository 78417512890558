import { TimeDuration } from '@/models/TimePeriods';

import { intervalToMilliseconds } from '+utils/intervalToMilleseconds';

const rand = (min, max) => min + Math.random() * (max - min);

const getSeriesInterval = (series) => {
  if (!series?.length) {
    return null;
  }
  return series[0].interval;
};

const getSeriesLastBucketTime = (series) => {
  if (!series?.length || !series[0].data?.length) {
    return null;
  }
  const { data } = series[0];
  const lastBucket = data[data.length - 1];
  return lastBucket[0];
};

/**
 * Function to calculate refresh interval for REST API stats requests.
 *
 * Algorithm for TS requests:
 * series (response) interval + last bucket timestamp + rand(4, 8)seconds
 *
 * Algorithm for AGG requests:
 * series (response) interval + rand(4, 8)seconds
 *
 * That rand() bit at the end does two things:
 * 1. We don't want to make the request exactly at the top of the minute, because that's when ElasticSearch is doing the rollups, which takes > 0 time.
 * 2. We want to randomize slightly so that ALL of the API requests don't fire off at exactly the same time.
 */
const calcRefreshInterval = ({
  seriesInterval = '',
  seriesLastBucketTime = 0,
  minRefreshInterval = TimeDuration.minute,
  maxRefreshInterval = 8 * TimeDuration.hour,
  randMin = 4000,
  randMax = 8000,
}) => {
  let refreshInterval;

  const seriesIntervalInMs = intervalToMilliseconds(seriesInterval);
  if (
    !seriesIntervalInMs ||
    !seriesLastBucketTime ||
    seriesIntervalInMs <= minRefreshInterval
  ) {
    refreshInterval = Math.max(seriesIntervalInMs, minRefreshInterval);
  } else {
    const now = Date.now();
    refreshInterval = seriesLastBucketTime + seriesIntervalInMs - now;
    refreshInterval =
      refreshInterval <= 0 ? seriesIntervalInMs : refreshInterval;
  }

  return Math.min(refreshInterval + rand(randMin, randMax), maxRefreshInterval);
};

export { getSeriesInterval, getSeriesLastBucketTime, calcRefreshInterval };

export default calcRefreshInterval;
