import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

import LeftIcon from 'mdi-react/ChevronLeftIcon';
import RightIcon from 'mdi-react/ChevronRightIcon';

import { propsSelectors as mapTheme } from '+theme/slices/map';

const Panel = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$open ? 'column' : 'row')};
  background: ${mapTheme.panelBackground};
  overflow: hidden;
  position: relative;
  transition:
    max-height 0.3s,
    lex-basis 0.5s;

  border-top: 1px solid ${mapTheme.panelBorderColor};

  min-height: 150px;
  max-height: ${({ $height }) => ($height ? '60vh' : '150px')};
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}px;
    `};

  flex-basis: 15%;
  &.closed {
    transition:
      max-height 0.3s,
      flex-basis 0.5s;
    flex-grow: 0;
    flex-basis: 2em;
    max-height: 150px;
  }
  pointer-events: all;
`;

const Handle = styled.div`
  position: absolute;
  z-index: 3;
  width: 3px;
  top: 0;
  bottom: 0;
  background: transparent;
  cursor: ew-resize;

  ${Panel}.closed & {
    display: none;
  }
`;

const TopHandle = styled(Handle)`
  width: auto;
  bottom: initial;
  left: 0;
  right: 0;
  height: 3px;
  cursor: n-resize;
`;

const Header = styled((props) => {
  return (
    <button type="button" className={props.className} onClick={props.onClick}>
      {props.open && <LeftIcon />}
      {!props.open && <RightIcon />}
      <div>{props.children}</div>
    </button>
  );
})`
  color: inherit;
  outline: 0;
  border: 0;
  margin: 0;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 2px;
  flex-direction: ${(props) => (props.open ? 'row' : 'column')};
  position: relative;

  background: ${mapTheme.panelHeaderBackground};

  & > div {
    white-space: nowrap;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform 0.5s;
    transform-origin: left top;
    transform: translate(${(props) => (props.open ? '30px, 0' : '26px, 30px')})
      rotate(${(props) => (props.open ? 0 : 90)}deg);
  }

  &:hover {
    background: ${mapTheme.panelHeaderHoverBackground};
  }
`;

const Container = styled.div`
  overflow: hidden;
  overflow-y: ${(props) => (props.$open ? 'auto' : null)};
  display: flex;
  flex-direction: column;
  min-width: 0;
  transition: max-width 0.5s ease-in-out;
  max-width: ${(props) => (props.$open ? '100%' : 0)};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    background-clip: padding-box;
    border-radius: 8px;
    border: 3px solid transparent;
    transition: background-color 0.8s ease-in-out;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.scrollBackgroundColor};
  }
`;

const NoSelect = styled.div`
  user-select: none;
`;

const ExpandedPanel = (props) => {
  const { title, children, className, open, onDrag } = props;
  const [opened, setOpened] = useState(open);
  const [container, setContainer] = useState(open);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(null);

  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setOpened(!opened);
    },
    [opened],
  );

  const cls = useMemo(
    () => [className, opened ? '' : 'closed'].join(' '),
    [opened, className],
  );

  useEffect(() => {
    setOpened(open);
  }, [open]);

  useEffect(() => {
    const update = () => {
      setContainer(false);
    };

    if (opened) {
      setContainer(opened);
    } else {
      containerRef.current.addEventListener('transitionend', update, true);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('transitionend', update, true);
      }
    };
  }, [opened, containerRef.current]);

  const onMouseDown = useCallback((event) => {
    const { y: startTop, height: startHeight } =
      event.target.parentNode.getBoundingClientRect();

    if (onDrag) {
      onDrag(true);
    }

    const onMouseMove = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const newHeight = startHeight + (startTop - e.clientY);
      if (newHeight > 0) {
        requestAnimationFrame(() => {
          setHeight(newHeight);
        });
      }
    };

    const onMouseUp = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (onDrag) {
        onDrag(false);
      }

      document.body.classList.remove(`${NoSelect}`);
      document.removeEventListener('mousemove', onMouseMove, true);
      document.removeEventListener('mouseup', onMouseUp, true);
    };

    document.body.classList.add(`${NoSelect}`);
    document.addEventListener('mousemove', onMouseMove, true);
    document.addEventListener('mouseup', onMouseUp, true);
  }, []);

  return (
    <Panel className={cls} $open={opened} $height={height}>
      <TopHandle onMouseDown={onMouseDown} />
      <Header open={opened} onClick={onClick}>
        {title}
      </Header>
      <Container $open={opened} ref={containerRef}>
        {container && children}
      </Container>
    </Panel>
  );
};

ExpandedPanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.children,
  className: PropTypes.string,
  open: PropTypes.bool,
  onDrag: PropTypes.func,
};

ExpandedPanel.defaultProps = {
  title: null,
  children: null,
  className: null,
  open: false,
  onDrag: null,
};

export default memo(ExpandedPanel);
