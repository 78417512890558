import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import set from 'lodash.set';

import { ContextIntegrations } from '@/models/integrations/ContextIntegrations';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as contextIntegrationsActions,
  selectors as contextIntegrationsSelectors,
} from '@/redux/api/integrations/context';

import { usePageTabs } from '+components/PageTabs';

import cleanUpFields from '../shared/cleanUpFieldsBeforeSubmit';
import NoData from '../shared/NoData';
import useContextIntegrationModels from '../shared/useContextIntegrationModels';
import Form from './Form';

const extractManifestInitialValues = (fields) => {
  let initialValues = {};

  if (!fields) {
    return initialValues;
  }

  Object.keys(fields).forEach((key) => {
    const field = fields[key];

    if (field?.default) {
      set(initialValues, key, field.default);
    }

    if (field?.fields) {
      initialValues = {
        ...initialValues,
        ...extractManifestInitialValues(field.fields),
      };
    }
  });

  return initialValues;
};

const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();

  const { adapter } = useParams();
  const error = useSelector(contextIntegrationsSelectors.getError);
  const isFetching = useSelector(contextIntegrationsSelectors.isFetching);
  const [creationValues, setCreationValues] = useState({});
  const createdIntegration = useSelector(
    contextIntegrationsSelectors.getContextIntegrationByName(
      creationValues?.name,
    ),
  );
  const [creatingIntegration, setCreatingIntegration] = useState(false);
  const [runningIntegration, setRunningIntegration] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [contextIntegrationModels, isIntegrationTypeHardcoded] =
    useContextIntegrationModels();

  const model = useMemo(
    () => contextIntegrationModels?.[adapter] ?? undefined,
    [adapter, contextIntegrationModels],
  );

  const manifestInitialValues = useMemo(
    () => extractManifestInitialValues(model?.manifest?.fields),
    [model?.manifest?.fields],
  );

  // true if integration is defined in portal code, false if it's a dynamic/manifest integration.
  const isHardcodedIntegration = useMemo(
    () => isIntegrationTypeHardcoded(model?.name),
    [model?.name, ContextIntegrations],
  );

  const initialValues = useMemo(
    () => ({
      ...manifestInitialValues,
      type: adapter,
      updateinterval: model?.minInterval ?? 3600,
      enabled: true,
    }),
    [model?.minInterval, manifestInitialValues],
  );

  const onSubmit = useCallback(
    (values) => {
      setCreationValues(values);
      const data = cleanUpFields(values, model?.manifest);
      setCreatingIntegration(activePageTab?.id);
      dispatch(
        contextIntegrationsActions.createContextIntegration({
          ...data,
          isDynamic: !isHardcodedIntegration,
          silent: data.enabled && isHardcodedIntegration,
        }),
      );
    },
    [isHardcodedIntegration, activePageTab?.id, model?.manifest],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.integrationsContext}`);
  }, []);

  useEffect(() => {
    if (!creatingIntegration || isFetching) {
      return;
    }

    if (creatingIntegration !== activePageTab?.id) {
      return;
    }

    // if we're done creating the integration, then try to run it
    if (!runningIntegration && createdIntegration?.id) {
      if (error) {
        // reset everything and stay on add mode
        setCreatingIntegration(false);
        return;
      }

      // TODO: for now, we don't run for manifest integrations, this will be removed once integration verification is developed, see SQC-102
      // if the integration isn't enabled, don't need to run
      if (!createdIntegration?.enabled || !isHardcodedIntegration) {
        onCancel();
        return;
      }

      setRunningIntegration(true);
      dispatch(
        contextIntegrationsActions.runContextIntegration({
          ...createdIntegration,
          afterCreateMode: true,
        }),
      );
      return;
    }

    // if we're done creating and running the integration
    if (runningIntegration && createdIntegration?.id) {
      setCreatingIntegration(false);
      setRunningIntegration(false);
      if (!error) {
        onCancel();
      } else {
        navigate(
          `${RoutePaths.integrationsContext}/edit/${createdIntegration?.id}`,
        );
      }
    }
  }, [
    creatingIntegration,
    runningIntegration,
    isFetching,
    createdIntegration,
    error,
    activePageTab?.id,
    isHardcodedIntegration,
    onCancel,
  ]);

  return !model ? (
    <NoData>No Provider Found</NoData>
  ) : (
    <Form
      mode="add"
      model={model}
      initialValues={initialValues}
      isFetching={isFetching}
      onSubmit={onSubmit}
      onCancel={onCancel}
      confirmButtonText={
        // TODO: temporary, remove once manifest integration verification work is done see SQC-102
        formValues?.enabled && isHardcodedIntegration
          ? 'Create and Run'
          : 'Create'
      }
      deleteButtonHidden
      formValueChangeCallback={setFormValues}
    />
  );
};

export default Add;
