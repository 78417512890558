import { CustomType } from '@/models/CustomType';
import { isCardMetric } from '@/models/MetricSettings';
import { WidgetTypes } from '@/models/WidgetTypes';

import makeArr from '+utils/makeArr';

import NqlModeTypes from './NqlModeTypes';
import { defaultDateTime } from './propTypes';
import { widgets } from './widgets';

export const makePaletteBackground = (colors) => {
  const stops = colors.map((color, i) => {
    const stop1 = Math.round((i / colors.length) * 100);
    const stop2 = Math.round(((i + 1) / colors.length) * 100);
    return `${color} ${stop1}%,${color} ${stop2}%`;
  });
  return `linear-gradient(45deg,${stops})`;
};

export const dashboardParamsToUi = (params) => {
  const nql = makeArr(params.nql);
  const intersect =
    nql.length > 1 ? makeArr(params.intersect).filter(Boolean) : [];
  const useCustomDateTime = !params.useGlobalDateTime;

  return {
    ...params,
    nql,
    intersect,
    useCustomDateTime,
  };
};

export const dashboardUiToParams = (values) => {
  const isPeriodTypeCustom = values.dateTime?.periodType === CustomType;

  const normalizedValue = {
    ...values,
    useGlobalDateTime: !values.useCustomDateTime,
    dateTime: values.useCustomDateTime
      ? {
          periodType: values.dateTime.periodType,
          periodValue: isPeriodTypeCustom ? null : values.dateTime.periodValue,
          from: isPeriodTypeCustom ? values.dateTime.from : null,
          to: isPeriodTypeCustom ? values.dateTime.to : null,
        }
      : defaultDateTime,
  };
  delete normalizedValue.useCustomDateTime;

  const nql = values.nqlMode === NqlModeTypes.global ? [] : makeArr(values.nql);
  const intersect =
    nql.length > 1 ? makeArr(values.intersect).filter(Boolean) : [];
  return { ...normalizedValue, nql, intersect };
};

/** * Widgets ** */
export const widgetParamsToUi = (widget = {}) => {
  const series = makeArr(widget.series).map((item) => {
    const nql = makeArr(item.nql);
    const intersect =
      nql.length > 1 ? makeArr(item.intersect).filter(Boolean) : [];

    return {
      ...item,
      nql,
      intersect,
      ...(item.display?.type === WidgetTypes.Heatmap
        ? { fieldX: item.fields?.[0], fieldY: item.fields?.[1] }
        : {}),
    };
  });

  return {
    ...widget,
    series,
  };
};

export const widgetUiToParams = (widget = {}) => {
  const series = makeArr(widget.series).map((item) => {
    const { fields, fieldX, fieldY, ...rest } = item;

    const fixedFields =
      item.display?.type === WidgetTypes.Heatmap
        ? [fieldX, fieldY].filter(Boolean)
        : fields;
    const nql = makeArr(item.nql);
    const intersect =
      nql.length > 1 ? makeArr(item.intersect).filter(Boolean) : [];

    return {
      ...rest,
      fields: fixedFields,
      nql,
      intersect,
    };
  });

  return {
    ...widget,
    series,
  };
};

export const getWidgetTitle = (widget) => {
  if (widget.title) {
    return widget.title;
  }
  if (widget.series?.length > 1) {
    return 'multiple charts widget';
  }
  const series0 = widget.series?.[0];
  const label = widgets[series0?.display?.type]?.label || 'unknown chart';
  return `${label} widget (${series0?.context} context)`;
};

// Note: in this function we are using "fields" (with "s")
export const isSeverity = (widgetSeries) =>
  !isCardMetric(widgetSeries?.metric) &&
  widgetSeries?.fields?.length === 1 &&
  widgetSeries.fields[0] === 'severity';

export const getIsPaletteColorPickerEnabled = (widgetSeries) => {
  const widgetMeta = widgets[widgetSeries?.display?.type] || {};
  if (isSeverity(widgetSeries)) {
    return false;
  }
  return (
    widgetMeta.isPaletteColorPickerEnabled ||
    (widgetSeries?.display?.type === WidgetTypes.Value &&
      widgetSeries?.display?.showTrend)
  );
};
