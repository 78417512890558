import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

const useCustomerStatuses = () => {
  const dispatch = useDispatch();
  const customerStatuses = useSelector(customerSelectors.getCustomerStatuses);
  useEffect(() => {
    if (!Object.keys(customerStatuses).length) {
      dispatch(customerActions.requestCustomerStatuses());
    }
  }, [Object.keys(customerStatuses).length]);
  return customerStatuses;
};

export default useCustomerStatuses;
