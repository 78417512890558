export const getId = ({ id } = {}) => id;
export const getX = ({ fx, x }) => fx ?? x;
export const getY = ({ fy, y }) => fy ?? y;
export const getX0orX = ({ x0, x }) => x0 ?? x;
export const getY0orY = ({ y0, y }) => y0 ?? y;
export const getText = ({ name }) => name;
export const getLinkId = ({ source, target }) =>
  `${getId(source)}-${getId(target)}`;
export const getSourceX = ({ source }) => getX(source);
export const getSourceY = ({ source }) => getY(source);
export const getTargetX = ({ target }) => getX(target);
export const getTargetY = ({ target }) => getY(target);
export const getParticleScale = (scale) => scale * 0.125;
export const getLabelContext = ({ labelContext }) => labelContext;
export const getLabel = ({ label }) => label;
export const getLabelCount = ({ labelCount }) =>
  labelCount ? `+${labelCount}` : '';
export const getShowLabel = ({ showLabel, label }) => !!(showLabel && label);
