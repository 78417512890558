import { call, put } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { EventSeverity } from '@/models/EventSeverity';
import { isCardMetric } from '@/models/MetricSettings';

import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

// Note: in this function we are using "field" (without "s")
const isSeverity = (item) =>
  !isCardMetric(item.metric) &&
  item.field?.length === 1 &&
  item.field[0] === 'severity';

const isSeverityRequest = (requestParams) =>
  !!requestParams?.series?.some(isSeverity);

const fixAggDataForSizeZero = (chart) => (item) => {
  if (item?.rate != null && item?.data?.length < 1) {
    item.data =
      chart === 'pie'
        ? [{ name: item.label, y: item.rate }]
        : [[item.label, item.rate]];
  }

  return item;
};

// TODO: Move severity color index mapping to widgets series index formatter
// @see: src/shared/components/charts/common/formatters.js -> severitySeriesColorIndexFormatter
const mapTsSeverityColorIndex = (el) => {
  const severity = EventSeverity[el.name];
  return {
    ...el,
    ...(severity ? { colorIndex: severity.colorIndex } : {}),
  };
};

// TODO: Move severity color index mapping to widgets series index formatter
// @see: src/shared/components/charts/common/formatters.js -> severitySeriesColorIndexFormatter
const mapBarSeverityColorIndex = (el) => {
  const data = el.data.map((item) => {
    const severity = EventSeverity[item[0]];
    return severity ? [...item, severity.colorIndex] : item;
  });
  return {
    ...el,
    data,
  };
};

// TODO: Move severity color index mapping to widgets series index formatter
// @see: src/shared/components/charts/common/formatters.js -> severitySeriesColorIndexFormatter
const mapPieSeverityColorIndex = (el) => {
  const data = el.data.map((item) => {
    const severity = EventSeverity[item.name];
    return severity ? { ...item, colorIndex: severity.colorIndex } : item;
  });
  return {
    ...el,
    data,
  };
};

const defaultParams = {
  start: -3600,
  end: 0,
  format: 'highcharts',
};

const initialState = {
  isFetching: false,
  error: '',
  metrics: [],
  fields: [],
  agg: {},
  isFetchingSeries: {},
  errorSeries: {},
};

let api;
const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const apiPath = '/stats/alert';

const startFetchingSeries = (state, seriesId) => {
  state.isFetchingSeries[seriesId] = true;
  delete state.errorSeries[seriesId];
};
const stopFetchingSeries = (state, seriesId) => {
  state.isFetchingSeries[seriesId] = false;
};

const slice = createSlice({
  name: 'statsEvent',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchMetrics: startFetching,
    fetchFields: startFetching,
    timeseriesRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },
    aggregateRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },
    heatmapRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },

    fetchMetricsSuccess: (state, { payload: data }) => {
      stopFetching(state);
      state.metrics = data;
    },

    fetchFieldsSuccess: (state, { payload: data }) => {
      stopFetching(state);
      state.fields = data;
    },

    timeseriesSuccess: (state, { payload: { data, seriesId, severity } }) => {
      stopFetching(state);
      stopFetchingSeries(state, seriesId);
      state[seriesId] =
        severity && Array.isArray(data)
          ? data.map(mapTsSeverityColorIndex)
          : data;
    },

    aggregateSuccess: (
      state,
      { payload: { data, seriesId, severity, chart } },
    ) => {
      stopFetching(state);
      stopFetchingSeries(state, seriesId);
      if (chart && Array.isArray(data)) {
        data?.forEach(fixAggDataForSizeZero(chart));
      }

      state[seriesId] = data;

      if (severity && Array.isArray(data)) {
        state[seriesId] =
          chart === 'bar'
            ? data.map(mapBarSeverityColorIndex)
            : data.map(mapPieSeverityColorIndex);
      }
    },

    seriesClear: (state, { payload: { seriesId, seriesIds } }) => {
      if (seriesId) {
        delete state[seriesId];
      }

      if (Array.isArray(seriesIds)) {
        seriesIds.forEach((key) => {
          delete state[key];
        });
      }
    },

    failSeries: (state, { payload: { error, seriesId } }) => {
      stopFetchingSeries(state, seriesId);
      state.errorSeries[seriesId] = error.message;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchMetrics]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/metrics`);
          yield put(actions.fetchMetricsSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error receiving list of metrics',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.fetchFields]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/fields`);
          yield put(actions.fetchFieldsSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error receiving list of fields',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.timeseriesRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();

        const mergedParams = {
          ...defaultParams,
          ...params,
        };

        try {
          const { data } = yield call(api.post, `${apiPath}/ts`, mergedParams);
          yield put(
            actions.timeseriesSuccess({
              data: data.data,
              seriesId,
              severity: isSeverityRequest(mergedParams),
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving event data',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.aggregateRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();

        const mergedParams = {
          ...defaultParams,
          ...params,
        };

        try {
          const { data } = yield call(api.post, `${apiPath}/agg`, mergedParams);
          yield put(
            actions.aggregateSuccess({
              data: data.data,
              seriesId,
              severity: isSeverityRequest(mergedParams),
              chart: mergedParams.chart,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving event data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.heatmapRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();

        const mergedParams = {
          ...defaultParams,
          ...params,
        };

        try {
          const { data } = yield call(
            api.post,
            `${apiPath}/heatmap`,
            mergedParams,
          );
          const severity =
            !!mergedParams.series?.[0]?.field?.includes('severity');
          yield put(
            actions.aggregateSuccess({ data: data.data, seriesId, severity }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving event data',
              details: error.message,
            }),
          );
        }
      },
    },
  }),
});

export const selectors = {
  ...slice.selectors,

  isFetching: createSelector([slice.selector], (stats) => stats.isFetching),

  getMetrics: createSelector([slice.selector], (stats) => stats.metrics),

  getFields: createSelector([slice.selector], (stats) => stats.fields),

  seriesSelector: (seriesId) =>
    createSelector([slice.selector], (state) => state[seriesId]),

  isFetchingSeries: (seriesId) =>
    createSelector(
      [slice.selector],
      (stats) => stats.isFetchingSeries[seriesId],
    ),

  errorSeries: (seriesId) =>
    createSelector([slice.selector], (stats) => stats.errorSeries[seriesId]),
};

// For convenience
export const { actions } = slice;

export default slice;
