/* eslint-disable no-console */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { isDevelopment } from '+utils/constants';
import { logRocket } from '+utils/logRocket';

import { rootSaga } from './modules';
import { rootReducer } from './reducers';

const sagaMiddleware = createSagaMiddleware();

const middleware = (gdm) =>
  gdm({
    serializableCheck: false,
  }).concat([sagaMiddleware, logRocket.middleware()]);

const configure = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware,
  });

  if (isDevelopment) {
    window.store = store;
  }

  sagaMiddleware.run(rootSaga);
  return store;
};

export const store = configure();
export default configure;
