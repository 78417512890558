import { withAuthenticationRequired } from 'react-oidc-context';

import authClient from '@/middleware/authClient';

import loader from '+utils/loader';

export const withProtectedArea = (Component) =>
  withAuthenticationRequired(Component, {
    onBeforeSignin: () => {
      loader.setMessage('Redirect to IAM service... 60%').show();
      authClient.beforeLogin();
    },
  });

export default withProtectedArea;
