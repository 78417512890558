import PropTypes from '+prop-types';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import isEqual from 'lodash.isequal';

import ScrollBarWrapper from './ScrollBarWrapper';

const ScrollBar = forwardRef((props, ref) => {
  const { onScroll, showShadows, ...tail } = props;

  const [top, setTop] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [scroll, setScroll] = useState();
  const lastState = useRef();

  const onRef = useCallback(
    (scrollRef, ...args) => {
      setScroll(scrollRef);
      if (typeof ref === 'function') {
        ref(scrollRef, ...args);
      }
    },
    [ref],
  );

  const scrollHandle = useCallback(
    (item) => {
      const { limit = {}, offset = {} } = item || {};

      if (!showShadows) {
        return;
      }

      let _top = false;
      let _bottom = false;

      switch (true) {
        case !limit.y:
          break;
        case limit.y === offset.y:
          _top = true;
          break;
        case !offset.y:
          _bottom = true;
          break;
        default:
          _top = true;
          _bottom = true;
          break;
      }

      setTop(_top);
      setBottom(_bottom);
    },
    [showShadows],
  );

  const doScroll = useCallback(
    (...args) => {
      scrollHandle(...args);
      if (typeof onScroll === 'function') {
        onScroll(...args);
      }
    },
    [onScroll, scrollHandle],
  );

  useEffect(() => {
    scrollHandle(scroll);
  }, [scrollHandle, scroll]);

  useEffect(() => {
    if (!showShadows || !scroll) {
      return undefined;
    }

    let timer;

    const doRefresh = () => {
      scroll?.update();
      const next = {
        scrollTop: scroll?.scrollTop,
        limitY: scroll?.limit?.y,
        offsetY: scroll?.offset?.y,
      };

      if (!isEqual(lastState.current, next)) {
        lastState.current = next;
        scrollHandle(scroll);
      }

      timer = requestAnimationFrame(doRefresh);
    };

    timer = requestAnimationFrame(doRefresh);

    return () => {
      cancelAnimationFrame(timer);
    };
  }, [scroll, showShadows]);

  return (
    <ScrollBarWrapper
      {...tail}
      $topShadow={showShadows && top}
      $bottomShadow={showShadows && bottom}
      onScroll={doScroll}
      ref={onRef}
    />
  );
});

ScrollBar.propTypes = {
  showShadows: PropTypes.bool,
  onScroll: PropTypes.func,
};

ScrollBar.defaultProps = {
  showShadows: true,
  onScroll: null,
};

export default ScrollBar;
