import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import * as ContentDuplicateIcon from 'mdi-react/ContentDuplicateIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';

import { getColumns } from './components/columns';

const getTrackbySearchParams = (original) => {
  const trackbyKeys = original?.trackValues?.map((valObj) => valObj.trackKey);
  const trackbyValues = original?.trackValues?.map(
    (valObj) => valObj.trackValue,
  );

  return trackbyKeys.reduce(
    (accumulator, currentValue, index) =>
      `${accumulator}${currentValue}=${trackbyValues[index]}${
        index < trackbyKeys.length - 1 ? '&' : ''
      }`,
    '?',
  );
};

const ThresholdsOverrides = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { algorithmId } = useParams();

  const [deleteModal, setDeleteModal] = useState({ show: false });
  const [selected, setSelected] = useState([]);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const { isFetching, error } = useSelector(rulesSelectors.getState);

  const algorithm = useSelector(rulesSelectors.getAlgorithm(algorithmId));
  const permissions = usePermissions(
    PermissionModel.Resources.threat_model.value,
  );
  const configuredTriggers =
    useSelector(
      rulesSelectors.getConfiguredTriggersByAlgorithm(algorithm?.name),
    ) || undefined;

  const tableData = useMemo(
    () =>
      algorithm && configuredTriggers
        ? configuredTriggers.map((trigger) => {
            const data = { ...trigger };
            data.trackValues = data?.track
              ?.split(' ')
              .map((trackVal, index) => {
                const obj = {
                  trackKey: algorithm?.track_by?.[0]?.[index],
                  trackValue: trackVal,
                };
                return obj;
              });
            return data;
          })
        : [],
    [configuredTriggers, algorithm, algorithmId],
  );

  useEffect(() => {
    if (algorithmId && !algorithm && !isFetching && !error) {
      dispatch(rulesActions.fetchAlgorithm(algorithmId));
    }
  }, [algorithmId, algorithm, isFetching, error]);

  useEffect(() => {
    if (algorithm && !configuredTriggers && !isFetching && !error) {
      dispatch(rulesActions.fetchConfiguredTriggersByAlgorithm(algorithm.name));
    }
  }, [algorithm, configuredTriggers, isFetching, error]);

  const handleEdit = useCallback(
    (original) => () => {
      const trackbySearchParams = getTrackbySearchParams(original);
      navigate({
        pathname: `${RoutePaths.modelsDetection}/${algorithmId}/${RoutePaths.modelOverrides}/update`,
        search: `${trackbySearchParams}&mode=edit`,
      });
    },
    [algorithmId],
  );

  const handleAdd = useCallback(() => {
    navigate(`${pathname}/update`);
  }, [pathname]);

  const cxActionMenu = useCallback(
    (_, original) => {
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: handleEdit(original),
          disabled: !permissions?.update,
        },
        {
          icon: <ContentDuplicateIcon />,
          text: 'Create As New',
          onClick: () => {
            navigate({
              pathname: `${pathname}/update`,
              search: getTrackbySearchParams(original),
            });
          },
          disabled: !permissions?.create,
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          onClick: () =>
            setDeleteModal({
              show: true,
              algorithm: original.algorithm,
              track: original.track,
            }),
          disabled: !permissions?.delete,
        },
      ];

      return (
        <MenuColumnContextMenu
          title={original.appname}
          items={items}
          dataTracking="api-keys"
        />
      );
    },
    [permissions, handleEdit, pathname],
  );

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter((value) => value !== null);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const onConfirmBulkDelete = useCallback(() => {
    toggleBulkDeleteModal();
    if (!selected.length) {
      return;
    }
    const tracks = selected
      .map((index) => tableData?.[+index]?.track)
      .filter(Boolean);
    dispatch(
      rulesActions.bulkDeleteTriggers({ algorithm: algorithm.name, tracks }),
    );
  }, [selected]);

  const columns = useMemo(() => getColumns({ cxActionMenu }), [cxActionMenu]);

  useLoadingIndicator(isFetching);

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          disabled={!permissions?.update}
          onClick={handleAdd}
          testId="add-threshold-override-button"
        >
          Add Override
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={toggleBulkDeleteModal}
          disabled={!permissions?.delete || !selected.length}
          testId="delete-threshold-overrides-button"
        >
          Delete Selected
        </Button>
      </ActionsContainer>
      <Table
        data={tableData}
        columns={columns}
        onSelectedRowsChange={onSelectedRowsChange}
        autoResetSelectedRows
        testId="threshold-overrides-table"
      />
      {deleteModal.show && (
        <ConfirmModal
          item={deleteModal?.track || null}
          onConfirm={() => {
            dispatch(
              rulesActions.deleteTriggersByAlgorithmAndObject({
                algorithm: deleteModal.algorithm,
                object: deleteModal.track,
              }),
            );
          }}
          toggleOnConfirm
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          onToggle={() => setDeleteModal({ show: false })}
          isOpen
          testId="delete-threshold-override-modal"
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} threshold override${
            selected.length > 1 ? 's' : ''
          }`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
          testId="bulk-delete-threshold-overrides-modal"
        />
      )}
    </Fragment>
  );
};

export default ThresholdsOverrides;
