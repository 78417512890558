import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RoleModel from '@/models/Role';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { selectors as usersSelectors } from '@/redux/api/user';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import PhoneNumberField from '+components/form/PhoneNumberField';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import { validateEmail, validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';

const AddUserModal = (props) => {
  const { roles, onToggle, onSubmit, testId } = props;

  const dispatch = useDispatch();

  const samlProvider = useSelector(customerSelectors.getSamlProvider);
  const profile = useSelector(profileSelectors.getProfile);
  const isUsersFetching = useSelector(usersSelectors.isFetching);

  const initialValues = useMemo(
    () => ({
      roles: [RoleModel.Roles.app_admin],
      app_metadata: {
        shortname: profile.app_metadata?.shortname,
      },
      useResellerSso: !!samlProvider?.resellerSso,
    }),
    [profile.app_metadata?.shortname, samlProvider?.resellerSso],
  );

  const rolesOptions = useMemo(
    () => Object.values(roles || {}).sort(RoleModel.sortRoles),
    [roles],
  );

  useEffect(() => {
    if (!samlProvider?.alias) {
      dispatch(customerActions.requestSamlProvider());
    }
  }, [samlProvider?.alias]);

  return (
    <FormModal
      initialValues={initialValues}
      mode="add"
      item="user"
      loading={isUsersFetching}
      disabled={isUsersFetching}
      onToggle={onToggle}
      onSubmit={onSubmit}
      isOpen
      testId={testId}
    >
      <Field
        name="roles[0]"
        component={SelectField}
        label="Role"
        options={rolesOptions}
        groupBy={(item) => (item.system ? 'System' : 'Custom')}
        parse={normalizeSelectValue}
        validate={validateRequired}
        required
      />

      <Field
        name="email"
        component={TextField}
        type="email"
        label="Email"
        validate={[validateRequired, validateEmail]}
        autoComplete="new-password"
        required
      />

      <Field
        name="given_name"
        component={TextField}
        label="Given Name"
        validate={validateRequired}
        required
      />

      <Field
        name="family_name"
        component={TextField}
        label="Family Name"
        validate={validateRequired}
        required
      />

      <Field
        name="user_metadata.phone_number"
        component={PhoneNumberField}
        type="text"
        label="Phone Number"
      />

      <Field
        name="user_metadata.job_title"
        label="Job Title"
        component={TextField}
        type="text"
      />

      {!!samlProvider?.resellerSso && (
        <Field
          name="useResellerSso"
          label=" "
          component={ToggleField}
          type="checkbox"
          checkedLabel="Use SSO Integration"
          helperText={
            samlProvider?.alias
              ? `This user will authenticate against ${samlProvider?.alias} SSO configuration`
              : ''
          }
        />
      )}
    </FormModal>
  );
};

AddUserModal.propTypes = {
  roles: PropTypes.shape().isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

AddUserModal.defaultProps = {
  testId: undefined,
};

export default AddUserModal;
