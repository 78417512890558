import isObject from 'lodash.isobject';
import isPlainObject from 'lodash.isplainobject';

const defaultAlphabet =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

/**
 * Make a random ID, similar to a UUID, except with variable length and less complex
 * @param {number} [length] length of id
 * @param {string} [alphabet] string of characters to choose from
 */
export const makeId = (length = 10, alphabet = defaultAlphabet) =>
  Array.from(
    { length },
    () => alphabet[Math.floor(Math.random() * alphabet.length)],
  ).join('');

/**
 * Compare two values to see if they are shallowly equal to each other.
 * @param {any} one value 1
 * @param {any} two value 2
 */
export const shallowEqual = (one, two) => {
  if (one === two) {
    return true;
  }

  if (Array.isArray(one) && Array.isArray(two) && one.length === two.length) {
    for (let i = 0; i < one.length; i += 1) {
      if (one[i] !== two[i]) {
        return false;
      }
    }

    return true;
  }

  if (isObject(one) && isObject(two)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in one) {
      if (!(key in two) || one[key] !== two[key]) {
        return false;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in two) {
      if (!(key in one) || one[key] !== two[key]) {
        return false;
      }
    }

    return true;
  }

  return false;
};

/**
 * Compare two objects and return the intersection of keys whose values are
 * different between the objects
 * @param {object} one
 * @param {object} two
 *
 * @return {Set<string>}
 */
export const shallowDiff = (one, two) => {
  const diffKeys = new Set();

  const k1 = Object.keys(one);
  const k2 = Object.keys(two);
  const compareAddSet = (k) => one[k] !== two[k] && diffKeys.add(k);
  k1.forEach(compareAddSet);
  k2.forEach(compareAddSet);

  return diffKeys;
};

/**
 * Compare sets for equality of length and membership
 * @param {Set} a
 * @param {Set} b
 * @return {boolean}
 */
export const eqSets = (a, b) =>
  a.size === b.size && [...a].every((value) => b.has(value));

/**
 * Take a function and return a function that only invokes when its arguments
 * change values based on a shallow compare between previous args and new args.
 * @param {Function} fn function to transform
 * @return {Function}
 */
export const makeArgObserver = (fn) => {
  let argsCache;
  return (...args) => {
    if (!shallowEqual(args, argsCache)) {
      argsCache = args;
      return fn(...args);
    }

    return undefined;
  };
};

/**
 * Map a tree with plain object nodes
 * @param {Object} objectTree tree root object node
 * @param {function(object): object} mapFn a function to transform leaves which are not objects
 * @return {Object}
 */
export const objectTreeMap = (objectTree, mapFn) => {
  const helper = (node) => {
    if (isPlainObject(node)) {
      const mappedNode = {};
      const keys = Object.keys(node);
      for (let i = 0; i < keys.length; i += 1) {
        mappedNode[keys[i]] = helper(node[keys[i]]);
      }

      return mappedNode;
    }

    return mapFn(node);
  };

  return helper(objectTree);
};

/**
 * Join passed array of keys with separator
 * @param {string[]} keys
 * @param {string} [separator='.']
 * @return {*}
 */
export const joinKeys = (keys, separator = '.') =>
  (keys || []).filter(Boolean).join(separator ?? '.');
