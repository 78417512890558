import { useMemo } from 'react';

import ContextNameLabel from '+components/Labels/ContextNameLabel';
import GenericLabel from '+components/Labels/GenericLabel';
import { CellIp, ShowMoreWrapper } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnButton,
  MenuColumnFactory,
  MenuColumnLinkIcon,
  NumberColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { makeId } from '+utils/general';
import { getColumnsHelper } from '+utils/getColumnsHelper';

const sortByContext = ({ context: a }, { context: b }) => a.localeCompare(b);

const disableProps = {
  disableDragAndDrop: true,
  disableContextMenu: true,
  disableHide: true,
  disableTooltip: true,
  disableExport: true,
  disableResizing: true,
};

export const IpColumns = {
  ip: 'ip',
  context: 'contexts',
  count: 'count',
  menu: 'menu',
};

export const ContextColumns = {
  context: 'context',
  ips: 'ips',
  count: 'count',
  menu: 'menu',
};

export const LabelIpColumns = {
  context: 'context',
  labels: 'labels',
};

export const LabelContextColumns = {
  ip: 'ip',
  labels: 'labels',
};

const ipColumnsCollection = ({ showLabels }) => ({
  [IpColumns.ip]: BaseColumnFactory({
    accessor: IpColumns.ip,
    Header: 'IP Address',
    Cell: ({ value }) =>
      useMemo(
        () => <CellIp key={value + makeId()} value={value} clickable={false} />,
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
    maxWidth: 120,
    minWidth: 120,
    ...disableProps,
  }),
  [IpColumns.context]: BaseColumnFactory({
    accessor: IpColumns.context,
    Header: 'Contexts',
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ShowMoreWrapper>
            {(value || []).sort(sortByContext).map((val) => (
              <ContextNameLabel key={val.context}>
                {val.context}
              </ContextNameLabel>
            ))}
          </ShowMoreWrapper>
        ),
        [value],
      ),
    Filter: SelectColumnFilter({
      optionValueExtractor: (row, id) =>
        row.values[id].map((item) => item.context),
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      return rows.filter(({ values }) =>
        (values.contexts || []).some(
          (item) => item.context === filterValue.value,
        ),
      );
    }, autoRemoveIfAll),
    maxWidth: 500,
    minWidth: 500,
    ...disableProps,
  }),
  [IpColumns.count]: NumberColumnFactory({
    accessor: IpColumns.count,
    Header: 'Label Count',
    maxWidth: 100,
    minWidth: 100,
    ...disableProps,
  }),
  [IpColumns.menu]: MenuColumnFactory({
    cxActionMenu: (_, original) => {
      return (
        <MenuColumnButton
          title="View labels"
          onClick={() => showLabels(original.labels)}
        >
          <MenuColumnLinkIcon size={16} />
        </MenuColumnButton>
      );
    },
    maxWidth: 40,
    minWidth: 40,
    ...disableProps,
  }),
});

const contextColumnsCollection = ({ showLabels }) => ({
  [ContextColumns.ips]: BaseColumnFactory({
    accessor: ContextColumns.ips,
    Header: 'IP Addresses',
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ShowMoreWrapper>
            {value.map((val) => (
              <CellIp key={val + makeId()} value={val} clickable={false} />
            ))}
          </ShowMoreWrapper>
        ),
        [value],
      ),
    maxWidth: 500,
    minWidth: 500,
    ...disableProps,
  }),
  [ContextColumns.context]: BaseColumnFactory({
    accessor: ContextColumns.context,
    Header: 'Context',
    Cell: ({ value }) =>
      useMemo(
        () => <ContextNameLabel key={value}>{value}</ContextNameLabel>,
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
    maxWidth: 120,
    minWidth: 120,
    ...disableProps,
  }),
  [ContextColumns.count]: NumberColumnFactory({
    accessor: ContextColumns.count,
    Header: 'Label Count',
    maxWidth: 100,
    minWidth: 100,
    ...disableProps,
  }),
  [ContextColumns.menu]: MenuColumnFactory({
    cxActionMenu: (_, original) => {
      return (
        <MenuColumnButton
          title="View labels"
          onClick={() => showLabels(original.labels)}
        >
          <MenuColumnLinkIcon size={16} />
        </MenuColumnButton>
      );
    },
    maxWidth: 40,
    minWidth: 40,
    ...disableProps,
  }),
});

const labelIpColumnsCollection = () => ({
  [LabelIpColumns.context]: BaseColumnFactory({
    accessor: LabelIpColumns.context,
    Header: 'Context',
    Cell: ({ value }) =>
      useMemo(
        () => <ContextNameLabel key={value}>{value}</ContextNameLabel>,
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
    ...disableProps,
  }),
  [LabelIpColumns.labels]: BaseColumnFactory({
    accessor: LabelIpColumns.labels,
    Header: 'Labels',
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ShowMoreWrapper>
            {(value || []).map((val) => {
              return (
                <GenericLabel
                  key={val + makeId()}
                  field=""
                  value={val}
                  clickable={false}
                >
                  {value}
                </GenericLabel>
              );
            })}
          </ShowMoreWrapper>
        ),
        [value],
      ),
    ...disableProps,
  }),
});

const labelContextColumnsCollection = () => ({
  [LabelContextColumns.ip]: BaseColumnFactory({
    accessor: LabelContextColumns.ip,
    Header: 'IP Address',
    Cell: ({ value }) =>
      useMemo(
        () => <CellIp key={value + makeId()} value={value} clickable={false} />,
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
    ...disableProps,
  }),
  [LabelContextColumns.labels]: BaseColumnFactory({
    accessor: LabelContextColumns.labels,
    Header: 'Labels',
    Cell: ({ value }) =>
      useMemo(
        () => (
          <ShowMoreWrapper>
            {(value || []).map((val) => (
              <GenericLabel
                key={val + makeId()}
                field=""
                value={val}
                clickable={false}
              >
                {value}
              </GenericLabel>
            ))}
          </ShowMoreWrapper>
        ),
        [value],
      ),
    ...disableProps,
  }),
});

export const getLabelIpColumns = getColumnsHelper(labelIpColumnsCollection);
export const getLabelContextColumns = getColumnsHelper(
  labelContextColumnsCollection,
);

export const getIpColumns = getColumnsHelper(ipColumnsCollection);
export const getContextColumns = getColumnsHelper(contextColumnsCollection);
