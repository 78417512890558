import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';

import DropdownFieldOrigin from '+components/form/DropdownField';
import { selectors as contextTypesTheme } from '+theme/slices/contextTypes';

const ContextDropdownField = styled(DropdownFieldOrigin).attrs({
  menuProps: {
    PaperProps: {
      style: {
        transform: 'translateY(5px)',
      },
    },
  },
})`
  width: 80px;
  height: 32px;
  max-height: 32px;
  font-weight: 600;
  font-size: 13px;
  text-transform: unset;

  .MuiButton-icon {
    margin-left: 2px;
  }

  &.${ContextTypes.traffic} {
    --button-color: ${contextTypesTheme[ContextTypes.traffic]};
    border-color: var(--button-color);
    background-color: var(--button-color);
    &:focus,
    &:hover,
    &:active {
      border-color: var(--button-color) !important;
      background-color: var(--button-color) !important;
    }
  }

  &.${ContextTypes.flow} {
    --button-color: ${contextTypesTheme[ContextTypes.flow]};
    border-color: var(--button-color);
    background-color: var(--button-color);

    &:focus,
    &:hover,
    &:active {
      border-color: var(--button-color) !important;
      background-color: var(--button-color) !important;
    }
  }

  &.${ContextTypes.dns} {
    --button-color: ${contextTypesTheme[ContextTypes.dns]};
    border-color: var(--button-color);
    background-color: var(--button-color);

    &:focus,
    &:hover,
    &:active {
      border-color: var(--button-color) !important;
      background-color: var(--button-color) !important;
    }
  }

  &.${ContextTypes.alerts} {
    --button-color: ${contextTypesTheme[ContextTypes.alerts]};
    border-color: var(--button-color);
    background-color: var(--button-color);

    &:focus,
    &:hover,
    &:active {
      border-color: var(--button-color) !important;
      background-color: var(--button-color) !important;
    }
  }

  &.${ContextTypes.blocks} {
    --button-color: ${contextTypesTheme[ContextTypes.blocks]};
    border-color: var(--button-color);
    background-color: var(--button-color);

    &:focus,
    &:hover,
    &:active {
      border-color: var(--button-color) !important;
      background-color: var(--button-color) !important;
    }
  }
`;

export default ContextDropdownField;
