import styled from 'styled-components';

import Paper from '@mui/material/Paper';

import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

export default styled(Paper)`
  background: ${globalFiltersTheme.selectBackground} !important;
  border-radius: 4px !important;
  text-align: center;

  .MuiAutocomplete-listbox {
    ${ScrollBarMixin};

    li:not(.MuiAutocomplete-option) + li:not(.MuiAutocomplete-option) {
      margin-top: 12px;
    }

    .MuiAutocomplete-groupLabel {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      max-width: 100%;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 11px;
      padding: 0 8px !important;
      color: ${({ theme }) => theme.colorTextSecondary};
      background: ${globalFiltersTheme.selectBackground};
      height: 24px;
      line-height: unset;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:empty {
        display: none;
      }
    }

    &.virtualized {
      padding: 0;
      max-height: 50vh;

      .MuiAutocomplete-groupLabel {
        padding-top: 8px !important;
      }
    }

    &:not(.virtualized) {
      .MuiAutocomplete-groupLabel {
        position: unset !important;
      }
    }

    .MuiAutocomplete-option {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 13px;
      padding: 6px 8px !important;
      color: ${({ theme }) => theme.colorText};

      &__body {
        max-width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow: hidden;

        > * + * {
          margin-left: 10px;
        }
      }

      &__icon {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 1px;
      }

      &__label,
      &__description {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__label:not(:only-child) {
        min-width: fit-content;
      }

      &__description {
        min-width: unset;
        opacity: 0.5;
      }

      &[aria-selected='true'] {
        background: ${globalFiltersTheme.selectOptionSelected};
        * {
          text-shadow: 0 0 1px ${({ theme }) => theme.colorText};
        }
      }

      &[aria-disabled='true'][aria-selected='true'] {
        //opacity: 1 !important;
      }

      &[data-focus='true'] {
        background: transparent;
      }

      &:hover,
      &[data-focus='true'] {
        background: ${globalFiltersTheme.selectOptionFocused} !important;
        * {
          color: ${({ theme }) => theme.colorFieldBackground};
          text-shadow: 0 0 1px ${({ theme }) => theme.colorFieldBackground};
          opacity: 1;
        }
      }
    }
  }

  .MuiAutocomplete-noOptions {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 13px;
    margin: 0 auto;
    color: ${({ theme }) => theme.colorText};
  }

  .MuiDivider-root {
    margin: 4px 0;
    border-color: ${globalFiltersTheme.separatorColor};
  }

  .virtualized .MuiDivider-root {
    margin: 0;
    box-sizing: border-box;
  }
`;
