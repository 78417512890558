import PropTypes from '+prop-types';
import { useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import cloneDeep from 'lodash.clonedeep';
import styled from 'styled-components';

import { ButtonGroup } from '@mui/material';

import Button, { ButtonVariants } from '+components/Button';
import { Col, Row } from '+components/Layout';
import { makeId } from '+utils/general';

import FormField from '../FormField';
import { FormStyledTextArea } from '../TextField';
import { validateTransformJson } from '../Validators';
import Editor from './Editor';

const isValidJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
// converts transforms json { transforms: {...}} to array
const generateTransformsArray = (transforms) => {
  let transformsData;
  try {
    transformsData = JSON.parse(transforms || '{}')?.transforms || {};
  } catch (e) {
    return [];
  }
  const transformsArray = [];
  Object.keys(transformsData).forEach((key) => {
    const data = transformsData[key];
    // if we find an array type field, need to handle all of its contents as separate fields.
    if (Array.isArray(data)) {
      data.forEach((innerField) =>
        transformsArray.push({
          ...innerField,
          sourceField: key,
          id: makeId(),
        }),
      );
    } else {
      transformsArray.push({
        ...data,
        sourceField: key,
        id: makeId(),
      });
    }
  });
  return transformsArray;
};

// converts transforms data array to json string:  "{ transforms: {...}}"
const transformsArrayToJsonString = (transformsArray) => {
  const copy = cloneDeep(transformsArray);
  const object = copy.reduce((acc, transformData) => {
    const { sourceField } = transformData;
    delete transformData.id;
    delete transformData.sourceField;
    // if there are duplicate fields, need to add them to the array type field
    if (acc[sourceField]) {
      if (Array.isArray(acc[sourceField])) {
        acc[sourceField].push({ ...transformData });
      } else {
        acc[sourceField] = [{ ...acc[sourceField] }, { ...transformData }];
      }
    } else {
      acc[sourceField] = { ...transformData };
    }

    return acc;
  }, {});
  return JSON.stringify({ transforms: object });
};

const ButtonRow = styled(Row)`
  margin-bottom: 10px;
`;

const NetofuseTransformField = (props) => {
  const {
    input,
    meta: { error },
    ...tail
  } = props;

  const [showEditor, toggleEditor] = useToggle(true);
  const [fieldIsValid, setFieldIsValid] = useState(
    validateTransformJson(input?.value) === null,
  );
  // initial state of the transform as a JSON object,
  // we keep it as an array to preserve order of fields and make name changes easier.
  const [transformsArray, setTransformsArray] = useState(
    input?.value ? generateTransformsArray(input?.value) : true,
  );

  const handleEditorChange = useCallback(
    (newTransformsArray) => {
      const newTransformsString =
        transformsArrayToJsonString(newTransformsArray);
      input?.onChange(newTransformsString);
      setTransformsArray(newTransformsArray);
    },
    [input?.onChange],
  );

  const handleTextBoxChange = useCallback(
    (event) => {
      const newTransformsString = event.target.value;
      input?.onChange(newTransformsString);
      setFieldIsValid(validateTransformJson(newTransformsString) === null);
      setTransformsArray(generateTransformsArray(newTransformsString));
    },
    [input?.onChange],
  );

  const addSourceField = useCallback(() => {
    const copy = [...transformsArray];
    copy.unshift({ sourceField: '', context: '', id: makeId() });
    setTransformsArray(copy);
  }, [transformsArray]);

  return (
    <FormField {...tail} error={error} invalid={!fieldIsValid}>
      <ButtonRow>
        <Col item xs={6}>
          <ButtonGroup>
            <Button
              key="editor"
              disabled={!fieldIsValid}
              style={{
                pointerEvents: showEditor ? 'none' : null,
              }}
              variant={
                showEditor ? ButtonVariants.contained : ButtonVariants.outlined
              }
              onClick={toggleEditor}
            >
              Editor
            </Button>
            <Button
              style={{
                pointerEvents: !showEditor ? 'none' : null,
              }}
              variant={
                !showEditor ? ButtonVariants.contained : ButtonVariants.outlined
              }
              onClick={toggleEditor}
            >
              JSON
            </Button>
          </ButtonGroup>
        </Col>
        <Col item xs={6} alignItems="end">
          {showEditor && (
            <Button variant="outlined" onClick={addSourceField}>
              + Add Source Field
            </Button>
          )}
        </Col>
      </ButtonRow>

      {!showEditor && (
        <FormStyledTextArea
          defaultValue={
            isValidJson(input?.value)
              ? JSON.stringify(JSON.parse(input.value || '{}'), null, 4)
              : input?.value
          }
          onChange={handleTextBoxChange}
          maxRows={10}
        />
      )}
      {showEditor && (
        <Editor transforms={transformsArray} onChange={handleEditorChange} />
      )}
    </FormField>
  );
};

NetofuseTransformField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
};

NetofuseTransformField.defaultProps = {
  meta: null,
};

export default NetofuseTransformField;
