import { useCallback, useContext } from 'react';

import styled from 'styled-components';

import MenuIcon from '+components/charts/common/MenuIcon';
import { useMenuActions, useMenuState } from '+components/Menu';

import Context from './Context';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  position: absolute;

  margin: 0;
  padding: 0 5px;
  border: 0;

  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ $active, theme }) =>
    $active ? theme.topbarMenuBackgroundHover : 'rgba(0, 0, 0, 0)'};
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colorHover};
  }

  &:active {
    background: ${({ theme }) => theme.topbarMenuBackgroundHover};
  }

  & path:not(#fake_id) {
    stroke: ${({ $hasSelected, theme }) =>
      $hasSelected ? theme.primary : null};
  }
`;

const Trigger = () => {
  const { show } = useMenuState();
  const { showMenuXY } = useMenuActions();
  const { selected } = useContext(Context);

  const onClick = useCallback((e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    showMenuXY(rect.left, rect.bottom - 3);
  }, []);

  return (
    <Button
      type="button"
      onClick={onClick}
      $active={show}
      title="Table context menu"
      $hasSelected={!!selected}
      data-testid="table-context-menu-trigger"
    >
      <MenuIcon size={16} />
    </Button>
  );
};

export default Trigger;
