import { useEffect, useRef, useState } from 'react';

import StatsRequest from '@/models/StatsRequest';

import calcRefreshInterval, {
  getSeriesInterval,
  getSeriesLastBucketTime,
} from '+utils/calcRefreshInterval';

/**
 * Hook to create polling heartbeat for REST API stats requests.
 *
 * @param {Object?} series - stats series
 * @param {StatsRequest.Types?} seriesType - series type.
 * @param {boolean?} stop - if true, polling heartbeat will be paused.
 * @param {number?} minRefreshInterval - minimum refresh interval.
 * @return number
 */
export const usePollingHeartbeat = (
  series,
  seriesType,
  stop,
  minRefreshInterval,
) => {
  const [pollingHeartbeat, setPollingHeartbeat] = useState(0);
  const seriesInterval = getSeriesInterval(series);
  const seriesLastBucketTime =
    seriesType === StatsRequest.Types.ts ? getSeriesLastBucketTime(series) : 0;

  const lastStopTime = useRef(0);
  useEffect(() => {
    if (stop) {
      lastStopTime.current = Date.now();
      return undefined;
    }

    const refreshInterval = calcRefreshInterval({
      seriesInterval,
      seriesLastBucketTime,
      minRefreshInterval,
    });

    const currentTime = Date.now();
    if (currentTime - (lastStopTime.current || currentTime) > refreshInterval) {
      setPollingHeartbeat(currentTime);
    }

    const intervalId = setInterval(() => {
      const newHeartbeat = Date.now();
      setPollingHeartbeat(newHeartbeat);
    }, refreshInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [stop, seriesInterval, seriesLastBucketTime]);

  return pollingHeartbeat;
};

export default usePollingHeartbeat;
