import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useEffect, useRef } from 'react';

import styled from 'styled-components';

import FilterVariantIcon from 'mdi-react/FilterVariantIcon';

import IconButtonOrigin from '+components/IconButton';
import { Menu, useMenuActions, useMenuState } from '+components/Menu';
import {
  cellPropsCompare,
  getHeaderActionMenuPosition,
  tooltipDelay,
} from '+components/Table/ReactTable/utils';
import Tooltip from '+components/Tooltip';

import {
  headerSettingsMenuWidth,
  THeadSettings as THeadSettingsOrigin,
} from './Components';

const IconButton = styled(IconButtonOrigin)`
  &:not(#fake-filter-button) {
    background-color: ${({ theme, $hasFilter }) =>
      $hasFilter
        ? `${theme.colorTool.alpha(theme.primary, 0.8)} !important`
        : null};
    color: ${({ theme, $hasFilter }) =>
      $hasFilter ? `${theme.iconButtonText} !important` : null};
  }
`;

const THeadSettings = memo(THeadSettingsOrigin, cellPropsCompare);

const HeaderSettings = (props) => {
  const {
    index,
    activeActionsColumnIndex,
    setActiveActionsColumnIndex,
    setContainerHover,
    hasFilter,
    Renderer,
  } = props;

  const menuState = useMenuState();
  const menuActions = useMenuActions();

  const iconRef = useRef(null);

  const onSettingsIconClick = useCallback(
    (event) => {
      event.stopPropagation();
      setActiveActionsColumnIndex(index);
      const { x: menuX, y: menuY } = getHeaderActionMenuPosition(
        iconRef,
        headerSettingsMenuWidth,
      );
      menuActions.showMenuXY(menuX, menuY);
    },
    [index],
  );

  const onSettingsMenuClose = useCallback(() => {
    setActiveActionsColumnIndex(null);
    setContainerHover(false);
  }, []);

  useEffect(() => {
    if (
      activeActionsColumnIndex !== index ||
      !menuState.show ||
      !iconRef.current
    ) {
      return undefined;
    }
    const menuReflow = () => {
      const { x: menuX, y: menuY } = getHeaderActionMenuPosition(
        iconRef,
        headerSettingsMenuWidth,
      );
      if (menuState.left !== menuX || menuState.top !== menuY) {
        menuActions.showMenuXY(menuX, menuY);
      }
    };
    document.addEventListener('scroll', menuReflow, true);
    return () => {
      document.removeEventListener('scroll', menuReflow, true);
    };
  }, [activeActionsColumnIndex, index, menuState]);

  return (
    <Fragment>
      <Tooltip
        title="Apply column settings"
        placement="top"
        enterDelay={tooltipDelay}
        enterNextDelay={tooltipDelay}
        arrow={false}
      >
        <IconButton
          ref={iconRef}
          onClick={onSettingsIconClick}
          $hasFilter={hasFilter}
        >
          <FilterVariantIcon size={24} />
        </IconButton>
      </Tooltip>

      <Menu onClose={onSettingsMenuClose} avoidScrollAction animated={false}>
        <THeadSettings>{Renderer}</THeadSettings>
      </Menu>
    </Fragment>
  );
};

HeaderSettings.propTypes = {
  index: PropTypes.number.isRequired,
  activeActionsColumnIndex: PropTypes.number,
  setActiveActionsColumnIndex: PropTypes.func.isRequired,
  setContainerHover: PropTypes.func.isRequired,
  Renderer: PropTypes.node.isRequired,
  hasFilter: PropTypes.bool.isRequired,
};

HeaderSettings.defaultProps = {
  activeActionsColumnIndex: null,
};

export default HeaderSettings;
