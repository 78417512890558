import { criticalPortMap, warnPortMap } from './data';

export const SECURITY = {
  critical: 'critical',
  warning: 'warning',
  ok: 'ok',
};

const severityMapping = {
  [SECURITY.critical]: 'rgb(162, 0, 0)',
  [SECURITY.warning]: 'rgb(252, 118, 35)',
  [SECURITY.ok]: 'rgb(0, 164, 11)',
};

export function getNodeColor(severity) {
  return severityMapping[severity] || severityMapping.ok;
}

export const getNodeTextColor = (severity) => {
  return SECURITY.ok === severity ? null : severityMapping[severity];
};

export function getSecurity(portProtoString) {
  if (criticalPortMap[portProtoString]) {
    return SECURITY.critical;
  }

  if (warnPortMap[portProtoString]) {
    return SECURITY.warning;
  }

  return SECURITY.ok;
}

export const getColorIndex = (severity) => {
  switch (severity) {
    case SECURITY.ok:
      return 2;
    case SECURITY.warning:
      return 4;
    case SECURITY.critical:
      return 6;
    default:
      return 2;
  }
};

const securityOrdering = [SECURITY.ok, SECURITY.warning, SECURITY.critical];
export function securityMax(a, b) {
  const aIdx = securityOrdering.findIndex((s) => s === a);
  const bIdx = securityOrdering.findIndex((s) => s === b);
  return aIdx > bIdx ? a : b;
}
