/* eslint-disable react/no-array-index-key, react/prop-types */
import { Fragment } from 'react';

import { ShowMoreWrapper, UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  GeoColumnFactory,
  LabelOrIpColumnFactory,
  OwnerAsColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import Tag from '+components/Tag';

const columnsCollection = ({ labelContext }) => [
  BaseColumnFactory({
    id: 'ip_type',
    accessor: ({ dstip }) => (dstip ? 'dstip' : 'srcip'),
    Header: 'IP Type',
    maxWidth: 80,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) => <Tag color={value}>{value}</Tag>,
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'dstip', 'srcip'],
      sort: false,
    }),
    filter: 'selectFilter',
  }),
  LabelOrIpColumnFactory({
    Header: 'IP',
    dataFieldName: 'ip',
    labelFieldName: 'ipname',
    showLabel: labelContext.show,
  }),
  BaseColumnFactory({
    accessor: 'bogon',
    Header: 'Bogon',
    maxWidth: 80,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) =>
      value ? (
        <Tag color="danger" outlined={false}>
          YES
        </Tag>
      ) : (
        <Tag color="success" outlined={false}>
          NO
        </Tag>
      ),
    Filter: BooleanColumnFilter({
      true: 'Yes',
      false: 'No',
    }),
    filter: 'booleanFilter',
  }),
  GeoColumnFactory({
    Header: 'Geo',
    field: 'geo',
    Cell: (cell) => {
      const original = getRowOriginal(cell?.row);
      return UniversalCell(original?.dstip ? 'dstgeo' : 'srcgeo')(cell);
    },
  }),
  OwnerAsColumnFactory({
    Header: 'Owner AS',
    field: 'as',
    Cell: (cell) => {
      const original = getRowOriginal(cell?.row);
      return UniversalCell(original?.dstip ? 'dstowneras' : 'srcowneras')(cell);
    },
  }),
  BaseColumnFactory({
    accessor: 'iprep.categories',
    Header: 'IP Reputation',
    Cell: ({ value }) => (
      <Fragment>
        {!value?.length && (
          <Tag color="success" outlined={false}>
            None
          </Tag>
        )}
        {value?.length && (
          <ShowMoreWrapper>
            {value.map((item) => (
              <span key={item}>{item}</span>
            ))}
          </ShowMoreWrapper>
        )}
      </Fragment>
    ),
  }),
];

export const getColumns = columnsCollection;
