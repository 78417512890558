import { css } from 'styled-components';

export default ({
  textColor,
  background,
  borderColor,
  textColorDisabled,
  backgroundDisabled,
  borderColorDisabled,
}) => css`
  border-color: ${borderColor};
  background-color: ${background};
  color: ${textColor};

  &,
  .MuiButton-startIcon,
  .MuiButton-endIcon {
    color: ${textColor};
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;
  }

  &:focus {
    border-color: ${borderColor};
    background-color: ${background};

    &,
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      color: ${textColor};
    }
  }

  &:focus-visible {
    border-color: ${({ theme }) => theme.colorTool.darken(borderColor, 0.4)};
  }

  &:hover {
    border-width: 2px;
    border-color: ${({ theme }) => theme.colorTool.lighten(borderColor, 0.2)};
    background-color: ${({ theme }) =>
      theme.colorTool.lighten(background, 0.2)};

    &,
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      color: ${({ theme }) => theme.colorTool.lighten(textColor, 0.2)};
    }
  }

  &:active:not(#fake_id_for_button),
  &:active:focus:not(#fake_id_for_button) {
    border-color: ${({ theme }) => theme.colorTool.darken(borderColor, 0.2)};
    background-color: ${({ theme }) => theme.colorTool.darken(background, 0.2)};

    &,
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      color: ${({ theme }) => theme.colorTool.darken(textColor, 0.2)};
    }
  }

  &.Mui-disabled {
    border-width: 2px;
    opacity: unset;
    border-color: ${borderColorDisabled};
    background-color: ${backgroundDisabled};

    &,
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      color: ${textColorDisabled} !important;
    }
  }
`;
