import PropTypes from '+prop-types';
import { Fragment, useCallback } from 'react';

import Papa from 'papaparse';

import FieldsSection from '+components/form/FieldsSection';
import FileUpload from '+components/form/FileUpload';
import { useForm } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const GcpFields = ({ disabled, tags }) => {
  const form = useForm();

  const regionOptions = useProviderRegions('gcp');

  const onImport = useCallback(
    (file) => {
      if (file) {
        Papa.parse(file, {
          complete: (results) => {
            const jsonData = JSON.parse(
              results.data.join(results.meta.linebreak),
            );
            Object.entries(jsonData).forEach(([key, value]) => {
              form.change(`gcp.credentials.${key}`, value);
            });
          },
        });
      }

      return file;
    },
    [form],
  );

  return (
    <Fragment>
      <FileUpload
        style={{ width: 'unset', marginLeft: 'auto', marginBottom: '2rem' }}
        buttonText="Import from JSON"
        accept=".json"
        onChange={onImport}
        resetAfterChange
        hideInput
      />

      <Field
        name="gcp.zone"
        label="Zone"
        component={SelectField}
        options={regionOptions}
        parse={normalizeSelectValue}
        helperText="Zone of the GCP, leave blank to include all zones"
        disabled={disabled}
      />

      <Field
        name="gcp.labels"
        label="Tag/Label Matches"
        helperText="One or many tags to match any custom tag names to track in GCP"
        options={tags}
        component={MultiSelectField}
        parse={normalizeMultiSelectValue}
        disabled={disabled}
        allowCreate
      />

      <FieldsSection label="Credentials">
        <Field
          name="gcp.credentials.type"
          label="Type"
          helperText="Credential type"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.project_id"
          label="Project ID"
          helperText="Project ID of the account"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.private_key_id"
          label="Private Key ID"
          helperText="Private Key ID of the service account"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.private_key"
          label="Private Key"
          helperText="Private Key to authenticate with"
          component={TextField}
          type="textarea"
          autoComplete="off"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.client_email"
          label="Client Email"
          helperText="Email of the service account"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.client_id"
          label="Client ID"
          helperText="ID of the client"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.auth_uri"
          label="Auth URI"
          helperText="OAuth2 URI"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.token_uri"
          label="Token URI"
          helperText="Token URI"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.auth_provider_x509_cert_url"
          label="Auth Cert URL"
          helperText=""
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="gcp.credentials.client_x509_cert_url"
          label="Client Cert URL"
          helperText=""
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />
      </FieldsSection>
    </Fragment>
  );
};

GcpFields.propTypes = {
  disabled: PropTypes.bool,
  tags: PropTypes.array,
};

GcpFields.defaultProps = {
  disabled: false,
  tags: [],
};

export default GcpFields;
