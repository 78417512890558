import PropTypes from '+prop-types';
import { forwardRef, useLayoutEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import isEqual from 'lodash.isequal';
import styled from 'styled-components';

import getItemSize, { listPadding } from './getItemSize';
import OuterElement, { OuterElementContext } from './OuterElement';
import Row from './Row';
import useListHeight from './useListHeight';

const InnerElement = styled('ul').attrs((props) => ({
  ...props,
  style: {
    ...props.style,
    height: props.style.height + 2 * listPadding,
  },
}))`
  padding: 0;
  margin: 0;
`;

const VirtualizedListbox = forwardRef((props, ref) => {
  const { children, ...rest } = props;

  const outerValue = useRef(rest);
  const gridRef = useRef();

  // don't need memoize, because children always new.
  const data = children
    .flatMap((child) => [child, ...(child.children || [])])
    .filter((child) => ('group' in child ? !!child.group : true));
  const selected = data.findIndex((item) => item.selected);
  const itemSize = (index) => getItemSize(data[index]);
  const height = useListHeight(data, 40);

  useLayoutEffect(() => {
    gridRef.current?.resetAfterIndex(0, true);
  }, [data.length]);

  useLayoutEffect(
    () => {
      gridRef.current?.scrollToItem(Math.max(selected, 0), 'start');
    },
    // data.length - allows to reset scroll position when data changed (e.g. on search)
    [selected, data.length],
  );

  if (!isEqual(rest, outerValue.current)) {
    outerValue.current = rest;
  }

  return (
    <OuterElementContext.Provider value={outerValue.current}>
      <VariableSizeList
        ref={gridRef}
        outerRef={ref}
        outerElementType={OuterElement}
        innerElementType={InnerElement}
        height={height + 2 * listPadding}
        itemData={data}
        itemCount={data.length}
        itemSize={itemSize}
        overscanCount={5}
        initialScrollOffset={listPadding}
      >
        {Row}
      </VariableSizeList>
    </OuterElementContext.Provider>
  );
});

VirtualizedListbox.displayName = 'VirtualizedListbox';

VirtualizedListbox.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(),
      PropTypes.arrayOf(PropTypes.shape()),
    ]),
  ).isRequired,
};

export default VirtualizedListbox;
