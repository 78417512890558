import { createThemeSlice } from '../util';

const themeSlice = createThemeSlice('map', (choose) => ({
  boardTrackColor: choose({
    light: 'rgba(21,59,87, 0.8)',
    dark: 'rgba(206,252,255,0.3)',
  }),
  panelBackground: choose({
    light: '#F0F1F4',
    dark: 'rgba(0, 0, 0, 0.5)',
  }),
  panelBorderColor: choose({
    light: '#CDCCD1',
    dark: '#3B3B3B',
  }),
  panelHeaderBackground: choose({
    light: '#E0DFE2',
    dark: '#202124',
  }),
  panelHeaderHoverBackground: choose({
    light: '#C0BFC5',
    dark: '#38373f',
  }),
  /** * Table ** */
  tableOddRowBackground: choose({
    light: '#EAEBED',
    dark: 'rgba(223, 222, 219, 0.03)',
  }),
  tableRowHover: choose({
    light: '#E0DFE2',
    dark: 'rgba(223, 222, 219, 0.05)',
  }),
  tableCellBorder: choose({
    light: '#CDCCD1',
    dark: 'rgba(255, 255, 255, 0.02)',
  }),
  tableCellValueBackground: choose({
    light: '#CDCCD1',
    dark: 'rgba(255, 255, 255, 0.2)',
  }),
}));

export const { selector, selectors, propsSelector, propsSelectors } =
  themeSlice;
export default themeSlice;
