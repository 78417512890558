import PropTypes from '+prop-types';

import styled from 'styled-components';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const Icon = <ChevronDownIcon size={16} />;

const Accordion = styled(MuiAccordion).attrs({
  square: true,
  disableGutters: true,
  elevation: 0,
})`
  &:before {
    content: none;
  }

  background: rgba(
    ${({ theme }) => (theme.name === 'dark' ? '15, 15, 15' : '220, 220, 220')},
    0.2
  );

  &:hover {
    background: rgba(
      ${({ theme }) =>
        theme.name === 'dark' ? '15, 15, 15' : '220, 220, 220'},
      0.5
    );
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  min-height: 0;
  font-size: 13px;
  padding-left: 40px;

  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 4px;
  }

  .MuiAccordionSummary-expandIconWrapper:not(.Mui-expanded) {
    transform: rotate(-90deg);
  }

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }

  .MuiAccordionSummary-content {
    margin: 4px 0;
  }
`;

const ExtendedAccordion = (props) => {
  const { children, title, ...rest } = props;

  return (
    <Accordion {...rest}>
      <AccordionSummary expandIcon={Icon}>{title}</AccordionSummary>
      {children}
    </Accordion>
  );
};

ExtendedAccordion.propTypes = {
  children: PropTypes.children.isRequired,
  title: PropTypes.string.isRequired,
};

export default ExtendedAccordion;
