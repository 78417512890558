import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/vpc';

import { Breadcrumb } from '+components/Breadcrumb';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { usePageTabs } from '+components/PageTabs';
import useUIProperty from '+hooks/useUIProperty';

import { cleanUpFields } from '../../utils';
import VpcForm from './Form';
import { vpcParamsToUi } from './utils';

const VpcEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vpcid } = useParams();

  const [, activePageTab] = usePageTabs();

  const vpc = useSelector(selectors.getVpcById(vpcid));
  // if the user nav'd from the vpc list, then the vpc will already be in redux
  // if directly loading (like on a refresh, then we need to call the api to fetch the vpc)
  const [isLoadingVpc, setIsLoadingVpc] = useState(false);

  // convert a nested API object to a flatten UI object
  const initialValues = useMemo(() => vpcParamsToUi(vpc), [vpc]);

  useEffect(() => {
    if (!vpc?.id) {
      setIsLoadingVpc(true);
      dispatch(actions.fetchById(vpcid));
    }
  }, [vpcid]);

  useEffect(() => {
    if (vpc) {
      setIsLoadingVpc(false);
    }
  }, [vpc]);

  // track if we have intercepted a submit and if we are waiting on the API/dispatch to complete
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching, error } = useSelector(selectors.getState);

  useEffect(() => {
    if (error) {
      setIsLoadingVpc(false);
    }
  }, [error]);

  const handleUpdateSubmit = useCallback(
    (values) => {
      const data = cleanUpFields(values);
      setIsSubmitting(activePageTab?.id);
      data.id = vpcid;
      dispatch(actions.vpcUpdate(data));
    },
    [vpcid, activePageTab?.id],
  );

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      navigate(`${RoutePaths.sources}`);
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    setMasqueradeUrl(`${RoutePaths.sources}`);
    return () => {
      setMasqueradeUrl(null);
    };
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      {initialValues && (
        <EditPageAuditLogTabs
          auditNqlQuery={`class == cloud_provider && original_id == ${vpcid}`}
          breadcrumb={<Breadcrumb title="Edit Cloud Provider" />}
        >
          <VpcForm
            mode="update"
            initialValues={initialValues}
            updateCallback={handleUpdateSubmit}
            loading={isLoadingVpc || (!!isSubmitting && isFetching)}
          />
        </EditPageAuditLogTabs>
      )}
    </Fragment>
  );
};

export default VpcEdit;
