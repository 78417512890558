import styled from 'styled-components';

const FieldLabel = styled.span`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(p) =>
    p.$disabled ? p.theme.colorTextSecondary : p.theme.colorText} !important;
`;

export default FieldLabel;
