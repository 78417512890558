import { useMemo } from 'react';

import ManageColumnHeader from './components/ManageColumnHeader';
import ManageColumnProvider from './components/ManageColumnProvider';
import MenuColumnButton from './components/MenuColumnButton';
import MenuColumnContextMenu, {
  MenuColumnContextMenuIcon,
} from './components/MenuColumnContextMenu';
import MenuColumnLink, {
  MenuColumnLinkIcon,
} from './components/MenuColumnLink';
import MenuColumnPropertiesTrayTrigger, {
  MenuColumnPropertiesTrayIcon,
} from './components/MenuColumnPropertiesTrayTrigger';

/**
 * @param cxActionMenu
 * @param cxGroupActionMenu
 * @return {{}}
 */
const MenuColumnFactory = ({ cxActionMenu, cxGroupActionMenu }) => ({
  Header: ManageColumnHeader,
  id: 'menu',
  minWidth: 40,
  maxWidth: 40,
  getCellProps: () => ({ style: { justifyContent: 'center' } }),

  disableFilters: true,
  disableGroupBy: true,
  disableAggregators: true,
  disableDuplicateBy: true,
  disableResizing: true,
  disableSortBy: true,
  disableDragAndDrop: true,
  disableContextMenu: true,
  disableHide: true,
  disableTooltip: true,
  disableExport: true,
  createdOnFactory: true,

  Cell: ({ row: { original, index } = {} }) =>
    useMemo(
      () => cxActionMenu(original?.id, original, index),
      [cxActionMenu, original, index],
    ),
  Aggregated: ({ cell: { row } }) =>
    useMemo(
      () => (cxGroupActionMenu ? cxGroupActionMenu(row) : ''),
      [cxGroupActionMenu, row],
    ),
});

export {
  ManageColumnHeader,
  ManageColumnProvider,
  MenuColumnFactory,
  MenuColumnPropertiesTrayTrigger,
  MenuColumnButton,
  MenuColumnLink,
  MenuColumnPropertiesTrayIcon,
  MenuColumnLinkIcon,
  MenuColumnContextMenu,
  MenuColumnContextMenuIcon,
};
