import PropTypes from '+prop-types';
import { useCallback } from 'react';
import { useDebounce } from 'react-use';

import styled from 'styled-components';

import AddIcon from 'mdi-react/AddIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import Field from '+components/form/FinalForm/Field';
import { Description as DescriptionOrigin } from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import IconButtonOrigin from '+components/IconButton';
import { Col as ColOrigin, LayoutSizes, Row } from '+components/Layout';

const IconButton = styled(IconButtonOrigin)`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.$hidden ? 'none' : 'auto')};
  margin-top: 4px;
`;

const FakeButton = styled.div`
  visibility: hidden;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
`;

const Description = styled(DescriptionOrigin)`
  margin-top: 8px !important;
  margin-left: 0 !important;
`;

const Col = styled(ColOrigin)`
  .form__form-group {
    margin-bottom: 0 !important;
  }
`;

const emptyRow = {
  role: null,
  key: null,
  value: null,
};

const UserRoleMapperFields = (props) => {
  const {
    className,
    fields,
    rolesOptions,
    helperText,
    disabled,
    required,
    ...tail
  } = props;

  const onAdd = useCallback(() => fields.push(emptyRow), [fields]);

  const onRemove = useCallback(
    (index) => () => {
      // if there is only one we should empty it instead of removing
      if (fields.value?.length === 1) {
        fields.update(index, emptyRow);
        return;
      }
      fields.remove(index);
    },
    [fields],
  );

  // We are using useDebounce because we need to wait until fields value will apply
  useDebounce(
    () => {
      if (!fields.value?.length) {
        onAdd();
      }
    },
    0,
    [fields.value, onAdd],
  );

  return (
    <Col {...tail} className={className}>
      {fields.value?.length > 0 && (
        <Row
          gap={LayoutSizes.groupGap}
          alignItems="flex-end"
          wrap="nowrap"
          style={{
            height: '26px',
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          <Col container={false} xs={3} item>
            {`Netography Role${required ? '*' : ''}`}
          </Col>
          <Col container={false} xs={3} item>
            {`SAML Attribute${required ? '*' : ''}`}
          </Col>
          <Col container={false} xs={3} item>
            {`SAML Attribute Value${required ? '*' : ''}`}
          </Col>
          {fields.value?.length > 1 && <FakeButton />}
          <FakeButton />
        </Row>
      )}

      <Col gap="15px">
        {fields.map((member, index) => {
          const rowValues = fields.value[index];
          const isRowEmpty = Object.values(rowValues).every((value) => !value);
          const isRowRequired = required && !isRowEmpty;
          const isRowDeletable =
            !disabled && (fields.value?.length > 1 || !isRowEmpty);

          return (
            <Row
              key={member}
              gap={LayoutSizes.groupGap}
              alignItems="flex-start"
              wrap="nowrap"
            >
              <Col container={false} xs={3} item>
                <Field
                  name={`${member}.role`}
                  component={SelectField}
                  options={rolesOptions}
                  groupBy={(item) => (item.system ? 'System' : 'Custom')}
                  parse={normalizeSelectValue}
                  validate={isRowRequired ? validateRequired : undefined}
                  required={isRowRequired}
                  disabled={disabled}
                />
              </Col>

              <Col container={false} xs={3} item>
                <Field
                  name={`${member}.key`}
                  type="text"
                  component={TextField}
                  validate={isRowRequired ? validateRequired : undefined}
                  required={isRowRequired}
                  disabled={disabled}
                />
              </Col>

              <Col container={false} xs={3} item>
                <Field
                  name={`${member}.value`}
                  type="text"
                  component={TextField}
                  validate={isRowRequired ? validateRequired : undefined}
                  required={isRowRequired}
                  disabled={disabled}
                />
              </Col>

              <IconButton
                color="primary"
                title="Remove Mapper"
                disabled={!isRowDeletable}
                onClick={onRemove(index)}
              >
                <DeleteForeverIcon size={16} />
              </IconButton>

              <IconButton
                color="primary"
                title="Add Mapper"
                $hidden={fields.length !== index + 1}
                disabled={disabled}
                onClick={onAdd}
              >
                <AddIcon size={16} />
              </IconButton>
            </Row>
          );
        })}
      </Col>

      {helperText && <Description>{helperText}</Description>}
    </Col>
  );
};

UserRoleMapperFields.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.shape().isRequired,
  rolesOptions: PropTypes.arrayOf(PropTypes.shape()),
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

UserRoleMapperFields.defaultProps = {
  className: '',
  rolesOptions: [],
  helperText: '',
  required: false,
  disabled: false,
};

export default UserRoleMapperFields;
