import { css } from 'styled-components';

export const DropdownMixin = css`
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-transform: unset;
  border: none;
  border-radius: 0;
  padding: 0 15px;

  --background: ${({ theme, $dirty }) =>
    $dirty ? theme.colorTool.alpha(theme.primary, 0.1) : 'transparent'};

  background-color: var(--background) !important;

  &.dropdown-toggle,
  .mdi-icon {
    color: ${({ theme }) => theme.colorText} !important;
  }

  &:focus,
  &:hover,
  &:active,
  &:focus:active {
    background-color: var(--background) !important;
    &.dropdown-toggle,
    .mdi-icon {
      color: ${({ theme }) => theme.colorText} !important;
    }
  }

  &.Mui-disabled {
    opacity: 0.3 !important;
  }

  .MuiMenu-list & {
    padding: 5px 15px;
  }
`;
