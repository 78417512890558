import PropTypes from '+prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';

import styled, { withTheme } from 'styled-components';

import mapThemeSlice from '+theme/slices/map';
import { useThemeSlice } from '+theme/util';

import Visualization from '../map-visualizarion';

const Div = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const options = {
  visualizationSpeed: 5,
  minDelayBetweenEvents: 300,

  map: {
    center: [35, 0],
    zoom: 2.25,
    zoomDelta: 0.25,
    maxBounds: [
      [110, 210],
      [-110, -210],
    ],
    style: {
      fillColor: 'rgba(0,0,0,0.16)',
      color: '#26272A',
    },
  },
  render: {
    // clearDirtyAlpha: false,
    clearAlpha: 0.22,
    alpha: 0.9,
  },
  board: {
    render: {
      // clearDirtyAlpha: false,
      clearAlpha: 0.15,
      alpha: 0.92,
    },
  },
};

const Map = ({ data, width, height, onData, theme, refresh }) => {
  const [map, setMap] = useState();
  const divRef = useRef();
  const mapTheme = useThemeSlice(mapThemeSlice);

  useEffect(() => {
    if (!divRef.current) {
      setMap(null);
      return undefined;
    }

    const _map = new Visualization({
      container: divRef.current,
      ...options,
      render: {
        ...options.render,

        globalCompositeOperation: theme.name === 'dark' ? 'lighter' : 'darker',
      },
      board: {
        // min bits for one packets and max bits
        extents: [624, 277056],
        // size of particle in px
        range: [8, 32],
        ...options.board,
        render: {
          ...(options.board && options.board.render),

          globalCompositeOperation:
            theme.name === 'dark' ? 'lighter' : 'darker',
        },
        style: {
          ...(options.board && options.board.style),
          color: mapTheme.boardTrackColor,
        },
      },
    });
    setMap(_map);

    return () => {
      if (_map) {
        _map.on('processedData', null);
        _map.destroy();
        setMap(null);
      }
    };
  }, [divRef]);

  useEffect(() => {
    if (map) {
      map.data(data);
    }
  }, [map, data]);

  useEffect(() => {
    if (map) {
      map.reset();
    }
  }, [map, refresh]);

  useEffect(() => {
    if (!map) {
      return undefined;
    }

    map.on('processedData', (completed) => {
      onData(completed, map.categories);
    });

    return () => {
      if (!map) {
        return;
      }

      map.on('processedData', null);
    };
  }, [map, onData]);

  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [map, width, height]);

  return useMemo(() => <Div ref={divRef} />, []);
};

Map.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape()),
  onData: PropTypes.func,
  refresh: PropTypes.number,
};

Map.defaultProps = {
  data: [],
  width: 100,
  height: 100,
  onData: null,
  refresh: 0,
};

export default withTheme(Map);
