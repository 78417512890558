import { useCallback, useMemo } from 'react';
import { actions, ensurePluginOrder, functionalUpdate } from 'react-table';

actions.resetAggregates = 'resetAggregates';
actions.setAllAggregates = 'setAllAggregates';
actions.setAggregate = 'setAggregate';

const emptyFn = () => {};

const reducer = (state, action, previousState, instance) => {
  if (action.type === actions.init) {
    return {
      aggregates: [],
      ...state,
    };
  }

  if (action.type === actions.resetAggregates) {
    return {
      ...state,
      aggregates: instance.initialState.aggregates || [],
    };
  }

  if (action.type === actions.setAllAggregates) {
    const { aggregates } = action;
    return {
      ...state,
      aggregates,
    };
  }

  if (action.type === actions.setAggregate) {
    const { columnId, aggregate } = action;
    const { allColumns } = instance;

    const column = allColumns.find((d) => d.id === columnId);

    if (!column) {
      throw new Error(
        `React-Table: Could not find a column with id: ${columnId}`,
      );
    }

    const previousAggregate = state.aggregates.find((d) => d.id === columnId);

    const newAggregate = functionalUpdate(
      aggregate,
      previousAggregate && previousAggregate.value,
    );

    if (newAggregate === undefined) {
      return {
        ...state,
        aggregates: state.aggregates.filter((d) => d.id !== columnId),
      };
    }

    if (previousAggregate) {
      return {
        ...state,
        aggregates: state.aggregates.map((d) => {
          if (d.id === columnId) {
            return { id: columnId, value: newAggregate };
          }
          return d;
        }),
      };
    }

    return {
      ...state,
      aggregates: [...state.aggregates, { id: columnId, value: newAggregate }],
    };
  }

  return state;
};

const useInstance = (instance) => {
  const {
    allColumns: allColumnsProp,
    disableAggregators,
    state: { aggregates },
    plugins,
    dispatch,
  } = instance;

  ensurePluginOrder(plugins, ['useAggregators'], 'useGroupBy');

  const resetAggregates = useCallback(() => {
    dispatch({ type: actions.resetAggregates });
  }, [dispatch]);

  const setAllAggregates = useCallback(
    (value) => {
      dispatch({ type: actions.setAllAggregates, aggregates: value });
    },
    [dispatch],
  );

  const setAggregate = useCallback(
    (columnId, value) => {
      dispatch({ type: actions.setAggregate, columnId, aggregate: value });
    },
    [dispatch],
  );

  const allColumns = useMemo(
    () =>
      allColumnsProp?.map((column) => {
        const found = aggregates.find((item) => item.id === column.id);
        column.aggregate = found?.value;
        column.canAggregate =
          !column.isGrouped &&
          !column.disableAggregators &&
          !disableAggregators;
        column.setAggregate = column.canAggregate
          ? (val) => setAggregate(column.id, val)
          : emptyFn;
        return column;
      }),
    [allColumnsProp, aggregates, setAggregate, disableAggregators],
  );

  Object.assign(instance, {
    allColumns,
    resetAggregates,
    setAllAggregates,
    setAggregate,
  });
};

export const useGroupByAggregators = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useGroupByAggregators.pluginName = 'useGroupByAggregators';
