const set = (value) => {
  const parts = window.location.hostname.split('.');
  let domain = window.location.hostname;
  if (parts.length > 2) {
    domain = `.${parts.slice(1).join('.')}`;
  }

  document.cookie = `netoUITheme=${value};path=/;domain=${domain};max-age=31536000`;

  return value;
};

const remove = () => {
  return set('');
};

const get = () => {
  const name = 'netoUITheme=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const found = decodedCookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith(name));

  return (found || '').split('=')[1] || set('');
};

export const themeCookie = {
  get,
  set,
  remove,
};
