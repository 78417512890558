import { CustomType } from '@/models/CustomType';

import dayjs from '+utils/dayjs';
import makeArr from '+utils/makeArr';

export const paramsToUi = ({ tz, ...params } = {}) => {
  const nql = [...makeArr(params.nql)];
  if (!nql.length) {
    nql.push('');
  }
  const customers = makeArr(params.customers).filter(Boolean);
  const ipLabelContext = params.labelContext?.show
    ? params.labelContext?.ip
    : params.labelContext?.show;
  delete params.refresher;

  let { from, to } = params;

  if (params.period.type !== CustomType) {
    from = +dayjs().startOf('day');
    to = +dayjs().endOf('day');
  }

  return { ...params, nql, customers, ipLabelContext, from, to };
};

export const uiToParams = ({ ipLabelContext, tz, ...values } = {}) => {
  const nql = makeArr(values.nql).filter((item) => !!item?.trim());
  const customers = makeArr(values.customers).filter(Boolean);
  const labelContext = ipLabelContext
    ? {
        show: true,
        ip: ipLabelContext,
        port: values.labelContext?.port || 'name',
      }
    : {
        show: false,
        ip: values.labelContext?.ip || 'name',
        port: values.labelContext?.port || 'name',
      };
  delete values.refresher;
  return { ...values, nql, customers, labelContext };
};
