import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import styled from 'styled-components';

import MenuIcon from 'mdi-react/MenuIcon';
import MonitorIcon from 'mdi-react/MonitorIcon';
import MonitorOffIcon from 'mdi-react/MonitorOffIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import ContextMenu from '+components/ContextMenu';
import { CardMixin, Col, LayoutSizes, Row } from '+components/Layout';
import { TriggerMenu, withMenu } from '+components/Menu';

import FieldLabel from './Components/FieldLabel';
import LowerRowCustom from './Components/LowerRowCustom';
import LowerRowRegex from './Components/LowerRowRegex';
import LowerRowReplace from './Components/LowerRowReplace';
import MethodSelector, { METHOD_OPTIONS } from './Components/MethodSelector';
import TransformTextField from './TransformTextField';

const Container = styled(Col)`
  margin-top: 10px;
  ${(p) => (p.$card ? CardMixin : '')}
`;

const DEFAULT_METHOD_OBJECT = {
  replace: { '': '' },
  regex: { match: '', replace: '' },
  function: { function: '' },
  context: '',
};

const detectMethodType = (data) => {
  const keys = Object.keys(data);
  if (keys.includes('regex')) {
    return METHOD_OPTIONS.regex.value;
  }
  if (keys.includes('function')) {
    return METHOD_OPTIONS.function.value;
  }
  if (keys.includes('replace')) {
    return METHOD_OPTIONS.replace.value;
  }
  return METHOD_OPTIONS.context.value;
};

const LowerRowContext = (props) => {
  const { disabled } = props;
  return (
    <Col gap="6px">
      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <FieldLabel $disabled={disabled}>Method</FieldLabel>
        </Col>
      </Row>

      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={2.5}>
          <MethodSelector disabled={disabled} {...props} />
        </Col>
      </Row>
    </Col>
  );
};

LowerRowContext.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

const SourceField = (props) => {
  const { onChange, removeField, data, index } = props;

  const [method, setMethod] = useState(detectMethodType(data));
  const [enabled, toggleEnabled] = useToggle(data?.enabled ?? true);

  const handleValueChange = useCallback(
    (fieldKey, value) => {
      const copy = { ...data };
      copy[fieldKey] = value;
      onChange(index, copy);
    },
    [data, onChange, index],
  );

  // handle removal of previous method object
  useEffect(() => {
    const copy = { ...data };
    Object.keys(METHOD_OPTIONS).forEach((option) => {
      if (method === option) {
        if (!copy?.[method]) {
          copy[method] = DEFAULT_METHOD_OBJECT[method];
        }
      } else if (option !== METHOD_OPTIONS.context.value) {
        delete copy?.[option];
      }
    });
    onChange(index, copy);
  }, [method]);

  useEffect(() => {
    handleValueChange('enabled', enabled);
  }, [enabled]);

  // experienced issues with removeField()'s closure not updating properly when I memoized this field,
  // leading to changes in other fields being reverted on delete, so no useMemo here.
  const items = [
    {
      icon: enabled ? <MonitorOffIcon /> : <MonitorIcon />,
      text: `${enabled ? 'Disable' : 'Enable'} Field`,
      onClick: toggleEnabled,
    },
    {
      icon: <TrashCanOutlineIcon />,
      text: 'Remove Field',
      onClick: () => {
        removeField(index);
      },
    },
  ];

  return (
    <Container $card>
      <Row gap={LayoutSizes.groupGap}>
        <Col item xs={5}>
          <FieldLabel $disabled={!enabled}>Source Field</FieldLabel>
        </Col>
        <Col item xs={5}>
          <FieldLabel $disabled={!enabled}>Context</FieldLabel>
        </Col>
        <Col item xs={1.5} alignItems="end">
          <ContextMenu items={items} text={<MenuIcon />} />
          <TriggerMenu />
        </Col>
      </Row>

      <Row alignItems="center" gap={LayoutSizes.groupGap}>
        <Col item xs={5}>
          <TransformTextField
            fieldKey="sourceField"
            defaultValue={data.sourceField || ''}
            onChange={handleValueChange}
            disabled={!enabled}
          />
        </Col>
        <Col item xs={5}>
          <TransformTextField
            fieldKey="context"
            defaultValue={data?.context || ''}
            onChange={handleValueChange}
            disabled={!enabled}
          />
        </Col>
        <Col item xs={1} />
      </Row>

      <Row />
      <Container>
        {method === METHOD_OPTIONS.context.value && (
          <LowerRowContext
            method={method}
            setMethod={setMethod}
            disabled={!enabled}
          />
        )}
        {method === METHOD_OPTIONS.replace.value && (
          <LowerRowReplace
            data={data.replace || DEFAULT_METHOD_OBJECT.replace}
            onChange={handleValueChange}
            method={method}
            setMethod={setMethod}
            disabled={!enabled}
          />
        )}
        {method === METHOD_OPTIONS.regex.value && (
          <LowerRowRegex
            data={data?.regex || DEFAULT_METHOD_OBJECT.regex}
            onChange={handleValueChange}
            method={method}
            setMethod={setMethod}
            disabled={!enabled}
          />
        )}
        {method === METHOD_OPTIONS.function.value && (
          <LowerRowCustom
            data={data.function || DEFAULT_METHOD_OBJECT.function}
            onChange={handleValueChange}
            method={method}
            setMethod={setMethod}
            disabled={!enabled}
          />
        )}
      </Container>
    </Container>
  );
};

SourceField.propTypes = {
  onChange: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
};

export default withMenu(SourceField);
