import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Dashboard from '@/pages/Dashboards/Dashboard';
import DashboardModeTypes from '@/pages/Dashboards/shared/DashboardModeTypes';

import NoFlowHomepage from '+components/NoFlowHomepage';
import { useHasFlow } from '+hooks/useHasFlow';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';

const HomePage = () => {
  const dispatch = useDispatch();

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);

  const isCustomerFetching = useSelector(customerSelectors.isFetching);

  const profile = useSelector(profileSelectors.getProfile);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const hasFlow = useHasFlow();

  const isDashboardsFetching = useSelector(dashboardsSelectors.isFetching);
  const [companyHomepageDashboard] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'companyHomepageDashboardId',
  );
  const [userHomepageDashboard] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'userHomepageDashboardId',
  );
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  const homepageDashboard = useMemo(() => {
    if (userHomepageDashboard && dashboardsMeta?.[userHomepageDashboard]) {
      return userHomepageDashboard;
    }
    if (
      isRolesUiSettingsEnabled &&
      userRoleUiSettings?.homepageDashboardId &&
      dashboardsMeta?.[userRoleUiSettings?.homepageDashboardId]
    ) {
      return userRoleUiSettings.homepageDashboardId;
    }
    return companyHomepageDashboard;
  }, [
    companyHomepageDashboard,
    dashboardsMeta,
    userHomepageDashboard,
    userRoleUiSettings,
    isRolesUiSettingsEnabled,
  ]);

  const isFetching = isDashboardsFetching || isCustomerFetching;

  useLoadingIndicator(isFetching, 'HomePage');

  useEffect(() => {
    if (!isAllMetaFetched) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [isAllMetaFetched]);

  return !hasFlow ? (
    <NoFlowHomepage />
  ) : (
    <Dashboard id={homepageDashboard} mode={DashboardModeTypes.page} />
  );
};

export default HomePage;
