import PropTypes from '+prop-types';
import { Fragment } from 'react';

import FieldsSection from '+components/form/FieldsSection';
import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const AzureVNetFields = ({ disabled }) => {
  const regions = useProviderRegions('azure');
  return (
    <Fragment>
      <Field
        name="region"
        label="Virtual Network Region"
        component={SelectField}
        validate={validateRequired}
        options={regions}
        parse={normalizeSelectValue}
        disabled={disabled}
        required
      />

      <Field
        name="containername"
        label="Container Name"
        component={TextField}
        type="text"
        maxLength={255}
        validate={validateRequired}
        disabled={disabled}
        helperText="Storage Account's Container Name"
        defaultValue="insights-logs-flowlogflowevent"
        required
      />

      <Field
        name="subscriptionid"
        label="Subscription ID"
        component={TextField}
        type="text"
        maxLength={255}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="resourcegroup"
        label="Network Watcher Resource Group"
        component={TextField}
        type="text"
        maxLength={255}
        validate={validateRequired}
        disabled={disabled}
        defaultValue="NETWORKWATCHERRG"
        required
      />

      <Field
        name="networkwatcher"
        label="Network Watcher Name"
        component={TextField}
        type="text"
        maxLength={255}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="flowlogname"
        label="Flow Log Name"
        component={TextField}
        type="text"
        maxLength={255}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <FieldsSection label="Authentication">
        <Field
          name="accountname"
          label="Storage Account Name"
          component={TextField}
          type="text"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="accountkey"
          label="Access Key"
          component={TextField}
          type="password"
          validate={validateRequired}
          disabled={disabled}
          helperText="Storage Account's Access Key for authenticating to this stream"
          required
        />
      </FieldsSection>
    </Fragment>
  );
};

AzureVNetFields.propTypes = {
  disabled: PropTypes.bool,
};

AzureVNetFields.defaultProps = {
  disabled: false,
};

export default AzureVNetFields;
