import PropTypes from '+prop-types/index';
import {
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import SmoothScrollbar from 'smooth-scrollbar';
import styled from 'styled-components';

export const ScrollBarContext = createContext();

const Wrapper = forwardRef((props, ref) => {
  const {
    damping,
    thumbMinSize,
    renderByPixels,
    alwaysShowTracks,
    continuousScrolling,
    delegateTo,
    plugins,

    onScroll,
    children,
    ...tail
  } = props;

  const scrollRef = useRef(null);
  const onScrollRef = useRef(onScroll);
  const [element, setElement] = useState(null);
  const [context, setContext] = useState(null);

  const scrollConfig = {
    damping,
    thumbMinSize,
    renderByPixels,
    alwaysShowTracks,
    continuousScrolling,
    delegateTo,
    plugins,
  };

  const handleScroll = useCallback((status) => {
    onScrollRef.current?.(status, scrollRef.current);
  }, []);

  useEffect(() => {
    if (!element) {
      ref?.(null);
      setContext(null);
      return undefined;
    }

    scrollRef.current = SmoothScrollbar.init(element, scrollConfig);
    scrollRef.current.addListener(handleScroll);

    ref?.(scrollRef.current);

    setContext(scrollRef.current);

    return () => {
      scrollRef.current?.destroy();
    };
  }, [element]);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    Object.entries(scrollConfig).forEach(([key, value]) => {
      if (key === 'plugins') {
        Object.entries(scrollConfig.plugins || {}).forEach(
          ([pluginKey, pluginValue]) => {
            scrollRef.current.updatePluginOptions(pluginKey, pluginValue);
          },
        );

        return;
      }

      scrollRef.current.options[key] = value;
    });

    scrollRef.current.update();
  }, Object.values(scrollConfig));

  return (
    <ScrollBarContext.Provider value={context}>
      <section data-scrollbar="" ref={setElement} {...tail}>
        <div>{children}</div>
      </section>
    </ScrollBarContext.Provider>
  );
});

Wrapper.propTypes = {
  children: PropTypes.children.isRequired,

  damping: PropTypes.number,
  thumbMinSize: PropTypes.number,
  renderByPixels: PropTypes.bool,
  alwaysShowTracks: PropTypes.bool,
  continuousScrolling: PropTypes.bool,
  delegateTo: PropTypes.instanceOf(EventTarget),
  plugins: PropTypes.shape(),
  onScroll: PropTypes.func,
};
Wrapper.defaultProps = {
  damping: 0.1,
  thumbMinSize: 20,
  renderByPixels: true,
  alwaysShowTracks: false,
  continuousScrolling: true,
  delegateTo: null,
  plugins: null,
  onScroll: null,
};

export default styled(Wrapper)`
  .scrollbar-track {
    background: transparent;
    transition: opacity 0.3s 0.5s;
  }
  &:hover .scrollbar-track {
    opacity: 1;
    transition: opacity 0.3s;
  }

  .scrollbar-track-x {
    height: 8px;
  }
  .scrollbar-track-y {
    width: 8px;
  }

  .scrollbar-thumb {
    background: ${({ theme }) => theme.scrollBackgroundColor};
  }

  .scrollbar-thumb-x {
    height: 4px;
    top: 50%;
    margin-top: -2px;

    transition:
      height 0.3s,
      margin-top 0.3s;
    &:hover,
    &:active {
      height: 8px;
      margin-top: -4px;
    }
  }
  .scrollbar-thumb-y {
    width: 4px;
    left: 50%;
    margin-left: -2px;

    transition:
      width 0.3s,
      margin-left 0.3s;
    &:hover,
    &:active {
      width: 8px;
      margin-left: -4px;
    }
  }

  &::after,
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    pointer-events: none;
  }

  &::after {
    content: ${({ $bottomShadow }) => ($bottomShadow ? '""' : null)};
    bottom: 0;
    background: radial-gradient(
        farthest-side at 50% 100%,
        rgba(34, 34, 34, ${({ theme }) => (theme.name === 'light' ? 0.4 : 1)}),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  }

  &::before {
    content: ${({ $topShadow }) => ($topShadow ? '""' : null)};
    top: 0;
    background: radial-gradient(
      farthest-side at 50% 0,
      rgba(34, 34, 34, ${({ theme }) => (theme.name === 'light' ? 0.4 : 1)}),
      rgba(0, 0, 0, 0)
    );
  }
`;
