import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled from 'styled-components';

import useDefaultPropsHSX from '+components/charts/common/defaultPropsHSX';
import { defaultColorVariety, lang } from '+components/charts/common/utils';
import useExportingFilename from '+hooks/useExportingFilename';

import { Highcharts } from './common/highcharts';

export const nodeFormatter = (point, nodeNameFormatter) => `
  <b class="tooltip-row-marker highcharts-color-${point.colorIndex}">
    ●
  </b>
  ${nodeNameFormatter?.(point) ?? point.name}
`;

const getDefaultTooltipFormatter = (nodeNameFormatter) =>
  function () {
    const { point } = this;

    if (point.isLink) {
      return `
      <div style="display: flex; flex-wrap: nowrap; align-items: center; gap: 5px">
        ${nodeFormatter(point.fromNode, nodeNameFormatter)}
        <span style="font-size: 14px; margin-top: -3px;">→</span>
        ${nodeFormatter(point.toNode, nodeNameFormatter)}
      </div>
    `;
    }

    return `
    <div style="display: flex; flex-wrap: nowrap; align-items: center; gap: 5px">
      ${nodeFormatter(point, nodeNameFormatter)}
      <span style="margin-left: 5px">
        ${
          point.node.children.length > 0
            ? `${point.node.children.length} children`
            : ''
        }
      </span>
    </div>
  `;
  };

const toggleCollapse = (item) => {
  item.__toggleCollapse();
};

const handleClickPoint = (onClickPoint) =>
  function (event) {
    const { target } = event;
    if (target?.classList?.contains('highcharts-internal-node')) {
      toggleCollapse(this);
      return;
    }

    onClickPoint?.call(this, event);
  };

const collapseTrick = (point) => {
  if (!point) {
    return;
  }

  const prototype = Object.getPrototypeOf(point);
  if (!(prototype?.toggleCollapse && !prototype?.__toggleCollapse)) {
    return;
  }

  prototype.__toggleCollapse = prototype.toggleCollapse;
  prototype.toggleCollapse = () => {};
  const originalGetClassName = prototype.getClassName;
  prototype.getClassName = function () {
    const originalClassName = originalGetClassName.call(this);
    return `${originalClassName}${
      this.collapsed ? ' highcharts-internal-node-collapsed' : ''
    }`;
  };
};

const TreeGraphChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    data,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    dataLabelFormatter,
    tooltipFormatter: tooltipFormatterProp,
    hideTitle,
    hideSubtitle,
    onClickPoint,
  } = props;

  const chartRef = useRef(null);

  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  const doClickPoint = useMemo(
    () =>
      handleClickPoint(function (event) {
        onClickPoint?.call(this, event, chartRef.current);
      }),
    [onClickPoint],
  );

  const tooltipFormatter = useMemo(
    () =>
      tooltipFormatterProp || getDefaultTooltipFormatter(dataLabelFormatter),
    [tooltipFormatterProp, dataLabelFormatter],
  );

  const plotOptions = useMemo(
    () => ({
      treegraph: {
        animation: {
          duration: 500,
        },
        clip: false,
        crisp: false,
        marker: {
          symbol: 'circle',
          radius: 7,
        },
        dataLabels: {
          align: 'left',
          x: 20,
          crop: false,
          overflow: 'none',
          formatter() {
            const { point } = this;
            return dataLabelFormatter?.(point) ?? point.name;
          },
          useHTML: true,
          padding: 0,
          allowOverlap: true,
        },
        reversed: true,
        collapseButton: {
          enabled: false,
        },
        point: {
          events: {
            click: doClickPoint,
          },
        },
      },
    }),
    [dataLabelFormatter, doClickPoint],
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    const nodes = chart.series?.[0]?.data;
    (data || []).forEach((item) => {
      const found = nodes?.find((node) => node.id === item.id);
      item.collapsed = found?.collapsed || item.collapsedState;
      if (item.collapsedState && found?.collapsed === false) {
        item.collapsed = false;
      }
    });

    // TODO: use setData after this is fixed: https://github.com/highcharts/highcharts/issues/19524
    chart.series?.[0]?.update({ data }, false);
    chart.redraw();

    // Workaround for preventing default toggleCollapse method for point
    const point = chart.series?.[0]?.points?.[0];
    collapseTrick(point);
  }, [data]);

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  const series = useMemo(
    () =>
      !!data?.length && (
        <HSX.Series
          requiresAxis={false}
          type="treegraph"
          id="treegraph-series"
          key="treegraph-series"
          keys={['id', 'parent', 'name']}
          name="treegraph"
        />
      ),
    [!data?.length],
  );

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(
          className,
          'treegraph-chart',
          `p-${colorVariety ?? defaultColorVariety}`,
          {
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
          },
        )}
        plotOptions={plotOptions}
        callback={onChartCallback}
        __colorVariety={colorVariety}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML margin={0}>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>{lang.loading}</HSX.Loading>

        <HSX.Tooltip
          formatter={tooltipFormatter}
          useHTML
          borderRadius={8}
          shadow={false}
          animation={false}
        />

        <HSX.Chart
          width={width}
          height={height}
          margin={[30, data?.length ? 170 : 20, 30, 20]}
          __type="treegraph"
          // animation // TODO: enable animation after this is fixed: https://github.com/highcharts/highcharts/issues/19524
        />

        <HSX.YAxis visible={false} />
        <HSX.XAxis visible={false} />

        {series}
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})`
  .highcharts-data-label.highcharts-tracker span:not(:empty) {
    line-height: 1;
  }
`;

TreeGraphChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart context.
   */
  context: PropTypes.string,
  /**
   * Outer pie series.
   */
  data: PropTypes.arrayOf(PropTypes.shape()),
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index.
   */
  colorIndex: PropTypes.number,
  /**
   * Tooltip formatter.
   */
  tooltipFormatter: PropTypes.func,
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * Data label formatter.
   */
  dataLabelFormatter: PropTypes.func,
};

TreeGraphChart.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  context: undefined,
  series: {},
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndex: undefined,
  tooltipFormatter: null,
  hideTitle: false,
  hideSubtitle: false,
  dataLabelFormatter: null,
};

export default memo(TreeGraphChart);
