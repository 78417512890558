import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  background-color: transparent;

  &.wizard__with-tabs .wizard__right-panel {
    padding-top: 80px;
  }

  @media screen and (max-width: 1200px) {
    .wizard__left-panel {
      width: 100%;
    }
    .wizard__right-panel {
      display: none;
    }
  }
`;
