import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import TextField from '+components/form/TextField';
import { validateEmails, validateRequired } from '+components/form/Validators';

const EmailFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.recipients"
      label="Recipients"
      component={TextField}
      type="textarea"
      autoComplete="new-password"
      validate={[validateRequired, validateEmails]}
      helperText="Comma separated list of email addresses"
      disabled={disabled}
      required
    />

    <Field
      name="config.cc"
      label="CC"
      component={TextField}
      type="textarea"
      autoComplete="new-password"
      validate={validateEmails}
      helperText="Comma separated list of email addresses"
      disabled={disabled}
    />

    <Field
      name="config.bcc"
      label="BCC"
      component={TextField}
      type="textarea"
      validate={validateEmails}
      helperText="Comma separated list of email addresses"
      disabled={disabled}
    />
  </Fragment>
);

EmailFields.propTypes = {
  disabled: PropTypes.bool,
};

EmailFields.defaultProps = {
  disabled: false,
};

export default EmailFields;
