import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ColorTypes } from '@/models/ColorTypes';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Alert from '+components/Alert';
import Button from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import Toggle from '+components/form/Toggle';
import { Col } from '+components/Layout';

import Context from '../Context';

const IdpSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { layout, permissions, isFetching, customer } = useContext(Context);

  const profile = useSelector(profileSelectors.getProfile);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);
  const samlProviderSettings = useSelector(
    customerSelectors.getSamlProviderSettings,
  );
  const resellerSamlProvider = useSelector(
    customerSelectors.getResellerSamlProvider,
  );

  const [showUseResellerSsoModal, setShowUseResellerSsoModal] = useState(false);
  const [showSamlModal, setShowSamlModal] = useState(false);
  const [showNewSamlForm, setShowNewSamlForm] = useState(false);

  const onUseResellerSso = useCallback(() => {
    setShowUseResellerSsoModal(true);
  }, []);

  const onUseResellerSsoCancel = useCallback(() => {
    setShowUseResellerSsoModal(false);
  }, []);

  const onUseResellerSsoConfirm = useCallback(() => {
    setShowUseResellerSsoModal(false);
    if (customer.useResellerSso) {
      dispatch(customerActions.disableResellerSso(customer.shortname));
    } else {
      dispatch(customerActions.enableResellerSso(customer.shortname));
    }
  }, [customer.shortname, customer.useResellerSso]);

  const onSamlChange = useCallback(() => {
    setShowSamlModal(true);
  }, []);

  const onSamlChangeCancel = useCallback(() => {
    setShowSamlModal(false);
  }, []);

  const onSamlChangeConfirm = useCallback(() => {
    if (samlProvider.alias) {
      dispatch(customerActions.deleteSamlProvider());
      setShowNewSamlForm(false);
    } else {
      navigate(`${RoutePaths.samlSettings}`);
      setShowNewSamlForm((prevValue) => !prevValue);
    }
  }, [samlProvider]);

  useEffect(() => {
    if (samlProvider?.alias && showNewSamlForm) {
      setShowNewSamlForm(false);
    }
  }, [samlProvider?.alias, showNewSamlForm]);

  useEffect(() => {
    dispatch(customerActions.requestSamlProviderSettings());
  }, []);

  useEffect(
    () => {
      if (customer.shortname && samlProvider?.alias) {
        dispatch(
          customerActions.requestSamlProviderMetadataXml({
            shortname: customer.shortname,
          }),
        );
      }
    },
    [customer.shortname, samlProvider], // we need to update metadata xml when samlProvider is changed
  );

  useEffect(
    () => {
      dispatch(customerActions.requestSamlProvider());
      dispatch(customerActions.requestResellerSamlProvider());
    },
    [customer.useResellerSso], // we need to update saml providers each time useResellerSso changed
  );

  const resellerSsoToggleEnabled =
    (!samlProvider?.alias || samlProvider?.alias !== customer.shortname) &&
    (!profile.idp || profile.idp === customer.shortname) && // reseller's idp user can't disable reseller sso cause he will be removed with this action
    permissions?.update &&
    !isFetching;

  const ssoToggleEnabled =
    (!samlProvider?.alias || samlProvider?.alias === customer.shortname) &&
    permissions?.update &&
    !isFetching;

  const showSsoEditButton =
    (samlProvider?.alias === customer.shortname || showNewSamlForm) &&
    !!Object.keys(samlProviderSettings).length &&
    permissions?.update;

  return (
    <Fragment>
      {resellerSamlProvider?.alias && (
        <Col
          className="form form--horizontal"
          width={`calc(${layout.marginLeft} + ${layout.width})`}
          item
          container={false}
        >
          {customer.useResellerSso && (
            <Alert style={{ marginLeft: '140px' }}>
              SSO controlled by parent {resellerSamlProvider?.alias}.
            </Alert>
          )}

          <Group>
            <Label disabled={!resellerSsoToggleEnabled}>Parent SSO</Label>
            <FieldContainer>
              <Toggle
                name="useResellerSso"
                checked={customer.useResellerSso}
                type="checkbox"
                checkedLabel="Enabled"
                disabled={!resellerSsoToggleEnabled}
                onChange={onUseResellerSso}
              />
            </FieldContainer>
            <Description>
              Users can authenticate against parent {resellerSamlProvider.alias}
              SSO configuration
            </Description>
          </Group>
        </Col>
      )}

      <Col
        className="form form--horizontal"
        width={`calc(${layout.marginLeft} + ${layout.width})`}
        item
        container={false}
      >
        <Group>
          <Label disabled={!ssoToggleEnabled}>SAML Single Sign-on</Label>
          <FieldContainer>
            <Toggle
              name="sso"
              checked={
                samlProvider?.alias === customer.shortname || showNewSamlForm
              }
              type="checkbox"
              checkedLabel="Enabled"
              disabled={!ssoToggleEnabled}
              onChange={onSamlChange}
            />
          </FieldContainer>
          <Description>
            SAML Single Sign-on enables users to authenticate using the same
            identity authentication service utilized for other internal and
            external services.
          </Description>
        </Group>
      </Col>

      {showSsoEditButton && (
        <Col
          marginLeft={layout.marginLeft}
          marginBottom="20px"
          width={layout.width}
          item
          container={false}
        >
          <Button
            onClick={() => {
              navigate(`${RoutePaths.samlSettings}`);
            }}
          >
            Edit SAML Settings
          </Button>
        </Col>
      )}

      {showUseResellerSsoModal && (
        <ConfirmModal
          item="Parent SSO"
          confirmButtonText={customer.useResellerSso ? 'Disable' : 'Enable'}
          confirmButtonColor={ColorTypes.primary}
          whyAsking={
            customer.useResellerSso
              ? 'Parent Single Sign-On will be disabled, all linked users will be removed and required to sign in via their email and password.'
              : `Single Sign-On will be controlled by parent ${resellerSamlProvider.alias} configuration.`
          }
          onToggle={onUseResellerSsoCancel}
          onConfirm={onUseResellerSsoConfirm}
          isOpen
        />
      )}

      {showSamlModal && (
        <ConfirmModal
          item="SAML Single Sign-on"
          confirmButtonText={
            samlProvider?.alias || showNewSamlForm ? 'Disable' : 'Enable'
          }
          confirmButtonColor={ColorTypes.primary}
          whyAsking={
            samlProvider?.alias || showNewSamlForm
              ? 'Single Sign-on configuration will be removed, all linked users will be removed and required to sign in via their email and password.'
              : 'Single Sign-on configuration is needed, and all users will be required to sign in via this single sign-on provider.'
          }
          onToggle={onSamlChangeCancel}
          onConfirm={onSamlChangeConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default IdpSection;
