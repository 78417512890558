import createSlice from '@/redux/util/createSlice';

import { unAuthMessage } from '@/middleware/backendClient';

import * as toast from '+components/toast';

let unAuthErrorWasShown = false;
let networkErrorWasShown = false;

const isNetworkError = (message) => {
  if (message?.message === 'Websocket Connection') {
    return true;
  }
  return [
    'Network Error',
    'The request was made but no response was received from the upstream API',
    'Gateway Timeout',
  ].includes(message?.details);
};
const isUnAuthError = (message) =>
  message?.details === 'Request failed with status code 401';
const isAuditLogFailed = (response) =>
  response?.headers?.['x-neto-audit-id'] === 'null';
const isForbiddenError = (message) => message?.details === 'Forbidden';

const getToastId = (message) => {
  if (typeof message?.message === 'string') {
    return message.message;
  }
  if (typeof message === 'string') {
    return message;
  }
  return null;
};

export const slice = createSlice({
  name: 'toasts',
  initialState: {},

  reducers: {
    log(state, { payload: message }) {
      toast.log(message);
    },

    success(state, { payload: message }) {
      toast.success(message);
    },

    info(state, { payload: message }) {
      toast.info(message);
    },

    warn(state, { payload: message }) {
      toast.warn(message);
    },

    error(state, { payload: message }) {
      const toastId = getToastId(message);

      if (isUnAuthError(message)) {
        if (!unAuthErrorWasShown) {
          unAuthErrorWasShown = true;
          toast.error(unAuthMessage, { toastId });
        }
        return;
      }

      if (isNetworkError(message)) {
        if (!networkErrorWasShown) {
          networkErrorWasShown = true;
          toast.error('Network Error', { toastId });
        }
        return;
      }

      if (isForbiddenError(message)) {
        // swallow forbidden errors per ENG-1012
        return;
      }

      unAuthErrorWasShown = false;
      networkErrorWasShown = false;
      toast.error(message, { toastId });
    },

    successWithAuditLogVerification(
      state,
      { payload: { message, response, showWarningOnly } },
    ) {
      const auditLogFailed = Array.isArray(response)
        ? response.some(isAuditLogFailed)
        : isAuditLogFailed(response);
      if (!showWarningOnly || (showWarningOnly && auditLogFailed)) {
        toast[auditLogFailed ? 'warn' : 'success']({
          message,
          details: auditLogFailed
            ? 'There was a problem logging this change to the audit log system'
            : '',
        });
      }
    },

    clearAll() {
      toast.clearAll();
    },
  },

  sagas: () => ({}),
});

export const { actions } = slice;

export default slice;
