import { put } from 'redux-saga/effects';

import { createSelector, createSlice, tryCancelSaga } from '@/redux/util';

export const FetchStates = {
  error: 'error',
  init: 'init',
  fetching: 'fetching',
  empty: 'empty',
  success: 'success',
};

const setStatus = (statusKey, status) => (state) => {
  state[statusKey] = status;
  if (status !== FetchStates.error) {
    state.error = null;
  }
  return state;
};

const initialState = {
  'plans-status': FetchStates.init,
  plans: [],
  'subscriptions-status': FetchStates.init,
  subscriptions: [],
  'chargebee-portal-session-status': FetchStates.init,
  'chargebee-portal-session': null,
  error: '',
};

const getUrl = (path) => `/billing/${path}`;

const slice = createSlice({
  name: 'billing',
  initialState,

  reducers: {
    getPlans: setStatus('plans-status', FetchStates.fetching),
    getPlansSuccess(state, { payload: { data: { data } = {} } = {} }) {
      setStatus(
        'plans-status',
        data ? FetchStates.success : FetchStates.empty,
      )(state);
      state.plans = data || [];
    },
    getSubscriptions: setStatus('status', FetchStates.fetching),
    getSubscriptionsSuccess(state, { payload: { data: { data } = {} } = {} }) {
      setStatus(
        'subscriptions-status',
        data ? FetchStates.success : FetchStates.empty,
      )(state);
      state.subscriptions = data || [];
    },
    createChargebeePortalSession: setStatus(
      'chargebee-portal-session-status',
      FetchStates.fetching,
    ),
    createChargebeePortalSessionSuccess(
      state,
      { payload: { data: { data } = {} } = {} },
    ) {
      setStatus(
        'chargebee-portal-session-status',
        data ? FetchStates.success : FetchStates.empty,
      )(state);
      state['chargebee-portal-session'] = data;
    },
    clearChargebeePortalSession(state) {
      setStatus('chargebee-portal-session-status', FetchStates.init)(state);
      state['chargebee-portal-session'] = null;
    },
    error(state, { payload: { message, statusKey } }) {
      setStatus(statusKey, FetchStates.error)(state);
      state.error = message;
    },
  },

  sagas: (actions) => ({
    *[actions.getPlans]() {
      yield tryCancelSaga(
        'get',
        {
          successAction: actions.getPlansSuccess,
          errorAction: actions.fail,
          // toasts: {
          //   error: 'Error fetching plans',
          // },
          *error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'plans-status',
              }),
            );
          },
        },
        getUrl('plans'),
      );
    },
    *[actions.getSubscriptions]() {
      yield tryCancelSaga(
        'get',
        {
          successAction: actions.getSubscriptionsSuccess,
          errorAction: actions.fail,
          // toasts: {
          //   error: 'Error fetching subscriptions',
          // },
          *error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'subscriptions-status',
              }),
            );
          },
        },
        getUrl('subscriptions'),
      );
    },
    *[actions.createChargebeePortalSession]() {
      yield tryCancelSaga(
        'post',
        {
          successAction: actions.createChargebeePortalSessionSuccess,
          errorAction: actions.fail,
          // toasts: {
          //   error: 'Error fetching detection models docs',
          // },
          *error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'chargebee-portal-session-status',
              }),
            );
          },
        },
        getUrl('portal-sessions'),
      );
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) =>
        state['plans-status'] === FetchStates.fetching ||
        state['subscriptions-status'] === FetchStates.fetching ||
        state['chargebee-portal-session-status'] === FetchStates.fetching,
    ),
    getPlans: createSelector([getState], (state) => state.plans),
    getSubscriptions: createSelector(
      [getState],
      (state) => state.subscriptions,
    ),
    getChargebeePortalSession: createSelector(
      [getState],
      (state) => state['chargebee-portal-session'],
    ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
