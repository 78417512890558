/* eslint-disable no-param-reassign */

import { ContextTypes } from '@/models/ContextTypes';

import { UniversalCell } from '+components/Table/Cells';
import { durationFormatter } from '+components/Table/Cells/formatters';
import {
  AnswersColumnFactory,
  BaseColumnFactory,
  GeoColumnFactory,
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  OwnerAsColumnFactory,
  StringsArrayColumnsFactory,
  TimestampColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  autoRemoveIfEmpty,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import sortByHelper from '+utils/sortByHelper';

// commented out columns can fallback on the default presentation (text)
export const Columns = {
  trafficType: 'traffictype',
  _source: '_source',
  _srcPort: '_srcPort',
  action: 'action',
  internal: 'internal',
  protocol: 'protocol',
  srcip: 'srcip',
  srcport: 'srcport',
  timestamp: 'timestamp',
  customer: 'customer',
  menu: 'menu',
  queryName: 'query.name',
  queryType: 'query.type',
  queryDepth: 'query.depth',
  rcode: 'rcode',
  answercount: 'answercount',
  datasrc: 'datasrc',
  answers: 'answers',
  type: 'type',
  _srcGeo: '_srcGeo',
  _srcOwnerAs: '_srcOwnerAs',
  _destination: '_destination',
  _dstPort: '_dstPort',
  _dstGeo: '_dstGeo',
  _dstOwnerAs: '_dstOwnerAs',
  bits: 'bits',
  bitsxrate: 'bitsxrate',
  bogondst: 'bogondst',
  bogonsrc: 'bogonsrc',
  dstasAsNumber: 'dstas.number',
  dstasAsOrg: 'dstas.org',
  dstip: 'dstip',
  dstipname: 'label.ip.name.dst',
  dstiprepCategories: 'dstiprep.categories',
  dstiprepCount: 'dstiprep.count',
  dstOwnerAsNumber: 'dstowneras.number',
  dstOwnerAsOrg: 'dstowneras.org',
  dstport: 'dstport',
  dstportname: 'label.port.name.dst',
  duration: 'duration',
  end: 'end',
  flowbrate: 'flowbrate',
  flowsrcip: 'flowsrcip',
  nexthop: 'nexthop',
  packets: 'packets',
  protocolint: 'protocolint',
  site: 'site',
  srcAsNumber: 'srcas.number',
  srcAsOrg: 'srcas.org',
  srcipname: 'label.ip.name.src',
  srciprepCategories: 'srciprep.categories',
  srcportname: 'label.port.name.src',
  srcOwnerAsNumber: 'srcowneras.number',
  srcOwnerAsOrg: 'srcowneras.org',
  start: 'start',
  tags: 'tags',
  tcpflags: 'tcpflags',
  trafficRecord: 'trafficRecord',
};

const tcpFlagsExtractor = (value) =>
  Object.keys(value || {})
    .filter((key) => value[key])
    .sort()
    .join('');

const sharedColumns = ({ labelContext, cxActionMenu }) => ({
  [Columns.action]: BaseColumnFactory({
    getCellProps: () => ({ style: { textAlign: 'center' } }),
    width: 100,
  }),
  [Columns.trafficType]: TrafficColumnFactory({
    Header: 'Traffic',
  }),
  [Columns.timestamp]: TimestampColumnFactory({ width: 160 }),
  [Columns._source]: LabelOrIpColumnFactory({
    Header: 'SRC IP',
    dataFieldName: 'srcip',
    labelFieldName: `label.ip.${labelContext.ip}.src`,
    showLabel: labelContext.show,
  }),
  [Columns._srcPort]: LabelOrPortColumnFactory({
    Header: 'SRC Port',
    dataFieldName: 'srcport',
    labelFieldName: `label.port.${labelContext.port}.src`,
    showLabel: labelContext.show,
  }),
  [Columns.srcip]: BaseColumnFactory({
    getCellProps: () => ({ style: { textAlign: 'right' } }),
    sortType: 'ip',
  }),
  [Columns.srcport]: NumberColumnFactory(),
  [Columns.protocol]: BaseColumnFactory({
    getCellProps: () => ({ style: { textAlign: 'center' } }),
    width: 80,
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
      enableLikeFilter: true,
    }),
    filter: 'selectFilter',
  }),
  [Columns.customer]: {
    Header: 'Account',
    width: 160,
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
  },
  [Columns.tags]: BaseColumnFactory({
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
  }),
  [Columns.datasrc]: BaseColumnFactory({
    trafficType: null,
  }),
  [Columns.site]: BaseColumnFactory(),
  [Columns.type]: StringsArrayColumnsFactory(),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
  [Columns.trafficRecord]: BaseColumnFactory({
    width: 600,
    Header: 'Traffic Record Preview',
    disableFilters: true,
    getCellProps: () => ({
      style: {
        flexWrap: 'wrap',
      },
    }),
  }),
  [Columns.internal]: BaseColumnFactory({
    width: 80,
  }),
  [Columns.srcportname]: BaseColumnFactory({
    width: 90,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
  }),
  [Columns.srcipname]: BaseColumnFactory({
    width: 110,
    getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
  }),
});

const dnsColumns = () => {
  const prepared = {
    [Columns.queryName]: BaseColumnFactory(),
    [Columns.queryType]: StringsArrayColumnsFactory(),
    [Columns.queryDepth]: BaseColumnFactory({
      width: 90,
    }),
    [Columns.answercount]: BaseColumnFactory({
      width: 90,
    }),
    [Columns.rcode]: StringsArrayColumnsFactory(),
    [Columns.answers]: AnswersColumnFactory({
      width: 250,
      disableFilters: true,
      disableSortBy: true,
      disableGroupBy: true,
    }),
  };

  Object.values(prepared).forEach((column) => {
    column.trafficType = ContextTypes.dns;
  });

  return prepared;
};

const flowColumns = ({ labelContext }) => {
  const prepared = {
    [Columns._srcGeo]: GeoColumnFactory({
      Header: 'SRC Geo',
      field: 'srcgeo',
    }),
    [Columns._srcOwnerAs]: OwnerAsColumnFactory({
      Header: 'SRC Owner AS',
      field: 'srcowneras',
    }),
    [Columns._destination]: LabelOrIpColumnFactory({
      Header: 'Destination',
      dataFieldName: 'dstip',
      labelFieldName: `label.ip.${labelContext.ip}.dst`,
      showLabel: labelContext.show,
    }),
    [Columns._dstPort]: LabelOrPortColumnFactory({
      Header: 'DST Port',
      dataFieldName: 'dstport',
      labelFieldName: `label.port.${labelContext.port}.dst`,
      showLabel: labelContext.show,
    }),
    [Columns._dstGeo]: GeoColumnFactory({
      Header: 'DST Geo',
      field: 'dstgeo',
    }),
    [Columns._dstOwnerAs]: OwnerAsColumnFactory({
      Header: 'DST Owner AS',
      field: 'dstowneras',
    }),
    [Columns.bits]: NumberColumnFactory({
      width: 80,
    }),
    [Columns.bogondst]: BaseColumnFactory({
      width: 70,
    }),
    [Columns.bogonsrc]: BaseColumnFactory({
      width: 70,
    }),
    [Columns.bitsxrate]: NumberColumnFactory({
      width: 80,
    }),
    [Columns.dstasAsNumber]: NumberColumnFactory({
      width: 110,
    }),
    [Columns.dstasAsOrg]: BaseColumnFactory({
      width: 100,
    }),
    [Columns.dstip]: BaseColumnFactory({
      width: 110,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      sortType: 'ip',
    }),
    [Columns.dstipname]: BaseColumnFactory({
      width: 110,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.dstiprepCategories]: BaseColumnFactory({
      width: 130,
    }),
    [Columns.dstiprepCount]: NumberColumnFactory({
      width: 100,
    }),
    [Columns.dstport]: NumberColumnFactory({
      width: 90,
    }),
    [Columns.dstportname]: BaseColumnFactory({
      width: 90,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.duration]: NumberColumnFactory({
      width: 70,
      filter: withAutoRemove((rows, [id], filterValue) => {
        if (autoRemoveIfEmpty(filterValue)) {
          return rows;
        }

        const normalizedFilterValue = String(filterValue).toLowerCase();
        return rows.filter(({ values: { [id]: value } }) =>
          durationFormatter(value, 'milliseconds').includes(
            normalizedFilterValue,
          ),
        );
      }, autoRemoveIfEmpty),
    }),
    [Columns.end]: NumberColumnFactory({
      width: 140,
    }),
    [Columns.flowbrate]: NumberColumnFactory({
      width: 80,
    }),
    [Columns.flowsrcip]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      sortType: 'ip',
    }),
    [Columns.nexthop]: BaseColumnFactory(),
    [Columns.packets]: NumberColumnFactory(),
    [Columns.srcAsNumber]: NumberColumnFactory({
      width: 80,
      genericCell: true,
    }),
    [Columns.srcAsOrg]: BaseColumnFactory(),
    [Columns.srciprepCategories]: BaseColumnFactory({
      width: 140, // in order to fit the column header, not data
    }),
    [Columns.srcOwnerAsNumber]: NumberColumnFactory({
      width: 144, // in order to fit the column header, not data
    }),
    [Columns.srcOwnerAsOrg]: BaseColumnFactory({
      width: 140,
    }),
    [Columns.start]: NumberColumnFactory({
      width: 140,
    }),
    [Columns.tcpflags]: BaseColumnFactory({
      Header: Columns.tcpflags,
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
      Cell: UniversalCell(Columns.tcpflags),
      Filter: SelectColumnFilter({
        optionValueExtractor: (row, id) => Object.keys(row.values[id] || {}),
        optionLabel: (key) => (key === 'all' ? 'All' : key.toUpperCase()),
        sort: false,
      }),
      filter: withAutoRemove((rows, [id], filterValue) => {
        if (autoRemoveIfAll(filterValue)) {
          return rows;
        }

        return rows.filter(
          ({ values: { [id]: value } }) => !!value?.[filterValue.value],
        );
      }, autoRemoveIfAll),
      sortType: sortByHelper(tcpFlagsExtractor),
    }),
  };

  Object.values(prepared).forEach((column) => {
    column.trafficType = ContextTypes.flow;
  });

  return prepared;
};

export const columnsCollection = ({ labelContext, cxActionMenu }) => {
  const collection = {
    // ================== DNS Columns ==================
    ...dnsColumns(),
    // ================== Flow Columns ==================
    ...flowColumns({ labelContext }),
    // ================== Shared Columns ==================
    ...sharedColumns({ labelContext, cxActionMenu }),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
