import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import * as PropertiesTray from '@/models/PropertiesTray';

import { selectors as customerSelectors } from '@/redux/api/customer';

import Table from '+components/Table';
import { MenuColumnPropertiesTrayTrigger } from '+components/Table/Columns';
import { useAvailableExtractor } from '+hooks/useAvailableExtractor';
import { usePreparedColumns } from '+hooks/usePreparedColumns';
import getColumnsHelper from '+utils/getColumnsHelper';

import { Columns, columnsCollection } from './components/Columns';

const subtractedFields = ['original_object', 'new_object', 'impersonator'];

const defaultColumns = [
  Columns.timestamp,
  Columns.class,
  Columns.subclass,
  Columns.action,
  Columns.user,
  Columns.description,
  Columns.menu,
];

const defaultSortBy = [
  {
    id: 'timestamp',
    desc: true,
  },
];

const AuditLogTable = (props) => {
  const {
    columns: columnsProp,
    data,
    searchId,
    hideSubComponent,
    ...tail
  } = props;

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const columns = useMemo(() => {
    if (customer?.multi_account) {
      const indexOfCustomerColumn = columnsProp.indexOf(Columns.customer);
      if (indexOfCustomerColumn === -1) {
        return [...columnsProp, Columns.customer];
      }
    }
    return columnsProp;
  }, [columnsProp, customer]);

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnPropertiesTrayTrigger
        title={`Audit Log Details — ${original.id}`}
        dataType={PropertiesTray.DataTypes.record}
        recordType={ContextTypes.audit}
        value={original}
      />
    ),
    [searchId],
  );

  const overrideColumns = useMemo(
    () => columnsCollection({ cxActionMenu }),
    [cxActionMenu],
  );

  const collection = usePreparedColumns(ContextTypes.audit, {
    overrideColumns,
    ignore: [/^original_object/, /^new_object\./, /^impersonator\./],
  });

  const preparedColumns = useMemo(() => Object.keys(collection), [collection]);

  const getColumns = useMemo(() => getColumnsHelper(collection), [collection]);

  const fixedData = useMemo(
    () => (data || []).map(({ impersonator, ...item }) => item),
    [data],
  );

  const [tableColumns, available] = useAvailableExtractor({
    // row: fixedData?.[0],
    selectedColumns: columns,
    getColumns,
    preparedColumns,
    subtractedFields,
  });

  return (
    <Table
      data={fixedData}
      {...tail}
      columns={tableColumns}
      availableColumns={available}
    />
  );
};

AuditLogTable.propTypes = {
  ...Table.propTypes,
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.string),
  noDataText: PropTypes.string,
  hideSubComponent: PropTypes.bool,
  sortBy: PropTypes.arrayOf(PropTypes.shape()),
};

AuditLogTable.defaultProps = {
  columns: defaultColumns,
  data: [],
  noDataText: 'No audit logs received',
  hideSubComponent: false,
  sortBy: defaultSortBy,
};

export default memo(AuditLogTable);
