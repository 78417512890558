import { css } from 'styled-components';

export const ScrollBarMixin = css`
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-corner {
    /* add border to act as background-color */
    border-right-style: inset;
    /* sum viewport dimensions to guarantee border will fill scrollbar */
    border-right-width: calc(100vw + 100vh);
    border-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-color: ${({ theme }) => theme.scrollBackgroundColor} !important;
    }
  }

  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    border-color: ${({ theme }) => theme.scrollBackgroundColor};
  }
`;
