import { DateRangePicker as DateRangePickerOrigin } from 'react-date-range';

import styled from 'styled-components';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

export const DateRangePicker = styled(DateRangePickerOrigin)`
  .rdrNextPrevButton {
    background-color: ${({ theme }) =>
      theme.iconButtonInsideComponentsBackground};
    border-radius: 50%;

    &:hover {
      background-color: ${({ theme }) =>
        theme.iconButtonInsideComponentsBackground};
    }
  }

  .rdrPrevButton i,
  .rdrPprevButton i {
    border-right-color: ${({ theme }) => theme.iconButtonInsideComponentsText};
  }

  .rdrNextButton i {
    border-left-color: ${({ theme }) => theme.iconButtonInsideComponentsText};
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrDayPassive {
    visibility: hidden;
  }

  .rdrYearPicker select {
    padding-top: 0;
    padding-bottom: 0;
  }

  .rdrMonthPicker select {
    padding-top: 0;
    padding-bottom: 0;
  }

  .rdrCalendarWrapper {
    background-color: ${({ theme }) => theme.dateTimePickerCalendarBackground};
  }

  .rdrMonthAndYearWrapper {
    transform: translateY(27px);
    height: 0;
    padding: 0;
  }

  .rdrMonths {
    gap: 15px;
    background-color: ${globalFiltersTheme.separatorColor};
  }

  .rdrMonth {
    width: 296px;
    height: 315px;
    padding: 10px 15px;
    background-color: ${({ theme }) => theme.dateTimePickerCalendarBackground};

    border-radius: 4px;
    overflow: hidden;
  }

  .rdrMonthName {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colorText};
    padding: 5px 10px;
  }

  .rdrWeekDays {
    margin-bottom: 5px;
  }

  .rdrWeekDay {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.05rem;
    color: ${({ theme }) => theme.colorText};
    max-width: 38px;
    height: 40px;
  }

  .rdrDay {
    height: 36px;
    max-width: 38px;
    background-color: ${({ theme }) => theme.dateTimePickerCalendarBackground};

    &.rdrDayDisabled {
      .rdrDayNumber span {
        opacity: 0.34;
      }
    }
  }

  .rdrDayNumber {
    z-index: 10;

    span {
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colorText};
    }
  }

  .rdrDayToday {
    :after {
      display: none;
    }
  }

  .rdrDayHovered {
    .rdrDayNumber span {
      font-weight: unset;
      color: ${({ theme }) => theme.colorText} !important;
    }
  }

  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-color: transparent;
    background-color: ${({ theme }) =>
      theme.iconButtonInsideComponentsBackground};
  }

  .rdrStartEdge,
  .rdrInRange,
  .rdrEndEdge {
    background-color: ${({ theme }) => theme.iconButtonBackground};
  }

  .rdrStartEdge ~ .rdrDayNumber span,
  .rdrInRange ~ .rdrDayNumber span,
  .rdrEndEdge ~ .rdrDayNumber span {
    font-weight: 700;
    color: ${({ theme }) => theme.iconButtonText} !important;
  }

  .rdrDayStartPreview + .rdrDayNumber span,
  .rdrDayInPreview + .rdrDayNumber span,
  .rdrDayEndPreview + .rdrDayNumber span {
    font-weight: 700;
    color: ${({ theme }) => theme.iconButtonText} !important;
  }
`;
