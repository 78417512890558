import styled from 'styled-components';

export default styled.div`
  --title-icon-count: ${(props) => props.$titleIconsCount || 0};
  --title-icon-size: 25px;
  --chart-right-icon-offset: 120px;

  position: absolute;
  top: 1px;
  left: 0;
  height: 32px;
  z-index: 10;

  width: calc(
    100% - var(--title-icon-count) * var(--title-icon-size) - var(
        --chart-right-icon-offset
      )
  );
  transform: translateX(calc(var(--title-icon-count) * var(--title-icon-size)));

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;
