/* eslint-disable react/prop-types */
import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { actions as bgpActions } from '@/redux/api/bgp';

import Table from '+components/Table';
import { CellFlowKey, CellIpLabels } from '+components/Table/Cells';
import { durationFormatter } from '+components/Table/Cells/formatters';
import {
  BaseColumnFactory,
  MenuColumnContextMenu,
  MenuColumnFactory,
  ToggleColumnFactory,
} from '+components/Table/Columns';
import { NumberColumnFactory } from '+components/Table/Columns/NumberColumnFactory';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories/someOfFieldsFilter';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';

import BgpStateLabel from './BgpStateLabel';

const ContextMenu = (props) => {
  const { item, onEdit, onDelete, permissions } = props;

  return (
    <MenuColumnContextMenu
      title={item.addressremote}
      items={[
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => onEdit(props),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
          onClick: () => onDelete(props),
        },
      ]}
      dataTracking="bgp"
    />
  );
};

ContextMenu.propTypes = {
  item: PropTypes.shape().isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  permissions: PropTypes.shape(),
};

ContextMenu.defaultProps = {
  permissions: null,
};

const sortBy = [
  { id: 'addresslocal', desc: false },
  { id: 'addressremote', desc: false },
];

const BgpTable = (props) => {
  const {
    neighbors,
    enableNeighbor,
    disableNeighbor,
    toggleForm,
    toggleDelete,
    setCurrentItem,
    permissions,
  } = props;

  const [filters] = useGlobalFilters();
  const { ipLabelsHash } = useIpLabels();

  const onEnable = useCallback(
    ({ id, admindown }) => {
      if (admindown) {
        enableNeighbor(id);
      } else {
        disableNeighbor(id);
      }
    },
    [enableNeighbor, disableNeighbor],
  );

  const contextMenuProps = useCallback(
    (item) => ({
      item,
      onEdit: () => {
        setCurrentItem(item);
        toggleForm();
      },
      onDelete: () => {
        setCurrentItem(item);
        toggleDelete();
      },
      permissions,
    }),
    [setCurrentItem, toggleForm, toggleDelete, permissions],
  );

  const columns = useMemo(
    () => [
      BaseColumnFactory({
        accessor: 'addresslocal',
        Header: 'Local IP',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value: ip, row }) => {
          const original = getRowOriginal(row);
          return (
            <CellIpLabels
              ips={[ip]}
              labels={[original?.addresslocalname]}
              context={filters.labelContext.ip}
              fetchLabels={filters.labelContext.show}
            />
          );
        },
        realAccessor: ['addresslocal', 'addresslocalname'],
        filter: someOfFieldsFilter(['addresslocal', 'addresslocalname']),
      }),
      BaseColumnFactory({
        accessor: 'addressremote',
        Header: 'Remote IP',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value: ip, row }) => {
          const original = getRowOriginal(row);
          return (
            <CellIpLabels
              ips={[ip]}
              labels={[original?.addressremotename]}
              context={filters.labelContext.ip}
              fetchLabels={filters.labelContext.show}
            />
          );
        },
        realAccessor: ['addressremote', 'addressremotename'],
        filter: someOfFieldsFilter(['addressremote', 'addressremotename']),
      }),
      NumberColumnFactory({
        accessor: 'asn',
        Header: 'ASN',
        width: 80,
        // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
        Cell: ({ value }) => (
          <CellFlowKey field="srcowneras.number" value={value} />
        ),
      }),
      BaseColumnFactory({
        accessor: 'state',
        Header: 'State',
        width: 80,
        getCellProps: () => ({ style: { justifyContent: 'center' } }),
        // TODO need understand why it's eslint error
        // eslint-disable-next-line react/prop-types,react/no-unstable-nested-components
        Cell: ({ value }) => <BgpStateLabel state={value} />,
        Filter: SelectColumnFilter(),
      }),
      NumberColumnFactory({
        accessor: 'routes.total.prefixtotalcount',
        Header: 'Prefixes',
        width: 80,
        textAlign: 'center',
        genericCell: true,
      }),
      BaseColumnFactory({
        id: 'uptime',
        accessor: ({ uptime }) => durationFormatter(uptime),
        Header: 'Uptime',
        width: 180,
        // value of 0 means we don't know the uptime.
        Cell: ({ value }) => (value === '0ms' ? '—' : value),
        getCellProps: () => ({ style: { justifyContent: 'center' } }),
      }),
      ToggleColumnFactory({
        accessor: 'admindown',
        filterLabels: {
          false: 'Enabled',
          true: 'Disabled',
        },
        getChecked: (value) => !(value ?? false),
        getDisabled: () => !permissions?.update,
        onToggle: onEnable,
      }),
      MenuColumnFactory({
        // TODO need understand why it's eslint error
        // eslint-disable-next-line react/no-unstable-nested-components
        cxActionMenu: (_, original) => (
          <ContextMenu {...contextMenuProps(original)} />
        ),
      }),
    ],
    [onEnable, contextMenuProps, permissions, filters.labelContext],
  );

  const tableData = useMemo(
    () =>
      !filters.labelContext.show
        ? neighbors
        : neighbors.map((el) => ({
            ...el,
            addresslocalname:
              ipLabelsHash[el.addresslocal]?.[filters.labelContext.ip],
            addressremotename:
              ipLabelsHash[el.addressremote]?.[filters.labelContext.ip],
          })),
    [neighbors, ipLabelsHash, filters.labelContext],
  );

  return (
    <Table
      id="Configuration_Devices_DeviceInfo_BgpTab_Table"
      columns={columns}
      data={tableData}
      sortBy={sortBy}
    />
  );
};

BgpTable.propTypes = {
  neighbors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disableNeighbor: PropTypes.func.isRequired,
  enableNeighbor: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
  toggleDelete: PropTypes.func.isRequired,
  setCurrentItem: PropTypes.func.isRequired,
  permissions: PropTypes.shape(),
};

BgpTable.defaultProps = {
  permissions: null,
};

export default connect(null, bgpActions)(BgpTable);
