import PropTypes from '+prop-types';

import styled from 'styled-components';

import CloseIcon from 'mdi-react/CloseIcon';

import ColorTypes from '@/models/ColorTypes';

import IconButton from '+components/IconButton';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 39px;
  border: unset;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 15px 0 15px;
  text-transform: ${({ $capitalize }) =>
    $capitalize && 'capitalize !important'};
  background-color: ${({ theme, $color }) => theme[$color]};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  border: unset;
  color: ${({ theme }) => theme.buttonPrimaryText} !important;
`;

const Close = styled(IconButton)`
  margin: 0 -12px 0 auto;
  color: ${({ theme }) => theme.buttonPrimaryText} !important;
  background-color: unset;
  &:hover {
    background-color: unset;
  }
`;

const Header = ({ capitalize, color, hideCloseButton, onClose, ...rest }) => (
  <Container $capitalize={capitalize} $color={color}>
    <Title {...rest} />
    {!hideCloseButton && (
      <Close onClick={onClose}>
        <CloseIcon size={20} />
      </Close>
    )}
  </Container>
);

Header.propTypes = {
  /**
   * Deprecated param.
   */
  capitalize: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(ColorTypes)),
  hideCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
};

Header.defaultProps = {
  capitalize: true,
  color: ColorTypes.primary,
  hideCloseButton: false,
  onClose: null,
};

export default Header;
