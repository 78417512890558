import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/vpc';

import { usePageTabs } from '+components/PageTabs';
import useMaxSources from '+hooks/useMaxSources';
import useProviderClassification from '+hooks/useProviderClassification';

import { cleanUpFields } from '../../utils';
import { SOURCETYPES } from '../../utils/sourceTypes';
import VpcForm from './Form';

const providers = Object.keys(SOURCETYPES);

const VpcAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flowtype, flowresource, traffictype } = useParams();
  const [, activePageTab] = usePageTabs();

  const classification = useProviderClassification(flowtype, flowresource);

  // track if we have intercepted a submit and if we are waiting on the API/dispatch to complete
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching, error } = useSelector(selectors.getState);
  const { maxDownsample } = useMaxSources();

  const initialValues = useMemo(
    () => ({
      enabled: true,
      flowtype,
      flowresource,
      samplerate: maxDownsample ?? 1,
      traffictype: classification?.[traffictype]
        ? traffictype
        : ContextTypes.flow,
    }),
    [flowtype, flowresource, maxDownsample, classification, traffictype],
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = cleanUpFields(values);
      setIsSubmitting(activePageTab?.id);
      dispatch(actions.vpcAdd(data));
    },
    [activePageTab?.id],
  );

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      navigate(`${RoutePaths.sources}`);
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id]);

  if (!providers.includes(`${flowtype}/${flowresource}`)) {
    return <Navigate to={`${RoutePaths.sources}`} replace />;
  }

  return (
    <VpcForm
      mode="create"
      initialValues={initialValues}
      createCallback={handleSubmit}
      loading={!!isSubmitting && isFetching}
    />
  );
};

export default VpcAdd;
