import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: ${(props) => props.$top ?? 0}px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  background-color: ${({ theme }) => theme.colorBackground};

  width: ${(props) => props.$width ?? 0}px;
  transform: translateX(${(props) => (props.$isOpen ? 0 : 100)}%);

  transition:
    transform 0.3s ease-in-out 0.1s,
    top 0.3s ease-in-out 0.1s;

  border-left: ${({ $separatorWidth }) => $separatorWidth}px solid
    ${({ theme }) => theme.colorBackgroundSeparator};
  z-index: 99998;
`;
