import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';

import styled from 'styled-components';

import Grow from '@mui/material/Grow';
import ModalOrigin from '@mui/material/Modal';

import { TextWrapper } from '+components/form/Plaintext';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

import ModalHeader from './components/Header';

const Container = styled.div`
  position: relative;
  max-height: calc(100vh - 3.5rem);
  width: 500px;
  margin: 1.75rem auto;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Context = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: calc(100vh - 3.5rem);

  border-radius: 4px;
  background: transparent;
  position: relative;
  overflow: hidden;
`;

const ModalBody = styled.div`
  width: 100%;
  padding: 16px 15px 8px 15px;
  background-color: ${({ theme }) => theme.colorBackgroundModalBody};
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 3.5rem - 96px);

  ${ScrollBarMixin};

  ${TextWrapper} > *:not(.form__form-group-description) {
    background-color: ${({ theme }) =>
      theme.colorBackgroundModalPlaintextFieldBackground};
  }
`;

const ModalFooter = styled.div`
  width: 100%;
  height: 57px;
  border: unset;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 15px 0 15px;
  background-color: ${({ theme }) => theme.colorBackgroundModalFooter};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .button-delete {
    margin-left: auto;
  }
`;

const getModalContainer = () => {
  return document.querySelector('#modal-root');
};

// We need to store the number of modals in the container
// to prevent the container from hiding when one modal is closing and another is opening.
const getModalCount = (container) => {
  return Number(container?.dataset?.modalCount || 0);
};

const setModalCount = (container, count) => {
  container.dataset.modalCount = count.toString();
  return count;
};

const incModalCount = (container) => {
  return setModalCount(container, getModalCount(container) + 1);
};

const decModalCount = (container) => {
  return setModalCount(container, Math.max(0, getModalCount(container) - 1));
};

const sx = {
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

/**
 * Component to add dialogs for lightboxes, user notifications, or completely custom content.
 */
const Modal = (props) => {
  const { children, isOpen, onClose, onEntered, ...tail } = props;

  const container = useMemo(() => getModalContainer(), [isOpen]);

  useEffect(() => {
    if (!container) {
      return undefined;
    }
    if (isOpen) {
      container.style.visibility = 'visible';
      incModalCount(container);
    } else {
      container.style.visibility = 'hidden';
      decModalCount(container);
    }
    return () => {
      const count = decModalCount(container);
      if (count <= 0) {
        container.style.visibility = 'hidden';
      }
    };
  }, [container, isOpen]);

  return (
    <ModalOrigin
      // BackdropProps={{ timeout: 700 }}
      closeAfterTransition
      {...tail}
      open={isOpen}
      onClose={onClose}
      sx={sx}
      container={container}
    >
      <Grow
        in={isOpen}
        // timeout={500}
        onEntered={onEntered}
        appear={false}
      >
        <Container>
          <Context>{children}</Context>
        </Container>
      </Grow>
    </ModalOrigin>
  );
};

Modal.propTypes = {
  /**
   * Content to display.
   */
  children: PropTypes.children.isRequired,
  /**
   * Boolean to control the state of the popover.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Callback for toggling isOpen in the controlling component.
   */
  onClose: PropTypes.func,
  onEntered: PropTypes.func,
};

Modal.defaultProps = {
  onClose: null,
  onEntered: null,
};

export { ModalHeader, ModalBody, ModalFooter, Modal, Container };

export default Modal;
