import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as trafficTopActions,
  selectors as trafficTopSelectors,
} from '@/redux/api/trafficTop';

export const useTrafficTopFields = (context) => {
  const dispatch = useDispatch();

  const fields = useSelector(trafficTopSelectors.getFields(context));

  useEffect(() => {
    if (fields?.length) {
      return undefined;
    }
    const namespace = `trafficTopFields_${context}`;
    dispatch(trafficTopActions.fetchFields(context, namespace));

    return () => {
      dispatch(trafficTopActions.cancel(namespace));
    };
  }, [fields?.length, context]);

  return fields;
};
