import { useCallback, useEffect, useRef } from 'react';

import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

const useInstance = (instance) => {
  const {
    allColumns,
    disableDragAndDrop,
    setColumnOrder,
    setGroupBy,
    state: { groupBy },
  } = instance;

  const defOrder = useRef([]);

  const moveColumn = useCallback(
    (prevId, nextId) => {
      if (nextId === TechnicalColumns.totalColumn) {
        const nextGroupBy = [...groupBy];
        const prevIndex = nextGroupBy.indexOf(prevId);
        if (prevIndex === -1) {
          // add to groupBy
          setGroupBy([...nextGroupBy, prevId]);
          return;
        }
        // remove from groupBy
        setGroupBy(nextGroupBy.filter((id) => id !== prevId));
        return;
      }

      if (groupBy?.includes(prevId) || groupBy?.includes(nextId)) {
        const nextGroupBy = [...groupBy];
        const prevIndex = nextGroupBy.indexOf(prevId);
        const nextIndex = nextGroupBy.indexOf(nextId);
        if (nextIndex === -1) {
          // remove from groupBy
          setGroupBy(nextGroupBy.filter((id) => id !== prevId));
          return;
        }
        if (prevIndex === -1) {
          // add to groupBy
          nextGroupBy.splice(nextIndex, 0, prevId);
          setGroupBy(nextGroupBy);
          return;
        }
        // change order
        nextGroupBy.splice(prevIndex, 1);
        nextGroupBy.splice(nextIndex, 0, prevId);
        setGroupBy(nextGroupBy);
        return;
      }

      setColumnOrder((order) => {
        const prev = [...(order?.length ? order : defOrder.current)];
        if (
          prev?.length &&
          prevId !== nextId &&
          prev.includes(prevId) &&
          prev.includes(nextId)
        ) {
          const prevIndex = prev.indexOf(prevId);
          const nextIndex = prev.indexOf(nextId);
          prev.splice(prevIndex, 1);
          prev.splice(nextIndex, 0, prevId);
          return prev;
        }
        return order;
      });
    },
    [groupBy, setColumnOrder],
  );

  useEffect(() => {
    defOrder.current = (allColumns || []).map(({ id }) => id);
  }, [allColumns]);

  useEffect(() => {
    allColumns?.forEach((column) => {
      column.canDragAndDrop = !(
        disableDragAndDrop || column.disableDragAndDrop
      );
      column.moveColumn = moveColumn;
    });
  }, [disableDragAndDrop, allColumns, moveColumn]);

  Object.assign(instance, {
    moveColumn,
  });
};

export const useMoveColumn = (hooks) => {
  hooks.useInstance.push(useInstance);
};
