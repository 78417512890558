/* eslint-disable react/prop-types */
import { useMemo } from 'react';

import PencilIcon from 'mdi-react/PencilIcon';

import RoutePaths from '@/models/RoutePaths';

import CellWrapper from './CellWrapper';

// Specific Flow Key Context Menus
const CellTrafficSourceName = (props) => {
  const { field, value, id, type, original, disabled } = props;

  const additionalMenuOptions = useMemo(() => {
    let url;
    if (type === 'device' && (id || value)) {
      url = `${RoutePaths.sourcesDevices}/edit/${encodeURIComponent(
        id || value,
      )}`;
    }
    if (type === 'cloud' && id) {
      url = `${RoutePaths.sourcesCloudProviders}/edit/${encodeURIComponent(
        id,
      )}`;
    }
    return !url
      ? null
      : [
          {
            url,
            icon: <PencilIcon />,
            text: 'Edit',
          },
        ];
  }, [id, value, type]);

  return (
    <CellWrapper
      customer={original?.customer}
      field={field}
      value={value}
      additionalMenuOptions={additionalMenuOptions}
      disabled={disabled}
    >
      <span>{value}</span>
    </CellWrapper>
  );
};

export default CellTrafficSourceName;
