import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import SettingCategories from '@/models/SettingCategories';
import { ThemeTypes } from '@/models/ThemeTypes';

import { usePortalSettingsValue } from '+hooks';
import { themeCookie } from '+utils/themeCookie';

import { Context } from './context';

export const Provider = ({ children }) => {
  const matchRef = useRef(window.matchMedia?.('(prefers-color-scheme: dark)'));
  const [isSystemDarkMode, setIsSystemDarkMode] = useState(
    matchRef.current?.matches ?? false,
  );

  const cookieTheme = themeCookie.get();

  const systemTheme = isSystemDarkMode ? ThemeTypes.dark : ThemeTypes.light;

  const defaultTheme = cookieTheme || systemTheme;

  const [theme, setTheme, isFetching] = usePortalSettingsValue(
    SettingCategories.ui,
    'theme',
  );

  const remove = useCallback(() => {
    setTheme(null);
  }, [setTheme]);

  const change = useCallback(
    (value) => {
      if (value == null) {
        remove();
        return;
      }

      setTheme(value);
    },
    [setTheme, remove],
  );

  const stateRef = useRef('');
  stateRef.current = cookieTheme;
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if ((theme || '') === stateRef.current) {
      return;
    }

    if (theme == null) {
      themeCookie.remove();
      return;
    }

    themeCookie.set(theme);
  }, [theme]);

  useEffect(() => {
    const match = window.matchMedia?.('(prefers-color-scheme: dark)');

    setIsSystemDarkMode(match?.matches ?? false);

    const handler = (e) => {
      setIsSystemDarkMode(e.matches);
    };

    match?.addEventListener?.('change', handler);

    return () => {
      match?.removeEventListener?.('change', handler);
    };
  }, []);

  const prepared = {
    theme: theme || defaultTheme,
    changeTheme: change,
    removeTheme: remove,
    isFetching,
    isUsedSystemTheme: !theme,
  };

  const value = useMemo(() => prepared, Object.values(prepared));

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};
