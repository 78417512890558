import styled, { css } from 'styled-components';

const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 12px;
  border: none;
  background-color: transparent;
  transition:
    background-color 0.3s,
    color 0.3s;
  color: ${({ theme }) => theme.colorText};

  &:hover {
    background-color: ${({ theme }) => theme.sideBarItemHoverBackground};
  }

  &:disabled {
    color: ${({ theme }) => theme.colorMenuDisabledText} !important;
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.sideBarItemActiveColor} !important;
    `}
`;

export default Tab;
