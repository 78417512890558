import { Fragment } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import isEqual from 'lodash.isequal';

import { countries } from '@/models/countries';
import FieldsSeparator from '@/models/FieldsSeparator';
import {
  isCountryCodeField,
  isIpField,
  isLabelField,
  isOrgField,
  isPortField,
} from '@/models/FieldTypes';
import { findMetricSettings, isCardMetric } from '@/models/MetricSettings';

import { paletteColorsLength } from '+components/charts/common/utils';
import Flag from '+components/Flag';

export const unknown = 'Unknown';
export const noFlag = 'No flags set';

export const getSeriesMetric = (series) =>
  series?.metric || series?.series?.split(FieldsSeparator)[0] || '';

export const getSeriesFields = (series) => {
  if (!series?.series) {
    return [];
  }
  const fields = series.series.split(FieldsSeparator);
  // remove first element (metric name)
  return fields.slice(1);
};

export const getSeriesValues = (series) => {
  if (!series?.name) {
    return [];
  }
  return series.name.split(FieldsSeparator);
};

/** * Common Formatters ** */

export const defaultNumberFormatter = (value) =>
  (+value).toLocaleString(undefined, { maximumFractionDigits: 2 });

/** * Field Checkers ** */
const _reverse = (arr, reverse) => (reverse ? arr.reverse() : arr);
export const isDataWithLabelFields = (fields, reverse = false) => {
  if (fields?.length !== 2) {
    return false;
  }

  let dataField;
  let labelField;

  if (reverse) {
    [labelField, dataField] = fields;
  } else {
    [dataField, labelField] = fields;
  }

  return (
    (dataField === 'dstip' &&
      /^label\.ip(\.('*'|[a-zA-Z0-9\-_]+))?(\.dst)?$/g.test(labelField)) ||
    (dataField === 'srcip' &&
      /^label\.ip(\.('*'|[a-zA-Z0-9\-_]+))?(\.src)?$/g.test(labelField)) ||
    (dataField === 'dstport' &&
      /^label\.port(\.('*'|[a-zA-Z0-9\-_]+))?(\.dst)?$/g.test(labelField)) ||
    (dataField === 'srcport' &&
      /^label\.port(\.('*'|[a-zA-Z0-9\-_]+))?(\.src)?$/g.test(labelField)) ||
    isEqual(fields, _reverse(['dstip', 'dstname'], reverse)) || // dstname - deprecated field
    isEqual(fields, _reverse(['dstip', 'dstipname'], reverse)) ||
    isEqual(fields, _reverse(['srcip', 'srcname'], reverse)) || // srcname - deprecated field
    isEqual(fields, _reverse(['srcip', 'srcipname'], reverse)) ||
    isEqual(fields, _reverse(['ipinfo.ip', 'ipinfo.ipname'], reverse)) ||
    // || isEqual(fields, _reverse(['nexthop', 'nexthopname'], reverse))
    // || isEqual(fields, _reverse(['flowsrcip', 'flowsrcname'], reverse))
    isEqual(fields, _reverse(['dstport', 'dstportname'], reverse)) ||
    isEqual(fields, _reverse(['srcport', 'srcportname'], reverse)) ||
    isEqual(fields, _reverse(['dstports', 'dstportnames'], reverse)) ||
    isEqual(fields, _reverse(['srcports', 'srcportnames'], reverse))
  );
};

export const isAsnWithOrgFields = (fields, reverse = false) => {
  if (fields?.length !== 2) {
    return false;
  }
  return (
    isEqual(fields, _reverse(['srcas.number', 'srcas.org'], reverse)) ||
    isEqual(
      fields,
      _reverse(['srcowneras.number', 'srcowneras.org'], reverse),
    ) ||
    isEqual(fields, _reverse(['dstas.number', 'dstas.org'], reverse)) ||
    isEqual(fields, _reverse(['dstowneras.number', 'dstowneras.org'], reverse))
  );
};

/** * Data Extractors ** */
export const dataWithLabelExtractor = (seriesFields, seriesValues) => {
  const [dataFieldName, labelsFieldName] = seriesFields;
  const [data, labelsString] = seriesValues;
  const labels = (labelsString?.split(', ') || []).filter(Boolean);
  let labelsContext;

  if (isIpField(dataFieldName)) {
    labelsContext = labelsFieldName?.startsWith('label.ip')
      ? labelsFieldName.split('.')[2]
      : 'name';
  }

  if (isPortField(dataFieldName)) {
    labelsContext = data;
  }

  return {
    dataFieldName,
    data,
    labelsFieldName,
    labelsContext,
    labels,
  };
};

export const labelWithDataExtractor = (seriesFields, seriesValues) => {
  const [labelsFieldName, dataFieldName] = seriesFields;
  const [labelsString, data] = seriesValues;
  const labels = (labelsString?.split(', ') || []).filter(Boolean);
  let labelsContext;

  if (!dataFieldName) {
    if (labelsFieldName?.startsWith('label.ip')) {
      // eslint-disable-next-line prefer-destructuring
      labelsContext = labelsFieldName.split('.')[2];
    } else if (
      labelsFieldName?.startsWith('dstip') ||
      labelsFieldName?.startsWith('srcip')
    ) {
      labelsContext = 'name';
    } else if (
      labelsFieldName?.startsWith('label.port') ||
      labelsFieldName?.startsWith('dstport') ||
      labelsFieldName?.startsWith('srcport')
    ) {
      labelsContext = '';
    }
  } else {
    if (isIpField(dataFieldName)) {
      labelsContext = labelsFieldName?.startsWith('label.ip')
        ? labelsFieldName.split('.')[2]
        : 'name';
    }
    if (isPortField(dataFieldName)) {
      labelsContext = data;
    }
  }

  return {
    dataFieldName,
    data,
    labelsFieldName,
    labelsContext,
    labels,
  };
};

const bitMask = [1, 2, 4, 8, 16, 32, 64, 128, 256];
const TCPFlags = ['FIN', 'SYN', 'RST', 'PSH', 'ACK', 'URG', 'ECE', 'CWR', 'NS'];
export const tcpflagsintDataExtractor = (tcpflagsint) => {
  try {
    const childInt = parseInt(tcpflagsint, 10);
    const flagset = [];

    bitMask.forEach((mask, i) => {
      // eslint-disable-next-line no-bitwise
      if ((childInt & bitMask[i]) === bitMask[i]) {
        flagset.push(TCPFlags[i]);
      }
    });

    return flagset.length ? flagset.reverse() : [noFlag];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Received bad data int');
    return null;
  }
};

const maxOrgLength = 30;
export const asnWithOrgExtractor = (seriesValues) => {
  const [rawNumber, rawOrg] = seriesValues;
  const number = +rawNumber;
  const org =
    rawOrg?.length > maxOrgLength
      ? `${rawOrg.substring(0, maxOrgLength - 3)}...`
      : rawOrg;
  return { number, org };
};

/** * Renderers ** */
export const defaultSeriesValueRenderer = (props) => {
  const { value, renderAsGroup = true } = props;
  const valueArr = Array.isArray(value) ? value : [value];
  const Wrapper = renderAsGroup ? 'span' : Fragment;
  const wrapperProps = renderAsGroup ? { className: 'label-series-group' } : {};
  const Renderer = (
    <Wrapper {...wrapperProps}>
      {valueArr.map((v, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`chart-series-${i}`} className="label-series">
          <span className="label-series__body">{v || unknown}</span>
        </span>
      ))}
    </Wrapper>
  );
  return renderAsGroup ? renderToStaticMarkup(Renderer) : Renderer;
};

export const labelWithDataRenderer = (props) => {
  const {
    data,
    labelsContext,
    labels: rawLabels,
    renderAsGroup = true,
  } = props;
  const labels = !data && !rawLabels?.length ? [unknown] : rawLabels;
  const Wrapper = renderAsGroup ? 'span' : Fragment;
  const wrapperProps = renderAsGroup ? { className: 'label-series-group' } : {};
  const Renderer = (
    <Wrapper {...wrapperProps}>
      <span className="label-series">
        {!labels?.length && <span className="label-series__body">{data}</span>}
        {!!labels?.length && (
          <Fragment>
            {!!labelsContext &&
              labels[0].toLowerCase() !== unknown.toLowerCase() && (
                <span className="label-series__context">{labelsContext}</span>
              )}
            <span className="label-series__body">{labels[0]}</span>
          </Fragment>
        )}
      </span>
      {labels?.length > 1 && (
        <span className="label-series__counter">+{labels.length - 1}</span>
      )}
    </Wrapper>
  );
  return renderAsGroup ? renderToStaticMarkup(Renderer) : Renderer;
};

export const countryCodeSeriesValueRenderer = (props) => {
  const { countrycode, subdiso, city, renderAsGroup = true } = props;
  const Wrapper = renderAsGroup ? 'span' : Fragment;
  const wrapperProps = renderAsGroup ? { className: 'label-series-group' } : {};
  const Renderer = (
    <Wrapper {...wrapperProps}>
      <span className="label-series">
        {countrycode?.toLowerCase() !== unknown.toLowerCase() && (
          <span className="label-series__icon">
            <Flag countryCode={countrycode} style={{}} />
          </span>
        )}
        <span className="label-series__body">
          {[countrycode, subdiso, city].filter(Boolean).join(' - ')}
        </span>
      </span>
    </Wrapper>
  );
  return renderAsGroup ? renderToStaticMarkup(Renderer) : Renderer;
};

export const autonomousSystemsRenderer = (props) => {
  const { number, org, renderAsGroup = true } = props;
  const Wrapper = renderAsGroup ? 'span' : Fragment;
  const wrapperProps = renderAsGroup ? { className: 'label-series-group' } : {};
  const Renderer = (
    <Wrapper {...wrapperProps}>
      <span className="label-series">
        {!org && <span className="label-series__body">{number}</span>}
        {org && (
          <Fragment>
            {!!number && (
              <span className="label-series__context">{number}</span>
            )}
            <span className="label-series__body">{org}</span>
          </Fragment>
        )}
      </span>
    </Wrapper>
  );
  return renderAsGroup ? renderToStaticMarkup(Renderer) : Renderer;
};

/** * Series Value Formatters ** */

export const defaultSeriesValueFormatter = ({
  labelContext = {},
  ...series
}) => {
  const metric = getSeriesMetric(series);
  if (isCardMetric(metric)) {
    return defaultSeriesValueRenderer({ value: ['unique'] });
  }

  const fields = getSeriesFields(series);
  const values = getSeriesValues(series);

  const skipFieldNames = fields.reduce((acc, field) => {
    switch (field) {
      case 'srcip': {
        acc[`label.ip.${labelContext.ip}.src`] = labelContext.show;
        acc.srcipname = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'dstip': {
        acc[`label.ip.${labelContext.ip}.dst`] = labelContext.show;
        acc.dstipname = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'ipinfo.ip': {
        acc['ipinfo.ipname'] = labelContext.show && labelContext.ip === 'name';
        return acc;
      }

      case 'srcport': {
        acc[`label.port.${labelContext.port}.src`] = labelContext.show;
        acc.srcportname = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'dstport': {
        acc[`label.port.${labelContext.port}.dst`] = labelContext.show;
        acc.dstportname = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'srcports': {
        acc.srcportnames = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'dstports': {
        acc.dstportnames = labelContext.show && labelContext.port === 'name';
        return acc;
      }

      case 'srcas.number': {
        acc['srcas.org'] = true;
        return acc;
      }

      case 'srcowneras.number': {
        acc['srcowneras.org'] = true;
        return acc;
      }

      case 'dstas.number': {
        acc['dstas.org'] = true;
        return acc;
      }

      case 'dstowneras.number': {
        acc['dstowneras.org'] = true;
        return acc;
      }

      default:
        return acc;
    }
  }, {});

  const renderers = fields.reduce((acc, field, fieldIndex) => {
    if (skipFieldNames[field]) {
      return acc;
    }

    switch (field) {
      case 'srcip': {
        if (!labelContext.show) {
          break;
        }
        const labelFieldNames = [
          `label.ip.${labelContext.ip}.src`,
          labelContext.ip === 'name' && 'srcipname',
        ].filter(Boolean);
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'dstip': {
        if (!labelContext.show) {
          break;
        }
        const labelFieldNames = [
          `label.ip.${labelContext.ip}.dst`,
          labelContext.ip === 'name' && 'dstipname',
        ].filter(Boolean);
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'ipinfo.ip': {
        if (!labelContext.show || labelContext.ip !== 'name') {
          break;
        }
        const labelFieldName = 'ipinfo.ipname';
        const labelIndex = fields.findIndex((f) => f === labelFieldName);
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'srcport': {
        if (!labelContext.show) {
          break;
        }
        const labelFieldNames = [
          `label.port.${labelContext.port}.src`,
          labelContext.port === 'name' && 'srcportname',
        ].filter(Boolean);
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'dstport': {
        if (!labelContext.show) {
          break;
        }
        const labelFieldNames = [
          `label.port.${labelContext.port}.dst`,
          labelContext.port === 'name' && 'dstportname',
        ].filter(Boolean);
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'srcports': {
        if (!labelContext.show || labelContext.port !== 'name') {
          break;
        }
        const labelFieldName = 'srcportnames';
        const labelIndex = fields.findIndex((f) => f === labelFieldName);
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'dstports': {
        if (!labelContext.show || labelContext.port !== 'name') {
          break;
        }
        const labelFieldName = 'dstportnames';
        const labelIndex = fields.findIndex((f) => f === labelFieldName);
        if (labelIndex === -1) {
          break;
        }
        const { data, labelsContext, labels } = dataWithLabelExtractor(
          [field, fields[labelIndex]],
          [values[fieldIndex], values[labelIndex]],
        );
        acc.push(
          labelWithDataRenderer({
            data,
            labelsContext,
            labels,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'srcas.number': {
        const labelFieldNames = ['srcas.org'];
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { number, org } = asnWithOrgExtractor([
          values[fieldIndex],
          values[labelIndex],
        ]);
        acc.push(
          autonomousSystemsRenderer({
            number,
            org,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'srcowneras.number': {
        const labelFieldNames = ['srcowneras.org'];
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { number, org } = asnWithOrgExtractor([
          values[fieldIndex],
          values[labelIndex],
        ]);
        acc.push(
          autonomousSystemsRenderer({
            number,
            org,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'dstas.number': {
        const labelFieldNames = ['dstas.org'];
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { number, org } = asnWithOrgExtractor([
          values[fieldIndex],
          values[labelIndex],
        ]);
        acc.push(
          autonomousSystemsRenderer({
            number,
            org,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      case 'dstowneras.number': {
        const labelFieldNames = ['dstowneras.org'];
        const labelIndex = fields.findIndex((f) => labelFieldNames.includes(f));
        if (labelIndex === -1) {
          break;
        }
        const { number, org } = asnWithOrgExtractor([
          values[fieldIndex],
          values[labelIndex],
        ]);
        acc.push(
          autonomousSystemsRenderer({
            number,
            org,
            renderAsGroup: false,
          }),
        );
        return acc;
      }

      default:
        break;
    }

    if (isLabelField(field)) {
      const { data, labelsContext, labels } = labelWithDataExtractor(
        [field, null],
        [values[fieldIndex], null],
      );
      acc.push(
        labelWithDataRenderer({
          data,
          labelsContext,
          labels,
          renderAsGroup: false,
        }),
      );
      return acc;
    }

    if (isOrgField(field)) {
      const { number, org } = asnWithOrgExtractor([null, values[fieldIndex]]);
      acc.push(
        autonomousSystemsRenderer({
          number,
          org,
          renderAsGroup: false,
        }),
      );
      return acc;
    }

    if (isCountryCodeField(field)) {
      // find subdiso and city for countrycode (search from current index)
      acc.push(
        countryCodeSeriesValueRenderer({
          countrycode: values[fieldIndex],
          renderAsGroup: false,
        }),
      );
      return acc;
    }

    if (field === 'tcpflagsint') {
      const flags = tcpflagsintDataExtractor(values[fieldIndex]);
      acc.push(
        defaultSeriesValueRenderer({
          value: flags,
          renderAsGroup: false,
        }),
      );
      return acc;
    }

    // default renderer
    acc.push(
      defaultSeriesValueRenderer({
        value: values[fieldIndex],
        renderAsGroup: false,
      }),
    );

    return acc;
  }, []);

  if (!renderers.length) {
    renderers.push(
      defaultSeriesValueRenderer({
        value: values,
        renderAsGroup: false,
      }),
    );
  }

  return renderToStaticMarkup(
    <span className="label-series-group">
      {renderers.map((renderer, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`chart-series-${i}`}>{renderer}</Fragment>
      ))}
    </span>,
  );
};

/** * Legend Formatters ** */

export const defaultLegendLabelFormatter =
  '' +
  "<span style='display: flex; flex-wrap: nowrap; align-items: center; height: 21px'>" +
  "<b class='highcharts-color-{colorIndex} {className}' style='margin-right: 4px'>▬</b>{name}" +
  '</span>';

export const scatterPlotLegendLabelFormatter =
  '' +
  "<span style='display: flex; flex-wrap: nowrap; align-items: center; height: 21px'>" +
  "<b class='highcharts-color-{colorIndex} {className}' style='font-size: {symbolSize}; line-height: 10px; margin-right: 4px'>{symbolUnicode}</b>{name}" +
  '</span>';

/** * Color Index Formatters ** */

export const defaultSeriesColorIndexFormatter = (series) => {
  const values = getSeriesValues(series);
  if (!values.length) {
    return unknown;
  }
  if (values.length === 1) {
    if (
      ['', unknown.toLowerCase(), `0-${unknown.toLowerCase()}`].includes(
        values[0].toLowerCase(),
      )
    ) {
      return unknown;
    }
  }
  const str = values.join('-');
  const randStr = `${str[0]}${str[str.length % 2]}${str[str.length - 1]}`; // add some uniqueness
  const value = `${str}-${randStr}`;
  const charIndexSum = Array.from(value).reduce(
    (sum, char) => sum + Math.abs(char.charCodeAt(0) - 65),
    0,
  );
  return charIndexSum % paletteColorsLength;
};

export const countryCodeSeriesColorIndexFormatter = (series) => {
  const [countrycode] = getSeriesValues(series);
  if (countries[countrycode]) {
    return countrycode;
  }
  // apply default color index logic if country code is not in the list
  return defaultSeriesColorIndexFormatter(series);
};

export const severitySeriesColorIndexFormatter = (series) => {
  const [severity] = getSeriesValues(series);
  return severity;
};

export const getSeriesColorIndexFormatter = ({ metric, fields }) => {
  if (isCardMetric(metric)) {
    return undefined;
  }

  if (isCountryCodeField(fields?.[0])) {
    return countryCodeSeriesColorIndexFormatter;
  }

  if (fields?.[0] === 'severity') {
    return severitySeriesColorIndexFormatter;
  }

  return undefined;
};

/** * Tooltip Formatters ** */

export const barChartTooltipFormatter = function () {
  const { series, point } = this;
  const {
    name,
    userOptions: { className, realData: value, sum: total, metric, context },
  } = series;

  const { colorIndex } = point;

  const percent = !total
    ? 0
    : ((value / total) * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      });

  const { tooltipFormatter } = findMetricSettings({ context, metric }) || {
    tooltipFormatter: defaultNumberFormatter,
  };

  return `
    <div style="display: flex; flex-wrap: nowrap; align-items: center">
      <span style="display: flex; flex-wrap: nowrap; align-items: center">
        <b class="${
          className || ''
        } highcharts-color-${colorIndex}" style="margin-right: 4px">▬</b>
        ${name}
      </span>
      <span style="margin: auto 0 auto 12px; font-weight: bold">
        ${tooltipFormatter(value)} (${percent}%)
      </span>
    </div>
  `;
};

/** * Data Labels Formatters ** */

export const barChartDataLabelFormatter = function () {
  const { series } = this;
  const {
    userOptions: { realData: value, sum: total, metric, context },
  } = series;

  const percent = !total
    ? 0
    : ((value / total) * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      });

  const { tooltipFormatter } = findMetricSettings({ context, metric }) || {
    tooltipFormatter: defaultNumberFormatter,
  };

  return `${tooltipFormatter(value)} (${percent}%)`;
};
