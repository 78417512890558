import { useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { useAuthProfile } from '+hooks/useAuthProfile';

export const useHasFlow = () => {
  const { isDefault } = useAuthProfile();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  return !!(isDefault || customer?.multi_account || customer?.hasFlow);
};
