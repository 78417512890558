import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import isFunction from 'lodash.isfunction';
import styled from 'styled-components';

import {
  Menu as MenuOrigin,
  Provider as MenuProvider,
  useMenuActions,
  useMenuState,
} from '+components/Menu';

import MenuIcon from './MenuIcon';

export const TriggerButton = styled.button.attrs(() => ({ type: 'button' }))`
  color: inherit;
  position: absolute;

  margin: 0;
  padding: 0;
  border: 0;

  top: 9px;
  right: 8px;
  background: transparent;
  line-height: 1em;

  pointer-events: ${({ $active }) => ($active ? 'none' : 'auto')};
`;

const Trigger = ({ icon, className, title }) => {
  const { show } = useMenuState();
  const { showMenuXY } = useMenuActions();

  const onClick = useCallback((e) => {
    const rect = e.currentTarget.getBoundingClientRect();

    showMenuXY(rect.right, rect.bottom + 4, {
      direction: 'left',
    });
  }, []);

  const iconRender = useMemo(() => icon || <MenuIcon size={17} />, [icon]);

  return (
    <TriggerButton
      onClick={onClick}
      $active={show}
      className={className}
      title={title}
      key={`${show}`}
      data-tracking={`${title?.toLowerCase().replace(/\s+/g, '-')}-row-menu`}
      data-testid="row-menu"
    >
      {iconRender}
    </TriggerButton>
  );
};

Trigger.propTypes = {
  icon: PropTypes.children,
  className: PropTypes.string,
  title: PropTypes.string,
};

Trigger.defaultProps = {
  icon: null,
  className: null,
  title: null,
};

const MenuStyled = styled(MenuOrigin)`
  padding: 0;
  font-size: 12px;
  line-height: normal;
`;

const MenuList = styled.ul.attrs(() => ({ className: 'highcharts-menu' }))`
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
`;

const Separator = styled.li`
  height: 1px;
  padding: 0;
  background: ${({ theme }) => theme.contextMenuBorderColor};
  border-radius: 0;
  margin: 0.5em 0;
`;

const MenuLi = styled.li`
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const MenuItem = styled(
  ({ action, closeMenu, content, className, separator, disabled }) => {
    const { hideMenu } = useMenuActions();

    const onClick = useCallback(
      (event) => {
        if (closeMenu ?? true) {
          hideMenu();
        }

        if (isFunction(action)) {
          action(event);
        }
      },
      [hideMenu, closeMenu, action],
    );

    const isDangerous = typeof content === 'string';

    if (separator) {
      return <Separator />;
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <MenuLi
        className={`highcharts-menu-item ${className}`}
        disabled={disabled}
        onClick={isFunction(action) ? onClick : undefined}
        dangerouslySetInnerHTML={isDangerous ? { __html: content } : undefined}
      >
        {isDangerous ? undefined : content}
      </MenuLi>
    );
  },
)``;

const Menu = ({
  actions,
  icon,
  className,
  title,
  hideOnClickOutside,
  ...rest
}) => (
  <MenuProvider>
    <Trigger icon={icon} className={className} title={title} {...rest} />
    <MenuStyled
      className="highcharts-contextmenu"
      border={false}
      hideOnClickOutside={hideOnClickOutside}
    >
      <MenuList>
        {actions.map(({ key, ...itemProps }, index) => (
          <MenuItem key={key || index} {...itemProps} />
        ))}
      </MenuList>
    </MenuStyled>
  </MenuProvider>
);

Menu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  icon: PropTypes.children,
  className: PropTypes.string,
  title: PropTypes.string,
  hideOnClickOutside: PropTypes.bool,
  disabled: PropTypes.bool,
};

Menu.defaultProps = {
  icon: null,
  className: null,
  title: null,
  hideOnClickOutside: true,
  disabled: false,
};

export default styled(Menu)``;
