import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import RoleModel from '@/models/Role';

import Button, { ButtonVariants } from '+components/Button';
import Field from '+components/form/FinalForm/Field';
import Form from '+components/form/Form';
import { normalizeSelectValue } from '+components/form/Normalizers';
import PhoneNumberField from '+components/form/PhoneNumberField';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 140px;
  gap: 10px;

  .button-delete {
    margin-left: auto;
  }
`;

const FormBody = (props) => {
  const {
    roles,
    isSelf,
    canManage,
    canManageRole,
    canRemove,
    form,
    handleSubmit,
    invalid,
    pristine,
    submitting,
    onDelete,
  } = props;

  usePageTabsAndFormSync();

  const { restart } = form;

  const rolesOptions = useMemo(
    () => Object.values(roles || {}).sort(RoleModel.sortRoles),
    [roles],
  );

  const reset = useCallback(() => {
    restart();
  }, []);

  return (
    <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
      {!isSelf && (
        <Field
          name="roles[0]"
          label="Role"
          component={SelectField}
          options={rolesOptions}
          groupBy={(item) => (item.system ? 'System' : 'Custom')}
          parse={normalizeSelectValue}
          disabled={!canManageRole}
          validate={canManageRole ? validateRequired : undefined}
          required={canManageRole}
        />
      )}

      <Field
        name="given_name"
        label="First Name"
        component={TextField}
        disabled={!canManage}
        validate={canManage ? validateRequired : undefined}
        required={canManage}
      />

      <Field
        name="family_name"
        label="Last Name"
        component={TextField}
        disabled={!canManage}
        validate={canManage ? validateRequired : undefined}
        required={canManage}
      />

      <Field
        name="user_metadata.nickname"
        label="Nickname"
        component={TextField}
        disabled={!canManage}
      />

      <Field
        name="user_metadata.phone_number"
        component={PhoneNumberField}
        type="text"
        label="Phone Number"
        disabled={!canManage}
      />

      <Field
        name="user_metadata.job_title"
        label="Job Title"
        component={TextField}
        type="text"
        disabled={!canManage}
      />

      <Field
        name="user_metadata.picture"
        label="Picture URL"
        component={TextField}
        type="url"
        disabled={!canManage}
      />

      <ButtonGroup>
        <Button
          type="submit"
          disabled={submitting || invalid || (!canManage && !canManageRole)}
          testId="confirm-button"
        >
          Save
        </Button>

        <Button
          variant={ButtonVariants.outlined}
          onClick={reset}
          disabled={pristine || submitting || !canManage}
          testId="reset-button"
        >
          Reset
        </Button>

        <Button
          className="button-delete"
          variant={ButtonVariants.outlined}
          disabled={!canRemove}
          onClick={onDelete}
          testId="delete-button"
        >
          Delete User
        </Button>
      </ButtonGroup>
    </Form>
  );
};

FormBody.propTypes = {
  roles: PropTypes.shape({}),
  isSelf: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
  canManageRole: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool.isRequired,
  form: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

FormBody.defaultProps = {
  roles: null,
};

export default FormBody;
