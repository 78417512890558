import PropTypes from '+prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors } from '@/redux/api/nql-complete';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { convertNqlContextToModeTag } from '@/shared/utils/getNewRecentFilters';

import { Separator } from '+components/Menu';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useSessionStorage from '+hooks/useSessionStorage';
import useUIProperty from '+hooks/useUIProperty';

import { MenuTabs } from '../../utils';
import Filter from './components/Filter';
import List from './components/List';
import Context from './Context';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NoData = styled.div`
  margin: 10px auto;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const filterFn = (filter) => (item) => {
  const fixedFilter = filter.toLowerCase();
  return (
    item.title?.toLowerCase().includes(fixedFilter) ||
    item.nql.toLowerCase().includes(fixedFilter) ||
    item.preset?.toLowerCase().includes(fixedFilter)
  );
};

const appendModeTagToDocPresets = (docPresets, nqlContext) => {
  const updatedPresets = [];
  docPresets.forEach((preset) => {
    updatedPresets.push({
      ...preset,
      mode: convertNqlContextToModeTag(nqlContext),
    });
  });
  return updatedPresets;
};

const Presets = (props) => {
  const { context, mode, onChoose, onEdit, onCopy, onRemove } = props;

  const [guest] = useUIProperty('guest');

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const profile = useSelector(profileSelectors.getProfile);
  const isAdmin = useSelector(profileSelectors.isAdminUser);

  const docs = useSelector(selectors.getDocs);
  const presetsData = useSelector(selectors.getPresets(context));

  const [globalRecentQueries] = usePortalSettingsValue(
    SettingCategories.ui,
    'globalFiltersRecents',
    [],
  );
  const [sessionRecentQueries] = useSessionStorage('globalFiltersRecents');

  const [filter, setFilter] = useState('');

  const extractedRecentQueries = useMemo(() => {
    if (!customer?.shortname) {
      return [];
    }
    const recent = guest
      ? sessionRecentQueries || globalRecentQueries
      : globalRecentQueries;
    const customerRecent = recent.find(
      (item) => item.customer === customer.shortname,
    );
    const modeTag = convertNqlContextToModeTag(context);
    return (customerRecent?.recentQueries || []).filter(
      (item) => item.mode === modeTag,
    );
  }, [
    customer?.shortname,
    guest,
    sessionRecentQueries,
    globalRecentQueries,
    context,
  ]);

  const separatedPresets = useMemo(() => {
    if (mode === MenuTabs.recent) {
      return [[], [], [], extractedRecentQueries];
    }
    return Object.values(presetsData || {}).reduce(
      (acc, item) => {
        let index = 1;

        if (item.system || isDefaultCustomer) {
          index = 2;
        } else if (item.email === profile?.email) {
          index = 0;
        }
        acc[index].push({
          ...item,
          mode: convertNqlContextToModeTag(context),
        });
        return acc;
      },
      [
        [],
        [],
        [...appendModeTagToDocPresets(docs?.presets?.[context] || [], context)],
        [],
      ],
    );
  }, [
    context,
    presetsData,
    profile?.email,
    docs?.presets?.[context],
    isDefaultCustomer,
    extractedRecentQueries,
    mode,
  ]);

  const [
    filteredPresetsUser,
    filteredPresetsCompany,
    filteredPresetsSystem,
    filteredRecentQueries,
  ] = useMemo(() => {
    if (!filter) {
      return separatedPresets;
    }

    return separatedPresets.map((presets) => presets.filter(filterFn(filter)));
  }, [separatedPresets, filter]);

  const onFilterChange = useCallback((value) => {
    setFilter(value);
  }, []);

  const contextValue = useMemo(
    () => ({
      onEdit,
      onCopy,
      onRemove,
    }),
    [onEdit, onCopy, onRemove],
  );

  const isEmpty =
    !filteredRecentQueries?.length &&
    !filteredPresetsUser.length &&
    !filteredPresetsCompany.length &&
    !filteredPresetsSystem.length;

  return (
    <Context.Provider value={contextValue}>
      <Container>
        <Filter onChange={onFilterChange} />

        <Separator />

        {!isEmpty && (
          <List
            userPresets={filteredPresetsUser}
            companyPresets={filteredPresetsCompany}
            systemPresets={filteredPresetsSystem}
            recentQueries={filteredRecentQueries}
            filter={filter}
            isDefault={isDefaultCustomer}
            isAdmin={isAdmin}
            onChooseItem={onChoose}
          />
        )}

        {isEmpty && <NoData>No queries to display</NoData>}
      </Container>
    </Context.Provider>
  );
};

Presets.propTypes = {
  context: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  onChoose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Presets;
