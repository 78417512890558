import { call, put, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { sankeyToAggRequest } from '@/models/Sankey';

import { selectors as filtersSelectors } from '@/redux/globalFilters';
import { actions as toastActions } from '@/redux/toast';
import {
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

const defaultParams = {
  start: -3600,
  end: 0,
  format: 'highcharts',
};

const initialState = {
  isFetching: false,
  error: '',
  metrics: [],
  fields: [],
  isFetchingSeries: {},
  errorSeries: {},
};

let api;
const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const apiPath = '/stats/dns';

const fixAggDataForSizeZero = (chart) => (item) => {
  if (item?.rate != null && item?.data?.length < 1) {
    item.data =
      chart === 'pie'
        ? [{ name: item.label, y: item.rate }]
        : [[item.label, item.rate]];
  }

  return item;
};

const startFetchingSeries = (state, seriesId) => {
  state.isFetchingSeries[seriesId] = true;
  delete state.errorSeries[seriesId];
};
const stopFetchingSeries = (state, seriesId) => {
  state.isFetchingSeries[seriesId] = false;
};

const slice = createSlice({
  name: 'statsDns',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchMetrics: startFetching,
    fetchFields: startFetching,
    timeseriesRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },
    aggregateRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },
    heatmapRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },
    sankeyRequest: (state, { payload: { seriesId } }) => {
      startFetching(state);
      startFetchingSeries(state, seriesId);
    },

    fetchMetricsSuccess: (state, { payload: data }) => {
      stopFetching(state);
      state.metrics = data;
    },

    fetchFieldsSuccess: (state, { payload: data }) => {
      stopFetching(state);
      state.fields = data;
    },

    timeseriesSuccess: (state, { payload: { data, seriesId } }) => {
      stopFetching(state);
      stopFetchingSeries(state, seriesId);
      state[seriesId] = data;
    },

    aggregateSuccess: (state, { payload: { data, seriesId, chart } }) => {
      stopFetching(state);
      stopFetchingSeries(state, seriesId);
      state[seriesId] =
        chart && Array.isArray(data)
          ? data?.map(fixAggDataForSizeZero(chart))
          : data;
    },

    sankeySuccess: (state, { payload: { data, seriesId } }) => {
      stopFetching(state);
      stopFetchingSeries(state, seriesId);
      state[seriesId] = (data || [])[0]?.buckets || [];
    },

    seriesClear: (state, { payload: { seriesId, seriesIds } }) => {
      if (seriesId) {
        delete state[seriesId];
      }

      if (Array.isArray(seriesIds)) {
        seriesIds.forEach((key) => {
          delete state[key];
        });
      }
    },

    failSeries: (state, { payload: { error, seriesId } }) => {
      stopFetchingSeries(state, seriesId);
      state.errorSeries[seriesId] = error.message;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchMetrics]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/metrics`);
          yield put(actions.fetchMetricsSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error receiving list of metrics',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.fetchFields]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/fields`);
          yield put(actions.fetchFieldsSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error receiving list of fields',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.timeseriesRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();

        const mergedParams = {
          ...defaultParams,
          ...params,
        };

        try {
          const { data } = yield call(api.post, `${apiPath}/ts`, mergedParams);
          yield put(actions.timeseriesSuccess({ data: data.data, seriesId }));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving flow data',
              details:
                error && error.message
                  ? error.message
                  : 'Unknown error occurred',
            }),
          );
        }
      },
    },

    [actions.aggregateRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();

        const mergedParams = {
          ...defaultParams,
          ...params,
        };

        try {
          const { data } = yield call(api.post, `${apiPath}/agg`, mergedParams);
          yield put(
            actions.aggregateSuccess({
              data: data.data,
              seriesId,
              chart: mergedParams.chart,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving flow data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.heatmapRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();

        const mergedParams = {
          ...defaultParams,
          ...params,
        };

        try {
          const { data } = yield call(
            api.post,
            `${apiPath}/heatmap`,
            mergedParams,
          );
          yield put(actions.aggregateSuccess({ data: data.data, seriesId }));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving flow data',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.sankeyRequest]: {
      *saga({ payload: { params, seriesId } }) {
        initApi();
        try {
          const filters = yield select(filtersSelectors.getFilters);
          const seriesParams = params.series[0];
          const requestParams = sankeyToAggRequest({
            start: params.start,
            end: params.end,
            metric: seriesParams.metric,
            field: seriesParams.field,
            size: seriesParams.size,
            search: seriesParams.search,
            intersect: seriesParams.intersect,
            customers: params.customers,
            filters,
          });
          const mergedParams = {
            ...defaultParams,
            ...requestParams,
          };
          const { data } = yield call(api.post, `${apiPath}/agg`, mergedParams);
          yield put(actions.sankeySuccess({ data: data.data, seriesId }));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(actions.failSeries({ error, seriesId }));
          yield put(
            toastActions.error({
              message: 'Error receiving sankey data',
              details: error.message,
            }),
          );
        }
      },
    },
  }),
});

export const selectors = {
  ...slice.selectors,

  isFetching: createSelector(
    [slice.selector],
    (statsFlow) => statsFlow.isFetching,
  ),

  getMetrics: createSelector(
    [slice.selector],
    (statsFlow) => statsFlow.metrics,
  ),

  getFields: createSelector([slice.selector], (statsFlow) => statsFlow.fields),

  seriesSelector: (seriesId) =>
    createSelector([slice.selector], (state) => state[seriesId]),

  isFetchingSeries: (seriesId) =>
    createSelector(
      [slice.selector],
      (stats) => stats.isFetchingSeries[seriesId],
    ),

  errorSeries: (seriesId) =>
    createSelector([slice.selector], (stats) => stats.errorSeries[seriesId]),
};

// For convenience
export const { actions } = slice;

export default slice;
