import { useRef } from 'react';

import useGlobalFiltersProperty from '+hooks/useGlobalFiltersProperty';

/**
 * Returns the last allowed context based on set of excludeContexts or allowedContexts.
 * @param {object?} options
 * @param {string} options.defaultContext - Default allowed context.
 * @param {Set<keyof typeof ContextTypes>} options.allowedContexts - Set of allowed contexts.
 * @param {Set<keyof typeof ContextTypes>} options.excludeContexts - Set of excluded contexts.
 * @returns {string}
 */
export const useLastAllowedContext = (options) => {
  const { defaultContext, allowedContexts, excludeContexts } = options || {};
  const [context] = useGlobalFiltersProperty('context');

  const lastContext = useRef(defaultContext || context);

  if (!allowedContexts?.size && !excludeContexts?.size) {
    lastContext.current = context;
    return lastContext.current;
  }

  if (allowedContexts) {
    lastContext.current = allowedContexts.has(context)
      ? context
      : lastContext.current;

    return lastContext.current;
  }

  lastContext.current = excludeContexts.has(context)
    ? lastContext.current
    : context;

  return lastContext.current;
};

export default useLastAllowedContext;
