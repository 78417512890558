import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled from 'styled-components';

import useExportingFilename from '+hooks/useExportingFilename';
import dayjs, { DateFormat } from '+utils/dayjs';
import { formatBitRate, formatBits, formatPackets } from '+utils/format';
import { getHighchartsColor } from '+utils/getHighchartsColor';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import { Highcharts } from './common/highcharts';
import { defaultColorVariety, lang } from './common/utils';

const formatterValue = function ({ series, point }) {
  series = series || this.series;
  point = point || this.point;
  if ('bits' === series.tooltipOptions.metric) {
    return formatBits(point.value);
  }
  if ('bitrate' === series.tooltipOptions.metric) {
    return formatBitRate(point.value);
  }
  if (series.tooltipOptions.metric.match(/packet/)) {
    return formatPackets(point.value);
  }
  return point.value;
};

const defaultFormatterTooltip = function () {
  const { series, point } = this;

  return renderToStaticMarkup(
    <table style={{ width: '100%' }}>
      <tbody>
        {!!series.tooltipOptions.customer?.length && (
          <tr>
            <td>account:</td>
            <td style={{ textAlign: 'right' }}>
              <b>{series.tooltipOptions.customer.join(', ')}</b>
            </td>
          </tr>
        )}
        <tr>
          <td>{series.xAxis?.axisTitle?.textStr}:</td>
          <td style={{ textAlign: 'right' }}>
            <b>{series.xAxis.categories[point.x]}</b>
          </td>
        </tr>
        <tr>
          <td>{series.yAxis?.axisTitle?.textStr}:</td>
          <td style={{ textAlign: 'right' }}>
            <b>{series.yAxis.categories[point.y]}</b>
          </td>
        </tr>
        <tr>
          <td>{series.tooltipOptions.metric}:</td>
          <td style={{ textAlign: 'right' }}>
            <b>{formatterValue({ series, point })}</b>
          </td>
        </tr>
      </tbody>
    </table>,
  );
};

const formatDateSeries = (series) =>
  series.map((ts) => dayjs(ts).format(DateFormat.minute));

const yAxisFormatter = (item) => (item.value === item.pos ? '' : item.value);

const plotOptions = {
  series: {
    stickyTracking: false,
  },
  heatmap: {
    animation: false,
    shadow: false,
    dataLabels: { style: { textShadow: false } },
  },
};

const defaultColorIndexMin = 0;
const defaultColorIndexMax = 6;

const HeatmapChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    data,
    metric,
    customer,
    seriesSize,
    xfield,
    yfield,
    xAxis,
    yAxis,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    colorIndexMin,
    colorIndexMax,
    hideTitle,
    hideSubtitle,
    onPlotClick,
    minColor,
    maxColor,
    xAxisLabelRotation,
    spacingLeft,
    marginBottom,
    alternateGridColor,
    formatterTooltip,
    yAxisReversed: initialYAxisReversed,
    xAxisReversed: initialXAxisReversed,
    waitingOnNql,
  } = props;

  const chartRef = useRef(null);

  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  let xField = xfield;
  let xAxisSeries = xAxis;
  let xAxisReversed = initialXAxisReversed;

  if (xfield === 'histogram') {
    xField = 'Date';
    xAxisSeries = formatDateSeries(xAxis);
    xAxisReversed = false;
  }

  let yField = yfield;
  let yAxisSeries = yAxis;
  let yAxisReversed = initialYAxisReversed;

  if (yfield === 'histogram') {
    yField = 'Date';
    yAxisSeries = formatDateSeries(yAxis);
    yAxisReversed = false;
  }

  const colorAxis = useMemo(() => {
    const _minColor =
      minColor ||
      getHighchartsColor({
        paletteClassName: 'p',
        paletteIndex: colorVariety,
        colorClassName: 'highcharts-color',
        colorIndex: colorIndexMin ?? defaultColorIndexMin,
      });
    const _maxColor =
      maxColor ||
      getHighchartsColor({
        paletteClassName: 'p',
        paletteIndex: colorVariety,
        colorClassName: 'highcharts-color',
        colorIndex: colorIndexMax ?? defaultColorIndexMax,
      });
    return {
      min: 0,
      minColor: _minColor,
      maxColor: _maxColor,
      className: 'color-axis',
    };
  }, [colorVariety, colorIndexMin, colorIndexMax, minColor, maxColor]);

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  // Workaround to dynamically change colorAxis
  // @see https://www.highcharts.com/forum/viewtopic.php?t=35199
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.colorAxis[0].update(colorAxis);
    }
  }, [chartRef.current, colorAxis]);

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(className, 'heatmap-chart', {
          short: width > 360 && height < 180,
          ultrashort: width <= 360 && height < 180,
        })}
        plotOptions={{
          ...plotOptions,
          series: {
            ...plotOptions.series,
            events: { click: onPlotClick },
          },
        }}
        callback={onChartCallback}
        colorAxis={colorAxis}
        time={{ useUTC: true }}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML margin={0}>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>
          {waitingOnNql ? lang.waitingOnNql : lang.loading}
        </HSX.Loading>
        <HSX.Tooltip
          useHTML
          formatter={formatterTooltip ?? defaultFormatterTooltip}
          metric={metric}
          customer={customer}
          shadow={false}
          animation={false}
        />

        <HSX.Chart
          width={width}
          height={height}
          marginTop={title || subtitle ? 48 : 32}
          marginBottom={marginBottom ?? undefined}
          plotBorderWidth={1}
          animation={false}
          reflow={false}
          spacingLeft={spacingLeft ?? undefined}
        />

        <HSX.XAxis
          title={{
            text: xField,
          }}
          categories={xAxisSeries}
          labels={{
            rotation: xAxisLabelRotation,
          }}
          minPadding={2}
          maxPadding={4}
          tickWidth={1}
          gridLineWidth={1}
          reversed={xAxisReversed}
        />

        <HSX.YAxis
          title={{
            text: yField,
          }}
          categories={yAxisSeries}
          labels={{
            formatter: yAxisFormatter, // Workaround to hide invisible categories counter
            rotation: 0,
          }}
          minPadding={0}
          maxPadding={0}
          tickWidth={1}
          gridLineWidth={1}
          reversed={yAxisReversed}
          alternateGridColor={alternateGridColor ?? undefined}
        >
          <HSX.HeatmapSeries
            name="Heatmap"
            data={data.slice()}
            borderWidth={1}
            dataLabels={{
              enabled:
                xfield !== 'histogram' &&
                yfield !== 'histogram' &&
                seriesSize < 25,
              style: {
                color: 'black',
                textOutline: 'none',
              },
              formatter: formatterValue,
            }}
          />
        </HSX.YAxis>
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})`
  .highcharts-yaxis-grid {
    display: none;
  }
`;

HeatmapChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart data.
   */
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape(),
    ]),
  ),
  /**
   * Chart metric.
   */
  metric: PropTypes.string,
  seriesSize: PropTypes.number,
  xAxis: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  yAxis: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  xfield: PropTypes.string,
  yfield: PropTypes.string,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index for min value.
   */
  colorIndexMin: PropTypes.number,
  /**
   * Series color index for min value.
   */
  colorIndexMax: PropTypes.number,
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * if true, chart data will not be loaded until user inputs NQL (handled in StatsWrapper)
   * Will show the corresponding message on the chart
   */
  waitingOnNql: PropTypes.bool,

  minColor: PropTypes.string,
  maxColor: PropTypes.string,
  xAxisLabelRotation: PropTypes.number,
  spacingLeft: PropTypes.number,
  onPlotClick: PropTypes.func,
  marginBottom: PropTypes.number,
  alternateGridColor: PropTypes.string,
  formatterTooltip: PropTypes.func,
  xAxisReversed: PropTypes.bool,
  yAxisReversed: PropTypes.bool,
};

HeatmapChart.defaultProps = {
  className: '',
  title: undefined,
  subtitle: undefined,
  data: [],
  metric: 'bitrate',
  seriesSize: 5,
  xAxis: [],
  yAxis: [],
  xfield: 'Unknown',
  yfield: 'Unknown',
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndexMin: defaultColorIndexMin,
  colorIndexMax: defaultColorIndexMax,
  hideTitle: false,
  hideSubtitle: false,
  minColor: undefined,
  maxColor: undefined,
  xAxisLabelRotation: -25,
  spacingLeft: null,
  onPlotClick: null,
  marginBottom: null,
  alternateGridColor: null,
  formatterTooltip: null,
  xAxisReversed: true,
  yAxisReversed: true,
};

export { defaultColorIndexMin, defaultColorIndexMax };
export default memo(HeatmapChart);
