import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import RoutePaths from '@/models/RoutePaths';

import { NqlCodeBlock } from '+components/form/NqlTextField';
import { HeaderSubheaderCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  FilterOperator,
  someOfFieldsFilter,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { autoRemoveByOperator } from '+components/Table/ReactTable/utils';
import { getColumnsHelper } from '+utils';

import { Columns } from './Columns';

const CodeBlock = styled(NqlCodeBlock)`
  pointer-events: auto;
  background: transparent !important;
  & > div {
    background: transparent !important;
  }
`;

const sortAuthorColumn = (rowA, rowB) => {
  const originalA = getRowOriginal(rowA);
  const originalB = getRowOriginal(rowB);
  const valueA = originalA?.user?.name || originalA?.created_by || '';
  const valueB = originalB?.user?.name || originalB?.created_by || '';
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

const columnsCollection = ({ profileEmail, cxActionMenu }) => ({
  [Columns.title]: BaseColumnFactory({
    Header: 'Name / Desc',
    width: 220,
    Cell: HeaderSubheaderCell({
      propHeader: 'title',
      propSubheader: 'description',
    }),
    realAccessor: ['title', 'description'],
    filter: someOfFieldsFilter(['title', 'description']),
  }),
  [Columns.nql]: BaseColumnFactory({
    Header: 'NQL',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <CodeBlock>{value}</CodeBlock>,
  }),
  [Columns.context]: TrafficColumnFactory({
    Header: 'Context',
    options: {
      disabled: true,
    },
    maxWidth: 120,
    width: 120,
    disableResizing: false,
  }),
  [Columns.author]: BaseColumnFactory({
    accessor: 'email',
    Header: 'Author',
    width: 220,
    Cell: ({ row }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        return !original?.user ? (
          original?.email
        ) : (
          <Link to={`${RoutePaths.users}/${original?.user?.id}`}>
            {original?.user?.name}
          </Link>
        );
      }, [row]),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'Mine', 'Not Mine'],
      enableLikeFilter: true,
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveByOperator(filterValue)) {
        return rows;
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'Mine'
      ) {
        return rows.filter(
          ({ original }) => !original.system && original.email === profileEmail,
        );
      }

      if (
        filterValue.operator === FilterOperator.equal &&
        filterValue.value === 'Not Mine'
      ) {
        return rows.filter(
          ({ original }) => original.system || original.email !== profileEmail,
        );
      }

      const normalizedFilterValue = String(filterValue.value).toLowerCase();
      return rows.filter(
        ({ original }) =>
          !original.system &&
          (original.email.toLowerCase().includes(normalizedFilterValue) ||
            original.user?.name?.toLowerCase().includes(normalizedFilterValue)),
      );
    }, autoRemoveByOperator),
    sortType: sortAuthorColumn,
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
