import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

import { chameleon } from '+utils/chameleon';

export const initialState = {
  isFetching: false,
  error: '',
  adapters: null, // []
  areAllPluginsFetched: false,
  plugins: null, // {}
};

const apiPath = '/integrations/response';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'responseIntegrations',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchAdapters: startFetching,
    fetchAdaptersSuccess(state, { payload: adapters }) {
      stopFetching(state);
      state.adapters = adapters;
    },

    fetchPlugins: startFetching,
    successPlugins(state, { payload: { data } }) {
      stopFetching(state);
      state.plugins = (data || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      state.areAllPluginsFetched = true;
    },

    fetchPlugin: startFetching,
    fetchPluginSuccess(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.plugins) {
        state.plugins = {};
      }
      state.plugins[item.id] = item;
    },

    addPlugin: startFetching,
    successPluginAdd(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.plugins) {
        state.plugins = {};
      }
      chameleon.sendEvent('response_integration_added');
      state.plugins[item.id] = item;
    },

    updatePlugin: startFetching,
    successPluginUpdate(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      if (!item) {
        return;
      }
      if (!state.plugins) {
        state.plugins = {};
      }
      state.plugins[item.id] = item;
    },

    testPlugin: startFetching,
    successPluginTest(state) {
      stopFetching(state);
    },

    deletePlugin: startFetching,
    successPluginDelete(state, { payload: id }) {
      stopFetching(state);
      delete state.plugins?.[id];
    },

    bulkDeleteResponseIntegrations: startFetching,
    bulkDeleteSuccess(state, { payload: ids }) {
      stopFetching(state);
      if (state.plugins) {
        ids.forEach((id) => {
          delete state.plugins[id];
        });
      }
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchAdapters]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/adapters`);
          yield put(actions.fetchAdaptersSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching adapters',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchPlugins]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, apiPath);
          yield put(actions.successPlugins(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching response integrations',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchPlugin]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${id}`);
          yield put(actions.fetchPluginSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching response integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.addPlugin]: {
      *saga({ payload }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, payload);
          yield put(actions.successPluginAdd(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response integration has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating response integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.testPlugin]: {
      *saga({ payload }) {
        initApi();

        try {
          yield call(api.post, `${apiPath}/test`, payload);
          yield put(actions.successPluginTest());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response integration tested successfully',
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error testing response integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updatePlugin]: {
      *saga({ payload }) {
        initApi();

        try {
          const { id } = payload;
          const response = yield call(api.put, `${apiPath}/${id}`, payload);
          yield put(actions.successPluginUpdate(response.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response integration has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating response integration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deletePlugin]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${id}`);
          yield put(actions.successPluginDelete(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response integration has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting response integration',
              details: error.message,
            }),
          );
        }
      },
    },
    [actions.bulkDeleteResponseIntegrations]: {
      *saga({ payload: ids }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/bulkdelete`, {
            ids,
          });
          yield put(actions.bulkDeleteSuccess(ids));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Response integrations have been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting response integrations',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getAdapters: createSelector([getState], (state) => state.adapters),

    getPlugins: createSelector([getState], ({ plugins }) => plugins),

    getPluginById: (id) =>
      createSelector([getState], ({ plugins }) => plugins?.[id]),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
