import PropTypes from '+prop-types';
import { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorTypes } from '@/models/ColorTypes';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import authClient from '@/middleware/authClient';

import ConfirmModal from '+components/ConfirmModal';
import GlobalLink from '+components/GlobalLink';

const TtouModal = ({ isOpen }) => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const onAcceptTtou = useCallback(() => {
    dispatch(customerActions.acceptTtou(customer.shortname));
  }, [customer.shortname]);

  const onDenyTtou = useCallback(() => {
    authClient.logout();
  }, []);

  return (
    <ConfirmModal
      item="Free Trial Services Terms"
      confirmButtonText="Confirm"
      confirmButtonColor={ColorTypes.primary}
      textTemplate={
        <Fragment>
          <span>
            By accessing or using the Netography Free Trial Services, you
            signify that you have read and understand, and agree to be bound by,
            our Terms of Use, Privacy Policy and these Free Trial Terms.
          </span>
          <br />
          <br />
          <GlobalLink
            href="https://netography.com/free-trial-terms/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Free Trial Services Terms
          </GlobalLink>
        </Fragment>
      }
      onToggle={onDenyTtou}
      onConfirm={onAcceptTtou}
      cancelButtonText="Logout"
      toggleOnConfirm={false}
      isOpen={isOpen}
      disableEscapeKeyDown
      hideCloseButton
    />
  );
};

TtouModal.propTypes = {
  isOpen: PropTypes.bool,
};

TtouModal.defaultProps = {
  isOpen: false,
};

export default TtouModal;
