import PropTypes from '+prop-types';
import { useCallback, useContext } from 'react';

import styled from 'styled-components';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import { Item as MenuItemOriginal } from '+components/Menu';

import CodeBlockOrigin from '../../CodeBlock';
import Context from '../Context';

const Title = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  background: ${({ theme }) => theme.tagSecondaryBackground} !important;
  padding: 0 6px;
  border-radius: 4px;
`;

const CodeBlock = styled(CodeBlockOrigin)`
  display: flex;
  align-items: center;
  padding: 2px 0 !important;
  height: unset !important;
  line-height: unset !important;
  background: unset !important;
  .language-nql {
    background: unset !important;
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  gap: 6px;
  padding: 2px 0 2px 2px;
`;

const Action = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  visibility: hidden;

  border-radius: 50%;
  background: ${({ theme }) => theme.contextMenuItemHoverBackground};

  &:hover {
    background: ${({ theme }) => theme.iconButtonInsideComponentsBackground};
  }
`;

export const Item = styled(MenuItemOriginal)`
  padding: 2px 8px;
  font-size: 13px;
  min-height: 32px;
  overflow: hidden;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  gap: 10px;

  &:hover ${Action} {
    visibility: visible;
  }
`;

const ListItem = (props) => {
  const { item, isEditable, ...rest } = props;
  const { onEdit, onCopy, onRemove } = useContext(Context);

  const doEdit = useCallback(
    (event) => {
      event.stopPropagation();
      onEdit(item);
    },
    [item, onEdit],
  );

  const doCopy = useCallback(
    (event) => {
      event.stopPropagation();
      onCopy(item);
    },
    [item, onCopy],
  );

  const doRemove = useCallback(
    (event) => {
      event.stopPropagation();
      onRemove(item);
    },
    [item, onRemove],
  );

  const itemTitle = item.title || item.preset;

  return (
    <Item {...rest}>
      {!!itemTitle && <Title>{itemTitle}</Title>}

      <CodeBlock>{item.nql}</CodeBlock>

      {isEditable && (
        <Actions>
          <Action title="Edit" onClick={doEdit}>
            <LeadPencilIcon size={14} />
          </Action>

          <Action title="Copy" onClick={doCopy}>
            <ContentCopyIcon size={14} />
          </Action>

          <Action title="Remove" onClick={doRemove}>
            <TrashCanOutlineIcon size={14} />
          </Action>
        </Actions>
      )}
    </Item>
  );
};

ListItem.propTypes = {
  item: PropTypes.shape().isRequired,
  isEditable: PropTypes.bool,
};

ListItem.defaultProps = {
  isEditable: false,
};

export default ListItem;
