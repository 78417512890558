import PropTypes from '+prop-types';

import classNames from 'classnames';

const Label = ({
  className,
  children,
  invalid,
  disabled,
  required,
  ...tail
}) => (
  <span
    {...tail}
    className={classNames(className, 'form__form-group-label', {
      invalid,
      disabled,
      required,
    })}
  >
    {children}
  </span>
);

Label.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Child elements.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  /**
   * If true, component will be in invalid state.
   */
  invalid: PropTypes.bool,
  /**
   * If true, component will be in disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * If true, the label title will ends with '*'.
   */
  required: PropTypes.bool,
};

Label.defaultProps = {
  children: null,
  className: null,
  invalid: false,
  disabled: false,
  required: false,
};

export default Label;
