import PropTypes from '+prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import isEqual from 'lodash.isequal';

import PermissionModel from '@/models/Permission';

import { useFormState } from '+components/form/FinalForm';

import FieldsSection from './FieldsSection';
import PermissionsRow from './PermissionsRow';

const RenderPermissions = (props) => {
  const { fields, resourceGroup, canManageResellerParams, disabled } = props;

  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true, modified: true },
  });

  useEffect(() => {
    formValues.permissions?.forEach((item, index) => {
      const { resource } = item;
      const resourceMeta = PermissionModel.Resources[resource];
      if (resourceMeta?.mergeWithResource) {
        const mergeWithItem = formValues.permissions?.find(
          (el) => el.resource === resourceMeta.mergeWithResource,
        );
        if (mergeWithItem) {
          const newItem = { ...mergeWithItem, resource };
          if (!isEqual(item, newItem)) {
            form.change(`permissions[${index}]`, newItem);
          }
        }
      }
    });
  }, [formValues.permissions]);

  return (
    <FieldsSection key={resourceGroup} label={resourceGroup}>
      {fields
        .map((name, index) => {
          const { resource } = fields.value[index];
          const resourceMeta = PermissionModel.Resources[resource];

          if (resourceMeta?.mergeWithResource) {
            return undefined;
          }

          if (resourceMeta?.group !== resourceGroup) {
            return undefined;
          }

          if (resourceMeta?.resellersOnly && !canManageResellerParams) {
            return undefined;
          }

          let label = resourceMeta?.label || resource;

          switch (resource) {
            case PermissionModel.Resources.threat_model.value:
              label = label.replace('Network ', '');
              break;
            case PermissionModel.Resources.network_classification.value:
              label = label.replace('Network ', 'Traffic ');
              break;
            default:
              break;
          }

          return (
            <PermissionsRow
              key={`${resourceGroup}${name}`}
              resourceGroup={resourceGroup}
              name={name}
              disabled={disabled}
              label={label}
              resourceMeta={resourceMeta}
            />
          );
        })
        .filter(Boolean)
        .sort((a, b) => a.props.label.localeCompare(b.props.label))}
    </FieldsSection>
  );
};

RenderPermissions.propTypes = {
  fields: PropTypes.shape().isRequired,
  resourceGroup: PropTypes.string,
  disabled: PropTypes.bool,
  canManageResellerParams: PropTypes.bool,
};

RenderPermissions.defaultProps = {
  resourceGroup: '',
  disabled: false,
  canManageResellerParams: false,
};

export default RenderPermissions;
