import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import CommonAdd from '../shared/CommonAdd';
import { Config, uiToParams } from '../shared/utils';
import FormBody from './FormBody';

const commonDefaultValues = {
  algo_type: 'CDM',
  algo_record_type: ContextTypes.flow,
  enabled: true,
  bypassdisplay: false,
  bypassrule: false,
  rollupperiod: 300,
  expiration: 3600,
  updateinterval: 0,
  subscriptiontype: 'optout',
};

const defaultValues = {
  [ContextTypes.flow]: {
    ...commonDefaultValues,
    search_by: [Config.defaultSearchBy],
    thresholds: [Config.defaultThresholds],
    track_by: [Config.defaultContextTrackBy[ContextTypes.flow]],
    discards: [Config.defaultDiscards],
  },
  [ContextTypes.dns]: {
    ...commonDefaultValues,
    search_by: [Config.defaultSearchBy],
    thresholds: [Config.defaultThresholds],
    track_by: [Config.defaultContextTrackBy[ContextTypes.dns]],
    discards: [Config.defaultDiscards],
  },
};

const pickFields = [
  'name',
  'algo_record_type',
  'description',
  'categories',
  'enabled',
  'expiration',
  'search_by',
  'rollupperiod',
  'thresholds',
  'track_by',
  'discards',
  'updateinterval',
];

const CCMAdd = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const initId = searchParams.get('init');

  const initAlgorithm = useSelector(rulesSelectors.getCCM(initId));

  const onSubmit = useCallback(({ id, ...values }) => {
    dispatch(rulesActions.addCCM(uiToParams(values)));
  }, []);

  useEffect(() => {
    if (initId && !initAlgorithm) {
      dispatch(rulesActions.fetchCCM(initId));
    }
  }, [initId, initAlgorithm]);

  return (
    <CommonAdd
      title="Add Context Creation Model"
      initAlgorithm={initAlgorithm}
      pickFields={pickFields}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      FormBody={FormBody}
    />
  );
};

export default CCMAdd;
