import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import omit from 'lodash.omit';

import { ContextTypes } from '@/models/ContextTypes';

import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';

import { usePageTabs } from '+components/PageTabs';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getMetricFieldName from '+utils/getMetricFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';

const omitProps = [];
// Object.values(ContextTypes).forEach((context) => {
//   const metricFieldName = getMetricFieldName(context);
//   const nqlFieldName = getNqlFieldName(context);
//   const intersectFieldName = getIntersectFieldName(context);
//   omitProps.push(metricFieldName);
//   omitProps.push(nqlFieldName);
//   omitProps.push(intersectFieldName);
// });

/**
 * Hook to use return global filter state.
 *
 * @param {ContextTypes} [context]
 * @return {Object} filters
 */
export const useGlobalFilters = (context) => {
  const dispatch = useDispatch();
  const [, activePageTab] = usePageTabs();
  const defaultFilters = useSelector(globalFiltersSelectors.getDefaultFilters);
  const allFilters = useSelector(
    globalFiltersSelectors.getTabFilters(activePageTab?.id),
  );

  const filtersByContext = useMemo(() => {
    let fixedContext = context || allFilters.context;
    if (!ContextTypes[fixedContext]) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown context type: ${fixedContext}`);
      fixedContext = ContextTypes.flow;
    }

    const metricFieldName = getMetricFieldName(fixedContext);
    const nqlFieldName = getNqlFieldName(fixedContext);
    const intersectFieldName = getIntersectFieldName(fixedContext);

    return omit(
      {
        ...allFilters,
        context: fixedContext,
        metric: allFilters[metricFieldName] || defaultFilters[metricFieldName],
        nql:
          allFilters[nqlFieldName] ||
          defaultFilters[nqlFieldName] ||
          defaultFilters.nql,
        intersect:
          allFilters[intersectFieldName] ||
          defaultFilters[intersectFieldName] ||
          defaultFilters.intersect,
      },
      omitProps,
    );
  }, [activePageTab?.id, allFilters, context, defaultFilters]);

  const change = useCallback(
    (filterPropValue) => {
      if (!activePageTab?.id) {
        return;
      }
      dispatch(
        globalFiltersActions.changeFilter({
          ...filterPropValue,
          tabId: activePageTab?.id,
        }),
      );
    },
    [activePageTab?.id],
  );

  return [filtersByContext, change];
};

export default useGlobalFilters;
