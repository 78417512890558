import styled from 'styled-components';

const MainRowGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 36px;
  max-height: 36px;
  padding: 2px 0;
`;

export default MainRowGroup;
