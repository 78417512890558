import PropTypes from '+prop-types';
import { forwardRef } from 'react';

import classNames from 'classnames';

const Form = forwardRef((props, ref) => {
  const { className, children, material, horizontal, labelOnTop, ...tail } =
    props;

  return (
    <form
      {...tail}
      ref={ref}
      className={classNames(className || '', {
        'material-form': material,
        form: !material,
        'form--horizontal': horizontal,
        'form--label-on-top': labelOnTop,
      })}
    >
      {children}
    </form>
  );
});

Form.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Child elements.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  /**
   * If true, form will have material-ui className.
   */
  material: PropTypes.bool,
  /**
   * If true, form will be displayed in horizontal style.
   */
  horizontal: PropTypes.bool,
  /**
   * If true, label will be displayed on top of field.
   */
  labelOnTop: PropTypes.bool,
};

Form.defaultProps = {
  children: null,
  className: null,
  material: false,
  horizontal: true,
  labelOnTop: false,
};

export default Form;
