import { SourceTypeKeys } from '@/pages/TrafficSources/utils/sourceTypes';
import { VpcIcon } from '@/shared/img/icon';

const cloudProviderFlow = 'Cloud Providers Flow Types';
const specificFlow = 'Other Flow Types';

export const SourceTypes = {
  all: {
    value: 'all',
    label: 'all',
    description: 'All flow types',
  },
  aws: {
    value: 'aws',
    icon: <VpcIcon sourceType={SourceTypeKeys.awsKinesis} size={18} />,
    label: 'aws',
    description: 'Amazon AWS',
    group: cloudProviderFlow,
  },
  azure: {
    value: 'azure',
    icon: <VpcIcon sourceType={SourceTypeKeys.azure} size={18} />,
    label: 'azure',
    description: 'Microsoft Azure',
    group: cloudProviderFlow,
  },
  gcp: {
    value: 'gcp',
    icon: <VpcIcon sourceType={SourceTypeKeys.gcp} size={18} />,
    label: 'gcp',
    description: 'Google Cloud Platform',
    group: cloudProviderFlow,
  },
  ibm: {
    value: 'ibm',
    icon: <VpcIcon sourceType={SourceTypeKeys.ibm} size={18} />,
    label: 'ibm',
    description: 'IBM',
    group: cloudProviderFlow,
  },
  oracle: {
    value: 'oracle',
    icon: <VpcIcon sourceType={SourceTypeKeys.oracle} size={18} />,
    label: 'oracle',
    description: 'Oracle',
    group: cloudProviderFlow,
  },
  netflow: {
    value: 'netflow',
    label: 'netflow',
    description: 'NetFlow',
    group: specificFlow,
  },
  sflow: {
    value: 'sflow',
    label: 'sflow',
    description: 'sFlow',
    group: specificFlow,
  },
};

export const CloudProvidersSourceTypes = Object.values(SourceTypes).reduce(
  (acc, el) =>
    el.group === cloudProviderFlow ? { ...acc, [el.value]: el } : acc,
  {},
);
export const DevicesSourceTypes = Object.values(SourceTypes).reduce(
  (acc, el) => (el.group === specificFlow ? { ...acc, [el.value]: el } : acc),
  {},
);

export default SourceTypes;
