import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import * as HSX from 'react-jsx-highcharts';

import classNames from 'classnames';
import styled from 'styled-components';

import { findMetricSettings } from '@/models/MetricSettings';

import useShowDataLabelsOnPrint from '+components/charts/common/useShowDataLabelsOnPrint';
import useExportingFilename from '+hooks/useExportingFilename';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import FlagBackgroundMixin from './common/FlagBackgroundMixin';
import {
  defaultLegendLabelFormatter,
  defaultNumberFormatter,
  defaultSeriesColorIndexFormatter,
  defaultSeriesValueFormatter,
} from './common/formatters';
import getPropertiesTrayData from './common/getPropertiesTrayData';
import { Highcharts } from './common/highcharts';
import {
  getLegendPositionOptions,
  LegendMixin,
  LegendPositions,
} from './common/legend';
import {
  defaultColorVariety,
  lang,
  legendItemClick,
  legendItemHover,
} from './common/utils';

const defaultDataLabelFormatter = function () {
  const { realData: value, sum: total, metric, context } = this.point;
  const { tooltipFormatter } = findMetricSettings({ context, metric }) || {
    tooltipFormatter: defaultNumberFormatter,
  };

  const percent = total
    ? ((value / total) * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })
    : 0;
  return `${tooltipFormatter(value)} (${percent}%)`;
};

const defaultTooltipFormatter = function () {
  const {
    realData: value,
    sum: total,
    metric,
    colorIndex,
    name,
    context,
    className,
  } = this.point;
  const { tooltipFormatter } = findMetricSettings({ context, metric }) || {
    tooltipFormatter: defaultNumberFormatter,
  };

  const percent = total
    ? ((value / total) * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })
    : 0;
  return `
    <div style="display: flex; flex-wrap: nowrap; align-items: center">
      <span style="display: flex; flex-wrap: nowrap; align-items: center">
        <b class="highcharts-color-${colorIndex}${
          className || ''
        }" style="margin-right: 4px">▬</b>
        ${name}
      </span>
      <span style="margin: auto 0 auto 12px; font-weight: bold">
        ${tooltipFormatter(value)} (${percent}%)
      </span>
    </div>
  `;
};

const minPointValue = 0.007;

const seriesDataNormalizer = ({
  context,
  metric,
  series,
  data,
  seriesNameFormatter,
  seriesColorIndexFormatter,
  colorIndex,
  labelContext,
}) => {
  if (!data) {
    return [];
  }

  const sum = data.reduce((acc, item) => acc + item.y, 0);

  return data.map((item, index) => ({
    ...item,
    index,
    name: seriesNameFormatter({ ...item, series, labelContext }),
    y: item.y / sum > minPointValue ? item.y : sum * minPointValue,
    colorIndex:
      colorIndex ?? item.colorIndex ?? seriesColorIndexFormatter(item),
    realData: item.y,
    sum,
    context,
    metric,
  }));
};

/**
 * Pie chart is a circular chart divided into sectors that are proportional to the quantity it represents.
 */
const PieChart = styled((props) => {
  const {
    className,
    title,
    subtitle,
    context,
    fields,
    series,
    legend,
    legendReversed,
    exporting,
    loading,
    width,
    height,
    colorVariety,
    colorIndex,
    seriesNameFormatter,
    seriesColorIndexFormatter,
    legendLabelFormatter,
    tooltipFormatter,
    legendPosition,
    hideTitle,
    hideSubtitle,
    waitingOnNql,
  } = props;

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);
  const [, setGlobalContextMenu] = useUIProperty('globalContextMenu', null);

  const chartRef = useRef(null);
  const [filters] = useGlobalFilters();
  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  useShowDataLabelsOnPrint(chartRef.current, 'pie');

  const plotOptions = useMemo(
    () => ({
      pie: {
        animation: false,
        shadow: false,
        dataLabels: {
          enabled: false,
          connectorWidth: 0,
          distance: 5,
        },
        center: ['50%', '50%'],
        point: {
          events: {
            legendItemClick(event) {
              legendItemClick({
                ...event,
                target: {
                  ...event.target,
                  chart: chartRef.current,
                },
              });
            },
          },
        },
        events: {
          afterAnimate(event) {
            legendItemHover(event);
          },
        },
        states: {
          hover: {
            halo: null,
          },
        },
        borderRadius: 0,
      },
    }),
    [chartRef.current],
  );

  const normalizedSeriesData = useMemo(
    () =>
      seriesDataNormalizer({
        context,
        metric: series.metric,
        series: series.series,
        data: series.data,
        seriesNameFormatter,
        seriesColorIndexFormatter,
        colorIndex,
        labelContext: filters.labelContext,
      }),
    [
      context,
      series.metric,
      series.series,
      series.data,
      seriesNameFormatter,
      seriesColorIndexFormatter,
      colorIndex,
      filters.labelContext,
    ],
  );

  const legendPositionOptions = useMemo(
    () =>
      getLegendPositionOptions({ legendPosition, title, subtitle, pie: true }),
    [legendPosition, title, subtitle],
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  const onLegendItemClick = useCallback(
    (event) => {
      legendItemClick(event);

      // do not open properties tray if chart is in fullscreen mode
      const chart = chartRef.current;
      if (chart?.fullscreen?.isOpen) {
        return;
      }

      const item = {
        name: series?.data?.[event.target.index]?.name,
        series: series.series,
      };

      const propertiesTrayData = getPropertiesTrayData({
        context,
        fields,
        item,
        labelContext: filters.labelContext,
      });

      setPropertiesTray({
        data: propertiesTrayData,
        isOpen: true,
      });
    },
    [context, fields, series, filters.labelContext],
  );

  const onLegendItemContextMenu = useCallback(
    (event) => {
      const legendItem = event.target.closest('.highcharts-legend-item');
      if (!legendItem) {
        return;
      }

      const legendItemsWrapper = legendItem.parentElement;
      const legendItems = [...legendItemsWrapper.children].filter(
        (item) => item.children.length,
      );
      const legendItemIndex = legendItems.indexOf(legendItem);
      if (legendItemIndex === -1) {
        return;
      }

      const legendSubItemsWrapper = event.target.closest('.label-series-group');
      if (!legendSubItemsWrapper) {
        return;
      }

      const legendSubItem = event.target.classList.contains('label-series')
        ? event.target
        : event.target.closest('.label-series');
      if (!legendSubItem) {
        return;
      }

      const legendSubItems =
        legendSubItemsWrapper.getElementsByClassName('label-series');
      const legendSubItemIndex = [...legendSubItems].indexOf(legendSubItem);
      if (legendSubItemIndex === -1) {
        return;
      }

      const item = {
        name: series?.data?.[legendItemIndex]?.name,
        series: series.series,
      };

      let globalContextMenuData = getPropertiesTrayData({
        context,
        fields,
        item,
        labelContext: filters.labelContext,
      });
      globalContextMenuData = [globalContextMenuData[legendSubItemIndex]];

      setGlobalContextMenu({
        data: globalContextMenuData,
        event,
      });
    },
    [context, fields, series, filters.labelContext],
  );

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  return (
    <HSX.HighchartsProvider Highcharts={Highcharts}>
      <HSX.HighchartsChart
        {...defaultProps}
        className={classNames(
          className,
          'pie-chart',
          `p-${colorVariety ?? defaultColorVariety}`,
          `legend-${legendPosition}`,
          {
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
          },
        )}
        plotOptions={plotOptions}
        callback={onChartCallback}
        __colorVariety={colorVariety}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML margin={0}>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Loading isLoading={loading}>
          {waitingOnNql ? lang.waitingOnNql : lang.loading}
        </HSX.Loading>

        <HSX.Legend
          {...legendPositionOptions}
          enabled={legend}
          labelFormat={legendLabelFormatter}
          margin={0}
          spacing={0}
          shadow={false}
          squareSymbol={false}
          symbolWidth={0}
          symbolHeight={0}
          symbolPadding={0}
          reversed={legendReversed}
          reverseLegendClickAction
          alignColumns
          useHTML
        />

        <HSX.Tooltip
          formatter={tooltipFormatter}
          useHTML
          borderRadius={8}
          shadow={false}
          animation={false}
        />

        <HSX.Chart
          type="pie"
          width={width}
          height={height}
          marginLeft={
            legend &&
            [LegendPositions.left, LegendPositions.right].includes(
              legendPosition,
            )
              ? undefined
              : 0
          }
          marginRight={
            legend &&
            [LegendPositions.left, LegendPositions.right].includes(
              legendPosition,
            )
              ? undefined
              : 0
          }
          animation={false}
          reflow={false}
        />

        <HSX.YAxis visible={false}>
          {normalizedSeriesData && (
            <HSX.PieSeries
              name={series.name || 'Outer pie'}
              data={normalizedSeriesData}
              size="100%"
              animation={false}
              dataLabels={{
                formatter: defaultDataLabelFormatter,
              }}
              showInLegend={legend}
              point={{
                events: {
                  legendItemClick: onLegendItemClick,
                },
              }}
              __onLegendItemContextMenu={onLegendItemContextMenu}
            />
          )}
        </HSX.YAxis>
      </HSX.HighchartsChart>
    </HSX.HighchartsProvider>
  );
})`
  ${(props) =>
    props.flagValue && props.showFlag && FlagBackgroundMixin(props.flagValue)}

  .highcharts-empty-series {
    stroke: #767676;
  }

  .highcharts-data-label {
    visibility: hidden;
  }

  ${LegendMixin};
`;

PieChart.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart context.
   */
  context: PropTypes.string,
  /**
   * Inner pie series.
   */
  innerPieSeries: PropTypes.shape({
    name: PropTypes.string,
    metric: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  /**
   * Outer pie series.
   */
  series: PropTypes.shape({
    name: PropTypes.string,
    metric: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  /**
   * If true, legend will be shown.
   */
  legend: PropTypes.bool,
  /**
   * If true, legend will be reversed.
   */
  legendReversed: PropTypes.bool,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * Series color index.
   */
  colorIndex: PropTypes.number,
  /**
   * Series name formatter.
   */
  seriesNameFormatter: PropTypes.func,
  /**
   * Series color index formatter.
   */
  seriesColorIndexFormatter: PropTypes.func,
  /**
   * Legend label formatter.
   */
  legendLabelFormatter: PropTypes.string,
  /**
   * Tooltip formatter.
   */
  tooltipFormatter: PropTypes.func,
  /**
   * Legend position.
   */
  legendPosition: PropTypes.oneOf(Object.values(LegendPositions)),
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
  /**
   * if true, chart data will not be loaded until user inputs NQL (handled in StatsWrapper)
   * Will show the corresponding message on the chart
   */
  waitingOnNql: PropTypes.bool,
};

PieChart.defaultProps = {
  className: '',
  title: null,
  subtitle: null,
  context: undefined,
  series: {},
  legend: true,
  legendReversed: false,
  exporting: true,
  loading: false,
  width: undefined,
  height: 300,
  colorVariety: defaultColorVariety,
  colorIndex: undefined,
  seriesNameFormatter: defaultSeriesValueFormatter,
  seriesColorIndexFormatter: defaultSeriesColorIndexFormatter,
  legendLabelFormatter: defaultLegendLabelFormatter,
  tooltipFormatter: defaultTooltipFormatter,
  legendPosition: LegendPositions.bottom,
  hideTitle: false,
  hideSubtitle: false,
};

export default memo(PieChart);
