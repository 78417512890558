import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';

import PermissionModel from '@/models/Permission';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { Col } from '+components/Layout';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';

import Container from './components/Container';
import Context from './components/Context';
import IdpSection from './components/IdpSection';
import IpSection from './components/IpSection';
import PasswordSection from './components/PasswordSection';
import Separator from './components/Separator';
import Title from './components/Title';
import TwoFactorAuthSection from './components/TwoFactorAuthSection';

const layout = { marginLeft: '140px', width: '580px' };

const Security = () => {
  const permissions = usePermissions(PermissionModel.Resources.account.value);
  const isFetching = useSelector(customerSelectors.isFetching);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isOnAccessAllowedIPs = useFlag('accessAllowedIPs');

  useLoadingIndicator(isFetching);

  const providerValue = useMemo(
    () => ({
      layout,
      permissions,
      isFetching,
      customer,
    }),
    [isFetching, permissions, customer],
  );

  return (
    <Context.Provider value={providerValue}>
      <Container item container={false}>
        <Col
          marginLeft={layout.marginLeft}
          width={layout.width}
          marginBottom="10px"
          item
          container={false}
        >
          <Title>Password</Title>
        </Col>

        <PasswordSection />

        <Col
          marginLeft={layout.marginLeft}
          width={layout.width}
          item
          container={false}
        >
          <Separator />
          <Title>Two-Factor Authentication</Title>
        </Col>

        <TwoFactorAuthSection />

        <Col
          marginLeft={layout.marginLeft}
          width={layout.width}
          item
          container={false}
        >
          <Separator />
          <Title>Single Sign-On (SSO)</Title>
        </Col>

        <IdpSection />

        {isOnAccessAllowedIPs && (
          <Fragment>
            <Col
              marginLeft={layout.marginLeft}
              width={layout.width}
              item
              container={false}
            >
              <Separator />
              <Title>Allowed IPs</Title>
            </Col>

            <IpSection />
          </Fragment>
        )}
      </Container>
    </Context.Provider>
  );
};

export default Security;
