import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';

import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';
import { selectors as globalFiltersUiSelectors } from '@/redux/globalFilters/ui';

import { usePageTabs } from '+components/PageTabs';
import useUIProperty from '+hooks/useUIProperty';

const withRealTimeController = (Component) => (props) => {
  const dispatch = useDispatch();

  const [, activePageTab] = usePageTabs();
  const [windowFocused] = useUIProperty('windowFocused', true);

  const refresher = useSelector(
    globalFiltersSelectors.getRefresher(activePageTab?.id),
  );
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );
  const updateEvery = useSelector(globalFiltersUiSelectors.getUpdateEvery);
  const available = useSelector(globalFiltersUiSelectors.getAvailable);

  const [time, setTime] = useState(null);

  useEffect(() => {
    if (!available || !autoRefresh) {
      setTime((prev) => (prev === null ? prev : null));
      return;
    }
    setTime(updateEvery);
  }, [available, autoRefresh, updateEvery, refresher]);

  const prevTime = useRef(null);
  useEffect(() => {
    const currentTime = Date.now();
    if (
      windowFocused &&
      currentTime - (prevTime.current || currentTime) > updateEvery
    ) {
      dispatch(globalFiltersActions.refreshRefresherAuto());
    }
    prevTime.current = currentTime;
  }, [windowFocused, updateEvery]);

  useInterval(
    () => {
      dispatch(globalFiltersActions.refreshRefresherAuto());
    },
    windowFocused ? time : null,
  );

  return useMemo(() => <Component {...props} />, Object.values(props));
};

export default withRealTimeController;
