import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as rolesActions,
  selectors as rolesSelectors,
} from '@/redux/api/roles';

/**
 * Returns roles.
 * @return {Object}
 */
export const useRoles = () => {
  const dispatch = useDispatch();

  const roles = useSelector(rolesSelectors.getRoles);
  const isRolesFetching = useSelector(rolesSelectors.isFetching);
  const error = useSelector(rolesSelectors.getError);
  const createdId = useSelector(rolesSelectors.getCreatedId);

  const rolesLength = Object.keys(roles || {}).length;
  useEffect(() => {
    if (!rolesLength) {
      dispatch(rolesActions.fetchRoles());
    }
  }, [rolesLength]);

  return { roles, isRolesFetching, error, createdId };
};

export default useRoles;
