import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

export const getRecordDetailsUrl = ({ context, id, customer }) => {
  let search = new URLSearchParams();

  if (customer) {
    search.append('customer', customer);
  }

  if (search.size) {
    search = `?${search.toString()}`;
  }

  switch (context) {
    case ContextTypes.flow:
      return `${RoutePaths.searchFlow}/${id}${search}`;
    case ContextTypes.dns:
      return `${RoutePaths.searchDns}/${id}${search}`;
    case ContextTypes.alerts:
      return `${RoutePaths.searchEvents}/${id}${search}`;
    case ContextTypes.blocks:
      return `${RoutePaths.searchBlocks}/${id}${search}`;
    case ContextTypes.audit:
      return `${RoutePaths.searchAuditLogs}/${id}${search}`;
    default:
      return null;
  }
};
