import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo } from 'react';

import upperFirst from 'lodash.upperfirst';

import { ColorTypes } from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';
import { MessageContainer } from '+components/ConfirmModal';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '+components/Modal';

const defaultTitleTemplate = (action, item) => (
  <Fragment>
    {upperFirst(action || '')} {item}
  </Fragment>
);

const defaultTextTemplate = () => (
  <Fragment>
    <MessageContainer>Do you really want to Regenerate Keys?</MessageContainer>
    <div>
      This process cannot be undone and the VPC flow will not be received until
      the Public Key is updated in your Oracle COS Console.
    </div>
  </Fragment>
);

/**
 * Regenerate dialog.
 */
const RegenerateKeyModal = (props) => {
  const {
    item,
    titleTemplate,
    textTemplate,
    cancelButtonText,
    confirmButtonText,
    isOpen,
    isDisabled,
    toggleOnConfirm,
    onConfirm,
    onToggle,
  } = props;

  const normalizedTitle = useMemo(
    () =>
      typeof titleTemplate === 'function'
        ? titleTemplate(confirmButtonText, item)
        : titleTemplate,
    [titleTemplate, confirmButtonText, item],
  );

  const normalizedText = useMemo(
    () =>
      typeof textTemplate === 'function'
        ? textTemplate(confirmButtonText, item)
        : textTemplate,
    [textTemplate, confirmButtonText, item],
  );

  const onCancelButtonClick = useCallback(() => {
    if (onToggle) {
      onToggle();
    }
  }, [onToggle]);

  const onConfirmButtonClick = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }

    if (toggleOnConfirm && onToggle) {
      onToggle();
    }
  }, [onConfirm, toggleOnConfirm, onToggle]);

  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <ModalHeader onClose={onToggle}>{normalizedTitle}</ModalHeader>

      <ModalBody>{normalizedText}</ModalBody>

      <ModalFooter>
        <Button
          color={ColorTypes.warning}
          type="submit"
          disabled={isDisabled}
          onClick={onConfirmButtonClick}
        >
          {confirmButtonText}
        </Button>

        <Button variant={ButtonVariants.outlined} onClick={onCancelButtonClick}>
          {cancelButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RegenerateKeyModal.propTypes = {
  /**
   * Item to remove.
   */
  item: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  /**
   * Title template function.
   */
  titleTemplate: PropTypes.func,
  /**
   * Text template function.
   */
  textTemplate: PropTypes.func,
  /**
   * Text for the chancel button
   */
  cancelButtonText: PropTypes.string,
  /**
   * Text for the confirm button
   */
  confirmButtonText: PropTypes.string,
  /**
   * Boolean to control the state of the popover.
   */
  isOpen: PropTypes.bool,
  /**
   * Boolean to control the state confirm button.
   */
  isDisabled: PropTypes.bool,
  /**
   * If true, modal will be toggled after confirm.
   */
  toggleOnConfirm: PropTypes.bool,
  /**
   * A callback fired when confirm delete clicked.
   */
  onConfirm: PropTypes.func.isRequired,
  /**
   * A callback fired when the modal toggle.
   */
  onToggle: PropTypes.func.isRequired,
};

RegenerateKeyModal.defaultProps = {
  item: null,
  titleTemplate: defaultTitleTemplate,
  textTemplate: defaultTextTemplate,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Regenerate Keys',
  isDisabled: false,
  isOpen: false,
  toggleOnConfirm: false,
};

export default RegenerateKeyModal;
