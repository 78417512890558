import { useContext } from 'react';

import AuditLogs from '@/pages/AuditLogs';

import nqlLang from '+utils/nqlLang';

import Context from '../Context';

const AuditLogsSection = () => {
  const { user } = useContext(Context);
  return !user.email ? null : (
    <AuditLogs defaultNql={nqlLang.equal('user', user.email)} runOnLoad />
  );
};

export default AuditLogsSection;
