import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import {
  autonomousSystemsRenderer,
  countryCodeSeriesValueRenderer,
  defaultSeriesValueRenderer,
  labelWithDataRenderer,
} from '+components/charts/common/formatters';
import getIntersectFieldName from '+utils/getIntersectFieldName';
import getNqlFieldName from '+utils/getNqlFieldName';
import { timeBounds } from '+utils/timeBounds';

const unknown = 'Unknown';

export const FieldTypes = {
  as: 'as',
  ip: 'ip',
  port: 'port',
  ownerAs: 'owneras',
  ipPort: 'ip:port',
  locCountryCode: 'loc.countrycode',
  srcIpSrcPortDstIp: 'srcIpSrcPortDstIp',
  srcIpDstIpDstPort: 'srcIpDstIpDstPort',
  dstIpDstPortSrcIp: 'dstIpDstPortSrcIp',
  dstIpSrcIpSrcPort: 'dstIpSrcIpSrcPort',
};

export const getSeriesName = (field) => {
  switch (field) {
    case FieldTypes.as:
      return 'srcas → dstas';
    case FieldTypes.ip:
      return 'srcip → dstip';
    case FieldTypes.port:
      return 'srcport → dstport';
    case FieldTypes.srcIpSrcPortDstIp:
      return 'srcip:srcport → dstip';
    case FieldTypes.srcIpDstIpDstPort:
      return 'srcip → dstip:dstport';
    case FieldTypes.dstIpDstPortSrcIp:
      return 'dstip:dstport → srcip';
    case FieldTypes.dstIpSrcIpSrcPort:
      return 'dstip → srcip:srcport';
    case FieldTypes.ipPort:
      return 'srcip:srcport → dstip:dstport';
    case FieldTypes.ownerAs:
      return 'srcowneras → dstowneras';
    case FieldTypes.locCountryCode:
      return 'srcgeo.countrycode → dstgeo.countrycode';
    default:
      return field;
  }
};

export const getSankeyOptions = () =>
  Object.values(FieldTypes)
    .map((value) => ({
      value,
      label: getSeriesName(value),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const sankeyFieldToRequestFields = (field, filters, customers) => {
  const { labelContext } = filters || {};

  let requestFields;
  switch (field) {
    case FieldTypes.as:
      requestFields = [
        'srcas.org',
        'srcas.number',
        'dstas.org',
        'dstas.number',
      ];
      break;
    case FieldTypes.ip:
      requestFields = [
        'srcip',
        labelContext.show && `label.ip.${labelContext.ip}.src`,
        'dstip',
        labelContext.show && `label.ip.${labelContext.ip}.dst`,
      ];
      break;
    case FieldTypes.port:
      requestFields = [
        'srcport',
        labelContext.show && `label.port.${labelContext.port}.src`,
        'protocol',
        'dstport',
        labelContext.show && `label.port.${labelContext.port}.dst`,
      ];
      break;
    case FieldTypes.srcIpSrcPortDstIp:
      // srcip:srcport → dstip
      requestFields = [
        'srcip',
        labelContext.show && `label.ip.${labelContext.ip}.src`,
        'srcport',
        labelContext.show && `label.port.${labelContext.port}.src`,
        'dstip',
        labelContext.show && `label.ip.${labelContext.ip}.dst`,
      ];
      break;
    case FieldTypes.srcIpDstIpDstPort:
      // srcip → dstip:dstport
      requestFields = [
        'srcip',
        labelContext.show && `label.ip.${labelContext.ip}.src`,
        'dstip',
        labelContext.show && `label.ip.${labelContext.ip}.dst`,
        'dstport',
        labelContext.show && `label.port.${labelContext.port}.dst`,
      ];
      break;
    case FieldTypes.dstIpDstPortSrcIp:
      // dstip:dstport → srcip
      requestFields = [
        'dstip',
        labelContext.show && `label.ip.${labelContext.ip}.dst`,
        'dstport',
        labelContext.show && `label.port.${labelContext.port}.dst`,
        'srcip',
        labelContext.show && `label.ip.${labelContext.ip}.src`,
      ];
      break;
    case FieldTypes.dstIpSrcIpSrcPort:
      // dstip → srcip:srcport
      requestFields = [
        'dstip',
        labelContext.show && `label.ip.${labelContext.ip}.dst`,
        'srcip',
        labelContext.show && `label.ip.${labelContext.ip}.src`,
        'srcport',
        labelContext.show && `label.port.${labelContext.port}.src`,
      ];
      break;
    case FieldTypes.ipPort:
      requestFields = [
        'srcip',
        labelContext.show && `label.ip.${labelContext.ip}.src`,
        'dstip',
        labelContext.show && `label.ip.${labelContext.ip}.dst`,
        'srcport',
        labelContext.show && `label.port.${labelContext.port}.src`,
        'dstport',
        labelContext.show && `label.port.${labelContext.port}.dst`,
      ];
      break;
    case FieldTypes.ownerAs:
      requestFields = [
        'srcowneras.org',
        'srcowneras.number',
        'dstowneras.org',
        'dstowneras.number',
      ];
      break;
    case FieldTypes.locCountryCode:
      requestFields = ['srcgeo.countrycode', 'dstgeo.countrycode'];
      break;
    default:
      requestFields = [field];
      break;
  }
  if (customers?.length) {
    requestFields.unshift('customer');
  }
  return requestFields.filter(Boolean).slice(0, 7);
};

export const sankeyToAggRequest = (options) => {
  const {
    start,
    end,
    metric,
    field,
    size,
    search,
    intersect,
    customers,
    filters,
  } = options;
  const { start: filtersStart, end: filtersEnd } = timeBounds(filters);

  const normalizedField = Array.isArray(field) ? field[0] : field;
  const fieldArr = sankeyFieldToRequestFields(
    normalizedField,
    filters,
    customers,
  );

  return {
    start: start ?? filtersStart,
    end: end ?? filtersEnd,
    format: 'keymap',
    series: [
      {
        name: 'sankey',
        metric: metric || 'counts',
        size,
        field: fieldArr,
        ...StatsRequest.makeSearch({
          search: search || filters[getNqlFieldName(ContextTypes.flow)],
          intersect:
            intersect || filters[getIntersectFieldName(ContextTypes.flow)],
        }),
      },
    ],
    ...(customers && { customers }),
  };
};

const container = (items) => `
  <span style="display: flex; flex-wrap: nowrap; align-items: center; gap: 4px; height: 21px">
    ${items.filter(Boolean).join('')}
  </span>
`;

const getFormat = ({ row, field, labelContext, direction, hideSubAccount }) => {
  const { customer } = row;
  const showSubAccount = !hideSubAccount;
  switch (field) {
    case FieldTypes.as: {
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const number = row[`${localDirection}as.number`];
      const org = row[`${localDirection}as.org`];
      return {
        raw: `${customer || ''}${number ?? ''}:${org || ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          autonomousSystemsRenderer({ number, org }),
        ]),
      };
    }
    case FieldTypes.ip: {
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const ipData = row[`${localDirection}ip`];
      const ipLabelsContext = labelContext.ip;
      const ipLabels = !labelContext.show
        ? []
        : row[`label.ip.${labelContext.ip}.${localDirection}`];

      const ipOptions = {
        data: ipData,
        labelsContext: ipLabelsContext,
        labels: ipLabels,
      };

      return {
        raw: `${customer || ''}:${ipData}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(ipOptions),
        ]),
      };
    }
    case FieldTypes.port: {
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const portData = row[`${localDirection}port`];
      const portLabelsContext = row[`${localDirection}port`];
      const portLabels = !labelContext.show
        ? []
        : row[`label.port.${labelContext.port}.${localDirection}`];

      const portOptions = {
        data: portData,
        labelsContext: portLabelsContext,
        labels: portLabels,
      };

      const { protocol } = row;

      return {
        raw: `${customer || ''}:${protocol}:${portData}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(portOptions),
          protocol && defaultSeriesValueRenderer({ value: protocol }),
        ]),
      };
    }
    case FieldTypes.srcIpSrcPortDstIp: {
      // srcip:srcport → dstip
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const ipData = row[`${localDirection}ip`];
      const ipLabelsContext = labelContext.ip;
      const ipLabels = !labelContext.show
        ? []
        : row[`label.ip.${labelContext.ip}.${localDirection}`];

      const ipOptions = {
        data: ipData,
        labelsContext: ipLabelsContext,
        labels: ipLabels,
      };

      let portOptions;
      let portData;
      if (direction === 'from') {
        portData = row[`${localDirection}port`];
        const portLabelsContext = row[`${localDirection}port`];
        const portLabels = !labelContext.show
          ? []
          : row[`label.port.${labelContext.port}.${localDirection}`];

        portOptions = {
          data: portData,
          labelsContext: portLabelsContext,
          labels: portLabels,
        };
      }

      return {
        raw: `${customer || ''}:${ipData}:${portData ?? ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(ipOptions),
          portOptions && labelWithDataRenderer(portOptions),
        ]),
      };
    }
    case FieldTypes.srcIpDstIpDstPort: {
      // srcip → dstip:dstport
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const ipData = row[`${localDirection}ip`];
      const ipLabelsContext = labelContext.ip;
      const ipLabels = !labelContext.show
        ? []
        : row[`label.ip.${labelContext.ip}.${localDirection}`];

      const ipOptions = {
        data: ipData,
        labelsContext: ipLabelsContext,
        labels: ipLabels,
      };

      let portOptions;
      let portData;
      if (direction === 'to') {
        portData = row[`${localDirection}port`];
        const portLabelsContext = row[`${localDirection}port`];
        const portLabels = !labelContext.show
          ? []
          : row[`label.port.${labelContext.port}.${localDirection}`];

        portOptions = {
          data: portData,
          labelsContext: portLabelsContext,
          labels: portLabels,
        };
      }

      return {
        raw: `${customer || ''}:${ipData}:${portData ?? ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(ipOptions),
          portOptions && labelWithDataRenderer(portOptions),
        ]),
      };
    }
    case FieldTypes.dstIpDstPortSrcIp: {
      // dstip:dstport → srcip
      const localDirection = direction === 'from' ? 'dst' : 'src';
      const ipData = row[`${localDirection}ip`];
      const ipLabelsContext = labelContext.ip;
      const ipLabels = !labelContext.show
        ? []
        : row[`label.ip.${labelContext.ip}.${localDirection}`];

      const ipOptions = {
        data: ipData,
        labelsContext: ipLabelsContext,
        labels: ipLabels,
      };

      let portOptions;
      let portData;
      if (direction === 'from') {
        portData = row[`${localDirection}port`];
        const portLabelsContext = row[`${localDirection}port`];
        const portLabels = !labelContext.show
          ? []
          : row[`label.port.${labelContext.port}.${localDirection}`];

        portOptions = {
          data: portData,
          labelsContext: portLabelsContext,
          labels: portLabels,
        };
      }

      return {
        raw: `${customer || ''}:${ipData}:${portData ?? ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(ipOptions),
          portOptions && labelWithDataRenderer(portOptions),
        ]),
      };
    }
    case FieldTypes.dstIpSrcIpSrcPort: {
      // dstip → srcip:srcport
      const localDirection = direction === 'from' ? 'dst' : 'src';
      const ipData = row[`${localDirection}ip`];
      const ipLabelsContext = labelContext.ip;
      const ipLabels = !labelContext.show
        ? []
        : row[`label.ip.${labelContext.ip}.${localDirection}`];

      const ipOptions = {
        data: ipData,
        labelsContext: ipLabelsContext,
        labels: ipLabels,
      };

      let portOptions;
      let portData;
      if (direction === 'to') {
        portData = row[`${localDirection}port`];
        const portLabelsContext = row[`${localDirection}port`];
        const portLabels = !labelContext.show
          ? []
          : row[`label.port.${labelContext.port}.${localDirection}`];

        portOptions = {
          data: portData,
          labelsContext: portLabelsContext,
          labels: portLabels,
        };
      }

      return {
        raw: `${customer || ''}:${ipData}:${portData ?? ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(ipOptions),
          portOptions && labelWithDataRenderer(portOptions),
        ]),
      };
    }
    case FieldTypes.ipPort: {
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const ipData = row[`${localDirection}ip`];
      const ipLabelsContext = labelContext.ip;
      const ipLabels = !labelContext.show
        ? []
        : row[`label.ip.${labelContext.ip}.${localDirection}`];

      const ipOptions = {
        data: ipData,
        labelsContext: ipLabelsContext,
        labels: ipLabels,
      };

      const portData = row[`${localDirection}port`];
      const portLabelsContext = row[`${localDirection}port`];
      const portLabels = !labelContext.show
        ? []
        : row[`label.port.${labelContext.port}.${localDirection}`];

      const portOptions = {
        data: portData,
        labelsContext: portLabelsContext,
        labels: portLabels,
      };

      return {
        raw: `${customer || ''}:${ipData}:${portData}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          labelWithDataRenderer(ipOptions),
          labelWithDataRenderer(portOptions),
        ]),
      };
    }
    case FieldTypes.ownerAs: {
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const number = row[`${localDirection}owneras.number`];
      const org = row[`${localDirection}owneras.org`];
      return {
        raw: `${customer || ''}${number ?? ''}:${org || ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          autonomousSystemsRenderer({ number, org }),
        ]),
      };
    }
    case FieldTypes.locCountryCode: {
      const localDirection = direction === 'from' ? 'src' : 'dst';
      const countrycode = row[`${localDirection}geo.countrycode`];
      return {
        raw: `${customer || ''}${countrycode || ''}`,
        formatted: container([
          showSubAccount &&
            customer &&
            defaultSeriesValueRenderer({ value: customer }),
          countryCodeSeriesValueRenderer({ countrycode }),
        ]),
      };
    }
    default:
      return { raw: unknown, formatted: unknown };
  }
};

export const formatFrom = ({ field, row, labelContext, hideSubAccount }) => {
  return getFormat({
    field,
    row,
    labelContext,
    hideSubAccount,
    direction: 'from',
  });
};

export const formatTo = ({ field, row, labelContext, hideSubAccount }) => {
  return getFormat({
    field,
    row,
    labelContext,
    hideSubAccount,
    direction: 'to',
  });
};
