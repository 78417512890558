import PropTypes from '+prop-types';

import classNames from 'classnames';

import FileUpload from '+components/form/FileUpload';
import FormField from '+components/form/FormField';

const FileUploadField = (props) => {
  const {
    className,
    input: { name, value, onChange, ...input },
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    ...tail
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  return (
    <FormField
      className={classNames(className, 'file-field')}
      label={label}
      helperText={helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <FileUpload
        {...tail}
        {...input}
        name={name}
        value={value}
        invalid={invalid}
        disabled={disabled}
        onChange={onChange}
      />
    </FormField>
  );
};

FileUploadField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FileUploadField.defaultProps = {
  className: null,
  meta: null,
  label: null,
  helperText: null,
  disabled: false,
  required: false,
};

export default FileUploadField;
