import { css } from 'styled-components';

const FlagBackgroundMixin = (flagValue) => css`
  --shadowColor: ${({ theme }) => theme.chartBackground};
  &.chart {
    background-image: url(/flags/${flagValue.toLowerCase()}.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: inherit;
    // darken background for flag
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => `linear-gradient(
            ${theme.chartBackground},
            ${theme.colorTool.alpha(theme.chartBackground, 0.94)},
            ${theme.colorTool.alpha(theme.chartBackground, 0.9)},
            ${theme.colorTool.alpha(theme.chartBackground, 0.35)}
      )`};
      border-radius: inherit;
    }
  }
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-title,
  .highcharts-subtitle {
    text-shadow:
      2px 0 var(--shadowColor),
      -2px 0 var(--shadowColor),
      0 2px var(--shadowColor),
      0 -2px var(--shadowColor),
      1px 1px var(--shadowColor),
      -1px -1px var(--shadowColor),
      1px -1px var(--shadowColor),
      -1px 1px var(--shadowColor);
    .mdi-icon {
      filter: drop-shadow(0 0 2px var(--shadowColor)) !important;
    }
  }
  .highcharts-axis-labels text {
    stroke: var(--shadowColor);
    paint-order: stroke;
    stroke-width: 1.5px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  }
`;

export default FlagBackgroundMixin;
