import RoutePaths from '@/models/RoutePaths';

import { makeFilterUrlParamStr } from '@/redux/globalFilters';

/**
 * @param {object} [options]
 * @param {keyof typeof import('@/models/ContextTypes').ContextTypes} [options.context]
 * @param {number} [options.from]
 * @param {number | 'now'} [options.to]
 * @param {object} [options.period]
 * @param {number} [options.period.value]
 * @param {number | import('@/models/CustomType').CustomType} [options.period.type]
 * @param {string} [options.nql]
 * @param {string} [options.customer]
 * @param {boolean} [options.autoRefresh]
 * @returns {`${string}?${string}`}
 */
export const getSearchUrl = (options) => {
  const { nql, ...rest } = options || {};
  const search = new URLSearchParams();
  const f = makeFilterUrlParamStr({
    ...rest,
    nql: [nql || ''],
  });
  search.append('f', f);
  return `${RoutePaths.search}?${search.toString()}`;
};
