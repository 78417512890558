import { put } from 'redux-saga/effects';

import { createSelector, createSlice, tryCancelSaga } from '@/redux/util';

export const FetchStates = {
  error: 'error',
  init: 'init',
  fetching: 'fetching',
  empty: 'empty',
  success: 'success',
};

const setStatus = (statusKey, status) => (state) => {
  state[statusKey] = status;
  if (status !== FetchStates.error) {
    state.error = null;
  }
  return state;
};

const initialState = {
  cvesStatus: FetchStates.init,
  cves: {},
};

const getUrl = (path) => `/cve/${path}`;

const slice = createSlice({
  name: 'cves',
  initialState,

  reducers: {
    fetchCveById: setStatus('cvesStatus', FetchStates.fetching),
    fetchCveByIdSuccess(state, { payload: { data: { data } = {} } = {} }) {
      if (!data?.id) {
        setStatus('cvesStatus', FetchStates.empty)(state);
        return;
      }
      setStatus('cvesStatus', FetchStates.success)(state);
      state.cves[data.id] = data;
    },
    error(state, { payload: { message, statusKey } }) {
      setStatus(statusKey, FetchStates.error)(state);
      state.error = message;
    },
  },

  sagas: (actions) => ({
    *[actions.fetchCveById]({ payload: id }) {
      yield tryCancelSaga(
        'get',
        {
          successAction: actions.fetchCveByIdSuccess,
          errorAction: actions.fail,
          toasts: {
            error: 'Error fetching CVE data',
          },
          *error(error) {
            yield put(
              actions.error({
                message: error.message,
                statusKey: 'cvesStatus',
              }),
            );
          },
        },
        getUrl(encodeURIComponent(id)),
      );
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(
      [getState],
      (state) => state.cvesStatus === FetchStates.fetching,
    ),
    getCveById: (id) =>
      createSelector(
        [getState],
        (state) => state.cves[(id || '').toUpperCase()],
      ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
