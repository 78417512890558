import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { config } from '@/config';

export const logRocket = {
  init: (customer) => {
    if (!config.logRocket.enabled || !customer?.type || !customer?.status) {
      return;
    }

    // https://docs.logrocket.com/reference/init
    LogRocket.init(config.logRocket.appId, {
      release: config.version,
      shouldDebugLog: config.environment === 'development',
      console: {
        isEnabled: {
          debug: false,
        },
      },
      // https://docs.logrocket.com/reference/dom
      dom:
        // disable cloaking for all PLG customers and other customers (SLG and MSSP) in POV status
        customer.type === 'plg' || customer.status === 'pov'
          ? {}
          : {
              textSanitizer: true,
              inputSanitizer: true,
              // privateAttributeBlocklist: ['data-hide-this'],
              privateClassNameBlocklist: ['highcharts-container'],
            },
    });
    setupLogRocketReact(LogRocket);
  },
  identify: (id, data) => {
    if (!config.logRocket.enabled) {
      return;
    }

    LogRocket.identify(id, data);
  },
  middleware: () =>
    config.logRocket.enabled
      ? LogRocket.reduxMiddleware({
          // https://docs.logrocket.com/reference/redux-logging#sanitize-redux-state
          stateSanitizer: () => null,
          // https://docs.logrocket.com/reference/redux-logging#sanitize-redux-actions
          actionSanitizer: () => null,
        })
      : () => (state) => state,
};
