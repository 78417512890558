import PropTypes from '+prop-types';
import { forwardRef, useEffect, useRef } from 'react';

import classNames from 'classnames';

import FormField from '+components/form/FormField';

import createDescriptionLinks from './createDescriptionLinks';
import NumberInput from './NumberInput';

const NumberField = forwardRef((props, ref) => {
  const {
    className,
    input,
    placeholder,
    label,
    helperText,
    focus,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    readOnly,
    parseDescriptionUrls,
    ...tail
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = ref || input.ref || useRef(null);
  const invalid = error && (dirty || submitFailed) && touched;
  const localPlaceholder = disabled ? '—' : placeholder;

  useEffect(() => {
    if (focus && inputRef) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [focus]);

  return (
    <FormField
      label={label}
      helperText={
        parseDescriptionUrls ? createDescriptionLinks(helperText) : helperText
      }
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <NumberInput
        {...tail}
        {...input}
        ref={inputRef}
        className={classNames(className, { invalid })}
        placeholder={localPlaceholder}
        disabled={disabled}
        readOnly={readOnly}
      />
    </FormField>
  );
});

NumberField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  focus: PropTypes.bool,
  parseDescriptionUrls: PropTypes.bool,
};

NumberField.defaultProps = {
  className: null,
  meta: null,
  placeholder: '',
  label: null,
  helperText: null,
  disabled: false,
  readOnly: false,
  required: false,
  focus: false,
  parseDescriptionUrls: false,
};

export { NumberField };
export default NumberField;
