import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import isEqual from 'lodash.isequal';

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import EqualIcon from 'mdi-react/EqualIcon';

import dayjs from '+utils/dayjs';

import { createFilterValue, FilterOperator } from '../FilterTypeFactories';
import { Container, DateTimePicker, Row } from './Components';

export const TimestampColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  const [localValue, setLocalValue] = useState({
    value: '',
    operator: undefined,
  });

  const min = new Date(2019, 0, 1, 0, 0, 0, 0);
  const max = dayjs().millisecond(0).toDate();

  const onChange = useCallback(
    (nextOperator) => (nextValue) => {
      setLocalValue({
        value: nextValue,
        operator:
          nextValue == null || nextValue === '' ? undefined : nextOperator,
      });
    },
    [],
  );

  useEffect(() => {
    setLocalValue((prevValue) => {
      const nextValue = {
        value: filterValue?.value ?? '',
        operator: filterValue?.operator ?? undefined,
      };
      return isEqual(prevValue, nextValue) ? prevValue : nextValue;
    });
  }, [filterValue]);

  useDebounce(
    () => {
      if (localValue?.value == null || localValue?.value === '') {
        setFilter(undefined);
        return;
      }
      setFilter(createFilterValue(localValue));
    },
    300,
    [localValue],
  );

  return (
    <Container>
      <Row>
        <EqualIcon size={24} />
        <DateTimePicker
          name="12311"
          value={
            localValue.operator === FilterOperator.equal
              ? localValue.value ?? ''
              : ''
          }
          min={min}
          max={max}
          onChange={onChange(FilterOperator.equal)}
        />
      </Row>

      <Row>
        <ChevronLeftIcon size={24} />
        <DateTimePicker
          value={
            localValue.operator === FilterOperator.lessThen
              ? localValue.value ?? ''
              : ''
          }
          min={min}
          max={max}
          onChange={onChange(FilterOperator.lessThen)}
        />
      </Row>

      <Row>
        <ChevronRightIcon size={24} />
        <DateTimePicker
          value={
            localValue.operator === FilterOperator.greaterThen
              ? localValue.value ?? ''
              : ''
          }
          min={min}
          max={max}
          onChange={onChange(FilterOperator.greaterThen)}
        />
      </Row>
    </Container>
  );
};

TimestampColumnFilter.propTypes = {
  column: PropTypes.shape().isRequired,
};
