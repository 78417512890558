import PropTypes from '+prop-types';

import { ContextTypes } from '@/models/ContextTypes';
import { TimeDuration } from '@/models/TimePeriods';
import { WidgetTypes } from '@/models/WidgetTypes';

import { defaultColorVariety } from '+components/charts/common/utils';

import NqlModeTypes from './NqlModeTypes';

export { defaultColorVariety };

export const defaultDateTime = {
  periodValue: 4,
  periodType: TimeDuration.hour,
};

export const widgetPropTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  useDashboardDateTime: PropTypes.bool.isRequired,
  dateTime: PropTypes.shape({
    periodValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    periodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.string.isRequired,
      metric: PropTypes.string,
      size: PropTypes.number,
      interval: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.string),
      nqlMode: PropTypes.oneOf([
        NqlModeTypes.inherit,
        NqlModeTypes.override,
        NqlModeTypes.append,
      ]),
      nql: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      intersect: PropTypes.arrayOf(PropTypes.string),
      customers: PropTypes.arrayOf(PropTypes.string),
      display: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(WidgetTypes)),
        // colors
        colorVariety: PropTypes.number,
        colorIndex: PropTypes.number,
        colorIndexMin: PropTypes.number,
        colorIndexMax: PropTypes.number,
        colorIndexValue: PropTypes.number,
        // chart
        showVertical: PropTypes.bool,
        showStacked: PropTypes.bool,
        showTrend: PropTypes.bool,
        fillArea: PropTypes.bool,
        showFlag: PropTypes.bool,
        hideSubAccount: PropTypes.bool,
        // table
        showHeaders: PropTypes.bool,
        showPagination: PropTypes.bool,
        // value, gauge
        aggregate: PropTypes.shape({
          type: PropTypes.oneOf(['count', 'rate', 'sum', 'cardinality']),
          min: PropTypes.number,
          max: PropTypes.number,
          decimals: PropTypes.number,
          thresholds: PropTypes.arrayOf(PropTypes.number),
        }),
        // alerts
        severities: PropTypes.shape({
          low: PropTypes.bool,
          medium: PropTypes.bool,
          high: PropTypes.bool,
        }),
      }),
    }),
  ),
  settings: PropTypes.shape({
    // common
    exporting: PropTypes.bool,
    showTitle: PropTypes.bool,
    showMetric: PropTypes.bool,
    showLegend: PropTypes.bool,
    legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  }),
};

export const defaultWidgetProps = {
  id: undefined,
  title: '',
  useDashboardDateTime: true,
  dateTime: defaultDateTime,
  series: [
    {
      context: ContextTypes.flow,
      metric: 'inherit',
      size: 0,
      interval: undefined,
      fields: undefined,
      nqlMode: NqlModeTypes.inherit,
      nql: [''],
      intersect: undefined,
      customers: undefined,
      display: {
        type: WidgetTypes.Area,
        // showVertical: false,
        // showHeaders: true,
        // showPagination: true,
        // showTrend: undefined,
        // showFlag: undefined,
        // fillArea: undefined,
        // colorVariety: undefined,
        // colorIndex: undefined,
        // colorIndexMin: undefined,
        // colorIndexMax: undefined,
        // colorIndexValue: undefined,
        // aggregate: {
        //   type: 'sum',
        //   min: null,
        //   max: null,
        //   decimals: 2,
        //   thresholds: [0.7, 0.9],
        // },
        // severities: undefined,
      },
    },
  ],
  settings: {
    exporting: true,
    showTitle: true,
    showMetric: true,
    showLegend: true,
    legendPosition: 'bottom',
  },
};

export const dashboardPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dateTime: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    periodValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    periodType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
  useGlobalDateTime: PropTypes.bool.isRequired,
  nqlMode: PropTypes.oneOf([
    NqlModeTypes.global,
    NqlModeTypes.override,
    NqlModeTypes.append,
  ]),
  nql: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  intersect: PropTypes.arrayOf(PropTypes.string),
  colorVariety: PropTypes.number,
  context: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  layout: PropTypes.arrayOf(
    PropTypes.shape({
      w: PropTypes.number,
      h: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
      i: PropTypes.string,
    }),
  ),
  widgets: PropTypes.arrayOf(PropTypes.shape(widgetPropTypes)),
  public: PropTypes.bool,
};

export const defaultDashboard = {
  id: '',
  title: '',
  description: '',
  context: 'flow',
  useGlobalDateTime: true,
  dateTime: defaultDateTime,
  nqlMode: NqlModeTypes.global,
  nql: [''],
  intersect: [],
  colorVariety: undefined,
  public: false,
  groups: [],
  layout: [],
  widgets: [],
};
