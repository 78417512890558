import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import dayjs from '+utils/dayjs';

import { DateRangePicker } from './DateRangePicker';

const staticRanges = [];
const inputRanges = [];

const addTzDiffToDate = (date) => {
  const dayjsDate = dayjs(date);

  if (!dayjsDate.$u) {
    return dayjsDate.toDate();
  }

  return dayjsDate
    .subtract(dayjsDate.tz(dayjs.tz.guess()).$offset, 'minute')
    .toDate();
};

const subTzDiffToDate = (date) => {
  const dayjsDate = dayjs(date);

  if (!dayjsDate.$u) {
    return dayjsDate.toDate();
  }

  return dayjsDate
    .add(dayjsDate.tz(dayjs.tz.guess()).$offset, 'minute')
    .toDate();
};

export const DateTimeRange = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
}) => {
  const ranges = useMemo(() => {
    return [
      {
        startDate: addTzDiffToDate(startDate),
        endDate: addTzDiffToDate(endDate),
        showDateDisplay: false,
        key: 'selection',
      },
    ];
  }, [startDate, endDate]);

  const fixedMinDate = useMemo(
    () => minDate && addTzDiffToDate(+minDate),
    [minDate],
  );

  const fixedMaxDate = useMemo(
    () => maxDate && addTzDiffToDate(+maxDate),
    [maxDate],
  );

  const doChange = useCallback(
    (item) => {
      onChange?.({
        startDate: dayjs(subTzDiffToDate(item.selection.startDate))
          .startOf('day')
          .toDate(),
        endDate: dayjs(subTzDiffToDate(item.selection.endDate))
          .endOf('day')
          .toDate(),
      });
    },
    [onChange],
  );

  return (
    <DateRangePicker
      ranges={ranges}
      minDate={fixedMinDate}
      maxDate={fixedMaxDate}
      moveRangeOnFirstSelection={false}
      preventSnapRefocus
      // calendarFocus="backwards"
      months={2}
      direction="horizontal"
      staticRanges={staticRanges}
      inputRanges={inputRanges}
      onChange={doChange}
      showSelectionPreview
      showMonthAndYearPickers={false}
      showDateDisplay={false}
      weekdayDisplayFormat="EEEEE"
      monthDisplayFormat="MMMM yyyy"
    />
  );
};

DateTimeRange.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};

DateTimeRange.defaultProps = {
  minDate: null,
  maxDate: null,
};
