// disable proptypes checking due to false positive for column accessor arguments
/* eslint-disable react/prop-types */
import capitalize from 'lodash.capitalize';
import styled from 'styled-components';

import { PluginIcon } from '@/shared/img/icon';

import { PluginLabel } from '+components/Labels/PluginLabel';
import { HeaderSubheaderCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories';
import Tag from '+components/Tag';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import { ResponseIntegrationModels } from '../../shared/ResponseIntegrationModels';
import { formatResponseIntegrationConfig } from './formatters';

export const Columns = {
  rowSelector: 'rowSelector',
  adapter: 'adapter',
  name: 'name',
  type: 'type',
  severities: 'severities',
  menu: 'menu',
  error: 'error',
};

const ErrorTag = styled(Tag)`
  cursor: pointer;
`;

const columnsCollection = ({ onErrorClick }) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.adapter]: BaseColumnFactory({
    accessor: 'adapter',
    Header: 'Provider',
    width: 250,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (
      <PluginLabel
        adapter={value}
        title={ResponseIntegrationModels[value]?.title}
        size={14}
      />
    ),
    Filter: SelectColumnFilter({
      optionIcon: (value) =>
        value === 'all' ? null : <PluginIcon name={value} size={14} />,
      optionLabel: (value) =>
        ResponseIntegrationModels[value]?.title || capitalize(value),
      sort: (a, b) =>
        ResponseIntegrationModels[a]?.group >
        ResponseIntegrationModels[b]?.group
          ? 1
          : -1,
      selectProps: {
        groupBy: (option) => ResponseIntegrationModels[option.value]?.group,
      },
    }),
    filter: 'selectFilter',
  }),
  [Columns.name]: BaseColumnFactory({
    accessor: 'name',
    Header: 'Name / Desc',
    width: 400,
    Cell: HeaderSubheaderCell({
      propHeader: 'name',
      propSubheader: 'description',
    }),
    realAccessor: ['name', 'description'],
    filter: someOfFieldsFilter(['name', 'description']),
  }),
  [Columns.type]: BaseColumnFactory({
    accessor: 'type',
    Header: 'Type',
    width: 150,
    // TODO need understand why it's eslint error
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      if (typeof value !== 'string') {
        return null;
      }

      return 'dns' === value ? 'DNS' : capitalize(value);
    },
    filter: 'selectFilter',
    Filter: SelectColumnFilter({
      optionLabel: (value) => (value === 'dns' ? 'DNS' : capitalize(value)),
    }),
  }),
  [Columns.severities]: BaseColumnFactory({
    accessor: 'config',
    Header: 'Config',
    Cell: ({ row }) => {
      const original = getRowOriginal(row);
      return original?.config?.check_error ? (
        <ErrorTag
          color="danger"
          outlined
          onClick={() =>
            onErrorClick({
              name: original.name,
              error: original.config.check_error,
            })
          }
        >
          Error
        </ErrorTag>
      ) : (
        formatResponseIntegrationConfig(original)
      );
    },
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
  }),
  [Columns.menu]: MenuColumnFactory,
});

export const getColumns = getColumnsHelper(columnsCollection);
