import { useCallback, useEffect, useState } from 'react';

import Highcharts from 'highcharts/highstock';
import cloneDeep from 'lodash.clonedeep';

// @see: https://stackoverflow.com/questions/35932078/remove-tooltip-in-synchronized-charts-when-user-leaves-the-chart-area
// @and: https://www.highcharts.com/demo/synchronized-charts

// const getSynchronizedCharts = () => (Highcharts.charts || []).filter((chart) => chart?.options.chart.__synchronizeTooltip);
// const getSynchronizedCharts = () => (Highcharts.charts || []).filter((chart) => chart && ['area', 'line', 'scatter'].includes(chart?.series[0]?.type));

/**
 * Hook for synchronize charts tooltips on the page
 *
 * @param {useRef} containerRef - ref of parent container
 * @param {boolean?} disabled - if true, synchronizer will be disabled
 * @return null
 */
const useSynchronizedCharts = (containerRef, disabled) => {
  const [syncCharts, setSyncCharts] = useState([]);

  const onContainerMouseMove = useCallback(() => {
    requestAnimationFrame(() => {
      const newSyncCharts = (Highcharts.charts || []).filter(
        (chart) => chart?.options.chart.__synchronizeTooltip,
      );
      setSyncCharts((prevValue) =>
        prevValue.length !== newSyncCharts.length ? newSyncCharts : prevValue,
      );
    });
  }, []);

  const onChartMouseMove = useCallback(
    (event) => {
      const { hoveredChart } = event.currentTarget;
      const isTimeseriesSeriesHovered = !event.target.point;

      requestAnimationFrame(() => {
        if (!hoveredChart.pointer || !isTimeseriesSeriesHovered) {
          return;
        }

        const hoveredChartPlot = hoveredChart.plotBackground.element;
        const hoveredChartPlotRect = hoveredChartPlot.getBoundingClientRect();
        const isMouseOverPlot =
          event.clientX >= hoveredChartPlotRect.left &&
          event.clientX <= hoveredChartPlotRect.right &&
          event.clientY >= hoveredChartPlotRect.top &&
          event.clientY <= hoveredChartPlotRect.bottom;

        if (!isMouseOverPlot) {
          return;
        }

        const hoveredChartEvent = hoveredChart.pointer.normalize(event); // Find coordinates within the chart
        const hoveredSeries = hoveredChart.series.find(
          (item) => item?.userOptions?.__synchronizeTooltip !== false,
        );
        const hoveredPoint = hoveredSeries?.searchPoint(
          hoveredChartEvent,
          true,
        ); // Get the hovered point

        if (!hoveredPoint) {
          return;
        }

        // hoveredPoint.onMouseOver?.();
        // hoveredChart.xAxis[0].drawCrosshair(hoveredChartEvent, hoveredPoint);

        syncCharts.forEach((chart) => {
          if (chart === hoveredChart) {
            return;
          }

          const firstSeries = chart.series?.find(
            (item) => item?.userOptions?.__synchronizeTooltip !== false,
          );

          if (!firstSeries?.points) {
            return;
          }

          // Try to find same point on other charts
          // If current chart and hovered chart got same width and scale and points length
          // Point will have same index
          let point = firstSeries.points[hoveredPoint.index];

          // If current chart and hovered chart got different width or scale or points length
          // Try to find point among all points
          if (point?.x !== hoveredPoint.x) {
            point = firstSeries.points.find(
              (_point) => _point.x === hoveredPoint.x,
            );
          }

          // Workaround for scatter plot in boost mode
          // In that mode we need to receive full point object by getPoint()
          if (point && !point.onMouseOver && firstSeries?.getPoint) {
            point = firstSeries.getPoint(point);
            point?.setState?.('');
          }

          // We found point on chart - let's highlight it
          if (point && point.onMouseOver) {
            point.onMouseOver();
            // chart.xAxis[0].drawCrosshair(hoveredChartEvent, point);
          }
        });
      });
    },
    [syncCharts],
  );

  const onChartMouseLeave = useCallback(
    (event) => {
      requestAnimationFrame(() => {
        syncCharts.forEach((chart) => {
          if (chart.pointer) {
            const normalizedEvent = chart.pointer.normalize(event);
            const series = chart.series.find(
              (item) => item?.userOptions?.__synchronizeTooltip !== false,
            );
            const point = series?.searchPoint(normalizedEvent, true);

            if (point) {
              point.onMouseOut?.();
              chart.tooltip.hide(point);
              chart.xAxis[0].hideCrosshair();
            }

            // set all series to normal state
            chart.series.forEach((item) => {
              item?.setState?.('normal');
            });
          }
        });
      });
    },
    [syncCharts],
  );

  useEffect(() => {
    if (disabled || !syncCharts.length) {
      return undefined;
    }

    syncCharts.forEach((chart) => {
      if (chart?.container) {
        // Save chart object to get it from event
        chart.container.hoveredChart = chart;

        // Override the reset function, we don't need to hide the tooltips and crosshairs
        chart.container.oldReset = cloneDeep(chart.pointer).reset;
        chart.pointer.reset = () => {};

        // Add mousemove event (show tooltips)
        chart.container.addEventListener('mousemove', onChartMouseMove);

        // Add mouseleave event (hide tooltips)
        chart.container.addEventListener('mouseleave', onChartMouseLeave);
      }
    });

    return () => {
      syncCharts.forEach((chart) => {
        if (chart?.container) {
          // Restore reset function
          chart.pointer.reset = chart.container.oldReset;
          // Remove temp fields
          delete chart.container.hoveredChart;
          delete chart.container.oldReset;
          // Remove events
          chart.container.removeEventListener('mousemove', onChartMouseMove);
          chart.container.removeEventListener('mouseleave', onChartMouseLeave);
        }
      });
    };
  }, [disabled, syncCharts, onChartMouseMove, onChartMouseLeave]);

  useEffect(() => {
    if (disabled || !containerRef.current) {
      return undefined;
    }

    containerRef.current.addEventListener('mousemove', onContainerMouseMove);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener(
          'mousemove',
          onContainerMouseMove,
        );
      }
    };
  }, [disabled, containerRef.current, onContainerMouseMove]);

  return null;
};

export default useSynchronizedCharts;
