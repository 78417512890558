import PropTypes from '+prop-types';
import { useCallback } from 'react';

import styled from 'styled-components';

import AddIcon from 'mdi-react/AddIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import { Field, useForm } from '+components/form/FinalForm';
import PhoneNumberField from '+components/form/PhoneNumberField';
import { validateRequired } from '+components/form/Validators';
import IconButtonOrigin from '+components/IconButton';
import { Col, LayoutSizes, Row } from '+components/Layout';

const IconButton = styled(IconButtonOrigin)`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.$hidden ? 'none' : 'auto')};
  margin-top: 4px;
`;

const PhoneInput = (props) => {
  const {
    name,
    fields,
    index,
    country,
    required,
    disabled,
    onAdd,
    onRemove,
    onCountryChange,
  } = props;

  return (
    <Row gap={LayoutSizes.groupGap} wrap="nowrap">
      <Col container={false} xs item>
        <Field
          name={`${name}.number`}
          component={PhoneNumberField}
          defaultCountry={country}
          country={country}
          onCountryChange={onCountryChange}
          validate={required ? validateRequired : undefined}
          required={required}
          disabled={disabled}
        />
      </Col>

      {fields.length > 1 && (
        <IconButton
          color="primary"
          title="Remove Number"
          disabled={disabled}
          onClick={onRemove}
        >
          <DeleteForeverIcon size={16} />
        </IconButton>
      )}

      <IconButton
        color="primary"
        title="Add Number"
        $hidden={fields.length !== index + 1}
        disabled={disabled}
        onClick={onAdd}
      >
        <AddIcon size={16} />
      </IconButton>
    </Row>
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.shape().isRequired,
  country: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCountryChange: PropTypes.func.isRequired,
};

const RenderPhoneInputArray = (props) => {
  const { fields, required, disabled } = props;

  const form = useForm();

  const onAdd = useCallback(
    () => fields.push({ number: '', region: 'US' }),
    [fields],
  );

  const onRemove = useCallback((index) => () => fields.remove(index), [fields]);

  const onCountryChange = useCallback(
    (name) => (value) => form.change(`${name}.region`, value),
    [],
  );

  return (
    <Col gap="15px">
      {fields.map((name, index) => (
        <PhoneInput
          key={name}
          name={name}
          fields={fields}
          index={index}
          country={fields.value[index].region}
          required={required}
          disabled={disabled}
          onAdd={onAdd}
          onRemove={onRemove(index)}
          onCountryChange={onCountryChange(name)}
        />
      ))}
    </Col>
  );
};

RenderPhoneInputArray.propTypes = {
  fields: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

RenderPhoneInputArray.defaultProps = {
  required: false,
  disabled: false,
};

export default RenderPhoneInputArray;
