import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import get from 'lodash.get';
import isEqual from 'lodash.isequal';

import { useForm, useFormState } from '+components/form/FinalForm';
import { usePageTabs } from '+components/PageTabs';

const usePageTabsAndFormSync = () => {
  const [, activePageTab, pageTabMethods] = usePageTabs();
  const { pathname } = useLocation();

  const form = useForm();

  const { values: formValues, dirty: formDirty } = useFormState({
    subscription: {
      values: true,
      dirty: true,
    },
  });

  const formValuesRef = useRef();
  formValuesRef.current = formValues;
  useEffect(() => {
    if (!activePageTab?.formValues) {
      return;
    }

    if (activePageTab.pathname !== pathname) {
      return;
    }

    if (isEqual(activePageTab.formValues, formValuesRef.current)) {
      return;
    }

    const registeredFields = form.getRegisteredFields();
    form.batch(() => {
      registeredFields.forEach((field) => {
        // form.resetFieldState(field);
        // form.mutators.touched(field, true);
        form.change(field, get(activePageTab.formValues, field));
      });
    });
  }, [pathname, activePageTab?.pathname, activePageTab?.formValues]);

  // we need to give time to initialize the form before we start syncing
  useDebounce(
    () => {
      pageTabMethods.updateActive({
        formValues: formDirty ? formValues : null,
      });
    },
    300,
    [formValues, formDirty],
  );
};

export default usePageTabsAndFormSync;
