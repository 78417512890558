import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/api/tableSettings';

import useLoadingIndicator from '+hooks/useLoadingIndicator';

export const useTableSettings = (id) => {
  const dispatch = useDispatch();

  const { isFetching, lastAdded } = useSelector(selectors.getState);

  useLoadingIndicator(isFetching);

  const presets = useSelector(selectors.getPresets(id || ''));

  const companyDefaultPresetId = useMemo(
    () => Object.values(presets).find((preset) => preset.isDefault)?.id,
    [presets],
  );

  const defaultPresetId = useMemo(
    () => Object.values(presets).find((p) => p.system && p.isDefault)?.id,
    [presets],
  );

  const saveConfig = useCallback(
    (config) => {
      if (!id) {
        return;
      }

      dispatch(
        actions.create({
          tableId: id,
          ...config,
        }),
      );
    },
    [id],
  );

  const updateConfig = useCallback((config) => {
    if (!config) {
      return;
    }

    dispatch(actions.update(config));
  }, []);

  const removeConfig = useCallback((configId) => {
    dispatch(actions.remove(configId));
  }, []);

  const toggleDefault = useCallback((configId, isDefault) => {
    dispatch(
      actions.toggleDefault({
        id: configId,
        isDefault,
      }),
    );
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(actions.fetch(id));
    }
  }, [id]);

  return {
    companyDefaultPresetId,
    defaultPresetId,
    lastAdded,
    presets,
    saveConfig,
    updateConfig,
    removeConfig,
    toggleDefault,
  };
};
