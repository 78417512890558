import { useMemo } from 'react';

import isFunction from 'lodash.isfunction';

const getInstanceBeforeDimensions = (ref) => (instance) => {
  const nodeWidth = ref.current?.clientWidth || 0;

  const { headerGroups } = instance;

  const [{ headers } = {}] = headerGroups.slice(-1);

  let delta = 0;
  const autoColumns = [];
  headers?.forEach((column) => {
    if (!column.isVisible) {
      return;
    }

    if (isFunction(column.getCellProps)) {
      const cellProps = column.getCellProps();
      // if (cellProps.style.display === 'none') {
      //   return;
      // }
      if (cellProps?.style?.minWidth !== undefined) {
        column.minWidth = cellProps.style.minWidth;
      }
      if (cellProps?.style?.maxWidth !== undefined) {
        column.maxWidth = cellProps.style.maxWidth;
      }
    }

    if (column.minWidth === column.maxWidth) {
      column.width = column.minWidth;
    }

    if (column.width === 'auto') {
      autoColumns.push(column);
    } else {
      delta += column.width;
    }
  });

  if (nodeWidth && autoColumns.length) {
    autoColumns.sort((a, b) => b.maxWidth - a.maxWidth);

    while (autoColumns.length) {
      const width = (nodeWidth - delta) / autoColumns.length;
      const item = autoColumns.pop();

      item.width = Math.max(item.minWidth, Math.min(width, item.maxWidth));
      delta += item.width;
    }
  }
};

const getTableProps = (props, { instance }) => [
  props,
  {
    style: {
      minWidth: `${instance.totalColumnsWidth}px`,
    },
  },
];

const getRowStyles = (props, { instance }) => [
  props,
  {
    style: {
      display: 'flex',
      flex: '1 0 auto',
      minWidth: `${instance.totalColumnsWidth}px`,
    },
  },
];

const needFlex = (value /* , canResize */) => {
  if (Number.isNaN(value)) {
    return '1 1 0';
  }

  return undefined;
};

const maxWidth = (value /* , max */) => {
  return `${value}px`;
};

const getHeaderProps = (props, { column }) => [
  props,
  {
    style: {
      flex: needFlex(column.totalWidth, column.canResize),
      minWidth: `${Math.max(column.totalMinWidth, column.totalWidth)}px`,
      width: `${column.totalWidth}px`,
      maxWidth: maxWidth(column.totalWidth, column.totalMaxWidth),
    },
  },
];

const getFooterProps = getHeaderProps;

const getCellProps = (props, { cell }) => [
  props,
  {
    style: {
      flex: needFlex(cell.column.totalWidth, cell.column.canResize),
      minWidth: `${Math.max(
        cell.column.totalMinWidth,
        cell.column.totalWidth,
      )}px`,
      width: `${cell.column.totalWidth}px`,
      maxWidth: maxWidth(cell.column.totalWidth, cell.column.totalMaxWidth),
    },
  },
];

export const useFixedFlexLayout = (ref) => {
  const method = useMemo(() => getInstanceBeforeDimensions(ref), []);

  return (hooks) => {
    hooks.useInstanceBeforeDimensions.push(method);

    hooks.getTableProps.push(getTableProps);
    hooks.getRowProps.push(getRowStyles);
    hooks.getHeaderGroupProps.push(getRowStyles);
    hooks.getFooterGroupProps.push(getRowStyles);
    hooks.getHeaderProps.push(getHeaderProps);
    hooks.getCellProps.push(getCellProps);
    hooks.getFooterProps.push(getFooterProps);
  };
};

useFixedFlexLayout.pluginName = 'useFixedFlexLayout';
