import PropTypes from '+prop-types';

import GenericLabel from '+components/Labels/ContextLabel';
import {
  CellIp,
  CellWrapper,
  ShowMoreWrapper,
  UniversalCell,
} from '+components/Table/Cells';
import {
  MenuColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { withAutoRemove } from '+components/Table/FilterTypeFactories';
import { autoRemoveIfAll } from '+components/Table/ReactTable/utils';

export const Columns = {
  menu: 'menu',
  track_by: 'track_by',
  rollup_period: 'rollup_period',
  thresholds: 'thresholds',
  update_interval: 'update_interval',
};

const ipCellValues = ['dstip', 'dstport', 'flowsrcip', 'srcip', 'srcport'];

const CellForDisplay = (props) => {
  const { trackObj, row } = props;

  if (ipCellValues.includes(trackObj.trackKey)) {
    return (
      <CellIp
        field={trackObj.trackKey}
        value={trackObj.trackValue}
        original={row.original}
        fetchLabels={
          trackObj.trackKey === 'dstip' ||
          trackObj.trackKey === 'srcip' ||
          trackObj.trackKey === 'flowsrcip'
        }
        hideUnderline
      />
    );
  }

  return (
    <CellWrapper
      key={trackObj.trackKey}
      field={trackObj.trackKey}
      value={trackObj.trackValue}
    >
      {trackObj.trackValue}
    </CellWrapper>
  );
};

CellForDisplay.propTypes = {
  trackObj: PropTypes.shape().isRequired,
  row: PropTypes.shape().isRequired,
};

export const getColumns = ({ cxActionMenu }) => [
  RowSelectorColumnFactory(),
  {
    accessor: 'trackValues',
    Header: 'Track Values',
    minWidth: 240,
    Cell: (row) => (
      <ShowMoreWrapper>
        {row?.value?.map((trackObj) => (
          <GenericLabel
            key={`${trackObj.trackKey}${trackObj.trackValue}`}
            context={trackObj.trackKey}
            clickable={
              ipCellValues.includes(trackObj.trackKey) ||
              trackObj.trackKey === 'tag' ||
              trackObj.trackKey === 'protocol'
            }
          >
            <CellForDisplay trackObj={trackObj} row={row} />
          </GenericLabel>
        ))}
      </ShowMoreWrapper>
    ),

    filter: withAutoRemove((rows, [id], filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }
      return rows.filter((row) =>
        row.values[id].some((track) => {
          const mergedTrack = `${track.trackKey}${track.trackValue}`;
          return mergedTrack.includes(filterValue.value.replace(' ', ''));
        }),
      );
    }, autoRemoveIfAll),
  },
  {
    accessor: 'rollupperiod',
    Header: 'Rollup Period',
    minWidth: 240,
    Cell: (row) => row.value,
  },
  {
    accessor: 'thresholds',
    Header: 'Thresholds',
    minWidth: 240,
    Cell: UniversalCell('thresholds'),
  },
  {
    accessor: 'updateinterval',
    Header: 'Update Interval',
    minWidth: 240,
    Cell: (row) => row.value,
  },
  MenuColumnFactory({ cxActionMenu }),
];
