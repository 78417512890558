import styled from 'styled-components';

import { DateTimePickerField as DateTimePickerFieldOrigin } from '+components/form/DateTimePicker';
import MultiSelectFieldOrigin from '+components/form/MultiSelectField';
import NumberFieldOrigin from '+components/form/NumberField';
import SelectFieldOrigin from '+components/form/SelectField';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.div`
  border-left: 1px solid ${globalFiltersTheme.separatorColor};
  margin: 0;
  margin-left: 25px;

  ${({ theme }) => theme.laptop`
    &:nth-last-child(2) {
      display: none;
    }
  `}

  ${({ theme }) => theme.mobileL`
    display: none;
  `}
`;

export const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 5px;
  opacity: ${(props) => (props.$disabled ? 0.25 : 1)};
`;

export const Group = styled(Col)`
  span,
  div,
  p,
  textarea,
  input {
    color: ${({ theme }) => theme.colorText};
  }
`;

export const Label = styled(({ title, children, ...tail }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for
  <label {...tail}>
    {title != null && <span className="text">{title}</span>}
    {children}
  </label>
))`
  display: flex;
  flex-direction: column;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : undefined)};
  width: 100%;

  & .text {
    opacity: ${(props) => (props.$disabled ? 0.3 : 1)};
    pointer-events: ${(props) => (props.$disabled ? 'none' : 'inherit')};
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .form__form-group {
    margin-bottom: unset;
  }

  .form__form-group-error {
    margin-top: unset;
  }
`;

export const NumberField = styled(NumberFieldOrigin)`
  .rc-input-number-disabled {
    opacity: 0.6;

    .rc-input-number-input {
      border: 1px solid ${({ theme }) => theme.colorFieldsBorder} !important;
      // cursor: not-allowed;
      padding: 0 7px !important;
    }
  }
`;

export const SelectField = styled(SelectFieldOrigin)`
  .MuiInputBase-root.Mui-disabled {
    opacity: 0.6;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder} !important;

    .MuiInputBase-input {
      // cursor: not-allowed;
      padding: 0 7px !important;
    }
  }
`;

export const MultiSelectField = styled(MultiSelectFieldOrigin)`
  .MuiInputBase-root.Mui-disabled {
    opacity: 0.6;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder} !important;
    padding: 0 7px !important;
    // cursor: not-allowed !important;

    .MuiAutocomplete-input,
    .MuiChip-root,
    .MuiChip-label {
      cursor: not-allowed !important;
    }
  }
`;

export const DateTimePickerField = styled(DateTimePickerFieldOrigin)`
  .MuiInputBase-root.Mui-disabled {
    opacity: 0.6;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder} !important;

    .MuiInputBase-input {
      // cursor: not-allowed;
      padding: 0 7px !important;
    }
  }
`;
