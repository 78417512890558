import PropTypes from '+prop-types';
import { Fragment } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import {
  getPropertiesList,
  SubDataColumn,
  SubDataRow,
  SubDataValue,
  SubDetails,
} from '+components/SubDetails';

export { getRowHeight } from '+components/SubDetails';

const originDetails = [
  {
    field: 'site',
    label: 'site',
  },
  {
    field: 'flowsrcname',
    label: 'flowsrcname / source',
  },
  {
    field: 'flowtype',
    label: 'flowtype / type',
  },
  {
    field: 'flowversion',
    label: 'flowversion / version',
  },
  {
    field: 'timestamp',
    label: 'timestamp',
  },
];

const network = [
  {
    field: 'srcip',
    label: 'srcip',
  },
  {
    field: 'srcport',
    label: 'srcport',
  },
  {
    field: 'srcinternal',
    label: 'srcinternal',
  },
  {
    field: 'dstip',
    label: 'dstip',
  },
  {
    field: 'dstport',
    label: 'dstport',
  },
  {
    field: 'dstinternal',
    label: 'dstinternal',
  },
  {
    field: 'protocol',
    label: 'protocol',
  },
];

const statistic = [
  {
    field: 'packets',
    label: 'packets',
  },
  {
    field: 'bits',
    label: 'bits',
  },
  {
    field: 'samplerate',
    label: 'samplerate',
  },
  {
    field: 'duration',
    label: 'duration',
  },
  {
    field: 'pbratio',
    label: 'pbratio',
  },
  {
    field: 'flowbrate',
    label: 'flowbrate',
  },
  {
    field: 'flowprate',
    label: 'flowprate',
  },
];

const DataBody = ({ record }) => (
  <Fragment>
    <SubDataColumn title="Origin Details">
      {getPropertiesList(record, originDetails)}
    </SubDataColumn>
    <SubDataColumn title="Network">
      {getPropertiesList(record, network)}
    </SubDataColumn>
    <SubDataColumn title="Statistic">
      {getPropertiesList(record, statistic)}
    </SubDataColumn>
    <SubDataColumn title="Tags">
      {(record.tags || []).map((tag) => (
        <SubDataRow key={tag}>
          <SubDataValue>{tag}</SubDataValue>
        </SubDataRow>
      ))}
    </SubDataColumn>
  </Fragment>
);

DataBody.propTypes = {
  record: PropTypes.shape().isRequired,
};

const SubFlowDetails = (props) => (
  <SubDetails
    context={ContextTypes.flow}
    rowData={props}
    Component={DataBody}
  />
);

export default SubFlowDetails;
