import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';

import styled, { css } from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import { selectors as profileSelectors } from '@/redux/api/user/profile';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';
import { selectors as globalFiltersUiSelectors } from '@/redux/globalFilters/ui';

import Alert from '+components/Alert';
import GlobalFiltersPanel from '+components/GlobalFilters/Panel';
import useCustomerStatuses from '+hooks/useCustomerStatuses';
import useEvent from '+hooks/useEvent';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';

import {
  sideBarRightWidth as defaultSideBarRightWidth,
  separatorWidth,
} from './shared/constants';
import { Banner, TrialBanner } from './Banner';
import Body from './Body';
import GlobalContextMenu from './GlobalContextMenu';
import { ChangeNqlPreset, RemoveNqlPreset } from './GlobalNqlPresets';
import PropertiesTray from './PropertiesTray';
import ScrollBar from './ScrollBar';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import Topbar from './Topbar';
import TtouModal from './TtouModal';

let passiveSupported = null;
const passiveEventSupported = () => {
  // memoize support to avoid adding multiple test events
  if (typeof passiveSupported === 'boolean') {
    return passiveSupported;
  }

  let supported = false;
  try {
    const options = {
      get passive() {
        supported = true;
        return false;
      },
    };

    window.addEventListener('test', null, options);
    window.removeEventListener('test', null, options);
  } catch (err) {
    supported = false;
  }

  passiveSupported = supported;
  return passiveSupported;
};

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colorBackgroundBody};
  ${(props) =>
    !props.$exportMode &&
    css`
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    `}
`;

const MasqueradingIndicator = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.Palette.danger};
`;

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ModalRoot = styled.div.attrs({ id: 'modal-root' })`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8888;
  visibility: hidden;
`;

const NoDNSSourcesAlert = () => (
  <Alert severity="info">
    No DNS traffic source detected. To display DNS records, please configure a{' '}
    <Link to={{ pathname: `${RoutePaths.sources}` }}>DNS traffic source</Link>.
  </Alert>
);

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const [hideNav] = useUIProperty('hideNav');
  const [isUnderCovered] = useUIProperty('underCover');
  const [isImpersonating] = useUIProperty('impersonating');
  const [version] = useUIProperty('version', '');
  const [expectedVersion] = useUIProperty('expectedVersion');
  const [showNewReleaseBanner, setShowNewReleaseBanner] = useUIProperty(
    'showNewReleaseBanner',
    false,
  );
  const [showNoDNSSourcesAlert, setShowNoDNSSourcesAlert] = useUIProperty(
    'showNoDNSSourcesAlert',
    false,
  );

  const [propertiesTray] = useUIProperty('propertiesTray', null);
  const [sideBarLeftCollapse, setSideBarLeftCollapse] = usePortalSettingsValue(
    SettingCategories.ui,
    'sideBarLeftCollapse',
  );
  const [sideBarLeftCollapseLocal, setSideBarLeftCollapseLocal] = useUIProperty(
    'sideBarLeftCollapse',
    hideNav || (sideBarLeftCollapse ?? false),
  );
  const [sideBarRightWidth, setSideBarRightWidth] = usePortalSettingsValue(
    SettingCategories.ui,
    'sideBarRightWidth',
  );
  const [sideBarRightWidthLocal, setSideBarRightWidthLocal] = useUIProperty(
    'sideBarRightWidth',
    sideBarRightWidth ?? defaultSideBarRightWidth,
  );

  const [, setIsSideBarRightResizing] = useUIProperty(
    'isSideBarRightResizing',
    false,
  );
  const [isLayoutExportMode] = useUIProperty('isLayoutExportMode', false);

  const customerStatuses = useCustomerStatuses();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const isAdmin = useSelector(profileSelectors.isAdminUser);
  const isGlobalFilterAvailable = useSelector(
    globalFiltersUiSelectors.getAvailable,
  );
  const context = useLastAllowedContext();

  const devices = useSelector(deviceSelectors.getDevices);
  const hasDevices = !!Object.keys(devices || {}).length;
  useEffect(() => {
    if (!hasDevices) {
      dispatch(deviceActions.devicesFetch(null, 'layout_devices'));
    }
    return () => {
      dispatch(deviceActions.cancel('layout_devices'));
    };
  }, [hasDevices]);

  const vpcs = useSelector(vpcSelectors.getVpcs);
  const hasVpcs = !!Object.keys(vpcs || {}).length;
  useEffect(() => {
    if (!hasVpcs) {
      dispatch(vpcActions.fetchVpcs(null, 'layout_vpcs'));
    }
    return () => {
      dispatch(vpcActions.cancel('layout_vpcs'));
    };
  }, [hasVpcs]);

  const hasDNSSources = useMemo(() => {
    return (
      Object.values(devices || {}).some(
        (device) => device.traffictype === 'dns',
      ) || Object.values(vpcs || {}).some((vpc) => vpc.traffictype === 'dns')
    );
  }, [devices, vpcs]);

  const bodyScrollRef = useRef();

  const customerStatusMeta = customerStatuses[customer?.status];
  const showTtouModal =
    customerStatusMeta?.trial &&
    customer.accepted_ttou == null &&
    isAdmin &&
    !(isUnderCovered || isImpersonating);

  const onPageReload = useCallback(() => {
    window.location.reload();
  }, []);

  const onToggleLeftSidebar = useCallback(() => {
    setSideBarLeftCollapseLocal((prev) => !prev);
    setSideBarLeftCollapse(!(sideBarLeftCollapse ?? false));
  }, [hideNav, sideBarLeftCollapse]);

  const onResizeStart = useEvent((event) => {
    event.preventDefault();
    event.stopPropagation();

    let isTouchEvent = false;
    if (event.type === 'touchstart') {
      // lets not respond to multiple touches (e.g. 2 or 3 fingers)
      if (event.touches && event.touches.length > 1) {
        return;
      }
      isTouchEvent = true;
    }

    const clientX = isTouchEvent
      ? Math.round(event.touches[0].clientX)
      : event.clientX;

    const dispatchMove = (clientXPos) => {
      setIsSideBarRightResizing(true);
      const min = defaultSideBarRightWidth / 2;
      const max = 2 * defaultSideBarRightWidth;
      const next = sideBarRightWidthLocal - clientXPos + clientX;
      if (next > max || next < min) {
        return;
      }
      setSideBarRightWidthLocal(next);
    };
    const dispatchEnd = () => {
      setIsSideBarRightResizing(false);
    };

    const handlersAndEvents = {
      mouse: {
        moveEvent: 'mousemove',
        moveHandler: (e) => {
          if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
          }
          dispatchMove(e.clientX);
          return false;
        },
        upEvent: 'mouseup',
        upHandler: () => {
          document.removeEventListener(
            'mousemove',
            handlersAndEvents.mouse.moveHandler,
          );
          document.removeEventListener(
            'mouseup',
            handlersAndEvents.mouse.upHandler,
          );
          dispatchEnd();
        },
      },
      touch: {
        moveEvent: 'touchmove',
        moveHandler: (e) => {
          if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
          }
          dispatchMove(e.touches[0].clientX);
          return false;
        },
        upEvent: 'touchend',
        upHandler: () => {
          document.removeEventListener(
            handlersAndEvents.touch.moveEvent,
            handlersAndEvents.touch.moveHandler,
          );
          document.removeEventListener(
            handlersAndEvents.touch.upEvent,
            handlersAndEvents.touch.moveHandler,
          );
          dispatchEnd();
        },
      },
    };

    const events = isTouchEvent
      ? handlersAndEvents.touch
      : handlersAndEvents.mouse;
    const passiveIfSupported = passiveEventSupported()
      ? { passive: false }
      : false;
    document.addEventListener(
      events.moveEvent,
      events.moveHandler,
      passiveIfSupported,
    );
    document.addEventListener(
      events.upEvent,
      events.upHandler,
      passiveIfSupported,
    );
  });

  const onResizeReset = useEvent(() => {
    setIsSideBarRightResizing(true);
    setSideBarRightWidthLocal(defaultSideBarRightWidth);
  });

  useEffect(
    () =>
      setSideBarLeftCollapseLocal(hideNav || (sideBarLeftCollapse ?? false)),
    [hideNav, sideBarLeftCollapse],
  );

  useEffect(() => {
    if (hideNav) {
      setSideBarLeftCollapseLocal(true);
    }
  }, [hideNav]);

  useDebounce(
    () => {
      setSideBarRightWidth(sideBarRightWidthLocal);
    },
    300,
    [sideBarRightWidthLocal],
  );

  useEffect(() => {
    const scrollElement = bodyScrollRef.current?.getScrollElement();
    scrollElement?.scrollTo?.({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  const showBanner = !!expectedVersion && version < expectedVersion;
  useEffect(() => setShowNewReleaseBanner(showBanner), [showBanner]);

  useEffect(() => {
    const show =
      isGlobalFilterAvailable &&
      [ContextTypes.dns, ContextTypes.traffic].includes(context) &&
      !hasDNSSources;
    setShowNoDNSSourcesAlert(show);
  }, [isGlobalFilterAvailable, context, hasDNSSources]);

  const showNoDNSSourcesAlertLocal =
    showNoDNSSourcesAlert &&
    !location.pathname.startsWith(`${RoutePaths.search}`) &&
    !location.pathname.startsWith(`${RoutePaths.sources}`);

  return (
    <LayoutContainer $exportMode={isLayoutExportMode}>
      <TrialBanner />
      {!isLayoutExportMode && (
        <Fragment>
          <Banner $isOpen={showNewReleaseBanner}>
            {showNewReleaseBanner && (
              <Fragment>
                A new version is available.{' '}
                <button type="button" onClick={onPageReload}>
                  Update now
                </button>
              </Fragment>
            )}
          </Banner>

          {(isUnderCovered || isImpersonating) && <MasqueradingIndicator />}

          <Topbar
            collapse={sideBarLeftCollapseLocal}
            $separatorWidth={separatorWidth}
          />
        </Fragment>
      )}

      <ContentContainer>
        {!isLayoutExportMode && (
          <SidebarLeft
            collapse={sideBarLeftCollapseLocal}
            onToggle={onToggleLeftSidebar}
            $separatorWidth={separatorWidth}
          />
        )}

        <PageContainer>
          {!isLayoutExportMode && isGlobalFilterAvailable && (
            <GlobalFiltersPanel $separatorWidth={separatorWidth} />
          )}

          <ScrollBar ref={bodyScrollRef} $wide>
            <Body>
              {showNoDNSSourcesAlertLocal && <NoDNSSourcesAlert />}
              {children}
            </Body>
          </ScrollBar>
        </PageContainer>

        {!isLayoutExportMode && !hideNav && (
          <SidebarRight
            $width={sideBarRightWidthLocal}
            $separatorWidth={separatorWidth}
            isOpen={propertiesTray?.isOpen}
            onResizeStart={onResizeStart}
            onResizeReset={onResizeReset}
          >
            <PropertiesTray
              $width={sideBarRightWidthLocal}
              $separatorWidth={separatorWidth}
            />
          </SidebarRight>
        )}
      </ContentContainer>

      <ModalRoot />

      <GlobalContextMenu />

      <ChangeNqlPreset />
      <RemoveNqlPreset />

      {showTtouModal && <TtouModal isOpen />}
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.children,
};

Layout.defaultProps = {
  children: null,
};

export { TtouModal, NoDNSSourcesAlert };

export default Layout;
