import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import { UnixTimestampFields } from '@/models/UnixTimestampFields';

import { actions, selectors } from '@/redux/api/search';

import {
  BaseColumnFactory,
  BooleanColumnFactory,
  LabelColumnsFactory,
  NumberColumnFactory,
  StringsArrayColumnsFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';

const columnsTemplates = (field) => {
  const { type, field: name, traffictype } = field;

  let column;
  let fixedType = type;

  if (UnixTimestampFields.includes(name)) {
    fixedType = 'timestamp';
  }

  switch (fixedType) {
    case 'timestamp':
    case 'Epoch timestamp (integer)':
      column = TimestampColumnFactory(field);
      break;
    case 'integer':
    case 'float':
    case 'uint74':
      column = NumberColumnFactory(field);
      break;
    case 'boolean':
      column = BooleanColumnFactory(field);
      break;
    case 'string (start, ongoing, or end)':
    case 'string (start, ongoing, end)':
    case 'Array of Regex Strings':
    case 'Array of strings':
    case 'Regex String':
    case 'string':
    case '[]string]':
    case '[][]string]': {
      column = name.startsWith('label.')
        ? LabelColumnsFactory(field)
        : StringsArrayColumnsFactory(field);
      break;
    }
    // case 'IP':
    // case 'IP address':
    // case 'IP or IP/CIDR':
    default:
      column = BaseColumnFactory(field);
      break;
  }

  delete column.traffictype;

  if (traffictype?.length === 1) {
    const [context] = traffictype;
    column.trafficType = ContextTypes[context] || context;
  }

  return column;
};

const replaceContext = {
  [ContextTypes.alerts]: 'alert',
  [ContextTypes.blocks]: 'block',
};

/**
 * Return a dictionary of columns generated based on fields of a context, key is name of field.
 * @param {ContextTypes} context
 * @param {Object} [options]
 * @param {Object} [options.overrideColumns] - dictionary of columns for overriding default props
 * @param {(string | RegExp)[]} [options.ignore] - array of string or regExp for ignoring some fields,
 *  if string then will use === comparing
 * @return {Object}
 *
 * @example
 * ```
 * // say that we have this array of fields from server for alert context
 * // [
 * //   { field: 'test', type: 'integer' },
 * //   { field: 'test2', type: 'Array of Strings' },
 * //   { field: 'test3', type: 'boolean' }
 * //   { field: 'for ignoring', type: 'string' },
 * //]
 *
 * // have dictionary of override columns
 * const overrideColumns = {
 *   // default will be result of NumberColumnFactory
 *   test: {
 *     width: 50,
 *   },
 *   // default will be result of BooleanColumnFactory
 *   test3: {
 *     // override default: width: 70
 *     width: null,
 *   },
 *   // default will be result of StringsArrayColumnsFactory
 *   test2: {
 *     width: 150,
 *     // override default: Cell: UniversalCell('test2')
 *     Cell: ({ value }) => value, //... or return some formatted view
 *     disableSortBy: true,
 *   }
 * }
 *
 * const collection = usePreparedColumns(ContextTypes.alerts, {
 *   overrideColumns,
 *
 *   // filter for missing 'for ignoring' field
 *   ignore: [/ignoring/uig, 'for ignoring']
 * });
 *
 *
 * // collection will be
 * {
 *   test: {
 *     ...NumberColumnFactory('test'),
 *     width: 50,
 *   },
 *   test3: {
 *     ...BooleanColumnFactory('test3'),
 *     width: null,
 *   },
 *   test2: {
 *     ...StringsArrayColumnsFactory('test2'),
 *     width: 150,
 *     Cell: ({ value }) => value, //... or return some formatted view
 *     disableSortBy: true,
 *   },
 * }
 * ```
 *
 * @see {@link NumberColumnFactory}
 * @see {@link BooleanColumnFactory}
 * @see {@link StringsArrayColumnsFactory}
 */
export const usePreparedColumns = (context, options) => {
  const { overrideColumns = {}, ignore = [] } = options || {};

  const dispatch = useDispatch();

  const fixedContext = replaceContext[context] || context;

  const fields = useSelector(selectors.getFields(fixedContext));

  useEffect(() => {
    dispatch(actions.fetchFields(fixedContext));
  }, []);

  const isIgnored = useCallback((field) => {
    if (!Array.isArray(ignore) || !ignore.length) {
      return false;
    }

    return ignore.filter(Boolean).some((rule) => {
      if (rule instanceof RegExp) {
        return rule.test(field);
      }

      return field.toLowerCase() === String(rule).toLowerCase();
    });
  }, []);

  const columns = useMemo(
    () =>
      (fields || [])
        .filter(({ type, field }) => !(type === 'Object' || isIgnored(field)))
        .reduce(
          (acc, field) => ({
            ...acc,
            [field.field]: columnsTemplates(field),
          }),
          {},
        ),
    [fields],
  );

  return useMemo(() => {
    const items = overrideColumns || {};

    const keys = Array.from(
      new Set([...Object.keys(items), ...Object.keys(columns)]),
    );

    const result = {};

    keys.forEach((key) => {
      result[key] = {
        ...(columns[key] || {}),
        // TODO that is workaround for traffic context and label.*.*.dst field since only flow context can use it.
        //   remove if BE fix that.
        ...(fixedContext === ContextTypes.traffic &&
          key.startsWith('label.') && {
            trafficType: key.endsWith('.dst') ? ContextTypes.flow : null,
          }),
        ...(items[key] || {}),
      };
    });

    return result;
  }, [columns, overrideColumns, fixedContext]);
};
