import PropTypes from '+prop-types';
import { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';

import styled from 'styled-components';

import { actions as contextIntegrationsActions } from '@/redux/api/integrations/context';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import CopyTextOrigin from '+components/CopyText';
import { useFormState } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import PlaintextOrigin from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import useProviderRegions from '+hooks/useProviderRegions';

const Plaintext = styled(PlaintextOrigin)`
  margin: unset !important;
  width: 100% !important;
  > div {
    width: 100% !important;
  }
`;

const CopyText = styled(CopyTextOrigin)`
  padding: 8px 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colorFieldBackground};
`;

const OracleFields = ({ disabled, tags }) => {
  const dispatch = useDispatch();
  const regionOptions = useProviderRegions('oracle');
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });
  const [showModal, toggleModal] = useToggle(false);

  const onUpdateKeys = useCallback(() => {
    dispatch(
      contextIntegrationsActions.updateContextIntegrationKeys(formValues),
    );
  }, [formValues]);

  return (
    <Fragment>
      <Field
        name="oracle.region"
        label="Region"
        component={SelectField}
        validate={validateRequired}
        options={regionOptions}
        parse={normalizeSelectValue}
        helperText="Location of the Oracle Cloud"
        disabled={disabled}
        required
      />

      <Field
        name="oracle.user"
        label="User OCID"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="User OCID to use for authentication to Oracle Cloud"
        disabled={disabled}
        required
      />

      <Field
        name="oracle.tenancy"
        label="Tenancy OCID"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Tenancy ocid to use for connecting to Oracle Cloud"
        disabled={disabled}
        required
      />

      <Field
        name="oracle.tags"
        label="Tag/Label Matches"
        helperText="One or many tags to match any custom tag names to track in Oracle Cloud"
        options={tags}
        component={MultiSelectField}
        parse={normalizeMultiSelectValue}
        disabled={disabled}
        allowCreate
      />

      {formValues?.oracle?.fingerprint && (
        <Group>
          <Label>Fingerprint</Label>
          <FieldContainer>
            <Plaintext monospace>
              <CopyText text={formValues.oracle.fingerprint}>
                {formValues.oracle.fingerprint}
              </CopyText>
            </Plaintext>
          </FieldContainer>
        </Group>
      )}

      {formValues?.oracle?.publickey && (
        <Group>
          <Label>Public Key</Label>
          <FieldContainer>
            <Plaintext monospace>
              <CopyText text={formValues.oracle.publickey}>
                {formValues.oracle.publickey}
              </CopyText>
            </Plaintext>
          </FieldContainer>
          <Description>
            Auto-generated PEM public key for use in the Oracle Console
            <br />
            <br />
            <Button variant={ButtonVariants.outlined} onClick={toggleModal}>
              Regenerate Key
            </Button>
          </Description>
        </Group>
      )}

      {showModal && (
        <ConfirmModal
          item="Regenerate Keys"
          titleTemplate="Regenerate Keys"
          confirmButtonText="Regenerate Keys"
          onConfirm={onUpdateKeys}
          onToggle={toggleModal}
          isOpen
        />
      )}
    </Fragment>
  );
};

OracleFields.propTypes = {
  disabled: PropTypes.bool,
  tags: PropTypes.array,
};

OracleFields.defaultProps = {
  disabled: false,
  tags: [],
};

export default OracleFields;
