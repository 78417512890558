import PropTypes from '+prop-types';
import { Fragment } from 'react';

import FieldsSection from '+components/form/FieldsSection';
import Field from '+components/form/FinalForm/Field';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import { validateRequired } from '+components/form/Validators';

const SenintelOneFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="sentinelone.base_url"
      label="Base URL"
      component={TextField}
      type="text"
      validate={validateRequired}
      helperText="The SentinelOne domain URL to query, e.g. https://<subdomain>.sentinelone.net"
      disabled={disabled}
      required
    />

    <Field
      name="sentinelone.params.accountId"
      label="Account Id"
      component={TextField}
      type="text"
      helperText="The SentinelOne account ID to query"
      disabled={disabled}
    />

    <Field
      name="sentinelone.params.filterId"
      label="Filter Id"
      component={TextField}
      type="text"
      helperText="The SentinelOne filter ID to limit query, SentinelOne API result size limit is 10,000"
      disabled={disabled}
    />

    <Field
      name="sentinelone.ranger.enabled"
      label="Include Ranger"
      helperText="If enabled, include Ranger labels"
      component={ToggleField}
      type="checkbox"
      checkedLabel="Included"
      disabled={disabled}
    />

    <FieldsSection label="Authentication">
      <Field
        name="sentinelone.token"
        label="Token"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="API token to use for authentication"
        disabled={disabled}
        required
      />
    </FieldsSection>
  </Fragment>
);

SenintelOneFields.propTypes = {
  disabled: PropTypes.bool,
};

SenintelOneFields.defaultProps = {
  disabled: false,
};

export default SenintelOneFields;
