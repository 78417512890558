import cloneDeep from 'lodash.clonedeep';

import { ResponseIntegrationModels } from '../../shared/ResponseIntegrationModels';

const parseIfInt = (value, radix) => {
  const parsed = parseInt(value, radix);
  return Number.isNaN(parsed) ? undefined : parsed;
};

// Remodels the data from UI-specific formats back to API-specific formats
export const uiToParams = (adapter, values) => {
  const ret = cloneDeep(values);
  ret.adapter = adapter;

  const headers = {};

  if (!values.config || Object.keys(values.config).length <= 0) {
    values.config = {};
  }

  switch (adapter) {
    case ResponseIntegrationModels.blocklist.name:
      ret.config = {
        ...values.config,
        expiration: parseIfInt(values.config.expiration ?? '', 10),
        max: parseIfInt(values.config.max, 10),
        factors: values.config.factors,
        ipv4_srcipagg: parseIfInt(values.config.ipv4_srcipagg, 10),
        ipv4_dstipagg: parseIfInt(values.config.ipv4_dstipagg, 10),
        ipv6_srcipagg: parseIfInt(values.config.ipv6_srcipagg, 10),
        ipv6_dstipagg: parseIfInt(values.config.ipv6_dstipagg, 10),
      };
      break;

    case ResponseIntegrationModels.bgp.name:
    case ResponseIntegrationModels.flowspec.name:
    case ResponseIntegrationModels.flowspec_custom.name:
    case ResponseIntegrationModels.crowdstrike.name:
      ret.config = {
        ...values.config,
        expiration: parseIfInt(values.config.expiration ?? '', 10),
        max: parseIfInt(values.config.max ?? '', 10),
      };
      break;
    case ResponseIntegrationModels.rtbh.name:
      ret.config = {
        ...values.config,
        expiration: parseIfInt(values.config.expiration, 10),
        factors: values.config.factors,
        max: parseIfInt(values.config.max, 10),
        localpref: parseIfInt(values.config.localpref, 10),
        rule: values.config.rule,
        ipv4_srcipagg: parseIfInt(values.config.ipv4_srcipagg, 10),
        ipv4_dstipagg: parseIfInt(values.config.ipv4_dstipagg, 10),
        ipv6_srcipagg: parseIfInt(values.config.ipv6_srcipagg, 10),
        ipv6_dstipagg: parseIfInt(values.config.ipv6_dstipagg, 10),
      };
      break;

    case ResponseIntegrationModels.email.name:
      let recipients = null; /* eslint-disable-line no-case-declarations */
      let cc = null; /* eslint-disable-line no-case-declarations */
      let bcc = null; /* eslint-disable-line no-case-declarations */
      if (
        'string' === typeof values.config.recipients &&
        values.config.recipients.length
      ) {
        recipients = values.config.recipients
          .split(',')
          .map((item) => item.trim());
      }
      if ('string' === typeof values.config.cc && values.config.cc.length) {
        cc = values.config.cc.split(',').map((item) => item.trim());
      }
      if ('string' === typeof values.config.bcc && values.config.bcc.length) {
        bcc = values.config.bcc.split(',').map((item) => item.trim());
      }
      ret.config = {
        ...values.config,
        bcc,
        cc,
        recipients,
      };
      break;
    case ResponseIntegrationModels.ns1.name:
    case ResponseIntegrationModels.route53.name:
      ret.config.evaluatetargethealth = !!values.config.evaluatetargethealth;
      break;
    case ResponseIntegrationModels.twilio.name:
      if (values.config.from && values.config.from.number) {
        ret.config.from = {
          region: values.config.from.region || 'US',
          number: values.config.from.number,
        };
      } else {
        delete ret.config.from;
      }
      break;
    case ResponseIntegrationModels.sumologic.name:
    case ResponseIntegrationModels.syslog.name:
      ret.config = {
        host: `${values.config.host}:${values.config.port}`,
        facility: values.config.facility,
        protocol: values.config.protocol,
        tag: values.config.tag,
        output: values.config.output,
        skipsslverification: !!values.config.skipsslverification,
      };
      ret.name = values.name;
      ret.description = values.description;
      break;
    case ResponseIntegrationModels.bigpanda.name:
    case ResponseIntegrationModels.panther.name:
    case ResponseIntegrationModels.splunk.name:
    case ResponseIntegrationModels.webhook.name:
      if (values.config.headers) {
        (values.config.headers || '').split(',').forEach((item) => {
          const temp = item.split(':');
          if (temp.length === 2) {
            headers[temp[0].trim()] = temp[1].trim();
          } else {
            console.warn(`Invalid webhook header found: ${item}`); // eslint-disable-line no-console
          }
        });
      }
      ret.config = {
        ...values.config,
        authpassword: values.config.authpassword,
        authusername: values.config.authusername,
        contenttype: 'json',
        headers,
        skipsslverification: !!values.config.skipsslverification,
        url: values.config.url,
      };
      break;

    default:
      break;
  }

  // console.log('uiToParams', ret);
  ret.config.type = ResponseIntegrationModels[adapter]?.group;

  return ret;
};

// Remodels the data from API-specific formats to UI-specific formats
export const paramsToUi = (adapter, values = {}) => {
  const result = cloneDeep(values);

  if (result.config && Object.keys(result.config).length > 0) {
    switch (adapter) {
      case ResponseIntegrationModels.blocklist.name:
        break;
      case ResponseIntegrationModels.email.name:
        if (Array.isArray(values.config.recipients)) {
          result.config.recipients = values.config.recipients.join(', ');
        }
        if (Array.isArray(values.config.cc)) {
          result.config.cc = values.config.cc.join(', ');
        }
        if (Array.isArray(values.config.bcc)) {
          result.config.bcc = values.config.bcc.join(', ');
        }
        break;
      case ResponseIntegrationModels.bgp.name:
      case ResponseIntegrationModels.flowspec.name:
      case ResponseIntegrationModels.flowspec_custom.name:
      case ResponseIntegrationModels.rtbh.name:
        break;
      case ResponseIntegrationModels.ns1.name:
      case ResponseIntegrationModels.pagerduty.name:
      case ResponseIntegrationModels.slack.name:
      case ResponseIntegrationModels.sms.name:
      case ResponseIntegrationModels.twilio.name:
      case ResponseIntegrationModels.teams.name:
        break;

      case ResponseIntegrationModels.bigpanda.name:
      case ResponseIntegrationModels.panther.name:
      case ResponseIntegrationModels.splunk.name:
      case ResponseIntegrationModels.webhook.name:
        const headers = []; /* eslint-disable-line no-case-declarations */
        Object.keys(values.config.headers).forEach((key) => {
          headers.push(`${key}:${values.config.headers[key]}`);
        });
        result.config.headers = headers.join(', ');
        result.config.skipsslverification = !!values.config.skipsslverification;
        break;
      case ResponseIntegrationModels.sumologic.name:
      case ResponseIntegrationModels.syslog.name:
        [result.config.host, result.config.port] = (
          result.config.host || ''
        ).split(':');
        break;
      default:
        break;
    }

    return result;
  }

  /* ********** "Add" Form Defaults ********** */
  switch (adapter) {
    case ResponseIntegrationModels.blocklist.name:
      result.config = {
        ipv4_srcipagg: 32,
        ipv4_dstipagg: 32,
        ipv6_srcipagg: 128,
        ipv6_dstipagg: 128,
      };
      break;
    case ResponseIntegrationModels.ns1.name:
      result.config = {
        type: 'A',
      };
      break;
    case ResponseIntegrationModels.pagerduty.name:
      result.config = {
        severity: 'info',
      };
      break;
    case ResponseIntegrationModels.bgp.name:
    case ResponseIntegrationModels.flowspec.name:
    case ResponseIntegrationModels.flowspec_custom.name:
    case ResponseIntegrationModels.rtbh.name:
      result.config = {
        localpref: 100,
        ipv4_srcipagg: 32,
        ipv4_dstipagg: 32,
        ipv6_srcipagg: 128,
        ipv6_dstipagg: 128,
      };
      break;
    case ResponseIntegrationModels.route53.name:
      result.config = {
        evaluatetargethealth: false,
        type: 'A',
      };
      break;
    case ResponseIntegrationModels.sms.name:
      result.config = {
        to: [{ region: 'US', number: null }],
      };
      break;
    case ResponseIntegrationModels.sumologic.name:
    case ResponseIntegrationModels.syslog.name:
      result.config = {
        port: 514,
        protocol: 'udp',
        facility: 'LOG_LOCAL0',
        tag: 'neto.event',
        output: 'CEF',
      };
      break;
    case ResponseIntegrationModels.twilio.name:
      result.config = {
        from: {
          number: null,
          region: 'US',
        },
        to: [{ region: 'US', number: null }],
      };
      break;

    case ResponseIntegrationModels.bigpanda.name:
    case ResponseIntegrationModels.panther.name:
    case ResponseIntegrationModels.splunk.name:
    case ResponseIntegrationModels.webhook.name:
      result.config = {
        skipsslverification: false,
      };
      break;
    default:
      break;
  }

  return result;
};
