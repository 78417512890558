import Palette from '../palette';
import { createThemeSlice } from '../util';

const themeSlice = createThemeSlice('input', (choose) => ({
  colorInputBorder: choose({
    light: Palette.light3,
    dark: '#8C8C8C',
  }),
  border: choose({
    light: Palette.light3,
    dark: '#8C8C8C',
  }),
  borderHover: choose({
    light: Palette.primary,
    dark: Palette.primary,
  }),
  borderFocused: choose({
    light: Palette.primary,
    dark: Palette.primary,
  }),
  colorSelect: choose({
    light: Palette.lightBlueGray,
    dark: Palette.a8gray,
  }),
}));

export const { selector, selectors, propsSelector, propsSelectors } =
  themeSlice;
export default themeSlice;
