import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';

import Button, { ButtonVariants } from '+components/Button';
import { Field, FinalForm } from '+components/form/FinalForm';
import FormOrigin from '+components/form/Form';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Select from '+components/form/Select';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import {
  validateIp,
  validateIpV6,
  validateRequired,
} from '+components/form/Validators';
import AdditionalFiltersRowItem from '+components/GlobalFilters/Panel/components/AdditionalFiltersRowItem';
import NqlFilter, {
  AdditionalNqlFilterLabel,
} from '+components/GlobalFilters/Panel/components/AdditionalNqlFilter';
import GlobalFiltersPortal from '+components/GlobalFilters/Portal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import IpExplorer from '+components/IpExplorer';
import { ActionsContainer } from '+components/Layout';
import { useVerifyNqlBeforeSend } from '+hooks';
import useGlobalFiltersProperty from '+hooks/useGlobalFiltersProperty';
import useIpExplorerResolver from '+hooks/useIpExplorerResolver';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import usePageTabsAndFormSync from '+hooks/usePageTabsAndFormSync';

const excludeContexts = new Set([
  ContextTypes.alerts,
  ContextTypes.blocks,
  ContextTypes.traffic,
  ContextTypes.dns,
]);

const ipTypes = [
  { value: 'ip', label: 'srcip & dstip' },
  { value: 'srcip', label: 'srcip' },
  { value: 'dstip', label: 'dstip' },
];

const systemNqlKey = 'ipExplorerSystemNql';
const allowEmptyString = (value) => value;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: absolute;
  top: 50px;
  left: 15px;
  right: 15px;
  bottom: 15px;
`;

const ToolbarItem = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  min-width: 15em;
`;

const ToolbarItemTitle = styled.div`
  white-space: nowrap;
`;

const Form = styled(FormOrigin)`
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .form__form-group {
    &:first-of-type {
      min-width: 15em;
      max-width: 15em;
    }
    &:last-of-type {
      min-width: 8em;
      max-width: 8em;
    }
    margin-bottom: unset;
  }
`;

const FormBody = ({ handleSubmit, pristine, form, values, onClear }) => {
  usePageTabsAndFormSync();

  return (
    <Form
      onSubmit={handleSubmit}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Field
        name="ipAddress"
        type="text"
        placeholder="IP Address"
        component={TextField}
        validate={[validateRequired, validateIp, validateIpV6]}
        required
        errorPositionOver
      />
      <span>as</span>
      <Field
        name="ipType"
        type="text"
        component={SelectField}
        parse={normalizeSelectValue}
        validate={validateRequired}
        options={ipTypes}
        data-tracking="ip-type-selector"
      />
      <Button
        type="submit"
        disabled={pristine || values.ipAddress?.trim() === ''}
        data-tracking="ip-search-button"
      >
        Search
      </Button>
      <Button
        style={{ marginLeft: '-5px' }}
        variant={ButtonVariants.outlined}
        onClick={(event) => {
          form.change('ipAddress', '');
          form.change('ipType', ipTypes[0].value);
          form.reset();
          onClear(event);
        }}
        disabled={values.ipAddress?.trim() === ''}
        data-tracking="ip-clear-button"
      >
        Clear
      </Button>
    </Form>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  form: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  onClear: PropTypes.func.isRequired,
};

const IpExplorerPage = () => {
  const { search: locSearch, pathname } = useLocation();
  const navigate = useNavigate();

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const {
    nodesResolver,
    setNodesResolver,
    nodesResolverOptions,
    nodesFunction,
    particlesFunction,
  } = useIpExplorerResolver();

  const search = useMemo(() => new URLSearchParams(locSearch), [locSearch]);

  const defaultIp = useMemo(() => search.get('ip') || '', [search]);

  const defaultIpType = useMemo(
    () => search.get('type') || ipTypes[0].value,
    [search],
  );

  const [currentIp, setCurrentIp] = useState(defaultIp);
  const [currentIpType, setCurrentIpType] = useState(defaultIpType);

  const refGetSearch = useRef();
  const [overrideSystemNql, setOverrideSystemNql] =
    useGlobalFiltersProperty(systemNqlKey);

  const { validateNql } = useVerifyNqlBeforeSend(context, systemNqlKey);

  const portalInitialValues = useMemo(
    () => ({
      [systemNqlKey]: overrideSystemNql ?? refGetSearch.current?.(),
    }),
    [overrideSystemNql],
  );

  const onSubmit = useCallback(
    ({ ipAddress, ipType }) => {
      const normalizedIP = (ipAddress || '').split(' (')[0];
      const normalizedType = ipType === ipTypes[0].value ? '' : ipType;

      let isChanged = false;
      if ((search.get('ip') || '') !== (normalizedIP || '')) {
        search.set('ip', normalizedIP);
        isChanged = true;
      }

      if ((search.get('type') || '') !== (normalizedType || '')) {
        if (!normalizedType) {
          search.delete('type');
        } else {
          search.set('type', normalizedType);
        }
        isChanged = true;
      }

      if (isChanged) {
        navigate(`${pathname}?${search.toString()}`);
      }

      setCurrentIp(normalizedIP);
      setCurrentIpType(normalizedType);
    },
    [navigate, pathname, search],
  );

  const onClear = useCallback(() => {
    setCurrentIp('');

    search.delete('ip');
    search.delete('type');
    navigate(`${pathname}?${search.toString()}`);
  }, [navigate, pathname]);

  const initialValues = useMemo(
    () => ({
      ipAddress: defaultIp,
      ipType: defaultIpType,
    }),
    [defaultIp, defaultIpType],
  );

  useEffect(() => {
    if (initialValues.ipAddress) {
      onSubmit(initialValues);
    }
  }, [initialValues, onSubmit]);

  const onNodesResolverChange = useCallback((value) => {
    setNodesResolver((prev) => (prev?.value === value.value ? prev : value));
  }, []);

  const onSystemNqlChange = useCallback((value) => {
    setOverrideSystemNql(value);
  }, []);

  useEffect(
    () => () => {
      setOverrideSystemNql(null);
    },
    [],
  );

  const innerSearch = refGetSearch.current?.();

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        nql
        context={context}
        excludeContexts={excludeContextsArr}
        customers
        socketControl
      />
      <GlobalFiltersPortal
        initialValues={portalInitialValues}
        isFiltered={(overrideSystemNql ?? innerSearch) !== innerSearch}
      >
        <AdditionalFiltersRowItem $width="100%" $flexShrink={1}>
          <AdditionalNqlFilterLabel>
            IP Explorer additional NQL
          </AdditionalNqlFilterLabel>
          <Field
            name={systemNqlKey}
            component={NqlFilter}
            placeholder="IP Explorer additional NQL"
            allowPresets={false}
            parse={allowEmptyString}
            context={context}
            validate={validateNql}
            data-tracking="filter-row-ip-explorer-nql"
          />
        </AdditionalFiltersRowItem>
      </GlobalFiltersPortal>

      <ActionsContainer>
        <ToolbarItem>
          <ToolbarItemTitle>Nodes Resolver:</ToolbarItemTitle>
          <Select
            name="NodesResolve"
            onChange={onNodesResolverChange}
            value={nodesResolver}
            options={nodesResolverOptions}
            data-tracking="nodes-resolver-selector"
          />
        </ToolbarItem>

        <ToolbarItem>
          <FinalForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            component={FormBody}
            keepDirtyOnReinitialize
            focusOnFields={false}
            onClear={onClear}
          />
        </ToolbarItem>
      </ActionsContainer>

      <Container>
        <IpExplorer
          id="ForceDirected_IpExplorer"
          nodesFunction={nodesFunction}
          particlesFunction={particlesFunction}
          currentIp={currentIp}
          currentIpType={currentIpType}
          onSystemNqlChanged={onSystemNqlChange}
          overrideSystemNql={overrideSystemNql}
          refGetSearch={refGetSearch}
        />
      </Container>
    </Fragment>
  );
};

export default IpExplorerPage;
