const Actions = {
  fetch: 'fetch',
  create: 'create',
  update: 'update',
  delete: 'delete',
  // disable: 'disable',
  // enable: 'enable',
  // event: 'event',
};

const ResourceGroups = {
  account: 'Account',
  users: 'User Management',
  data: 'Data Management',
  ndr: 'Detect & Respond',
  portal: 'Portal',
};

const Resources = {
  account: {
    value: 'account',
    label: 'Overview',
    group: ResourceGroups.account,
    excludeActions: [Actions.create, Actions.delete],
  },
  // NOTE: 2022.05: For audit logs only. Do not use for permissions
  // authentication: 'authentication',
  // NOTE: 2022.12: For audit logs only. For permissions use device @see: https://netography.atlassian.net/browse/API-195
  // bgp: {
  //   value: 'bgp',
  //   label: 'BGP Neighbors',
  //   group: ResourceGroups.setup,
  //   mergeWithResource: 'device',
  // },
  blocklist: {
    value: 'blocklist',
    label: 'Block List',
    group: ResourceGroups.ndr,
    excludeActions: [Actions.create, Actions.update],
  },
  category: {
    value: 'category',
    label: 'Detection Categories',
    group: ResourceGroups.ndr,
  },
  cloud_provider: {
    value: 'cloud_provider',
    label: 'Cloud Providers',
    group: ResourceGroups.data,
  },
  customer: {
    value: 'customer',
    label: 'Customers',
    group: ResourceGroups.account,
    resellersOnly: true,
  },
  dashboard: {
    value: 'dashboard',
    label: 'Dashboards',
    group: ResourceGroups.portal,
  },
  device: {
    value: 'device',
    label: 'Devices',
    group: ResourceGroups.data,
  },
  flow_tag: {
    value: 'flow_tag',
    label: 'Flow Tags',
    group: ResourceGroups.data,
  },
  integration: {
    value: 'integration',
    label: 'Integrations',
    group: ResourceGroups.ndr,
  },
  // NOTE: 2022.05: For audit logs only. Do not use for permissions
  // ipreplist,
  label: {
    value: 'label',
    label: 'Context Labels',
    group: ResourceGroups.data,
  },
  network_classification: {
    value: 'network_classification',
    label: 'Traffic Classifications',
    group: ResourceGroups.data,
    excludeActions: [Actions.create],
  },
  response_policy: {
    value: 'response_policy',
    label: 'Response Policies',
    group: ResourceGroups.ndr,
  },
  role: {
    value: 'role',
    label: 'Roles',
    group: ResourceGroups.users,
  },
  api_key: {
    value: 'api_key',
    label: 'API Keys',
    group: ResourceGroups.users,
  },
  threat_model: {
    value: 'threat_model',
    label: 'Network Detection Models',
    group: ResourceGroups.ndr,
  },
  user: {
    value: 'user',
    label: 'Users',
    group: ResourceGroups.users,
  },
  whitelist: {
    value: 'whitelist',
    label: 'Allow Lists',
    group: ResourceGroups.ndr,
  },
};

export default {
  Actions,
  Resources,
  ResourceGroups,
};
