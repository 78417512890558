import PropTypes from '+prop-types';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import Stack from '@mui/material/Stack';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';

import Button from '+components/Button';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import {
  useFetchContextRecord,
  useGetContextRecord,
  useIsFetchingContextRecord,
} from '+hooks';
import { getRecordDetailsUrl } from '+utils/getRecordDetailsUrl';
import { getRecordModalTitle } from '+utils/getRecordModalTitle';

import { detailsHeight } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${detailsHeight}px;
  background-color: ${({ theme }) =>
    theme.table.recordSubDetailsBackground} !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.table.recordSubDetailsBorderColor};
  border-left: 0;
  border-right: 0;
  padding: 3px 15px;
`;

const HeaderTitle = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  line-height: calc(18 / 11);
`;

const ContextTitle = styled(HeaderTitle)`
  font-weight: 700;
  color: ${({ theme }) => theme.table.recordSubDetailsTitleColor} !important;
`;

const TimestampTitle = styled(HeaderTitle)`
  font-weight: 600;
  color: ${({ theme }) => theme.table.recordSubDetailsSubTitleColor} !important;
`;

const ButtonRawRecord = styled(Button).attrs({
  variant: 'text',
})`
  font-size: 11px;
  height: 18px;
  max-height: 18px;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent !important;
  color: ${({ theme }) =>
    theme.table.recordSubDetailsLinkButtonColor} !important;
`;

const DataContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

export const SubDetails = (props) => {
  const {
    rowData: { original },
    context,
    children,
    Component,
    className,
  } = props;
  const { id } = original;

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  const [, , pageTabMethods] = usePageTabs();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const record = useGetContextRecord(context, id);

  const fixedRecord = record || original;

  const isSubAccountRecord =
    fixedRecord?.customer && fixedRecord?.customer !== customer?.shortname;

  const isFetching = useIsFetchingContextRecord(context, id);

  useFetchContextRecord(
    context,
    {
      id,
      customer: isSubAccountRecord ? fixedRecord?.customer : undefined,
    },
    'subDetails',
    [id, fixedRecord.customer, isSubAccountRecord],
  );

  const onFullRecordClick = useCallback(() => {
    const url = getRecordDetailsUrl({
      context,
      id,
      customer: isSubAccountRecord ? fixedRecord?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [
    id,
    isSubAccountRecord,
    context,
    fixedRecord.customer,
    pageTabMethods.add,
  ]);

  const onRecordClick = useCallback(() => {
    setRecordModalOpened(true);
  }, []);

  const onRecordClose = useCallback(() => {
    setRecordModalOpened(false);
  }, []);

  return (
    <Container className={className}>
      <Header>
        <Stack direction="row" gap={2}>
          <ContextTitle $context={context}>
            {ContextTypesLabels[context] || context} record summary
          </ContextTitle>
          {fixedRecord.timestamp && (
            <TimestampTitle>
              Timestamp: {timestampFormatter(fixedRecord.timestamp)}
            </TimestampTitle>
          )}
          <ButtonRawRecord onClick={onFullRecordClick}>
            Full Details
          </ButtonRawRecord>
          {record && (
            <ButtonRawRecord onClick={onRecordClick}>
              Raw Record
            </ButtonRawRecord>
          )}
          {!record && isFetching && <TimestampTitle>Loading...</TimestampTitle>}
        </Stack>
      </Header>
      <DataContainer>
        {!!children && children(fixedRecord)}
        {!!Component && <Component record={fixedRecord} />}
      </DataContainer>

      {recordModalOpened && (
        <RecordModal
          title={getRecordModalTitle(context, fixedRecord)}
          data={record}
          onToggle={onRecordClose}
          isOpen
        />
      )}
    </Container>
  );
};

SubDetails.propTypes = {
  rowData: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      customer: PropTypes.string,
    }).isRequired,
  }).isRequired,
  children: PropTypes.func,
  Component: PropTypes.elementType,
  context: PropTypes.oneOfContext(
    new Set([ContextTypes.thresholdDns, ContextTypes.thresholdFlow]),
  ).isRequired,
  className: PropTypes.string,
};

SubDetails.defaultProps = {
  children: () => null,
  Component: undefined,
  className: undefined,
};
