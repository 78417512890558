import { useMemo } from 'react';

import styled from 'styled-components';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import GearIcon from 'mdi-react/GearIcon';

import {
  BaseColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { BooleanColumnFilter } from '+components/Table/Filters';
import Tooltip from '+components/Tooltip';
import { getColumnsHelper } from '+utils/getColumnsHelper';

const TooltipContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .mdi-icon + span {
    margin-left: 4px;
  }
`;

export const Columns = {
  rowSelector: 'rowSelector',
  system: 'system',
  systemdefault: 'systemdefault',
  name: 'name',
  description: 'description',
  menu: 'menu',
};

const columnsCollection = () => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.system]: BaseColumnFactory({
    accessor: 'system',
    Header: <GearIcon size={14} />,
    Description: 'System',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    disableResizing: true,
    sortType: 'boolean',
    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <GearIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>System Role</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'System',
      false: 'Custom',
    }),
    filter: 'booleanFilter',
  }),
  [Columns.systemdefault]: BaseColumnFactory({
    accessor: (original) => original.system && !original.systemdefault,
    Header: <ContentCopyIcon size={14} />,
    Description: 'Customized',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    disableResizing: true,
    sortType: 'boolean',
    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <ContentCopyIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>Customized</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter(
      {
        false: 'Default',
        true: 'Customized',
      },
      null,
      ['all', 'false', 'true'],
    ),
    filter: 'booleanFilter',
  }),
  [Columns.name]: BaseColumnFactory({
    accessor: 'name',
    Header: 'Category',
  }),
  [Columns.description]: BaseColumnFactory({
    accessor: 'description',
    Header: 'Description',
  }),
  [Columns.menu]: MenuColumnFactory,
});

export const getColumns = getColumnsHelper(columnsCollection);
