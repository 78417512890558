import chmln from '@chamaeleonidae/chmln';

import { config } from '@/config';

export const chameleon = {
  init: () => {
    if (!config.chameleon.enabled) {
      return;
    }

    chmln.init(config.chameleon.apiKey, {
      fastUrl: config.chameleon.fastUrl,
    });
  },
  identify: (id, data) => {
    if (!config.chameleon.enabled) {
      return;
    }

    chmln.identify(id, data);
  },
  showExperience: (expID) => {
    if (!config.chameleon.enabled) {
      return;
    }

    window.chmln?.show?.(expID, { open: true });
  },
  sendEvent: (eventID) => {
    if (!config.chameleon.enabled) {
      return;
    }
    const currentTheme = document
      .getElementById('app')
      .firstChild.className.split(' ')[1]
      .replace('theme-', '');

    window.chmln?.track?.(`${eventID}_${currentTheme}`);
  },
};
