import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import ScrollBarOrigin from '+components/ScrollBar/smooth';

import ListItem from './ListItem';

const ScrollBar = styled(ScrollBarOrigin)`
  padding-top: 0;
  padding-bottom: 5px;
  max-height: calc(30vh - 32px);
`;

const ShadowContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const TitleItem = styled.div`
  padding-left: 8px;
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTextSecondary} !important;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const List = (props) => {
  const {
    userPresets,
    companyPresets,
    systemPresets,
    recentQueries,
    filter,
    onChooseItem,
    isDefault,
    isAdmin,
  } = props;

  const [scroll, setScroll] = useState();

  useEffect(() => {
    if (scroll) {
      scroll.scrollTop = 0;
    }
  }, [filter]);

  const doChooseItem = useCallback(
    (item) => (event) => {
      if (typeof onChooseItem === 'function') {
        onChooseItem(item, event);
      }
    },
    [onChooseItem],
  );

  return (
    <ShadowContainer>
      <ScrollBar ref={setScroll}>
        {recentQueries?.map((item, index) => (
          <ListItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}_${item.nql}`}
            onClick={doChooseItem(item)}
            item={item}
          />
        ))}

        {!!userPresets?.length && (
          <Fragment>
            <TitleItem>My Presets</TitleItem>
            {userPresets.map((item) => (
              <ListItem
                key={item.id}
                onClick={doChooseItem(item)}
                item={item}
                isEditable
              />
            ))}
          </Fragment>
        )}

        {!!companyPresets?.length && (
          <Fragment>
            <TitleItem>Company Presets</TitleItem>
            {companyPresets.map((item) => (
              <ListItem
                key={item.id}
                onClick={doChooseItem(item)}
                item={item}
                isEditable={isAdmin}
              />
            ))}
          </Fragment>
        )}

        {!!systemPresets?.length && (
          <Fragment>
            <TitleItem>System Presets</TitleItem>
            {systemPresets.map((item) => (
              <ListItem
                key={item.id}
                onClick={doChooseItem(item)}
                item={item}
                isEditable={isDefault}
              />
            ))}
          </Fragment>
        )}
      </ScrollBar>
    </ShadowContainer>
  );
};

List.propTypes = {
  userPresets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyPresets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  systemPresets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  recentQueries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filter: PropTypes.string,
  onChooseItem: PropTypes.func,
  isDefault: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

List.defaultProps = {
  filter: null,
  onChooseItem: null,
  isDefault: false,
  isAdmin: false,
};

export default List;
