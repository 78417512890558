import PropTypes from '+prop-types';
import { useMemo } from 'react';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';

import { HeaderSubheader } from '+components/Table/Cells/HeaderSubheaderCell';

import CellWrapper from './CellWrapper';

const CellPluginName = (props) => {
  const { id, icon, name, description, style, original, disabled } = props;
  const additionalMenuOptions = useMemo(
    () =>
      !id
        ? []
        : [
            {
              url: `${RoutePaths.integrationsResponse}/edit/${id}`,
              icon: <LeadPencilIcon />,
              text: 'Edit',
            },
          ],
    [id],
  );

  return (
    <HeaderSubheader
      header={
        <CellWrapper
          customer={original?.customer}
          field="plugin"
          value={name}
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
        >
          <span style={style}>
            {icon}
            {name}
          </span>
        </CellWrapper>
      }
      subHeader={description}
    />
  );
};

CellPluginName.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.children,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
};

CellPluginName.defaultProps = {
  icon: null,
  style: null,
  description: null,
  original: undefined,
  disabled: false,
};

export default CellPluginName;
