/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import get from 'lodash.get';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import ChartBoxIcon from 'mdi-react/ChartBoxIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import FlagIcon from 'mdi-react/FlagIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import TagsIcon from 'mdi-react/TagsIcon';
import TextBoxIcon from 'mdi-react/TextBoxIcon';

import { ContextTypes } from '@/models/ContextTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as flowsActions,
  selectors as flowsSelectors,
} from '@/redux/api/flows';
import { actions as globalFiltersActions } from '@/redux/globalFilters';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import TCPFlagLabel from '+components/Labels/TCPFlagLabel';
import {
  ActionsContainer,
  Col,
  LayoutSizes,
  LayoutTypes,
  Row,
} from '+components/Layout';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import Table from '+components/Table';
import { CellTag, UniversalCell } from '+components/Table/Cells';
import SubAccountTag from '+components/Tag/SubAccountTag';
import Tooltip from '+components/Tooltip';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import getNqlFieldName from '+utils/getNqlFieldName';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

import { getColumns } from './components/columns';

const FlowDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const [, , pageTabMethods] = usePageTabs();

  const [filters] = useGlobalFilters();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const flow = useSelector(flowsSelectors.getRecord(id)) || {};
  const isFetching = useSelector(flowsSelectors.isFetchingRecord(id));

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  useLoadingIndicator(isFetching);

  const isSubAccountRecord =
    flow?.customer && flow?.customer !== customer?.shortname;

  const columns = useMemo(
    () => getColumns({ labelContext: filters.labelContext }),
    [filters.labelContext],
  );

  const row = useMemo(() => ({ original: flow }), [flow]);

  const ipIntellData = useMemo(() => {
    const data = [];
    if (flow.dstip) {
      data.push({
        dstip: true,
        ip: flow.dstip,
        ipname: !filters.labelContext.show
          ? []
          : flow.label?.ip?.[filters.labelContext.ip]?.dst,
        bogon: flow.bogondst,
        geo: flow.dstgeo,
        as: flow.dstowneras,
        iprep: flow.dstiprep,
        customer: flow.customer,
      });
    }
    if (flow.srcip) {
      data.push({
        srcip: true,
        ip: flow.srcip,
        ipname: !filters.labelContext.show
          ? []
          : flow.label?.ip?.[filters.labelContext.ip]?.src,
        bogon: flow.bogonsrc,
        geo: flow.srcgeo,
        as: flow.srcowneras,
        iprep: flow.srciprep,
        customer: flow.customer,
      });
    }
    return data;
  }, [flow, filters.labelContext]);

  const { nql } = useMemo(
    () => getFlowDataFromRecord({ record: flow, type: ContextTypes.flow }),
    [flow],
  );

  const onSearch = useCallback(() => {
    const url = getSearchUrl({
      context: ContextTypes.flow,
      nql,
      customer: isSubAccountRecord ? flow?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [nql, isSubAccountRecord, flow]);

  const onPushToGF = useCallback(() => {
    dispatch(
      globalFiltersActions.changeFilter({
        context: ContextTypes.flow,
        [getNqlFieldName(ContextTypes.flow)]: makeArr(nql),
        ...(isSubAccountRecord && { customers: [flow?.customer] }),
      }),
    );
  }, [nql, isSubAccountRecord, flow]);

  useEffect(() => {
    if (!id) {
      return undefined;
    }
    const namespace = `flowDetails_fetchFlow_${id}`;
    const search = new URLSearchParams(location.search);
    dispatch(
      flowsActions.fetch({ id, customer: search.get('customer') }, namespace),
    );
    return () => {
      dispatch(flowsActions.cancel(namespace));
    };
  }, [id, location.search]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  const srcIpLabelFieldName = `label.ip.${filters.labelContext.ip}.src`;
  const srcPortLabelFieldName = `label.port.${filters.labelContext.port}.src`;
  const dstIpLabelFieldName = `label.ip.${filters.labelContext.ip}.dst`;
  const dstPortLabelFieldName = `label.port.${filters.labelContext.port}.dst`;

  if (!flow?.id) {
    return (
      <Fragment>
        <Breadcrumb title="Flow Details" />

        <GlobalFiltersSetting
          context={ContextTypes.flow}
          range={false}
          from={false}
          to={false}
          nql={false}
          customers={false}
          metric={false}
          socketControl={false}
        />

        <Col
          $type={LayoutTypes.card}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isFetching ? 'Loading...' : 'Flow not found'}
        </Col>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu.TriggerMenu />

      <Breadcrumb title="Flow Details" />

      <GlobalFiltersSetting
        context={ContextTypes.flow}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <ActionsContainer>
        <Button
          variant={ButtonVariants.text}
          onClick={() => setRecordModalOpened(true)}
        >
          View Raw Record
        </Button>

        <Button
          variant={ButtonVariants.text}
          startIcon={<MagnifyIcon size={16} />}
          onClick={onSearch}
          disabled={!nql}
        >
          Search Flows
        </Button>

        <Tooltip title="Push Flow NQL to the Global Filters">
          <div>
            <Button
              variant={ButtonVariants.text}
              startIcon={<ArrowTopLeftIcon size={16} />}
              onClick={onPushToGF}
              disabled={!nql}
            >
              Push to Global Filters
            </Button>
          </div>
        </Tooltip>
      </ActionsContainer>

      <Col gap={LayoutSizes.groupGap}>
        <Row
          gap={LayoutSizes.groupGap}
          wrap="nowrap"
          alignItems="center"
          $type={LayoutTypes.card}
        >
          <Row $type={LayoutTypes.field}>
            {isSubAccountRecord && (
              <Col container={false} marginRight="5px">
                <SubAccountTag
                  icon={<AccountMultipleIcon size={16} />}
                  context={flow.customer}
                  clickable={false}
                />
              </Col>
            )}
            <Col container={false}>Flow Type:</Col>
            <Col $type={LayoutTypes.fieldValue}>
              {UniversalCell('flowtype')({ row, value: flow.flowtype })}
            </Col>
          </Row>
        </Row>

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TextBoxIcon size={16} />
              <span>Overview</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Protocol:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('protocol')({ row, value: flow.protocol })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Flow Version:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('flowversion')({
                  row,
                  value: flow.flowversion,
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>IP Version:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('ipversion')({ row, value: flow.ipversion })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Packets:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('packets')({ row, value: flow.packets })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Sample Rate:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('samplerate')({
                  row,
                  value: flow.samplerate,
                })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <ClockIcon size={16} />
              <span>Time</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Start:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('start')({ row, value: flow.start })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>End:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('end')({ row, value: flow.end })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Duration:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('duration')({
                  row,
                  value: flow.duration,
                  options: { unit: 'milliseconds' },
                })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Flowtime:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('flowrtime')({ row, value: flow.flowrtime })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Timestamp:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('timestamp')({ row, value: flow.timestamp })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <RoutesIcon size={16} />
              <span>Traffic</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Source:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                !get(flow, srcIpLabelFieldName)?.length
                  ? UniversalCell('srcip')({
                      row,
                      value: flow.srcip,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell(srcIpLabelFieldName)({
                      row,
                      value: get(flow, srcIpLabelFieldName),
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>SRC Port:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                !get(flow, srcPortLabelFieldName)?.length
                  ? UniversalCell('srcport')({
                      row,
                      value: flow.srcport,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell(srcPortLabelFieldName)({
                      row,
                      value: get(flow, srcPortLabelFieldName),
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Destination:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                !get(flow, dstIpLabelFieldName)?.length
                  ? UniversalCell('dstip')({
                      row,
                      value: flow.dstip,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell(dstIpLabelFieldName)({
                      row,
                      value: get(flow, dstIpLabelFieldName),
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>DST Port:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {!filters.labelContext.show ||
                !get(flow, dstPortLabelFieldName)?.length
                  ? UniversalCell('dstport')({
                      row,
                      value: flow.dstport,
                      options: { showAsLabel: true },
                    })
                  : UniversalCell(dstPortLabelFieldName)({
                      row,
                      value: get(flow, dstPortLabelFieldName),
                      options: { useDataValueInPropertiesTray: true },
                    })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Site:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('site')({ row, value: flow.site })}
              </Col>
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <ChartBoxIcon size={16} />
              <span>Metrics</span>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Bits:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('bits')({ row, value: flow.bits })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Bits x Rate:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('bitsxrate')({ row, value: flow.bitsxrate })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Flow Bit Rate:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('flowbrate')({ row, value: flow.flowbrate })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Flow Packet Rate:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('flowprate')({ row, value: flow.flowprate })}
              </Col>
            </Row>

            <Row $type={LayoutTypes.field}>
              <Col $type={LayoutTypes.fieldName}>Packets x Rate:</Col>
              <Col $type={LayoutTypes.fieldValue}>
                {UniversalCell('packetsxrate')({
                  row,
                  value: flow.packetsxrate,
                })}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gap={LayoutSizes.groupGap}>
          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <TagsIcon size={16} />
              <span>Tags</span>
            </Row>

            <Row gap="5px">
              {(flow.tags || []).map((tag) => (
                <CellTag key={tag} field="tags" value={tag} original={flow} />
              ))}
            </Row>
          </Col>

          <Col $type={LayoutTypes.card}>
            <Row $type={LayoutTypes.title}>
              <FlagIcon size={16} />
              <span>TCP Flags</span>
            </Row>

            <Row gap="5px">
              {Object.entries(flow.tcpflags || {}).map(([flag, flagValue]) => (
                <TCPFlagLabel
                  key={flag}
                  $flag={flagValue && flag.toLowerCase()}
                >
                  {flag.toUpperCase()}
                </TCPFlagLabel>
              ))}
            </Row>
          </Col>
        </Row>

        <Row gap={LayoutSizes.groupGap} $type={LayoutTypes.card}>
          <Row $type={LayoutTypes.title}>
            <InformationIcon size={16} />
            <span>IP Intelligence</span>
          </Row>

          <Row>
            <Col sm={12} item container={false}>
              <Table
                id="Flow_Details_Ip_Intell"
                data={ipIntellData}
                columns={columns}
                minRows={1}
              />
            </Col>
          </Row>
        </Row>
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`Flow Record — ${flow.id}`}
          data={flow}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default withMenu(FlowDetails);
