import 'd3-transition';

import { GlowFilter } from '@pixi/filter-glow';
import { color as d3color } from 'd3-color';
import { dispatch } from 'd3-dispatch';
import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import { timerFlush } from 'd3-timer';
import { Viewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js-legacy';

import { clearTextures, hasTransition } from '+utils/pixijsUtils';
import { throttleRAF } from '+utils/throttleRAF';

import Cull, { isCulled } from './Cull';
import { drawGroup, drawLink, drawNode, drawParticle } from './drawItems';
import {
  getId,
  getLabel,
  getLabelContext,
  getLabelCount,
  getLinkId,
  getShowLabel,
  getSourceX,
  getSourceY,
  getTargetX,
  getTargetY,
  getText,
  getX,
  getX0orX,
  getY,
  getY0orY,
} from './extractors';
import {
  addLinkFactory,
  addNodeFactory,
  addParticleFactory,
  addTitleFactory,
  removeItemFactory,
} from './itemsFactories';
import Simulation from './Simulation';

export const GraphicsQuality = {
  Low: 0,
  High: 2,
};

export const ChartModes = {
  Static: 'Static',
  Dynamic: 'Dynamic',
};

export const Events = {
  nodeOver: 'nodeOver',
  nodeOut: 'nodeOut',
  nodeMove: 'nodeMove',
  nodeClick: 'nodeClick',
  nodeRightClick: 'nodeRightClick',
  nodeDragStart: 'nodeDragStart',
  nodeDragEnd: 'nodeDragEnd',
  nodeDrag: 'nodeDrag',
  autoFitToggled: 'autoFitToggled',
  titlesAutoHideToggled: 'titlesAutoHideToggled',
  afterDraw: 'afterDraw',
  beforeDraw: 'beforeDraw',
  visibilityToggled: 'visibilityToggled',
  labelCountClick: 'labelCountClick',
};

const Layers = {
  links: 'links',
  particles: 'particles',
  titles: 'titles',
  nodes: 'nodes',
  frontTitles: 'frontTitles',
  frontNodes: 'frontNodes',
};

export const VisibleElements = {
  ...Layers,
  arrows: 'arrows',
  glowEffect: 'glowEffect',
};

const Elements = {
  node: 'node',
  title: 'title',
  titleBackground: 'titleBackground',
  link: 'link',
  particle: 'particle',
  labelBorderColor: 'labelBorderColor',
  labelContextColor: 'labelContextColor',
  labelContextBackground: 'labelContextBackground',
  label: 'label',
  labelBackground: 'labelBackground',
  labelCount: 'labelCount',
  labelCountBackground: 'labelCountBackground',
  selectedNode: 'selectedNode',
};

PIXI.settings.RESOLUTION = 1;

const defaultTextStyle = {
  fontSize: '2.4em',
  fontFamily: "'Source Sans Pro',sans-serif",
  // fontWeight: 'lighter',
  lineJoin: 'round',
};

const defaultColors = {
  [Elements.node]: '#61BBD9',
  [Elements.title]: '#fff',
  [Elements.titleBackground]: '#202124',
  [Elements.link]: '#aaa',
  [Elements.particle]: '#fff',
  [Elements.labelBorderColor]: '#fff',
  [Elements.labelContextColor]: '#202124',
  [Elements.labelContextBackground]: '#202124',
  [Elements.label]: '#fff',
  [Elements.labelBackground]: '#202124',
  [Elements.labelCount]: '#fff',
  [Elements.labelCountBackground]: '#202124',
  [Elements.selectedNode]: '#61BBD9',
};

const defaultRadius = {
  [Elements.node]: 7,
  [Elements.particle]: 1,
};

const defaultLinkWidth = 1;
const defaultLabelContext = getLabelContext;
const defaultLabel = getLabel;
const defaultLabelCount = getLabelCount;
const defaultShowLabel = getShowLabel;

const rateOfWorld = 4;

const glowFilter = new GlowFilter({ distance: 15, outerStrength: 1 });

const addGlowFilter = function () {
  const { graphic } = this;
  if (!graphic) {
    return;
  }

  graphic.filters = graphic.filters || [];

  if (!graphic.filters.includes(glowFilter)) {
    graphic.filters.push(glowFilter);
  }
};

const removeGlowFilter = function () {
  const { graphic } = this;
  if (!graphic?.filters) {
    return;
  }

  graphic.filters = graphic.filters.filter((f) => f !== glowFilter);
};

const getOpacity = (node) => {
  const hovered = node.hovered || node.source?.hovered || node.target?.hovered;

  return hovered ? 1 : 0.2;
};

const fadeOutItemsFactory = (endCallback) => (selection, delay) => {
  selection
    .transition('fade')
    .delay(delay ?? 7000)
    .duration(3000)
    .attr('opacity', getOpacity)
    .on('end', endCallback);
};

const fadeOutItems = fadeOutItemsFactory(removeGlowFilter);
const removeGlowEffectItems = (selection) => {
  selection
    .transition('fade')
    .delay(7000)
    .duration(3000)
    .on('end', removeGlowFilter);
};

const linkIsVisible = (bounds) => (link) => {
  const x = getSourceX(link);
  const y = getSourceY(link);
  const tx = getTargetX(link);
  const ty = getTargetY(link);

  const box = {
    x: Math.min(x, tx),
    y: Math.min(y, ty),
    width: Math.abs(tx - x),
    height: Math.abs(ty - y),
  };

  return isCulled(bounds, box);
};

let countInstances = 0;

class ForceDirected {
  constructor(container, options) {
    countInstances += 1;

    this._destroyed = false;

    this._dispatch = dispatch(...Object.values(Events));

    this._initVariables(options);

    this._bindFunction();

    this._initForceLayout();

    this._initPixiApplication(container, options);

    this._canRecieveData = true;

    const setTimeoutSkipData = () => {
      clearTimeout(this._timerSkipData);
      this._timerSkipData = setTimeout(() => {
        this._canRecieveData = false;
        this._canRecieveParticles = false;

        this._shadow.selectAll('.particle').each(this._removeParticle);
      }, 500);
    };

    const forceData = () => {
      if (!this._savedData) {
        return;
      }

      this.data(this._savedData);
      this._savedData = null;
    };

    const checkVisibility = (force) => {
      if (this.isDestroyed || !(force || this._viewport.dirty)) {
        return;
      }

      this._viewport.dirty = false;

      const bounds = this._viewport.getVisibleBounds();

      // performance issue if skipUpdate is false.
      // Calculation bounds of an object appears in draw methods,
      // in order to avoid unnecessary calculations and arrays walking.
      this._cull.cull(bounds, true);

      this._shadowNodes?.attr('r', this._radius[Elements.node]);

      this._shadowTitles
        ?.attr('anchorY', this._getTitleAnchorY)
        .attr('offsetY', this._getTitleOffsetY);

      this._shadowParticles?.attr('r', this._radius[Elements.particle]);

      this._shadowLinks?.attr('visible', linkIsVisible(bounds));
    };

    this._lastTitleVisibility = true;
    const hideTitles = () => {
      if (!this._titlesAutoHide) {
        return;
      }

      const scale = this._viewport.scaled;

      if (scale < 0.6) {
        if (
          !this._titlesVisibilityForcedOn &&
          this._visibility[Layers.titles]
        ) {
          this._lastTitleVisibility = this._visibility[Layers.titles];
          this.toggleTitles(false);
        }

        return;
      }

      if (!this._visibility[Layers.titles] && this._lastTitleVisibility) {
        this._lastTitleVisibility = false;
        this.toggleTitles(true);
      }

      this._titlesVisibilityForcedOn = false;
    };

    this._simulation.onTick(this._tick.bind(this));

    const steps = [
      () => {
        if (this.isDestroyed) {
          return steps[0];
        }

        this._canRecieveData = true;
        this._canRecieveParticles = true;

        setTimeoutSkipData();

        forceData();

        if (this._drawing) {
          return steps[0];
        }

        this._emit(Events.beforeDraw);

        hideTitles();

        this._drawing = true;

        this._drawGraphics();

        return steps[1];
      },
      () => {
        if (this.isDestroyed) {
          return steps[0];
        }

        const hasParticles = this._shadowContainer.node().childElementCount > 0;

        checkVisibility(this._layoutUpdated || hasParticles);

        return steps[2];
      },
      () => {
        if (this.isDestroyed) {
          return steps[0];
        }

        if (hasTransition(this._shadow.selectAll('*'))) {
          this._instance.render();
        }

        this._instance.render();

        this._layoutUpdated = false;
        this._drawing = false;

        this._emit(Events.afterDraw);

        return steps[0];
      },
    ];

    let nextStep = steps[0];
    const animationLoop = async () => {
      if (this.isDestroyed) {
        return;
      }

      this._animationFrame = requestAnimationFrame(animationLoop);

      await new Promise((resolve) => {
        setTimeout(resolve, 10);
      });

      nextStep = nextStep();
    };
    this._animationFrame = requestAnimationFrame(animationLoop);

    const turnOffAutofit = () => {
      this.toggleAutoFit(false);
    };

    this._viewport.on('wheel', turnOffAutofit);
    this._viewport.on('wheel-scroll', turnOffAutofit);
    this._viewport.on('drag-start', () => {
      turnOffAutofit();

      this._viewport.cursor = 'move';
    });

    this._viewport.on('drag-end', () => {
      this._viewport.cursor = 'default';
    });
  }

  get isDestroyed() {
    return this._destroyed;
  }

  destroy() {
    if (this._destroyed) {
      return;
    }

    this._destroyed = true;

    cancelAnimationFrame(this._animationFrame);

    Object.values(this._throttle).forEach((fn) => fn.cancel());

    clearTimeout(this._timerSkipData);

    this._savedData = null;

    this._simulation.destroy();

    this._shadow.selectAll('*').transition().duration(0).remove();
    this._shadow.remove();

    timerFlush();

    countInstances = Math.max(0, countInstances - 1);

    if (countInstances > 0) {
      // if instances more than 1
      // remove title with textures, because each text is texture.
      this._titlesGroup.children.forEach((child) => {
        child.getChildByName('title').destroy(true);
      });
    }

    this._instance.destroy(true, {
      children: true,
      texture: countInstances < 1,
      baseTexture: countInstances < 1,
    });

    if (countInstances < 1) {
      PIXI.utils.destroyTextureCache();
      clearTextures();
    }
  }

  on(...args) {
    const value = this._dispatch.on.call(this._dispatch, ...args);
    return value === this._dispatch ? this : value;
  }

  once(event, fn) {
    const key = `${event}.once_${performance.now()}`;
    this._dispatch.on(key, (...args) => {
      this._dispatch.on(key, null);
      fn(...args);
    });
    return this;
  }

  toDataURL(contextType = 'image/png', quality = 0.92) {
    if (this.isDestroyed) {
      return '';
    }

    return this._instance.renderer.plugins.extract.base64(
      this._viewport,
      contextType,
      quality,
    );
  }

  toCanvas() {
    if (this.isDestroyed) {
      return '';
    }

    return this._instance.renderer.plugins.extract.canvas(this._viewport);
  }

  mode(mode) {
    this._mode = mode || ChartModes.Static;
    return this;
  }

  groupBy(getter) {
    this._groupBy = getter;
    return this;
  }

  textStyle(style) {
    this._textStyle = {
      ...defaultTextStyle,
      ...(style || {}),
    };
    return this;
  }

  /**
   * Set method or scaler for getting radius of nodes
   * @param {number|Function} value
   * @returns {ForceDirected}
   */
  nodeRadius(value) {
    this._radiusGetters[Elements.node] = value;
    // this._updateNodesTransaction();
    return this;
  }

  /**
   * Set method or scalar for getting radius of particles
   * @param {number|Function} value
   * @returns {ForceDirected}
   */
  particleRadius(value) {
    this._radiusGetters[Elements.particle] = value;
    return this;
  }

  /**
   * Set method or color for getting color of nodes
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  nodeColor(value) {
    this._colorGetters[Elements.node] = value;
    // this._updateNodesTransaction();
    return this;
  }

  /**
   * Set method or color for getting color of titles
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  titleColor(value) {
    this._colorGetters[Elements.title] = value;
    // this._updateTitlesTransaction();
    return this;
  }

  /**
   * Set method or background for getting color of titles background
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  titleBackground(value) {
    this._colorGetters[Elements.titleBackground] = value;
    return this;
  }

  /**
   * Set method or color for getting color of links
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  linkColor(value) {
    this._colorGetters[Elements.link] = value;
    // this._updateLinksTransaction();
    return this;
  }

  /**
   * Set method or color for getting color of particles
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  particleColor(value) {
    this._colorGetters[Elements.particle] = value;
    return this;
  }

  labelBorderColor(value) {
    this._colorGetters[Elements.labelBorderColor] = value;
    return this;
  }

  labelContextColor(value) {
    this._colorGetters[Elements.labelContextColor] = value;
    return this;
  }

  labelContextBackground(value) {
    this._colorGetters[Elements.labelContextBackground] = value;
    return this;
  }

  /**
   * Set method or color for getting color of labels
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  labelColor(value) {
    this._colorGetters[Elements.label] = value;
    return this;
  }

  /**
   * Set method or background for getting color of labels
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  labelBackground(value) {
    this._colorGetters[Elements.labelBackground] = value;
    return this;
  }

  /**
   * Set method or color for getting color of labelCount
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  labelCountColor(value) {
    this._colorGetters[Elements.labelCount] = value;
    return this;
  }

  /**
   * Set method or background for getting background of labelCount
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  labelCountBackground(value) {
    this._colorGetters[Elements.labelCountBackground] = value;
    return this;
  }

  /**
   * Set method or color for getting color of selected node
   * @param {string|Function} value
   * @returns {ForceDirected}
   */
  selectedNodeColor(value) {
    this._colorGetters[Elements.selectedNode] = value;
    return this;
  }

  /**
   * Set method or scalar for getting color of nodes
   * @param {number|Function} value
   * @returns {ForceDirected}
   */
  linkWidth(value) {
    this._linkWidthGetter = value;
    // this._updateLinksTransaction();
    return this;
  }

  labelContext(value) {
    this._labelContextGetter = value;
    return this;
  }

  label(value) {
    this._labelGetter = value;
    return this;
  }

  labelCount(value) {
    this._labelCountGetter = value;
    return this;
  }

  showLabel(value) {
    this._showLabelGetter = value;
    return this;
  }

  resize() {
    this._instance.queueResize();
  }

  /**
   * Toggle the visibility of the nodes layer.
   * @param {boolean} [on] - can be pass in order to set needed state
   * @return {ForceDirected}
   */
  toggleNodes(on) {
    this._toggleLayer(VisibleElements.nodes, on);
    return this._toggleLayer(VisibleElements.frontNodes, on);
  }

  get isNodesVisible() {
    return this._visibility[VisibleElements.nodes];
  }

  /**
   * Toggle the visibility of the titles layer.
   * @param {boolean} [on] - can be pass in order to set needed state
   * @return {ForceDirected}
   */
  toggleTitles(on) {
    this._toggleLayer(VisibleElements.titles, on);
    this._toggleLayer(VisibleElements.frontTitles, on);

    if (
      this._viewport.scaled < 0.6 &&
      this._visibility[VisibleElements.titles]
    ) {
      this._titlesVisibilityForcedOn = true;
      this._lastTitleVisibility = false;
    }

    return this;
  }

  get isTitlesVisible() {
    return this._visibility[VisibleElements.titles];
  }

  /**
   * Toggle the visibility of the links layer.
   * @param {boolean} [on] - can be pass in order set needed state
   * @return {ForceDirected}
   */
  toggleLinks(on) {
    return this._toggleLayer(VisibleElements.links, on);
  }

  get isLinksVisible() {
    return this._visibility[VisibleElements.links];
  }

  /**
   * Toggle the visibility of the arrows.
   * @param {boolean} [on] - can be pass in order set needed state
   * @return {ForceDirected}
   */
  toggleArrows(on) {
    this._visibility[VisibleElements.arrows] =
      on ?? !this._visibility[VisibleElements.arrows];
    this._emit(
      Events.visibilityToggled,
      VisibleElements.arrows,
      this._visibility[VisibleElements.arrows],
    );
    return this.updateLayout();
  }

  get isArrowsVisible() {
    return this._visibility[VisibleElements.arrows];
  }

  /**
   * Toggle the visibility of the particles layer.
   * @param {boolean} [on] - can be pass in order set needed state
   * @return {ForceDirected}
   */
  toggleParticles(on) {
    return this._toggleLayer(VisibleElements.particles, on);
  }

  get isParticlesVisible() {
    return this._visibility[VisibleElements.particles];
  }

  /**
   * Toggle using of the glow effect.
   * @param {boolean} [on] - can be pass in order set needed state
   * @return {ForceDirected}
   */
  toggleGlowEffect(on) {
    this._visibility[VisibleElements.glowEffect] =
      on ?? !this._visibility[VisibleElements.glowEffect];
    this._emit(
      Events.visibilityToggled,
      VisibleElements.glowEffect,
      this._visibility[VisibleElements.glowEffect],
    );
    return this.updateLayout();
  }

  get isGlowEffectVisible() {
    return this._visibility[VisibleElements.glowEffect];
  }

  get isAutoFitted() {
    return this._autoFit;
  }

  get isTitlesAutoHide() {
    return this._titlesAutoHide;
  }

  /**
   * Toggle auto hiding of the titles layer.
   * @param {boolean} [on] - can be pass in order to set needed state
   * @return {ForceDirected}
   */
  toggleTitlesAutoHide(on) {
    this._titlesAutoHide = on ?? !this._titlesAutoHide;
    this._emit(Events.titlesAutoHideToggled, this._titlesAutoHide);

    this._titlesVisibilityForcedOn = false;
    this._lastTitleVisibility = !this._visibility[VisibleElements.titles];

    return this.updateLayout();
  }

  /**
   * Toggle using of the particles blinking effect.
   * @param {boolean} [on] - can be pass in order set needed state
   * @return {ForceDirected}
   */
  toggleParticlesBlinking(on) {
    this._particlesBlinking = on ?? !this._particlesBlinking;
    return this;
  }

  /**
   * Toggle autofit mode.
   * @param {boolean} [on] - can be pass in order set needed state
   * @return {ForceDirected}
   */
  toggleAutoFit(on) {
    const oldState = this._autoFit;
    this._autoFit = on ?? !this._autoFit;

    if (this._autoFit) {
      this.fitWorld();
    }

    if (oldState !== this._autoFit) {
      this._emit(Events.autoFitToggled, this._autoFit);
    }

    return this;
  }

  get hasPinnedNodes() {
    return (this._simulation.nodes() || {}).some((node) => node.fx != null);
  }

  selected(ids) {
    this._selected = new Set(ids || []);
    this._updateSelectedNodes();
    return this;
  }

  unpinAllNodes() {
    this._simulation.nodes().forEach((node) => {
      node.fx = null;
      node.fy = null;
      node.dfx = null;
      node.dfy = null;
    });
    this._simulation.updateNodes();
    this._restartSimulation();
    return this;
  }

  data({ nodes, links }) {
    if (this.isDestroyed) {
      return this;
    }

    if (!this._canRecieveData) {
      this._savedData = { nodes, links };
      this._simulation.stop();
      return this;
    }

    this._savedData = null;

    this._simulation
      .nodes(nodes || [])
      .links(links || [])
      .sync();

    this._restartSimulation();

    return this.updateLayout();
  }

  particles(particles) {
    if (this.isDestroyed || !this._canRecieveParticles) {
      return this;
    }

    const { nodesIds, linksIds, map } = (particles || []).reduce(
      (acc, particle) => {
        particle.source = { id: particle.source };
        particle.target = { id: particle.target };

        const linkId = getLinkId(particle);

        acc.nodesIds.add(particle.source.id);
        acc.nodesIds.add(particle.target.id);
        acc.linksIds.add(linkId);

        acc.map[linkId] = particle;

        return acc;
      },
      {
        nodesIds: new Set(),
        linksIds: new Set(),
        map: {},
      },
    );

    const fade =
      this._mode === ChartModes.Static ? removeGlowEffectItems : fadeOutItems;

    this._shadowNodes
      .filter((d) => nodesIds.has(d.id))
      .transition('fade')
      .duration(1000)
      .attr('opacity', 1)
      .on(
        'start',
        this._visibility[VisibleElements.glowEffect] ? addGlowFilter : null,
      )
      .call(fade);

    this._shadowTitles
      .filter((d) => nodesIds.has(d.id))
      .transition('fade')
      .duration(1000)
      .attr('opacity', 1)
      .call(fade);

    this._shadowLinks
      .filter((d) => {
        const linkId = getLinkId(d);
        if (!linksIds.has(linkId)) {
          return false;
        }

        const particle = map[linkId];

        Object.assign(particle, {
          id: `${linkId}_${performance.now()}`,
          source: d.source,
          target: d.target,
        });

        return true;
      })
      .transition('fade')
      .duration(1000)
      .attr('opacity', 1)
      .on(
        'start',
        this._visibility[VisibleElements.glowEffect]
          ? this._addGlowFilterLinks
          : null,
      )
      .call(this._fadeOutLinks);

    let transition = this._shadowContainer
      .selectAll('.particle')
      .data(particles || [], getId)
      .enter()
      .append('shadow')
      .attr('class', 'item particle')
      .attr('id', getId)
      .attr('position', 0)
      .attr('opacity', 0)
      .attr('fill', this._colors[Elements.particle])
      .attr('r', this._radius[Elements.particle])
      .attr('scale', 1)
      .transition()
      .duration(500)
      .attr('opacity', 1)
      .on('start', this._addParticle);

    if (this._particlesBlinking) {
      transition = transition
        .transition()
        .duration(200)
        .attr('scale', 4)
        .transition()
        .duration(100)
        .attr('scale', 1);
    }

    transition = transition
      .transition()
      .ease(easeLinear)
      .duration(3000)
      .attr('position', 1);

    if (this._particlesBlinking) {
      transition = transition.transition().duration(200).attr('scale', 3);
    }

    transition.on('end', this._removeParticle).remove();

    this._shadowParticles = this._shadowContainer.selectAll('.particle');

    return this;
  }

  /**
   * @param {{ x: number, y: number }} center
   * @return {ForceDirected}
   */
  resetViewport(center) {
    if (this.isDestroyed) {
      return this;
    }

    this._viewport.moveCenter(center || { x: 0, y: 0 });
    this._viewport.fit(true);

    return this;
  }

  fitWorld() {
    if (!(this._width > 0 && this._height > 0)) {
      return this;
    }

    const width = this._width;
    const height = this._height;

    const nodes = this._simulation.nodes() || [];

    const minNodeX = Math.min(...nodes.map(getX));
    const maxNodeX = Math.max(...nodes.map(getX));
    const minNodeY = Math.min(...nodes.map(getY));
    const maxNodeY = Math.max(...nodes.map(getY));

    const graphWidth = Math.abs(maxNodeX - minNodeX);
    const graphHeight = Math.abs(maxNodeY - minNodeY);

    const worldWidth = graphWidth * 1.2; // Math.min(width * rateOfWorld, graphWidth * 1.2);
    const worldHeight = graphHeight * 1.2; // Math.min(height * rateOfWorld, graphHeight * 1.2);

    const center = new PIXI.Point(
      minNodeX + graphWidth * 0.5,
      minNodeY + graphHeight * 0.5,
    );

    this._resizeViewport(width, height, worldWidth, worldHeight, true, center);

    return this;
  }

  updateLayout(animation = true, afterUpdate = null) {
    const originNodes = this._simulation.nodes() || [];

    if (this.isDestroyed) {
      return this;
    }

    const originLinks = this._simulation.links() || [];

    this._animationChanges = animation;

    this._updateNodes(originNodes);

    this._updateTitles(originNodes);

    this._updateLinks(originLinks);

    this._animationChanges = true;

    this._layoutUpdated = true;

    if (typeof afterUpdate === 'function') {
      this.once(Events.afterDraw, () => {
        this.once(Events.afterDraw, afterUpdate);
      });
    }

    return this;
  }

  pause() {
    this._paused = true;

    if (this.isDestroyed) {
      return this;
    }

    this._simulation.stop();

    return this;
  }

  resume() {
    this._paused = false;

    if (this.isDestroyed) {
      return this;
    }

    this._simulation.restart();

    return this;
  }

  getScale() {
    if (this.isDestroyed) {
      return new PIXI.ObservablePoint(1, 1);
    }

    return this._viewport.scale.clone();
  }

  _updateLinks(originLinks) {
    const links = this._shadow.selectAll('.link').data(originLinks, getLinkId);

    const linksEnter = links
      .enter()
      .append('shadow')
      .attr('class', 'link')
      .attr('id', getLinkId)
      .attr('x0', getSourceX)
      .attr('y0', getSourceY)
      .attr('x1', getTargetX)
      .attr('y1', getTargetY)
      .attr('opacity', 1)
      .attr('width', this._getLinkWidth)
      .attr('stroke', this._colors[Elements.link])
      .each(this._addLink);

    this._fadeOutLinks(linksEnter);

    const updateTransaction =
      this._animationChanges ?? true
        ? this._throttle.updateLinksTransaction
        : this._updateLinksTransaction;

    this._shadowLinks = links
      .merge(linksEnter)
      .attr('x0', getSourceX)
      .attr('y0', getSourceY)
      .attr('x1', getTargetX)
      .attr('y1', getTargetY)
      .attr('arrow', this._visibility[VisibleElements.arrows])
      .call(updateTransaction || (() => {}));

    const ids = new Set();
    links.exit().each((d) => ids.add(getLinkId(d)));
    this._shadow
      .selectAll('.particle')
      .filter((d) => ids.has(getLinkId(d)))
      .each(this._removeParticle)
      .remove();

    links.exit().each(this._removeLink).remove();
  }

  _updateLinksTransaction(selection) {
    const collection =
      selection || this._shadowLinks || this._shadow.selectAll('.link');

    const animation = this._animationChanges ?? true;

    collection
      .transition('updateLinks')
      .duration(animation ? 1000 : 0)
      .attr('width', this._getLinkWidth)
      .attr('stroke', this._colors[Elements.link]);

    return collection;
  }

  _updateTitles(originNodes) {
    const titles = this._shadow.selectAll('.title').data(originNodes, getId);

    const titlesEnter = titles
      .enter()
      .append('shadow')
      .attr('class', 'item title')
      .attr('id', getId)
      .attr('cx', getX0orX)
      .attr('cy', getY0orY)
      .attr('opacity', 1)
      .attr('background', this._colors[Elements.titleBackground])
      .attr('color', this._colors[Elements.title])
      .attr('labelBorderColor', this._colors[Elements.labelBorderColor])
      .attr('labelContextColor', this._colors[Elements.labelContextColor])
      .attr(
        'labelContextBgColor',
        this._colors[Elements.labelContextBackground],
      )
      .attr('labelColor', this._colors[Elements.label])
      .attr('labelBgColor', this._colors[Elements.labelBackground])
      .attr('labelCountColor', this._getLabelCountColor)
      .attr('labelCountBgColor', this._getLabelCountBackground)
      .each(this._addTitle);

    if (this._mode !== ChartModes.Static) {
      fadeOutItems(titlesEnter);
    }

    const updateTransaction =
      this._animationChanges ?? true
        ? this._throttle.updateTitlesTransaction
        : this._updateTitlesTransaction;

    this._shadowTitles = titles
      .merge(titlesEnter)
      .attr('text', getText)
      .attr('labelContext', this._getLabelContext)
      .attr('label', this._getLabel)
      .attr('labelCount', this._getLabelCount)
      .attr('showLabel', this._getShowLabel)
      .attr('cx', getX)
      .attr('cy', getY)
      .attr('anchorY', this._getTitleAnchorY)
      .attr('offsetY', this._getTitleOffsetY)
      .call(updateTransaction || (() => {}));

    titles.exit().each(this._removeTitle).remove();
  }

  _updateTitlesTransaction(selection) {
    const collection =
      selection || this._shadowTitles || this._shadow.selectAll('.title');

    const animation = this._animationChanges ?? true;

    collection
      .transition('updateTitles')
      .duration(animation ? 1000 : 0)
      .attr('background', this._colors[Elements.titleBackground])
      .attr('color', this._colors[Elements.title])
      .attr('labelBorderColor', this._colors[Elements.labelBorderColor])
      .attr('labelContextColor', this._colors[Elements.labelContextColor])
      .attr(
        'labelContextBgColor',
        this._colors[Elements.labelContextBackground],
      )
      .attr('labelColor', this._colors[Elements.label])
      .attr('labelBgColor', this._colors[Elements.labelBackground])
      .attr('labelCountColor', this._getLabelCountColor)
      .attr('labelCountBgColor', this._getLabelCountBackground);

    return collection;
  }

  _updateNodes(originNodes) {
    const nodes = this._shadow.selectAll('.node').data(originNodes, getId);

    const nodesEnter = nodes
      .enter()
      .append('shadow')
      .attr('class', 'item node')
      .attr('id', getId)
      .attr('cx', getX0orX)
      .attr('cy', getY0orY)
      .attr('opacity', 1)
      .attr('r', this._radius[Elements.node])
      .attr('fill', this._colors[Elements.node])
      .each(this._addNode);

    if (this._mode !== ChartModes.Static) {
      fadeOutItems(nodesEnter);
    }

    const updateTransaction =
      this._animationChanges ?? true
        ? this._throttle.updateNodesTransaction
        : this._updateNodesTransaction;

    this._shadowNodes = nodes
      .merge(nodesEnter)
      .attr('cx', getX)
      .attr('cy', getY)
      .attr('r', this._radius[Elements.node])
      .call(this._updateSelectedNodes)
      .call(updateTransaction || (() => {}));

    nodes.exit().each(this._removeNode).remove();
  }

  _updateSelectedNodes(selection) {
    const collection =
      selection || this._shadowNodes || this._shadow.selectAll('.node');
    collection.attr('selected', this._getNodeSelectedColor);
    return collection;
  }

  _updateNodesTransaction(selection) {
    const collection =
      selection || this._shadowNodes || this._shadow.selectAll('.node');

    const animation = this._animationChanges ?? true;

    collection
      .transition('updateNodes')
      .duration(animation ? 1000 : 0)
      .attr('fill', this._colors[Elements.node]);

    return collection;
  }

  _updateParticlesTransaction(selection) {
    const collection =
      selection || this._shadowParticles || this._shadow.selectAll('.particle');

    const animation = this._animationChanges ?? true;

    collection
      .transition('updateParticles')
      .duration(animation ? 1000 : 0)
      .attr('r', this._radius[Elements.particle])
      .attr('fill', this._colors[Elements.particle]);

    return collection;
  }

  // section getters for elements states
  _getRadius(element, defaultValue, data) {
    let getter = this._radiusGetters[element];

    getter = typeof getter === 'function' ? getter(data) : getter;

    const rate = this._viewport.transform
      ? Math.max(Math.min(1 / this._viewport.scaled, 3), 1)
      : 1;

    return (+getter || defaultValue) * rate;
  }

  _getColor(element, defaultValue, data) {
    const getter = this._colorGetters[element];

    if (typeof getter === 'function') {
      return getter(data) || defaultValue;
    }

    return getter || defaultValue;
  }

  _getLinkWidth(link) {
    if (typeof this._linkWidthGetter === 'function') {
      return +this._linkWidthGetter(link) || defaultLinkWidth;
    }

    return +this._linkWidthGetter || defaultLinkWidth;
  }

  _getLabelContext(node) {
    if (typeof this._labelContextGetter === 'function') {
      return this._labelContextGetter(node);
    }

    return this._labelContextGetter;
  }

  _getLabel(node) {
    if (typeof this._labelGetter === 'function') {
      return this._labelGetter(node);
    }

    return this._labelGetter;
  }

  _getLabelCount(node) {
    if (typeof this._labelCountGetter === 'function') {
      return this._labelCountGetter(node);
    }

    return this._labelCountGetter;
  }

  _getShowLabel(node) {
    if (typeof this._showLabelGetter === 'function') {
      return this._showLabelGetter(node);
    }

    return this._showLabelGetter;
  }
  // end section getters

  // section events
  _emit(name, data, event) {
    this._dispatch.call(
      name,
      this,
      data,
      event?.data?.originalEvent || event,
      event?.target?.getBounds?.(),
    );
  }

  _onNodeDragStart(event) {
    if (event?.target !== this._hovered?.graphic) {
      return;
    }

    const node = this._hovered;

    this.toggleAutoFit(false);

    this._viewport.plugins.pause('drag');

    this._clicked = node;

    node.graphic.cursor = 'grabbing';

    const data = select(node).datum();

    node.startPoint = this._viewport.toWorld(event.data.global);

    node.startPoint = {
      x: data.fx - node.startPoint.x,
      y: data.fy - node.startPoint.y,
    };

    this._emit(Events.nodeDragStart, data, event);

    node.graphic.parent.interactive = true;

    select(document.body).style('user-select', 'none');

    this._instance.renderer.plugins.interaction
      .on('pointermove', this._onNodeDrag)
      .on('pointerup', this._onNodeDragEnd)
      .on('pointerupoutside', this._onNodeDragEnd);

    this._restartSimulation();
  }

  _onNodeDrag(event) {
    if (!this._clicked) {
      return;
    }

    const node = this._clicked;

    this._dragged = true;

    this._simulation.switchAutoStop(false);

    const data = select(node).datum();

    const point = this._viewport.toWorld(event.data.global);

    if (node.startPoint) {
      point.x += node.startPoint.x;
      point.y += node.startPoint.y;
    }

    data.dfx = point.x;
    data.dfy = point.y;

    data.fx = point.x;
    data.fy = point.y;

    data.x = point.x;
    data.y = point.y;

    this._simulation.updateNode(data);

    this._emit(Events.nodeDrag, data, event);
  }

  _onNodeDragEnd(event) {
    if (!this._clicked) {
      return;
    }

    const node = this._clicked;
    this._clicked = null;

    node.graphic.parent.interactive = false;

    select(document.body).style('user-select', null);

    this._instance.renderer.plugins.interaction
      .off('pointermove', this._onNodeDrag)
      .off('pointerup', this._onNodeDragEnd)
      .off('pointerupoutside', this._onNodeDragEnd);

    this._viewport.plugins.resume('drag');

    node.startPoint = null;

    node.graphic.cursor = 'grab';

    const data = select(node).datum();

    this._emit(Events.nodeDragEnd, data, event);

    if (event.type === 'pointerupoutside') {
      data.dfx = null;
      data.dfy = null;
      data.fx = null;
      data.fy = null;

      this._simulation.updateNode(data);

      this._emit(Events.nodeOut, data, event);
    }
  }

  _onNodeOver(node) {
    return (event) => {
      if (this._dragged) {
        return;
      }

      let sibling;
      if (node.graphic.parent === this._nodesGroup) {
        sibling = this._titlesGroup.getChildByName(node.graphic.name);
        this._frontNodesGroup.addChild(node.graphic);
        this._frontTitlesGroup.addChild(sibling);
      } else if (node.graphic.parent === this._titlesGroup) {
        sibling = this._nodesGroup.getChildByName(node.graphic.name);
        this._frontTitlesGroup.addChild(node.graphic);
        this._frontNodesGroup.addChild(sibling);
      }

      node.graphic.cursor = 'grab';

      this._hovered = node;

      const data = select(node).datum();

      data.fx = data.x;
      data.fy = data.y;
      this._simulation.updateNode(data);

      data.unpinOnOutAutomatically = data.unpinOnOutAutomatically ?? true;
      data.pinOnOver =
        data.pinOnOver ||
        (() => {
          data.unpinOnOutAutomatically = false;
          data.fx = data.x;
          data.fy = data.y;
          this._simulation.updateNode(data);
        });
      data.unpinOnOut =
        data.unpinOnOut ||
        (() => {
          data.unpinOnOutAutomatically = true;
          data.fx = data.dfx;
          data.fy = data.dfy;
          this._simulation.updateNode(data);

          this._shadowLinks
            .filter((d) => d.source === data || d.target === data)
            .transition('opacity')
            .duration(3000)
            .attr('opacity', getOpacity);
        });

      data.hovered = true;

      this._shadowLinks
        .filter((d) => d.source === data || d.target === data)
        .transition('opacity')
        .duration(200)
        .attr('opacity', 1);

      this._emit(Events.nodeOver, data, event);
    };
  }

  _onNodeOut(node) {
    return (event) => {
      if (this._dragged) {
        return;
      }

      if (this._hovered !== node) {
        return;
      }

      let sibling;
      if (node.graphic.parent === this._frontNodesGroup) {
        sibling = this._frontTitlesGroup.getChildByName(node.graphic.name);
        this._nodesGroup.addChild(node.graphic);
        this._titlesGroup.addChild(sibling);
      } else if (node.graphic.parent === this._frontTitlesGroup) {
        sibling = this._frontNodesGroup.getChildByName(node.graphic.name);
        this._titlesGroup.addChild(node.graphic);
        this._nodesGroup.addChild(sibling);
      }

      this._hovered = null;

      const data = select(node).datum();

      data.hovered = false;

      if (data.unpinOnOutAutomatically) {
        data.unpinOnOut();
      }

      this._emit(Events.nodeOut, data, event);
    };
  }

  _onNodeMove(node) {
    return (event) => {
      if (this._hovered !== node) {
        return;
      }

      const data = select(node).datum();

      this._emit(Events.nodeMove, data, event);
    };
  }

  _onNodeRightClick(node) {
    return (event) => {
      if (this._dragged) {
        this._dragged = false;
        this._simulation.switchAutoStop(true);
        event.stopPropagation();
        return;
      }

      if (this._hovered !== node) {
        return;
      }

      const data = select(node).datum();

      data.dfx = null;
      data.dfy = null;
      data.fx = null;
      data.fy = null;

      this._simulation.updateNode(data);

      this._emit(Events.nodeRightClick, data, event);
    };
  }

  _onNodeClick(node) {
    return (event) => {
      if (this._dragged) {
        this._dragged = false;
        this._simulation.switchAutoStop(true);
        event.stopPropagation();
        return;
      }

      if (this._hovered !== node) {
        return;
      }

      const data = select(node).datum();

      this._emit(Events.nodeClick, data, event);
    };
  }

  _onLabelCountOver(node) {
    return () => {
      this._labelCountHovered = node;
      if (!this._labelCountClickable) {
        return;
      }
      node.graphic.cursor = 'pointer';
      select(node)
        .attr('labelCountColor', this._getLabelCountColor)
        .attr('labelCountBgColor', this._getLabelCountBackground);
    };
  }

  _onLabelCountOut(node) {
    return () => {
      this._labelCountHovered = null;
      if (!this._labelCountClickable) {
        return;
      }
      node.graphic.cursor = 'grab';
      select(node)
        .attr('labelCountColor', this._getLabelCountColor)
        .attr('labelCountBgColor', this._getLabelCountBackground);
    };
  }

  _onLabelCountClick(node) {
    return (event) => {
      event.stopPropagation();
      this._viewport.plugins.pause('drag');
      const data = select(node).datum();
      this._emit(Events.labelCountClick, data, event);
      setTimeout(() => {
        this._viewport.plugins.resume('drag');
      }, 10);
    };
  }
  // end section events

  _drawGraphics() {
    this._shadowGroups?.each(drawGroup);

    this._shadowNodes?.each(drawNode);

    this._shadowTitles?.each(drawNode);

    this._linksActive.clear();
    this._linksInactive.clear();

    this._shadowLinks?.each(drawLink);

    this._shadowParticles?.each(drawParticle);
  }

  /**
   * @param {Layers} layer
   * @param {boolean} [on]
   * @return {ForceDirected}
   * @private
   */
  _toggleLayer(layer, on) {
    this._visibility[layer] = on ?? !this._visibility[layer];
    this._shadow
      .select(`.group#${layer}`)
      .transition()
      .duration(500)
      .attr('opacity', +this._visibility[layer]);
    this._emit(Events.visibilityToggled, layer, this._visibility[layer]);
    return this.updateLayout();
  }

  _tick() {
    if (this.isDestroyed || this._paused) {
      return;
    }

    if (this._autoFit) {
      this.fitWorld();
    }

    this.updateLayout();
  }

  _restartSimulation(alpha = 0.5) {
    if (this._paused) {
      return;
    }

    this._simulation.restart(alpha ?? 0.5);
  }

  _resizeViewport(width, height, worldWidth, worldHeight, fit, center) {
    const wWidth = worldWidth || width * rateOfWorld;
    const wHeight = worldHeight || height * rateOfWorld;

    const lastCenter = center || this._viewport.center;

    this._viewport.resize(width, height, wWidth, wHeight);

    if (fit) {
      this.resetViewport(lastCenter);
    } else {
      this._viewport.moveCenter(lastCenter);
    }
  }

  _resize(width, height) {
    this._width = width;
    this._height = height;

    this._resizeViewport(width, height);

    this.updateLayout();
  }

  _bindFunction() {
    const that = this;

    this._addGlowFilterLinks = function () {
      this.graphic = that._linksActive;
    };

    this._fadeOutLinks = fadeOutItemsFactory(function () {
      this.graphic = that._linksInactive;
    });

    this._getNodeSelectedColor = (node) =>
      node && this._selected.has(getId(node))
        ? this._colors[Elements.selectedNode](node)
        : null;

    this._updateNodesTransaction = this._updateNodesTransaction.bind(this);
    this._updateTitlesTransaction = this._updateTitlesTransaction.bind(this);
    this._updateLinksTransaction = this._updateLinksTransaction.bind(this);
    this._updateParticlesTransaction =
      this._updateParticlesTransaction.bind(this);
    this._updateSelectedNodes = this._updateSelectedNodes.bind(this);

    this._throttle = {
      updateNodesTransaction: throttleRAF(this._updateNodesTransaction, 500),
      updateTitlesTransaction: throttleRAF(this._updateTitlesTransaction, 500),
      updateLinksTransaction: throttleRAF(this._updateLinksTransaction, 500),
      updateParticlesTransaction: throttleRAF(
        this._updateParticlesTransaction,
        500,
      ),
    };

    this._onNodeDragStart = this._onNodeDragStart.bind(this);
    this._onNodeDrag = this._onNodeDrag.bind(this);
    this._onNodeDragEnd = this._onNodeDragEnd.bind(this);

    // getters
    this._radius = Object.keys(this._radiusGetters).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this._getRadius.bind(this, key, defaultRadius[key]),
      }),
      {},
    );

    this._colors = Object.keys(this._colorGetters).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this._getColor.bind(this, key, defaultColors[key]),
      }),
      {},
    );

    this._getTitleOffsetY = (node) => {
      if (this._visibility.nodes) {
        return this._radius[Elements.node](node);
      }

      return 0;
    };

    this._getTitleAnchorY = () => {
      return this._visibility.nodes ? 0.6 : 0;
    };

    this._getLinkWidth = this._getLinkWidth.bind(this);

    this._getLabelContext = this._getLabelContext.bind(this);
    this._getLabel = this._getLabel.bind(this);
    this._getLabelCount = this._getLabelCount.bind(this);
    this._getShowLabel = this._getShowLabel.bind(this);

    this._getLabelCountColor = function (data) {
      const color = that._colors[Elements.labelCount](data);
      return that._labelCountHovered === this
        ? d3color(color).brighter(0.52)
        : color;
    };

    this._getLabelCountBackground = function (data) {
      const color = that._colors[Elements.labelCountBackground](data);
      return that._labelCountHovered === this
        ? d3color(color).brighter(0.52)
        : color;
    };
  }

  _initVariables(options) {
    const {
      mode,
      textStyle,

      nodeRadius,
      particleRadius,

      nodeColor,
      titleColor,
      selectedNodeColor,
      linkColor,
      particleColor,
      labelColor,
      labelCountColor,

      linkWidth,
      labelContext,
      label,
      labelCount,
      showLabel,

      titleBackground,
      labelBorderColor,
      labelContextColor,
      labelContextBackground,
      labelBackground,
      labelCountBackground,
      labelCountClickable,

      particlesBlinking,

      visibleElements,

      autoFit,
      titlesAutoHide,

      selected,
    } = options || {};

    this._textStyle = {
      ...defaultTextStyle,
      ...(textStyle || {}),
    };

    this._mode = mode || ChartModes.Dynamic;

    this._radiusGetters = {
      [Elements.node]: nodeRadius,
      [Elements.particle]: particleRadius,
    };

    this._colorGetters = {
      [Elements.node]: nodeColor,
      [Elements.title]: titleColor,
      [Elements.titleBackground]: titleBackground,
      [Elements.link]: linkColor,
      [Elements.particle]: particleColor,
      [Elements.labelBorderColor]: labelBorderColor,
      [Elements.labelContextColor]: labelContextColor,
      [Elements.labelContextBackground]: labelContextBackground,
      [Elements.label]: labelColor,
      [Elements.labelBackground]: labelBackground,
      [Elements.labelCount]: labelCountColor,
      [Elements.labelCountBackground]: labelCountBackground,
      [Elements.selectedNode]: selectedNodeColor,
    };

    this._linkWidthGetter = linkWidth || defaultLinkWidth;

    this._labelContextGetter = labelContext || defaultLabelContext;
    this._labelGetter = label || defaultLabel;
    this._labelCountGetter = labelCount || defaultLabelCount;
    this._showLabelGetter = showLabel || defaultShowLabel;

    this._visibility = Object.values(VisibleElements).reduce(
      (acc, key) => ({
        ...acc,
        [key]: Boolean((visibleElements || {})[`${key}`] ?? true),
      }),
      {},
    );

    this._visibility[VisibleElements.frontTitles] =
      this._visibility[VisibleElements.titles];
    this._visibility[VisibleElements.frontNodes] =
      this._visibility[VisibleElements.nodes];

    this._particlesBlinking = particlesBlinking ?? true;

    this._autoFit = autoFit ?? false;
    this._titlesAutoHide = titlesAutoHide ?? true;

    this._selected = new Set(selected || []);

    this._labelCountClickable = labelCountClickable ?? false;
  }

  _initForceLayout() {
    const group = (node) => this._groupBy?.(node) ?? '';
    const radius = (node) => {
      const r =
        this._radius?.[Elements.node]?.(node) ?? defaultRadius[Elements.node];

      const rate = this._viewport.transform
        ? Math.max(Math.min(1 / this._viewport.scaled, 3), 1)
        : 1;

      return r / rate;
    };

    this._simulation = new Simulation();
    this._simulation.radius(radius).groupBy(group).nodes([]).links([]).sync();
  }

  _initPixiApplication(container, options) {
    const { quality = GraphicsQuality.High } = options || {};
    this._shadow = select(document.createElement('shadow'));

    const antialias = quality === GraphicsQuality.High;
    PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

    if (!antialias) {
      PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.LOW;
      PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
    }

    this._instance = new PIXI.Application({
      antialias,
      backgroundAlpha: 0,
      resizeTo: container,
      roundPixels: true,
      resolution: 1,
      autoStart: false,
      // forceCanvas: true,
    });

    this._viewport = new Viewport({
      passiveWheel: false,
      interaction: this._instance.renderer.plugins.interaction,
    });

    this._instance.renderer.on('resize', this._resize.bind(this));

    container.append(this._instance.view);
    this._instance.view.addEventListener('contextmenu', (e) =>
      e.preventDefault(),
    );
    this._instance.queueResize();

    this._group = new PIXI.Container();

    this._viewport.addChild(this._group);

    this._instance.stage.addChild(this._viewport);

    this._viewport
      .drag()
      .pinch()
      .wheel()
      .decelerate()
      .clampZoom({ minScale: 0.1, maxScale: 2 });

    this._viewport.moveCenter(0, 0);

    this._cull = new Cull({ dirtyTest: true });

    this._linksGroup = new PIXI.Container();

    this._linksInactive = new PIXI.Graphics();
    this._linksActive = new PIXI.Graphics();
    this._linksActive.filters = [glowFilter];

    this._linksGroup.addChild(this._linksInactive);
    this._linksGroup.addChild(this._linksActive);

    this._particlesGroup = new PIXI.ParticleContainer(
      1e5,
      {
        scale: true,
        position: true,
        alpha: true,
      },
      16384,
      true,
    );
    this._titlesGroup = new PIXI.Container();
    this._nodesGroup = new PIXI.Container();

    // this._frontLinksGroup = new PIXI.Graphics();
    this._frontTitlesGroup = new PIXI.Container();
    this._frontNodesGroup = new PIXI.Container();

    this._cull.addList(this._particlesGroup.children);
    this._cull.addList(this._titlesGroup.children);
    this._cull.addList(this._nodesGroup.children);
    this._cull.addList(this._frontTitlesGroup.children);
    this._cull.addList(this._frontNodesGroup.children);

    const that = this;
    this._shadowGroups = this._shadow
      .selectAll('.group')
      .data(Object.values(Layers))
      .enter()
      .append('shadow')
      .attr('class', 'group')
      .attr('id', (d) => d)
      .attr('opacity', (id) => +(this._visibility[id] ?? true))
      .each(function (id) {
        const item = that[`_${id}Group`];
        this.graphic = item;
        item.name = id;
        if (!(that._visibility[id] ?? true)) {
          item.alpha = 0;
          item.visible = false;
        }
        that._group.addChild(item);
      });

    this._shadowContainer = this._shadow.select('#particles');

    this._addNode = addNodeFactory(this);
    this._removeNode = removeItemFactory(this._nodesGroup);

    this._addTitle = addTitleFactory(this);
    this._removeTitle = removeItemFactory(this._titlesGroup);

    this._addParticle = addParticleFactory(this);
    this._removeParticle = removeItemFactory(this._particlesGroup);

    this._addLink = addLinkFactory(this);
    this._removeLink = () => {};
  }
}

export default ForceDirected;
