import { useMemo } from 'react';

import { PluginIcon } from '@/shared/img/icon';

import ContextLabel from '+components/Labels/ContextLabel';
import ContextNameLabel from '+components/Labels/ContextNameLabel';
import {
  CellIpLabel,
  ShowMoreWrapper,
  UniversalCell,
} from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { getColumnsHelper } from '+utils/getColumnsHelper';
import { isProviderContext } from '+utils/labels';

import { slicedLength } from '../../shared/utils';

export const Columns = {
  rowSelector: 'rowSelector',
  ip: 'ip',
  context: 'context',
  labels: 'labels',
  menu: 'menu',
};

const columnsCollection = ({ labelContexts }) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.ip]: BaseColumnFactory({
    accessor: Columns.ip,
    Header: 'IP Address',
    Cell: UniversalCell(Columns.ip),
    sortType: 'ip',
  }),
  [Columns.context]: BaseColumnFactory({
    accessor: Columns.context,
    Header: 'Context',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <ContextNameLabel>{value}</ContextNameLabel>,
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
      fixedOptions: ['all', ...labelContexts.filter((item) => !item.alias)],
    }),
    filter: 'selectFilter',
  }),
  [Columns.labels]: BaseColumnFactory({
    accessor: Columns.labels,
    Header: 'Labels',
    Cell: ({ value, row }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        return (
          <ShowMoreWrapper
            gap={5}
            originalLength={(value || []).length}
            slicedLength={slicedLength}
          >
            {(value || []).slice(0, slicedLength).map((label) => (
              <ContextLabel
                key={label}
                icon={
                  isProviderContext(original?.context, label) ? (
                    <PluginIcon name={label} size={14} />
                  ) : null
                }
                clickable
              >
                <CellIpLabel
                  field={`label.ip.${original?.context}`}
                  value={label}
                />
              </ContextLabel>
            ))}
          </ShowMoreWrapper>
        );
      }, [row, value]),
  }),
  [Columns.menu]: MenuColumnFactory,
});

export const getColumns = getColumnsHelper(columnsCollection);
