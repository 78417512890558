import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  margin-left: 140px;

  button + button,
  button + div,
  div + div {
    margin-left: 15px;
  }
`;
