import { Breadcrumb } from '+components/Breadcrumb';
import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';

import DnsClassifications from './components/Dns';
import NetworkClassifications from './components/Network';

const LabeledBreadCrumb = (label) => {
  return <Breadcrumb title={`Traffic Classifications ${label}`} />;
};

const additionalTabs = [
  {
    label: 'DNS',
    value: 'dns',
    element: <DnsClassifications />,
  },
];

const TrafficClassifications = () => (
  <EditPageAuditLogTabs
    auditNqlQuery="class == network_classification"
    breadcrumb={LabeledBreadCrumb}
    defaultTabLabel="Network"
    additionalTabs={additionalTabs}
  >
    <NetworkClassifications />
  </EditPageAuditLogTabs>
);

export default TrafficClassifications;
