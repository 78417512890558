import { call, put } from 'redux-saga/effects';

import RoutePaths from '@/models/RoutePaths';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

import { removeAllSavedFilters } from '+utils';

const initialState = {
  isFetching: false,
  error: '',
  users: null, // {},
  sessions: {},
  otpDevices: {},
};

let api;
const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const apiPath = '/user';

export const slice = createSlice({
  name: 'users',
  initialState,

  reducers: {
    ...defaultReducers,
    requestUsers: startFetching,
    requestUsersSuccess(state, { payload }) {
      stopFetching(state);
      state.users = payload.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
    },

    requestUser: startFetching,
    requestUserSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    createUser: startFetching,
    createUserSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    updateUser: startFetching,
    updateUserSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    deleteUser: startFetching,
    deleteUserSuccess(state, { payload: userId }) {
      stopFetching(state);
      delete state.users?.[userId];
    },

    sendChangePasswordEmail: startFetching,
    sendChangePasswordEmailSuccess: stopFetching,

    sendVerificationEmail: startFetching,
    sendVerificationEmailSuccess: stopFetching,

    logoutUser: startFetching,
    logoutUserSuccess: stopFetching,

    logoutUserFromCustomer: startFetching,
    logoutUserFromCustomerSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    blockUser: startFetching,
    blockUserSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    unblockUser: startFetching,
    unblockUserSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    enableOtp: startFetching,
    enableOtpSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    disableOtp: startFetching,
    disableOtpSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    toggleOtpEmail: startFetching,
    toggleOtpEmailSuccess(state, { payload: user }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[user.id] = user;
    },

    requestSessions: startFetching,
    requestSessionsSuccess(state, { payload: { userId, data } }) {
      stopFetching(state);
      if (!state.sessions) {
        state.sessions = {};
      }
      state.sessions[userId] = data;
    },
    clearSessions(state, { payload: userId }) {
      delete state.sessions?.[userId];
    },

    requestOtpDeviceConfiguration: startFetching,
    cancelOtpDeviceConfigurationRequest: startFetching,
    manageOtpDeviceConfigurationSuccess(state, { payload: { userId, data } }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[userId] = data;
    },

    resetOtpDevices: startFetching,
    resetOtpDevicesSuccess(state, { payload: { userId, data } }) {
      stopFetching(state);
      if (!state.users) {
        state.users = {};
      }
      state.users[userId] = data;
    },

    requestOtpDevices: startFetching,
    requestOtpDevicesSuccess(state, { payload: { userId, data } }) {
      stopFetching(state);
      if (!state.otpDevices) {
        state.otpDevices = {};
      }
      state.otpDevices[userId] = data;
    },

    // deleteOtpDevice: startFetching,
    // deleteOtpDeviceSuccess(state, { payload: { userId, deviceId } }) {
    //   stopFetching(state);
    //   if (!state.otpDevices) {
    //     state.otpDevices = {};
    //   }
    //   state.otpDevices[userId] = state.otpDevices[userId].filter((el) => el.id !== deviceId);
    // },

    impersonate: startFetching,
    impersonateSuccess(state, { payload: data }) {
      stopFetching(state);
      localStorage.setItem('impersonate', data.access_token);
      removeAllSavedFilters();
      setTimeout(() => {
        document.location = `${RoutePaths.home}`;
      }, 10);
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.requestUsers]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}s`);
          yield put(actions.requestUsersSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.requestUser]: {
      *saga({ payload: { id, silent = false } }) {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/${id}`);
          yield put(actions.requestUserSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          if (!silent) {
            yield put(
              toastActions.error({
                message: 'Error loading user',
                details: error.message,
              }),
            );
          }
        }
      },
    },

    [actions.createUser]: {
      *saga({ payload: user }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, user);
          yield put(actions.createUserSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'User has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating user',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateUser]: {
      *saga({ payload }) {
        initApi();

        const { email, requiredActions, ...user } = payload;

        try {
          const response = yield call(api.put, `${apiPath}/${user.id}`, user);
          yield put(actions.updateUserSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'User has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating user',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteUser]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${id}`);
          yield put(actions.deleteUserSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'User has been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting user',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.sendChangePasswordEmail]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/send-change-password-email`,
          );
          yield put(actions.sendChangePasswordEmailSuccess());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Password change email sent',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error sending password change email',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.sendVerificationEmail]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/send-verification-email`,
          );
          yield put(actions.sendVerificationEmailSuccess());
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Verification email sent',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error sending verification email',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.logoutUser]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const { data } = yield call(api.post, `${apiPath}/${userId}/logout`);
          yield put(actions.logoutUserSuccess(data.data));
          yield put(toastActions.success('User logged out'));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error issuing user logout',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.logoutUserFromCustomer]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const { data } = yield call(
            api.post,
            `${apiPath}/${userId}/logout-from-customer`,
          );
          yield put(actions.logoutUserFromCustomerSuccess(data.data));
          yield put(toastActions.success('Masquerade revoked'));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error revoking masquerade',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.blockUser]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${userId}/block`);
          yield put(actions.blockUserSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'User blocked',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error issuing user block',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.unblockUser]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${userId}/unblock`);
          yield put(actions.unblockUserSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'User unblocked',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error issuing user unblock',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableOtp]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/enable-otp`,
          );
          yield put(actions.enableOtpSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Two-Factor Authentication enabled for the user',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling Two-Factor Authentication',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableOtp]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/disable-otp`,
          );
          yield put(actions.disableOtpSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Two-Factor Authentication disabled for the user',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling Two-Factor Authentication',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.toggleOtpEmail]: {
      *saga({ payload: { userId, on } }) {
        initApi();

        try {
          const path = on ? 'enable' : 'disable';
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/${path}-otp-email`,
          );
          yield put(actions.enableOtpSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Two-Factor Authentication by Email ${
                on ? 'enabled' : 'disabled'
              } for the user`,
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: `Error ${
                on ? 'enabling' : 'disabling'
              } Two-Factor Authentication by Email`,
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.impersonate]: {
      *saga({ payload: userId }) {
        initApi();
        try {
          const impersonateToken = localStorage.getItem('impersonate');
          const { data } = yield call(
            api.post,
            `${apiPath}/${userId}/impersonate`,
            { deleteSession: !!impersonateToken },
          );
          yield put(actions.impersonateSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error impersonating',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestSessions]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/${userId}/sessions`);
          yield put(actions.requestSessionsSuccess({ userId, data }));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching user sessions',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestOtpDeviceConfiguration]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/request-otp-device-configuration`,
          );
          yield put(
            actions.manageOtpDeviceConfigurationSuccess({
              userId,
              data: response.data.data,
            }),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'OTP device configuration requested',
              response,
              // showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error requesting OTP device configuration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.cancelOtpDeviceConfigurationRequest]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/cancel-otp-device-configuration-request`,
          );
          yield put(
            actions.manageOtpDeviceConfigurationSuccess({
              userId,
              data: response.data.data,
            }),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'OTP device configuration request canceled',
              response,
              // showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error canceling OTP device configuration request',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.resetOtpDevices]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/${userId}/reset-otp-devices`,
          );
          yield put(
            actions.resetOtpDevicesSuccess({
              userId,
              data: response.data.data,
            }),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'OTP devices reset',
              response,
              // showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error resetting OTP devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestOtpDevices]: {
      *saga({ payload: userId }) {
        initApi();

        try {
          const { data } = yield call(
            api.get,
            `${apiPath}/${userId}/otp-devices`,
          );
          yield put(
            actions.requestOtpDevicesSuccess({ userId, data: data.data }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching OTP devices',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getUsers: createSelector([getState], (state) => state.users),

    getUser: (id) => createSelector([getState], (state) => state.users?.[id]),

    getSessions: (id) =>
      createSelector([getState], (state) => state.sessions?.[id]),

    getOtpDevices: (id) =>
      createSelector([getState], (state) => state.otpDevices?.[id]),
  }),
});

export const { actions, selectors } = slice;

export default slice;
