import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { scaleLog, scaleSqrt } from 'd3-scale';
import styled from 'styled-components';

import CHART_COLORS_HEX from '@/models/ChartColors';
import * as PropertiesTray from '@/models/PropertiesTray';

import * as Menu from '+components/Menu';
import TooltipOrigin from '+components/Tooltip';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';

import { getNodeColor } from '../AttackSurface/util';
import { lang } from '../common/utils';
import {
  getLabel,
  getLabelContext,
  getLabelCount,
  getShowLabel,
} from './Chart/extractors';
import Chart, { Button, ChartModes, SettingItem, SettingToggle } from './Chart';
import Resolvers, { sumFields } from './Resolvers';
import TooltipContext from './TooltipContext';

export { Button, SettingItem, SettingToggle, ChartModes, Resolvers, sumFields };

const ContainerChart = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Node = styled.div`
  position: absolute;
  top: ${(props) => Math.round(props.$y || 0)}px;
  left: ${(props) => Math.round(props.$x || 0)}px;
  width: ${(props) => Math.round(props.$style?.width || 14)}px;
  height: ${(props) => Math.round(props.$style?.height || 14)}px;
  background: transparent !important;
  pointer-events: none;
`;

const Tooltip = styled(TooltipOrigin)`
  &.MuiTooltip-tooltip {
    position: relative;
    min-width: 180px;
    max-width: 400px;
    font-size: 12px;
    box-shadow: unset;
    border: 1px solid ${({ theme }) => theme.tooltipBorderColor};
  }
`;

const tooltipTimerId = `__tooltipTimerId__${Math.random()}`;

const NoData = styled.div`
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  text-shadow: ${({ theme }) =>
    theme.name === 'dark' ? '0 0 2px black' : null};
`;

const particleColor = (particle) => getNodeColor(particle.severity);

const ForceDirected = (props) => {
  const {
    className,
    showLegend,
    data: incomingData,
    particles: incomingParticles,
    selectedIds: selectedIps,
    nodesFunction,
    particlesFunction,
    buttons,
    additionalSettingsActions,
    noData,
    suffixOfExportFilename,
    waitingOnNql,
  } = props;

  const [filters] = useGlobalFilters();

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);
  const [globalContextMenu] = useUIProperty('globalContextMenu', null);

  const linkWidthScale = useRef(scaleSqrt([1, 10]).domain([1, 20])).current;
  const nodeRadiusScale = useRef(scaleSqrt([5, 15]).domain([1, 20])).current;
  const colors = useRef(
    scaleLog([CHART_COLORS_HEX[0], CHART_COLORS_HEX[7], CHART_COLORS_HEX[8]]),
  ).current;

  const [data, setData] = useState(null);
  const [particles, setParticles] = useState(null);

  const [position, setPosition] = useState({});
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [tooltipProps, setTooltipProps] = useState(null);
  const [tooltipNode, setTooltipNode] = useState(null);
  const closeTooltipTimer = useRef({});
  const tooltipMenuIsOpen = useRef(false);
  const tooltipIsEntered = useRef(false);

  const lastNodesFunction = useRef(nodesFunction);

  const removeTimer = useCallback((id) => {
    clearTimeout(closeTooltipTimer.current[id]);
    delete closeTooltipTimer.current[id];
  }, []);

  useEffect(() => {
    if (!incomingData) {
      setData(null);
      return;
    }

    if (lastNodesFunction.current !== nodesFunction) {
      lastNodesFunction.current = nodesFunction;
      setData(nodesFunction?.(null, incomingData));
      return;
    }

    setData((prev) => nodesFunction?.(prev, incomingData) || prev);
  }, [incomingData, nodesFunction]);

  useEffect(() => {
    if (!incomingParticles) {
      setParticles(null);
      return;
    }

    setParticles((prev) => particlesFunction?.(incomingParticles) || prev);
  }, [incomingParticles, particlesFunction]);

  const nodeGroupBy = useCallback((node) => node.group, []);

  const legendProps = useMemo(() => {
    let domain = 1;

    let minValue = Infinity;
    let meanValue = 0;
    let maxValue = 0;

    const nodes = Object.values(data?.nodes || {});

    nodes.forEach((node) => {
      domain = Math.max(domain, node.fromCount + node.toCount);
      minValue = Math.min(minValue, node.bits);
      meanValue += node.bits;
      maxValue = Math.max(maxValue, node.bits);
    });

    meanValue /= nodes.length || 1;

    colors.domain([minValue, meanValue, maxValue]);

    nodeRadiusScale.domain([1, domain]);

    domain = 10;

    Object.values(data?.links || {}).forEach((link) => {
      domain = Math.max(domain, link.eventsCount);
    });

    linkWidthScale.domain([1, domain]);

    if (!showLegend || !data) {
      return null;
    }

    return {
      nodeSizeTitle: filters.labelContext.show ? 'IPs Count' : null,
      colorsMap: colors.domain().map((value) => ({
        value,
        color: colors(value),
      })),
      nodeRadiusRange: {
        min: nodeRadiusScale.domain()[0],
        avg: nodeRadiusScale.domain()[1] * 0.5,
        max: nodeRadiusScale.domain()[1],
      },
      linkWidthRange: {
        min: linkWidthScale.domain()[0],
        avg: linkWidthScale.domain()[1] * 0.5,
        max: linkWidthScale.domain()[1],
      },
    };
  }, [data, showLegend, filters.labelContext.show]);

  const nodeColor = useCallback((node) => {
    return colors(node.bits);
  }, []);

  const nodeRadius = useCallback((node) => {
    const { fromCount, toCount } = node;
    const total = fromCount + toCount;
    return nodeRadiusScale(total);
  }, []);

  const linkWidth = useCallback((link) => {
    const { eventsCount } = link;
    return linkWidthScale(eventsCount);
  }, []);

  const linkColor = useCallback((link) => {
    const { direction } = link;
    return direction ? '#61BBD9' : '#fd7b0f';
  }, []);

  const labelContext = useCallback(
    (node) => {
      if (!filters.labelContext.show) {
        return null;
      }

      let _labelContext = filters.labelContext.ip;
      if (node.type === 'port') {
        _labelContext = node.port;
      }

      return getLabelContext({ labelContext: _labelContext });
    },
    [filters.labelContext],
  );

  const label = useCallback(
    (node) => {
      if (!filters.labelContext.show) {
        return null;
      }

      if (node.type === 'label') {
        return node.name;
      }

      const _labelContext = filters.labelContext[node.type];
      const _label = node[`${node.type}Labels`]?.[_labelContext]?.[0] || '';
      return getLabel({ label: _label });
    },
    [filters.labelContext],
  );

  const labelCount = useCallback(
    (node) => {
      if (!filters.labelContext.show) {
        return null;
      }

      if (node.type === 'label') {
        return null;
      }

      const _labelContext = filters.labelContext[node.type];
      const _labels = node[`${node.type}Labels`]?.[_labelContext];
      const _labelCount = (_labels?.length || 1) - 1;
      return getLabelCount({ labelCount: _labelCount });
    },
    [filters.labelContext],
  );

  const showLabel = useCallback(
    (node) => {
      if (!filters.labelContext.show) {
        return false;
      }

      return getShowLabel({
        showLabel: true,
        label: label(node),
      });
    },
    [filters.labelContext, label],
  );

  const onNodeOver = useCallback(
    (node, event, bounds) => {
      if (!node || !bounds) {
        return;
      }

      setPosition({
        y: bounds.y /*  + bounds.height - 14 */,
        x: bounds.x /*  + (bounds.width * 0.5 - 7) */,
        style: {
          height: bounds.height,
          width: bounds.width,
        },
      });

      setTooltipProps({
        ...node,
        labelContext: filters.labelContext,
        ipLabels: node.ipLabels?.[filters.labelContext.ip] || [],
        portLabels: node.portLabels?.[filters.labelContext.port] || [],
        onMenuOpen: () => {
          tooltipMenuIsOpen.current = true;
        },
        onMenuClose: () => {
          tooltipMenuIsOpen.current = false;
          if (!tooltipIsEntered.current) {
            node?.unpinOnOut();
            setIsTooltipOpen(false);
          }
        },
      });

      node.pinOnOver();
      removeTimer(node.id);
      removeTimer(tooltipTimerId);
      setIsTooltipOpen(true);
    },
    [filters.labelContext],
  );

  const onNodeDragStart = useCallback(() => {
    tooltipProps?.unpinOnOut();
    setIsTooltipOpen(false);
  }, [tooltipProps]);

  const onNodeOut = useCallback(() => {
    const { id, unpinOnOut } = tooltipProps || { id: 'notfound' };
    closeTooltipTimer.current[id] = setTimeout(() => {
      unpinOnOut?.();
    }, 100);
    closeTooltipTimer.current[tooltipTimerId] = setTimeout(() => {
      setIsTooltipOpen(false);
    }, 100);
  }, [tooltipProps]);

  const popperProps = useMemo(
    () => ({
      ref: setTooltipNode,
    }),
    [],
  );

  useEffect(() => {
    if (!tooltipNode) {
      return undefined;
    }

    const { id, unpinOnOut } = tooltipProps || { id: 'notfound' };

    const onEnter = () => {
      tooltipIsEntered.current = true;
      removeTimer(id);
      removeTimer(tooltipTimerId);
    };

    const onLeave = () => {
      tooltipIsEntered.current = false;
      closeTooltipTimer.current[id] = setTimeout(() => {
        if (!tooltipMenuIsOpen.current) {
          if (globalContextMenu) {
            return;
          }
          unpinOnOut?.();
          setIsTooltipOpen(false);
        }
      }, 100);
      closeTooltipTimer.current[tooltipTimerId] = setTimeout(() => {
        if (!tooltipMenuIsOpen.current) {
          if (globalContextMenu) {
            return;
          }
          setIsTooltipOpen(false);
        }
      }, 100);
    };

    tooltipNode.addEventListener('mouseenter', onEnter);
    tooltipNode.addEventListener('mouseleave', onLeave);

    return () => {
      removeTimer(tooltipTimerId);
      if (tooltipNode) {
        tooltipNode.removeEventListener('mouseenter', onEnter);
        tooltipNode.removeEventListener('mouseleave', onLeave);
      }
    };
  }, [tooltipNode, globalContextMenu]);

  const TooltipComp = useMemo(
    () => <TooltipContext {...tooltipProps} />,
    [tooltipProps],
  );

  useEffect(() => {
    tooltipIsEntered.current = false;
    tooltipMenuIsOpen.current = false;

    return () => {
      removeTimer(tooltipTimerId);
    };
  }, [tooltipProps]);

  useEffect(
    () => () => {
      Object.keys(closeTooltipTimer.current).forEach(removeTimer);
    },
    [],
  );

  const onNodeClick = useCallback(
    (node) => {
      let trayData = [
        {
          dataType: PropertiesTray.DataTypes.field,
          field: node.type,
          value: node.name,
          customer: node.customer,
        },
      ];

      if (node.type === 'label') {
        trayData = Array.from(node.ips).map((ip) => ({
          dataType: PropertiesTray.DataTypes.field,
          field: 'ip',
          value: ip,
          customer: node.customer,
        }));
        trayData.unshift({
          dataType: PropertiesTray.DataTypes.field,
          field: `label.ip.${filters.labelContext.ip}`,
          value: node.name,
          customer: node.customer,
        });
      }

      setPropertiesTray({
        data: trayData,
        isOpen: true,
      });
    },
    [filters.labelContext.ip],
  );

  const selectedIds = useMemo(() => {
    if (!data?.nodes || !selectedIps?.length) {
      return [];
    }

    return Object.values(data.nodes || {})
      .filter((node) => {
        const { ips } = node;
        if (!ips?.size) {
          return false;
        }

        return selectedIps.some((ip) => ips?.has(ip));
      })
      .map((node) => node.id);
  }, [data, selectedIps]);

  return (
    <Fragment>
      {waitingOnNql && <NoData>{lang.waitingOnNql}</NoData>}
      {!waitingOnNql && (
        <ContainerChart className={className}>
          <Chart
            data={data}
            particles={particles}
            selectedIds={selectedIds}
            mode={ChartModes.Static}
            groupBy={nodeGroupBy}
            nodeColor={nodeColor}
            linkColor={linkColor}
            particleColor={particleColor}
            nodeRadius={nodeRadius}
            linkWidth={linkWidth}
            labelContext={labelContext}
            label={label}
            labelCount={labelCount}
            showLabel={showLabel}
            onNodeOver={onNodeOver}
            onNodeOut={onNodeOut}
            onNodeDragStart={onNodeDragStart}
            onNodeDragEnd={onNodeOver}
            onNodeClick={onNodeClick}
            onLabelCountClick={onNodeClick}
            legendProps={legendProps}
            buttons={buttons}
            additionalSettingsActions={additionalSettingsActions}
            suffixOfExportFilename={suffixOfExportFilename}
          />
          {!data && noData && !waitingOnNql && <NoData>{noData}</NoData>}
          <Tooltip
            key={`${Object.values(position).join('-')}`}
            title={TooltipComp}
            open={isTooltipOpen}
            PopperProps={popperProps}
            arrow={false}
            enterDelay={0}
            enterTouchDelay={0}
            TransitionProps={{ timeout: 0 }}
          >
            <Node $x={position.x} $y={position.y} $style={position.style} />
          </Tooltip>
          <Menu.TriggerMenu />
        </ContainerChart>
      )}
    </Fragment>
  );
};

ForceDirected.propTypes = {
  className: PropTypes.string,
  showLegend: PropTypes.bool,
  nodesFunction: PropTypes.func,
  particlesFunction: PropTypes.func,
  buttons: PropTypes.children,
  noData: PropTypes.children,
  data: PropTypes.arrayOf(PropTypes.shape()),
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  particles: PropTypes.arrayOf(PropTypes.shape()),
  additionalSettingsActions: PropTypes.arrayOf(PropTypes.shape()),
  suffixOfExportFilename: PropTypes.string,
  waitingOnNql: PropTypes.bool,
};

ForceDirected.defaultProps = {
  className: '',
  showLegend: true,
  nodesFunction: Resolvers.ip.nodes,
  particlesFunction: null,
  buttons: null,
  noData: null,
  data: null,
  selectedIds: null,
  particles: null,
  additionalSettingsActions: null,
  suffixOfExportFilename: 'fdc',
  waitingOnNql: false,
};

export default Menu.withMenu(ForceDirected);
