import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

/**
 * @param {number} algorithmId
 * @param {Object} triggerData
 * @return {{configuredTriggers: (function(*=): string), triggerExists: (function(*=): boolean)}}
 */
export default (algorithmId, triggerData) => {
  const dispatch = useDispatch();

  const { isRulesFetching, error } = useSelector(rulesSelectors.getState);
  const algorithm = useSelector(rulesSelectors.getAlgorithm(algorithmId));
  const configuredTriggers = useSelector(
    rulesSelectors.getConfiguredTriggersByAlgorithm(algorithm?.name),
  );

  useEffect(() => {
    if (algorithm?.name && !configuredTriggers && !error) {
      dispatch(
        rulesActions.fetchConfiguredTriggersByAlgorithm(algorithm?.name),
      );
    }
  }, [algorithm?.name, configuredTriggers, isRulesFetching, error]);

  const triggerExists = useCallback(() => {
    if (!triggerData || !configuredTriggers || !configuredTriggers.length) {
      return false;
    }

    const values = Object.values(triggerData.track_values);

    const matchingTrigger = configuredTriggers.find((trigger) =>
      values.every((trackValue) => trigger.track.includes(trackValue)),
    );
    return !!matchingTrigger;
  }, [configuredTriggers, triggerData]);

  const getTrackBySearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(triggerData?.track_values || {});
    return searchParams.size > 0 ? `?${searchParams.toString()}` : '';
  }, [triggerData]);

  return { getTrackBySearchParams, triggerExists };
};
