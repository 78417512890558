import PropTypes from '+prop-types';
import { useMemo } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { avatarColors } from '+theme';

/**
 * Avatar component.
 */
const Avatar = styled((props) => {
  const { className, style, src, alt, name, ...tail } = props;

  const initials = useMemo(() => {
    const nameArr = (name || 'N A').split(' ');
    const first = (nameArr[0] || '').charAt(0).toUpperCase();
    const second = (nameArr[1] || '').charAt(0).toUpperCase();
    return `${first}${second}`;
  }, [name]);

  const avatarColor = useMemo(() => {
    const charIndexSum = Array.from(initials).reduce(
      (sum, char) => sum + Math.abs(char.charCodeAt(0) - 65),
      0,
    );
    const colorIndex = charIndexSum % avatarColors.length;
    return avatarColors[colorIndex];
  }, [initials]);

  return (
    <div
      {...tail}
      className={classNames(className || '', 'avatar')}
      style={{ ...style, backgroundColor: avatarColor }}
    >
      {!src && initials}
      {src && <img className="avatar-image" src={src} alt={alt} />}
    </div>
  );
})`
  display: flex;
  align-items: center;

  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  width: ${({ $size }) => $size || '100%'};
  height: ${({ $size }) => $size || '100%'};

  position: relative;
  font-size: ${({ $size }) => `calc(${$size || '100%'} / 2)`};
  flex-shrink: 0;
  line-height: 1;
  user-select: none;

  transition:
    width 0.3s ease-in-out 0.1s,
    height 0.3s ease-in-out 0.1s;

  .avatar-image {
    color: transparent;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: center;
    text-indent: 10000px;
  }
`;

Avatar.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Override or extend the styles applied to the component.
   */
  style: PropTypes.shape({}),
  /**
   * The src attribute for the img element.
   */
  src: PropTypes.string,
  /**
   * An alt attribute for the rendered img element.
   */
  alt: PropTypes.string,
  /**
   * The name attribute that will used if there is no img.
   */
  name: PropTypes.string,
};

Avatar.defaultProps = {
  className: '',
  style: {},
  src: '',
  alt: 'avatar',
  name: 'N A',
};

export default Avatar;
