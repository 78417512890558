import capitalize from 'lodash.capitalize';

import { ContextTypesLabels } from '@/models/ContextTypes';

import { SelectColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

export const TrafficColumnFactory = (props = {}) =>
  BaseColumnFactory({
    Header: 'traffic',
    getCellProps: () => ({
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
    Filter: SelectColumnFilter({
      optionLabel: (value) => ContextTypesLabels[value] || capitalize(value),
    }),
    filter: 'selectFilter',
    minWidth: 60,
    maxWidth: 60,
    disableResizing: true,
    ...props,
  });
