import { createSelector } from 'reselect';

import { createSlice } from '@/redux/util';

const initialState = {
  isPaused: {},
};

export const slice = createSlice({
  name: 'socketControl',
  initialState,

  reducers: {
    pause(state, { payload: tabId }) {
      state.isPaused[tabId] = true;
    },
    resume(state, { payload: tabId }) {
      state.isPaused[tabId] = false;
    },
  },

  sagas: () => ({}),

  selectors: (getState) => ({
    isPaused: (tabId) =>
      createSelector([getState], (state) => state.isPaused[tabId] ?? true),
  }),
});

export const { actions, selectors } = slice;
export default slice;
