import styled from 'styled-components';

import AlertTypeLabel from '+components/Labels/AlertTypeLabel';
import dayjs, { DateFormat } from '+utils/dayjs';

const dateToString = (date) =>
  dayjs.unix(date).format(DateFormat.secondWithoutDate);

export default styled(({ className, start, end, alerttype }) => {
  if ((!Number.isNaN(+end) && start === end) || alerttype === 'start') {
    return (
      <div className={className}>
        <span>{dateToString(start)}</span>
      </div>
    );
  }

  return (
    <div className={className}>
      <span>{dateToString(start)}</span> -{' '}
      {Number.isNaN(+end) || +end <= 0 ? (
        <AlertTypeLabel state={alerttype} active />
      ) : (
        <span>{dateToString(end)}</span>
      )}
    </div>
  );
})`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 7px;

  span {
    font-weight: bold !important;
    color: ${(props) => props.theme.rightSidebarEventTitleTextColor};
  }
`;
