import styled from 'styled-components';

import IconButtonOrigin from '+components/IconButton';
import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

const AdditionalFiltersButton = styled(IconButtonOrigin)`
  --color: ${({ $expanded }) =>
    $expanded
      ? globalFiltersTheme.additionalFiltersButtonText
      : globalFiltersTheme.additionalFiltersButtonCollapsedText};
  align-self: center;
  color: var(--color);
  background-color: ${globalFiltersTheme.additionalFiltersButtonBackground};

  &:focus,
  &:hover,
  &:active {
    color: var(--color);
    background-color: ${globalFiltersTheme.additionalFiltersButtonBackground};
  }

  &.MuiIconButton-sizeSmall {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
`;

export default AdditionalFiltersButton;
