import { makeId } from '+utils';

export const groups = {
  recent: 'Recent',
  favorites: 'Favorites',
  system: 'System',
  custom: 'Custom',
};

const separator = makeId();

const makeValue = (group, id) => `${group}${separator}${id}`;

export const makeOptions = (dashboards, group, currentId, isDefaultCustomer) =>
  Object.values(dashboards)
    .map((item) => ({
      group,
      value: makeValue(group, item.id),
      label: item.id === currentId ? `${item.title} (current)` : item.title,
      disabled: isDefaultCustomer != null && item.system && !isDefaultCustomer,
      id: item.id,
      lastseen: item.lastseen,
    }))
    .sort((a, b) => {
      if (group === groups.recent) {
        return b.lastseen - a.lastseen;
      }
      return a.label.localeCompare(b.label);
    });

export const formatter = (value, options) => {
  const option = options.find((el) => el.id === value);
  return !option ? '' : makeValue(option.group, option.id);
};

export const parser = (value) => (value == null ? value : value.id);
