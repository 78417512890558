const getPortalSourceType = ({ logtype, flowtype, flowresource }) => {
  if (
    flowtype === 'aws' &&
    flowresource === 's3' &&
    logtype === 'aws-tgw-flow-log'
  ) {
    return 'aws-tgw/s3';
  }
  if (
    flowtype === 'azure' &&
    flowresource === 'blobstorage' &&
    logtype === 'azure-vnet-flow-log'
  ) {
    return 'azure-vnet/blobstorage';
  }

  return `${flowtype}/${flowresource}`;
};

export default getPortalSourceType;
