import PropTypes from '+prop-types';

import styled from 'styled-components';

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ContainerDiv = styled.div`
  display: block;
  margin-right: 10px;
`;

const StyledDiv = styled.div`
  display: inline-block;
  margin-right: 10px;
`;

const DarkSpan = styled.span`
  color: ${(props) =>
    props.theme.table.recordSubDetailsSubTitleColor} !important;
`;

const LightSpan = styled.span`
  margin-left: 2px;
  color: ${(props) => props.theme.table.recordSubDetailsTitleColor} !important;
`;

const CellTrafficRecord = ({ value }) => {
  const { domain, ...rest } = value || {};

  const arr = Object.entries(rest);

  const fields = arr.map(([key, val]) => (
    <StyledDiv key={key}>
      <DarkSpan>{key}:</DarkSpan>
      <LightSpan>{val}</LightSpan>
    </StyledDiv>
  ));

  if (!domain) {
    return fields;
  }

  return (
    <ParentDiv>
      <ContainerDiv>
        <DarkSpan>domain:</DarkSpan>
        <LightSpan>{domain}</LightSpan>
      </ContainerDiv>
      <ContainerDiv>{fields}</ContainerDiv>
    </ParentDiv>
  );
};

CellTrafficRecord.propTypes = {
  value: PropTypes.object,
};
CellTrafficRecord.defaultProps = {
  value: null,
};

export default CellTrafficRecord;
