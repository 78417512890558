import PropTypes from '+prop-types';
import { useEffect } from 'react';
import {
  MapContainer as MapOrigin,
  TileLayer as TileLayerOrigin,
  useMap,
  ZoomControl,
} from 'react-leaflet';

import L from 'leaflet';
import styled from 'styled-components';

import FullscreenControl from './FullscreenControl';
import Marker from './Marker';

L.Icon.Default.imagePath = '/leaflet/';

const SetView = ({ animate, location }) => {
  const map = useMap();

  useEffect(() => {
    const zoom = location.lat === 0 && location.lon === 0 ? 2 : 11;
    map.setView([location.lat, location.lon], zoom, { animate });
  }, [animate, location.lat, location.lon]);

  return null;
};

SetView.propTypes = {
  animate: PropTypes.bool,
  location: PropTypes.shape(),
};
SetView.defaultProps = {
  animate: false,
  location: {},
};

const TileLayer = styled(TileLayerOrigin)`
  filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3)
    brightness(0.7);
  filter: ${({ theme }) => (theme.name === 'dark' ? null : 'none')};
`;

const Map = styled((props) => {
  const { geo, className } = props;
  const { location } = geo;

  return (
    <MapOrigin
      center={[0, 0]}
      className={className}
      style={{
        minHeight: '240px',
        height: '100%',
        width: '100%',
        borderRadius: '4px',
      }}
      zoomControl={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {(location.lat !== 0 || location.lon !== 0) && <Marker geo={geo} />}
      <ZoomControl position="topleft" />
      <FullscreenControl position="topright" />
      <SetView location={location} animate />
    </MapOrigin>
  );
})`
  background: rgba(0, 0, 0, 0.15);
  // Fullscreen
  .leaflet-control-fullscreen a {
    background: #fff
      url(/assets/leaflet/fullscreen-${({ theme }) => theme.name}.png) no-repeat
      0 0;
    background-size: 30px 60px;
  }
  .leaflet-touch .leaflet-control-fullscreen a {
    background-position: 2px 2px;
  }
  .leaflet-fullscreen-on .leaflet-control-fullscreen a {
    background-position: 0 -30px;
  }
  .leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
    background-position: 2px -28px;
  }

  /* Do not combine these two rules; IE will break. */
  .leaflet-container:-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
  }
  .leaflet-container.leaflet-fullscreen-on {
    width: 100% !important;
    height: 100% !important;
  }

  .leaflet-pseudo-fullscreen {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 99999;
  }

  .leaflet-bar a {
    background-color: ${({ theme }) => theme.contextMenuBackground};

    &:not(:last-child) {
      border-bottom: 1px solid transparent;
    }

    span {
      color: ${({ theme }) => theme.contextMenuColor};
    }
  }

  .leaflet-control-attribution {
    background: ${({ theme }) => theme.contextMenuBackground};
    color: ${({ theme }) => theme.contextMenuColor};
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .leaflet-control-fullscreen a {
      background-image: url(/assets/leaflet/fullscreen-${({ theme }) =>
        theme.name}@2x.png);
    }
  }
`;

Map.propTypes = {
  className: PropTypes.string,
  geo: PropTypes.shape({
    continentcode: PropTypes.string,
    countrycode: PropTypes.string,
    subdiso: PropTypes.string,
    subdivisionb: PropTypes.string,
    city: PropTypes.string,
    postal: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
};

Map.defaultProps = {
  className: null,
  geo: {
    continentcode: null,
    countrycode: null,
    subdiso: null,
    subdivisionb: null,
    city: null,
    postal: null,
    location: { lat: 0, lon: 0 },
  },
};

export default Map;
