import { createSelector, createSlice } from '@/redux/util';

const initialState = {};

const slice = createSlice({
  name: 'ui',
  initialState,

  reducers: {
    change(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    remove(state, { payload }) {
      delete state[payload];
    },
  },

  selectors: (getState) => ({
    get: (key) => createSelector([getState], (state) => state[key]),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
