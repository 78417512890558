import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';

import { Breadcrumb } from '+components/Breadcrumb';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { Col, Row } from '+components/Layout';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useUIProperty from '+hooks/useUIProperty';

import { excludeMetrics } from '../../utils';
import BgpTab from './components/BgpTab';

const rootPath = `${RoutePaths.sources}`;

const excludeContexts = new Set([
  ContextTypes.alerts,
  ContextTypes.blocks,
  ContextTypes.traffic,
  ContextTypes.dns,
]);

const DeviceInfo = (props) => {
  const { id } = props;

  const dispatch = useDispatch();

  const { deviceid } = useParams();
  const normalizedId = id || deviceid;

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const { error, isFetching } = useSelector(deviceSelectors.getState);
  const device = useSelector(deviceSelectors.getDeviceById(normalizedId));
  const status = useSelector(deviceSelectors.getStatusById(normalizedId));
  useEffect(() => {
    if (status) {
      return;
    }
    dispatch(deviceActions.devicesStatus());
  }, [status]);

  useEffect(() => {
    if (status || error) {
      return undefined;
    }

    const intervalId = setInterval(() => {
      dispatch(deviceActions.devicesStatus());
    }, 5e3);

    return () => {
      clearInterval(intervalId);
    };
  }, [status, error]);

  useEffect(() => {
    if (device || !normalizedId) {
      return;
    }

    dispatch(deviceActions.deviceFetchById(normalizedId, normalizedId));
  }, [device, normalizedId]);

  useEffect(
    () => () => {
      dispatch(deviceActions.cancel(normalizedId));
      dispatch(deviceActions.cancel('testing'));
    },
    [normalizedId],
  );

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [rootPath]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        context={context}
        excludeMetrics={excludeMetrics}
        excludeContexts={excludeContextsArr}
        nql
        metric
        socketControl={false}
      />
      {device && (
        <Breadcrumb title={`BGP Config - ${device?.name || 'unknown'}`} />
      )}

      {!device && !isFetching && (
        <Row alignItems="center" style={{ height: '80vh' }}>
          <Col alignItems="center" style={{ margin: '5% 0' }}>
            <h3>Device not found</h3>
            <Link to={`${RoutePaths.sources}`}>Manage Devices</Link>
          </Col>
        </Row>
      )}

      {device && !status && (
        <Row alignItems="center" style={{ height: '80vh' }}>
          <Col alignItems="center" style={{ margin: '5% 0' }}>
            {!error && <h3>Fetching device status...</h3>}
            {error && (
              <Fragment>
                <h3>Could not retrieve device status</h3>
                <Link to={`${RoutePaths.sources}`}>Manage Devices</Link>
              </Fragment>
            )}
          </Col>
        </Row>
      )}

      {device && status && <BgpTab device={device} status={status} />}
    </Fragment>
  );
};

DeviceInfo.propTypes = {
  id: PropTypes.string,
};

DeviceInfo.defaultProps = {
  id: null,
};

export default DeviceInfo;
