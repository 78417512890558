import { Fragment } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import ArrowTopLeftIcon from 'mdi-react/ArrowTopLeftIcon';
import FileChartOutlineIcon from 'mdi-react/FileChartOutlineIcon';
import FileCodeOutlineIcon from 'mdi-react/FileCodeOutlineIcon';
import FileExcelOutlineIcon from 'mdi-react/FileExcelOutlineIcon';
import FullscreenExitIcon from 'mdi-react/FullscreenExitIcon';
import FullscreenIcon from 'mdi-react/FullscreenIcon';
import PrinterIcon from 'mdi-react/PrinterIcon';

import { countries } from '@/models/countries';

import SeriesTypes from '+components/charts/common/SeriesTypes';
import {
  Container,
  TrGroup,
} from '+components/Table/ReactTable/components/Components';

export const defaultColorVariety = 0;
export const paletteColorsLength = 20; // check here $palette: src/scss/vendor/_highcharts-colors.scss

const severities = ['high', 'medium', 'low'];

const makeDef = (id) => ({
  tagName: 'linearGradient',
  id,
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 1,
  children: [
    {
      tagName: 'stop',
      offset: 0,
    },
    {
      tagName: 'stop',
      offset: 1,
    },
  ],
});

export const getDefs = (config) => {
  const {
    paletteDefs = true,
    countriesDefs = true,
    severitiesDefs = true,
  } = config || {};

  const defs = {};

  if (paletteDefs) {
    for (let i = 0; i < 10; i += 1) {
      for (let j = 0; j < paletteColorsLength; j += 1) {
        const id = `g-${i}-${j}`;
        defs[id] = makeDef(id);
      }
    }
  }

  if (countriesDefs) {
    Object.keys(countries).forEach((countryCode) => {
      const id = `g-${countryCode}`;
      defs[id] = makeDef(id);
    });
  }

  if (severitiesDefs) {
    severities.forEach((severity) => {
      const id = `g-${severity}`;
      defs[id] = makeDef(id);
    });
  }

  // Unknown
  const id = 'g-Unknown';
  defs[id] = makeDef(id);

  return defs;
};

// @see https://highcharts.uservoice.com/forums/55896-highcharts-javascript-api/suggestions/995999-add-an-event-for-legend-item-hovering
export const legendItemHover = (event) => {
  const { chart: legendHoveredChart } = event.target;
  const { legend, tooltip } = legendHoveredChart;
  for (const item of legend.allItems) {
    const isTimeseries = [SeriesTypes.area, SeriesTypes.line].includes(
      item.initialType,
    );
    if (!isTimeseries) {
      item.legendItem.label
        .on('mouseover', () => {
          const data = item?.series?.data?.[item.index] || item?.data?.[0];
          if (data) {
            tooltip.refresh(data);
          }
          // isHidden = true is a part of the workaround for issue with tooltip hidden when hovering on the legend
          // It's important to set isHidden = true after the tooltip is shown (not before)
          // @see: https://netography.atlassian.net/browse/PORTAL-1866
          tooltip.isHidden = true;
          legend.allItems.forEach((itm) => itm.setState('inactive'));
          item.setState('hover');
        })
        .on('mouseout', () => {
          // isHidden = false is a part of the workaround for issue with tooltip hidden when hovering on the legend
          // It's important to set isHidden = false before the tooltip is hidden (not after)
          // @see: https://netography.atlassian.net/browse/PORTAL-1866
          tooltip.isHidden = false;
          tooltip.hide();
          legend.allItems.forEach((itm) => itm.setState('normal'));
        });
    }
  }
};

// Refactored legendItemClick event
// @see https://www.highcharts.com/plugin-registry/single/56/ReverseLegendClickAction
export const legendItemClick = (event) => {
  event.preventDefault();

  // event.target.series.chart - in pie chart case
  const legendClickedChart = event.target.chart || event.target.series.chart;
  const legendItemIndex = event.target.index;

  const { options: legendOptions } = legendClickedChart?.legend || {};
  if (!legendOptions?.reverseLegendClickAction) {
    return;
  }

  const { navigator, series } = legendClickedChart;
  const { chart } = legendClickedChart.userOptions;
  const chartSeries = chart.type === 'pie' ? series[0]?.points : series;

  const totalSeriesLength = navigator?.navigatorEnabled
    ? chartSeries.length - (navigator?.series?.length ?? 0)
    : chartSeries.length;

  if (!totalSeriesLength || totalSeriesLength === 1) {
    return;
  }
  const visibleSeriesIndexes = chartSeries.reduce((acc, item) => {
    if (item.visible) {
      acc.push(item.index);
    }
    return acc;
  }, []);

  if (
    visibleSeriesIndexes.length === 1 &&
    visibleSeriesIndexes.includes(legendItemIndex)
  ) {
    // make all legend items visible
    chartSeries.forEach((item) => item.setVisible(true, false));
    legendClickedChart.redraw();
    return;
  }

  if (visibleSeriesIndexes.length === totalSeriesLength) {
    // deselect all not clicked
    chartSeries.forEach((item) => {
      // don't consider navigator series
      if (!`${item.name}`.includes('Navigator')) {
        const visible =
          item.options.alwaysVisible || item.index === legendItemIndex;
        item.setVisible(visible, false);
      }
    });
    legendClickedChart.redraw();
    return;
  }

  chartSeries.forEach((item) => {
    if (item.index === legendItemIndex) {
      const visible =
        item.options.alwaysVisible ||
        !visibleSeriesIndexes.includes(legendItemIndex);
      item.setVisible(visible, false);
    }
    // Uncomment if you want to make only clicked legend item visible and hide all others
    // const visible = item.options.alwaysVisible || item.index === legendItemIndex;
    // item.setVisible(visible, false);
  });

  legendClickedChart.redraw();
};

export const downloadFile = (content, filename, type = 'text/csv') => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);

  const blob = new Blob([content], { type });
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}`);
  fakeLink.click();
  fakeLink.remove();
};

export const downloadDataUrl = (dataUrl, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);

  fakeLink.setAttribute('href', dataUrl);
  fakeLink.setAttribute('download', `${filename}`);
  fakeLink.click();
  fakeLink.remove();
};

export const downloadImageByCanvas = (svg, filename) => {
  const blob = new Blob([svg], { type: 'image/svg+xml' });

  const img = new Image();
  // img.style.display = 'none';
  document.body.appendChild(img);
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.clientWidth;
    canvas.height = img.clientHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    downloadDataUrl(canvas.toDataURL('image/png'), filename);
    img.remove();
  };
  img.src = URL.createObjectURL(blob);
};

export const getSvg = (svgElement, background) => {
  const svg = svgElement.cloneNode(true);
  svg.setAttribute('version', '1.1');
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute(
    'viewBox',
    `0 0 ${svgElement.clientWidth} ${svgElement.clientHeight}`,
  );
  if (background) {
    const rect = document.createElement('rect');
    rect.setAttribute('x', 0);
    rect.setAttribute('y', 0);
    rect.setAttribute('width', svgElement.clientWidth);
    rect.setAttribute('height', svgElement.clientHeight);
    rect.setAttribute('fill', background);
    svg.insertBefore(rect, svg.firstChild);
  }
  return svg.outerHTML;
};

export const printStyle = `
  .react-grid-item {
    border: 2px solid gray !important;
  }

  .widget__chart_container {
    visibility: visible !important;
    opacity: 1 !important;
  }

  ${TrGroup} {
    border-bottom: 1px solid lightgray !important;
  }

  .react-resizable-handle,
  .widget__drag-drop-bar,
  .widget__controls,
  .highcharts-title .mdi-icon,
  .highcharts-exporting-group,
  .highcharts-tooltip,
  .highcharts-map-navigation,
  .widget__table-title .mdi-icon,
  .pagination-container,
  button .mdi-icon,
  [class^='Menu__ThemedMenu'],
  [class^='AddWidget'] {
    display: none !important;
  }

  [class^='NoData'] {
    height: 30vh;
  }

  .chart {
    background-image: none !important;
  }

  .react-grid-item,
  ${Container},
  ${Container} *,
  .gauge-with-sparkline-chart,
  .value-with-sparkline-chart,
   [class^='SeriesSpiralChart'],
  .highcharts-background {
    fill: white !important;
    background-color: white !important;
  }

  :not(.highcharts-solidgauge-series) text { fill: gray !important; }

  .highcharts-heatmap-series text { fill: white !important; }

  .highcharts-label span:after,
  .highcharts-solidgauge-series span {
    background-color: transparent !important;
  }

  .highcharts-label span text,
  .value-chart__value {
    fill: #000 !important;
  }

  .gauge-chart .highcharts-pane {
    fill: gray !important;
    fill-opacity: 1 !important;
  }

  .highcharts-title,
  .highcharts-subtitle {
    text-shadow: none !important;
  }

  .highcharts-subtitle {
    fill: gray !important;
  }

  .highcharts-data-label:not([visibility="hidden"]) {
    visibility: visible !important;
  }

  .report-title {
    width: var(--bodyWidth, 100%);
    text-align: center;
    font-size: 2em;
    font-weight: 600;
  }

  .report-customer {
    width: var(--bodyWidth, 100%);
    text-align: center;
    font-size: 1.6em;
    font-weight: 600;
  }

  .report-date {
    width: var(--bodyWidth, 100%);
    text-align: center;
  }

  .report-logo {
    width: var(--bodyWidth, 100%);
    display: flex;
    justify-content: center;
  }

  .report-logo img {
    width: 15%;
  }

  .report-body {
    margin: 60px 0;
  }
`;

export const openPrintDialog = (content, title, bodyWidth) => {
  const { clientHeight, clientWidth } = document.documentElement;
  let newWindow;
  while (!newWindow) {
    newWindow = window.open(
      '',
      'Print',
      `height=${clientHeight},width=${bodyWidth || clientWidth}`,
    );
  }
  newWindow.document.title = title;
  newWindow.document.write(content);

  setTimeout(() => {
    newWindow.document.body.style.removeProperty('visibility');
    newWindow.document.close();
    newWindow.onafterprint = () => {
      // dispatchEvent works only in setTimeout
      setTimeout(() => {
        window.dispatchEvent(new Event('afterChartPrint'));
      }, 10);
      newWindow.close();
    };
    newWindow.focus();
    newWindow.print();
  }, 1000);
};

export const print = (ref, styleOverride) => {
  const element = ref?.current || ref?.container;
  if (!element) {
    return;
  }

  window.dispatchEvent(new Event('beforeChartPrint'));

  const { outerHTML: head } = document.head;
  const { outerHTML: body } = element;

  const content = `
    <html lang="en">
      ${head}
      <body class="theme-light" style="visibility: hidden">
        <style>
          ${printStyle}
          .value-chart__value {
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            width: ${element.clientWidth} !important;
            height: ${element.clientHeight} !important;

            font-family: "Source Sans Pro", sans-serif !important;
            font-weight: 600 !important;
            text-anchor: middle !important;
            fill: #000 !important;
            stroke-width: 0 !important;
          }
          .chart-body {
            width: ${element.clientWidth};
            height: ${element.clientHeight};
            border: 2px solid gray !important;
            position: relative;
            box-sizing: content-box;
            padding: 5px;
          }
          ${styleOverride || ''}
        </style>
        <div class="chart-body">
          ${body}
        </div>
      </body>
    </html>
  `;

  openPrintDialog(content, document.title);
};

export const lang = {
  loading: 'Loading data...',
  waitingOnNql: 'Input NQL to load data',
  noData: 'No Data',
  resetZoom: '↺',
  thousandsSep: ',',
  viewFullscreen: renderToStaticMarkup(
    <Fragment>
      <FullscreenIcon size={16} />
      <span>Fullscreen</span>
    </Fragment>,
  ),
  exitFullscreen: renderToStaticMarkup(
    <Fragment>
      <FullscreenExitIcon size={16} />
      <span>Exit fullscreen</span>
    </Fragment>,
  ),
  pushToGF: renderToStaticMarkup(
    <Fragment>
      <ArrowTopLeftIcon size={16} />
      <span>Push to GF</span>
    </Fragment>,
  ),
  printChart: renderToStaticMarkup(
    <Fragment>
      <PrinterIcon size={16} />
      <span>Print</span>
    </Fragment>,
  ),
  downloadCSV: renderToStaticMarkup(
    <Fragment>
      <FileExcelOutlineIcon size={16} />
      <span>CSV</span>
    </Fragment>,
  ),
  downloadPNG: renderToStaticMarkup(
    <Fragment>
      <FileChartOutlineIcon size={16} />
      <span>PNG</span>
    </Fragment>,
  ),
  downloadSVG: renderToStaticMarkup(
    <Fragment>
      <FileCodeOutlineIcon size={16} />
      <span>SVG</span>
    </Fragment>,
  ),
};

export const exportingButtons = ({ onPushToGF = false } = {}) => ({
  contextButton: {
    menuItems: [
      onPushToGF && {
        textKey: 'pushToGF',
        onclick() {
          onPushToGF(this.axes);
        },
        separator: false,
      },
      'viewFullscreen',
      {
        textKey: 'printChart',
        onclick() {
          print(this);
        },
        separator: false,
      },
      'downloadCSV',
      'downloadPNG',
      'downloadSVG',
    ].filter(Boolean),
  },
});

export function exportingLoadEvent() {
  this.update({
    plotOptions: {
      series: { dataLabels: { enabled: true } },
      pie: { dataLabels: { enabled: true } },
      scatter: { dataLabels: { enabled: true } },
    },
  });
  this.container.classList.add('export');
  if (this.options.__colorVariety !== false) {
    this.container.classList.add(
      `p-${this.options.__colorVariety ?? defaultColorVariety}`,
    );
  }
}

export function positioner(...args) {
  const [tooltipWidth, tooltipHeight] = args;
  const position = this.getPosition(...args);
  const { chart } = this;
  const { userMin, userMax } = chart?.xAxis?.[0] || {};
  const zoomed = !!(userMin || userMax);
  if (zoomed) {
    const resetZoomButtonBoundaryX =
      chart.resetZoomButton.translateX - chart.resetZoomButton.width / 2;
    const resetZoomButtonBoundaryY =
      chart.resetZoomButton.translateY + chart.resetZoomButton.height / 2;
    const maxPositionX = Math.round(resetZoomButtonBoundaryX - tooltipWidth);
    const minPositionY = Math.round(resetZoomButtonBoundaryY + tooltipHeight);
    if (position.x > maxPositionX && position.y < minPositionY) {
      position.x = maxPositionX;
    }
  }
  return position;
}
