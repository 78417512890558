import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 0;
  left: ${({ $separatorWidth }) => $separatorWidth * -1}px;
  bottom: 0;
  width: ${({ $separatorWidth }) => $separatorWidth * 2}px;
  background-color: transparent;
  z-index: 99997;
  &:hover {
    cursor: col-resize;
    background-color: ${({ theme }) => theme.colorBackgroundSeparator};
  }
`;
