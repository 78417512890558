import styled, { css } from 'styled-components';

import Autocomplete from '@mui/material/Autocomplete';

export default styled(Autocomplete)`
  width: 100%;

  .MuiAutocomplete-inputRoot {
    padding: 0 7px !important;
    box-sizing: border-box;
    cursor: ${(props) =>
      props.$limitReached ? 'default !important' : undefined};
    height: unset !important;
    min-height: 32px;

    .MuiAutocomplete-input {
      min-width: unset;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 13px;
      padding: ${({ $showClear }) =>
        $showClear ? '0 40px 0 0' : '0 18px 0 0'} !important;
      border: unset !important;
      border-radius: unset !important;
      cursor: ${(props) =>
        props.$limitReached ? 'default !important' : undefined};
      caret-color: ${(props) =>
        props.$limitReached ? 'transparent !important' : undefined};
    }

    .MuiAutocomplete-tag {
      height: 24px;
      border: 1px solid ${({ theme }) => theme.colorFieldsBorder};
      border-radius: 4px;
      background: ${({ theme }) => theme.colorFieldBackground};
      font-size: 13px;
      margin: 3px 6px 3px 0;
      max-width: calc(100% - 14px) !important;

      .MuiChip-label {
        padding-left: 5px;
        padding-right: 5px;
      }

      .MuiChip-deleteIcon {
        transform: scale(0.7);
        margin-right: 0;
      }

      &.isDragging {
        opacity: 0.5;
      }
    }

    &.Mui-disabled {
      padding-left: unset !important;
      padding-right: unset !important;

      .MuiAutocomplete-input {
        padding-right: unset !important;
      }

      .MuiAutocomplete-endAdornment {
        display: none;
      }

      .MuiAutocomplete-tag {
        opacity: 1 !important;
        background: transparent !important;
        pointer-events: initial;

        .MuiChip-label {
          pointer-events: initial;
          user-select: text;
          cursor: text;
          color: ${({ theme }) => theme.colorTextSecondary};
        }

        .MuiChip-deleteIcon {
          display: none;
        }
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    right: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .MuiIconButton-root {
      padding: unset !important;
      visibility: unset !important;
      &:hover {
        background: unset !important;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      &.MuiAutocomplete-clearIndicator {
        width: 24px;
        height: 100%;
      }
      &.MuiAutocomplete-popupIndicator {
        width: 24px;
        height: 100%;
      }
    }

    .MuiIconButton-label {
      color: ${({ theme }) => theme.colorFieldsBorder};
    }
  }

  ${(props) =>
    props.$readOnly &&
    css`
      .MuiInputBase-root {
        background: transparent !important;
      }
      .MuiInputBase-input,
      .MuiAutocomplete-endAdornment {
        cursor: default;
        pointer-events: none;
      }
      .MuiAutocomplete-tag {
        background: transparent !important;
        .MuiChip-deleteIcon {
          opacity: 0.25;
          pointer-events: none;
        }
      }
    `}
`;
