import { useCallback } from 'react';
import { ensurePluginOrder } from 'react-table';

const pluginName = 'useFiltersColumnMethodsExtender';

const useInstance = (instance) => {
  const { flatHeaders, setAllFilters, plugins, state } = instance;

  ensurePluginOrder(plugins, ['useFilters'], pluginName);

  const { filters } = state || {};

  const resetAllFilters = useCallback(() => {
    setAllFilters([]);
  }, [setAllFilters]);

  const tableHasFilters = !!filters?.length;

  flatHeaders.forEach((column) => {
    column.resetAllFilters = resetAllFilters;
    column.tableHasFilters = tableHasFilters;
  });
};

export const useFiltersColumnMethodsExtender = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useFiltersColumnMethodsExtender.pluginName = pluginName;
