import { config } from '@/config';

const isLocal = window.location.hostname.endsWith('.local');

const getHostname = () => {
  const { hostname } = window.location;
  const parts = hostname.split('.');

  return parts.slice(isLocal ? -2 : -3).join('.');
};

const hostname = getHostname();

const getHomeUrl = (shortname) => {
  const { port, protocol } = window.location;

  const domain = [shortname, hostname].filter(Boolean).join('.');
  const portPart = port ? `:${port}` : '';

  return `${protocol}//${domain}${portPart}`;
};

export const homeUrl = getHomeUrl();

export const getSsoLoginUrl = (shortname) => getHomeUrl(shortname);

export const getSsoLogoutUrl = (shortname) =>
  `${homeUrl}/auth/logout?returnTo=${encodeURIComponent(
    getSsoLoginUrl(shortname),
  )}`;

export const getIdpLoginUrl = (shortname) => {
  const path = [
    config.keycloak.serverUrl,
    'realms',
    config.keycloak.realm,
    'protocol',
    'openid-connect',
    'auth',
  ].join('/');

  const search = new URLSearchParams({
    client_id: config.keycloak.clientId,
    redirect_uri: homeUrl,
    response_mode: 'fragment',
    response_type: 'code',
    scope: 'openid',
    kc_idp_hint: shortname,
  });

  return `${path}?${search}`;
};

export const getMetadataXmlPublicUrl = (shortname) =>
  `${config.appBackendUrlRoot}/api/v1/sso/${shortname}/metadata.xml`;

export const getShortnameFromDomain = () => {
  const domain = window.location.hostname;
  const parts = domain.split('.');

  return parts.at(isLocal ? -3 : -4);
};

export default {
  getSsoLoginUrl,
  getSsoLogoutUrl,
  getShortnameFromDomain,
  getIdpLoginUrl,
  getMetadataXmlPublicUrl,
};
