const escape = (str) => str.replace(/[.*+?^${}()|[\]\\]/gu, '\\$&');
const hasSpace = (str) => /\s/g.test(escape(`${str}`));
const hasOr = (str) => /(\s(or|\\\|\\\|)\s)/giu.test(escape(`${str}`));
const bracketForAnd = (str) => (hasOr(str) ? `(${str})` : str);
const hasAnd = (str) => /(\s(and|&&)\s)/giu.test(escape(`${str}`));
const bracketForOr = (str) => (hasAnd(str) ? `(${str})` : str);
const needQuotes = (str) => /[,'"()[\]#+]/.test(escape(`${str}`));
const isValid = (key, value) => key && value != null && value !== '';

export const quote = (str) => {
  const isNeededQuotes = hasSpace(str) || needQuotes(str);

  if (isNeededQuotes) {
    const quoteSymbol = /"/.test(str) ? "'" : '"';
    return `${quoteSymbol}${str}${quoteSymbol}`;
  }

  return str;
};

export const and = (...args) =>
  args
    .filter((a) => a?.trim?.())
    .map((a, _, array) => (array.length > 1 ? bracketForAnd(a) : a))
    .join(' && ');

export const or = (...args) =>
  args
    .filter((a) => a?.trim?.())
    .map((a, _, array) => (array.length > 1 ? bracketForOr(a) : a))
    .join(' || ');

export const equal = (key, value) =>
  isValid(key, value) ? `${key} == ${quote(value)}` : '';

export const notEqual = (key, value) =>
  isValid(key, value) ? `${key} != ${quote(value)}` : '';

export const greater = (key, value) =>
  isValid(key, value) ? `${key} > ${quote(value)}` : '';

export const greaterOrEqual = (key, value) =>
  isValid(key, value) ? `${key} >= ${quote(value)}` : '';

export const less = (key, value) =>
  isValid(key, value) ? `${key} < ${quote(value)}` : '';

export const lessOrEqual = (key, value) =>
  isValid(key, value) ? `${key} <= ${quote(value)}` : '';

const proceedLine = (value) => {
  let result = value || '';
  if (!result.trim().length) {
    return result.trim();
  }

  const prefix = result.replace(/^(\s+)?.*/, '$1');

  result = result.trim();

  const opReg = /(=~)|(!~)|(==)|(!=)|(>=?)|(<=?)|(\|\|)|(&&)|(∙or∙)|(∙and∙)/gi;
  const strReg = /('.*?')|(".*?")|(\/.*?\/)/g;
  const spacedOrReg = /(^|[\s"')])(or)([\s!(]|$)/gi;
  const spacedAndReg = /(^|[\s"')])(and)([\s!(]|$)/gi;

  const hash = {};

  result =
    result.match(strReg)?.reduce((acc, item) => {
      const key = btoa(encodeURIComponent(item)).replace(/[=/+]/g, '.');
      hash[key] = item;
      return acc.replaceAll(item, ` ${key} `);
    }, result) || result;

  result = result
    .replace(spacedOrReg, '$1∙$2∙$3')
    .replace(spacedAndReg, '$1∙$2∙$3')
    .replace(/\s/g, '')
    .split(opReg)
    .map((item) => (item || '').trim())
    .filter((item) => item !== '')
    .join(' ')
    .replace(/∙/g, '')
    .replace(/,/g, ', ');

  return Object.entries(hash).reduce(
    (acc, [key, item]) => acc.replaceAll(key, item),
    `${prefix}${result}`,
  );
};

export const format = (value) => {
  return (value || '').split(/\n/g).map(proceedLine).join('\n');
};

export default {
  quote,
  and,
  or,
  equal,
  notEqual,
  greater,
  greaterOrEqual,
  less,
  lessOrEqual,
  format,
};
