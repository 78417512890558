import { useContext } from 'react';

import { Context } from './context';

export const useCurrentTheme = () => {
  const value = useContext(Context);

  if (value == null) {
    throw new Error(
      'useCurrentTheme must be used within a CurrentThemeProvider',
    );
  }

  return value;
};
