import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import {
  actions as billingActions,
  selectors as billingSelectors,
} from '@/redux/api/billing';
import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import Button from '+components/Button';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import dayjs from '+utils/dayjs';

const CurrencyMap = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 120px;
  font-size: 13px;
  font-weight: 600;
`;

const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 580px;
  min-width: 420px;
  border: 1px solid ${({ theme }) => theme.colorFieldsBorder};
  border-radius: 4px;
  padding: 20px;
`;

const SubscriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SubscriptionName = styled.div`
  font-weight: bold;
`;

const SubscriptionDescription = styled.div``;

const SubscriptionAmount = styled.div``;

const UsageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
`;

const UsageItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsageLabel = styled.div`
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const UsageValue = styled.div``;

export const BillingPage = () => {
  const dispatch = useDispatch();

  const isFetching = useSelector(billingSelectors.isFetching);
  const plans = useSelector(billingSelectors.getPlans);
  const subscriptions = useSelector(billingSelectors.getSubscriptions);
  const chargebeePortalSession = useSelector(
    billingSelectors.getChargebeePortalSession,
  );
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const totalGb = useSelector(customerSelectors.getTotalGb);

  useLoadingIndicator(isFetching);

  const subscription = useMemo(
    () => subscriptions?.[0]?.subscription,
    [subscriptions],
  );

  const subscriptionRenderer = useMemo(() => {
    if (!subscription) {
      return <SubscriptionName>No plan activated</SubscriptionName>;
    }

    switch (subscription.status) {
      case 'in_trial': {
        const daysRemaining = dayjs(subscription.trial_end * 1000).diff(
          dayjs().startOf('second'),
          'days',
        );
        return (
          <div>
            <SubscriptionName>Free trial</SubscriptionName>
            <SubscriptionDescription>
              {daysRemaining} day{daysRemaining > 1 ? 's' : ''} remaining
            </SubscriptionDescription>
          </div>
        );
      }
      case 'active': {
        const plan = subscription.subscription_items.filter(
          (item) => item.item_type === 'plan',
        )[0];
        const commited = subscription.subscription_items.filter((item) =>
          item.item_price_id.startsWith('Committed-'),
        )[0];
        if (!plan) {
          return null;
        }
        const planId = plan.item_price_id.split('-').slice(0, -2).join('-');
        const planName =
          plans.find((p) => p.item.id === planId)?.item?.name ||
          planId.split('-').join(' ');
        const planAmount = (plan.amount / 100).toFixed(2);
        const planCurrency =
          CurrencyMap[subscription.currency_code] || subscription.currency_code;
        const planPeriod =
          subscription.billing_period > 1
            ? `${subscription.billing_period_unit} x ${subscription.billing_period}`
            : `${subscription.billing_period_unit}`;
        const includedGb = plan.quantity * 100;
        const commitedGb = commited ? commited.quantity : 0;
        return (
          <Fragment>
            <SubscriptionName>{planName}</SubscriptionName>
            <SubscriptionDescription>
              {includedGb} GB included
              {commitedGb ? `, ${commitedGb} GB commited` : ''}
            </SubscriptionDescription>
            <SubscriptionDescription>
              {customer?.retention || 0} days data retention
            </SubscriptionDescription>
            <SubscriptionAmount>
              {planCurrency}
              {planAmount}/{planPeriod}
            </SubscriptionAmount>
          </Fragment>
        );
      }
      case 'non_renewing':
        return (
          <SubscriptionName>
            Plan will be canceled at the end of the billing period
          </SubscriptionName>
        );
      case 'paused':
        return <SubscriptionName>Plan paused</SubscriptionName>;
      case 'cancelled':
        return <SubscriptionName>Plan cancelled</SubscriptionName>;
      default:
        return null;
    }
  }, [subscription, plans, customer?.retention]);

  // const availableDataIngest = useMemo(() => {
  //   if (subscription?.status !== 'active') {
  //     return 0;
  //   }
  //
  //   const plan = subscription.subscription_items.filter(
  //     (item) => item.item_type === 'plan',
  //   )[0];
  //   const commited = subscription.subscription_items.filter((item) =>
  //     item.item_price_id.startsWith('Committed-'),
  //   )[0];
  //
  //   if (!plan) {
  //     return 0;
  //   }
  //
  //   const includedGb = plan.quantity * 100;
  //   const commitedGb = commited?.quantity || 0;
  //   return includedGb + commitedGb;
  // }, [subscription]);

  const onManageSubscription = useCallback(() => {
    if (!window.cbInstance || !chargebeePortalSession) {
      return;
    }

    window.cbInstance.setPortalCallbacks({
      close: () => {
        dispatch(billingActions.getSubscriptions());
      },
    });
    window.cbInstance.setPortalSession(() => {
      return new Promise((resolve) => {
        resolve(chargebeePortalSession);
      });
    });
    const cbPortal = window.cbInstance.createChargebeePortal();
    cbPortal.open();
  }, [chargebeePortalSession]);

  useEffect(() => {
    if (!window.cbInstance) {
      return () => {};
    }
    dispatch(billingActions.createChargebeePortalSession());
    return () => {
      dispatch(billingActions.clearChargebeePortalSession());
    };
  }, []);

  useEffect(() => {
    dispatch(billingActions.getPlans());
    dispatch(billingActions.getSubscriptions());
    dispatch(customerActions.fetchTotalGb(customer.shortname));
  }, [customer.shortname]);

  return (
    <Container>
      <Row>
        <Label>Subscription</Label>

        <SubscriptionContainer>
          <SubscriptionsContainer>
            {subscriptionRenderer}
          </SubscriptionsContainer>

          <Button
            onClick={onManageSubscription}
            disabled={!chargebeePortalSession}
            testId="manage-subscription-button"
          >
            Manage Subscription
          </Button>
        </SubscriptionContainer>
      </Row>

      <Row>
        <Label>Usage</Label>

        <UsageContainer>
          <UsageItem>
            <UsageLabel>Data Ingest</UsageLabel>
            <UsageValue>
              <b>{totalGb?.usage || 0} GB</b> of {totalGb?.limit || 0} GB
            </UsageValue>
          </UsageItem>
        </UsageContainer>
      </Row>
    </Container>
  );
};
