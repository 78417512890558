import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import capitalize from 'lodash.capitalize';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';

import BarChart from '+components/charts/BarChart';
import { usePageTabs } from '+components/PageTabs';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { timeBounds } from '+utils/timeBounds';

const chartType = 'devices-agg';
const fields = {
  [ContextTypes.flow]: ['flowsrcname'],
  [ContextTypes.dns]: ['datasrc'],
};

const SourcesBarChart = (props) => {
  const {
    sources,
    width,
    height,
    excludeMetrics,
    size,
    seriesNameFormatter,
    context,
  } = props;

  const [, activePageTab] = usePageTabs();
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );
  const filtersManualRefresher = useSelector(
    globalFiltersSelectors.getRefresherManualOnly(activePageTab?.id),
  );

  const namespace = useMemo(() => `sources-bar-chart-${context}`, [context]);

  const [filters] = useGlobalFilters(context);
  const { start, end } = timeBounds(filters);
  const request = useMemo(
    () => ({
      seriesId: namespace,
      params: {
        start,
        end,
        chart: 'bar',
        series: [
          {
            metric: filters.metric,
            size,
            name: chartType,
            field: fields[context],
            ...StatsRequest.makeSearch({
              search: filters.nql,
              intersect: filters.intersect,
            }),
          },
        ],
      },
    }),
    [
      namespace,
      start,
      end,
      filters.metric,
      filters.nql,
      filters.intersect,
      size,
      context,
    ],
  );

  const { series, isFetching: isStatsFetching } = useStatsRequest({
    context,
    requestType: StatsRequest.Types.agg,
    request,
    stopRequest: excludeMetrics.includes(filters.metric) || !sources?.length,
    refresher: filtersManualRefresher,
    stopPollingHeartbeat: !autoRefresh,
  });

  const title = useMemo(
    () =>
      series === undefined
        ? ''
        : `Top ${size} by ${capitalize(filters.metric)}`,
    [series, size, filters.metric],
  );

  useLoadingIndicator(isStatsFetching);

  return (
    <BarChart
      title={title}
      series={series?.[0]}
      metric={filters.metric}
      fields={fields[context]}
      height={height}
      width={width}
      seriesNameFormatter={seriesNameFormatter}
      loading={!series}
      type="column"
    />
  );
};

SourcesBarChart.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
  height: PropTypes.number,
  excludeMetrics: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.number,
  seriesNameFormatter: PropTypes.func,
  context: PropTypes.string.isRequired,
};

SourcesBarChart.defaultProps = {
  sources: [],
  width: undefined,
  height: 300,
  excludeMetrics: [],
  size: 10,
  seriesNameFormatter: undefined,
};

export default SourcesBarChart;
