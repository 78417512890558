// eslint-disable-next-line no-unused-vars
import * as PIXI from 'pixi.js-legacy';

const defaultSimpleOptions = {
  visibleProp: 'visible',
  dirtyTest: false,
};

/**
 * @param {PIXI.Rectangle} bounds
 * @param {PIXI.Rectangle} box
 * @returns {boolean}
 */
export const isCulled = (bounds, box) =>
  box.x + box.width > bounds.x &&
  box.x < bounds.x + bounds.width &&
  box.y + box.height > bounds.y &&
  box.y < bounds.y + bounds.height;

export const isContained = (bounds, box) =>
  box.x + box.width > bounds.x &&
  box.x - box.width < bounds.x + bounds.width &&
  box.y + box.height > bounds.y &&
  box.y - box.height < bounds.y + bounds.height;

export const updateObjectAABB = (object) => {
  const box = object.getLocalBounds();
  object.AABB = object.AABB || { x: 0, y: 0, width: 0, height: 0 };
  object.AABB.x =
    object.x + (box.x - object.pivot.x) * Math.abs(object.scale.x);
  object.AABB.y =
    object.y + (box.y - object.pivot.y) * Math.abs(object.scale.y);
  object.AABB.width = box.width * Math.abs(object.scale.x);
  object.AABB.height = box.height * Math.abs(object.scale.y);
};

export class Cull {
  /**
   * Creates a simple cull
   * Note, options.dirtyTest defaults to false. Set to true for much better performance--this requires
   * additional work to ensure displayObject.dirty is set when objects change
   *
   * @param {object} [options]
   * @param {string} [options.visible='visible'] - The property to set visibility of object
   * @param {string} [options.dirtyTest=false] - only update the AABB box for objects with object.dirty === true; this has a HUGE impact on performance
   */
  constructor(options) {
    options = { ...defaultSimpleOptions, ...(options || {}) };
    this.visibleProp = options.visible ?? defaultSimpleOptions.visibleProp;
    this.dirtyTest = options.dirtyTest ?? defaultSimpleOptions.dirtyTest;
    this.lists = [[]];
  }

  setVisible(displayObject, visible) {
    displayObject[this.visibleProp || defaultSimpleOptions.visibleProp] =
      visible;
  }

  getVisible(displayObject) {
    return displayObject[this.visibleProp || defaultSimpleOptions.visibleProp];
  }

  /**
   * add an array of objects to be culled, eg: `cull.addList(container.children)`
   * @param {Array} array
   * @param {boolean} [staticObject] - set to true if the object's position/size does not change
   * @return {Array} array
   */
  addList(array, staticObject) {
    this.lists.push(array);
    if (staticObject) {
      array.staticObject = true;
    }

    array.forEach((item) => {
      this.updateObject(item);
    });

    return array;
  }

  /**
   * remove an array added by addList()
   * @param {Array} array
   * @return {Array} array
   */
  removeList(array) {
    const index = this.lists.indexOf(array);

    if (index === -1) {
      return array;
    }

    this.lists.splice(index, 1);
    return array;
  }

  /**
   * add an object to be culled
   * NOTE: for implementation, add and remove uses this.lists[0]
   *
   * @param {DisplayObject} object
   * @param {boolean} [staticObject] - set to true if the object's position/size does not change
   * @return {DisplayObject} object
   */
  add(object, staticObject) {
    if (staticObject) {
      object.staticObject = true;
    }

    if (this.dirtyTest || staticObject) {
      this.updateObject(object);
    }

    this.lists[0].push(object);
    return object;
  }

  /**
   * remove an object added by add()
   * NOTE: for implementation, add and remove uses this.lists[0]
   *
   * @param {DisplayObject} object
   * @return {DisplayObject} object
   */
  remove(object) {
    const index = this.lists[0].indexOf(object);

    if (index === -1) {
      return object;
    }

    this.lists[0].splice(index, 1);
    return object;
  }

  /**
   * cull the items in the list by changing the object[visibleProp]
   * @param {PIXI.Rectangle} bounds
   * @param {boolean} [skipUpdate] - skip updating the AABB bounding box of all objects
   */
  cull(bounds, skipUpdate) {
    if (!skipUpdate) {
      this.updateObjects();
    }

    this.lists.forEach((list) => {
      list.forEach((item) => {
        if (item.AABB) {
          this.setVisible(item, isCulled(bounds, item.AABB));
        }
      });
    });
  }

  /**
   * update the AABB for all objects
   * automatically called from update() when calculatePIXI=true and skipUpdate=false
   *
   * @returns {Cull}
   */
  updateObjects() {
    this.lists.forEach((list) => {
      if (list.staticObject) {
        return;
      }

      list.forEach((item) => {
        if (list.staticObject || (this.dirtyTest && item.AABB && !item.dirty)) {
          return;
        }

        this.updateObject(item);

        if (this.dirtyTest) {
          item.dirty = false;
        }
      });
    });

    return this;
  }

  /**
   * update the has of an object
   * automatically called from updateObjects()
   * @param {DisplayObject} object
   *
   * @returns {Cull}
   */
  updateObject(object) {
    updateObjectAABB(object);

    return this;
  }

  /**
   * returns an array of objects contained within bounding box
   * @param {PIXI.Rectangle} bounds - bounding box to search
   * @return {DisplayObject[]} - search results
   */
  getContainedChildren(bounds) {
    return this.lists.flatMap((list) =>
      list.filter((item) => item.AABB && isContained(bounds, item.AABB)),
    );
  }

  /**
   * iterates through objects contained within bounding box
   * stops iterating if the checker function returns true
   * @param {PIXI.Rectangle} bounds - bounding box to search
   * @param {function} checker
   * @return {boolean} - true if callback returned early
   */
  someInBounds(bounds, checker) {
    return this.lists.some((list) =>
      list.some(
        (item) => item.AABB && isContained(bounds, item.AABB) && checker(item),
      ),
    );
  }

  /**
   * get stats
   * @return {{total: number, culled: number, visible: number}}
   */
  stats() {
    return this.lists.reduce(
      (acc, list) => {
        acc.total += list.length;
        acc.visible += list.filter((item) => this.getVisible(item)).length;
        acc.culled = acc.total - acc.visible;
        return acc;
      },
      { total: 0, visible: 0, culled: 0 },
    );
  }
}

export default Cull;
