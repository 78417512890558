import PropTypes from '+prop-types';
import { Suspense, useCallback, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import Button, { ButtonVariants } from '+components/Button';
import { useFormState } from '+components/form/FinalForm';
import { Label } from '+components/form/FormField';

import {
  dashboardPropTypes,
  defaultWidgetProps,
} from '../../../shared/propTypes';
import { widgetParamsToUi } from '../../../shared/utils';
import { widgets } from '../../../shared/widgets';
import FieldsGroup from '../../components/FieldsGroup';
import FieldsRow from '../../components/FieldsRow';
import ChartTypeFields from './components/ChartTypeFields';

const FieldsCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  ${FieldsGroup} {
    position: relative;
    width: calc(100% - 140px);
  }
`;

const RemoveButton = styled(Button).attrs({
  variant: ButtonVariants.link,
})`
  z-index: 999;
  position: absolute;
  top: 7px;
  right: 15px;
  &.MuiButton-link {
    text-decoration: unset;
    height: unset;
    font-weight: unset;
  }
`;

const maxMultipleSeriesCount = 2;

const SeriesFields = (props) => {
  const { fields, dashboard } = props;

  const { values: formValues } = useFormState({
    subscription: { values: true },
  });
  const series0Values = formValues?.series?.[0];

  const widgetMeta = useMemo(
    () => widgets[series0Values?.display?.type] || {},
    [series0Values?.display?.type],
  );

  const multipleSeriesChart = !!widgetMeta.canBeCombinedWith?.length;

  const onAdd = useCallback(() => {
    const next = widgetParamsToUi({ series: defaultWidgetProps.series });
    fields.push(next.series[0]);
  }, [fields]);

  const onRemove = useCallback((index) => () => fields.remove(index), [fields]);

  // remove series if maxMultipleSeriesCount is reached
  useEffect(() => {
    const maxSeriesCount = multipleSeriesChart ? maxMultipleSeriesCount : 1;
    if (fields.length > maxSeriesCount) {
      for (let i = fields.length - 1; i >= maxSeriesCount; i -= 1) {
        fields.remove(i);
      }
    }
  }, [fields, fields.length, multipleSeriesChart]);

  const Fields = widgetMeta.SeriesFields;

  return (
    <FieldsCol>
      {fields.map((name, index) => (
        <FieldsRow key={name}>
          <Label style={{ maxHeight: '24px' }}>
            {fields.length > 1 ? `yAxis ${index + 1}` : ' '}
          </Label>

          <FieldsGroup className="form--label-on-top">
            {fields.length > 1 && (
              <RemoveButton onClick={onRemove(index)}>Remove</RemoveButton>
            )}

            <ChartTypeFields name={name} index={index} />

            {Fields && (
              <Suspense>
                <Fields
                  name={name}
                  index={index}
                  widgetMeta={widgetMeta}
                  dashboard={dashboard}
                />
              </Suspense>
            )}
          </FieldsGroup>
        </FieldsRow>
      ))}

      {multipleSeriesChart && (
        <Button
          style={{ marginLeft: '140px' }}
          variant={ButtonVariants.outlined}
          onClick={onAdd}
          disabled={fields.length >= maxMultipleSeriesCount}
        >
          + Add Series
        </Button>
      )}
    </FieldsCol>
  );
};

SeriesFields.propTypes = {
  fields: PropTypes.shape().isRequired,
  dashboard: PropTypes.shape(dashboardPropTypes).isRequired,
};

export default SeriesFields;
