import { useDispatch } from 'react-redux';
import { useInterval } from 'react-use';

import { actions as customerActions } from '@/redux/api/customer';

import { useHasFlow } from '+hooks/useHasFlow';
import { useHasSources } from '+hooks/useHasSources';

export const usePollCurrentCustomer = () => {
  const dispatch = useDispatch();
  const time = 5000; // 5 second refresh

  const hasSources = useHasSources();
  const hasFlow = useHasFlow();

  useInterval(
    () => {
      dispatch(customerActions.fetchCurrentCustomerSilent(null, 'polling'));
    },
    hasSources && !hasFlow ? time : null,
  );
};
