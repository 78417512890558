import cloneDeep from 'lodash.clonedeep';

const allSubRowsHaveOneElement = (row, getSubRows) => {
  const subRows = getSubRows(row);

  if (!subRows?.length) {
    return true;
  }

  if (subRows?.length > 1) {
    return false;
  }

  return allSubRowsHaveOneElement(subRows[0], getSubRows);
};

const getDeepestSubRow = (row, getSubRows) => {
  const subRows = getSubRows(row);

  if (!subRows?.length) {
    return row;
  }

  return getDeepestSubRow(subRows[0], getSubRows);
};

const consolidateSubRows = (row, getSubRows) => {
  if (allSubRowsHaveOneElement(row, getSubRows)) {
    const deepestSubRow = getDeepestSubRow(row, getSubRows);
    if (row.id !== deepestSubRow.id) {
      row.virtualRowId = deepestSubRow.id;
    }
    row.original = cloneDeep(deepestSubRow.original);
    row.values = cloneDeep(deepestSubRow.values);
    row.subRows = [];
    row.leafRows = [];
    row.isGrouped = false;

    return row;
  }

  const subRows = getSubRows(row);

  if (subRows) {
    return subRows.map((subRow) => consolidateSubRows(subRow, getSubRows));
  }

  return row;
};

const useInstance = (instance) => {
  const {
    groupedRows,
    getSubRows,
    state: { groupBy },
  } = instance;

  if (!groupBy?.length) {
    return;
  }

  Object.assign(instance, {
    groupedRows: groupedRows.map((row) => consolidateSubRows(row, getSubRows)),
  });
};

export const useGroupByConsolidateOneItemsGroups = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useGroupByConsolidateOneItemsGroups.pluginName =
  'useGroupByConsolidateOneItemsGroups';
