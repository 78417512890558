import PropTypes from '+prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import ScrollBar from '+components/ScrollBar/smooth';
import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

import Context from '../Context';
import Accordion from './Accordion';
import Column from './Column';

const Container = styled(ScrollBar)`
  padding-top: 0;
  padding-bottom: 5px;
  max-height: 25vh;
`;

const ShadowContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 0 10px;
`;

const Separator = styled.div`
  opacity: 0.5;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08rem;
  font-size: 0.9em;
  margin: 5px 15px 0 23px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
`;

const ColumnsList = ({ allColumns, filter }) => {
  const { availableColumns, groupBy, disableGroupBy } = useContext(Context);
  const [scroll, setScroll] = useState();

  useEffect(() => {
    if (scroll) {
      scroll.scrollTop = 0;
    }
  }, [filter]);

  const hasTrafficType = useMemo(() => {
    return (
      allColumns?.some((column) => column.trafficType != null) ||
      availableColumns?.some((column) => column.trafficType != null)
    );
  }, [allColumns, availableColumns]);

  const groupByColumns = useMemo(
    () =>
      allColumns?.filter((column) => {
        if (column.isGrouped) {
          return true;
        }
        if (!disableGroupBy) {
          return column.id === TechnicalColumns.totalColumn;
        }
        return false;
      }),
    [allColumns, disableGroupBy],
  );

  const columns = useMemo(
    () =>
      allColumns?.filter(
        (column) => !column.isGrouped && !TechnicalColumns[column.id],
      ),
    [allColumns],
  );

  const available = useMemo(() => {
    const result = [];
    const groups = {};
    let hasGroups = false;

    const searchText = (filter || '').toLowerCase();
    let filtered = availableColumns || [];
    if (searchText) {
      filtered = filtered.filter(({ id }) =>
        id.toLowerCase().includes(searchText),
      );
    }

    let sysGroupBy = [];
    filtered.forEach(({ id }) => {
      if (id.indexOf('.') < 0) {
        return;
      }

      const [name] = id.split('.');
      if (sysGroupBy[name]) {
        return;
      }

      sysGroupBy.push({
        name,
        pattern: new RegExp(`^${name}(\\.|$)`, 'i'),
      });
      sysGroupBy[name] = true;
    });

    sysGroupBy = [...(groupBy || []), ...sysGroupBy];

    filtered.forEach((column, index) => {
      const { id } = column;

      const item = (
        <Column
          hasTrafficType={hasTrafficType}
          key={id}
          column={column}
          index={index}
          disableDragAndDrop
        />
      );

      if (!item) {
        return;
      }

      let key;
      let group;
      let groupName;
      let collection = result;

      group = sysGroupBy.find(({ pattern }) => pattern.test(id));
      if (group) {
        groupName = group.name || id;
        key = `${group.name}_${group.pattern}`;
        group = groups[key];

        if (!group) {
          hasGroups = true;
          group = [];
          group.name = groupName;
          groups[key] = group;
          result.push(group);
        }

        collection = group;
      }

      collection.push(item);
    });

    return !hasGroups
      ? result
      : result.map((item) =>
          !Array.isArray(item) ? (
            item
          ) : (
            <Accordion
              key={`group_${item.name}_${!!searchText}`}
              title={item.name}
              defaultExpanded={!!searchText}
            >
              {item}
            </Accordion>
          ),
        );
  }, [availableColumns, groupBy, filter, hasTrafficType]);

  const notFound = useMemo(() => {
    let result = !filter;
    if (result) {
      return false;
    }

    const item = filter.toLowerCase();
    result = [
      ...(groupByColumns || []),
      ...(columns || []),
      ...(availableColumns || []),
    ];
    return !result.some(({ id }) => id.toLowerCase().includes(item));
  }, [groupByColumns, columns, availableColumns, filter]);

  return (
    <ShadowContainer>
      <Container ref={setScroll}>
        {!!groupByColumns?.length && <Separator>Group By</Separator>}
        {groupByColumns?.map((column, index) => (
          <Column
            hasTrafficType={hasTrafficType}
            key={column.id}
            column={column}
            index={index}
            isVisible={column.isVisible}
            disableGroupBy={disableGroupBy}
          />
        ))}

        {!!groupByColumns?.length && <Separator>Columns</Separator>}
        {columns?.map((column, index) => (
          <Column
            hasTrafficType={hasTrafficType}
            key={column.id}
            column={column}
            index={index}
            isVisible={column.isVisible}
          />
        ))}

        {!!availableColumns?.length && <Separator>Additional</Separator>}
        {available}

        {notFound && (
          <Separator style={{ opacity: 0.6 }}>No fields found</Separator>
        )}
      </Container>
    </ShadowContainer>
  );
};

ColumnsList.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filter: PropTypes.string,
};

ColumnsList.defaultProps = {
  filter: null,
};

export default ColumnsList;
