import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ColorTypes from '@/models/ColorTypes';
import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import ConfirmModal from '+components/ConfirmModal';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';

const useCreateEventDashboard = (event) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [eventTemplateDashboardId] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'eventTemplateDashboardId',
  );

  const { isFetching } = useSelector(dashboardsSelectors.isFetching);
  const eventTemplateDashboardMeta = useSelector(
    dashboardsSelectors.getDashboard(eventTemplateDashboardId),
  );
  const createdDashboardId = useSelector(
    dashboardsSelectors.getCreatedDashboardId,
  );
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const [showDashboardConfirmModal, setShowDashboardConfirmModal] =
    useState(false);
  const [existingDashboardId, setExistingDashboardId] = useState('');

  const createDashboard = useCallback(() => {
    const { nql, from, to } = getFlowDataFromRecord({
      record: event,
      type: ContextTypes.alerts,
    });

    const { id, system, hidden, ...rest } = eventTemplateDashboardMeta;

    const newDashboard = {
      ...rest,
      title: `Event Dashboard - ${event.id}`,
      dateTime: {
        periodType: 'custom',
        periodValue: null,
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      },
      useGlobalDateTime: false,
      groups: ['event'],
      intersect: [],
      nqlMode: 'override',
      nql,
      description: event.summary,
      eventId: event.id,
    };

    dispatch(dashboardsActions.createDashboard(newDashboard));
  }, [event, eventTemplateDashboardMeta, dispatch]);

  const onCreateDashboard = useCallback(() => {
    const existingDashboard = Object.values(dashboardsMeta).find(
      (item) => item.eventId === event.id,
    );

    if (existingDashboard) {
      setExistingDashboardId(existingDashboard.id);
      setShowDashboardConfirmModal(true);
    } else {
      createDashboard();
    }
  }, [createDashboard, dashboardsMeta]);

  const onDashboardCreateConfirm = useCallback(() => {
    createDashboard();
    setShowDashboardConfirmModal(false);
  }, [createDashboard]);

  const onDashboardCreateToggle = useCallback(() => {
    setShowDashboardConfirmModal(false);
  }, [existingDashboardId]);

  const onDashboardCreateSecondaryAction = useCallback(() => {
    navigate(`${RoutePaths.dashboards}/${existingDashboardId}`);
    setShowDashboardConfirmModal(false);
  }, [existingDashboardId]);

  useEffect(() => {
    if (!isAllMetaFetched) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [isAllMetaFetched]);

  useEffect(() => {
    if (createdDashboardId) {
      const tempId = createdDashboardId;
      dispatch(dashboardsActions.clearCreatedDashboardId());
      navigate(`${RoutePaths.dashboards}/${tempId}`);
    }
  }, [createdDashboardId]);

  useEffect(() => {
    if (!eventTemplateDashboardId) {
      return;
    }

    dispatch(
      dashboardsActions.fetchDashboard({
        id: eventTemplateDashboardId,
      }),
    );
  }, [eventTemplateDashboardId]);

  return [
    onCreateDashboard,
    !!showDashboardConfirmModal && (
      <ConfirmModal
        item=""
        titleTemplate="Dashboard Exists"
        onToggle={onDashboardCreateToggle}
        onConfirm={onDashboardCreateConfirm}
        onSecondary={onDashboardCreateSecondaryAction}
        isOpen
        confirmButtonColor={ColorTypes.primary}
        confirmButtonText="Create A New Dashboard"
        secondaryButtonText="View Current Dashboard"
        toggleOnConfirm={false}
        whyAsking="A dashboard for this event already exists, you can create a new dashboard for this event or view the existing one."
      />
    ),
    !isFetching && !!eventTemplateDashboardMeta,
  ];
};

export default useCreateEventDashboard;
