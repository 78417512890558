import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMeasure } from 'react-use';

import styled from 'styled-components';

import Box from '@mui/material/Box';

import { selectors as rulesSelectors } from '@/redux/api/rules';
import {
  actions as thresholdActions,
  selectors as thresholdSelectors,
} from '@/redux/api/thresholder';

import TimeseriesChart from '+components/charts/TimeseriesChart';
import Select from '+components/form/Select';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { ActionsContainer } from '+components/Layout';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { convertThresholdsValuesToSeries } from '+utils/convertThresholdsValuesToSeries';
import dayjs from '+utils/dayjs';
import { timeBounds } from '+utils/timeBounds';

const DEFAULT_GROUP = 'default';

const ThresholdValuesChart = styled(TimeseriesChart)`
  margin-bottom: 15px;
`;

const chartOverrides = {
  xAxis: { labels: {} },
  yAxis: { labels: {} },
  plotOptions: {
    line: {
      step: 'left',
    },
  },
};

const ToolbarItem = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  min-width: 20em;
`;

const ToolbarItemTitle = styled.div`
  white-space: nowrap;
`;

const AutoThresholds = () => {
  const dispatch = useDispatch();
  const { algorithmId } = useParams();
  const algorithm = useSelector(rulesSelectors.getAlgorithm(algorithmId));

  const [selectedTrack, setSelectedTrack] = useState({
    value: DEFAULT_GROUP,
    label: DEFAULT_GROUP,
  });

  const thresholdData = useSelector(
    thresholdSelectors.getThresholdValues(
      algorithm.name,
      selectedTrack.value ?? DEFAULT_GROUP,
    ),
  );
  const automatonTracks = useSelector(
    thresholdSelectors.getTracks(algorithm.name),
  );
  const isFetching = useSelector(thresholdSelectors.isFetching);

  useLoadingIndicator(isFetching);

  const [filters] = useGlobalFilters();
  const { start: filtersStart, end: filtersEnd } = timeBounds(filters);

  const [ref, { width }] = useMeasure();

  const allowedSeverities = useMemo(
    () =>
      new Set(
        (algorithm?.thresholds || []).map((threshold) => threshold.severity),
      ),
    [algorithm?.thresholds],
  );

  const thresholdValues = useMemo(
    () =>
      (thresholdData || []).map((item) => ({
        ...item,
        threshold: item.threshold.filter((thresh) =>
          allowedSeverities.has(thresh.level),
        ),
      })),
    [allowedSeverities, thresholdData],
  );

  const operations = useMemo(() => {
    return Array.from(
      (thresholdValues || []).reduce((acc, curr) => {
        if (curr.operation) {
          acc.add(curr.operation.toLowerCase());
        }

        return acc;
      }, new Set()),
    );
  }, [thresholdValues]);
  const [series, setSeries] = useState([{ values: [] }]);

  // governs updating of series
  useEffect(() => {
    if (!isFetching && thresholdValues && thresholdValues.length > 0) {
      setSeries(
        operations?.map((op) => ({
          operation: op,
          values: convertThresholdsValuesToSeries(
            thresholdValues.filter(
              (item) => item?.operation?.toLowerCase() === op,
            ),
          ),
        })),
      );
    }
  }, [thresholdValues, operations, isFetching]);

  const trackOptions = useMemo(() => {
    const allOptions = [
      DEFAULT_GROUP,
      ...(automatonTracks ?? []).filter((option) => option !== DEFAULT_GROUP),
    ];

    return allOptions.map((value) => ({
      value,
      label: value,
    }));
  }, [automatonTracks]);

  useEffect(() => {
    const algorithmName = algorithm?.name;
    const namespace = `${algorithmName}_tracks`;

    if (algorithmName) {
      dispatch(
        thresholdActions.fetchValuesByTrack({
          modelName: algorithmName,
          track: selectedTrack.value ?? DEFAULT_GROUP,
          to: parseInt(dayjs().format('X'), 10) + filtersEnd,
          from: parseInt(dayjs().format('X'), 10) + filtersStart,
        }),
        namespace,
      );
    }

    return () => {
      if (algorithmName) {
        dispatch(thresholdActions.cancel(namespace));
      }
    };
  }, [algorithm?.name, filtersEnd, filtersStart, selectedTrack.value]);

  useEffect(() => {
    const algorithmName = algorithm?.name;
    if (algorithmName) {
      dispatch(
        thresholdActions.fetchTracks({
          modelName: algorithmName,
          to: parseInt(dayjs().format('X'), 10) + filtersEnd,
          from: parseInt(dayjs().format('X'), 10) + filtersStart,
        }),
        algorithmName,
      );
    }

    return () => {
      if (algorithmName) {
        dispatch(thresholdActions.cancel(algorithmName));
      }
    };
  }, [algorithm?.name, filtersEnd, filtersStart]);

  return (
    <Box display="flex" flexDirection="column" ref={ref}>
      <GlobalFiltersSetting to from range />

      <ActionsContainer>
        <ToolbarItem>
          <ToolbarItemTitle>Track:</ToolbarItemTitle>
          <Select
            name="NodesResolve"
            onChange={(value) => setSelectedTrack(value)}
            value={selectedTrack}
            options={trackOptions}
            data-tracking="at-track-track-selector"
          />
        </ToolbarItem>
      </ActionsContainer>
      {series?.map((serie, index) => (
        <ThresholdValuesChart
          // Okay to use array index in key as these components are never re-ordered
          // eslint-disable-next-line react/no-array-index-key
          key={`tvchart${index}`}
          title="Auto Threshold Values"
          subtitle={`${selectedTrack.value ?? DEFAULT_GROUP} - ${serie.operation}`}
          series={serie.values}
          type="line"
          overrides={chartOverrides}
          loading={isFetching}
          context="flow"
          width={width}
        />
      ))}
    </Box>
  );
};

export default AutoThresholds;
