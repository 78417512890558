const si = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
export const formatNumber = (num, digits = 0, suffix = '', precise = false) => {
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return precise
    ? `${(num / si[i].value).toFixed(digits)}${
        si[i].symbol || suffix ? ' ' : ''
      }${si[i].symbol}${suffix}`
    : `${(num / si[i].value).toFixed(digits).replace(rx, '$1')}${
        si[i].symbol || suffix ? ' ' : ''
      }${si[i].symbol}${suffix}`;
};

export const formatNumberWithSizes = (num, digits, sizes) => {
  if (typeof num === 'undefined' || Number.isNaN(num) || num === 0) {
    return `0 ${sizes[0]}`;
  }

  const k = 1000;
  const dm = Math.max(0, digits ?? 0);

  if (num < 1) {
    const val = parseFloat(num).toFixed(dm);
    return `${+val ? val : 0} ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(num) / Math.log(k));
  return `${parseFloat((num / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatRate = (rate, decimals = 1, suffix = 'ps') =>
  formatNumber(rate, decimals, suffix);

const sizesBits = ['Bits', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
export const formatBits = (bitrate, decimals = 2) =>
  formatNumberWithSizes(bitrate, decimals, sizesBits);

const sizesBitRate = [
  'bps',
  'Kbps',
  'Mbps',
  'Gbps',
  'Tbps',
  'Pbps',
  'Ebps',
  'Zbps',
  'Ybps',
];
export const formatBitRate = (bitrate, decimals = 2) =>
  formatNumberWithSizes(bitrate, decimals, sizesBitRate);

const sizesPackets = ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
export const formatPackets = (packets, decimals = 2) =>
  formatNumberWithSizes(packets, decimals, sizesPackets);

export const PortCategory = {
  privileged: 0,
  registered: 1,
  ephemeral: 2,
};

const PortCategoryName = {
  [PortCategory.privileged]: 'privileged ports',
  [PortCategory.registered]: 'registered ports',
  [PortCategory.ephemeral]: 'ephemeral ports',
};

export function getPortCategory(port) {
  if (port < 1024) {
    return PortCategory.privileged;
  }

  if (port >= 1024 && port < 49152) {
    return PortCategory.registered;
  }

  return PortCategory.ephemeral;
}

export const getPortCategoryName = (port) =>
  PortCategoryName[getPortCategory(port)];

export const formatLabels = (
  labels,
  { short = true, brackets = true } = {},
) => {
  if (!labels) {
    return '';
  }
  const fixedLabels = labels.filter(Boolean);
  if (!fixedLabels.length) {
    return '';
  }
  const labelsStr = short
    ? `${fixedLabels[0]}${
        fixedLabels.length > 1 ? ` [+${fixedLabels.length - 1}]` : ''
      }`
    : fixedLabels.join(', ');
  return brackets ? ` (${labelsStr})` : labelsStr;
};
