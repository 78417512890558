import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';

import { Columns, getColumns } from './components/Columns';

const sortBy = [{ id: 'start', desc: true }];
const defaultColumns = Object.values(Columns);

const SchedulesTable = ({ id }) => {
  const dispatch = useDispatch();

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);

  const isFetching = useSelector(dashboardsSelectors.isFetching);
  const schedules = useSelector(dashboardsSelectors.getSchedules);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);
  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const [scheduleToDelete, setScheduleToDelete] = useState(null);

  useLoadingIndicator(isFetching);

  const onScheduleDeleteCancel = useCallback(() => {
    setScheduleToDelete(null);
  }, []);

  const onScheduleDeleteSubmit = useCallback(() => {
    dispatch(dashboardsActions.removeSchedule(scheduleToDelete.data.dashboard));
    setScheduleToDelete(null);
  }, [scheduleToDelete]);

  const cxActionMenu = useCallback(
    (_, original) => {
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          url: `${RoutePaths.dashboards}/${original?.data?.dashboard}/schedule`,
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.update,
          onClick: () => setScheduleToDelete(original),
        },
      ];
      return (
        <MenuColumnContextMenu
          title={original.name}
          items={items}
          dataTracking="dashboard-schedules"
        />
      );
    },
    [permissions],
  );

  const columns = useMemo(
    () => getColumns(defaultColumns, { dashboardsMeta, cxActionMenu }),
    [dashboardsMeta, cxActionMenu],
  );

  const tableData = useMemo(() => Object.values(schedules || {}), [schedules]);

  useEffect(() => {
    dispatch(dashboardsActions.fetchSchedules());
  }, []);

  useEffect(() => {
    if (!isAllMetaFetched) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [isAllMetaFetched]);

  return (
    <Fragment>
      <Table
        id={id}
        columns={columns}
        data={tableData}
        noDataText={isFetching ? lang.loading : 'No scheduled dashboards'}
        sortBy={sortBy}
      />

      {!!scheduleToDelete && (
        <ConfirmModal
          item={scheduleToDelete.data.name}
          onToggle={onScheduleDeleteCancel}
          onConfirm={onScheduleDeleteSubmit}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

SchedulesTable.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SchedulesTable;
