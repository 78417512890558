import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/ui/globalLoading';

import { makeId } from '+utils';

/**
 * useLoadingIndicator
 * @param {boolean} [loading=false]
 * @param {string|number} [id=makeId()] some unique id
 * @return {[(function(boolean): void), boolean]} - [setLoading, isLoading]
 */
export default (loading = true, id = '') => {
  const dispatch = useDispatch();
  const normalizedId = useMemo(() => id || makeId(), []);

  const isLoading = useSelector(selectors.getIsLoading(normalizedId));
  const refLoading = useRef();
  refLoading.current = isLoading;

  useEffect(
    () => () => {
      dispatch(actions.remove({ id: normalizedId }));
    },
    [normalizedId],
  );

  useEffect(() => {
    if (!!loading !== refLoading.current) {
      dispatch(
        actions.change({
          id: normalizedId,
          active: !!loading,
        }),
      );
    }
  }, [normalizedId, loading]);

  return isLoading;
};
