import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import get from 'lodash.get';
import set from 'lodash.set';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import * as PropertiesTray from '@/models/PropertiesTray';

import { selectors as customerSelectors } from '@/redux/api/customer';

import Table from '+components/Table';
import { MenuColumnPropertiesTrayTrigger } from '+components/Table/Columns';
import { useAvailableExtractor } from '+hooks/useAvailableExtractor';
import useGlobalFilters from '+hooks/useGlobalFilters';
import { usePreparedColumns } from '+hooks/usePreparedColumns';
import { getColumnsHelper } from '+utils/getColumnsHelper';

import SubDnsDetails from '../DnsTable/components/SubDetails';
import SubFlowDetails, {
  getRowHeight,
} from '../FlowTable/components/SubDetails';
import { Columns, columnsCollection } from './components/Columns';
import { defaultColumns } from './components/defaultColumns';
import { trafficRecordFields } from './components/trafficRecordFields';

const formattedData = (unformattedTableData) => {
  return unformattedTableData.map((row) => {
    const trafficRecord = {};

    trafficRecordFields[row?.traffictype]?.forEach((field) => {
      set(trafficRecord, field, get(row, field));
    });

    // get rid of the nested query
    if (trafficRecord.query) {
      trafficRecord.domain = trafficRecord.query.domain;
      trafficRecord.type = trafficRecord.query.type;
      delete trafficRecord.query;
    }

    return {
      ...row,
      trafficRecord,
    };
  });
};

const SubComponent = (props) => {
  const { original } = props;

  const Component =
    original?.[Columns.trafficType] === ContextTypes.dns
      ? SubDnsDetails
      : SubFlowDetails;

  return <Component {...props} />;
};

SubComponent.propTypes = {
  original: PropTypes.shape().isRequired,
};

const TableOfTraffic = (props) => {
  const {
    columns: columnsProp,
    tableData,
    filterable,
    hideSubComponent,
    ...tail
  } = props;

  const [filters] = useGlobalFilters();

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const columns = useMemo(() => {
    if (!customer?.multi_account || columnsProp.includes(Columns.customer)) {
      return columnsProp;
    }

    return [...columnsProp, Columns.customer];
  }, [columnsProp, customer]);

  const cxActionMenu = useCallback(
    (_, original) => (
      <MenuColumnPropertiesTrayTrigger
        title={`${
          ContextTypesLabels[original.traffictype] || original.traffictype
        } Details — ${original.id}`}
        dataType={PropertiesTray.DataTypes.record}
        recordType={original.traffictype}
        value={original}
      />
    ),
    [],
  );

  const overrideColumns = useMemo(
    () =>
      columnsCollection({
        labelContext: filters.labelContext,
        cxActionMenu,
      }),
    [cxActionMenu, filters.labelContext],
  );

  const collection = usePreparedColumns(ContextTypes.traffic, {
    overrideColumns,
  });

  const preparedColumns = useMemo(() => Object.keys(collection), [collection]);

  const getColumns = useMemo(() => getColumnsHelper(collection), [collection]);

  const [tableColumns, available] = useAvailableExtractor({
    row: tableData?.[0],
    selectedColumns: columns,
    getColumns,
    preparedColumns,
  });

  const data = useMemo(
    () =>
      columns.includes(Columns.trafficRecord)
        ? formattedData(tableData)
        : tableData,
    [columns, tableData],
  );

  return (
    <Table
      data={data}
      disableFilters={!filterable}
      SubComponent={SubComponent}
      rowHeight={getRowHeight}
      {...tail}
      columns={tableColumns}
      availableColumns={available}
    />
  );
};

TableOfTraffic.propTypes = {
  ...Table.propTypes,
  tableData: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.string),
  noDataText: PropTypes.string,
  filterable: PropTypes.bool,
  hideSubComponent: PropTypes.bool,
};

TableOfTraffic.defaultProps = {
  columns: defaultColumns,
  noDataText: 'No Traffic received',
  tableData: [],
  filterable: true,
  hideSubComponent: false,
};

export const TrafficTable = memo(TableOfTraffic);
