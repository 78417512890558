import PropTypes from '+prop-types';
import { Suspense, useEffect, useRef, useState } from 'react';
import {
  AuthProvider as AuthProviderOriginal,
  useAuth,
} from 'react-oidc-context';

import { Log, WebStorageStateStore } from 'oidc-client-ts';

import { config } from '@/config';
import authClient from '@/middleware/authClient';

import loader from '+utils/loader';

const propTypes = {
  children: PropTypes.children.isRequired,
};

const listForRemoving = new Set([
  'code',
  'iss',
  'state',
  'session_state',
  'error',
]);

const getUrlWithoutAuthState = () => {
  const { search, hash } = window.location;

  let url = new URLSearchParams(search);

  listForRemoving.forEach((part) => {
    url.delete(part);
  });

  url = url.size ? `?${url.toString()}` : '';

  let fixedHash = new URLSearchParams(hash.replace('#', '?'));
  listForRemoving.forEach((part) => {
    fixedHash.delete(part);
  });

  fixedHash = fixedHash.size ? `#${fixedHash.toString()}` : '';

  return `${window.location.pathname}${url}${fixedHash}`;
};

const SilentSignin = ({ children }) => {
  const auth = useAuth();
  const [isSilentSignin, setIsSilentSignin] = useState(auth.isAuthenticated);

  useEffect(() => {
    (async () => {
      try {
        if (auth.user) {
          loader.setMessage('Authentication... 60%').show();
          await authClient.silentLogin();
        }
        setIsSilentSignin(false);
      } catch (error) {
        authClient.logout();
      }
    })();
  }, []);

  if (isSilentSignin) {
    return null;
  }

  return children;
};
SilentSignin.propTypes = propTypes;

const ErrorProcessing = (props) => {
  const { auth } = props;

  throw (async () => {
    try {
      try {
        await auth.revokeTokens();
      } catch (_) {
        // ...
      }

      try {
        await auth.clearStaleState();
      } catch (_) {
        // ...
      }

      try {
        await auth.removeUser();
      } catch (_) {
        // ...
      }
    } catch (error) {
      if (config.environment === 'development') {
        // eslint-disable-next-line no-console
        console.error('catch error:', error.message);
      }
    }

    window.location.replace(getUrlWithoutAuthState());
  })();
};

const isLoginRequired = () => {
  const search = new URLSearchParams(document.location.search);

  return search.get('error') === 'login_required';
};

let isCriticalError = false;

const Wrapper = ({ children }) => {
  const auth = useAuth();
  authClient.setClient(auth);
  const isErrorRef = useRef(false);

  if (isErrorRef.current) {
    return null;
  }

  const currentError = isLoginRequired()
    ? new Error('login_required')
    : auth.error;

  if (currentError) {
    // eslint-disable-next-line no-console
    console.error('auth error:', currentError.message);

    isErrorRef.current = true;

    if (!auth.user && currentError.message === 'Failed to fetch') {
      loader
        .setMessage(
          `Authentication error<br/><a href="${window.location.origin}${window.location.pathname}">Reload</a>`,
        )
        .show();
      return null;
    }

    loader.setMessage('Authentication refreshing...').show();

    return (
      <Suspense>
        <ErrorProcessing auth={auth} />
      </Suspense>
    );
  }

  if (auth.isLoading && auth.activeNavigator !== 'signinSilent') {
    return null;
  }

  return <SilentSignin>{children}</SilentSignin>;
};
Wrapper.propTypes = propTypes;

if (config.environment === 'development') {
  Log.setLevel(Log.DEBUG);
  Log.setLogger(console);
}

const oidcConfig = {
  authority: `${config.keycloak.serverUrl}/realms/${config.keycloak.realm}`,
  client_id: config.keycloak.clientId,
  redirect_uri: window.location.href,
  automaticSilentRenew: true,
  monitorSession: !config.isLocalRun,
  revokeTokensOnSignout: false,
  silentRequestTimeoutInSeconds: 4,
  onSigninCallback: () => {
    const url = getUrlWithoutAuthState();

    window.history.replaceState({}, document.title, url);
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const AuthProvider = ({ children }) => {
  if (isInIframe()) {
    // oidc lib try to open our app in iframe with ?error=login_required
    // we should do nothing in this case
    isCriticalError = isCriticalError || isLoginRequired();

    if (isCriticalError) {
      return null;
    }
  }

  return (
    <AuthProviderOriginal {...oidcConfig}>
      <Wrapper>{children}</Wrapper>
    </AuthProviderOriginal>
  );
};
AuthProvider.propTypes = propTypes;

export default AuthProvider;
