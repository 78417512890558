import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import muiDark from 'react-syntax-highlighter/dist/esm/styles/prism/material-dark';
import muiLight from 'react-syntax-highlighter/dist/esm/styles/prism/material-light';

import styled from 'styled-components';

import FontMixin from './FontMixin';
import { WordBreakMixin } from './WordBreakMixin';

const nqlDeclaration = (Prism) => {
  Prism.languages.nql = {
    number: /-?(?:0x[\da-f]+|\d+(?:\.\d+)?)(?:(?=[\s)\]])|$)/i,
    string: [
      {
        pattern: /"(?:[^"\\\r\n]|\\.)*"|'(?:[^'\\\r\n]|\\.)*'/,
        greedy: true,
      },
      {
        pattern: /(=\s)[^&|\s=><!()^]+(?:(?=[\s)])|$)/,
        greedy: true,
        lookbehind: true,
      },
    ],
    regex: [
      {
        // support /ip-([0-9]{1,3}-?){4}[1-2][0-9]{2}.*/
        pattern: /((?:^|[^\w\s)])\s*)\/(?:[^/\\\r\n]|\\.)*\//,
        lookbehind: true,
        greedy: true,
      },
      {
        // support *at, cat~, cat~2, ?at
        pattern: /(~\s*)[^&|\s=><!()^]+(?:(?=[\s)])|$)/,
        greedy: true,
        lookbehind: true,
      },
    ],
    operator: /\|\||&[^&]?|[=!]~|[=!><]=|[><]|\b(?:AND|OR)\b/i,
    function: /\b\w+(?=\()/,
    boolean: /\b(?:false|true)\b/i,
    punctuation: /[[\](),]/,
  };
};

nqlDeclaration.displayName = 'nql';

SyntaxHighlighter.registerLanguage('nql', nqlDeclaration);

const CodeBlock = styled(SyntaxHighlighter).attrs((props) => ({
  style: props.theme.name === 'dark' ? muiDark : muiLight,
  language: 'nql',
  PreTag: 'div',
  CodeTag: 'div',
  wrapLongLines: true,
  ...props,
}))`
  ${FontMixin};

  ${WordBreakMixin};

  min-height: 30px !important;
  width: 100%;
  border: unset !important;
  border-radius: unset !important;
  padding: 6px 7px 0 7px !important;
  margin: 0 !important;

  & > div {
    ${FontMixin};

    ${WordBreakMixin};
  }

  pointer-events: none;

  background: ${({ theme }) => theme.colorFieldBackground} !important;

  div {
    ${FontMixin};
    background: ${({ theme }) => theme.colorFieldBackground} !important;
  }

  overflow: hidden !important;
`;

export default CodeBlock;
