import { useMemo } from 'react';

import { isIpField } from '@/models/FieldTypes';

import { UniversalCell } from '+components/Table/Cells';
import {
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
  NumberColumnFactory,
} from '+components/Table/Columns';

export const getColumns = ({ field, labelContext }) => [
  {
    accessor: 'agg',
    Header: 'Aggregation',
    Cell: (cell) => UniversalCell(field)(cell),
    sortType: isIpField(field) ? 'ip' : 'alphanumeric',
  },
  NumberColumnFactory({
    accessor: 'agg_count',
    Header: 'Count (of 1000)',
    textAlign: 'center',
  }),
  LabelOrIpColumnFactory({
    Header: 'Source',
    dataFieldName: 'srcip',
    labelFieldName: `label.ip.${labelContext.ip}.src`,
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    id: 'srcip_%',
    accessor: (original) => Math.round(original['srcip_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'SRC IP %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  LabelOrPortColumnFactory({
    Header: 'SRC Port',
    dataFieldName: 'srcport',
    labelFieldName: `label.port.${labelContext.port}.src`,
    showLabel: labelContext.show,
  }),
  NumberColumnFactory({
    id: 'srcport_%',
    accessor: (original) => Math.round(original['srcport_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'SRC Port %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'protocol',
    Header: 'Protocol',
    Cell: UniversalCell('protocol'),
  },
  NumberColumnFactory({
    id: 'protocol_%',
    accessor: (original) => Math.round(original['protocol_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Protocol %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'type',
    Header: 'Query Type',
    Cell: UniversalCell('type'),
  },
  NumberColumnFactory({
    id: 'type_%',
    accessor: (original) => Math.round(original['type_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Type %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'domain',
    Header: 'Query Domain',
    Cell: UniversalCell('domain'),
  },
  NumberColumnFactory({
    id: 'domain_%',
    accessor: (original) => Math.round(original['domain_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Query Domain %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'tld',
    Header: 'Query TLD',
    Cell: UniversalCell('tld'),
  },
  NumberColumnFactory({
    id: 'tld_%',
    accessor: (original) => Math.round(original['tld_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Query TLD %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'publicsuffix',
    Header: 'Query Public Suffix',
    Cell: UniversalCell('publicsuffix'),
  },
  NumberColumnFactory({
    id: 'publicsuffix_%',
    accessor: (original) =>
      Math.round(original['publicsuffix_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Query Public Suffix %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
  {
    accessor: 'rcode',
    Header: 'Query RCode',
    Cell: UniversalCell('rcode'),
  },
  NumberColumnFactory({
    id: 'rcode_%',
    accessor: (original) => Math.round(original['rcode_%'].toFixed(2) * 100),
    Header: '%',
    ContextMenuHeader: 'Query RCode %',
    width: 50,
    textAlign: 'center',
    Cell: ({ value }) => useMemo(() => `${value}%`, [value]),
  }),
];
