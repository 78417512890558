// import Chargebee from '@chargebee/chargebee-js-react-wrapper';

import { config } from '@/config';

export const chargebee = {
  init: () => {
    if (!config.chargebee.enabled || window.cbInstance) {
      return;
    }

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://js.chargebee.com/v2/chargebee.js';
    scriptTag.addEventListener('load', () => {
      window.cbInstance = window.Chargebee.init({
        site: config.chargebee.site,
      });
    });
    document.body.appendChild(scriptTag);
  },
};
