import PropTypes from '+prop-types';
import { useContext, useEffect, useMemo } from 'react';

import { ContextTypes } from '@/models/ContextTypes';

import { lang } from '+components/charts/common/utils';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';

import Context from '../Context';
import { Columns, getColumns } from './components/Columns';
import Container from './components/Container';

const sessionsTableId = 'Profile_Sessions_Table';
const sortBy = [{ id: 'start', desc: true }];
const defaultColumns = [Columns._ip, Columns.start, Columns.lastAccess];

const SessionsSection = (props) => {
  const { onSessions } = props;

  const [filters] = useGlobalFilters();
  const { ipLabelsHash } = useIpLabels();
  const { user, sessions } = useContext(Context);

  const columns = useMemo(
    () => getColumns(defaultColumns, { labelContext: filters.labelContext }),
    [filters.labelContext],
  );

  const tableData = useMemo(
    () =>
      (sessions || []).map(({ ipAddress, ...item }) => {
        let ipname;
        if (filters.labelContext.show) {
          ipname = ipLabelsHash[ipAddress]?.[filters.labelContext.ip];
        }
        return { ...item, ip: ipAddress, ipname };
      }),
    [sessions, ipLabelsHash, filters.labelContext],
  );

  useEffect(() => {
    if (user?.id && onSessions) {
      onSessions();
    }
  }, [user?.id, onSessions]);

  return (
    <Container>
      <GlobalFiltersSetting
        context={ContextTypes.flow}
        range={false}
        from={false}
        to={false}
        nql={false}
        socketControl={false}
      />

      <Table
        id={sessionsTableId}
        columns={columns}
        data={tableData}
        noDataText={sessions ? 'No active sessions' : lang.loading}
        sortBy={sortBy}
      />
    </Container>
  );
};

SessionsSection.propTypes = {
  onSessions: PropTypes.func,
};

SessionsSection.defaultProps = {
  onSessions: null,
};

export default SessionsSection;
