import PropTypes from '+prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';

import { makeId } from '+utils/general';

export const Context = createContext();

const Provider = ({ children }) => {
  const [id] = useState(makeId());
  const [anchor, setAnchor] = useState(null);
  const show = useCallback((event) => setAnchor(event.currentTarget), []);
  const hide = useCallback(() => setAnchor(null), []);
  const value = useMemo(
    () => ({
      id,
      show,
      hide,
      anchor,
      isOpen: !!anchor,
    }),
    [id, show, hide, anchor],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.children.isRequired,
};

export default Provider;
