import { useTheme } from 'styled-components';

/**
 * @enum {string}
 */
export const Theme = {
  light: 'light',
  dark: 'dark',
};

/**
 * Create a sliced theme with a namespace to be able to build theme modularly.
 * Returns selector and selectors which are functions to select parts of the theme
 * inside styled-component css interpoplations.
 *
 * @param namespace path key on main theme prop to place this theme under
 * @param theme theme object containing style values
 */
export const createThemeSlice = (namespace, theme) => {
  const propsSelectors = new Proxy(
    {},
    {
      get(_target, prop) {
        return (props) => props.theme[namespace][prop];
      },
    },
  );

  const selectors = new Proxy(
    {},
    {
      get(_target, prop) {
        return (props) => props.theme[namespace][prop];
      },
    },
  );

  const themeFn = (fn) => (props) => fn(props.theme, props);
  themeFn.process = (choose) => ({
    [namespace]: theme(choose),
  });
  themeFn.propsSelector = (props) => props.theme[namespace];
  themeFn.propsSelectors = propsSelectors;
  themeFn.selector = (_theme) => _theme[namespace];
  themeFn.selectors = selectors;

  return themeFn;
};

/**
 * React hook to use a slice of the theme context
 * @param slice ThemeSlice returned from createThemeSlice
 */
export const useThemeSlice = (slice) => {
  const theme = useTheme();
  return slice.selector(theme);
};
