import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actionsMap } from './sagasMap';

export const useFetchContextRecord = (context, params, nsPrefix, deps) => {
  const dispatch = useDispatch();

  const actions = actionsMap[context];

  useEffect(() => {
    if (!actions?.fetch || !params?.id) {
      return undefined;
    }

    const namespace = `${nsPrefix}_fetch_${context}_${params.id}`;

    dispatch(actions.fetch(params, namespace));

    return () => {
      dispatch(actions.cancel(namespace));
    };
  }, [actions, context, ...deps]);
};
