import { BrowserRouter, Route, Routes } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import MainWrapper from '@/pages/App/MainWrapper';
import Logout from '@/pages/Auth/Logout';

import { PreloadState } from './PreloadState';
import withProtectedArea from './withProtectedArea';
import WrappedRoutes from './WrappedRoutes';

const Protected = withProtectedArea(() => (
  <PreloadState>
    <WrappedRoutes />
  </PreloadState>
));

const FullMode = () => (
  <BrowserRouter>
    <MainWrapper>
      <Routes>
        <Route path={`${RoutePaths.logout}`} element={<Logout />} />
        <Route path="*" element={<Protected />} />
      </Routes>
    </MainWrapper>
  </BrowserRouter>
);

export default FullMode;
