import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  BooleanColumnFactory,
  GeoColumnFactory,
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  OwnerAsColumnFactory,
  RowSelectorColumnFactory,
  TimestampColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import dayjs from '+utils/dayjs';

export const Columns = {
  _source: '_source',
  _srcPort: '_srcPort',
  _srcGeo: '_srcGeo',
  _srcOwnerAs: '_srcOwnerAs',
  _destination: '_destination',
  _dstPort: '_dstPort',
  _dstGeo: '_dstGeo',
  _dstOwnerAs: '_dstOwnerAs',
  rowSelector: 'rowSelector',
  start: 'start',
  end: 'end',
  active: 'active',
  expiration: 'expiration',
  dstip: 'dstip',
  srcip: 'srcip',
  dstipname: 'dstipname',
  srcipname: 'srcipname',
  srciprepCat: 'srciprep.categories',
  dstasNum: 'dstas.number',
  dstasOrg: 'dstas.org',
  adapter: 'adapter',
  rules: 'rules',
  pluginName: 'plugin.name',
  algorithms: 'algorithms',
  dstport: 'dstport',
  srcport: 'srcport',
  dstportname: 'dstportname',
  srcportname: 'srcportname',
  customer: 'customer',
  menu: 'menu',
};

export const ColumnsCollection = (props) => {
  const { labelContext, ...tail } = props;

  const collection = {
    [Columns._source]: LabelOrIpColumnFactory({
      Header: 'Source',
      dataFieldName: 'srcip',
      labelFieldName:
        labelContext.ip === 'name' ? `srcip${labelContext.ip}` : '',
      showLabel: labelContext.show,
    }),
    [Columns._srcPort]: LabelOrPortColumnFactory({
      Header: 'SRC Port',
      dataFieldName: 'srcport',
      labelFieldName: `srcport${labelContext.port}`,
      showLabel: labelContext.show,
    }),
    [Columns._srcGeo]: GeoColumnFactory({
      Header: 'SRC Geo',
      field: 'srcgeo',
    }),
    [Columns._dstOwnerAs]: OwnerAsColumnFactory({
      Header: 'SRC AS',
      field: 'srcas',
    }),
    [Columns._destination]: LabelOrIpColumnFactory({
      Header: 'Destination',
      dataFieldName: 'dstip',
      labelFieldName:
        labelContext.ip === 'name' ? `dstip${labelContext.ip}` : '',
      showLabel: labelContext.show,
    }),
    [Columns._dstPort]: LabelOrPortColumnFactory({
      Header: 'DST Port',
      dataFieldName: 'dstport',
      labelFieldName: `dstport${labelContext.port}`,
      showLabel: labelContext.show,
    }),
    [Columns._dstGeo]: GeoColumnFactory({
      Header: 'DST Geo',
      field: 'dstgeo',
    }),
    [Columns._dstOwnerAs]: OwnerAsColumnFactory({
      Header: 'DST AS',
      field: 'dstas',
    }),
    [Columns.rowSelector]: RowSelectorColumnFactory(),
    [Columns.active]: BooleanColumnFactory({
      field: Columns.active,
      Cell: ({ value }) => (value ? 'active' : 'inactive'),
      Filter: SelectColumnFilter({
        fixedOptions: ['all', 'active', 'inactive'],
      }),
      filter: withAutoRemove((rows, _, filterValue) => {
        if (autoRemoveIfAll(filterValue)) {
          return rows;
        }

        return rows.filter((row) => {
          const original = getRowOriginal(row);
          return filterValue.value === 'inactive'
            ? !original.active
            : original.active;
        });
      }, autoRemoveIfAll),
    }),
    [Columns.expiration]: BaseColumnFactory({
      width: 100,
      Cell: ({ value }) => dayjs.unix(value).fromNow(),
      Filter: SelectColumnFilter({
        fixedOptions: ['all', 'not expired', 'expired'],
        defaultValue: 'all',
      }),
      filter: withAutoRemove((rows, _, filterValue) => {
        if (autoRemoveIfAll(filterValue)) {
          return rows;
        }

        // have to convert seconds to milliseconds for dayjs
        return rows.filter((row) => {
          const original = getRowOriginal(row);
          return filterValue.value === 'expired'
            ? dayjs().isAfter(dayjs(original.expiration * 1000))
            : dayjs().isBefore(dayjs(original.expiration * 1000));
        });
      }, autoRemoveIfAll),
    }),
    [Columns.dstip]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      sortType: 'ip',
    }),
    [Columns.srcip]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
      sortType: 'ip',
    }),
    [Columns.dstipname]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.srcipname]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.srciprepCat]: BaseColumnFactory({
      width: 150,
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.start]: TimestampColumnFactory({
      accessor: Columns.start,
      width: 160,
    }),
    [Columns.end]: TimestampColumnFactory({
      accessor: Columns.end,
      width: 160,
    }),
    [Columns.rules]: BaseColumnFactory({
      id: Columns.rules,
      accessor: ({ rules }) => (rules || []).map(({ name }) => name),
    }),
    [Columns.algorithms]: BaseColumnFactory({
      width: 130,
      Cell: UniversalCell(Columns.algorithms),
    }),
    [Columns.dstport]: NumberColumnFactory({ accessor: Columns.dstport }),
    [Columns.srcport]: NumberColumnFactory({ accessor: Columns.srcport }),
    [Columns.dstportname]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.srcportname]: BaseColumnFactory({
      getCellProps: () => ({ style: { justifyContent: 'flex-end' } }),
    }),
    [Columns.customer]: BaseColumnFactory({
      accessor: 'customer',
      Header: 'Account',
      width: 160,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.menu]: MenuColumnFactory(tail),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
