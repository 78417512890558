import PropTypes from '+prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import isEqual from 'lodash.isequal';

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import EqualIcon from 'mdi-react/EqualIcon';

import { createFilterValue, FilterOperator } from '../FilterTypeFactories';
import { Container, NumberInput, Row } from './Components';

export const NumberColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;

  const [localValue, setLocalValue] = useState({
    value: undefined,
    operator: undefined,
  });

  const onChange = useCallback(
    (nextOperator) => (nextValue) => {
      setLocalValue({
        value: nextValue,
        operator:
          nextValue == null || nextValue === '' ? undefined : nextOperator,
      });
    },
    [],
  );

  useEffect(() => {
    setLocalValue((prevValue) => {
      const nextValue = {
        value: filterValue?.value ?? undefined,
        operator: filterValue?.operator ?? undefined,
      };
      return isEqual(prevValue, nextValue) ? prevValue : nextValue;
    });
  }, [filterValue]);

  useDebounce(
    () => {
      if (localValue?.value == null || localValue?.value === '') {
        setFilter(undefined);
        return;
      }
      setFilter(createFilterValue(localValue));
    },
    300,
    [localValue],
  );

  return (
    <Container>
      <Row>
        <EqualIcon size={24} />
        <NumberInput
          min={0}
          value={
            localValue.operator === FilterOperator.equal
              ? localValue.value
              : undefined
          }
          onChange={onChange(FilterOperator.equal)}
        />
      </Row>

      <Row>
        <ChevronLeftIcon size={24} />
        <NumberInput
          min={0}
          value={
            localValue.operator === FilterOperator.lessThen
              ? localValue.value
              : undefined
          }
          onChange={onChange(FilterOperator.lessThen)}
        />
      </Row>

      <Row>
        <ChevronRightIcon size={24} />
        <NumberInput
          min={0}
          value={
            localValue.operator === FilterOperator.greaterThen
              ? localValue.value
              : undefined
          }
          onChange={onChange(FilterOperator.greaterThen)}
        />
      </Row>
    </Container>
  );
};

NumberColumnFilter.propTypes = {
  column: PropTypes.shape().isRequired,
};
