import { useCallback } from 'react';

import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';
import { ThemeTypes } from '@/models/ThemeTypes';
import TimeZones from '@/models/TimeZones';

import Button, { ButtonVariants } from '+components/Button';
import { useCurrentTheme } from '+components/CurrentTheme';
import FeatureFlag from '+components/FeatureFlag';
import Form from '+components/form/Form';
import { FieldContainer, Group, Label } from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Select from '+components/form/Select';
import Toggle from '+components/form/Toggle';
import { LayoutSizes, Row } from '+components/Layout';
import useEvent from '+hooks/useEvent';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import dayjs from '+utils/dayjs';

const localTz = dayjs.tz.guess();

const SettingsSection = () => {
  const [tz, changeTz] = usePortalSettingsValue(
    SettingCategories.ui,
    'tz',
    'Etc/UTC',
  );
  const { theme, changeTheme, removeTheme, isFetching, isUsedSystemTheme } =
    useCurrentTheme();
  const [isTimeFormat12h, changeTimeFormat] = usePortalSettingsValue(
    SettingCategories.ui,
    'isTimeFormat12h',
    false,
  );
  const [isNotificationPopupsEnabled, changeNotificationPopups] =
    usePortalSettingsValue(
      SettingCategories.ui,
      'isNotificationPopupsEnabled',
      true,
    );

  const onTzChange = useCallback(
    (value) => changeTz(normalizeSelectValue(value)),
    [],
  );

  const setLocalTz = useCallback(() => onTzChange(localTz), []);

  const onThemeChange = useEvent(() =>
    changeTheme(theme === ThemeTypes.dark ? ThemeTypes.light : ThemeTypes.dark),
  );

  const onTimeFormatChange = useEvent(() => changeTimeFormat(!isTimeFormat12h));

  const onNotificationPopupsChange = useEvent(() =>
    changeNotificationPopups(!isNotificationPopupsEnabled),
  );

  return (
    <Form>
      <Group>
        <Label>Theme</Label>
        <FieldContainer>
          <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
            <Toggle
              uncheckedLabel="Dark Theme"
              checked={theme !== ThemeTypes.dark}
              onChange={onThemeChange}
              checkedLabel="Light Theme"
              twoOptionToggle
              disabled={isFetching}
            />

            <Button
              onClick={removeTheme}
              disabled={isFetching || isUsedSystemTheme}
              variant={ButtonVariants.outlined}
            >
              OS theme
            </Button>
          </Row>
        </FieldContainer>
      </Group>

      <Group>
        <Label>Time Format</Label>
        <FieldContainer>
          <Toggle
            uncheckedLabel="Time in 24"
            checked={isTimeFormat12h}
            onChange={onTimeFormatChange}
            checkedLabel="12 Hours"
            twoOptionToggle
            disabled={isFetching}
          />
        </FieldContainer>
      </Group>

      <FeatureFlag flag={FeatureFlags.notificationPopupsManager}>
        <Group>
          <Label>Notification Popups</Label>
          <FieldContainer>
            <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
              <Toggle
                checked={isNotificationPopupsEnabled}
                onChange={onNotificationPopupsChange}
                uncheckedLabel="Disabled"
                checkedLabel="Enabled"
                disabled={isFetching}
              />
            </Row>
          </FieldContainer>
        </Group>
      </FeatureFlag>

      <Group style={{ maxWidth: '475px' }}>
        <Label>Time Zone</Label>
        <FieldContainer>
          <Row gap={LayoutSizes.groupGap} wrap="nowrap" alignItems="center">
            <Select
              name="tz"
              value={tz}
              options={TimeZones}
              onChange={onTzChange}
              disabled={isFetching}
            />

            <Button
              variant={ButtonVariants.outlined}
              disabled={isFetching || tz === localTz}
              onClick={setLocalTz}
            >
              OS time zone
            </Button>
          </Row>
        </FieldContainer>
      </Group>
    </Form>
  );
};

export default SettingsSection;
