import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import ipaddr from 'ipaddr.js';
import isCidr from 'is-cidr';
import { isIP } from 'is-ip';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ColorTypes from '@/models/ColorTypes';
import PermissionModel from '@/models/Permission';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal, {
  MessageContainer,
  MessageItem,
} from '+components/ConfirmModal';
import { Field, FinalForm } from '+components/form/FinalForm';
import { Group } from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValueAndParseCommas } from '+components/form/Normalizers';
import { validateIpOrCidrs } from '+components/form/Validators';
import { Col, Row } from '+components/Layout';
import usePermissions from '+hooks/usePermissions';
import useUIProperty from '+hooks/useUIProperty';

import ImportCsvForm from './ImportCsvForm';

const Form = styled.form`
  width: 80%;
  background-color: transparent;
  margin-bottom: 40px;
`;

const StyledButton = styled(Button)`
  margin-left: 140px;
  margin-right: 8px;
`;

const FormBody = (props) => {
  const { handleSubmit } = props;
  const [ipOptions] = useState([]);

  const [showImportCsvModal, toggleImportCsvModal] = useToggle(false);

  const permissions = usePermissions(
    PermissionModel.Resources.network_classification.value,
  );

  const canManage = permissions?.update;

  const { isFetching } = useSelector(customerSelectors.getState);

  return (
    <Form
      className="form form--horizontal wizard__form"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col lg={7} className="form form--horizontal" item container={false}>
          <Group>
            <Field
              name="allowedIPs"
              component={MultiSelectField}
              options={ipOptions}
              parse={normalizeMultiSelectValueAndParseCommas}
              validate={validateIpOrCidrs}
              allowCreate
              disabled={isFetching || !canManage}
              label="Inbound IP Allow List"
              helperText={
                <Stack>
                  <Box>
                    List of Classless Inter-Domain Routings (CIDRs) or IPs which
                    are allowed access to Fusion.
                  </Box>
                  <Box>If left blank, there is no restriction.</Box>
                  <Box>
                    IPs & CIDRs can be imported from txt or csv or pasted as
                    list.
                  </Box>
                  <Box component="code">
                    Example: 1.1.1.1, 2.2.2.2, 3.3.3.3/16
                  </Box>
                </Stack>
              }
            />
          </Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Group>
            <StyledButton type="submit" disabled={isFetching || !canManage}>
              Save Allowed IPs
            </StyledButton>
            <Button
              variant={ButtonVariants.outlined}
              disabled={isFetching || !canManage}
              onClick={toggleImportCsvModal}
            >
              Import IPs
            </Button>
          </Group>
        </Col>
      </Row>

      {showImportCsvModal && (
        <ImportCsvForm
          initialValues={{}}
          onToggle={toggleImportCsvModal}
          isOpen
        />
      )}
    </Form>
  );
};

FormBody.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const isIPAllowed = (ip, listIPs) => {
  const allowedIPs = (listIPs || []).filter(
    (item) => isIP(item) || isCidr(item),
  );

  if (!allowedIPs?.length) {
    return true;
  }

  return allowedIPs.some((allowedIP) => {
    if (isCidr(allowedIP)) {
      try {
        return ipaddr.parse(ip).match(ipaddr.parseCIDR(allowedIP));
      } catch {
        return false;
      }
    }

    return ipaddr.parse(ip).toString() === ipaddr.parse(allowedIP).toString();
  });
};

const IpSection = () => {
  const dispatch = useDispatch();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const [isUnderCovered] = useUIProperty('underCover');
  const [updateValues, setUpdateValues] = useState(null);

  const { allowedIPs } = useSelector(customerSelectors.getState);

  useEffect(() => {
    dispatch(customerActions.requestAllowedIps(customer.shortname));
  }, []);

  const initialValues = useMemo(() => {
    return { allowedIPs };
  }, [allowedIPs]);

  const onSubmit = useCallback(
    (values) => {
      if (isUnderCovered || isIPAllowed(profile.currentIp, values.allowedIPs)) {
        dispatch(customerActions.saveAllowedIPs(values.allowedIPs));
        return;
      }

      setUpdateValues(values);
    },
    [isUnderCovered, profile.currentIp],
  );

  const onClose = useCallback(() => {
    setUpdateValues(null);
  }, []);

  const onConfirm = useCallback(() => {
    onSubmit(updateValues);
    onClose();
  }, [onSubmit, onClose]);

  const onConfirmWithAdding = useCallback(() => {
    const newValues = [...updateValues.allowedIPs, profile.currentIp];
    onSubmit({ allowedIPs: newValues });
    onClose();
  }, [onSubmit, onClose, updateValues]);

  const textTemplate = useCallback(() => {
    return (
      <MessageContainer>
        <Stack>
          <Stack direction="row" gap={1}>
            <span>Your current IP</span>
            <MessageItem $color={ColorTypes.warning}>
              <strong>{profile.currentIp}</strong>
            </MessageItem>
            <span>is outside the allowed list.</span>
          </Stack>
          <Box>Do you want to add it to the list and save?</Box>
          <Box mt={1}>
            If you save without adding your current IP, you will lose access to
            the portal from the IP{' '}
            <Box display="inline-block" px={1}>
              <MessageItem $color={ColorTypes.warning}>
                <strong>{profile.currentIp}</strong>
              </MessageItem>
            </Box>
            .
          </Box>
        </Stack>
      </MessageContainer>
    );
  }, [profile.currentIp]);

  return (
    <Fragment>
      <FinalForm
        onSubmit={onSubmit}
        component={FormBody}
        initialValues={initialValues}
      />

      {updateValues && (
        <ConfirmModal
          item=""
          actionText="Add Your IP and Save"
          secondaryButtonText="Save"
          secondaryButtonVariant={ButtonVariants.contained}
          secondaryButtonColor={ColorTypes.warning}
          confirmButtonText="Add Your IP and Save"
          confirmButtonColor={ColorTypes.warning}
          toggleOnConfirm={false}
          onToggle={onClose}
          onConfirm={onConfirmWithAdding}
          onSecondary={onConfirm}
          isOpen
          testId="confirm-saving-allowed-ips"
          textTemplate={textTemplate}
        />
      )}
    </Fragment>
  );
};

export default IpSection;
