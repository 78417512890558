import PropTypes from '+prop-types';
import { memo, useCallback, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import styled from 'styled-components';

import DragVerticalIcon from 'mdi-react/DragVerticalIcon';

import { ContextTypesLabels } from '@/models/ContextTypes';

import Toggle from '+components/form/Toggle';
import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';
import { selectors as contextTypesTheme } from '+theme/slices/contextTypes';
import { throttleRAF } from '+utils';

const Titles = {
  menu: 'Menu',
  expander: 'Expander',
};

const ColumnContainer = styled.div`
  font-size: 12px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding: 2px 15px;

  max-height: 50px; // 24px
  will-change: padding, max-height;
  transition:
    padding 0.3s,
    max-height 0.5s;

  opacity: ${({ $dragging }) => ($dragging ? 0 : 1)};
  background: ${({ $hovered, theme }) =>
    $hovered
      ? theme.colorTool.lighten(theme.contextMenuItemHoverBackground, 0.2)
      : null};

  &:hover {
    background: ${({ $hovered, theme }) =>
      $hovered ? null : theme.contextMenuItemHoverBackground};
  }
`;

const Handle = styled.div`
  cursor: grab;
  min-width: 21px;
  margin-right: 4px;
  text-align: center;
  line-height: 1em;

  opacity: ${({ $hidden }) => ($hidden ? 0 : 0.5)};
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : null)};

  &:active {
    cursor: grabbing !important;
  }
`;

const ToggleStyled = styled(Toggle)`
  margin-left: 20px;
  transform: scale(0.8, 0.8);
  height: 20px;
  padding: 0;
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2em;
`;

const Traffic = styled.div`
  background: transparent;
  background: ${({ $context }) => contextTypesTheme[$context] || null};
  font-size: 10px;
  font-weight: bold;
  width: 32px;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 0 5px;
  max-height: 14px;
  min-height: 14px;
  margin-top: -2px;

  & > span {
    margin-top: 1px;
    color: ${contextTypesTheme.trafficTypeColorText} !important;
  }
`;

const Space = styled.div`
  flex: 1 1 0;
`;

const throttleMove = throttleRAF((cb) => cb(), 100);

const Column = (props) => {
  const { column, index, disableDragAndDrop, disableGroupBy, hasTrafficType } =
    props;

  const dropRef = useRef(null);

  let canDrag =
    !disableDragAndDrop &&
    (column.canDragAndDrop ?? !column.disableDragAndDrop);
  if (column.isGrouped) {
    canDrag = canDrag && !disableGroupBy;
  }

  const [searchText, setSearchText] = useState('');

  const [{ isHover }, drop] = useDrop({
    accept: 'column',
    collect: (monitor) => ({
      isHover: monitor.isOver(),
    }),
    hover: (item) => {
      const { index: dragIndex, id: dragId } = item;
      if (column.id === dragId || index === dragIndex) {
        return;
      }

      throttleMove(() => {
        column.moveColumn(dragId, column.id);

        item.index = index;
      });
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'column',
    item: {
      id: column.id,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const onVisibilityToggle = useCallback(() => {
    column.toggleHidden();
    if (column.isDuplicate) {
      column.removeDuplicate();
    }
  }, [column.toggleHidden, column.removeDuplicate, column.isDuplicate]);

  const onGroupByToggle = useCallback(() => {
    column.toggleGroupBy();
  }, [column.toggleGroupBy]);

  const extractTextContext = useCallback((node) => {
    setSearchText((node?.textContent ?? '').toLowerCase());
  }, []);

  const handleProps =
    canDrag && column.id !== TechnicalColumns.totalColumn
      ? {
          ref: drag,
          title: 'Move column',
        }
      : { $hidden: true };

  preview(drop(dropRef));

  const showColumn = canDrag || !column.disableHide;

  return showColumn ? (
    <ColumnContainer
      ref={canDrag ? dropRef : null}
      $dragging={isDragging}
      $hovered={isHover}
      data-mlc-search={searchText}
    >
      <Handle {...handleProps}>
        <DragVerticalIcon size={18} />
      </Handle>
      {hasTrafficType && (
        <Traffic $context={column.trafficType}>
          <span>
            {ContextTypesLabels[column.trafficType] || column.trafficType || ''}
          </span>
        </Traffic>
      )}
      <Title ref={extractTextContext} title={searchText}>
        {Titles[column.id] ||
          column.render('ContextMenuHeader') ||
          column.render('Header')}
      </Title>
      <Space />
      {!column.disableHide && !column.isGrouped && (
        <ToggleStyled
          checked={column.isVisible}
          onChange={onVisibilityToggle}
        />
      )}
      {!column.disableHide && column.isGrouped && !disableGroupBy && (
        <ToggleStyled checked={column.isGrouped} onChange={onGroupByToggle} />
      )}
    </ColumnContainer>
  ) : null;
};

Column.propTypes = {
  column: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  disableDragAndDrop: PropTypes.bool,
  disableGroupBy: PropTypes.bool,
  hasTrafficType: PropTypes.bool,
};

Column.defaultProps = {
  disableDragAndDrop: false,
  disableGroupBy: true,
  hasTrafficType: false,
};

export default memo(Column);
