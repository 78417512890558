import PropTypes from '+prop-types';
import { Fragment } from 'react';

import FieldsSection from '+components/form/FieldsSection';
import Field from '+components/form/FinalForm/Field';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const severities = [
  { value: 'info', label: 'Info' },
  { value: 'warning', label: 'Warning' },
  { value: 'error', label: 'Error' },
  { value: 'critical', label: 'Critical' },
];

const PagerdutyFields = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.severity"
      label="Severity"
      component={SelectField}
      options={severities}
      style={{ width: '50%' }}
      validate={validateRequired}
      parse={normalizeSelectValue}
      disabled={disabled}
      required
    />
    <FieldsSection label="Authentication">
      <Field
        name="config.apikey"
        label="API Key"
        component={TextField}
        type="password"
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />
      <Field
        name="config.integrationkey"
        label="Integration Key"
        component={TextField}
        type="password"
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />
    </FieldsSection>
  </Fragment>
);

PagerdutyFields.propTypes = {
  disabled: PropTypes.bool,
};

PagerdutyFields.defaultProps = {
  disabled: false,
};

export default PagerdutyFields;
