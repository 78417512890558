import { color as d3color } from 'd3-color';

const PI2 = 2 * Math.PI;

export class Point {
  constructor({
    radius,
    waveLength,
    speed,
    color,
    lineWidth,
    isWaveMovingForward,
    splitPiecesCount,
    rotateAfter,
    rotateBefore,
    point,
  }) {
    this.radius = radius || 100;
    this.waveLength = waveLength || 10;
    this.speed = speed || 0.5;
    this.strokeColor = d3color(color || 'rgba(255, 0, 0, 0.5)');
    this.lineWidth = lineWidth || 1;
    this.isWaveMovingForward = isWaveMovingForward === true;
    this.splitPiecesCount = splitPiecesCount;
    this.rotateAfter = rotateAfter || 1;
    this.rotateBefore = rotateBefore || 0;

    this.point = point;
    this._needDie = false;
    this._dead = false;

    this._p = this.radius + this.lineWidth;
    this._downWave = 0;

    this._canvasSize = this.radius * 2 + this.lineWidth * 2;

    this._canvas = document.createElement('canvas');
    this._canvas.width = this._canvasSize;
    this._canvas.height = this._canvasSize;

    this._lastStep = !this.isWaveMovingForward ? this.radius : 1;

    this._ctx = this._canvas.getContext('2d');
  }

  get x() {
    return this.point ? this.point.x : 0;
  }

  get y() {
    return this.point ? this.point.y : 0;
  }

  center() {
    this._center = {
      x: this.x + this.lineWidth,
      y: this.y + this.lineWidth,
    };
    return this._center;
  }

  kill() {
    this._needDie = true;
  }

  get isDead() {
    return this._dead;
  }

  draw(ctx) {
    let currentRadius;
    let currentArcLength;
    let currentDashLineLength;
    let currentDashSpaceLength;
    let currentRadiusPercent;
    let strokeAlpha;

    ctx = ctx || this._ctx;
    ctx.save();

    if (this.isWaveMovingForward) {
      if (this._lastStep < this.radius) {
        this._lastStep += this.speed;
      } else {
        this._lastStep = this.radius;
      }
    } else if (this._lastStep > 0) {
      this._lastStep -= this.speed;
    } else {
      this._lastStep = 0;
    }

    let waves = Math.ceil(this._lastStep / this.waveLength);

    if (this._needDie) {
      this._downWave += 0.05 * this.speed;
    }

    // eslint-disable-next-line no-plusplus
    while (waves--) {
      currentRadius = this._lastStep - this.waveLength * waves;
      currentRadiusPercent = currentRadius / this.radius;
      strokeAlpha =
        1 - currentRadiusPercent - (this._needDie ? this._downWave : 0);
      strokeAlpha = strokeAlpha > 0 ? strokeAlpha : 0;

      ctx.beginPath();
      this.strokeColor.opacity = strokeAlpha;
      ctx.strokeStyle = `${this.strokeColor}`;
      ctx.lineWidth = this.lineWidth;
      if (
        currentRadiusPercent <= this.rotateAfter &&
        currentRadiusPercent >= this.rotateBefore
      ) {
        if (this.splitPiecesCount > 1) {
          currentArcLength = currentRadius * PI2;
          currentDashSpaceLength =
            currentArcLength / (this.splitPiecesCount * 2);
          currentDashLineLength =
            currentArcLength / this.splitPiecesCount - currentDashSpaceLength;
          ctx.setLineDash([currentDashLineLength, currentDashSpaceLength]);
          ctx.lineDashOffset = -currentArcLength * currentDashLineLength;
        }
      }
      // TODO: Create a param to move by X or Y
      ctx.arc(this._p, this._p, currentRadius, 0, PI2);
      ctx.stroke();
    }

    if (this._needDie && this._downWave > 1) {
      this._dead = true;
    }

    ctx.restore();
  }
}
