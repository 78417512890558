import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/api/nql-complete';

export const useThresholdsParse = (threshold, thresholdContext, id) => {
  const dispatch = useDispatch();
  const parseThreshold = useSelector(selectors.getParseData(id));

  const namespace = `parse_threshold_${id}`;

  useEffect(() => {
    dispatch(
      actions.parse(
        { context: thresholdContext, text: threshold, id },
        namespace,
      ),
    );

    return () => {
      dispatch(actions.cancel(namespace));
      dispatch(actions.clearParseError({ id }));
    };
  }, [threshold, thresholdContext, id]);

  return parseThreshold;
};

export default useThresholdsParse;
