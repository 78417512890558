import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import PhoneInput, {
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isSupportedCountry,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

import classNames from 'classnames';
import styled from 'styled-components';

import FormField from '+components/form/FormField';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const PhoneNumberField = styled((props) => {
  const {
    className,
    input: { name, value, onChange },
    meta: { touched, error, dirty, submitFailed },
    label,
    helperText,
    country,
    defaultCountry,
    disabled,
    readOnly,
    required,
    onCountryChange,
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  const normalizedDefaultCountry = useMemo(
    () =>
      isSupportedCountry(defaultCountry || '') ? defaultCountry : undefined,
    [defaultCountry],
  );

  const normalizedCountry = useMemo(
    () => (isSupportedCountry(country || '') ? country : undefined),
    [country],
  );

  const countryCallingCode = useMemo(
    () =>
      normalizedCountry ? `+${getCountryCallingCode(normalizedCountry)}` : '',
    [normalizedCountry],
  );

  const normalizedValue = useMemo(() => {
    const fixedValue = (value || '').replace(/\s/g, '');
    return fixedValue.startsWith('+')
      ? fixedValue
      : `${countryCallingCode}${fixedValue}`;
  }, [countryCallingCode, value]);

  const emitChange = useCallback(
    (val) => {
      if (onChange) {
        let fixedValue = (val || '').replace(/\s/g, '');
        // If country was set in props then return national number only
        if (countryCallingCode) {
          fixedValue = isValidPhoneNumber(fixedValue)
            ? parsePhoneNumber(fixedValue).nationalNumber
            : fixedValue.replace(`${countryCallingCode}`, '');
        }
        onChange(fixedValue);
      }
    },
    [countryCallingCode, onChange],
  );

  const emitCountryChange = useCallback(
    (val) => {
      if (onCountryChange) {
        onCountryChange(val ?? '');
      }
    },
    [onCountryChange, value],
  );

  return (
    <FormField
      className={className}
      label={label}
      helperText={helperText}
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <PhoneInput
        className={classNames({ invalid, disabled, readOnly })}
        name={name}
        defaultCountry={normalizedDefaultCountry}
        country={normalizedCountry}
        value={normalizedValue}
        disabled={disabled}
        readOnly={readOnly}
        onChange={emitChange}
        onCountryChange={emitCountryChange}
      />
    </FormField>
  );
})`
  .PhoneInput {
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colorFieldsBorder};
    background: ${({ theme }) => theme.colorFieldBackground};

    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      border-color: ${({ theme }) => theme.primary};
    }
  }

  .PhoneInput.invalid {
    border-color: ${({ theme }) => theme.colorFieldsBorderInvalid};
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      border-color: ${({ theme }) => theme.colorFieldsBorderInvalid};
    }
  }

  .PhoneInput.disabled {
    border: 0 solid transparent;
    background: transparent;

    .PhoneInputCountry {
      padding-left: unset;
    }

    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  .PhoneInput.readOnly {
    .PhoneInputCountrySelect {
      pointer-events: none;
    }
    background: transparent;
  }

  .PhoneInputCountry {
    padding-left: 7px;
    .PhoneInputCountrySelect {
      ${ScrollBarMixin}
    }

    .PhoneInputCountryIcon--border {
      border: unset;
      box-shadow: unset;
    }
  }

  .PhoneInputInput,
  .PhoneInputInput:hover {
    border: unset;
  }
`;

PhoneNumberField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  error: PropTypes.string,
  defaultCountry: PropTypes.string,
  country: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onCountryChange: PropTypes.func,
};

PhoneNumberField.defaultProps = {
  className: '',
  input: {
    value: null,
    onChange: null,
  },
  meta: {},
  label: null,
  helperText: null,
  error: null,
  defaultCountry: 'US',
  country: null,
  invalid: false,
  disabled: false,
  readOnly: false,
  required: false,
  onCountryChange: null,
};

export { formatPhoneNumberIntl };
export default PhoneNumberField;
