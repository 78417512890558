import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo } from 'react';

import classNames from 'classnames';
import upperFirst from 'lodash.upperfirst';
import styled from 'styled-components';

import { ColorTypes } from '@/models/ColorTypes';

import Button, { ButtonVariants } from '+components/Button';
import Modal, {
  ModalBody,
  ModalFooter as ModalFooterOrigin,
  ModalHeader,
} from '+components/Modal';

const ModalFooter = styled(ModalFooterOrigin)`
  .gray:not(:hover) {
    color: #c0bfc5 !important;
    border-color: #c0bfc5 !important;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

export const MessageAction = styled.span`
  text-transform: lowercase !important;
  &:not(:first-child) {
    margin-left: 0.25rem;
  }
`;

export const MessageItem = styled.span`
  display: flex;
  color: ${({ theme, $color }) =>
    theme[$color || ColorTypes.primary]} !important;
  text-transform: ${({ $capitalize }) =>
    $capitalize && 'capitalize !important'};
  &:not(:first-child) {
    margin-left: 0.25rem;
  }
`;

const defaultTitleTemplate = (action, item) => (
  <Fragment>
    {upperFirst(action || '')} {item}
  </Fragment>
);

const defaultTextTemplate = (action, item, whyAsking, capitalize, color) => {
  return (
    <Fragment>
      <MessageContainer>
        <span>Do you really want to</span>
        <MessageAction>{action}</MessageAction>
        <MessageItem $capitalize={capitalize} $color={color}>
          {item ?? 'this item'}
        </MessageItem>
        <span>?</span>
      </MessageContainer>
      <div>{whyAsking}</div>
    </Fragment>
  );
};

/**
 * Confirm dialog.
 */
const ConfirmModal = (props) => {
  const {
    item,
    titleTemplate,
    textTemplate,
    cancelButtonText,
    confirmButtonText,
    secondaryButtonText,
    actionText,
    confirmButtonColor,
    secondaryButtonColor,
    secondaryButtonVariant,
    confirmButtonVariant,
    whyAsking,
    isOpen,
    isDisabled,
    cancelButtonDisabled,
    toggleOnConfirm,
    onConfirm,
    onToggle,
    onSecondary,
    capitalize,
    disableEscapeKeyDown,
    hideCloseButton,
    testId,
  } = props;

  const bodyActionText = actionText === '' ? confirmButtonText : actionText;

  const normalizedTitle = useMemo(
    () =>
      typeof titleTemplate === 'function'
        ? titleTemplate(actionText || confirmButtonText, item)
        : titleTemplate,
    [titleTemplate, confirmButtonText, item],
  );

  const normalizedText = useMemo(
    () =>
      typeof textTemplate === 'function'
        ? textTemplate(
            bodyActionText,
            item,
            whyAsking,
            capitalize,
            confirmButtonColor,
          )
        : textTemplate,
    [
      textTemplate,
      bodyActionText,
      item,
      whyAsking,
      capitalize,
      confirmButtonColor,
    ],
  );

  const doConfirm = useCallback(() => {
    onConfirm?.();

    if (toggleOnConfirm) {
      onToggle?.();
    }
  }, [onConfirm, toggleOnConfirm, onToggle]);

  return (
    <Modal
      isOpen={isOpen}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={onToggle}
      data-testid={testId}
    >
      <ModalHeader
        color={confirmButtonColor}
        hideCloseButton={hideCloseButton}
        onClose={onToggle}
        capitalize={capitalize}
      >
        {normalizedTitle}
      </ModalHeader>

      <ModalBody>{normalizedText}</ModalBody>

      <ModalFooter>
        {confirmButtonText && (
          <Button
            color={confirmButtonColor}
            variant={confirmButtonVariant}
            type="submit"
            disabled={isDisabled}
            onClick={doConfirm}
            testId="confirm-button"
          >
            {confirmButtonText}
          </Button>
        )}

        {secondaryButtonText && (
          <Button
            $gray={confirmButtonColor === ColorTypes.danger}
            className={classNames({
              gray: confirmButtonColor === ColorTypes.danger,
            })}
            color={secondaryButtonColor}
            variant={secondaryButtonVariant}
            type="submit"
            disabled={isDisabled}
            onClick={onSecondary}
            testId="secondary-button"
          >
            {secondaryButtonText}
          </Button>
        )}

        {cancelButtonText && (
          <Button
            $gray={confirmButtonColor === ColorTypes.danger}
            className={classNames({
              gray: confirmButtonColor === ColorTypes.danger,
            })}
            variant={ButtonVariants.outlined}
            disabled={cancelButtonDisabled}
            onClick={onToggle}
            testId="cancel-button"
          >
            {cancelButtonText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  /**
   * Item to confirm action.
   */
  item: PropTypes.component,
  /**
   * Title template function.
   */
  titleTemplate: PropTypes.children,
  /**
   * Text template function.
   */
  textTemplate: PropTypes.children,
  /**
   * Text for the chancel button
   */
  cancelButtonText: PropTypes.string,
  /**
   * Text for the confirm button
   */
  confirmButtonText: PropTypes.string,
  /**
   * Text for the secondary button
   */
  secondaryButtonText: PropTypes.string,
  /**
   * Action text for modal body, if not supplied, will default to confirmButtonText
   */
  actionText: PropTypes.string,
  /**
   * Confirm button color.
   */
  confirmButtonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  /**
   * Confirm button variant.
   */
  confirmButtonVariant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * Secondary button color.
   */
  secondaryButtonColor: PropTypes.oneOf(Object.values(ColorTypes)),
  /**
   * Secondary button variant
   */
  secondaryButtonVariant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * Text explaining why action need to be confirmed.
   */
  whyAsking: PropTypes.string,
  /**
   * Boolean to control the state of the popover.
   */
  isOpen: PropTypes.bool,
  /**
   * Boolean to control the state confirm button.
   */
  isDisabled: PropTypes.bool,
  /**
   * Boolean to control the state of canceled button.
   */
  cancelButtonDisabled: PropTypes.bool,
  /**
   * If true, modal will be toggled after confirm.
   */
  toggleOnConfirm: PropTypes.bool,
  /**
   * A callback fired when confirm delete clicked.
   */
  onConfirm: PropTypes.func.isRequired,
  /**
   * A callback fired when the secondary button is clicked
   */
  onSecondary: PropTypes.func,
  /**
   * A callback fired when the modal toggle.
   */
  onToggle: PropTypes.func.isRequired,
  /**
   * Deprecated param.
   */
  capitalize: PropTypes.bool,
  /**
   * If true, hitting escape will not fire the onClose callback.
   */
  disableEscapeKeyDown: PropTypes.bool,
  /**
   * If true, close button will be hidden
   */
  hideCloseButton: PropTypes.bool,
  testId: PropTypes.string,
};

ConfirmModal.defaultProps = {
  item: null,
  titleTemplate: defaultTitleTemplate,
  textTemplate: defaultTextTemplate,
  cancelButtonText: 'Cancel',
  secondaryButtonText: null,
  confirmButtonText: 'Delete',
  actionText: '',
  confirmButtonColor: ColorTypes.danger,
  secondaryButtonColor: undefined,
  confirmButtonVariant: ButtonVariants.contained,
  secondaryButtonVariant: ButtonVariants.outlined,
  whyAsking: 'This process cannot be undone.',
  isDisabled: false,
  cancelButtonDisabled: false,
  isOpen: false,
  toggleOnConfirm: true,
  capitalize: false,
  disableEscapeKeyDown: false,
  hideCloseButton: false,
  onSecondary: null,
  testId: 'confirm-modal',
};

export default ConfirmModal;
