import L from 'leaflet';
import { feature as topojsonFeature } from 'topojson-client';

import worldMapJson from './worldMap.geoJson.json';

// import worldMapJson from '../SchemeLayer/lands-110m';

// remove Antarctica iso id === 10
if (worldMapJson.objects && worldMapJson.objects.countries) {
  const {
    objects: {
      countries: { geometries },
    },
  } = worldMapJson;
  let l = geometries.length;
  // eslint-disable-next-line no-plusplus
  while (l--) {
    const { id } = geometries[l];
    if (id === 10) {
      geometries.splice(l, 1);
      break;
    }
  }
}

const worldMap =
  worldMapJson.objects && worldMapJson.objects.countries
    ? topojsonFeature(worldMapJson, worldMapJson.objects.countries).features
    : worldMapJson;

export class GeoJSONLayer {
  constructor(map, { map: options } = {}) {
    this._map = map;
    const { style } = options || {};

    const renderer = L.canvas();
    this._layer = L.geoJSON(worldMap, {
      renderer,

      smoothFactor: 1,

      style: {
        weight: 1,

        fillColor: '#0E161A',
        fillOpacity: 1,

        color: '#29434A',
        opacity: 1,
        ...style,
      },
    }).addTo(map);

    renderer._container.innerText = 'This browser does not support Canvas';
  }
}
