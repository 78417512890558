import styled from 'styled-components';

const NoData = styled.div`
  display: ${(props) => (props.$plotBox ? null : 'none')};
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  text-shadow: ${({ theme }) =>
    theme.name === 'dark' ? '0 0 2px black' : null};
`;

export default NoData;
