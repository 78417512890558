import { useMemo, useState } from 'react';

import { produce } from 'immer';

export const useMenu = () => {
  const [state, setState] = useState({
    props: {},
    top: 0,
    left: 0,
    show: false,
  });

  const updateMenu = (transform) => setState(transform);

  const updateMenuProps = (props) =>
    setState((prev) =>
      produce(prev, (draft) => {
        draft.props = props;
      }),
    );

  const showMenuXY = (x, y, props) =>
    setState((prev) =>
      produce(prev, (draft) => {
        if (y !== null) {
          draft.top = y;
          draft.left = x;
        }

        if (props) {
          draft.props = props;
        }

        draft.show = true;
      }),
    );

  const showMenu = (event, _props) => {
    event.preventDefault();
    event.stopPropagation();
    showMenuXY(event.pageX, event.pageY, _props);
  };

  const showMenuDropdown = (htmlElem, _props) => {
    const rect = htmlElem.getBoundingClientRect();
    showMenuXY(rect.x, rect.bottom + 5, _props);
  };

  const hideMenu = () => {
    setState((prev) =>
      !prev.show
        ? prev
        : {
            ...prev,
            show: false,
          },
    );
  };

  const actions = useMemo(
    () => ({
      showMenu,
      showMenuXY,
      showMenuDropdown,
      hideMenu,
      updateMenu,
      updateMenuProps,
    }),
    [],
  );

  return {
    state,
    actions,
  };
};
