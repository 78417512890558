/* eslint-disable camelcase */
export class Customer {
  constructor(data) {
    const {
      id,
      ingest_ip,
      ingest_port,
      billing: { data_retention, rollup_retention, fps },
      reseller: { node, parent },
      created_datetime,
      ...rest
    } = data;

    Object.assign(this, {
      ...rest,
      id,
      ingestIp: ingest_ip,
      ingestPort: ingest_port,
      retention: data_retention,
      rollupRetention: rollup_retention ?? data_retention,
      fps,
      parent,
      node,
      created: new Date(created_datetime),
    });
  }
}
