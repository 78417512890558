import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PermissionModel from '@/models/Permission';

import { selectors as docsSelectors } from '@/redux/api/docs';
import {
  actions as contextActions,
  selectors as contextSelectors,
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { lang } from '+components/charts/common/utils';
import usePermissions from '+hooks/usePermissions';

import CommonTable, { TableTypes } from './components/CommonTable';

const DetectionModels = () => {
  const dispatch = useDispatch();

  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const ccms = useSelector(contextSelectors.getCCMs);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);
  const detectionModelsDocs = useSelector(docsSelectors.getDetectionModelsDocs);

  const permissions = usePermissions(
    PermissionModel.Resources.threat_model.value,
  );

  const models = useMemo(
    () => [
      ...Object.values(algorithms || {}).reduce((acc, row) => {
        if (row.algo_type !== 'TDM') {
          return acc;
        }
        const url = detectionModelsDocs?.[row.name]?.url;
        return [...acc, { ...row, url }];
      }, []),
      ...Object.values(ccms || {}).map((row) => {
        const url = detectionModelsDocs?.[row.name]?.url;
        return { ...row, url };
      }),
    ],
    [algorithms, ccms, detectionModelsDocs],
  );

  useEffect(() => {
    dispatch(rulesActions.fetchAlgorithms(null, 'fetchAlgorithms'));
    dispatch(rulesActions.fetchRules(null, 'fetchRules'));
    dispatch(contextActions.fetchCCMs(null, 'fetchCCMs'));
    return () => {
      dispatch(rulesActions.cancel('fetchAlgorithms'));
      dispatch(rulesActions.cancel('fetchRules'));
      dispatch(contextActions.cancel('fetchCCMs'));
    };
  }, []);

  return (
    <CommonTable
      type={isDefaultCustomer ? TableTypes.system : TableTypes.systemCustomer}
      rows={models}
      permissions={permissions}
      isDefaultCustomer={isDefaultCustomer}
      noDataText={algorithms ? undefined : lang.loading}
    />
  );
};

export default DetectionModels;
