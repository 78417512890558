import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import * as HSX from 'react-jsx-highmaps';

import geojson from '@highcharts/map-collection/custom/world-lowres.geo.json';
import classNames from 'classnames';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import NoDataStr from '@/models/NoDataStr';
import * as PropertiesTray from '@/models/PropertiesTray';

import Flag from '+components/Flag';

import '+utils/proj4-module';

import useExportingFilename from '+hooks/useExportingFilename';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';
import dayjs from '+utils/dayjs';

import useDefaultPropsHSX from './common/defaultPropsHSX';
import { labelWithDataRenderer } from './common/formatters';
import { Highmaps, pointClickEvent } from './common/highcharts';
import NoData from './common/NoData';
import { defaultColorVariety, lang } from './common/utils';

export const includeFields = [
  'srcip',
  'srcipname',
  'dstip',
  'dstipname',
  'srcgeo',
  'dstgeo',
  'adapter',
  'plugin',
  'rules',
  'expiration',
];

const extractData = (data, showExpired, labelContext) => {
  const now = dayjs();

  const normalizedOptions = data.reduce((acc, record) => {
    if (!showExpired) {
      const expiration = dayjs(record.expiration);
      if (expiration.diff(now) <= 0) {
        return acc;
      }
    }

    if (
      record.srcgeo &&
      (record.srcgeo.location.lat !== 0 || record.srcgeo.location.lon !== 0)
    ) {
      acc[
        `srcgeo-${record.srcgeo.location.lat}-${record.srcgeo.location.lon}`
      ] = {
        recordtype: 'srcip',
        colorIndex: 0,
        name: record.adapter,
        lat: record.srcgeo.location.lat,
        lon: record.srcgeo.location.lon,
        record,
        labelContext,
      };
    }
    if (
      record.dstgeo &&
      (record.dstgeo.location.lat !== 0 || record.dstgeo.location.lon !== 0)
    ) {
      acc[
        `dstgeo-${record.dstgeo.location.lat}-${record.dstgeo.location.lon}`
      ] = {
        recordtype: 'dstip',
        colorIndex: 2,
        name: record.adapter,
        lat: record.dstgeo.location.lat,
        lon: record.dstgeo.location.lon,
        record,
        labelContext,
      };
    }
    return acc;
  }, {});

  return Object.values(normalizedOptions);
};

function dataLabelFormatter() {
  return labelWithDataRenderer({
    data: this.point.name,
  });
}

function pointFormatter() {
  const { expiration, plugin, rules, srcgeo, dstgeo } = this.record;

  const labelsContext = this.labelContext.ip;

  const srcIpLabels =
    !this.labelContext.show || this.labelContext.ip !== 'name'
      ? []
      : this.record.srcipname;
  const dstIpLabels =
    !this.labelContext.show || this.labelContext.ip !== 'name'
      ? []
      : this.record.dstipname;

  const srcIpLabel = labelWithDataRenderer({
    data: this.record.srcip,
    labelsContext,
    labels: srcIpLabels,
    renderAsGroup: false,
  });

  const dstIpLabel = labelWithDataRenderer({
    data: this.record.dstip,
    labelsContext,
    labels: dstIpLabels,
    renderAsGroup: false,
  });

  return renderToStaticMarkup(
    <div className="tooltip-data">
      <span className="tooltip-item">
        <span className="tooltip-item__name">Expiration:</span>
        <span className="tooltip-item__value">
          {dayjs.unix(expiration).fromNow()}
        </span>
      </span>
      <span className="tooltip-item">
        <span className="tooltip-item__name">Plugin:</span>
        <span className="tooltip-item__value">{plugin.name || NoDataStr}</span>
      </span>
      <span className="tooltip-item">
        <span className="tooltip-item__name">Rule:</span>
        <span className="tooltip-item__value">
          {rules.map(({ name }) => name).join(', ') || NoDataStr}
        </span>
      </span>
      <span className="tooltip-item">
        <span className="tooltip-item__name">SRCIP:</span>
        <span
          className="tooltip-item__value"
          data-srcip={this.record.srcip}
          data-iptype="SRCIP"
          data-customer={this.record.customer}
        >
          {srcIpLabel}
        </span>
      </span>
      <span className="tooltip-item">
        <span className="tooltip-item__name">SRCGEO:</span>
        <span className="tooltip-item__value">
          {srcgeo.countrycode && (
            <Fragment>
              <Flag countryCode={srcgeo.countrycode} />
              <span>{srcgeo.city}</span>
              <span>{srcgeo.subdiso}</span>
              <span>{srcgeo.countrycode}</span>
            </Fragment>
          )}
          {!srcgeo.countrycode && NoDataStr}
        </span>
      </span>
      <span className="tooltip-item">
        <span className="tooltip-item__name">DSTIP:</span>
        <span
          className="tooltip-item__value"
          data-dstip={this.record.dstip}
          data-iptype="DSTIP"
          data-customer={this.record.customer}
        >
          {dstIpLabel}
        </span>
      </span>
      <span className="tooltip-item">
        <span className="tooltip-item__name">DSTGEO:</span>
        <span className="tooltip-item__value">
          {dstgeo.countrycode && (
            <Fragment>
              <Flag countryCode={dstgeo.countrycode} />
              <span>{dstgeo.city}</span>
              <span>{dstgeo.subdiso}</span>
              <span>{dstgeo.countrycode}</span>
            </Fragment>
          )}
          {!dstgeo.countrycode && NoDataStr}
        </span>
      </span>
    </div>,
  );
}

const mapSeriesData = [];
const mapSeriesJoinBy = ['iso-a2', 'key'];

const BlockWorldMap = styled((props) => {
  const {
    className,
    title,
    subtitle,
    series,
    loading,
    width,
    height,
    colorVariety,
    exporting,
    showExpired,
    hideTitle,
    hideSubtitle,
  } = props;

  const chartRef = useRef(null);

  const [, setPropertiesTray] = useUIProperty('propertiesTray', null);
  const [globalContextMenu, setGlobalContextMenu] = useUIProperty(
    'globalContextMenu',
    null,
  );

  const exportingFilename = useExportingFilename(title);

  const defaultProps = useDefaultPropsHSX({ exporting });

  const [filters] = useGlobalFilters();

  const normalizedSeries = extractData(
    series,
    showExpired,
    filters.labelContext,
  );

  const onChartCallback = useCallback((chart) => {
    chartRef.current = chart;
  }, []);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart && !chart?.fullscreen?.isOpen) {
      chart.setSize(width, height, false);
    }
  }, [width, height]);

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart?.container) {
      return undefined;
    }

    // Add mouseleave event (hide tooltips)
    const onMouseLeave = (event) => {
      const { target } = event;
      if (
        target.classList.contains('highcharts-container') ||
        target.classList.contains('highcharts-tooltip')
      ) {
        if (globalContextMenu) {
          return;
        }
        chart.tooltip.hide(true);
      }
    };

    chart.container.addEventListener('mouseleave', onMouseLeave, true);

    return () => {
      if (chart?.container) {
        chart.container.removeEventListener('mouseleave', onMouseLeave, true);
      }
    };
  }, [chartRef.current, globalContextMenu]);

  // Dynamically set export file name (for cases when chart title changing dynamically in widgets)
  // @see: https://api.highcharts.com/highcharts/exporting.filename
  // @see: https://www.highcharts.com/forum/viewtopic.php?t=31299
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chart.options.exporting.filename = exportingFilename;
    }
  }, [chartRef.current, exportingFilename]);

  // Memoize chart properties
  const dataLabelProps = useMemo(
    () => ({
      enabled: true,
      useHTML: true,
      formatter: dataLabelFormatter,
    }),
    [],
  );

  const markerProps = useMemo(
    () => ({
      enabled: true,
      symbol: 'triangle-down',
    }),
    [],
  );

  const pointProps = useMemo(
    () => ({
      events: {
        click: pointClickEvent,
      },
    }),
    [],
  );

  const tooltipProps = useMemo(
    () => ({
      shadow: false,
      headerFormat: '',
      pointFormatter,
    }),
    [],
  );

  const chartClickEvent = useCallback((event) => {
    const chart = chartRef.current;

    chart.tooltip.hide(true);

    // do not open properties tray if chart is in fullscreen mode
    if (chart?.fullscreen?.isOpen) {
      return;
    }

    const { target } = event;
    const { srcip, dstip, iptype, customer } =
      target.parentNode?.parentNode?.dataset || {};
    if (!srcip && !dstip) {
      return;
    }

    const ipTtype = iptype === 'SRCIP' ? 'srcip' : 'dstip';
    const ip = srcip || dstip;

    const propertiesTrayData = [
      {
        title: `${ipTtype} — ${ip}`,
        dataType: PropertiesTray.DataTypes.field,
        context: ContextTypes.flow,
        field: ipTtype,
        value: ip,
        customer,
      },
    ];

    setPropertiesTray({
      data: propertiesTrayData,
      isOpen: true,
    });
  }, []);

  const onChartContextMenu = useCallback((event) => {
    const { target } = event;
    const { srcip, dstip, iptype, customer } =
      target.parentNode?.parentNode?.dataset || {};
    if (!srcip && !dstip) {
      return;
    }

    const ipTtype = iptype === 'SRCIP' ? 'srcip' : 'dstip';
    const ip = srcip || dstip;

    const globalContextMenuData = [
      {
        title: `${ipTtype} — ${ip}`,
        dataType: PropertiesTray.DataTypes.field,
        context: ContextTypes.flow,
        field: ipTtype,
        value: ip,
        customer,
      },
    ];

    setGlobalContextMenu({
      data: globalContextMenuData,
      event,
    });
  }, []);

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return undefined;
    }
    chart.container?.addEventListener('contextmenu', onChartContextMenu);
    return () => {
      chart.container?.removeEventListener('contextmenu', onChartContextMenu);
    };
  }, [chartRef.current, onChartContextMenu]);

  return (
    <HSX.HighmapsProvider Highcharts={Highmaps}>
      <HSX.HighchartsMapChart
        {...defaultProps}
        className={classNames(
          className,
          'block-worldmap-chart',
          `p-${colorVariety ?? defaultColorVariety}`,
          {
            'block-worldmap-chart__with-title': !!title && !hideTitle,
            short: width > 360 && height < 180,
            ultrashort: width <= 360 && height < 180,
          },
        )}
        map={geojson}
        colorAxis={{
          min: 1,
          max: 1000,
        }}
        chart={{
          ...defaultProps.chart,
          panning: {
            enabled: true,
            type: 'xy',
          },
          alignTicks: false,
          events: {
            click: chartClickEvent,
          },
        }}
        callback={onChartCallback}
        __colorVariety={colorVariety}
      >
        {!!title && !hideTitle && (
          <HSX.Title align="left" useHTML>
            {title}
          </HSX.Title>
        )}

        {!!subtitle && !hideSubtitle && (
          <HSX.Subtitle useHTML>{subtitle}</HSX.Subtitle>
        )}

        <HSX.Legend enabled={false} />

        <HSX.Tooltip
          useHTML
          borderRadius={8}
          shadow={false}
          animation={false}
        />

        <HSX.MapNavigation buttonOptions={{ alignTo: 'spacingBox' }}>
          <HSX.MapNavigation.ZoomIn />
          <HSX.MapNavigation.ZoomOut />
        </HSX.MapNavigation>

        <HSX.MapSeries
          name="Basemap"
          enableMouseTracking={false}
          visible
          data={mapSeriesData}
          joinBy={mapSeriesJoinBy}
          nullColor={normalizedSeries.length ? '#ccc' : 'transparent'}
          borderColor={normalizedSeries.length ? '#eee' : '#767676'}
          animation={false}
          reflow={false}
          shadow={false}
        />

        {normalizedSeries.length ? (
          <HSX.MapPointSeries
            name="Geo IPs"
            data={normalizedSeries}
            type="mappoint"
            colorKey="color"
            dataLabels={dataLabelProps}
            marker={markerProps}
            point={pointProps}
            tooltip={tooltipProps}
            animation={false}
            visible
          />
        ) : (
          // We need this fake series to display axis when there is no data
          // @see: https://netography.atlassian.net/browse/PORTAL-1336
          <NoData $plotBox={chartRef.current?.plotBox}>
            {loading ? lang.loading : lang.noData}
          </NoData>
        )}
      </HSX.HighchartsMapChart>
    </HSX.HighmapsProvider>
  );
})`
  position: relative;

  .highcharts-map-navigation {
    font-size: 1.3em !important;
    font-weight: bold !important;
    cursor: pointer !important;

    &.highcharts-zoom-in {
      text {
        transform: translateX(4px) !important;
      }
    }

    &.highcharts-zoom-out {
      text {
        transform: translateX(6px) !important;
      }
    }
  }

  .highcharts-mappoint-series .highcharts-point {
    cursor: pointer;
  }

  .highcharts-tooltip {
    pointer-events: auto; // we need this for links work in map tooltip
  }

  .tooltip-data {
    display: flex;
    flex-direction: column;
  }

  .tooltip-item {
    display: flex;
    align-items: center;

    .tooltip-item__name {
      font-weight: bold;
    }

    .tooltip-item__value {
      display: flex;
      align-items: center;
      span:empty {
        display: none;
      }
      img {
        margin: auto 0;
      }
      img + span,
      span + span {
        margin-left: 5px;
      }
    }

    .tooltip-item__name + .tooltip-item__value {
      margin-left: 5px;
    }
  }

  .label-series__body {
    cursor: pointer;
  }
`;

BlockWorldMap.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  className: PropTypes.string,
  /**
   * Chart title.
   */
  title: PropTypes.string,
  /**
   * Chart subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * Chart series.
   */
  series: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * If true, loading overlay will be active.
   */
  loading: PropTypes.bool,
  /**
   * Chart width.
   */
  width: PropTypes.number,
  /**
   * Chart height.
   */
  height: PropTypes.number,
  /**
   * Series color palette variety.
   */
  colorVariety: PropTypes.number,
  /**
   * If true, exporting mode on.
   */
  exporting: PropTypes.bool,
  /**
   * If true, expired blocks will be displayed.
   */
  showExpired: PropTypes.bool,
  /**
   * If true, chart title will be hidden.
   */
  hideTitle: PropTypes.bool,
  /**
   * If true, chart subtitle will be hidden.
   */
  hideSubtitle: PropTypes.bool,
};

BlockWorldMap.defaultProps = {
  className: undefined,
  title: undefined,
  subtitle: undefined,
  series: [],
  loading: false,
  width: undefined,
  height: undefined,
  colorVariety: defaultColorVariety,
  exporting: true,
  showExpired: false,
  hideTitle: false,
  hideSubtitle: false,
};

export default memo(BlockWorldMap);
