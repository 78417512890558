import { getSecurity } from '../AttackSurface/util';

export const sumFields = [
  'bits',
  'bitsxrate',
  'duration',
  'flowbrate',
  'flowprate',
  'packets',
  'packetsxrate',
];

const ipNodesResolver = (prev, arr) => {
  const nodes = { ...(prev?.nodes || {}) };
  const links = { ...(prev?.links || {}) };
  const linksDirections = { ...(prev?.linksDirections || {}) };

  let hasNew = false;

  (arr || []).forEach((item) => {
    const { srcip, dstip, flowsrcname, flowsrcip, customer } = item;

    const srcLabels = {};
    const dstLabels = {};

    Object.entries(item.label?.ip || {}).forEach(([context, directionsObj]) => {
      Object.entries(directionsObj).forEach(([direction, labels]) => {
        if (direction === 'src') {
          srcLabels[context] = labels;
        } else {
          dstLabels[context] = labels;
        }
      });
    });

    let source = nodes[srcip];
    if (!source) {
      hasNew = true;
      source = {
        id: srcip,
        name: srcip,
        group: flowsrcname,
        flowsrcname,
        flowsrcip,
        ip: srcip,
        ips: new Set([srcip]),
        fromCount: 0,
        toCount: 0,
        type: 'ip',
        customer,
      };
      nodes[srcip] = source;
    }
    Object.assign(source, { ipLabels: srcLabels });

    let target = nodes[dstip];
    if (!target) {
      hasNew = true;
      target = {
        id: dstip,
        name: dstip,
        group: flowsrcname,
        flowsrcname,
        flowsrcip,
        ip: dstip,
        ips: new Set([dstip]),
        fromCount: 0,
        toCount: 0,
        type: 'ip',
        customer,
      };
      nodes[dstip] = target;
    }
    Object.assign(target, { ipLabels: dstLabels });

    sumFields.forEach((key) => {
      source[key] = (source[key] ?? 0) + (item[key] ?? 0);
    });
    sumFields.forEach((key) => {
      target[key] = (target[key] ?? 0) + (item[key] ?? 0);
    });

    const linkKey = `${srcip}-${dstip}`;

    let link = links[linkKey];
    if (!link) {
      hasNew = true;

      const dirKey = [source.id, target.id].sort().join('-');

      const direction = !linksDirections[dirKey];

      link = {
        source: source.id,
        target: target.id,
        eventsCount: 0,
        direction,
      };

      target.toCount += 1;
      source.fromCount += 1;
      links[linkKey] = link;

      linksDirections[dirKey] = 1;
    }

    link.eventsCount += 1;

    sumFields.forEach((key) => {
      link[key] = (link[key] ?? 0) + (item[key] ?? 0);
    });
  });

  return hasNew ? { nodes, links, linksDirections } : prev;
};

const ipPortNodesResolver = (prev, arr) => {
  const nodes = { ...(prev?.nodes || {}) };
  const links = { ...(prev?.links || {}) };
  const linksDirections = { ...(prev?.linksDirections || {}) };

  let hasNew = false;

  (arr || []).forEach((item) => {
    const {
      srcip,
      dstip,
      srcport,
      dstport,
      flowsrcname,
      flowsrcip,
      protocol,
      customer,
    } = item;

    const srcIpLabels = {};
    const dstIpLabels = {};

    Object.entries(item.label?.ip || {}).forEach(([context, directionsObj]) => {
      Object.entries(directionsObj).forEach(([direction, labels]) => {
        if (direction === 'src') {
          srcIpLabels[context] = labels;
        } else {
          dstIpLabels[context] = labels;
        }
      });
    });

    const srcPortLabels = {};
    const dstPortLabels = {};

    Object.entries(item.label?.port || {}).forEach(
      ([context, directionsObj]) => {
        Object.entries(directionsObj).forEach(([direction, labels]) => {
          if (direction === 'src') {
            srcPortLabels[context] = labels;
          } else {
            dstPortLabels[context] = labels;
          }
        });
      },
    );

    [
      {
        sourceKey: srcip,
        sourceData: {
          name: srcip,
          group: flowsrcname,
          flowsrcname,
          flowsrcip,
          ip: srcip,
          ips: new Set([srcip]),
          type: 'ip',
          customer,
        },
        sourceDataUpdate: { ipLabels: srcIpLabels },
        targetKey: `${srcip}-${srcport}`,
        targetData: {
          name: srcport,
          group: flowsrcname,
          flowsrcname,
          flowsrcip,
          ip: srcip,
          ips: new Set([srcip]),
          port: srcport,
          protocol,
          type: 'port',
          customer,
        },
        targetDataUpdate: {
          ipLabels: srcIpLabels,
          portLabels: srcPortLabels,
        },
        linkLevel: 1,
      },
      {
        sourceKey: `${dstip}-${dstport}`,
        sourceData: {
          name: dstport,
          group: flowsrcname,
          flowsrcname,
          flowsrcip,
          ip: dstip,
          ips: new Set([dstip]),
          port: dstport,
          protocol,
          type: 'port',
          customer,
        },
        sourceDataUpdate: {
          ipLabels: dstIpLabels,
          portLabels: dstPortLabels,
        },
        targetKey: dstip,
        targetData: {
          name: dstip,
          group: flowsrcname,
          flowsrcname,
          flowsrcip,
          ip: dstip,
          ips: new Set([dstip]),
          type: 'ip',
          customer,
        },
        targetDataUpdate: {
          ipLabels: dstIpLabels,
        },
        linkLevel: 1,
      },
      {
        sourceKey: `${srcip}-${srcport}`,
        targetKey: `${dstip}-${dstport}`,
      },
    ].forEach((pair) => {
      const {
        sourceKey,
        sourceData,
        sourceDataUpdate,
        targetKey,
        targetData,
        targetDataUpdate,
        linkLevel,
      } = pair;

      let source = nodes[sourceKey];
      if (!source) {
        hasNew = true;
        source = {
          ...sourceData,
          fromCount: 0,
          toCount: 0,
          id: sourceKey,
        };
        nodes[sourceKey] = source;
      }
      Object.assign(source, sourceDataUpdate || {});

      let target = nodes[targetKey];
      if (!target) {
        hasNew = true;
        target = {
          ...targetData,
          fromCount: 0,
          toCount: 0,
          id: targetKey,
        };
        nodes[targetKey] = target;
      }
      Object.assign(target, targetDataUpdate || {});

      sumFields.forEach((key) => {
        source[key] = (source[key] ?? 0) + (item[key] ?? 0);
      });
      sumFields.forEach((key) => {
        target[key] = (target[key] ?? 0) + (item[key] ?? 0);
      });

      const linkKey = `${sourceKey}-${targetKey}`;

      let link = links[linkKey];
      if (!link) {
        hasNew = true;

        const dirKey = [source.id, target.id].sort().join('-');

        const direction = !linksDirections[dirKey];

        link = {
          source: source.id,
          target: target.id,
          eventsCount: 0,
          level: linkLevel ?? 1,
          direction,
        };
        target.toCount += 1;
        source.fromCount += 1;
        links[linkKey] = link;

        linksDirections[dirKey] = 1;
      }

      link.eventsCount += 1;

      sumFields.forEach((key) => {
        link[key] = (link[key] ?? 0) + (item[key] ?? 0);
      });
    });
  });

  return hasNew ? { nodes, links, linksDirections } : prev;
};

const ipLabelsNodesResolver = (context) => (prev, arr) => {
  const nodes = { ...(prev?.nodes || {}) };
  const links = { ...(prev?.links || {}) };
  const linksDirections = { ...(prev?.linksDirections || {}) };

  let hasNew = false;

  (arr || []).forEach((item) => {
    const { srcip, dstip, flowsrcname, flowsrcip, customer } = item;

    const { src, dst } = item.label?.ip?.[context] || {};

    const srcLabels = { [context]: src };
    const dstLabels = { [context]: dst };

    const srcItems =
      src && src.length
        ? src
        : [
            {
              type: 'ip',
              toString: () => srcip,
            },
          ];
    const dstItems =
      dst && dst.length
        ? dst
        : [
            {
              type: 'ip',
              toString: () => dstip,
            },
          ];

    srcItems.forEach((srcItem) => {
      dstItems.forEach((dstItem) => {
        const sourceKey = `${srcItem || ''}`;
        const targetKey = `${dstItem || ''}`;

        let source = nodes[sourceKey];
        if (!source) {
          hasNew = true;
          source = {
            id: sourceKey,
            name: sourceKey,
            group: context,
            ips: new Set([srcip]),
            fromCount: 0,
            toCount: 0,
            type: srcItem.type || 'label',
          };

          if (source.type === 'ip') {
            Object.assign(source, {
              flowsrcname,
              flowsrcip,
              ip: srcip,
            });
          } else {
            source.ipLabels = {
              [context]: [sourceKey],
            };
          }

          nodes[sourceKey] = source;
        }

        source.customer = customer;
        source.ips.add(srcip);
        source.fromCount = source.ips.size;

        if (source.type === 'ip') {
          Object.assign(source, { ipLabels: srcLabels });
        }

        let target = nodes[targetKey];
        if (!target) {
          hasNew = true;
          target = {
            id: targetKey,
            name: targetKey,
            group: context,
            ips: new Set([dstip]),
            fromCount: 0,
            toCount: 0,
            type: dstItem.type || 'label',
          };

          if (target.type === 'ip') {
            Object.assign(target, {
              flowsrcname,
              flowsrcip,
              ip: dstip,
            });
          } else {
            target.ipLabels = {
              [context]: [targetKey],
            };
          }

          nodes[targetKey] = target;
        }

        target.customer = customer;
        target.ips.add(dstip);
        target.fromCount = target.ips.size;

        if (target.type === 'ip') {
          Object.assign(target, { ipLabels: dstLabels });
        }

        sumFields.forEach((key) => {
          source[key] = (source[key] ?? 0) + (item[key] ?? 0);
        });
        sumFields.forEach((key) => {
          target[key] = (target[key] ?? 0) + (item[key] ?? 0);
        });

        const linkKey = `${source.id}-${target.id}`;

        let link = links[linkKey];
        if (!link) {
          hasNew = true;

          const dirKey = [source.id, target.id].sort().join('-');

          const direction = !linksDirections[dirKey];

          link = {
            source: source.id,
            target: target.id,
            eventsCount: 0,
            direction,
          };

          links[linkKey] = link;

          linksDirections[dirKey] = 1;
        }

        link.eventsCount += 1;

        sumFields.forEach((key) => {
          link[key] = (link[key] ?? 0) + (item[key] ?? 0);
        });
      });
    });
  });

  return hasNew ? { nodes, links, linksDirections } : prev;
};

const ipParticleResolver = (arr) =>
  (arr || []).map((item) => {
    const { srcip, dstip, dstport, protocol, customer } = item;

    const particle = {
      source: srcip,
      target: dstip,
      severity: getSecurity(`${dstport}:${protocol}`),
      customer,
    };

    sumFields.forEach((key) => {
      particle[key] = item[key];
    });

    return particle;
  });

const ipPortParticleResolver = (arr) =>
  (arr || []).map((item) => {
    const { srcip, dstip, srcport, dstport, protocol, customer } = item;

    const particle = {
      source: `${srcip}-${srcport}`,
      target: `${dstip}-${dstport}`,
      severity: getSecurity(`${dstport}:${protocol}`),
      customer,
    };

    sumFields.forEach((key) => {
      particle[key] = item[key] ?? 0;
    });

    return particle;
  });

const ipLabelsParticleResolver = (context) => (arr) => {
  const particles = [];

  (arr || []).forEach((item) => {
    const { srcip, dstip, dstport, protocol, customer } = item;

    const { src, dst } = item.label?.ip?.[context] || {};

    const srcItems = src && src.length ? src : [srcip];
    const dstItems = dst && dst.length ? dst : [dstip];

    srcItems.forEach((srcItem) => {
      dstItems.forEach((dstItem) => {
        const particle = {
          source: srcItem,
          target: dstItem,
          severity: getSecurity(`${dstport}:${protocol}`),
          customer,
        };

        sumFields.forEach((key) => {
          particle[key] = item[key] ?? 0;
        });

        particles.push(particle);
      });
    });
  });

  return particles;
};

// const ipPortParticleResolver = (arr) => (arr || []).flatMap((item) => {
//   const { srcip, dstip, srcport, dstport } = item;
//
//   const items = [];
//
//   let particle = {
//     source: `${srcip}-${srcport}`,
//     target: `${dstip}-${dstport}`,
//   };
//
//   fields.forEach((key) => {
//     particle[key] = item[key];
//   });
//
//   items.push(particle);
//
//   particle = {
//     source: srcip,
//     target: `${srcip}-${srcport}`,
//   };
//
//   sumFields.forEach((key) => {
//     particle[key] = item[key];
//   });
//
//   items.push(particle);
//
//   particle = {
//     source: `${dstip}-${dstport}`,
//     target: dstip,
//   };
//
//   sumFields.forEach((key) => {
//     particle[key] = item[key];
//   });
//
//   items.push(particle);
//
//   return items;
// });

export default {
  ip: {
    key: 'ip',
    nodes: ipNodesResolver,
    particles: ipParticleResolver,
  },
  ipPort: {
    key: 'ipPort',
    nodes: ipPortNodesResolver,
    particles: ipPortParticleResolver,
  },
  ipLabels: {
    key: 'ipLabels',
    nodes: ipLabelsNodesResolver,
    particles: ipLabelsParticleResolver,
  },
};
