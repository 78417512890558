import PropTypes from '+prop-types';
import { useMemo } from 'react';

import CellWrapper from './CellWrapper';

// Generic Context Menu for IP Addresses
const CellPortLabel = (props) => {
  const {
    field,
    value,
    portFieldName,
    portFieldValue,
    original,
    disabled,
    useDataValueInPropertiesTray,
  } = props;

  const propertiesTrayData = useMemo(() => {
    if (!useDataValueInPropertiesTray) {
      return {
        customer: original?.customer,
        field,
        value,
      };
    }
    const stream = {
      srcip: original?.srcip,
      srcport: original?.srcport,
      dstip: original?.dstip,
      dstport: original?.dstport,
      protocol: original?.protocol,
    };
    return {
      customer: original?.customer,
      field: portFieldName,
      value: portFieldValue,
      stream,
    };
  }, [
    useDataValueInPropertiesTray,
    field,
    value,
    portFieldName,
    portFieldValue,
    original,
  ]);

  return (
    <CellWrapper {...propertiesTrayData} disabled={disabled} hideUnderline>
      <span>{value}</span>
    </CellWrapper>
  );
};

CellPortLabel.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  portFieldName: PropTypes.string,
  portFieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
  useDataValueInPropertiesTray: PropTypes.bool,
};

CellPortLabel.defaultProps = {
  portFieldName: undefined,
  portFieldValue: undefined,
  original: undefined,
  disabled: false,
  useDataValueInPropertiesTray: false,
};

export default CellPortLabel;
