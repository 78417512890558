import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import MDEditor from '@uiw/react-md-editor';
import rehypeExternalLinks from 'rehype-external-links';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';

import { selectors as docsSelectors } from '@/redux/api/docs';
import { selectors as rulesSelectors } from '@/redux/api/rules';

import { usePageTabs } from '+components/PageTabs';

import CellWrapper from './CellWrapper';

const MarkdownStyling = {
  fontSize: '14px',
  backgroundColor: 'transparent',
};

const plugins = [
  [rehypeExternalLinks, { target: '_blank', rel: 'noreferrer' }],
];

const CellCaterithm = (props) => {
  const { field, value, original, renderer: Renderer, disabled } = props;
  const [, , pageTabMethods] = usePageTabs();

  const category = useSelector(rulesSelectors.getCategoryByName(value));
  const algorithm = useSelector(rulesSelectors.getAlgorithmByName(value));
  const detectionModelDoc = useSelector(
    docsSelectors.getDetectionModelDoc(value),
  );

  const item = useMemo(() => {
    if (category) {
      return {
        name: category.name,
        description: category.description,
      };
    }
    if (algorithm) {
      const path = RoutePaths.modelsDetection;
      return {
        id: algorithm.id,
        name: algorithm.name,
        description: algorithm.description,
        type: 'algorithm',
        info: detectionModelDoc?.body,
        model:
          algorithm.algo_type === 'CDM'
            ? RoutePaths.modelsContext.pageName
            : path.pageName,
      };
    }
    // this is possible when category or algorithm was removed but it still in policy config
    return {
      name: value,
      info: detectionModelDoc?.body,
    };
  }, [value, category, algorithm, detectionModelDoc?.body]);

  const additionalMenuOptions = useMemo(
    () =>
      item.id && item.type === 'algorithm'
        ? [
            {
              icon: <LeadPencilIcon />,
              text: 'Edit',
              onClick: () => {
                pageTabMethods.add(
                  `${RoutePaths.models}/${item.model}/${item.id}`,
                );
              },
            },
          ]
        : [],
    [item.id, item.type, item.model],
  );

  return value === 'all' ? null : (
    <Renderer
      header={
        <CellWrapper
          customer={original?.customer}
          field={field}
          value={value}
          info={
            detectionModelDoc?.body && (
              <MDEditor.Markdown
                source={detectionModelDoc?.body}
                fullscreen
                style={MarkdownStyling}
                rehypePlugins={plugins}
              />
            )
          }
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
        >
          <span>{item.name}</span>
        </CellWrapper>
      }
      subHeader={item.description}
    />
  );
};

CellCaterithm.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  original: PropTypes.shape(),
  renderer: PropTypes.children.isRequired,
  disabled: PropTypes.bool,
};
CellCaterithm.defaultProps = {
  field: null,
  value: null,
  original: null,
  disabled: false,
};

export default CellCaterithm;
