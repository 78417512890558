import styled, { css } from 'styled-components';

import { Col, Row } from '+components/Layout';
import { Header } from '+components/Table/Cells/HeaderSubheaderCell';
import TagOrigin from '+components/Tag';

export const BodyContainer = styled(Row)`
  transition:
    opacity 0.3s,
    max-height 0.3s,
    flex 0.3s;
  opacity: 0;
  max-height: 0;
  flex: 0;

  ${(props) =>
    props.$isOpen &&
    css`
      padding-top: 2px;
      padding-bottom: ${props.$padding};
      opacity: 1;
      max-height: 1000000px;
      flex: 1;
    `}
  &:empty {
    visibility: hidden;
  }
`;

export const BodySeparator = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colorBackgroundSeparator};
`;

export const BodyGroupCol = styled(Col).attrs({
  gap: '4px',
})`
  ${Header} {
    font-size: 12px;
  }
`;

export const BodyGroupTitleRow = styled(Row)`
  font-weight: bold;
`;

export const BodyItemRow = styled(Row).attrs({
  wrap: 'nowrap',
})``;

export const BodyNameCol = styled(Col)`
  font-size: 12px;
`;

export const BodyValueCol = styled(Col)`
  font-size: 12px;
  word-break: break-all;
  overflow: hidden;
  ${(props) =>
    props.$preLine &&
    css`
      white-space: pre-line;
      overflow-wrap: break-word;
    `}
  &:empty:before {
    content: '—';
  }

  & > [class^='CellWrapper__'] {
    width: 100%;
    ${(props) =>
      props.$whiteSpace &&
      css`
        white-space: ${props.$whiteSpace};
      `}
`;

export const BodyTag = styled(TagOrigin)`
  width: fit-content;
`;

export const BodyCountryCode = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
