import PropTypes from '+prop-types';

import ReactJsonView from '@uiw/react-json-view';
import { githubLightTheme } from '@uiw/react-json-view/githubLight';
import { nordTheme } from '@uiw/react-json-view/nord';
import { TriangleSolidArrow } from '@uiw/react-json-view/triangle-solid-arrow';
import copy from 'copy-to-clipboard';
import styled, { withTheme } from 'styled-components';

const commonTheme = {
  // fontSize: '12px',
  '--w-rjv-background-color': 'transparent',
  '--w-rjv-font-family': "'Source Code Pro', monospace",
};

const themes = {
  dark: {
    ...nordTheme,
    ...commonTheme,
  },
  light: {
    ...githubLightTheme,
    ...commonTheme,
  },
};

const StyledJsonView = styled(ReactJsonView)`
  .w-rjv-colon {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
`;

const handleCopy = (_, value) => copy(JSON.stringify(value, null, 2));

const JsonView = ({ theme, rowData, ...rest }) => (
  <StyledJsonView
    value={rowData}
    displayDataTypes={false}
    indentWidth={20}
    collapsed={false}
    style={themes[theme.name]}
    onCopied={handleCopy}
    {...rest}
  >
    <ReactJsonView.Arrow>
      <TriangleSolidArrow />
    </ReactJsonView.Arrow>
  </StyledJsonView>
);

JsonView.propTypes = {
  theme: PropTypes.shape().isRequired,
  rowData: PropTypes.shape().isRequired,
};

export default withTheme(JsonView);
