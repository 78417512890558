import { put, select } from 'redux-saga/effects';

import {
  awaiters,
  createSelector,
  createSlice,
  fail,
  pseudoTtlCache,
  startFetching,
  stopFetching,
  tryCancelSaga,
} from '@/redux/util';

const initialState = {
  isFetching: false,
  tables: {},
};

const ttl = 6e6; // 10 minutes

const apply = (state, data) => {
  if (!data) {
    return;
  }

  data = Array.isArray(data) ? data : [data];

  data.forEach((item) => {
    if (!item || item.tableId == null) {
      return;
    }

    if (!state.tables[item.tableId]) {
      pseudoTtlCache.setWithTtl(state.tables, item.tableId, {}, ttl);
    }

    if (item.id != null) {
      state.tables[item.tableId][item.id] = item;
    }
  });
};

const getUrl = (part) => `/table-setting${part ? `/${part}` : ''}`;

const slice = createSlice({
  name: 'tableSettings',
  initialState,
  reducers: {
    fetch: startFetching,
    fetchSuccess: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      stopFetching(state);
      delete state.lastAdded;
      apply(state, data);
    },
    create: startFetching,
    createSuccess: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      stopFetching(state);
      state.lastAdded = data;
      apply(state, data);
    },
    update: startFetching,
    remove: startFetching,
    removeSuccess: (state, { payload: { id, tableId } }) => {
      stopFetching(state);
      if (state.tables[tableId]) {
        delete state.tables[tableId][id];
        pseudoTtlCache.removeTtl(state.tables[tableId], id);
      }
    },
    toggleDefault: startFetching,
    cancel: stopFetching,
    skip: stopFetching,
    fail,
  },
  sagas: (actions, selectors) => ({
    *[actions.fetch]({ payload }) {
      const fullPath = getUrl('');
      const path = getUrl(encodeURIComponent(payload || ''));

      const { tables } = yield select(selectors.getState);

      const isInCache = payload && pseudoTtlCache.isValid(tables, payload);

      if (isInCache || awaiters.has(fullPath) || awaiters.has(path)) {
        yield put(actions.skip());
        return;
      }

      awaiters.add(path);

      yield tryCancelSaga(
        'get',
        {
          *success(result) {
            // need to prevent unnecessary request to server
            // if for a tableId we don't have record.
            if (payload && !result?.data?.data.length) {
              result.data = result.data || {};
              result.data.data = [{ tableId: payload }];
            }
            yield put(actions.fetchSuccess(result));
          },
          errorAction: actions.fail,
          toasts: {
            error: 'Error fetching table configuration',
          },
        },
        path,
      );

      awaiters.delete(path);
    },
    *[actions.create]({ payload }) {
      yield tryCancelSaga(
        'post',
        {
          successAction: actions.createSuccess,
          errorAction: actions.fail,
          toasts: {
            success: `Table configuration '${payload.title}' has been saved`,
            error: 'Error adding table configuration',
          },
        },
        getUrl(''),
        payload,
      );
    },
    *[actions.update]({ payload: { id, ...payload } }) {
      yield tryCancelSaga(
        'put',
        {
          successAction: actions.fetchSuccess,
          errorAction: actions.fail,
          toasts: {
            success: `Table configuration '${payload.title}' has been updated`,
            error: 'Error updating table configuration',
          },
        },
        getUrl(`${id}`),
        payload,
      );
    },
    *[actions.remove]({ payload }) {
      yield tryCancelSaga(
        'delete',
        {
          *success({
            data: {
              data: { id, tableId },
            },
          }) {
            yield put(actions.removeSuccess({ id, tableId }));
          },
          errorAction: actions.fail,
          toasts: {
            success: 'Table configuration has been deleted',
            error: 'Error deleting table configuration',
          },
        },
        getUrl(`${payload}`),
      );
    },
    *[actions.toggleDefault]({ payload: { id, isDefault } }) {
      yield tryCancelSaga(
        'post',
        {
          successAction: actions.fetchSuccess,
          errorAction: actions.fail,
          toasts: {
            success: `Table configuration has been ${
              isDefault ? '' : 'un'
            }set as default`,
            error: 'Error updating table configuration',
          },
        },
        getUrl(`${isDefault ? '' : 'un'}set-default/${id}`),
      );
    },
  }),
  selectors: (getState) => ({
    getPresets: (tableId) =>
      createSelector(getState, (state) => state?.tables?.[tableId] || {}),
  }),
});

export const { actions, selectors } = slice;
export default slice;
