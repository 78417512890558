import { emptyGroupKey } from '+components/Table/ReactTable/utils';

const getIsEmptyGroup = (row) => {
  if (!row?.isGrouped || row?.groupByVal === undefined) {
    return false;
  }
  return Array.isArray(row.groupByVal)
    ? !row.groupByVal.length
    : row.groupByVal === emptyGroupKey;
};

const useInstance = (instance) => {
  const {
    getSubRows,
    groupedRows: groupedRowsProp,
    rows: rowsProp,
    state: { groupBy },
  } = instance;

  if (groupBy?.length > 1) {
    return;
  }

  const emptyRowIndex = rowsProp.findIndex(getIsEmptyGroup);
  if (emptyRowIndex === -1) {
    return;
  }

  const groupedRows = groupedRowsProp.filter((row) => !getIsEmptyGroup(row));

  const rows = [
    ...rowsProp.slice(0, emptyRowIndex),
    ...rowsProp.slice(emptyRowIndex + 1),
    ...(getSubRows(rowsProp[emptyRowIndex]) || []).map((row) => ({
      ...row,
      isFromEmptyGroup: true,
    })),
  ];

  Object.assign(instance, {
    groupedRows,
    rows,
  });
};

export const useGroupByHideEmptyGroups = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useGroupByHideEmptyGroups.pluginName = 'useGroupByHideEmptyGroups';
