import { useSelector } from 'react-redux';

import { selectors } from '@/redux/api/allowlists';

/**
 * Returns allowLists.
 * @return {Object}
 */
export const useAllowlists = () => {
  // const dispatch = useDispatch();

  const allowlists = useSelector(selectors.getAllowlists);
  const isAllowlistsFetching = useSelector(selectors.isFetching);

  // TODO: uncomment if we need to return back allowlists
  // const allowlistLength = Object.keys(allowlists || {}).length;
  // useEffect(() => {
  //   if (allowlistLength) {
  //     return;
  //   }
  //   dispatch(allowlistsActions.fetchAllowlists());
  // }, [allowlistLength]);

  return { allowlists, isAllowlistsFetching };
};

export default useAllowlists;
