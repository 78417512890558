import styled, { css } from 'styled-components';

import { printStyle } from '+components/charts/common/utils';

export default styled.div`
  ${(props) =>
    props.$exportMode &&
    css`
      ${printStyle};
      width: calc(297mm - 2 * 5mm);
    `}
`;
