import styled from 'styled-components';

import MultiDropdownFieldOrigin from '+components/form/MultiDropdownField';

import { DropdownMixin } from './DropdownMixin';

const AdditionalFiltersMultiDropdownCaptureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0;
  overflow: hidden;
  &:only-child {
    margin-top: unset;
    * {
      max-height: unset;
    }
  }
`;

const AdditionalFiltersMultiDropdownCaptureLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  max-height: 14px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const AdditionalFiltersMultiDropdownCaptureValue = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  white-space: nowrap;
  user-select: none;
  text-transform: none;
  max-height: 14px;
  font-weight: normal !important;
`;

const AdditionalFiltersMultiDropdownShowMore = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  white-space: nowrap;
  border-radius: 10px;
  color: ${({ theme }) => theme.colorFieldBackground} !important;
  background-color: ${({ theme }) => theme.colorText};
  height: 12px;
  font-size: 11px;
  font-weight: 600;
`;

const AdditionalFiltersMultiDropdownField = styled(
  MultiDropdownFieldOrigin,
).attrs((props) => ({
  $dirty: props.meta?.dirty,
  menuProps: {
    PaperProps: {
      style: {
        transform: 'translate(10px, 4px)',
      },
    },
    style: {
      zIndex: 9999999,
    },
  },
}))`
  ${DropdownMixin};
`;

export {
  AdditionalFiltersMultiDropdownCaptureContainer,
  AdditionalFiltersMultiDropdownCaptureLabel,
  AdditionalFiltersMultiDropdownCaptureValue,
  AdditionalFiltersMultiDropdownShowMore,
};
export default AdditionalFiltersMultiDropdownField;
