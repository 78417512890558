import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  apiKeys: null, // {},
  lastApiKey: {},
};

const apiPath = '/api-keys';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'apiKeys',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchApiKeys: startFetching,
    fetchApiKeysSuccess(state, { payload: data }) {
      stopFetching(state);
      state.apiKeys = (data || []).reduce((acc, item) => {
        acc[item.appname] = item;
        return acc;
      }, {});
    },

    fetchApiKey: startFetching,
    fetchApiKeySuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.apiKeys) {
        state.apiKeys = {};
      }
      state.apiKeys[data.appname] = data;
    },

    createApiKey: startFetching,
    createApiKeySuccess(state, { payload: { appkey, ...data } }) {
      stopFetching(state);
      if (!state.apiKeys) {
        state.apiKeys = {};
      }
      state.apiKeys[data.appname] = data;
      state.lastApiKey = { appname: data.appname, appkey };
    },

    updateApiKey: startFetching,
    updateApiKeySuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.apiKeys) {
        state.apiKeys = {};
      }
      state.apiKeys[data.appname] = data;
    },

    removeApiKey: startFetching,
    removeApiKeySuccess(state, { payload: appname }) {
      stopFetching(state);
      delete state.apiKeys?.[appname];
    },

    regenApiKey: startFetching,
    regenApiKeySuccess(state, { payload: { appkey, ...data } }) {
      stopFetching(state);
      state.lastApiKey = { appname: data.appname, appkey };
    },

    clearApiKeyKey: (state) => {
      stopFetching(state);
      state.lastApiKey = {};
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchApiKeys]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, apiPath);
          yield put(actions.fetchApiKeysSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching API keys',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchApiKey]: {
      *saga({ payload: appname }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${appname}`);
          yield put(actions.fetchApiKeySuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching API key',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createApiKey]: {
      *saga({ payload: apiKey }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, apiKey);
          yield put(actions.createApiKeySuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'API key has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating API key',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateApiKey]: {
      *saga({ payload: apiKey }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${apiKey.appname}`,
            apiKey,
          );
          yield put(actions.updateApiKeySuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'API key has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating API key',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeApiKey]: {
      *saga({ payload: appname }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${appname}`);
          yield put(actions.removeApiKeySuccess(appname));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'API key has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting API key',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.regenApiKey]: {
      *saga({ payload: appname }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${appname}/regen`);
          yield put(actions.regenApiKeySuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'API key has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating API key',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getApiKeys: createSelector([getState], (state) => state.apiKeys),

    getApiKey: (appname) =>
      createSelector([getState], (state) => state.apiKeys?.[appname]),

    getLastApiKey: createSelector([getState], (state) => state.lastApiKey),
  }),
});

export const { actions, selectors } = slice;

export default slice;
