import { put } from 'redux-saga/effects';

import {
  createSelector,
  createSlice,
  fail,
  startFetching,
  stopFetching,
  takeLeading,
  tryCancelSaga,
} from '@/redux/util';

export const TagsEntityTypes = {
  flow: 'flow',
  device: 'device',
  vpc: 'vpc',
};

const initialState = {
  flowTags: {},
};

const urlList = (url, full, origin) => {
  const search = new URLSearchParams();
  if (full) {
    search.append('terse', '0');
  }

  if (origin) {
    search.append('origin', '1');
  }

  return `${url}?${search.toString()}`;
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    fetchList: startFetching,
    fetchListSuccess: (
      state,
      {
        payload: {
          entity,
          data: { data },
        },
      },
    ) => {
      stopFetching(state);
      state[`${entity}TagsList`] = data || [];
    },

    // flow
    fetchFlowTags: startFetching,
    fetchFlowTagsSuccess: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      stopFetching(state);
      state.flowTags = (data || []).reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {},
      );
    },

    fetchFlowTagById: startFetching,
    flowTagsByIdSuccess: (
      state,
      {
        payload: {
          data: {
            data: [data],
          },
        },
      },
    ) => {
      stopFetching(state);

      if (!data) {
        return;
      }

      state.flowTags[data.id] = data;
    },

    addFlowTag: startFetching,
    updateFlowTag: startFetching,
    deleteFlowTag: startFetching,
    bulkDeleteTags: startFetching,

    deleteFlowTagSuccess: (state, { payload: id }) => {
      stopFetching(state);
      delete state.flowTags[id];
    },

    bulkDeleteTagsSuccess: (state, { payload: ids }) => {
      stopFetching(state);
      ids.forEach((id) => delete state.flowTags[id]);
    },

    testRegexFlowTags: (state) => {
      state.testRegexResult = null;
      state.testRegexError = '';
      startFetching(state);
    },
    testRegexSuccess: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      state.testRegexResult = data;
      stopFetching(state);
    },
    testRegexFail: (state, { payload: { message } }) => {
      state.testRegexError = message;
      stopFetching(state);
    },
    // end flow

    skip: stopFetching,
    cancel: stopFetching,
    fail,
  },

  sagas: (actions) => ({
    [actions.fetchList]: {
      *saga({ payload }) {
        let params = payload;
        if (typeof params === 'string') {
          params = [params];
        } else {
          params = [params.listOfObject];
        }

        const entity = params[0];

        yield tryCancelSaga(
          'get',
          {
            *success({ data }) {
              yield put(actions.fetchListSuccess({ entity, data }));
            },
            errorAction: actions.fail,
            toasts: {
              error: `Error fetching ${entity} tags`,
            },
          },
          urlList(`/tags/${entity}`, ...params),
        );
      },
    },

    // flow
    [actions.fetchFlowTags]: {
      taker: takeLeading(actions.skip),
      *saga() {
        yield tryCancelSaga(
          'get',
          {
            successAction: actions.fetchFlowTagsSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error fetching flow tags',
            },
          },
          urlList(`/tags/${TagsEntityTypes.flow}`, true, true),
        );
      },
    },

    [actions.fetchFlowTagById]: {
      *saga({ payload: id }) {
        yield tryCancelSaga(
          'get',
          {
            successAction: actions.flowTagsByIdSuccess,
            errorAction: actions.fail,
            toasts: {
              error: 'Error fetching flow tag',
            },
          },
          `/tag/${TagsEntityTypes.flow}/${id}`,
        );
      },
    },

    [actions.addFlowTag]: {
      *saga({ payload }) {
        yield tryCancelSaga(
          'post',
          {
            successAction: actions.flowTagsByIdSuccess,
            errorAction: actions.fail,
            toasts: {
              success: 'Flow tag has been added',
              error: 'Error adding flow tag',
              withAuditLogVerification: true,
            },
          },
          `/tag/${TagsEntityTypes.flow}`,
          payload,
        );
      },
    },

    [actions.updateFlowTag]: {
      *saga({ payload: { id, ...payload } }) {
        yield tryCancelSaga(
          'put',
          {
            successAction: actions.flowTagsByIdSuccess,
            errorAction: actions.fail,
            toasts: {
              success: 'Flow tag has been updated',
              error: 'Error updating flow tag',
              withAuditLogVerification: true,
            },
          },
          `/tag/${TagsEntityTypes.flow}/${id}`,
          payload,
        );
      },
    },

    [actions.deleteFlowTag]: {
      *saga({ payload: id }) {
        yield tryCancelSaga(
          'delete',
          {
            *success() {
              yield put(actions.deleteFlowTagSuccess(id));
            },
            errorAction: actions.fail,
            toasts: {
              success: 'Flow tag has been removed',
              error: 'Error deleting flow tag',
              withAuditLogVerification: true,
            },
          },
          `/tag/${TagsEntityTypes.flow}/${id}`,
        );
      },
    },

    [actions.bulkDeleteTags]: {
      *saga({ payload: ids }) {
        yield tryCancelSaga(
          'put',
          {
            *success() {
              yield put(actions.bulkDeleteTagsSuccess(ids));
            },
            errorAction: actions.fail,
            toasts: {
              success: 'Flow tags have been removed',
              error: 'Error deleting flow tags',
              withAuditLogVerification: true,
            },
          },
          `/tags/${TagsEntityTypes.flow}/bulkdelete`,
          { ids },
        );
      },
    },

    [actions.testRegexFlowTags]: {
      *saga({ payload }) {
        yield tryCancelSaga(
          'post',
          {
            successAction: actions.testRegexSuccess,
            errorAction: actions.testRegexFail,
            toasts: {
              success: 'Test Regex has been success',
              error: 'Error testing regex',
            },
          },
          '/tag/flow/regextest',
          payload,
        );
      },
    },
  }),

  selectors: (getState) => ({
    getTagsList: (entity) =>
      createSelector(getState, (state) => state?.[`${entity}TagsList`] ?? []),
  }),
});

export const { actions, selectors } = slice;
export default slice;
