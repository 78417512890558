import PropTypes from '+prop-types';
import { memo, useCallback, useMemo } from 'react';

import styled from 'styled-components';

import PaginationOrigin from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { defaultNumberFormatter } from '+components/charts/common/formatters';
import { propsSelectors as themeTable } from '+theme/slices/table';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px;
  position: relative;
  justify-content: space-between;
  background: ${themeTable.colorPaginationBackground};
  border-top: 1px solid ${themeTable.colorPaginationBorder};
`;

const PaginationStyled = styled(PaginationOrigin)`
  li + li {
    margin-left: 7px;
  }

  .MuiPaginationItem-previousNext {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border-radius: 16px;
    margin: unset;
    padding: unset;

    color: #999999 !important;
    background: ${themeTable.colorPaginationButtonBackground} !important;

    .MuiSvgIcon-fontSizeMedium {
      font-size: 16px;
    }

    &:hover {
      background: ${({ theme }) => theme.primary} !important;
      svg {
        fill: black;
      }
    }
  }

  .MuiPaginationItem-page {
    min-width: 24px;
    max-width: 34px;
    min-height: 24px;
    max-height: 24px;

    font-size: 0.875em;
    color: #999999 !important;
    background: none !important;

    &:hover {
      color: ${({ theme }) => theme.primary} !important;
    }

    &.Mui-selected {
      pointer-events: none;
      color: ${({ theme }) => theme.colorText} !important;
    }
  }
`;

const Info = styled.div`
  color: #999999;
  font-size: 12px;
  z-index: 1;

  ${({ $invisible }) =>
    $invisible &&
    `
    opacity: 0;
    pointer-events: none;
  `};

  select {
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 4px;
    font-weight: normal;
    outline: none;
    color: ${({ theme }) => theme.colorText};
    border: 1px solid
      ${({ theme }) =>
        theme.colorTool.alpha(
          theme.colorTool.negate(
            themeTable.colorPaginationButtonBackground({ theme }),
          ),
          0.1,
        )};
    background: ${themeTable.colorPaginationButtonBackground};

    &:hover,
    &:focus,
    &:active {
      border-color: ${({ theme }) =>
        theme.colorTool.alpha(
          theme.colorTool.negate(
            themeTable.colorPaginationButtonBackground({ theme }),
          ),
          0.3,
        )};
    }
  }
`;

const InfoSpace = styled(Info)`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 0;
  pointer-events: none;
  margin: 0 1ch;
`;

const Pagination = (props) => {
  const {
    pageIndex,
    pageCount,
    pageSize,
    boundaryCount,
    rowsCount,
    gotoPage,
    setPageSize,
    pageSizeOptions,
    showPageSizeOptions,
  } = props;

  const currentPage = useMemo(() => pageIndex + 1, [pageIndex]);

  const onPageSizeChange = useCallback(
    (event) => {
      event.preventDefault();
      setPageSize(Number(event.target.value));
    },
    [setPageSize],
  );

  const onChange = useCallback(
    (_, page) => {
      gotoPage(Math.max(page - 1, 0));
    },
    [gotoPage],
  );

  const showing =
    pageSize * pageIndex > rowsCount ? 0 : pageSize * pageIndex + !!rowsCount;

  const to =
    pageSize * currentPage > rowsCount ? rowsCount : pageSize * currentPage;

  return (
    <Container className="pagination-container" data-testid="pagination">
      <PaginationStyled
        count={pageCount}
        page={currentPage < pageCount ? currentPage : pageCount}
        boundaryCount={boundaryCount}
        size="small"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            page={item.page ? defaultNumberFormatter(item.page) : item.page}
          />
        )}
        onChange={onChange}
      />

      <InfoSpace>
        <span>
          Showing {defaultNumberFormatter(showing)} to{' '}
          {defaultNumberFormatter(to)} of {defaultNumberFormatter(rowsCount)}
        </span>
      </InfoSpace>

      {showPageSizeOptions && !!(pageSizeOptions || []).length && (
        <Info>
          <select
            onChange={onPageSizeChange}
            value={pageSize}
            data-testid="pagination-page-size"
          >
            {pageSizeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </Info>
      )}
    </Container>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  showPageSizeOptions: PropTypes.bool,
  rowsCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  boundaryCount: PropTypes.number,
};

Pagination.defaultProps = {
  pageSizeOptions: [],
  showPageSizeOptions: true,
  rowsCount: 0,
  pageIndex: 1,
  pageSize: 10,
  boundaryCount: 1,
};

export default memo(Pagination);
