import { Route, Routes } from 'react-router-dom';

import ResponsePolicies from '@/pages/ResponsePolicies';
import ResponsePoliciesAdd from '@/pages/ResponsePolicies/Add';
import ResponsePoliciesEdit from '@/pages/ResponsePolicies/Edit';

import { CrumbRoute } from '+components/Breadcrumb';

const ResponsePoliciesRoutes = () => (
  <Routes>
    <Route
      path="add"
      element={
        <CrumbRoute
          title="Add Response Policy"
          component={ResponsePoliciesAdd}
        />
      }
    />
    <Route
      path=":ruleId/*"
      element={
        <CrumbRoute
          title="Edit Response Policy"
          component={ResponsePoliciesEdit}
        />
      }
    />
    <Route path="*" element={<ResponsePolicies />} />
  </Routes>
);

export default ResponsePoliciesRoutes;
