import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as usersActions,
  selectors as usersSelectors,
} from '@/redux/api/user';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import NoData from '@/pages/Dashboards/Dashboard/components/NoData';

import UserProfile from '+components/UserProfile';

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();

  const user = useSelector(usersSelectors.getUser(userId)) || {};
  const otpDevices = useSelector(usersSelectors.getOtpDevices(userId));
  const sessions = useSelector(usersSelectors.getSessions(userId));

  const isCurrentCustomerFetching = useSelector(customerSelectors.isFetching);
  const isProfileFetching = useSelector(profileSelectors.isFetching);
  const isUsersFetching = useSelector(usersSelectors.isFetching);

  const isFetching =
    isCurrentCustomerFetching || isProfileFetching || isUsersFetching;

  const pageTitle = `User — ${user?.name || user?.email || 'Unknown'}`;

  const onSendVerificationEmail = useCallback(() => {
    dispatch(usersActions.sendVerificationEmail(user?.id));
  }, [user?.id]);

  const onSendChangePasswordEmail = useCallback(() => {
    dispatch(usersActions.sendChangePasswordEmail(user?.id));
  }, [user?.id]);

  const onUpdate = useCallback((values) => {
    dispatch(usersActions.updateUser(values));
  }, []);

  const onSessions = useCallback(() => {
    dispatch(usersActions.requestSessions(user?.id));
  }, [user?.id]);

  const onOtpDevices = useCallback(() => {
    dispatch(usersActions.requestOtpDevices(user?.id));
  }, [user?.id]);

  const onOtpEnable = useCallback(() => {
    dispatch(usersActions.enableOtp(user?.id));
  }, [user?.id]);

  const onOtpDisable = useCallback(() => {
    dispatch(usersActions.disableOtp(user?.id));
  }, [user?.id]);

  const onOtpEmailEnable = useCallback(() => {
    dispatch(
      usersActions.toggleOtpEmail({
        userId: user?.id,
        on: true,
      }),
    );
  }, [user?.id]);

  const onOtpEmailDisable = useCallback(() => {
    dispatch(
      usersActions.toggleOtpEmail({
        userId: user?.id,
        on: false,
      }),
    );
  }, [user?.id]);

  // const onOtpDeviceAdd = useCallback(
  //   () => {
  //     dispatch(usersActions.requestOtpDeviceConfiguration(user?.id));
  //   },
  //   [user?.id],
  // );

  // const onOtpDeviceDelete = useCallback(
  //   (deviceId) => {
  //     if (deviceId === 'new') {
  //       dispatch(usersActions.cancelOtpDeviceConfigurationRequest(user?.id));
  //     } else {
  //       dispatch(usersActions.deleteOtpDevice(deviceId));
  //     }
  //   },
  //   [user?.id],
  // );

  const onOtpDevicesReset = useCallback(() => {
    dispatch(usersActions.resetOtpDevices(user?.id));
  }, [user?.id]);

  const onLogout = useCallback(() => {
    dispatch(usersActions.logoutUser(user?.id));
  }, [user?.id]);

  const onLogoutFromCustomer = useCallback(() => {
    dispatch(usersActions.logoutUserFromCustomer(user?.id));
  }, [user?.id]);

  const onBlock = useCallback(() => {
    dispatch(usersActions.blockUser(user?.id));
  }, [user?.id]);

  const onUnblock = useCallback(() => {
    dispatch(usersActions.unblockUser(user?.id));
  }, [user?.id]);

  const onDelete = useCallback(() => {
    dispatch(usersActions.deleteUser(user?.id));
    navigate(`${RoutePaths.users}`);
  }, [user?.id]);

  const onImpersonate = useCallback(() => {
    dispatch(usersActions.impersonate(user?.id));
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      return;
    }
    dispatch(
      usersActions.requestUser({
        id: userId,
        silent: true,
      }),
    );
  }, [user?.id, userId]);

  if (!user?.id) {
    return isFetching ? null : <NoData>User not found.</NoData>;
  }

  return (
    <UserProfile
      user={user}
      otpDevices={otpDevices}
      sessions={sessions}
      isFetching={isFetching}
      onSendVerificationEmail={onSendVerificationEmail}
      onSendChangePasswordEmail={onSendChangePasswordEmail}
      onSessions={onSessions}
      onOtpDevices={onOtpDevices}
      onOtpEnable={onOtpEnable}
      onOtpDisable={onOtpDisable}
      onOtpEmailEnable={onOtpEmailEnable}
      onOtpEmailDisable={onOtpEmailDisable}
      // onOtpDeviceAdd={onOtpDeviceAdd}
      // onOtpDeviceDelete={onOtpDeviceDelete}
      onOtpDevicesReset={onOtpDevicesReset}
      onImpersonate={onImpersonate}
      onLogout={onLogout}
      onLogoutFromCustomer={onLogoutFromCustomer}
      onBlock={onBlock}
      onUnblock={onUnblock}
      onDelete={onDelete}
      onUpdate={onUpdate}
      breadcrumbData={{ title: pageTitle }}
    />
  );
};

export default User;
