import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions, selectors } from '@/redux/api/labels/ports';

import makeArr from '+utils/makeArr';

/**
 * Returns port labels.
 * @param {Object?} options
 * @param {boolean?} options.fetchAll
 * @param {string|string[]?} options.fetchExact
 * @return {Object}
 */
export const usePortLabels = (options) => {
  const { fetchAll = false, fetchExact = null } = options || {};

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const portLabels = useSelector(selectors.getPortLabels);
  const portLabelsHash = useSelector(selectors.getPortLabelsHash);
  const isFetchingPortLabels = useSelector(selectors.isFetching);

  useEffect(() => {
    const isSubAccountRequest =
      options?.customer && options?.customer !== customer?.shortname;
    const fetchExactArr = makeArr(fetchExact).filter(Boolean);
    fetchExactArr.forEach((port) => {
      if (isSubAccountRequest) {
        const namespace = `usePortLabels - ${port} - ${options?.customer}`;
        dispatch(
          actions.fetchPortLabelsByPort(
            { port, customer: options?.customer },
            namespace,
          ),
        );
      } else {
        const namespace = `usePortLabels - ${port}`;
        dispatch(actions.fetchPortLabelsByPort({ port }, namespace));
      }
    });
  }, [fetchExact, options?.customer, customer?.shortname]);

  useEffect(() => {
    if (!fetchAll) {
      return undefined;
    }
    const namespace = 'usePortLabels';
    dispatch(actions.fetchPortLabels(null, namespace));
    return () => {
      dispatch(actions.cancel(namespace));
    };
  }, [fetchAll]);

  return { portLabels, portLabelsHash, isFetchingPortLabels };
};

export default usePortLabels;
