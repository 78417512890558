import PropTypes from '+prop-types';
import { Fragment, useCallback } from 'react';
import { useForm } from 'react-final-form';

import styled from 'styled-components';

import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';

import { Field } from '+components/form/FinalForm';
import FormField from '+components/form/FormField';
import NumberField from '+components/form/NumberField';
import TextField from '+components/form/TextField';
import { ToggleField as ToggleFieldOrigin } from '+components/form/Toggle';
import { validateRequired } from '+components/form/Validators';
import { Col, Row } from '+components/Layout';
import Tooltip from '+components/Tooltip';

const SystemPolicy = styled((props) => {
  return (
    <Tooltip title="This field has a system enforced minimum and can't be disabled.">
      <span {...props}>
        <InformationOutlineIcon size={16} />
      </span>
    </Tooltip>
  );
})`
  cursor: pointer;
  margin-left: 4px;
  .mdi-icon {
    margin-bottom: 1px;
  }
`;

const ToggleField = styled(ToggleFieldOrigin)`
  margin-top: 4px;
  width: unset;
`;

const BooleanField = styled(FormField).attrs({
  className: 'boolean',
  children: [],
})``;

const parser = (input) => input.replace(/[^\d]/g, '');

const getFieldComponent = ({ type }) => {
  switch (type) {
    case 'number':
      return NumberField;
    case 'boolean':
      return BooleanField;
    default:
      return TextField;
  }
};

const getFieldAdditionalProps = ({ type, minValue }) => {
  switch (type) {
    case 'number':
      return { step: 1, min: minValue ?? 1, precision: 0, parser };
    case 'boolean':
      return { readOnly: true };
    default:
      return {};
  }
};

const RenderPasswordPolicy = styled(({ className, fields, disabled }) => {
  const form = useForm();

  const onPolicyToggle = useCallback(
    (policy) => (toggleValue) => {
      if (policy.type === 'boolean') {
        form.change(policy.name, toggleValue);
      } else {
        form.change(policy.name, toggleValue ? policy.defaultValue : null);
      }
    },
    [form],
  );

  return (
    <Col className={className} gap="8px">
      {fields.map((name, index) => {
        const policy = fields.value[index];
        const label = (
          <Fragment>
            {policy.label}
            {policy.system && <SystemPolicy />}
          </Fragment>
        );
        const helperText = (
          <Fragment>
            {policy.description ? `${policy.description}. ` : ''}
            {/* eslint-disable-next-line no-nested-ternary */}
            {policy.system
              ? `Must be equal or greater than ${policy.minValue}.`
              : policy.defaultValue
                ? `Default value is ${policy.defaultValue}.`
                : ''}
          </Fragment>
        );
        return (
          <Row key={name} gap="10px" alignItems="flex-start" wrap="nowrap">
            <Field
              name={`${name}.value`}
              label={label}
              component={getFieldComponent(policy)}
              helperText={helperText}
              validate={policy.enabled && validateRequired}
              required={policy.enabled}
              disabled={disabled || !policy.enabled}
              {...getFieldAdditionalProps(policy)}
            />

            <Field
              name={`${name}.enabled`}
              component={ToggleField}
              type="checkbox"
              disabled={disabled || policy.system}
              onChange={onPolicyToggle({ ...policy, name: `${name}.value` })}
            />
          </Row>
        );
      })}
    </Col>
  );
})`
  --labelWidth: 150px;

  .form__form-group-label {
    width: var(--labelWidth) !important;
    &.required :after {
      display: none;
    }
  }
  .form__form-group-field {
    width: calc(100% - var(--labelWidth)) !important;
    &:empty {
      display: none;
    }
  }
  .form__form-group-description {
    margin-left: calc(20px + var(--labelWidth)) !important;
  }

  .boolean {
    .form__form-group-description {
      padding-left: 20px;
      align-self: center;
      justify-self: center;
      margin: 2px 0 0 0 !important;
    }
  }
`;

RenderPasswordPolicy.propTypes = {
  fields: PropTypes.shape().isRequired,
};

export default RenderPasswordPolicy;
