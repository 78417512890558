import styled from 'styled-components';

import CloseButton from './CloseButton';
import Title from './Title';
import UnpinButton from './UnpinButton';

export default styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 30px;
  gap: 10px;
  padding: 0 10px;
  border-radius: 16px;
  width: 221px;
  max-width: 221px;
  min-width: 40px;
  background-color: ${({ theme }) => theme.pageTabBackground};
  overflow: hidden;
  cursor: default;

  ${Title}, ${CloseButton}, ${UnpinButton} {
    color: ${({ theme }) => theme.pageTabColor};
  }

  ${CloseButton} {
    display: none;
  }

  &.isContextMenuOpen,
  &:hover {
    background-color: ${({ theme }) => theme.pageTabBackgroundHover};

    ${Title}, ${CloseButton}, ${UnpinButton} {
      color: ${({ theme }) => theme.pageTabColorHover};
    }

    ${CloseButton} {
      display: flex;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.active {
    position: relative;
    background-color: ${({ theme }) =>
      theme.pageTabBackgroundActive} !important;

    ${Title}, ${CloseButton}, ${UnpinButton} {
      z-index: 1;
      color: ${({ theme }) => theme.pageTabColorActive} !important;
    }

    ${CloseButton} {
      display: flex;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: background-color 0.3s ease-in-out;
    }

    &.isNew {
      ${Title}, ${CloseButton}, ${UnpinButton} {
        color: ${({ theme }) => theme.pageTabColorNew} !important;
      }
      &:before {
        background-color: ${({ theme }) =>
          theme.pageTabBackgroundNew} !important;
      }
    }
  }

  &.isItemDragging:not(.active) {
    background-color: ${({ theme }) => theme.pageTabBackgroundHover} !important;
  }

  &.isNavDragging:not(.isItemDragging):not(.active):hover {
    background-color: unset;
  }
`;
