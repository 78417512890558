import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/tag';

import { usePageTabs } from '+components/PageTabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

import FlowTagForm from './FlowTagForm';

const FlowTagAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, activePageTab] = usePageTabs();

  const { isFetching, error } = useSelector(selectors.getState);

  const [defaultFlowTags] = useState({
    tags: [],
  });
  const [inProgress, setInProgress] = useState(false);

  useLoadingIndicator(isFetching);

  const onSubmit = useCallback(
    (values) => {
      setInProgress(activePageTab?.id);
      dispatch(actions.addFlowTag(values));
    },
    [activePageTab?.id],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.flowTags}`);
  }, []);

  useEffect(() => {
    if (isFetching || !inProgress) {
      return;
    }

    if (inProgress !== activePageTab?.id) {
      return;
    }

    if (error) {
      setInProgress(false);
      return;
    }

    onCancel();
  }, [isFetching, inProgress, error, activePageTab?.id, onCancel]);

  return (
    <FlowTagForm
      initialValues={defaultFlowTags}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default FlowTagAdd;
