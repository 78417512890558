import { Fragment, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import omit from 'lodash.omit';

import { CustomType } from '@/models/CustomType';
import FeatureFlags from '@/models/FeatureFlags';
import SettingCategories from '@/models/SettingCategories';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import { selectors as selectorsCustomer } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { DateTimePickerField } from '+components/form/DateTimePicker';
import { Field, useForm, useFormState } from '+components/form/FinalForm';
import {
  normalizeDateTimePickerValue,
  normalizeNumber,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateDateTime,
  validateDateTimeBetween,
  validateDateTimeCannotBeSame,
  validateMinMaxValue,
  validateRequired,
} from '+components/form/Validators';
import { NumberField } from '+components/GlobalFilters/Components';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import dayjs from '+utils/dayjs';

import { defaultDateTime } from '../../shared/propTypes';
import { widgets } from '../../shared/widgets';
import FieldsRow from '../components/FieldsRow';

const WidgetDateTimeFields = () => {
  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const series0 = formValues?.series?.[0];

  const widgetMeta = useMemo(
    () => widgets[series0?.display?.type] || {},
    [series0?.display?.type],
  );

  const retention = useSelector(selectorsCustomer.getRetention);

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const profile = useSelector(profileSelectors.getProfile);
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  /** * OPTIONS ** */
  // DateTime Options
  const dateTimeLimit = useMemo(() => {
    if (isRolesUiSettingsEnabled && userRoleUiSettings?.dateTimeLimit) {
      return Math.min(userRoleUiSettings.dateTimeLimit, retention);
    }
    return retention;
  }, [retention, isRolesUiSettingsEnabled, userRoleUiSettings?.dateTimeLimit]);

  const dateFrom = useMemo(
    () =>
      formValues.dateTime?.from ? new Date(formValues.dateTime.from) : null,
    [formValues.dateTime?.from],
  );

  const dateTo = useMemo(
    () => (formValues.dateTime?.to ? new Date(formValues.dateTime.to) : null),
    [formValues.dateTime?.to],
  );

  /** * FORM SETTINGS ** */
  const formSettings = useMemo(() => {
    const isCustomDateTimeEnabled = !formValues.useDashboardDateTime;
    const isCustomDateTimePeriodEnabled =
      isCustomDateTimeEnabled && formValues.dateTime?.periodType === CustomType;

    return {
      isCustomDateTimeEnabled,
      isCustomDateTimePeriodEnabled,
    };
  }, [
    // isCustomDateTimeEnabled
    formValues.useDashboardDateTime,
    // isCustomDateTimePeriodEnabled
    formValues.dateTime?.periodType,
  ]);

  /** * FIELD VALIDATORS ** */
  const now = new Date();
  const max = new Date(
    +now.getFullYear(),
    +now.getMonth(),
    +now.getDate(),
    23,
    59,
    59,
  );
  const min = useMemo(
    () =>
      dayjs(now - TimeDuration.day * dateTimeLimit)
        .millisecond(0)
        .toDate(),
    [dateTimeLimit],
  );

  const minPeriodValue = 1;

  const maxPeriodValue = useMemo(() => {
    if (formValues.dateTime?.periodType === CustomType) {
      return undefined;
    }
    return Math.floor(
      (dateTimeLimit * TimeDuration.day) /
        (formValues.dateTime?.periodType || 1),
    );
  }, [dateTimeLimit, formValues.dateTime?.periodType]);

  const periodOptions = useMemo(() => {
    const allowedPeriods = [
      TimeDuration.minute,
      TimeDuration.hour,
      TimeDuration.day,
      TimeDuration.week,
      TimeDuration.month,
    ];
    return [
      {
        value: CustomType,
        label: 'Custom Range',
      },
      ...allowedPeriods.map((value) => ({
        value,
        label: TimePeriods[value].name,
      })),
    ];
  }, []);

  const periodTypeValidator = useMemo(() => {
    if (formSettings.isCustomDateTimePeriodEnabled) {
      return undefined;
    }
    return [
      validateRequired,
      validateMinMaxValue({
        min: 1,
        max: maxPeriodValue,
        fieldName: 'dateTime.periodValue',
      }),
    ];
  }, [
    maxPeriodValue,
    formSettings.isCustomDateTimePeriodEnabled,
    formValues.dateTime?.periodType,
  ]);

  const fromDateValidator = useMemo(
    () => [
      validateRequired,
      validateDateTime,
      validateDateTimeCannotBeSame({
        fieldName: 'dateTime.to',
        errorMessage: 'From date and To date cannot be the same.',
      }),
      validateDateTimeBetween({
        min,
        maxFieldName: 'dateTime.to',
        includeMin: true,
        includeMax: false,
      }),
    ],
    [min],
  );

  const toDateValidator = useMemo(
    () => [
      validateRequired,
      validateDateTime,
      validateDateTimeCannotBeSame({
        fieldName: 'dateTime.from',
        errorMessage: ' ',
      }),
      validateDateTimeBetween({
        minFieldName: 'dateTime.from',
        max,
        includeMin: false,
        includeMax: true,
      }),
    ],
    // To prevent form validation looping do not put 'max' to deps (validator will update if form values changed)
    // @see: https://netography.atlassian.net/browse/PORTAL-1415
    [formValues],
  );

  /** * FIELDS VALUES NORMALIZERS ** */
  // DateTime Field
  useEffect(() => {
    // clear dateTime if it's not allowed
    if (!formSettings.isCustomDateTimeEnabled) {
      form.change('dateTime', defaultDateTime);
      return;
    }

    // clear dateTime custom period if it's not allowed
    if (!formSettings.isCustomDateTimePeriodEnabled) {
      if ('from' in formValues.dateTime || 'to' in formValues.dateTime) {
        form.change('dateTime', omit(formValues.dateTime, ['from', 'to']));
      }
    }
  }, [
    formSettings.isCustomDateTimeEnabled,
    formSettings.isCustomDateTimePeriodEnabled,
    formValues.dateTime,
  ]);

  useEffect(() => {
    // clear dateTime altogether if disabled
    if (!widgetMeta.isDateTimeEnabled) {
      form.change('dateTime', defaultDateTime);
      form.change('useDashboardDateTime', true);
    }
  }, [formValues.dateTime, widgetMeta.isDateTimeEnabled]);

  // Period Value Field
  useEffect(() => {
    if (formSettings.isCustomDateTimePeriodEnabled) {
      return;
    }
    if (formValues.dateTime?.periodType === CustomType) {
      return;
    }
    if (formValues.dateTime?.periodValue < minPeriodValue) {
      form.change('dateTime.periodValue', minPeriodValue);
      return;
    }
    if (formValues.dateTime?.periodValue > maxPeriodValue) {
      form.change('dateTime.periodValue', maxPeriodValue);
    }
  }, [
    formValues.dateTime?.periodType,
    formValues.dateTime?.periodValue,
    minPeriodValue,
    maxPeriodValue,
  ]);

  return (
    widgetMeta.isDateTimeEnabled && (
      <Fragment>
        <Field
          name="useDashboardDateTime"
          label="Date & Time"
          component={ToggleField}
          type="checkbox"
          uncheckedLabel="Inherit"
          checkedLabel="Custom"
          parse={(v) => !v}
          format={(v) => !v}
        />

        {(formSettings.isCustomDateTimeEnabled ||
          formSettings.isCustomDateTimePeriodEnabled) && (
          <Fragment>
            {formSettings.isCustomDateTimeEnabled && (
              <FieldsRow>
                <Field
                  name="dateTime.periodValue"
                  label="Period"
                  component={NumberField}
                  min={minPeriodValue}
                  max={maxPeriodValue}
                  validate={periodTypeValidator}
                  parse={normalizeNumber}
                  disabled={formSettings.isCustomDateTimePeriodEnabled}
                  required={!formSettings.isCustomDateTimePeriodEnabled}
                />

                <Field
                  name="dateTime.periodType"
                  label=" "
                  component={SelectField}
                  options={periodOptions}
                  validate={validateRequired}
                  parse={normalizeSelectValue}
                />
              </FieldsRow>
            )}

            {formSettings.isCustomDateTimePeriodEnabled && (
              <FieldsRow>
                <Field
                  name="dateTime.from"
                  label="From"
                  component={DateTimePickerField}
                  defaultValue={dateFrom}
                  min={min}
                  max={dateTo ?? now}
                  validate={fromDateValidator}
                  parse={normalizeDateTimePickerValue}
                  required
                />

                <Field
                  name="dateTime.to"
                  label="To"
                  component={DateTimePickerField}
                  defaultValue={dateTo}
                  min={dateFrom ?? min}
                  max={max}
                  validate={toDateValidator}
                  parse={normalizeDateTimePickerValue}
                  required
                />
              </FieldsRow>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  );
};

export default WidgetDateTimeFields;
