import PropTypes from '+prop-types';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import BookOpenVariantIcon from 'mdi-react/BookOpenVariantIcon';
import CheckIcon from 'mdi-react/CheckIcon';

import RoutePaths from '@/models/RoutePaths';

import Button from '+components/Button';
import { Row } from '+components/Layout';

import { AwsIcon } from '../img/icon/components/AwsIcon';
import { AzureIcon } from '../img/icon/components/AzureIcon';
import { DeviceIcon } from '../img/icon/components/DeviceIcon';
import { GcpIcon } from '../img/icon/components/GcpIcon';
import { IbmIcon } from '../img/icon/components/IbmIcon';
import { OracleIcon } from '../img/icon/components/OracleIcon';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.noDevicesWrapper};
  overflow: hidden;
  z-index: 700;
  background-color: ${(p) => p.theme.colorBackgroundBody};

  @media screen and (max-width: 1280px) {
    justify-content: flex-start;
  }
`;

const Container = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: 100%;
  margin: 5px;
  width: 260px;
  padding: 15px 20px;

  background-color: ${(props) => props.theme.cardBackgroundColor};
`;

const SecondColumnLinkContainer = styled.div`
  margin: 5px 0;
`;

const SecondColumnLink = styled.span`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;

  svg {
    margin-right: 10px;
  }
`;

const LargeTitle = styled.div`
  font-size: 30px;
  line-height: 35px;
  font-weight: 400;

  color: ${(props) => props.theme.noDevicesTitle};
`;

const SmallTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin: 15px 0;
`;

const SubTitle = styled.div`
  font-size: 13px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;

  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

const SampleText = styled.div`
  font-size: 13px;
  line-height: 32px;
  font-weight: 500;
`;

const SourcesContainer = styled.div`
  width: 850px;
  border-radius: 4px;
  text-align: center;
  margin: 0 5px 5px 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colorBackground};
`;

const SourceImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SourceContainer = styled.div`
  width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 5px;

  svg {
    width: 40px;
    height: 40px;
  }
`;

const SourceImg = styled.div`
  width: 85px;
  height: 85px;
  background-color: ${(props) => props.theme.topbarMenuBackgroundHover};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin: 7px;
`;

const DetectionsContainer = styled.div`
  width: 850px;
  border-radius: 4px;
  text-align: center;
  padding: 15px;
  margin: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.cardBackgroundColor};
`;

const DetectionColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 15px 0;
`;

const DetectionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 200px;
  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

const DetectionsLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0;
  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${(props) => props.theme.sideBarItemActiveColor};
`;

const StyledBookIcon = styled(BookOpenVariantIcon)`
  width: 22px;
  height: 22px;
  color: ${(props) => props.theme.sideBarItemActiveColor};
`;

const sources = [
  { label: 'AWS', icon: AwsIcon },
  { label: 'Azure', icon: AzureIcon },
  { label: 'GCP', icon: GcpIcon },
  { label: 'IBM', icon: IbmIcon },
  { label: 'Oracle', icon: OracleIcon },
  { label: 'Device', icon: DeviceIcon },
];

// toggleFocusBorderColor
const NoFlowHomepage = ({ className }) => {
  const navigate = useNavigate();

  return (
    <Wrapper className={className}>
      <Container>
        <FirstColumn>
          <SourcesContainer>
            <SubTitle>Setup</SubTitle>
            <LargeTitle>Get started by connecting flow logs</LargeTitle>
            <SourceImages>
              {sources.map((source) => (
                <SourceContainer key={source.label}>
                  <SourceImg>
                    <source.icon />
                  </SourceImg>
                  {source.label}
                </SourceContainer>
              ))}
            </SourceImages>
            <Button onClick={() => navigate(`${RoutePaths.sources}/add`)}>
              + Add A Traffic Source
            </Button>
          </SourcesContainer>
          <DetectionsContainer>
            <SmallTitle>
              The following real-time detections will be activated:
            </SmallTitle>
            <DetectionColumnContainer>
              <DetectionsColumn>
                <DetectionsLine>Network Obfuscation</DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  Tor
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  DNS tunneling
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  VPN
                </DetectionsLine>
              </DetectionsColumn>
              <DetectionsColumn>
                <DetectionsLine>Compromise Activity</DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  Scanning detection
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  Authentication attacks
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  Malware Beaconing
                </DetectionsLine>
              </DetectionsColumn>
              <DetectionsColumn>
                <DetectionsLine>Network Governance</DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  Cryptocurrency Activity
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  File Sharing / P2P
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  Unexpected Remote Access
                </DetectionsLine>
              </DetectionsColumn>
              <DetectionsColumn>
                <DetectionsLine>
                  Internal Infrastructure Exposure
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  SMB
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  RDP
                </DetectionsLine>
                <DetectionsLine>
                  <StyledCheckIcon />
                  SSH
                </DetectionsLine>
              </DetectionsColumn>
            </DetectionColumnContainer>
            <SampleText>
              These are just a sample of the 300+ detections that will begin on
              your traffic immediately
            </SampleText>
          </DetectionsContainer>
        </FirstColumn>
        <SecondColumn>
          <SmallTitle>Quick Start Guides</SmallTitle>
          <SecondColumnLinkContainer>
            <SecondColumnLink>
              <AwsIcon />
              <a
                href="https://docs.netography.com/docs/getting-started-with-aws"
                rel="noreferrer"
                target="_blank"
              >
                Amazon AWS Quick Start Guide
              </a>
            </SecondColumnLink>
            <SecondColumnLink>
              <GcpIcon />
              <a
                href="https://docs.netography.com/docs/getting-started-with-gcp"
                rel="noreferrer"
                target="_blank"
              >
                Google Cloud Platform Quick Start Guide
              </a>
            </SecondColumnLink>
            <SecondColumnLink>
              <AzureIcon />
              <a
                href="https://docs.netography.com/docs/getting-started-with-azure"
                rel="noreferrer"
                target="_blank"
              >
                Microsoft Azure Quick Start Guide
              </a>
            </SecondColumnLink>
            <SecondColumnLink>
              <StyledBookIcon />
              <a
                href="https://docs.netography.com/docs/quickstart"
                rel="noreferrer"
                target="_blank"
              >
                All Setup Guides
              </a>
            </SecondColumnLink>
          </SecondColumnLinkContainer>
        </SecondColumn>
      </Container>
    </Wrapper>
  );
};

NoFlowHomepage.propTypes = {
  className: PropTypes.string,
};

NoFlowHomepage.defaultProps = {
  className: null,
};

export default NoFlowHomepage;
