import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;

  &.no-data-page__loading {
    .no-data-page__image {
      background-color: transparent;
      animation: pulse 2s infinite alternate;
      animation-timing-function: cubic-bezier(1, 1.2, 0.8, 1.2);
    }
    .no-data-page__text-container {
      display: none;
      visibility: hidden;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
`;
