import PropTypes from '+prop-types';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import getBodyContainer from '+utils/getBodyContainer';

const Portal = (props) => {
  const { inBody, children } = props;

  const [container, setContainer] = useState(null);
  const [containerRefresher, setContainerRefresher] = useState(null);

  useEffect(() => {
    if (inBody) {
      setContainer(getBodyContainer());
      return undefined;
    }

    const element = document.createElement('div');
    getBodyContainer().append(element);
    setContainer(element);
    return () => {
      setContainer(null);
      element.remove();
    };
  }, [inBody, containerRefresher]);

  // We need this mostly for Toast component
  // because this component created once inside Portal and then never recreated the Portal
  useEffect(() => {
    let fullscreenchange;

    if ('onfullscreenchange' in document) {
      fullscreenchange = 'fullscreenchange';
    } else if ('onmozfullscreenchange' in document) {
      fullscreenchange = 'mozfullscreenchange';
    } else if ('onwebkitfullscreenchange' in document) {
      fullscreenchange = 'webkitfullscreenchange';
    } else if ('onmsfullscreenchange' in document) {
      fullscreenchange = 'MSFullscreenChange';
    }

    if (!fullscreenchange) {
      return undefined;
    }

    const onFullscreenChange = () => {
      setContainerRefresher(+new Date());
    };

    document.addEventListener(fullscreenchange, onFullscreenChange, true);
    return () => {
      document.removeEventListener(fullscreenchange, onFullscreenChange, true);
    };
  }, []);

  return useMemo(
    () => Boolean(container) && createPortal(children, container),
    [container, children],
  );
};

Portal.propTypes = {
  inBody: PropTypes.bool,
  children: PropTypes.children,
};

Portal.defaultProps = {
  inBody: false,
  children: null,
};

export default Portal;
