import styled from 'styled-components';

import FieldsSectionOriginal from '+components/form/FieldsSection';

const FieldsSection = styled(FieldsSectionOriginal)`
  margin-top: 24px;
  margin-bottom: unset;

  .hidden {
    visibility: hidden;
  }
`;

export default FieldsSection;
