import SigmaIcon from 'mdi-react/SigmaIcon';

import TechnicalColumns from '+components/Table/ReactTable/TechnicalColumns';

const pluginName = 'useTotalColumn';

const getHeaderProps = () => ({
  style: { borderRightWidth: 2 },
  className: 'sigma',
});
const getCellProps = () => ({ style: { justifyContent: 'center' } });
const getBorderProps = () => ({ style: { borderRightWidth: 2 } });

const totalColumn = {
  id: TechnicalColumns.totalColumn,
  disableFilters: true,
  // disableGroupBy: true,
  disableAggregators: true,
  disableDuplicateBy: true,
  // disableResizing: true,
  // disableSortBy: true,
  disableDragAndDrop: true,
  // disableContextMenu: true,
  // disableHide: true,
  // disableTooltip: true,
  // disableExport: true,
  canSort: true,
  // defaultCanFilter: true,
  getCellProps,
  getProps: getHeaderProps,
  getBorderProps,
  width: 40,
  minWidth: 40,
  // maxWidth: 40,
  Header: <SigmaIcon size={14} />,
  Description: 'Records Count In Group',
  // eslint-disable-next-line react/prop-types
  Cell: ({ row }) => (row?.leafRows?.length ? row?.leafRows.length : null),
};

const visibleColumns = (
  columns,
  {
    instance: {
      state: { groupBy },
    },
  },
) => {
  // Default visibleColumns function from useGroupBy hook >>>
  const groupByColumns = groupBy
    .map((g) => columns.find((col) => col.id === g))
    .filter(Boolean);

  const nonGroupByColumns = columns.filter((col) => !groupBy.includes(col.id));

  // This is the change >>>
  columns.forEach((column) => {
    if ('originalGetProps' in column) {
      column.getProps = column.originalGetProps;
      delete column.originalGetProps;
    }
    if ('originalGetBorderProps' in column) {
      column.getBorderProps = column.originalGetBorderProps;
      delete column.originalGetBorderProps;
    }
  });

  if (groupByColumns?.length) {
    const last = groupByColumns[groupByColumns.length - 1];
    last.originalGetProps = last.getProps;
    last.originalGetBorderProps = last.getBorderProps;
    const originalProps = last.originalGetProps?.();
    const originalBorderProps = last.originalGetBorderProps?.();
    last.getProps = () => ({
      ...(originalProps || {}),
      style: {
        ...originalProps?.style,
        ...getHeaderProps().style,
      },
    });
    last.getBorderProps = () => ({
      ...(originalBorderProps || {}),
      style: {
        ...originalBorderProps?.style,
        ...getBorderProps().style,
      },
    });
  }
  // <<< This is the change

  columns = [
    ...groupByColumns,
    ...(!groupByColumns?.length ? [] : [totalColumn]), // <<< This is the change
    ...nonGroupByColumns,
  ];

  columns.forEach((column) => {
    column.isGrouped = groupBy.includes(column.id);
    column.groupedIndex = groupBy.indexOf(column.id);
  });
  // Default visibleColumns function from useGroupBy hook <<<

  return columns;
};

export const useTotalColumn = (hooks) => {
  hooks.visibleColumns.push(visibleColumns);
};

useTotalColumn.pluginName = pluginName;
