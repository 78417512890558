import PropTypes from '+prop-types';
import { Fragment, useCallback, useContext } from 'react';

import styled from 'styled-components';

import CheckboxOrigin from '@mui/material/Checkbox';
import DividerOrigin from '@mui/material/Divider';
import ListSubheaderOrigin from '@mui/material/ListSubheader';
import MenuItemOrigin from '@mui/material/MenuItem';

import CheckIcon from 'mdi-react/CheckIcon';

import ColorTypes from '@/models/ColorTypes';

import { propsSelectors as globalFiltersTheme } from '+theme/slices/globalFilters';

import { Context } from './Context';

const Header = styled(ListSubheaderOrigin)`
  position: unset;
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  padding: ${(p) => p.$menuItemPadding};
  color: ${({ theme }) => theme.colorTextSecondary};
  background: unset;
  height: 24px;
  line-height: unset;
  text-transform: uppercase;

  &:empty {
    display: none;
  }
`;

const Divider = styled(DividerOrigin)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  margin: 2px 0 !important;
`;

const Checkbox = styled(CheckboxOrigin).attrs({
  disableRipple: true,
  checkedIcon: <CheckIcon />,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  icon: <Fragment />,
})`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.checkboxBorderColor};
  background-color: ${({ theme }) => theme.colorFieldBackground};
  overflow: hidden;

  input,
  .MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorFieldBackground};
    border-color: ${({ theme }) => theme.checkboxBorderColor};
  }
`;

const Label = styled.span`
  flex-shrink: 0;
  white-space: nowrap;
`;

const Description = styled.span`
  flex-shrink: 1;
  color: ${({ theme }) => theme.colorTextSecondary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 30vw;
`;

const MenuItem = styled(MenuItemOrigin)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  padding: ${(p) => p.$menuItemPadding};
  color: ${({ theme }) => theme.colorText};

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  overflow: hidden;

  &:hover {
    background: ${globalFiltersTheme.selectOptionFocused} !important;
    color: ${({ theme }) => theme.colorFieldBackground};
    text-shadow: 0 0 1px ${({ theme }) => theme.colorFieldBackground};
    opacity: 1;
    ${Label}, ${Description} {
      color: inherit;
      text-shadow: inherit;
      opacity: inherit;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  &.Mui-selected {
    background: ${globalFiltersTheme.selectOptionSelected};
  }
`;

const Item = (props) => {
  const {
    onClick,
    header,
    divider,
    color,
    short,
    hideOnClick,
    testId,
    ...rest
  } = props;
  const { hide } = useContext(Context);
  const menuItemPadding = short
    ? '2px 15px !important;'
    : '6px 15px !important;';

  const doClick = useCallback(
    (event) => {
      if (hideOnClick) {
        hide();
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [hideOnClick, onClick],
  );

  if (header) {
    return (
      <Header
        color={color || 'default'}
        {...rest}
        $menuItemPadding={menuItemPadding}
      />
    );
  }

  if (divider) {
    return <Divider sx={{ my: 0.5 }} {...rest} />;
  }

  return (
    <MenuItem
      {...rest}
      $color={color}
      onClick={doClick}
      $menuItemPadding={menuItemPadding}
      data-testid={testId}
    />
  );
};

Item.propTypes = {
  onClick: PropTypes.func,
  header: PropTypes.bool,
  divider: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(ColorTypes)),
  short: PropTypes.bool,
  selected: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  testId: PropTypes.string,
};

Item.defaultProps = {
  onClick: null,
  header: false,
  divider: false,
  color: null,
  short: false,
  selected: false,
  hideOnClick: true,
  testId: null,
};

export { Header, Divider, Checkbox, Label, Description };
export default Item;
