import { useCallback, useState } from 'react';

import { scrollToChildIndex as scrollToChildIndexOrigin } from '+utils/dom';

const homeEnd = ['Home', 'End'];

/**
 * @callback ScrollToChildIndex
 * @param {HTMLElement} listContainer
 * @param {number} index - index of listContainer child.
 * @param {string} position - one of 'start' | 'center' | 'end' | 'nearest'
 */

/**
 * @param {HTMLElement} listContainer
 * @param {object?} options
 * @param {number} [options.pageSize=10] - page size for PageUp and PageDown events.
 * @param {boolean} [options.skipHomeEnd=false] - skip pressing Home and End keys.
 * @param {ScrollToChildIndex} [options.scrollToChildIndex=+utils/dom/scrollToChildIndex] - function to scrolling to child by its index.
 * @return {{onKeyDown: function, focusIndex: number, setFocusIndex: function }}
 */
export const useListKeyboardNavigation = (listContainer, options) => {
  const [focusIndex, setFocusIndex] = useState(0);

  const {
    pageSize = 10,
    skipHomeEnd = false,
    scrollToChildIndex = scrollToChildIndexOrigin,
  } = options || {};

  const onKeyDown = useCallback(
    (event) => {
      const length = listContainer?.children?.length || 0;
      if (length < 1) {
        return event;
      }

      if (skipHomeEnd && homeEnd.includes(event.key)) {
        return event;
      }

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();

          setFocusIndex((prev) => {
            const next = (+prev + 1) % length;
            scrollToChildIndex(listContainer, next);
            return next;
          });
          break;
        case 'ArrowUp':
          event.preventDefault();

          setFocusIndex((prev) => {
            const next = (+prev < 1 ? length : +prev) - 1;
            scrollToChildIndex(listContainer, next);
            return next;
          });
          break;

        case 'PageDown':
          event.preventDefault();

          setFocusIndex((prev) => {
            const next = Math.min(length - 1, Math.floor(+prev + pageSize));
            scrollToChildIndex(listContainer, next, 'start');
            return next;
          });
          break;
        case 'PageUp':
          event.preventDefault();

          setFocusIndex((prev) => {
            const next = Math.max(0, Math.floor(+prev - pageSize));
            scrollToChildIndex(listContainer, next, 'start');
            return next;
          });
          break;

        case 'Home':
          event.preventDefault();

          setFocusIndex(0);
          scrollToChildIndex(listContainer, 0, 'start');
          break;
        case 'End':
          event.preventDefault();

          setFocusIndex(length - 1);
          scrollToChildIndex(listContainer, length - 1, 'start');
          break;
        default:
          break;
      }

      return event;
    },
    [pageSize, listContainer],
  );

  return {
    focusIndex,
    setFocusIndex,
    onKeyDown,
  };
};
