import classNames from 'classnames';
import styled from 'styled-components';

import { BaseColumnFactory } from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import Tag from '+components/Tag';

const StepCell = styled(({ className, status, step, description }) => (
  <div className={classNames(className, { 'no-status': !status })}>
    <span className="step-cell__name" title={description}>
      {step}
    </span>
  </div>
))`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 100%;
  white-space: normal;

  &.no-status {
    opacity: 0.4;
  }

  .step-cell__name {
    color: ${(p) => p.theme.colorTextSecondary} !important;
  }
`;

const MessageCell = styled.div.attrs((props) => ({
  ...props,
  className: classNames(props.className, 'message-cell'),
}))`
  padding: 10px;
  white-space: normal;
  word-break: break-word;
`;

const getColumns = () => {
  return [
    BaseColumnFactory({
      accessor: 'lastPolled',
      Header: 'Last Polled',
      maxWidth: 200,
    }),
    BaseColumnFactory({
      id: 'step',
      Header: 'Step',
      maxWidth: 400,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        const original = getRowOriginal(row);
        return <StepCell {...original} />;
      },
    }),
    BaseColumnFactory({
      accessor: 'status',
      Header: 'Status',
      maxWidth: 150,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        if (!value) {
          return '';
        }

        let color;
        switch (value) {
          case 'pass':
            color = 'success';
            break;
          case 'fail':
            color = 'danger';
            break;
          case 'no flow':
          case 'no messages':
            color = 'warning';
            break;
          default:
            break;
        }

        return (
          <Tag color={color} outlined={false}>
            {value}
          </Tag>
        );
      },
    }),
    BaseColumnFactory({
      accessor: 'error',
      Header: 'Message',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <MessageCell>{value || ''}</MessageCell>,
    }),
  ];
};

export default getColumns;
