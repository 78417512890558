import PropTypes from '+prop-types';
import { useCallback } from 'react';

import Button, { ButtonVariants } from '+components/Button';
import ButtonGroup from '+components/ButtonGroup';
import FormField from '+components/form/FormField';

import createDescriptionLinks from './createDescriptionLinks';

const ButtonGroupField = (props) => {
  const {
    options,
    input,
    label,
    helperText,
    meta: { touched, error, dirty, submitFailed },
    disabled,
    required,
    parseDescriptionUrls,
    ...tail
  } = props;

  const invalid = error && (dirty || submitFailed) && touched;

  const onOptionClick = useCallback(
    (value) => () => {
      input.onChange(value);
    },
    [],
  );

  return (
    <FormField
      label={label}
      helperText={
        parseDescriptionUrls ? createDescriptionLinks(helperText) : helperText
      }
      error={error}
      invalid={invalid}
      disabled={disabled}
      required={required}
    >
      <ButtonGroup {...tail} disabled={disabled}>
        {options.map((option) => (
          <Button
            key={option.value}
            style={{
              pointerEvents: input.value === option.value ? 'none' : null,
            }}
            variant={
              input.value === option.value
                ? ButtonVariants.contained
                : ButtonVariants.outlined
            }
            disabled={option.disabled}
            onClick={onOptionClick(option.value)}
            data-tracking={`filter-option-${option.value}`}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </FormField>
  );
};

ButtonGroupField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  parseDescriptionUrls: PropTypes.bool,
};

ButtonGroupField.defaultProps = {
  meta: null,
  options: [],
  label: null,
  helperText: null,
  disabled: false,
  required: false,
  parseDescriptionUrls: false,
};

export default ButtonGroupField;
