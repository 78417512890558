import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as globalFiltersActions,
  selectors as globalFiltersSelectors,
} from '@/redux/globalFilters';

import { usePageTabs } from '+components/PageTabs';

/**
 * Hook to use global filter's property and callback to change it.
 *
 * @param {string} property of global filters
 * @param {*} [defaultValue] of the property
 * @return {Array} Array of `[filters[property], changePropertyValueFunction]`
 */
export const useGlobalFiltersProperty = (property, defaultValue) => {
  if (!property) {
    throw new Error('property is required');
  }

  const dispatch = useDispatch();
  const [, activePageTab] = usePageTabs();
  const allFilters = useSelector(
    globalFiltersSelectors.getTabFilters(activePageTab?.id),
  );
  const prop = useRef();
  prop.current = property;

  const change = useCallback((filterPropValue) => {
    dispatch(
      globalFiltersActions.changeFilter({ [prop.current]: filterPropValue }),
    );
  }, []);

  return [allFilters[property] ?? defaultValue, change];
};

export default useGlobalFiltersProperty;
