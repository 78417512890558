/* eslint-disable react/prop-types */
import { useMemo } from 'react';

import PopperOrigin from '@mui/material/Popper';

const Popper = (props) => {
  const { anchorEl, children, style, disablePortal, popperStyle, ...tail } =
    props;

  const anchorRect = useMemo(
    () => anchorEl?.getBoundingClientRect(),
    [anchorEl],
  );

  const fixedStyle = useMemo(
    () => ({
      zIndex: 9999,
      ...style,
      width: anchorRect.width,
      ...popperStyle,
    }),
    [anchorRect.width, style, popperStyle],
  );

  return (
    <PopperOrigin
      {...tail}
      anchorEl={anchorEl}
      style={fixedStyle}
      disablePortal={disablePortal ?? true}
    >
      {children}
    </PopperOrigin>
  );
};

export default Popper;
