import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

import { chameleon } from '+utils/chameleon';

export const initialState = {
  isFetching: false,
  error: '',
  dnsClass: {},
  isSubmitting: false, // for modals
  submitSucceeded: false, // for modals
};

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'dnsclass',
  initialState,

  reducers: {
    ...defaultReducers,
    fetch: startFetching,
    set: startFetching,
    remove: startFetching,

    success(state, { payload: { data: [item] = [] } = {} }) {
      stopFetching(state);
      state.dnsClass = item || {};
    },

    successRemove(state) {
      stopFetching(state);
      delete state.dnsClass;
    },

    resetSubmit(state) {
      state.submitSucceeded = false;
      state.isSubmitting = false;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetch]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();
        try {
          const { data } = yield call(api.get, '/dnsclasses');

          yield put(actions.success(data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching DNS Classifications',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.set]: {
      *saga({ payload: { id, ...params } }) {
        initApi();
        try {
          const url = ['/dnsclass', id].filter(Boolean).join('/');
          const response = yield call(api.put, url, params);
          yield put(actions.success(response.data));
          yield put(actions.resetSubmit());
          chameleon.sendEvent('dns_classification_updated');
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'DNS Classification has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error configuring DNS Classification',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getDnsClass: createSelector([getState], (state) => state.dnsClass),
  }),
});

// For convenience
export const { actions, selectors } = slice;

export default slice;
