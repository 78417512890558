import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import isEqual from 'lodash.isequal';

import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { incName } from '+utils/incName';

import { Provider } from './ManageColumnHeader';

const ManageColumnProvider = (props) => {
  const {
    id,
    presets,
    lastId,
    setDefaultPreset,
    saveSettings,
    defaultPreset,
    resetDefault,
    availableColumns,
    exportingCurrentView,
    exportingAllFields,
    exportData,
    isExporting,
    showManagerLayout,
    toggleDefault,
    saveConfig,
    updateConfig,
    removeConfig,
    columnsGroupBy,
    disableGroupBy,
    isColumnDragging,
    isDefaultLayout,
    ...rest
  } = props;

  const {
    user: { email, app_metadata: { shortname } = {} },
  } = useSelector(profileSelectors.getState);
  const isAdmin = useSelector(profileSelectors.isAdminUser);
  const isDefault = shortname === 'default';

  const select = useCallback(
    (key) => {
      if (!key) {
        resetDefault();
        saveSettings();
        return;
      }

      if (!id || !presets?.[key]?.value) {
        return;
      }

      setDefaultPreset(presets?.[key]?.value);

      saveSettings({ lastId: key });
    },
    [id, presets, setDefaultPreset, saveSettings],
  );

  const save = useCallback(
    (title) => {
      if (!title) {
        return;
      }

      saveConfig({
        title,
        value: { ...defaultPreset },
      });
    },
    [defaultPreset, saveConfig],
  );

  const update = useCallback(
    (key) => {
      if (!presets || !key || !presets[key]) {
        return;
      }

      updateConfig({
        ...presets[key],
        value: { ...defaultPreset },
      });
    },
    [presets, defaultPreset, updateConfig],
  );

  const updateTitle = useCallback(
    (key, title) => {
      if (!presets || !key || !title || !presets[key]) {
        return;
      }

      updateConfig({
        ...presets[key],
        title,
      });
    },
    [presets, updateConfig],
  );

  const remove = useCallback(
    (key) => {
      if (!id || !key) {
        return;
      }

      saveSettings({
        ...(lastId === key ? {} : { lastId }),
      });
      removeConfig(key);
    },
    [id, lastId, saveSettings, removeConfig],
  );

  const duplicate = useCallback(
    (key) => {
      if (!id || !presets || !key || !presets[key]) {
        return;
      }

      const { title: oldTitle } = presets[key];

      const newTitle = incName(
        oldTitle,
        Array.from(Object.values(presets).map(({ title }) => title)),
      );
      saveSettings({ lastId });

      const { id: _, ...clone } = presets[key] || {};
      saveConfig({
        ...clone,
        title: newTitle,
      });
    },
    [id, lastId, presets, saveSettings, saveConfig],
  );

  const items = useMemo(() => Object.values(presets || {}), [presets]);

  const [userItems, companyItems, systemItems] = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          let index = 1;

          if (item.system || isDefault) {
            index = 2;
          } else if (item.email === email) {
            index = 0;
          }

          acc[index].push(item);
          return acc;
        },
        [[], [], []],
      ),
    [items],
  );

  const preparedValue = {
    items,
    userItems,
    companyItems,
    systemItems,
    isDefault,
    isAdmin,
    save,
    update,
    duplicate,
    updateTitle,
    remove,
    select,
    availableColumns,
    exportingCurrentView,
    exportingAllFields,
    exportData,
    isExporting,
    showManagerLayout,
    toggleDefault,
    groupBy: columnsGroupBy,
    disableGroupBy,
    isColumnDragging,
    isDefaultLayout,
  };

  const value = useMemo(() => {
    const prev = presets?.[lastId]?.value || defaultPreset;

    const needSave = lastId && !isEqual(prev, defaultPreset);

    return {
      ...preparedValue,
      needSave,
      selected: lastId,
    };
  }, [
    lastId,
    presets?.[lastId]?.value,
    defaultPreset,
    email,
    ...Object.values(preparedValue),
  ]);

  return <Provider value={value} {...rest} />;
};

ManageColumnProvider.propTypes = {
  resetDefault: PropTypes.func.isRequired,
  setDefaultPreset: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  defaultPreset: PropTypes.shape().isRequired,
  saveConfig: PropTypes.func.isRequired,
  updateConfig: PropTypes.func.isRequired,
  removeConfig: PropTypes.func.isRequired,
  toggleDefault: PropTypes.func.isRequired,
  id: PropTypes.string,
  presets: PropTypes.shape(),
  lastId: PropTypes.string,
  availableColumns: PropTypes.arrayOf(PropTypes.shape()),
  exportingCurrentView: PropTypes.bool,
  exportingAllFields: PropTypes.bool,
  isExporting: PropTypes.bool.isRequired,
  exportData: PropTypes.func.isRequired,
  showManagerLayout: PropTypes.bool,
  columnsGroupBy: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      pattern: PropTypes.shape(),
    }),
  ),
  disableGroupBy: PropTypes.bool.isRequired,
  isColumnDragging: PropTypes.bool.isRequired,
  isDefaultLayout: PropTypes.bool.isRequired,
};

ManageColumnProvider.defaultProps = {
  id: null,
  presets: null,
  lastId: null,
  availableColumns: null,
  exportingCurrentView: true,
  exportingAllFields: true,
  showManagerLayout: true,
  columnsGroupBy: null,
};

export default ManageColumnProvider;
