import _ from 'immutable';
import omit from 'lodash.omit';
import { eventChannel } from 'redux-saga';
import {
  actionChannel,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';

// import authClient from '@/middleware/authClient';
import { actions as toastActions } from '@/redux/toast';
import {
  selectors as globalFiltersUiSelectors,
  actions as uiActions,
} from '@/redux/ui';
import { createSlice } from '@/redux/util';

import websocketClient, { CLOSE_CODES } from '@/middleware/websocketClient';

import { makeId } from '+utils';

/**
 * Redux saga slice for websocket
 */

export const ConnectionStatus = {
  connected: 'connected',
  disconnected: 'disconnected',
  connecting: 'connecting',
  ready: 'ready',
};

export const SubscribeMode = {
  collect: 'collect',
  throw: 'throw',
};

/**
 * ================ Default subscriptions ======================================
 * Payloads that are sent when socket begins as default subscriptions
 */
const defaultSubscriptions = {};

const initialState = {
  serverStatus: ConnectionStatus.disconnected,
  records: {},
  subscribed: {},
  subscribing: {},
  unsubscribing: {},
  settings: {},
  functions: {},
  modes: {},
  keepData: {},
  requesting: {},
};

const waitForSubscriptions = {};

const limitList = (list, limit) => {
  return list.size <= limit ? list : list.takeLast(limit);
};

// is defined below
let makeRequestSuccess;

const slice = createSlice({
  name: 'sockets',
  initialState,
  reducers: {
    appendData(state, { payload: { name, data } }) {
      if (!state.records[name]) {
        return;
      }

      const incoming = (Array.isArray(data) ? data : [data]).map((row) => {
        if (!row.id) {
          row.id = makeId();
        }
        return row;
      });

      const mode = state.modes[name];

      const limit = Number.MAX_SAFE_INTEGER; // set the limit if needed to 1000
      state.records[name] =
        mode === SubscribeMode.throw
          ? limitList(_.List(incoming), limit)
          : limitList(state.records[name].push(...incoming), limit);
    },
    setServerStatus(state, { payload }) {
      state.serverStatus = payload;
    },
    // We need this for 'action == request' case
    // Because in this case server send back data without first status message and we need to append this date
    request(
      state,
      { payload: { name, options, fn, mode, eventAboutLimit = true } },
    ) {
      state.settings[name] = options;
      state.functions[name] = makeRequestSuccess(fn, eventAboutLimit);

      state.modes[name] = mode || SubscribeMode.collect;

      delete state.subscribed[name];
      delete state.subscribing[name];

      state.requesting[name] = true;
      delete state.keepData[name];
    },
    requestSuccess(state, { payload: { name } }) {
      state.records[name] = _.List();
      delete state.requesting[name];
      delete state.functions[name];
    },
    subscribe(state, { payload: { name, options, fn, mode } }) {
      delete state.subscribed[name];
      state.subscribing[name] = true;
      state.settings[name] = options;
      if (fn) {
        state.functions[name] = fn;
      }
      state.modes[name] = mode || SubscribeMode.collect;
    },
    subscribeSuccess(state, { payload: { name } }) {
      if (!state.records[name] || state.settings[name]?.replace) {
        state.records[name] = _.List();
      }
      delete state.subscribing[name];
      state.subscribed[name] = true;
      delete state.keepData[name];
    },
    clearData(state, { payload: { name, keepData = true } }) {
      delete state.settings[name];
      delete state.functions[name];
      delete state.subscribed[name];
      delete state.subscribing[name];
      delete state.unsubscribing[name];
      delete state.keepData[name];
      if (keepData) {
        state.keepData[name] = true;
      } else {
        delete state.records[name];
      }
      delete state.modes[name];
    },
    unsubscribe(state, { payload: { name, keepData = true } }) {
      delete state.keepData[name];
      state.unsubscribing[name] = true;
      if (keepData) {
        state.keepData[name] = true;
      }
    },
    unsubscribeSuccess(state, { payload: { name } }) {
      delete state.settings[name];
      delete state.functions[name];
      delete state.subscribed[name];
      delete state.unsubscribing[name];
      if (!state.keepData[name]) {
        delete state.records[name];
      }
      delete state.modes[name];
    },
    resubscribe() {},
    resubscribeSuccess() {},
    connectServer() {},
    disconnectServer() {},
  },
  selectors: (getState) => ({
    recordsSelector: (name) =>
      createSelector([getState], (state) => state.records[name]),

    socketStateSelector: createSelector(
      [getState],
      (state) => state.serverStatus,
    ),

    isSubscribed: (name) =>
      createSelector([getState], (state) => state.subscribed[name] ?? false),

    isSubscribing: (name) =>
      createSelector([getState], (state) => state.subscribing[name] ?? false),

    isUnsubscribing: (name) =>
      createSelector([getState], (state) => state.unsubscribing[name] ?? false),

    isRequesting: (name) =>
      createSelector([getState], (state) => state.requesting[name] ?? false),
  }),
});

export const { actions } = slice;

// we can pass these function at subscribing moment.
const eventToFn = {
  *default(name, data) {
    if (data.record) {
      yield put(actions.appendData({ name, data: data.record }));
    }
  },
};

makeRequestSuccess = (fn, eventAboutLimit) =>
  function* (name, data) {
    yield put(actions.requestSuccess({ name }));
    if (fn) {
      yield call(fn, name, data);
    } else {
      yield call(eventToFn.default, name, data);
    }
    if (eventAboutLimit && data.record?.length > 1000) {
      const message =
        'Results were limited to 1000 records. Please consider adding more conditions so results are not truncated.';
      yield put(toastActions.info(message));
    }
  };

/**
 * ================= Socket incoming event handler switch map ===========================
 */
const createReadChannel = (socket) => {
  // let subscribeOnce = true;
  return eventChannel((emit) => {
    const messageHandler = (evt) => {
      let json;
      try {
        json = JSON.parse(evt.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Could not parse socket message to JSON', err);
        return;
      }

      // if (json.event === 'server_connect') {
      //   window.logoAnim?.show?.();
      // }

      emit({
        event: json.event,
        data: json,
      });
    };

    /** TODO: CHANGE ALL THESE */

    const reconnectingHandler = ({ delay }) => {
      const seconds = Math.round(delay / 1000);
      if (seconds > 5) {
        emit(
          toastActions.error({
            message: 'Websocket Connection',
            details: `Attempting to reconnect in ${seconds} seconds...`,
          }),
        );
      }

      // window.logoAnim?.playDisconnect?.();

      emit(actions.setServerStatus(ConnectionStatus.connecting));
    };

    const closeHandler = () => {
      // if (evt.code === CLOSE_CODES.NORMAL) {
      //   window.logoAnim?.disconnect?.();
      // }
      emit(actions.setServerStatus(ConnectionStatus.disconnected));
    };

    const connectHandler = () => {
      // window.logoAnim?.playLoading?.();
      emit(actions.setServerStatus(ConnectionStatus.connected));
    };

    socket.addEventListener('message', messageHandler);
    socket.addEventListener('close', closeHandler);
    socket.addEventListener('reconnecting', reconnectingHandler);
    socket.addEventListener('open', connectHandler);

    return () => {
      socket.removeEventListener('message', messageHandler);
      socket.removeEventListener('close', closeHandler);
      socket.removeEventListener('reconnecting', reconnectingHandler);
      socket.removeEventListener('open', connectHandler);
    };
  });
};

let isInitPageLoad = true;
const messageSwitch = function* ({ event, data }) {
  const { name, message, status } = data || {};

  if (status === 'error') {
    // eslint-disable-next-line no-console
    console.warn(data);

    if (!message.startsWith('Failed to find an active subscription')) {
      yield put(
        toastActions.error({
          message: 'Websocket Error',
          details: message,
        }),
      );
    }
  }

  if (data?.pong) {
    const { expectedVersion } = yield select(globalFiltersUiSelectors.getState);
    if (expectedVersion !== data.version) {
      yield put(uiActions.change({ expectedVersion: data.version }));
    }
  }

  if (!event && !name) {
    return;
  }

  if (event === 'server-connect') {
    if (isInitPageLoad) {
      isInitPageLoad = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(defaultSubscriptions)) {
        yield put(
          actions.subscribe({
            name: defaultSubscriptions[key].name,
            options: defaultSubscriptions[key],
          }),
        );
      }
    } else {
      yield put(actions.resubscribe());
      yield take(actions.resubscribeSuccess);
    }
    yield put(actions.setServerStatus(ConnectionStatus.ready));
    return;
  }

  if (data) {
    if (status === 'error') {
      const { subscribed = {} } = yield select(slice.selector);
      if (!subscribed[event]) {
        yield put(actions.unsubscribeSuccess({ name }));
      }
      return;
    }

    if (message === 'unsubscribed') {
      yield put(actions.unsubscribeSuccess({ name }));
      return;
    }

    if (message === 'subscribed') {
      yield put(actions.subscribeSuccess({ name }));
      return;
    }
  }

  const { functions, settings } = yield select(slice.selector);

  if (settings[name]) {
    const fn = functions[name] || eventToFn[name] || eventToFn.default;
    if (fn) {
      yield call(fn, name, data);
    }
  }
};

const read = function* (socket) {
  const channel = yield call(createReadChannel, socket);

  while (true) {
    const action = yield take(channel);
    if (action.type) {
      yield put(action);
    } else {
      yield fork(messageSwitch, action);
    }
  }
};

// ========================SOCKET OUTGOING SWITCH MAP ==========================
const requestForData = (socket, { name, options }) => {
  if (!name) {
    return;
  }

  const msg = {
    action: 'request',
    ...options,
    name,
  };

  socket.send(JSON.stringify(msg));
};

const subscribeToEvent = (socket, { name, options }) => {
  if (!name) {
    return;
  }

  const msg = {
    action: 'start',
    ...options,
    name,
  };

  socket.send(JSON.stringify(msg));
};

const unsubscribeFromEvent = (socket, { name, type }) => {
  if (!name) {
    return;
  }

  const msg = {
    action: 'end',
    name,
    type,
  };

  socket.send(JSON.stringify(msg));
};

const writeSwitchHandler = {
  *[actions.request](socket, { payload }) {
    const { name } = payload;
    const { subscribed = {} } = yield select(slice.selector);

    if (!name || (subscribed[name] && !payload?.options?.replace)) {
      return;
    }

    if (waitForSubscriptions[name]) {
      clearTimeout(waitForSubscriptions[name]);
      delete waitForSubscriptions[name];
    }

    requestForData(socket, payload);
  },
  *[actions.subscribe](socket, { payload }) {
    const { name } = payload;
    const { subscribed = {} } = yield select(slice.selector);

    if (!name || (subscribed[name] && !payload?.options?.replace)) {
      return;
    }

    if (waitForSubscriptions[name]) {
      clearTimeout(waitForSubscriptions[name]);
    }

    waitForSubscriptions[name] = setTimeout(() => {
      delete waitForSubscriptions[name];
      subscribeToEvent(socket, payload);
    }, 500);
  },
  *[actions.unsubscribe](socket, { payload }) {
    const { name } = payload;
    const { subscribed = {}, settings = {} } = yield select(slice.selector);
    const { type } = settings[name] || {};

    if (waitForSubscriptions[name]) {
      clearTimeout(waitForSubscriptions[name]);
      delete waitForSubscriptions[name];
      yield put(actions.unsubscribeSuccess({ name }));
      return;
    }

    if (!name || !subscribed[name]) {
      return;
    }

    unsubscribeFromEvent(socket, { name, type });
  },
  *[actions.resubscribe]() {
    const { settings } = yield select(slice.selector);

    const payloads = Object.keys(settings).map((key) => ({
      event: key,
      options: omit(settings[key], 'replace'),
    }));

    const { length } = payloads;
    for (let i = 0; i < length; i += 1) {
      yield put(actions.subscribe(payloads[i]));
      // TODO ? yield take(actions.subscribeSuccess);
    }
    yield put(actions.resubscribeSuccess());
  },
};

const write = function* (socket) {
  const writeActions = new Set(Object.keys(writeSwitchHandler));
  const channel = yield actionChannel((action) =>
    writeActions.has(action.type),
  );

  while (true) {
    const action = yield take(channel);
    const handler = writeSwitchHandler[action.type];
    if (handler) {
      yield fork(handler, socket, action);
    }
  }
};

/**
 * Saga to listen for starting and stopping saga channel
 */
const socketStartStopWatcherSaga = function* () {
  while (true) {
    // TODO: proper login & logout hooks
    // Wait for start
    yield race({
      connect: take(actions.connectServer.type),
    });

    yield put(actions.setServerStatus(ConnectionStatus.connecting));

    try {
      const socket = yield call(websocketClient);

      const readTask = yield fork(read, socket);
      const writeTask = yield fork(write, socket);

      // Run readTask until a stop is received
      yield race({
        disconnect: take(actions.disconnectServer.type),
      });

      yield cancel(readTask);
      yield cancel(writeTask);
      socket.close(CLOSE_CODES.DISCONNECT, 'Disconnecting from server');
      yield put(actions.setServerStatus(ConnectionStatus.disconnected));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Stopping socket:', error);
      yield put(actions.setServerStatus(ConnectionStatus.disconnected));
    }
  }
};

slice.sagas = [socketStartStopWatcherSaga];

export const { selectors } = slice;

export default slice;
