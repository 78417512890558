import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash.debounce';

import LabelContextTypes from '@/models/LabelContextTypes';
import PermissionModel from '@/models/Permission';

import {
  actions as portLabelsActions,
  selectors as portLabelsSelectors,
} from '@/redux/api/labels/ports';
import { actions, selectors } from '@/redux/api/nql-complete';

import { useFormState } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import { Group, Label } from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import NumberField from '+components/form/NumberField';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import { ToggleField } from '+components/form/Toggle';
import {
  validateLabels,
  validatePort,
  validatePorts,
  validateRequired,
} from '+components/form/Validators';
import FormModal, { defaultProps, propTypes } from '+components/FormModal';
import ContextNameLabel from '+components/Labels/ContextNameLabel';
import usePermissions from '+hooks/usePermissions';
import { getContextType } from '+utils/labels';
import makeArr from '+utils/makeArr';

const FormBody = (props) => {
  const { canManage, protocols, onVisibilityToggle } = props;

  const dispatch = useDispatch();

  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const suggestionsIds = useMemo(
    () => makeArr(formValues.context).map((item) => `portLabels_${item}`),
    [formValues.context],
  );

  const suggestionsData = useSelector(
    selectors.getMultipleSuggestionsData(suggestionsIds),
  );

  const labelSuggestions = useMemo(
    () =>
      suggestionsData?.reduce(
        (acc, item) => [...acc, ...(item?.suggestions || [])],
        [],
      ),
    [suggestionsData],
  );

  const doVisibilityToggle = useCallback(
    () =>
      onVisibilityToggle({ ...formValues, silent: false }, !formValues.hide),
    [formValues],
  );

  const onLabelInputChange = useMemo(
    () =>
      debounce((_, value) => {
        suggestionsIds.forEach((suggestionsId) => {
          const context = suggestionsId.split('_')[1];
          if (!context) {
            return;
          }
          const text = `label.port.${context} == ${value}`;
          const caretPos = text.length;
          const fieldType = 'flow';
          dispatch(actions.cancel(suggestionsId));
          dispatch(
            actions.fetchSuggestions(
              {
                id: suggestionsId,
                text,
                caretPos,
                fieldType,
                takeEvery: true,
              },
              suggestionsId,
            ),
          );
        });
      }, 300),
    [suggestionsIds],
  );

  useEffect(
    () => () => {
      dispatch(actions.clearMultipleSuggestions(suggestionsIds));
    },
    [suggestionsIds],
  );

  return (
    <Fragment>
      {formValues.id ? (
        <Fragment>
          <Group>
            <Label>Port</Label>
            <Plaintext>{formValues.port}</Plaintext>
          </Group>

          <Group>
            <Label>Protocol</Label>
            <Plaintext>
              <ContextNameLabel>{formValues.protocol}</ContextNameLabel>
            </Plaintext>
          </Group>
        </Fragment>
      ) : (
        <Fragment>
          {Array.isArray(formValues.port) ? (
            <Field
              name="port"
              label="Ports"
              placeholder="+ Add port"
              component={MultiSelectField}
              parse={normalizeMultiSelectValue}
              validate={[validatePorts, validateRequired]}
              disabled={!canManage}
              allowCreate
              required
            />
          ) : (
            <Field
              name="port"
              label="Port"
              component={NumberField}
              step={1}
              min={0}
              max={65535}
              validate={[validatePort, validateRequired]}
              disabled={!canManage}
              required
            />
          )}

          {Array.isArray(formValues.protocol) ? (
            <Field
              name="protocol"
              label="Protocols"
              placeholder="+ Add protocol"
              options={protocols}
              component={MultiSelectField}
              parse={normalizeMultiSelectValue}
              validate={[validateRequired]}
              disabled={!canManage}
              required
            />
          ) : (
            <Field
              name="protocol"
              label="Protocol"
              options={protocols}
              component={SelectField}
              parse={normalizeSelectValue}
              validate={[validateRequired]}
              disabled={!canManage}
              required
            />
          )}
        </Fragment>
      )}

      <Field
        name="labels"
        label="Labels"
        placeholder="+ Add label"
        component={MultiSelectField}
        options={labelSuggestions}
        parse={normalizeMultiSelectValue}
        validate={[validateRequired, validateLabels]}
        disabled={!canManage}
        helperText="Maximum length: 80. Allowed characters: a-z A-Z 0-9 . _ - # ~ : ( ) /"
        allowCreate
        required
        optionsLimit={100}
        onInputChange={onLabelInputChange}
      />

      {getContextType(formValues) === LabelContextTypes.system && (
        <Field
          name="hide"
          label=" "
          component={ToggleField}
          type="checkbox"
          uncheckedLabel="Hide"
          checkedLabel="Show"
          disabled={!canManage}
          onChange={doVisibilityToggle}
          parse={(v) => !v}
          format={(v) => !v}
        />
      )}
    </Fragment>
  );
};

FormBody.propTypes = {
  canManage: PropTypes.bool,
  protocols: PropTypes.arrayOf(PropTypes.shape({})),
  onVisibilityToggle: PropTypes.func,
};

FormBody.defaultProps = {
  canManage: false,
  protocols: [],
  onVisibilityToggle: () => {},
};

const PortLabelForm = (props) => {
  const { initialValues, onVisibilityToggle, ...tail } = props;

  const dispatch = useDispatch();

  const protocols = useSelector(portLabelsSelectors.getProtocols);

  const permissions = usePermissions(PermissionModel.Resources.label.value);
  const canManage = initialValues.id
    ? permissions?.update
    : permissions?.create;
  const canResetOrRemove =
    getContextType(initialValues) === LabelContextTypes.customized
      ? permissions?.update
      : permissions?.delete &&
        initialValues.id &&
        getContextType(initialValues) !== LabelContextTypes.system;

  useEffect(() => {
    if (!protocols?.length) {
      dispatch(portLabelsActions.fetchProtocols());
    }
  }, [protocols?.length]);

  return (
    <FormModal
      {...tail}
      mode={initialValues.id ? 'edit' : 'add'}
      item={initialValues.id ? `label for ${initialValues.port}` : 'new label'}
      initialValues={initialValues}
      disabled={!canManage}
      deleteButtonText={
        getContextType(initialValues) === LabelContextTypes.customized
          ? 'Reset Customization'
          : 'Delete Label'
      }
      deleteButtonHidden={!initialValues.id}
      deleteButtonDisabled={!canResetOrRemove}
    >
      <FormBody
        canManage={canManage}
        protocols={protocols}
        onVisibilityToggle={onVisibilityToggle}
      />
    </FormModal>
  );
};

PortLabelForm.propTypes = propTypes;
PortLabelForm.defaultProps = defaultProps;

export default PortLabelForm;
