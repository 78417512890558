import PropTypes from '+prop-types';
import { useCallback } from 'react';

import isFunction from 'lodash.isfunction';
import omit from 'lodash.omit';

import Toggle from '+components/form/Toggle';
import { BaseColumnFactory } from '+components/Table/Columns/BaseColumnFactory';
import { BooleanColumnFilter } from '+components/Table/Filters';

const CellToggle = (options) => {
  const { accessor, onToggle, getChecked, getDisabled } = options;

  const Component = ({
    row: {
      original,
      original: { [accessor]: value },
    },
  }) => {
    const enabled = isFunction(getChecked) ? getChecked(value) ?? value : value;
    const disabled = isFunction(getDisabled) ? getDisabled(original) : false;

    const onChange = useCallback(() => onToggle(original, !enabled), [enabled]);

    return <Toggle checked={enabled} disabled={disabled} onChange={onChange} />;
  };

  Component.propTypes = {
    row: PropTypes.shape().isRequired,
  };

  return Component;
};

/**
 * ToggleColumn factory
 * @param {object} [props]
 * @param {string|Function} [props.accessor]
 * @param {string|Function} [props.Header]
 * @param {number} [props.width]
 * @param {{ true: string | JSX.Element, false: string | JSX.Element }} [props.filterLabels]
 * @param {function(any): boolean} [props.getChecked]
 * @param {function(any): boolean} [props.getDisabled]
 * @return {function({onToggle?: (function(any, bool): void)}): *}
 */
export const ToggleColumnFactory = (props) => {
  const {
    accessor = 'enabled',
    Header = 'Enabled',
    width = 60,
    filterLabels,
    getChecked,
    getDisabled,
    Cell: CellProp,
    onToggle,
  } = props || {};

  return {
    ...BaseColumnFactory(omit(props, 'onToggle')),
    accessor: accessor ?? 'enabled',
    Header: Header ?? 'Enabled',
    width: width || 60,
    maxWidth: width || 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    sortType: 'boolean',
    Cell:
      CellProp ?? CellToggle({ accessor, onToggle, getChecked, getDisabled }),
    Aggregated: () => '',
    Filter: BooleanColumnFilter(
      filterLabels ?? {
        true: 'Enabled',
        false: 'Disabled',
      },
    ),
    filter: 'booleanFilter',
    disableGroupBy: true,
    disableAggregators: true,
    disableDuplicateBy: true,
  };
};
