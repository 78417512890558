import { useMemo } from 'react';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import GearIcon from 'mdi-react/GearIcon';

import LabelContextTypes from '@/models/LabelContextTypes';

import ContextLabel from '+components/Labels/ContextLabel';
import ContextNameLabel from '+components/Labels/ContextNameLabel';
import { ShowMoreWrapper, UniversalCell } from '+components/Table/Cells';
import CellPortLabel from '+components/Table/Cells/CellPortLabel';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  RowSelectorColumnFactory,
  ToggleColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import Tooltip from '+components/Tooltip';
import { getColumnsHelper } from '+utils/getColumnsHelper';
import { getContextType } from '+utils/labels';

import TooltipContent from '../../shared/TooltipContent';
import { slicedLength } from '../../shared/utils';

export const Columns = {
  rowSelector: 'rowSelector',
  system: 'system',
  customized: 'customized',
  port: 'port',
  protocol: 'protocol',
  labels: 'labels',
  hide: 'hide',
  menu: 'menu',
};

const columnsCollection = ({ permissions, onVisibilityToggle }) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.system]: BaseColumnFactory({
    accessor: (original) =>
      [LabelContextTypes.customized, LabelContextTypes.system].includes(
        getContextType(original),
      ),
    Header: <GearIcon size={14} />,
    Description: 'System',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    disableResizing: true,
    sortType: 'boolean',
    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <GearIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>System Label</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'System',
      false: 'Custom',
    }),
    filter: 'booleanFilter',
  }),
  [Columns.customized]: BaseColumnFactory({
    accessor: (original) =>
      getContextType(original) === LabelContextTypes.customized,
    Header: <ContentCopyIcon size={14} />,
    Description: 'Customized',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    disableResizing: true,
    sortType: 'boolean',
    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <ContentCopyIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>Customized</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter(
      {
        false: 'Default',
        true: 'Customized',
      },
      null,
      ['all', 'false', 'true'],
    ),
    filter: 'booleanFilter',
  }),
  [Columns.port]: NumberColumnFactory({
    accessor: Columns.port,
    Header: 'Port',
    Cell: UniversalCell(Columns.port),
  }),
  [Columns.protocol]: BaseColumnFactory({
    accessor: Columns.protocol,
    Header: 'Protocol',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <ContextNameLabel>{value}</ContextNameLabel>,
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
      sort: true,
    }),
    filter: 'selectFilter',
  }),
  [Columns.labels]: BaseColumnFactory({
    accessor: Columns.labels,
    Header: 'Labels',
    Cell: ({ value, row }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        return (
          <ShowMoreWrapper
            gap={5}
            originalLength={(value || []).length}
            slicedLength={slicedLength}
          >
            {(value || []).slice(0, slicedLength).map((label) => (
              <ContextLabel key={label} clickable>
                <CellPortLabel
                  field={`label.port.${original?.context}`}
                  value={label}
                />
              </ContextLabel>
            ))}
          </ShowMoreWrapper>
        );
      }, [row, value]),
  }),
  [Columns.hide]: ToggleColumnFactory({
    accessor: Columns.hide,
    Header: 'Show',
    filterLabels: {
      true: 'Hide',
      false: 'Show',
    },
    getChecked: (value) => !(value ?? false),
    getDisabled: (original) =>
      getContextType(original) !== LabelContextTypes.system ||
      !permissions?.update,
    onToggle: onVisibilityToggle,
  }),
  [Columns.menu]: MenuColumnFactory,
});

export const getColumns = getColumnsHelper(columnsCollection);
