import styled from 'styled-components';

import IconButton from '+components/IconButton';

const BackIconButton = styled(IconButton)`
  margin-right: 8px;
  background-color: ${({ theme }) => theme.buttonPrimary};
  color: ${({ theme }) => theme.buttonPrimaryText} !important;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colorTool.lighten(theme.buttonPrimary, 0.2)};
  }
`;

export default BackIconButton;
