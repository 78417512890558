import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-use';

import { getIdpLoginUrl, getShortnameFromDomain } from '@/shared/utils';

import FullMode from './components/FullMode';
import GuestMode from './components/GuestMode';

const AppRouter = () => {
  const { pathname } = useLocation();
  const guestMatch = matchPath('/g/*', pathname);
  const ssoRouteMatch = matchPath('/sso/:shortname', pathname);
  const domainShortname = getShortnameFromDomain();

  if (ssoRouteMatch || domainShortname) {
    const shortname = domainShortname || ssoRouteMatch.params.shortname;
    window.location.href = getIdpLoginUrl(shortname);
    return null;
  }

  if (guestMatch) {
    return <GuestMode />;
  }

  return <FullMode />;
};

export default AppRouter;
