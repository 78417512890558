import PropTypes from '+prop-types';
import { Fragment } from 'react';

import CheckBox from '+components/form/CheckBox';
import FieldsSection from '+components/form/FieldsSection';
import Field from '+components/form/FinalForm/Field';
import Description from '+components/form/FormField/components/Description';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const types = [
  { value: 'A', label: 'A - IPv4 address' },
  { value: 'CNAME', label: 'CNAME - Canonical name' },
  { value: 'MX', label: 'MX - Mail exchange' },
  { value: 'AAAA', label: 'AAAA - IPv6 address' },
  { value: 'TXT', label: 'TXT - Text' },
  { value: 'PTR', label: 'PTR - Pointer' },
  { value: 'SRV', label: 'SRV - Service locator' },
  { value: 'SPF', label: 'SRV - Sender Policy Framework' },
  { value: 'NAPTR', label: 'NAPTR - Name Authority Pointer' },
  { value: 'CAA', label: 'CAA - Certification Authority Authorization' },
  { value: 'NS', label: 'NS - Name server' },
];

const Route53Fields = ({ disabled }) => {
  return (
    <Fragment>
      <Field
        name="name"
        label="Name"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        validate={validateRequired}
        style={{ width: '50%' }}
        disabled={disabled}
        required
      />

      <Field
        name="description"
        label="Description"
        component={TextField}
        type="text"
        maxLength={255}
        autoComplete="new-password"
        disabled={disabled}
      />

      <Field
        name="config.name"
        label="Record Name"
        component={TextField}
        type="text"
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="config.type"
        label="Type"
        component={SelectField}
        options={types}
        validate={validateRequired}
        parse={normalizeSelectValue}
        disabled={disabled}
        required
      />

      <Field
        name="config.aliastarget"
        label="Alias Target"
        component={TextField}
        type="text"
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Field
        name="config.hostedzoneid"
        label="Host Zone ID"
        component={TextField}
        type="text"
        autoComplete="new-password"
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <Group>
        <FieldContainer>
          <Field
            name="config.evaluatetargethealth"
            label="Check Health"
            type="checkbox"
            component={CheckBox}
            disabled={disabled}
          />
        </FieldContainer>
        <Description>Evaluate Target Health</Description>
      </Group>

      <FieldsSection label="Authentication">
        <Field
          name="config.accesskeyid"
          label="Access Key ID"
          component={TextField}
          type="text"
          autoComplete="new-password"
          validate={validateRequired}
          disabled={disabled}
          required
        />

        <Field
          name="config.secretaccesskey"
          label="Secret Access Key"
          component={TextField}
          type="password"
          autoComplete="new-password"
          validate={validateRequired}
          disabled={disabled}
          required
        />
      </FieldsSection>
    </Fragment>
  );
};

Route53Fields.propTypes = {
  disabled: PropTypes.bool,
};

Route53Fields.defaultProps = {
  disabled: false,
};

export default Route53Fields;
