import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PermissionModel from '@/models/Permission';

import { actions, selectors } from '@/redux/api/rules';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import { lang } from '+components/charts/common/utils';
import usePermissions from '+hooks/usePermissions';

import CustomTable from './components/CommonTable';
import ModalResetAllCategories from './components/modals/ResetAll';

const DetectionCategories = () => {
  const dispatch = useDispatch();

  const [modalReset, setModalReset] = useState(false);
  const [resetInProgress, setResetInProgress] = useState(false);

  const { isFetching, categories } = useSelector(selectors.getState);
  const user = useSelector(profileSelectors.getProfile);
  const permissions = usePermissions(PermissionModel.Resources.category.value);
  const isDefaultCustomer = String(user?.app_metadata?.shortname) === 'default';

  const tableData = useMemo(
    () => Object.values(categories || {}),
    [categories],
  );

  const toggleModalReset = useCallback(
    () => setModalReset((prev) => !prev),
    [],
  );

  const onResetAll = useCallback(() => {
    dispatch(actions.deleteAllCategories());
    setResetInProgress(true);
  }, []);

  useEffect(() => {
    if (!resetInProgress || isFetching) {
      return;
    }
    setModalReset(false);
    setResetInProgress(false);
  }, [resetInProgress]);

  useEffect(() => {
    dispatch(actions.fetchCategories());
    return () => {
      dispatch(actions.cancel());
    };
  }, []);

  return (
    <Fragment>
      <CustomTable
        rows={tableData}
        permissions={permissions}
        isDefaultCustomer={isDefaultCustomer}
        noDataText={categories ? undefined : lang.loading}
      />

      {modalReset && (
        <ModalResetAllCategories
          isOpen={modalReset}
          isDisabled={isFetching}
          toggleModal={toggleModalReset}
          onConfirm={onResetAll}
        />
      )}
    </Fragment>
  );
};

export default DetectionCategories;
