import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, FetchStates, selectors } from '@/redux/api/nql-complete';

import useUIProperty from '+hooks/useUIProperty';

import { CommonForm } from './CommonForm';

export const ChangeNqlPreset = () => {
  const dispatch = useDispatch();

  const [globalNqlPresetChange, setGlobalNqlPresetChange] = useUIProperty(
    'globalNqlPresetChange',
    null,
  );

  const status = useSelector(selectors.getPresetsStatus);

  const isFetching = status === FetchStates.fetching;
  const isError = status === FetchStates.error;

  const [isProcessing, setIsProcessing] = useState(false);

  const initialValues = useMemo(
    () => ({
      ...globalNqlPresetChange,
      context: globalNqlPresetChange?.context,
      nql: [globalNqlPresetChange?.nql || ''],
    }),
    [globalNqlPresetChange],
  );

  const onCancel = useCallback(() => {
    setGlobalNqlPresetChange(null);
  }, []);

  const onSubmit = useCallback((values) => {
    dispatch(
      actions[values.id ? 'updatePreset' : 'addPreset']({
        ...values,
        nql: values.nql[0],
      }),
    );
    setIsProcessing(true);
  }, []);

  useEffect(() => {
    if (isFetching || !isProcessing) {
      return;
    }

    setIsProcessing(false);

    if (!isError) {
      setGlobalNqlPresetChange(null);
    }
  }, [isFetching, isProcessing, isError]);

  return (
    <CommonForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onToggle={onCancel}
      loading={isFetching || isProcessing}
      isOpen={!!globalNqlPresetChange}
    />
  );
};
