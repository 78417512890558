import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import { actions, selectors } from '@/redux/api/rules';

import EditPageAuditLogTabs from '+components/EditPageAuditLogTabs';
import { usePageTabs } from '+components/PageTabs';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';

import RuleForm from './Form';
import { rulesParamsToUi } from './Rules';

const RuleEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ruleId } = useParams();

  const [, activePageTab] = usePageTabs();

  const { isFetching, error } = useSelector(selectors.getState);
  const rule = useSelector(selectors.getRule(ruleId));

  // track if we have intercepted a submit and if we are waiting on the API/dispatch to complete
  const [isSubmitting, setIsSubmitting] = useState(false);

  // if the user nav'd from the rules list, then the rule will already be in redux
  // if directly loading (like on a refresh, then we need to call the api to fetch the rule)
  const [isLoadingRule, setIsLoadingRule] = useState(false);

  useLoadingIndicator(isLoadingRule || (!!isSubmitting && isFetching));

  useEffect(() => {
    if (!rule) {
      setIsLoadingRule(true);
      dispatch(actions.fetchRule(ruleId));
    }
  }, [rule, ruleId]);

  useEffect(() => {
    if (rule || error) {
      setIsLoadingRule(false);
    }
  }, [rule, error]);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      delete values.id;
      dispatch(actions.addRule(values));
    },
    [activePageTab?.id],
  );

  const onUpdate = useCallback(
    (values) => {
      setIsSubmitting(activePageTab?.id);
      values.id = ruleId;
      dispatch(actions.updateRule(values));
    },
    [ruleId, activePageTab?.id],
  );

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      navigate(`${RoutePaths.responsePolicies}`);
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id]);

  // convert nested API object to flattenened UI object
  const initialValues = useMemo(() => rulesParamsToUi(rule), [rule]);

  const [, setMasqueradeUrl] = useUIProperty('masqueradeUrl');
  useEffect(() => {
    setMasqueradeUrl(`${RoutePaths.responsePolicies}`);
    return () => {
      setMasqueradeUrl(null);
    };
  }, []);

  return (
    <EditPageAuditLogTabs
      auditNqlQuery={`class == response_policy && original_id == ${ruleId}`}
    >
      <RuleForm
        mode="update"
        ruleId={ruleId}
        initialValues={initialValues}
        createCallback={onSubmit}
        updateCallback={onUpdate}
      />
    </EditPageAuditLogTabs>
  );
};

export default RuleEdit;
