import { Navigate, Route, Routes } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

const ToolsRoutes = () => (
  <Routes>
    <Route
      path={RoutePaths.ipIntelligence.pageName}
      element={<Navigate to={RoutePaths.ipIntelligence.toString()} replace />}
    />

    <Route
      path={RoutePaths.cveLookup.pageName}
      element={<Navigate to={RoutePaths.cveLookup.toString()} replace />}
    />

    <Route
      path="*"
      element={<Navigate to={RoutePaths.ipIntelligence.toString()} replace />}
    />
  </Routes>
);

export default ToolsRoutes;
