import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';

import { Col, Row } from '+components/Layout';
import {
  CellAlgorithmName,
  CellPluginName,
  CellResponsePolicyName,
  CellTrafficSourceName,
  UniversalCell,
} from '+components/Table/Cells';
import UniversalField from '+components/UniversalField';

import {
  BodyGroupCol,
  BodyGroupTitleRow,
  BodyItemRow,
  BodyNameCol,
  BodySeparator,
  BodyValueCol,
} from '../../BodyComponents';

const EventOverview = ({ padding, record }) => {
  const dispatch = useDispatch();

  const devices = useSelector(deviceSelectors.getDevices);

  const row = useMemo(() => ({ original: record }), [record]);

  const fixedDevices = useMemo(
    () =>
      Object.values(devices || {}).reduce(
        (acc, el) => ({ ...acc, [el.name]: el.id }),
        {},
      ),
    [devices],
  );

  useEffect(() => {
    if (!Object.keys(devices || {}).length) {
      dispatch(deviceActions.devicesFetch());
    }
  }, [devices]);

  return (
    <Col gap="14px">
      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyItemRow>
          <BodyNameCol>Event ID:</BodyNameCol>
          <BodyValueCol>{record.id}</BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Severity:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('severity')({
              row,
              value: record.severity,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Summary:</BodyNameCol>
          <BodyValueCol>{record.summary}</BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Time</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Alert Type:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('alerttype')({
              row,
              value: record.alerttype,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        {record.duration === 0 ? (
          <BodyItemRow>
            <BodyNameCol>Date:</BodyNameCol>
            <BodyValueCol>
              {UniversalCell('start')({
                row,
                value: record.start,
                options: { disabled: true },
              })}
            </BodyValueCol>
          </BodyItemRow>
        ) : (
          <Fragment>
            <BodyItemRow>
              <BodyNameCol>Start:</BodyNameCol>
              <BodyValueCol>
                {UniversalCell('start')({
                  row,
                  value: record.start,
                  options: { disabled: true },
                })}
              </BodyValueCol>
            </BodyItemRow>

            <BodyItemRow>
              <BodyNameCol>End:</BodyNameCol>
              <BodyValueCol>
                {UniversalCell('end')({
                  row,
                  value: record.end,
                  options: { disabled: true },
                })}
              </BodyValueCol>
            </BodyItemRow>

            <BodyItemRow>
              <BodyNameCol>Duration:</BodyNameCol>
              <BodyValueCol>
                {UniversalCell('duration')({
                  row,
                  value: record.duration,
                  options: { disabled: true },
                })}
              </BodyValueCol>
            </BodyItemRow>
          </Fragment>
        )}
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Traffic</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Type:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="traffic_type"
              value={record.traffic_type}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Traffic Sources:</BodyNameCol>
          <BodyValueCol>
            {(record.datasrcs?.length
              ? record.datasrcs
              : record.flowsrcnames
            )?.map((name) => {
              const field = record.datasrcs?.length
                ? 'datasrcs'
                : 'flowsrcnames';
              if (fixedDevices[name]) {
                return (
                  <CellTrafficSourceName
                    key={`device-${name}`}
                    field={field}
                    value={name}
                    id={fixedDevices[name]}
                    type="device"
                    disabled
                  />
                );
              }
              return (
                <CellTrafficSourceName
                  key={`cloud-${name}`}
                  field={field}
                  value={name}
                  type="cloud"
                  disabled
                />
              );
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol># of SRC IPs:</BodyNameCol>
          <BodyValueCol>
            {record.ipinfo?.filter((item) => item.srcip).length}
          </BodyValueCol>
        </BodyItemRow>

        {record.traffic_type === 'flow' && (
          <BodyItemRow>
            <BodyNameCol># of DST IPs:</BodyNameCol>
            <BodyValueCol>
              {record.ipinfo?.filter((item) => item.dstip).length}
            </BodyValueCol>
          </BodyItemRow>
        )}
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Detection Model</BodyGroupTitleRow>

        <Row gap="5px">
          {record.tdm && (
            <CellAlgorithmName
              id={record.tdm.id}
              name={record.tdm.name}
              description={record.tdm.description}
              disabled
            />
          )}
        </Row>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Response Policies</BodyGroupTitleRow>

        <Row gap="5px">
          {record.rules?.map((item) => (
            <CellResponsePolicyName
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              disabled
            />
          ))}
        </Row>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Integrations</BodyGroupTitleRow>

        <Row gap="5px">
          {record.rules
            ?.reduce((acc, item) => [...acc, ...item.plugins], [])
            .map((item) => (
              <CellPluginName
                key={item.id}
                id={item.id}
                name={item.name}
                description={item.description}
                disabled
              />
            ))}
        </Row>
      </BodyGroupCol>
    </Col>
  );
};

EventOverview.propTypes = {
  padding: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default EventOverview;
