import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';

import AwsAuthTypes from '@/models/AwsAuthTypes';

const cleanUpAwsAuth = (values, accessor, roleAuth) => {
  const copy = cloneDeep(values);
  if (roleAuth === AwsAuthTypes.role.value) {
    copy[accessor].awsauthtype = 'RoleARN';
    copy[accessor].accesskeyid = '';
    copy[accessor].accesssecret = '';
  } else {
    copy[accessor].awsauthtype = 'AccessKey';
    delete copy[accessor].role;
  }
  delete copy.roleAuth;
  return copy;
};

// searches through all manifest field objects and 'fields' value children to check if all submit values are represetend, returns a list of unrepresented object paths
const findMissingFieldsinSubmitValues = (searchObject, submitValues) => {
  return Object.keys(searchObject).reduce((acc, curr) => {
    if (searchObject[curr]?.type === 'section' && searchObject[curr]?.fields) {
      acc.concat(
        findMissingFieldsinSubmitValues(
          searchObject[curr].fields,
          submitValues,
        ),
      );
    } else if (get(submitValues, curr) === undefined) {
      acc.push(curr);
    }
    return acc;
  }, []);
};
const cleanUpFields = (values, manifest) => {
  if (manifest?.fields) {
    const missingFields = findMissingFieldsinSubmitValues(
      manifest?.fields,
      values,
    );
    missingFields.forEach((field) => set(values, field, ''));
  }
  // per ENG-700, backend expects a comma-separated string of regions if multiple are selected, so need to parse that.
  if (values.aws) {
    const copy = cloneDeep(values);
    if (copy.aws?.region && typeof copy.aws?.region !== 'string') {
      copy.aws.region = copy.aws.region.join(',');
      if (copy.aws.region.length === 0) {
        delete copy.aws.region;
      }
    }
    return cleanUpAwsAuth(copy, 'aws', copy.roleAuth);
  }
  if (values.s3) {
    return cleanUpAwsAuth(values, 's3', values.roleAuth);
  }
  if (values.config && values.roleAuth) {
    return cleanUpAwsAuth(values, 'config', values.roleAuth);
  }
  return values;
};

export default cleanUpFields;
