import styled from 'styled-components';

import DropdownFieldOrigin from '+components/form/DropdownField';

import { DropdownMixin } from './DropdownMixin';

const AdditionalFiltersDropdownCaptureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0;
  overflow: hidden;
  &:only-child {
    margin-top: unset;
    * {
      max-height: unset;
    }
  }
`;

const AdditionalFiltersDropdownCaptureLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  max-height: 14px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const AdditionalFiltersDropdownCaptureValue = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  white-space: nowrap;
  user-select: none;
  text-transform: none;
  max-height: 14px;
  font-weight: normal !important;
`;

const AdditionalFiltersDropdownField = styled(DropdownFieldOrigin).attrs(
  (props) => ({
    $dirty: props.meta?.dirty,
    menuProps: {
      PaperProps: {
        style: {
          transform: 'translate(10px, 4px)',
        },
      },
      style: {
        zIndex: 9999999,
      },
    },
  }),
)`
  ${DropdownMixin};
`;

export {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
};
export default AdditionalFiltersDropdownField;
