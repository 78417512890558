/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import Button, { ButtonVariants } from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import {
  MenuColumnContextMenu,
  MenuColumnFactory,
  ToggleColumnFactory,
} from '+components/Table/Columns';
import * as toast from '+components/toast';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import usePermissions from '+hooks/usePermissions';

import columns from './components/columns';

const idTable = 'RulesEngine_Rules_List';

const RulesTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, setRowId] = useFocusOnRowId(idTable);

  const rules = useSelector(rulesSelectors.getRules);
  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const categories = useSelector(rulesSelectors.getCategories);
  const isFetching = useSelector(rulesSelectors.isFetching);
  const permissions = usePermissions(
    PermissionModel.Resources.response_policy.value,
  );

  const [modal, setModal] = useState({});
  const [showBulkEnableModal, toggleBulkEnableModal] = useToggle(false);
  const [showBulkDisableModal, toggleBulkDisableModal] = useToggle(false);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState([]);

  useLoadingIndicator(isFetching);

  const toggleDeleteModal = useCallback((row = null) => {
    setModal((prev) => {
      let show = !prev.show;
      if (!row) {
        show = false;
      }

      return {
        show,
        id: row ? row.id : null,
        name: row ? row.name : null,
        description: row ? row.description : null,
      };
    });
  }, []);

  const cxActionMenu = useCallback(
    (id, row) => {
      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          onClick: () => {
            setRowId(id);
            navigate(`${RoutePaths.responsePolicies}/${id}`);
          },
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete,
          onClick: () => {
            toggleDeleteModal(row);
          },
        },
      ];

      return (
        <MenuColumnContextMenu
          title={row.name}
          items={items}
          dataTracking="response-policy"
        />
      );
    },
    [toggleDeleteModal, permissions],
  );

  const onToggleEnabled = useCallback(
    ({ id = '0' }, newEnabled) => {
      if (newEnabled) {
        dispatch(rulesActions.enableRule(id));
      } else {
        dispatch(rulesActions.disableRule(id));
      }
    },
    [dispatch],
  );

  const deleteRule = useCallback(() => {
    if (modal.id) {
      setDeleting(true);
      dispatch(rulesActions.deleteRule(modal.id));
    }
  }, [dispatch, modal]);

  const onToggle = useCallback(() => setModal({}), []);

  const tableData = useMemo(() => Object.values(rules || {}), [rules]);

  const algorithmsHash = useMemo(
    () =>
      Object.values(algorithms || {}).reduce(
        (acc, item) => ({ ...acc, [item.name]: item }),
        {},
      ),
    [algorithms],
  );

  const categoriesHash = useMemo(
    () =>
      categories?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {}),
    [categories],
  );

  const columnsList = useMemo(
    () => [
      ...columns({
        algorithms: algorithmsHash,
        categories: categoriesHash,
      }),
      ToggleColumnFactory({
        getDisabled: () => !permissions?.update,
        onToggle: onToggleEnabled,
      }),
      MenuColumnFactory({ cxActionMenu }),
    ],
    [
      onToggleEnabled,
      cxActionMenu,
      permissions,
      algorithmsHash,
      categoriesHash,
    ],
  );

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const getIsRowSelectorDisabled = useCallback(
    () => !permissions?.update,
    [permissions],
  );

  useEffect(() => {
    if (!rules) {
      dispatch(rulesActions.fetchRules(null, 'rpList'));
    }

    return () => {
      dispatch(rulesActions.cancel('rpList'));
    };
  }, [rules]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (deleting) {
      setDeleting(false);
      setModal({});
    }
  }, [deleting, isFetching]);

  const onClick = useCallback(
    () => navigate(`${RoutePaths.responsePolicies}/add`),
    [],
  );

  const onConfirmBulkEnable = useCallback(() => {
    toggleBulkEnableModal();
    const data = selected.filter((id) => !rules[id].enabled);
    if (!data.length) {
      toast.info('All selected response policies are already enabled');
    } else {
      dispatch(rulesActions.bulkEnableRules(data));
    }
  }, [selected, rules]);

  const onConfirmBulkDisable = useCallback(() => {
    toggleBulkDisableModal();
    const data = selected.filter((id) => rules[id].enabled);
    if (!data.length) {
      toast.info('All selected response policies are already disabled');
    } else {
      dispatch(rulesActions.bulkDisableRules(data));
    }
  }, [selected, rules]);

  const onConfirmBulkDelete = useCallback(() => {
    toggleBulkDeleteModal();
    if (!selected.length) {
      return;
    }

    dispatch(rulesActions.bulkDeleteRules(selected));
  }, [selected]);

  return (
    <Fragment>
      <ActionsContainer>
        <Button
          disabled={!permissions?.create}
          onClick={onClick}
          testId="add-response-policy-button"
        >
          Add Response Policy
        </Button>

        <Dropdown
          caption="Update Selected"
          variant={ButtonVariants.outlined}
          disabled={!selected.length || !permissions?.update}
        >
          <DropdownItem onClick={toggleBulkEnableModal} short>
            Enable
          </DropdownItem>
          <DropdownItem onClick={toggleBulkDisableModal} short>
            Disable
          </DropdownItem>
          <DropdownItem onClick={toggleBulkDeleteModal} short>
            Delete
          </DropdownItem>
        </Dropdown>
      </ActionsContainer>

      <Table
        id={idTable}
        columns={columnsList}
        data={tableData}
        sortBy={[
          {
            id: 'name',
            desc: false,
          },
        ]}
        noDataText={rules ? undefined : lang.loading}
        getIsRowSelectorDisabled={getIsRowSelectorDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
        testId="response-policies-table"
      />

      {modal.show && (
        <ConfirmModal
          item={modal.name || modal.description}
          onConfirm={deleteRule}
          onToggle={onToggle}
          isOpen
          testId="delete-response-policy-modal"
        />
      )}
      {showBulkEnableModal && (
        <ConfirmModal
          item={`${selected.length} Response Polic${
            selected.length > 1 ? 'ies' : 'y'
          }`}
          confirmButtonText="enable"
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={toggleBulkEnableModal}
          onConfirm={onConfirmBulkEnable}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {showBulkDisableModal && (
        <ConfirmModal
          item={`${selected.length} Response Polic${
            selected.length > 1 ? 'ies' : 'y'
          }`}
          confirmButtonText="disable"
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={toggleBulkDisableModal}
          onConfirm={onConfirmBulkDisable}
          toggleOnConfirm={false}
          isOpen
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Response Polic${
            selected.length > 1 ? 'ies' : 'y'
          }`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default RulesTable;
