import styled from 'styled-components';

const FieldsCol = styled.div`
  display: flex;
  flex-direction: column;

  .form__form-group + .form__form-group {
    margin-left: unset !important;
  }
`;

export default FieldsCol;
