import original from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const dayjs = (...args) => {
  return original(...args).tz();
};

Object.assign(dayjs, original);

const proto = Object.getPrototypeOf(original());

dayjs.prototype = proto;

const unix = (timestamp) => dayjs(timestamp * 1e3);

const extend = (...args) =>
  Object.assign(dayjs, original.extend(...args), { extend, unix });

dayjs.extend = extend;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const originalFormat = proto.format;
const oldStartOf = proto.startOf;

proto.startOf = function (units, startOf) {
  const currentFormat = proto.format;
  proto.format = originalFormat;
  const result = oldStartOf.call(this, units, startOf);
  proto.format = currentFormat;
  return result;
};

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);

export const DateFormat = {
  // full
  millisecond: 'YYYY-MM-DD HH:mm:ss.SSS',
  second: 'YYYY-MM-DD HH:mm:ss',
  minute: 'YYYY-MM-DD HH:mm',
  hour: 'YYYY-MM-DD HH',
  day: 'YYYY-MM-DD',
  // without date
  secondWithoutDate: 'HH:mm:ss',
  minuteWithoutDate: 'HH:mm',
  // without year
  minuteWithoutYear: 'MM-DD HH:mm',
  dayWithoutYear: 'MM-DD',
  // without hour
  yearAndMonth: 'YYYY-MM',
  year: 'YYYY',
};

export const dayjsOriginal = original;
export default dayjs;
